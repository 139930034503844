import { SBInput, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';

interface FormValue {
  question: string;
  sequence: number;
}

interface QuestionFromProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
}

export default function QuestionFrom(props: QuestionFromProps) {
  const { register, errors } = props;

  return (
    <Stack gap={4}>
      <SBInput
        crossOrigin={undefined}
        label="Question*"
        placeholder="Add Question"
        {...register('question')}
        error={errors?.question?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Sequence*"
        type="number"
        placeholder="Add Question's sequence"
        {...register('sequence')}
        error={errors?.sequence?.message}
      />
    </Stack>
  );
}
