import { useMutation } from '@apollo/client';
import { UPDATE_ORDER } from 'src/components/Particles/resolvers/orders/orders.graphql';
import { type clientOptionType } from '../SharedTypes';
import { type Order, type UpdateOrderInput } from './orders.types';

interface UpdateOrderResponse {
  message: string;
  data: Order;
}
interface UpdateOrderProps {
  payload: UpdateOrderInput;
  onCompleted: (
    data: { updateOrder: UpdateOrderResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useUpdateOrder() {
  const [mutate, { loading, error }] = useMutation<
    { updateOrder: UpdateOrderResponse },
    { updateOrderInput: UpdateOrderInput }
  >(UPDATE_ORDER);

  async function updateOrder({ payload, onCompleted }: UpdateOrderProps) {
    await mutate({
      variables: {
        updateOrderInput: payload,
      },
      onCompleted,
    });
  }

  return {
    updateOrder,
    loading,
    error,
  };
}
