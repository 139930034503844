import { toast, IconButton, SBTrashIcon } from '@swftbox/style-guide';
import { type Role, useRemoveRole } from 'src/components/Particles';
import { DefaultRoles } from '../helper';

interface RemoveRoleProps {
  role: Role;
}
export function RemoveRole({ role }: RemoveRoleProps) {
  const { mutate } = useRemoveRole({
    onCompleted: (data) => {
      if (data.removeRole.success) toast.success(data.removeRole.message);
    },
  });

  const onRemove = (roleId: number) => {
    mutate({
      variables: {
        removeRoleId: roleId,
      },
    });
  };

  return (
    <IconButton
      onClick={() => onRemove(role.id)}
      size="small"
      variant="link"
      color="gray.600"
      _hover={{ opacity: '0.5', color: 'green' }}
      aria-label="remove role"
      icon={<SBTrashIcon width="15px" />}
      isDisabled={DefaultRoles.includes(role.name)}
    />
  );
}
