import {
  type ReactNode,
  useState,
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { GRID_VIEW } from '../../../Helper';
import { AddJobOptions } from './AddJobOptions';
import { ListWrapper } from './ItemList';
import { SelectedItems } from './SelectedItemList';

interface AddJobBodyProps {
  itemList: ReactNode;
  revealAllLocations: boolean;
  setRevealAllLocations: Dispatch<SetStateAction<boolean>>;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AddJobBody = (props: AddJobBodyProps) => {
  const { itemList, onSearchChange, revealAllLocations, setRevealAllLocations } = props;
  const [view, setView] = useState(GRID_VIEW);

  return (
    <>
      <AddJobOptions />
      <SelectedItems view={view} />
      <ListWrapper
        onSearchChange={onSearchChange}
        view={view}
        setView={setView}
        revealAllLocations={revealAllLocations}
        setRevealAllLocations={setRevealAllLocations}
        itemList={itemList}
      />
    </>
  );
};
