import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Spinner,
} from '@swftbox/style-guide';
import { FC } from 'react';
import { ShiftTime } from 'src/components/Particles';
import { ActionButtons } from './ActionButtons';

interface Props {
  driverId: string;
  shiftTimes: ShiftTime[];
  onEdit: () => void;
  onDelete: (shiftTime: ShiftTime) => void;
  setSelectedShiftTime: (shiftTime: ShiftTime) => void;
  loading?: boolean;
}

const tableHeaders = [
  { title: '#', subtitle: '', id: 'id' },
  { title: 'Day', subtitle: '', id: 'day' },
  { title: 'From', subtitle: '', id: 'from' },
  { title: 'To', subtitle: '', id: 'to' },
  { title: '', subtitle: '', id: 'actions' },
];

export const ShiftTimesTable = (props: Props) => {
  const { driverId, shiftTimes, setSelectedShiftTime, loading, onEdit, onDelete } = props;

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead>
            <Tr bg="#fbfbfc" color="neutral.normal">
              {tableHeaders.map((header) => (
                <Th key={header.id}>
                  <Text>{header.title}</Text>
                  <Text color="neutral.normal">{header.subtitle}</Text>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {shiftTimes?.map((shiftTime: ShiftTime, index: number) => {
              return (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <Text>{shiftTime.day}</Text>
                  </Td>
                  <Td>
                    <Text>{shiftTime.from}</Text>
                  </Td>
                  <Td>
                    <Text>{shiftTime.to}</Text>
                  </Td>
                  <Td>
                    <ActionButtons
                      onEdit={() => {
                        setSelectedShiftTime(shiftTime);
                        onEdit();
                      }}
                      onRemove={() => {
                        setSelectedShiftTime(shiftTime);
                        onDelete(shiftTime);
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
