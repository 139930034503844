import { Box, Flex, Spacer } from '@swftbox/style-guide';

import { OrganizingFilters } from './OrganizingFilters';

const OrdersHeader = () => {
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        {/* <Text as="p" fontWeight="regular" fontSize="text-sm" color="gray.600">
          Keep track of Orders and their Status.
        </Text> */}
      </Box>
      <Spacer />
      <OrganizingFilters />
    </Flex>
  );
};

export default OrdersHeader;
