import { Button, HStack } from '@swftbox/style-guide';

interface PartnersFilterProps {
  updateStatusFilter: (value?: string[]) => void;
  filterStatus?: string[];
}

const StatsMocking = [
  {
    header: 'All',
    value: undefined,
  },
  {
    header: 'Pending',
    value: 'pending',
  },
  {
    header: 'Off Boarded',
    value: 'offboarded',
  },
  {
    header: 'Active',
    value: 'active',
  },
  {
    header: 'Disabled',
    value: 'disabled',
  },
];

export function PartnersFilter(props: PartnersFilterProps) {
  const { updateStatusFilter, filterStatus } = props;

  return (
    <HStack
      border="1px solid #D0D5DD;"
      h="40px"
      lineHeight="40px"
      borderRadius="8px"
      shadow="xs"
      overflow="hidden"
      m="20px 24px"
      w="fit-content"
      spacing="0"
    >
      {StatsMocking.map((stat, index) => (
        <Button
          key={index}
          variant="grouped"
          bg={stat.value === filterStatus ? 'gray.100' : 'transparent'}
          onClick={() => {
            updateStatusFilter(stat.value ? [stat.value] : undefined);
          }}
          fontSize="x-small"
        >
          {stat.header}
        </Button>
      ))}
    </HStack>
  );
}
