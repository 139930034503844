import { SBInput, Stack, SBTextArea, Autocomplete } from '@swftbox/style-guide';
import {
  Control,
  Controller,
  useFormContext,
  UseFormGetValues,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import { InvoiceLineItemType, SelectOptionType } from 'src/components/Particles';
import { type FocusEvent } from 'react';

interface FormValue {
  code: string;
  description: string;
  rate: number;
  vat: number;
  type: InvoiceLineItemType;
}

interface FormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
  editMode?: boolean;
  getValues: UseFormGetValues<FormValue>;
  control: Control<FormValue, any>;
}

export default function LineItemForm(props: FormProps) {
  const { register, errors, editMode, getValues, control } = props;

  const lineItemTypeOptions = Object.values(InvoiceLineItemType)?.map((type) => ({
    label: type,
    value: type,
  }));

  function onFocus(event: FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  return (
    <Stack gap={4} as={'form'}>
      <SBInput
        label="Code*"
        placeholder="Add Line Item Code"
        {...register('code')}
        error={errors.code?.message}
        crossOrigin={undefined}
        isDisabled={editMode}
      />
      <SBTextArea
        label="Description"
        minHeight="60px"
        placeholder="Add Line Item Description"
        {...register('description')}
        error={errors.description?.message}
      />
      <SBInput
        label="Rate*"
        placeholder="Add Line Item Rate"
        {...register('rate')}
        type="number"
        error={errors.rate?.message}
        crossOrigin={undefined}
      />

      <SBInput
        label="VAT*"
        placeholder="Add Line Item VAT"
        {...register('vat')}
        type="number"
        value={getValues('vat')}
        error={errors.vat?.message}
        crossOrigin={undefined}
      />

      <Controller
        name="type"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            isMulti={false}
            label={'Type'}
            onFocus={onFocus}
            onChange={(selectedType: SelectOptionType | readonly SelectOptionType[] | null) => {
              if (selectedType == null || selectedType instanceof Array) return;
              else {
                onChange(selectedType?.value);
              }
            }}
            options={lineItemTypeOptions || []}
            placeholder="Select invoice type"
            value={lineItemTypeOptions?.find((option) => option.value === value)}
          />
        )}
      />
    </Stack>
  );
}
