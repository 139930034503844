import { gql } from '@apollo/client';

export const GET_DRIVERS = gql`
  query Query(
    $page: Int
    $employmentType: [EmploymentType!]
    $userStatus: [AccountStatus!]
    $text: String
  ) {
    drivers(page: $page, employmentType: $employmentType, userStatus: $userStatus, text: $text) {
      pageCount
      data {
        birthday
        employmentType
        balance
        codBalance
        gender
        id
        isOnline
        licenseNumber
        nationalId
        operatorId
        status
        user {
          id
          email
          name
          phone
          status
        }
        vehicle {
          contractor
          model
          plateNumber
          type
        }
        shiftTimes {
          from
          to
          day
        }
      }
      total
    }
  }
`;

export const GET_DRIVERS_LIST = gql`
  query DriversList(
    $isOnline: Boolean
    $userStatus: [AccountStatus!]
    $status: DriverStatus
    $shiftDay: Days
    $shiftTimeStart: LocalTime
  ) {
    driversList(
      isOnline: $isOnline
      userStatus: $userStatus
      status: $status
      shiftDay: $shiftDay
      shiftTimeStart: $shiftTimeStart
    ) {
      id
      user {
        id
        name
      }
      totalActiveJobs
      totalActiveOrders
    }
  }
`;

export const ADD_DRIVER = gql`
  mutation Mutation($createDriverInput: CreateDriverInput!) {
    createDriver(createDriverInput: $createDriverInput) {
      id
      userId
    }
  }
`;

export const UPDATE_DRIVER = gql`
  mutation Mutation($updateDriverInput: UpdateDriverInput!) {
    updateDriver(updateDriverInput: $updateDriverInput) {
      id
      userId
    }
  }
`;

export const UPDATE_STATUS_DRIVER = gql`
  mutation Mutation($updateDriverStatusInput: UpdateDriverStatusInput!) {
    updateStatus(updateDriverStatusInput: $updateDriverStatusInput) {
      id
      userId
    }
  }
`;

export const REMOVE_DRIVER = gql`
  mutation Mutation($removeDriverId: UUID!) {
    removeDriver(id: $removeDriverId) {
      success
      message
    }
  }
`;

export const UPDATE_SHIFT_TIME = gql`
  mutation Mutation($updateShiftTimesInput: UpdateShiftTimesInput!) {
    updateShiftTimes(updateShiftTimesInput: $updateShiftTimesInput) {
      driverId
    }
  }
`;

export const DELETE_SHIFT_TIME = gql`
  mutation DeleteShiftTimes($deleteShiftTimesInput: UpdateShiftTimesInput!) {
    deleteShiftTimes(deleteShiftTimesInput: $deleteShiftTimesInput) {
      driverId
    }
  }
`;
