import { toast, IconButton, SBTrashIcon } from '@swftbox/style-guide';
import { useRemoveInvoiceSetting, type InvoiceSetting } from 'src/components/Particles';

interface RemoveLineItemProps {
  invoiceLineItem: InvoiceSetting;
}
export function RemoveLineItem({ invoiceLineItem }: RemoveLineItemProps) {
  const { mutate } = useRemoveInvoiceSetting({
    onCompleted: (data) => {
      if (data.removeInvoiceSetting.success) toast.success(data.removeInvoiceSetting.message);
    },
  });

  const onRemove = (id: string) => {
    mutate({
      variables: {
        removeInvoiceSettingInput: { id },
      },
    });
  };

  return (
    <IconButton
      onClick={() => onRemove(invoiceLineItem.id)}
      size="small"
      variant="link"
      color="gray.600"
      _hover={{ opacity: '0.5', color: 'green' }}
      aria-label="remove line item"
      icon={<SBTrashIcon width="15px" />}
    />
  );
}
