import {
  Badge,
  Box,
  Button,
  HStack,
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBAddIcon,
  SBMenuDotsVerticalIcon,
  Stack,
  Td,
  Text,
  Tooltip,
  Tr,
  toast,
} from '@swftbox/style-guide';
import OptimisedIcon from 'src/assets/icons/General/optimized-route.svg';
import NotRoutedIcon from 'src/assets/icons/General/not-routed.svg';
import ChilledIcon from 'src/assets/icons/chilled.svg';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import Svg from 'react-inlinesvg';
import JobIcon from 'src/assets/icons/orders/job.svg';
import { AssignDriver } from 'src/components/Organisms';
import { JobType } from 'src/components/Pages/Jobs/Components/JobType';
import {
  useAssignJob,
  useAuthContext,
  useOptimizeRoute,
  useSyncRoutes,
  type Job,
  OptimizerStatus,
} from 'src/components/Particles';
import { getJobStatusColor, getJobStatusLabel } from '../../Helper';
import { JobDetails } from './../JobDetails';
import { RemoveJob } from './../RemoveJob';

export const JobTableCell = ({ job }: { job: Job }) => {
  if (!job) return <></>;

  const [isOpen, setIsOpen] = useState(false);
  const { syncRoutes } = useSyncRoutes();
  const { optimizeRoute, loading: isOptimizingRoute } = useOptimizeRoute();
  const { user } = useAuthContext();
  const { assign } = useAssignJob();

  function performSyncRoute(route: Job) {
    void syncRoutes({
      onCompleted: (data) => {
        toast.success(data.fixRoutesElasticSync.message);
      },
      payload: {
        reference: [route.reference],
      },
    });
  }

  function performOptimizeRoute(route: Job) {
    void optimizeRoute({
      onCompleted: (data) => {
        toast.success(data.optimizeRoute.message);
      },
      payload: {
        routeId: route.id,
      },
    });
  }

  const onDriverAssignment = useCallback(
    async (driverId: string, routeId?: string) => {
      try {
        if (!routeId) return false;
        await assign({
          payload: { driverId, routeId },
          onCompleted: (message) => {
            toast.success(message);
          },
        });
        return true;
      } catch {
        return false;
      }
    },
    [assign]
  );

  const routeIcon =
    job?.optimizerStatus === OptimizerStatus.completed
      ? OptimisedIcon
      : job?.optimizerStatus === OptimizerStatus.failed
      ? NotRoutedIcon
      : null;
  return (
    <Tr position="relative">
      <Td>
        {routeIcon ? (
          <Svg
            src={routeIcon}
            width={job?.optimizerStatus === OptimizerStatus.failed ? '30px' : '20px'}
            height="20px"
          />
        ) : (
          ''
        )}
      </Td>
      <Td>
        <JobDetails
          id={job.id}
          OpenButton={
            <HStack>
              <Text width="100%" fontWeight="semibold" textAlign="start" cursor="pointer">
                {job.reference}
              </Text>
            </HStack>
          }
        />
        <Text color="neutral.normal" fontSize="text-sm">
          {job.extraProps?.ordersCount || 0} order/s
        </Text>
      </Td>
      <Td>
        <JobType label={job.label} />
      </Td>
      <Td>
        {!!job.hasChilledOrders && (
          <Tooltip label={'Has Chilled orders'} hasArrow>
            <Box
              bg="blue.500"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={ChilledIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
      </Td>
      <Td>
        <Badge
          p="2px 8px"
          fontWeight="medium"
          borderRadius="8px"
          mb="5px"
          fontSize="x-small"
          variant="solid"
          colorScheme={getJobStatusColor(job.status)}
        >
          {getJobStatusLabel(job.status)}
        </Badge>

        <Stack direction={['column', 'row']} alignItems="center">
          <Box color="neutral.normal" fontSize="text-sm">
            {job.extraProps?.remainingStopsCount || 0} / {job.extraProps?.stopsCount || 0} stops
          </Box>
        </Stack>
      </Td>
      <Td>
        <AssignDriver
          fontSize="text-sm"
          color="gray.500"
          id={job.id}
          onAssign={onDriverAssignment}
          entityName="job"
          openButton={
            <MenuItem>
              <IconWText
                text="Assign"
                spacing="20px"
                aria-label="assign a driver"
                Icon={<SBAddIcon width="15px" />}
              />
            </MenuItem>
          }
        >
          <Button bg="#EFEFEF" minW="110px" p="1" borderRadius="4" my="2" variant="link">
            <HStack w="100%" justifyContent="space-between">
              <Svg src={JobIcon} />
              <Text>{job?.driver?.user?.name || '-'}</Text>
              <div />
            </HStack>
          </Button>
        </AssignDriver>
      </Td>
      <Td>
        <Text isTruncated fontWeight="semibold">
          {format(new Date(job.updatedAt), 'HH:mm MMM dd, yyyy')}
        </Text>
        <Text color="neutral.normal" fontSize="text-sm">
          {format(new Date(job.createdAt), 'HH:mm MMM dd, yyyy')}
        </Text>
      </Td>
      <Td pr="0" textAlign="end" padding="0" position={{ base: 'absolute' }} />
      <Td
        textAlign="end"
        padding="0"
        position={{ base: 'sticky' }}
        bg={{ '2xl': 'unset', base: '#fbfbfc' }}
        right={{ '2xl': '0px', base: '-1px' }}
        zIndex={isOpen ? 1 : 0}
      >
        <Menu
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <MenuButton
            as="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <SBMenuDotsVerticalIcon width="15px" />
          </MenuButton>
          <MenuList zIndex="10" display={isOpen ? 'inline-block' : 'none'}>
            <JobDetails
              id={job.id}
              btnW="100%"
              OpenButton={
                <Text
                  width="100%"
                  fontWeight="semibold"
                  textAlign="start"
                  p="3"
                  _hover={{ bg: '#F9FAFB' }}
                >
                  View Job
                </Text>
              }
            />
            {['SuperAdmin', 'Admin'].some((role) => user?.roles?.includes(role)) && (
              <>
                <Button
                  onClick={() => {
                    performSyncRoute(job);
                  }}
                  variant="link"
                  _hover={{ textDecor: 'none' }}
                  w="100%"
                >
                  <Text
                    width="100%"
                    fontWeight="semibold"
                    textAlign="start"
                    p="3"
                    _hover={{ bg: '#F9FAFB' }}
                  >
                    Sync Job
                  </Text>
                </Button>
                <Button
                  onClick={() => {
                    performOptimizeRoute(job);
                  }}
                  variant="link"
                  _hover={{ textDecor: 'none' }}
                  w="100%"
                  isLoading={isOptimizingRoute}
                >
                  <Text
                    width="100%"
                    fontWeight="semibold"
                    textAlign="start"
                    p="3"
                    _hover={{ bg: '#F9FAFB' }}
                  >
                    Optimize Job
                  </Text>
                </Button>
              </>
            )}
            {/* <AddOrdersToJob jobId={job.id} /> */}
            <AssignDriver
              fontSize="text-sm"
              color="gray.500"
              id={job.id}
              onAssign={onDriverAssignment}
              entityName="job"
              openButton={
                <MenuItem>
                  <IconWText
                    text="Assign"
                    spacing="20px"
                    aria-label="assign a driver"
                    Icon={<SBAddIcon width="15px" />}
                  />
                </MenuItem>
              }
            >
              <MenuItem fontSize="text-sm" color="gray.500">
                <Text fontWeight="semibold">Assign</Text>
              </MenuItem>
            </AssignDriver>

            {['SuperAdmin', 'Admin'].some((role) => user?.roles?.includes(role)) && (
              <RemoveJob routeId={job.id} reference={job.reference} />
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};
