import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, SBEditIcon, SBModal, toast, useDisclosure } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdateFeature, type Feature, type UpdateFeatureInput } from 'src/components/Particles';
import EditFeatureForm from './EditFeatureForm';
import { featureUpdateSchema } from './Feature.Schema';

interface EditFeatureProps {
  feature: Feature;
}
export function EditFeature({ feature }: EditFeatureProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useUpdateFeature({
    onCompleted: (data) => {
      handleCloseModal(data.updateFeature.message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateFeatureInput>({
    resolver: yupResolver(featureUpdateSchema),
    mode: 'all',
    defaultValues: {
      id: feature.id,
      name: feature.name,
      description: feature.description,
      billing: feature.billing,
      isConfigurable: feature.isConfigurable,
      category: feature.category,
    },
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: UpdateFeatureInput) => {
    mutate({
      variables: {
        input: data,
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit feature"
        icon={<SBEditIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={<EditFeatureForm control={control} register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
