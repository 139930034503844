import { addDays, addYears } from 'date-fns';
import uuid from 'react-uuid';

export const DeliveryProfiles = [
  { id: 'express', name: 'Express', value: ['express2h', 'express4h'] },
  { id: 'timeSlots', name: 'Time Slots', value: ['timeslots'] },
  { id: 'standard', name: 'Standard', value: ['nextday', 'sameday'] },
  { id: 'returns', name: 'Returns', value: ['returns'] },
  { id: 'exchanges', name: 'Exchanges', value: ['exchanges'] },
];

export const delayedOrdersList = [
  {
    id: uuid(),
    label: 'Delayed',
    value: {
      startDate: addYears(new Date(), -2),
      endDate: addDays(new Date(), -1),
    },
  },
  {
    id: uuid(),
    label: 'Due yesterday',
    value: {
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
    },
  },
  {
    id: uuid(),
    label: '2 days ago',
    value: {
      startDate: addDays(new Date(), -2),
      endDate: addDays(new Date(), -2),
    },
  },
  {
    id: uuid(),
    label: '3 days +',
    value: {
      startDate: addYears(new Date(), -2),
      endDate: addDays(new Date(), -3),
    },
  },
];
