import { useMutation } from '@apollo/client';
import { FILTER_ORDERS } from '../../orders/orders.graphql';
import { QueriesStore } from '../../queriesStore';
import { type MessageResponse } from '../../SharedTypes';
import { FILTERED_JOBS, REMOVE_ROUTE_MUTATION, type RemoveRouteInput } from '../Queries';
import { QueryJobsResponse } from './Jobs.service';

interface RemoveJobProps {
  payload: RemoveRouteInput;
  onCompleted?: (message: string) => void;
}

export function useRemoveJob() {
  const [mutate, { loading, error }] = useMutation<
    { removeRoute: MessageResponse },
    { removeRouteInput: RemoveRouteInput }
  >(REMOVE_ROUTE_MUTATION);

  async function remove({ payload, onCompleted }: RemoveJobProps) {
    const { routeId } = payload;
    await mutate({
      variables: {
        removeRouteInput: {
          routeId,
        },
      },

      update: (cache, result, context) => {
        if (result.data && onCompleted) {
          onCompleted(result.data.removeRoute.message);
        }

        const existingJobs: QueryJobsResponse | null = cache.readQuery({
          query: FILTERED_JOBS,
          variables: QueriesStore.get('JOBS'),
        });
        const newRoutes = existingJobs?.filterRoutes.data.filter(
          (route) => context.variables?.removeRouteInput.routeId !== route.id
        );
        cache.writeQuery({
          query: FILTERED_JOBS,
          variables: QueriesStore.get('JOBS'),
          data: {
            filterRoutes: {
              ...existingJobs?.filterRoutes,
              data: newRoutes,
            },
          },
        });
      },
    });
  }

  return {
    remove,
    loading,
    error,
  };
}
