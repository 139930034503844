import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../SharedTypes';
import { DELETE_TASK, type GeneralMessageResponse, GET_PAGINATED_TASKS } from '../Queries';
import { QueriesStore } from '../../queriesStore';

interface DeleteTaskProps {
  payload: {
    removeTaskId: string;
  };
  onCompleted: (
    data: { removeTask: GeneralMessageResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useDeleteTask() {
  const [mutate, { loading, error }] = useMutation<
    { removeTask: GeneralMessageResponse },
    { removeTaskId: string }
  >(DELETE_TASK);

  async function deleteTask({ payload, onCompleted }: DeleteTaskProps) {
    const { removeTaskId } = payload;
    await mutate({
      variables: { removeTaskId },
      onCompleted,
      refetchQueries: [
        {
          query: GET_PAGINATED_TASKS,
          variables: QueriesStore.get('GET_TASKS'),
        },
      ],
    });
  }

  return {
    deleteTask,
    loading,
    error,
  };
}
