import { Dispatch, FC, SetStateAction } from 'react';
import {
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBMenuDotsVerticalIcon,
  If,
  SBEditIcon,
  SBFileIcon,
  toast,
  SBCheckIcon,
  SBCrossCircleIcon,
  Box,
  HStack,
  Button,
  Tooltip,
} from '@swftbox/style-guide';
import { type Payment, useUpdatePaymentStatusMutation } from 'src/components/Particles';
import { PaymentHistoryModal } from './PaymentHistory';

interface Props {
  onMenuOpen: () => void;
  payment: Payment;
  editPayment: Dispatch<SetStateAction<Payment | undefined>>;
}

export const PaymentActionButtons: FC<Props> = (props) => {
  const { onMenuOpen, payment, editPayment } = props;
  const { updatePaymentStatus, loading: updating } = useUpdatePaymentStatusMutation();

  const handlePaymentStatusUpdate = async (approved: boolean) => {
    await updatePaymentStatus({
      id: payment.id,
      type: payment.type,
      ...(approved && { approved: true }),
      ...(!approved && { rejected: true }),
    });
    toast.success(`Payment ${approved ? 'approved' : 'rejected'} successfully`);
  };

  const showReceipt = () => {
    window.open(payment.receipt!, '_blank', 'noreferrer');
  };

  return (
    <HStack spacing="4" justifyContent="end">
      <If
        children={
          <Button
            variant="link"
            onClick={showReceipt}
            disabled={updating}
            color="gray.700"
            _hover={{ opacity: 0.4 }}
            sx={{ minW: '10px' }}
          >
            <Tooltip label="Receipt">
              <Box>
                <SBFileIcon width="15px" />
              </Box>
            </Tooltip>
          </Button>
        }
        condition={!!payment.receipt}
      />
      <If
        children={
          <Button
            variant="link"
            onClick={() => {
              handlePaymentStatusUpdate(true);
            }}
            disabled={updating}
            color="gray.700"
            _hover={{ opacity: 0.4 }}
            px="0"
            sx={{ minW: '10px' }}
          >
            <Tooltip label="Approve">
              <Box>
                <SBCheckIcon width="15px" />
              </Box>
            </Tooltip>
          </Button>
        }
        condition={payment.status !== 'APPROVED'}
      />
      <If
        children={
          <Button
            variant="link"
            onClick={() => {
              handlePaymentStatusUpdate(false);
            }}
            disabled={updating}
            color="gray.700"
            _hover={{ opacity: 0.4 }}
            sx={{ minW: '10px' }}
          >
            <Tooltip label="Reject">
              <Box>
                <SBCrossCircleIcon width="15px" />
              </Box>
            </Tooltip>
          </Button>
        }
        condition={payment.status !== 'REJECTED'}
      />

      <Menu>
        <MenuButton as="button" onClick={onMenuOpen}>
          <SBMenuDotsVerticalIcon width="15px" />
        </MenuButton>
        <MenuList>
          <PaymentHistoryModal paymentId={payment.id} />
          <MenuItem onClick={editPayment.bind(null, payment)}>
            <IconWText text="Edit" spacing="20px" Icon={<SBEditIcon width="15px" />} />
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default PaymentActionButtons;
