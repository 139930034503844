import { useMutation } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import { clientOptionType } from '../../SharedTypes';
import { LIST_INVOICES, REGENERATE_INVOICE, type InvoiceMutationResponse } from '../Queries';

interface Props {
  onCompleted: (
    data: { regenerateInvoice: InvoiceMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export const useRegenerateInvoiceMutation = ({ onCompleted }: Props) => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { regenerateInvoice: InvoiceMutationResponse },
    { idInput: { id: string } }
  >(REGENERATE_INVOICE, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_INVOICES,
        variables: QueriesStore.get('LIST_INVOICES'),
        pollInterval: 5000,
      },
    ],
  });

  return {
    mutate,
    reset,
    loading,
    error,
    ...rest,
  };
};
