import { createContext, type Dispatch, useContext } from 'react';

import {
  type JobsOrganizingFilters,
  type JobsOrganizedFilterAction,
} from '../../providers';

interface JobsFiltersContextProps {
  organizedFilters: JobsOrganizingFilters;
}

export const JobsFiltersContext = createContext<JobsFiltersContextProps>({
  organizedFilters: {},
});

type JobsFiltersActionsContextProps = Dispatch<JobsOrganizedFilterAction>;

export const JobsFiltersActionsContext = createContext<JobsFiltersActionsContextProps | null>(
  null
);
export function useJobsFiltersActions() {
  const dispatch = useContext(JobsFiltersActionsContext);
  return dispatch as JobsFiltersActionsContextProps;
}
export function useJobsFilters() {
  return useContext(JobsFiltersContext);
}

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
