import { useState } from 'react';
import { Box, Grid, GridItem, Image, Link } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import EmptyBox from 'src/assets/emptyBox.svg';
import { Document, Page } from 'react-pdf';

interface OrderDetailsDeliveryAttachmentsProps {
  deliveryNotes?: string[];
  proofOfId?: string[];
  attachments?: string[];
  customerProofSignatureUrl?: string;
}

export const DeliveryAttachments = ({
  deliveryNotes,
  proofOfId,
  customerProofSignatureUrl,
  attachments,
}: OrderDetailsDeliveryAttachmentsProps) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPage }: any) {
    setNumPages(numPages);
  }
  console.log(proofOfId, customerProofSignatureUrl, deliveryNotes);
  return (
    <>
      <Box bg="gray.100" borderRadius="8px" p="3" minH="300px">
        {!!proofOfId?.length && (
          <>
            <Grid
              gridTemplateColumns="repeat(12,1fr) 15px"
              color="gray.700"
              fontWeight="semibold"
              gap="3"
              py="3"
              mb="5"
              zIndex="100"
              bg="gray.100"
            >
              <GridItem colSpan={12}>Proof of Id</GridItem>
            </Grid>
            <Grid
              gridTemplateColumns="repeat(4,1fr)  15px"
              gap="3"
              color="primary.900"
              fontWeight="medium"
              fontSize="x-small"
              _notLast={{ mb: '2.5', pb: '2.5', borderBottom: '3px solid white' }}
            >
              {(proofOfId || []).map((proof: string, index) => (
                <GridItem key={index} display="flex" alignItems="center" overflow="hidden">
                  {proof.includes('pdf') ? (
                    <Document file={proof} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(new Array(numPages), (el, index) => (
                        <Link key={el} href={proof} isExternal>
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={200}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                        </Link>
                      ))}
                    </Document>
                  ) : (
                    <Link href={proof} isExternal>
                      <Image
                        width={200}
                        objectFit="cover"
                        src={proof}
                        alt={`proof of id #${index + 1}`}
                      />
                    </Link>
                  )}
                </GridItem>
              ))}
            </Grid>
          </>
        )}
        {!!deliveryNotes?.length && (
          <>
            <Grid
              gridTemplateColumns="repeat(12,1fr) 15px"
              color="gray.700"
              fontWeight="semibold"
              gap="3"
              py="3"
              mb="5"
              zIndex="100"
              bg="gray.100"
            >
              <GridItem colSpan={12}>Delivery Notes</GridItem>
            </Grid>
            <Grid
              gridTemplateColumns="repeat(4,1fr)  15px"
              gap="3"
              color="primary.900"
              fontWeight="medium"
              fontSize="x-small"
              _notLast={{ mb: '2.5', pb: '2.5', borderBottom: '3px solid white' }}
            >
              {(deliveryNotes || []).map((note: string, index) => (
                <GridItem key={index} display="flex" alignItems="center" overflow="hidden">
                  {note.includes('pdf') ? (
                    <Document file={note} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(new Array(numPages), (el, index) => (
                        <Link key={el} href={note} isExternal>
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={200}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                        </Link>
                      ))}
                    </Document>
                  ) : (
                    <Link href={note} isExternal>
                      <Image
                        width={200}
                        objectFit="cover"
                        src={note}
                        alt={`note of id #${index + 1}`}
                      />
                    </Link>
                  )}
                </GridItem>
              ))}
            </Grid>
          </>
        )}
        {!!attachments?.length && (
          <>
            <Grid
              gridTemplateColumns="repeat(12,1fr) 15px"
              color="gray.700"
              fontWeight="semibold"
              gap="3"
              py="3"
              zIndex="100"
              bg="gray.100"
            >
              <GridItem colSpan={12}>Attachments</GridItem>
            </Grid>
            <Grid
              gridTemplateColumns="repeat(4,1fr)  15px"
              gap="3"
              color="primary.900"
              fontWeight="medium"
              fontSize="x-small"
              _notLast={{ mb: '2.5', pb: '2.5', borderBottom: '3px solid white' }}
            >
              {(attachments || []).map((note: string, index) => (
                <GridItem key={index} display="flex" alignItems="center" overflow="hidden">
                  <Link href={note} isExternal>
                    <Image
                      width={200}
                      objectFit="cover"
                      src={note}
                      alt={`note of id #${index + 1}`}
                    />
                  </Link>
                </GridItem>
              ))}
            </Grid>
          </>
        )}
        {customerProofSignatureUrl && (
          <Grid
            gridTemplateColumns="repeat(12,1fr) 15px"
            color="gray.700"
            fontWeight="semibold"
            borderColor="gray.500"
            gap="3"
            py="3"
            mb="5"
            bg="gray.100"
          >
            <GridItem colSpan={12}>Customer Signature</GridItem>
            <GridItem colSpan={8} bg="#fff">
              <Image
                objectFit="cover"
                height="100%"
                src={customerProofSignatureUrl}
                alt="Customer Signature"
              />
            </GridItem>
          </Grid>
        )}
        {/* {!proofOfId?.length && (
          <Box w="100%" h="200px" justifyContent="center" alignItems="center" display="flex">
            <EmptyTable icon={EmptyBox} title="No Items Found" />
          </Box>
        )} */}
      </Box>
    </>
  );
};
