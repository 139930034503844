import { useMutation } from '@apollo/client';
import {
  CARRIYO_INTEGRATION_CARRIERS,
  CREATE_CARRIYO_INTEGRATION_CARRIER,
} from '../orders.graphql';
import { type CreateCarriyoIntegrationCarrierInput } from '../orders.types';

interface CreateCarriyoIntegrationCarrierResponse {
  message: string;
}

interface CreateCarriyoIntegrationCarrierProps {
  createCarriyoIntegrationCarrierInput: CreateCarriyoIntegrationCarrierInput;
  onCompleted: (message: string) => void;
}

export function useCreateCarriyoIntegrationCarrier() {
  const [mutate, { loading, error }] = useMutation<
    { createCarriyoIntegrationCarrier: CreateCarriyoIntegrationCarrierResponse },
    { createCarriyoIntegrationCarrierInput: CreateCarriyoIntegrationCarrierInput }
  >(CREATE_CARRIYO_INTEGRATION_CARRIER);

  async function createCarriyoIntegrationCarrier({
    createCarriyoIntegrationCarrierInput,
    onCompleted,
  }: CreateCarriyoIntegrationCarrierProps) {
    await mutate({
      variables: {
        createCarriyoIntegrationCarrierInput,
      },
      onCompleted: (data) => {
        onCompleted(data.createCarriyoIntegrationCarrier.message);
      },
      refetchQueries: [CARRIYO_INTEGRATION_CARRIERS],
    });
  }

  return {
    createCarriyoIntegrationCarrier,
    loading,
    error,
  };
}
