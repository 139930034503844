import * as yup from 'yup';

type ValidateUniqueCompanyNameFunction = (name: string) => Promise<boolean>;

export const getStepOneSchema = (companyNameValidator: ValidateUniqueCompanyNameFunction) =>
  yup.object().shape({
    email: yup.string().email('Please Enter a valid email').required(),
    name: yup
      .string()
      .min(1)
      .test({
        name: 'unique-company-name',
        test: async (value, ctx) => {
          if (!value) return ctx.createError({ message: 'Company name is required' });
          const existed = await companyNameValidator(value);
          if (existed) return ctx.createError({ message: `${value} already exist` });
          return true;
        },
      })
      .required(),
  });
