import { Badge, Box, Flex, HStack, SBShoppingBag02Icon, Text, Tooltip } from '@swftbox/style-guide';
import { getAddress, type Order } from 'src/components/Particles';

interface OrderItemProps {
  order: Order;
}

export const OrderItem = (props: OrderItemProps) => {
  const { order } = props;

  return (
    <HStack h="100%">
      <Box
        bg="green.100"
        color="green.600"
        borderRadius="50%"
        border="5px solid"
        borderColor="green.50"
        p="4px"
      >
        <SBShoppingBag02Icon width="18px" height="18px" stroke="currentColor" fill="transparent" />
      </Box>
      <Box>
        <Flex dir="row" justifyContent="space-between">
          <Tooltip hasArrow label={`Reference: ${order.reference}`}>
            <Text fontSize="x-small" color="gray.600" overflowX="clip">
              {order.reference}
            </Text>
          </Tooltip>
          <Badge fontWeight="normal" fontSize="x-small" h="fit-content">
            {order.state}
          </Badge>
        </Flex>
        <Text fontSize="x-small" color="gray.600">
          <Tooltip hasArrow label="Swftbox Tracking">
            {order.swftboxTracking}
          </Tooltip>
        </Text>
        <Text fontSize="x-small" color="gray.600">
          <Tooltip hasArrow label="Partner company name">
            {order.retailer.name}
          </Tooltip>
        </Text>
        <Text fontSize="x-small" color="gray.600">
          <Tooltip hasArrow label="Dispatch Zone">
            Dubai | Marina/JLT
          </Tooltip>
        </Text>
        <Tooltip hasArrow label={`Destination Address: ${getAddress(order.to)}`} overflow="hidden">
          <Text fontSize="x-small" color="gray.600" noOfLines={2}>
            {getAddress(order.to)}
          </Text>
        </Tooltip>
      </Box>
    </HStack>
  );
};
