import { Button, toast } from '@swftbox/style-guide';
import {
  JobOption,
  useCreateOrdersJob,
  useCreatePickupJob,
  useJobItemsSelectionContext,
  useJobTypeContext,
} from 'src/components/Particles';

interface SubmitButtonProps {
  close: () => void;
}

export function SubmitButton(props: SubmitButtonProps) {
  const { close } = props;
  const { jobType } = useJobTypeContext();
  const { orders, rtoOrders, retailers } = useJobItemsSelectionContext();
  const { createOrdersJob, loading: submittingOrdersJob } = useCreateOrdersJob();
  const { createPickupJob, loading: submittingPickupJob } = useCreatePickupJob();

  const onSubmitComplete = (message: string) => {
    toast.success(message);
    close();
  };

  const submitOrdersRoute = () => {
    if (!orders.size) {
      toast.error('No orders selected');
      return;
    }
    const orderIds = [...orders.values()].map((order) => order.id);
    void createOrdersJob({ onCompleted: onSubmitComplete, ids: orderIds });
  };

  const submitRtoOrdersRoute = () => {
    if (!rtoOrders.size) {
      toast.error('No orders selected');
      return;
    }
    const orderIds = [...rtoOrders.values()].map((order) => order.id);
    void createOrdersJob({ onCompleted: onSubmitComplete, ids: orderIds });
  };

  const submitPartnersRoute = () => {
    if (!retailers.size) {
      toast.error('No partners selected');
      return;
    }
    const ids = [...retailers.values()].map((retailer) => retailer.location.id);
    void createPickupJob({ onCompleted: onSubmitComplete, ids });
  };

  const submit = () => {
    const submitFunctions = {
      [JobOption.ROUTE]: submitOrdersRoute,
      [JobOption.RTO]: submitRtoOrdersRoute,
      [JobOption.PARTNER]: submitPartnersRoute,
    };
    submitFunctions[jobType]();
  };

  return (
    <Button
      colorScheme="blue"
      onClick={submit}
      isLoading={submittingOrdersJob || submittingPickupJob}
    >
      Submit
    </Button>
  );
}
