import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { type RetailerSubscription, useRetailerActiveAgreement } from 'src/components/Particles';
import linkIcon from 'src/assets/icons/Arrows/link.svg';
import SVG from 'react-inlinesvg';
import TermsContent from './TermsContent';

interface ShowTermsOfServiceProps {
  retailerId: string;
  retailerSubscription: RetailerSubscription;
}

export function ShowTermsOfService({ retailerId, retailerSubscription }: ShowTermsOfServiceProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getActiveAgreement, activeAgreement } = useRetailerActiveAgreement();

  useEffect(() => {
    if (retailerId) getActiveAgreement(retailerId);
  }, [retailerId]);
  return (
    <>
      {retailerId && activeAgreement && (
        <Button
          variant="link"
          onClick={onOpen}
          fontSize="text-md"
          fontWeight="medium"
          color="gray.900"
          py="3"
        >
          Terms of Service
          <Box pt="2px">
            <SVG src={linkIcon} width="12px" stroke="currentColor" />
          </Box>
        </Button>
      )}
      <Modal onClose={onClose} isOpen={isOpen} size="2xl" scrollBehavior="outside" isCentered>
        <ModalOverlay />
        <ModalContent top={0}>
          <ModalHeader>
            <Box pb="1vh">
              <Text>Terms of Service ("{retailerSubscription.plan.name} Plan")</Text>
              <Text> SWFTBOX DELIVERY SERVICES ("swftbox")</Text>
              <Text fontWeight="normal" fontSize="x-small" color="gray.500" fontStyle="italic">
                Updated Effective Date: June 1, 2024
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box height="400px" overflow="scroll" bg="gray.100" p="2" borderRadius="8px">
              <TermsContent termsContent={activeAgreement?.termsContent} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Stack spacing="3" w="100%" direction={['column', 'row']}>
              <Text fontWeight="medium" fontSize="x-small" color="gray.900" fontStyle="italic">
                Signed at{' '}
                {activeAgreement?.createdAt
                  ? format(new Date(activeAgreement?.createdAt), 'HH:mm MMM d, yyyy,')
                  : ''}{' '}
                by {activeAgreement?.user?.name}, ({activeAgreement?.user?.phone})
              </Text>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
