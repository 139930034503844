import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import SVG from 'react-inlinesvg';
import LeftIcon from 'src/assets/icons/Arrows/left.svg';
import RightIcon from 'src/assets/icons/Arrows/right.svg';
import DownIcon from 'src/assets/icons/Arrows/chervonDown.svg';
import { OrdersPageSort } from 'src/components/Pages/Orders/order.types';
import {
  OrderSortableFields,
  type OrderSortFilter,
  ElasticSearchSortOptions,
} from 'src/components/Particles';

function getOrderSort(sort: OrdersPageSort): OrderSortFilter {
  return {
    [OrdersPageSort.URGENT]: {
      order: ElasticSearchSortOptions.ASC,
      orderBy: OrderSortableFields.expectedDropDateStart,
    },
    [OrdersPageSort.OLDEST]: {
      order: ElasticSearchSortOptions.ASC,
      orderBy: OrderSortableFields.createdAt,
    },
  }[sort];
}
interface SmallPaginateProps {
  onPageChange: (selectedItem: number) => void;

  totalOrders: number;
  page: number;
  perPage: number;
  onSortChange?: (sort?: OrderSortFilter) => void;
  onPageSorting?: ({ by, order }: { by: any; order: ElasticSearchSortOptions }) => void;
  sortingOption?: {
    id: string;
    order: ElasticSearchSortOptions;
    by: string;
    name: string;
  }[];
}

export function SmallPaginate(props: Readonly<SmallPaginateProps>) {
  const { onPageChange, page, perPage, totalOrders, onSortChange, sortingOption, onPageSorting } =
    props;
  function updateSort({
    selectedSort,
    by,
    order,
  }: {
    selectedSort?: OrdersPageSort;
    by?: any;
    order?: ElasticSearchSortOptions;
  }) {
    if (onPageSorting && order) return onPageSorting({ by, order });
    if (onSortChange && selectedSort) return onSortChange(getOrderSort(selectedSort));
  }
  const handleToNumber = () => {
    const to = +page * +perPage;
    return to < totalOrders ? to : totalOrders;
  };

  return (
    <HStack justifyContent="end" gap="8">
      {!!onSortChange || !!onPageSorting ? (
        <Menu matchWidth>
          <MenuButton
            as={Text}
            whiteSpace="nowrap"
            _hover={{ backgroundColor: '#9090903b' }}
            transition="all 0.3s"
            bg="#fff"
            border="1px solid #D0D5DD"
            p="8px 12px"
            borderRadius="8px"
            boxShadow="xs"
            cursor="pointer"
          >
            <HStack>
              <Text>
                {+page * +perPage - +perPage || 1}-{handleToNumber()}{' '}
              </Text>
              <Text as="span" mx="3" textTransform="lowercase">
                of {totalOrders}
              </Text>
              <SVG src={DownIcon} width="8px" />
            </HStack>
          </MenuButton>
          <MenuList
            borderRadius="8px"
            bg="#fff"
            border="1px solid #EAECF8"
            padding="12px"
            zIndex={1}
          >
            {sortingOption ? (
              sortingOption.map((option) => (
                <MenuItem
                  pb="5"
                  key={option.id}
                  _hover={{ opacity: '0.5' }}
                  onClick={() => {
                    updateSort({ by: option.by, order: option.order });
                  }}
                >
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <>
                <MenuItem
                  pb="5"
                  _hover={{ opacity: '0.5' }}
                  onClick={() => {
                    updateSort({ selectedSort: OrdersPageSort.URGENT });
                  }}
                >
                  Most urgent first
                </MenuItem>
                <MenuItem
                  _hover={{ opacity: '0.5' }}
                  onClick={() => {
                    updateSort({ selectedSort: OrdersPageSort.OLDEST });
                  }}
                >
                  Oldest first
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      ) : (
        <Text
          as="span"
          mx="3"
          whiteSpace="nowrap"
          textTransform="lowercase"
          transition="all 0.3s"
          bg="#fff"
          border="1px solid #D0D5DD"
          p="8px 12px"
          borderRadius="8px"
          boxShadow="xs"
        >
          {+page * +perPage - +perPage || 1}-{handleToNumber()} of {totalOrders}
        </Text>
      )}

      <HStack gap="4">
        <Tooltip background="black" color="#fff" p="5" fontSize="12px" label="older" hasArrow>
          <Button
            onClick={() => {
              onPageChange(+page - 1);
            }}
            isDisabled={page - 1 === 0}
            _disabled={{ opacity: 0.5 }}
            bg="#fff"
            border="1px solid #D0D5DD"
            p="8px 12px"
            borderRadius="8px"
            boxShadow="xs"
          >
            <SVG src={LeftIcon} width="6px" stroke="currentColor" />
          </Button>
        </Tooltip>
        <Tooltip background="black" color="#fff" p="5" fontSize="12px" label="newer" hasArrow>
          <Button
            onClick={() => {
              onPageChange(+page + 1);
            }}
            isDisabled={+page * +perPage >= totalOrders}
            _disabled={{ opacity: 0.5 }}
            bg="#fff"
            border="1px solid #D0D5DD"
            p="8px 12px"
            borderRadius="8px"
            boxShadow="xs"
          >
            <SVG src={RightIcon} width="6px" />
          </Button>
        </Tooltip>
      </HStack>
    </HStack>
  );
}
