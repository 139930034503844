import { type SortOptions } from '../SharedTypes';

export enum PaymentType {
  CASH = 'CASH',
  CARD = 'CARD',
}

export enum PaymentMode {
  paymentOnDelivery = 'paymentOnDelivery',
  prePaid = 'prePaid',
}

export enum PaymentStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export interface Payment {
  id: string;
  retailerId: string;
  driverId: string;
  orderId: string;
  orderSwftboxTracking: string;
  amount: number;
  currency: string;
  type: PaymentType;
  receipt: string | null;
  retailerSettled: boolean;
  approvedBy: string | null;
  approvedAt: string | null;
  createdAt: string;
  retailer?: Retailer;
  driver?: Driver;
  order?: Order;
  status: PaymentStatus;
  provider: string;
}

export enum PaymentEntityType {
  CUSTOMER = 'CUSTOMER',
  RETAILER = 'RETAILER',
}

export enum PurchaseReferenceType {
  ORDER = 'ORDER',
  PLAN_SUBSCRIPTION = 'PLAN_SUBSCRIPTION',
  INVOICES = 'INVOICES',
}

export interface Purchase {
  amount: number;
  createdAt: string;
  currency: string;
  deletedAt: string;
  description: string;
  entityId: string;
  entityType: PaymentEntityType;
  id: string;
  paymentGateway: string;
  paymentMethod: string;
  redirectUrl: string;
  referenceId: string;
  referenceType: PurchaseReferenceType;
  transactionId: string;
  provider: string;
  updatedAt: string;
  success?: boolean;
  retailer?: {
    name: string;
  };
  customer?: {
    phone: string;
    name: string;
  };
  order?: {
    swftboxTracking: string;
  };
}

export interface Order {
  id: string;
  retailerTracking: string;
  reference: string;
}

export interface Driver {
  id: string;
  user: {
    name: string;
  };
}

export interface CreatePaymentInput {
  swftboxTracking: string;
  driverId: string;
  type: PaymentType;
  receipt?: File | string | null;
}

export interface UpdatePaymentInput extends Partial<CreatePaymentInput> {
  id: string;
  amount?: number;
  approved?: boolean;
  rejected?: boolean;
  status?: string;
}

export enum SettlementType {
  ORDER = 'ORDER',
  FUEL = 'FUEL',
  MAINTENANCE = 'MAINTENANCE',
  OTHER = 'OTHER',
  DRIVER_COMPENSATION = 'DRIVER_COMPENSATION',
}

export enum SubjectType {
  DRIVER = 'DRIVER',
  RETAILER = 'RETAILER',
}

export interface Settlement {
  id: string;
  subjectId: string;
  subjectType: SubjectType;
  type: SettlementType;
  amount: number;
  currency: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  receipt: string | null;
  approvedAt: string | null;
  approvedBy: string | null;
  driver: {
    id: string;
    user: {
      name: string;
    };
  };
  retailer: {
    id: string;
    name: string;
  };
  status: string;
}

export interface SettlementFilterArgs extends PaymentSortFilter {
  subjectId?: string;
  subjectType?: SubjectType;
  type?: SettlementType;
  page: number;
  perPage: number;
  status?: string;
  expenses?: boolean;
  settled?: boolean;
}

export interface CreateSettlementInput {
  subjectId: string;
  subjectType: SubjectType;
  type: SettlementType;
  amount: number;
  receipt?: File | string | null;
  description?: string | null;
}

export interface UpdateSettlementInput extends Partial<CreateSettlementInput> {
  id: string;
  approved?: boolean;
  rejected?: boolean;
}

export interface PaymentFilterArgs extends PaymentSortFilter {
  driverId?: string;
  retailerId?: string;
  type?: PaymentType | null;
  page: number;
  status?: string;
  perPage: number;
  approved?: boolean;
  startDate?: Date;
  endDate?: Date;
  orderSwftboxTracking?: string;
  provider?: boolean;
}

interface Retailer {
  id: string;
  name: string;
}

export enum PaymentSortableFields {
  createdAt = 'createdAt',
}

export interface PaymentSortFilter {
  order: SortOptions;
  orderBy: PaymentSortableFields;
}

export enum PurchaseStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface PurchaseFilterArgs {
  referenceId?: string;
  referenceType?: PurchaseReferenceType;
  entityId?: string;
  type?: PaymentType | null;
  entityType?: PaymentEntityType;
  startDate?: Date;
  endDate?: Date;
  provider?: string;
  status?: string;
  page: number;
  perPage: number;
  orderBy?: string;
  order?: 'DESC' | 'ASC';
}
