import { type SortOptions } from '../../SharedTypes';

export enum ReportType {
  CONSOLIDATED = 'CONSOLIDATED',
  DRIVER_CASH = 'DRIVER_CASH',
  PAYMENTS = 'PAYMENTS',
  SETTLEMENTS = 'SETTLEMENTS',
  MISSING_PAYMENTS = 'MISSING_PAYMENTS',
  PROPLEMATIC_ORDERS = 'PROPLEMATIC_ORDERS',
  USERS = 'USERS',
  CUSTOMER_BAGS = 'CUSTOMER_BAGS',
  PARTNER_PICKUPS = 'PARTNER_PICKUPS',
  ORDER_ITEMS = 'ORDER_ITEMS',
  TASKS = 'TASKS',
  PARTNER_CONFIG = 'PARTNER_CONFIG',
}

export interface ReportOptions {
  isClosed?: boolean;
  recepientEmail?: string;
  firstMile?: boolean;
  lastMile?: boolean;
  jobless?: boolean;
}

export interface GenerateReportInput {
  retailerId?: string;
  driverId?: string;
  assigneeId?: string;
  type: ReportType;
  fromDate?: string | null;
  toDate?: string | null;
  options?: ReportOptions;
}

export interface GenerateReportResponse {
  success: boolean;
  message: string;
}

export enum ReportStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  READY = 'READY',
}

export interface ReportsFilterArgs {
  page?: number;
  perPage?: number;
  orderBy: string;
  order: SortOptions;
  keyword?: string;
  retailerId?: string;
  driverId?: string;
  type?: ReportType;
  status?: ReportStatus;
  assigneeId?: string;
}

export interface Report {
  id: string;
  userId: string;
  retailerId: string;
  driverId: string;
  type: ReportType;
  status: ReportStatus;
  filePath: string;
  fromDate: string;
  toDate: string;
  options: ReportOptions;
  downloadUrl: string;
  createdAt: string;
  updatedAt: string;
  retailer: {
    id: string;
    name: string;
  };
  driver: {
    id: string;
    user: {
      name: string;
      id: string;
    };
  };
  user: {
    id: string;
    name: string;
  };
  assignee: {
    id: string;
    name: string;
  };
}

export interface ReportsQueryResponse {
  paginatedReports: { data: Report[]; total: number; pageCount: number; currentPage: number };
}
