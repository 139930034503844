import {
  Box,
  SBModal,
  Text,
  useDisclosure,
  IconButton,
  SBTrashIcon,
  toast,
} from '@swftbox/style-guide';
import { useDeleteCategory } from 'src/components/Particles';

interface DeleteCategoryProps {
  name: string;
  id: string;
}

export function DeleteCategory({ name, id }: DeleteCategoryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { callDeleteCategory } = useDeleteCategory();

  const onConfirm = () => {
    callDeleteCategory({
      payload: { id },
      onCompleted: ({ removeCategory: { message, success } }) => {
        if (success) {
          toast.success(message);
          onClose();
        }
      },
    });
  };
  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'red' }}
        aria-label="delete customer"
        icon={<SBTrashIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Category</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            Are You Sure About Deleting This Category ({name}) ?
          </Text>
        }
        size="lg"
        handleConfirm={onConfirm}
      />
    </>
  );
}
