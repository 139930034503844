import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_PICKUP_PROFILE, GET_PICKUP_PROFILES, REMOVE_PICKUP_PROFILE, UPDATE_PICKUP_PROFILE } from './pickupProfiles.graphql';
import { SavePickupProfileInput, PickupProfile, PickupProfileResponse } from './pickupProfiles.types';

export function usePickupProfilesQuery(retailerId: string) {
  const [getPickupProfiles, { data, loading, error, ...rest }] = useLazyQuery<{ pickupProfiles: PickupProfile[] }>(GET_PICKUP_PROFILES, {
    variables: { retailerId },
  });

  return {
    getPickupProfiles,
    pickupProfiles: data?.pickupProfiles ?? [],
    loading,
    error,
    ...rest,
  };
}

export const useCreatePickupProfileMutation = () => {
	const [mutate, { loading, error, reset }] = useMutation<
    { createPickupProfile: PickupProfileResponse },
    { createPickupProfileInput: SavePickupProfileInput }
  >(CREATE_PICKUP_PROFILE);

  async function createPickupProfile(payload: SavePickupProfileInput) {
    return mutate({
      variables: {
        createPickupProfileInput: payload,
      },
      refetchQueries: [
        'GetPickupProfiles',
      ],
    });
  }

  return {
    createPickupProfile,
    reset,
    loading,
    error,
  };
}

export const useUpdatePickupProfileMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { updatePickupProfile: PickupProfileResponse },
    { updatePickupProfileInput: SavePickupProfileInput }
  >(UPDATE_PICKUP_PROFILE);

  async function updatePickupProfile(payload: SavePickupProfileInput) {
    return mutate({
      variables: {
        updatePickupProfileInput: payload,
      },
      refetchQueries: [
        'GetPickupProfiles',
      ],
    });
  }

  return {
    updatePickupProfile,
    reset,
    loading,
    error,
  };
}

export const useRemovePickupProfileMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { removePickupProfile: { success: boolean, message: string } },
    { id: string }
  >(REMOVE_PICKUP_PROFILE);

  async function removePickupProfile(id: string) {
    return mutate({
      variables: {
        id,
      },
      refetchQueries: [
        'GetPickupProfiles',
      ],
    });
  }

  return {
    removePickupProfile,
    reset,
    loading,
    error,
  };
}
