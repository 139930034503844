import { useMutation } from '@apollo/client';
import { toast } from '@swftbox/style-guide';
import {
  FILTER_ORDERS,
  RESCHEDULE_ORDER,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type FilterQueryResponse } from './filterOrders.service';

interface RescheduleOrderResponse {
  message: string;
}
interface RescheduleOrderProps {
  customerDateStart: string | Date;
  customerDateEnd: string | Date;
  orderId: string;
}
export const useRescheduleOrder = (id: string) => {
  const [mutate, { loading, error }] = useMutation<
    { rescheduleOrder: RescheduleOrderResponse },
    {
      rescheduleOrderInput: RescheduleOrderProps;
    }
  >(RESCHEDULE_ORDER, {
    update: (cache, result, data) => {
      toast.success(result.data?.rescheduleOrder.message);
      const existingItems: FilterQueryResponse | null = cache.readQuery({
        query: FILTER_ORDERS,
        variables: QueriesStore.get('FILTER_ORDERS'),
      });
      const newItems = existingItems?.filterOrders.data.map((item) =>
        item.id === id
          ? {
              ...item,
              timing: {
                ...item.timing,
                expectedDropDateStart: data.variables?.rescheduleOrderInput.customerDateStart,
                expectedDropDateEnd: data.variables?.rescheduleOrderInput.customerDateEnd,
              },
            }
          : item
      );
      cache.writeQuery({
        query: FILTER_ORDERS,
        variables: QueriesStore.get('FILTER_ORDERS'),
        data: { filterOrders: { ...existingItems?.filterOrders, data: newItems } },
      });
    },
  });

  return {
    mutate,
    loading,
    error,
  };
};
