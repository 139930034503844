import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import { User } from 'src/components/Particles';
import { StyledBadge } from 'src/components/Atom/StyledBadge';
import { EditUser, UserDetails } from './Modal';

interface CustomersTableProps {
  users: User[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
}

export function UsersTable(props: CustomersTableProps) {
  const { users, page, total, onPageChange } = props;
  if (!users.length) return null;
  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead position="relative">
          <Tr bg="#fbfbfc">
            <Th>Name</Th>
            <Th>Phone</Th>
            <Th>Email</Th>
            <Th>Status</Th>
            <Th>Role</Th>
            <Th right="0">
              <SmallPaginate
                totalOrders={total}
                page={page ?? 1}
                perPage={50}
                onPageChange={onPageChange}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => {
            return (
              <Tr key={user.id}>
                <Td>
                  <Text>{user.name}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{user.phone ?? 'N / A'}</Text>
                </Td>
                <Td>
                  <Text>{user.email ?? 'N / A'}</Text>
                </Td>
                <Td>
                  <Text>{user.status ?? 'N / A'}</Text>
                </Td>
                <Td>
                  {user.roles?.map((role, i) => (
                    <StyledBadge
                      key={`role-${role}`}
                      style={{ marginLeft: i > 0 ? 5 : 0, marginBottom: 5 }}
                    >
                      {role}
                    </StyledBadge>
                  )) ?? 'N / A'}
                </Td>
                <Td alignItems="end">
                  <Flex gap={4} justifyContent="flex-end" w="100%">
                    <UserDetails user={user} />
                    <EditUser user={{ ...user, verifyEmail: !!user.emailVerifiedAt }} />
                    {/* <DeleteUser id={user.id} name={user.name} /> */}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
