import { Box, Button, SBAddIcon, SBModal, useDisclosure, toast } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { Permission, useAddRole } from 'src/components/Particles';
import RoleForm from './RoleForm';
import { roleCreateSchema } from './Role.Schema';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormValue {
  name: string;
  permissions: { name: string }[];
}
interface AddRoleProps {
  permissions: Permission[];
}
export function AddRole({ permissions }: AddRoleProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useAddRole({
    onCompleted: (data) => {
      handleCloseModal('Role added successfully');
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<FormValue>({
    resolver: yupResolver(roleCreateSchema),
    mode: 'all',
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: FormValue) => {
    mutate({
      variables: {
        createRoleInput: {
          name: data.name,
          permissions: data.permissions.map((permission) => Number(permission?.name)),
        },
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add New Role
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBAddIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={
          <RoleForm
            register={register}
            errors={errors}
            control={control}
            allPermissions={permissions}
          />
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
