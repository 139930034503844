import { useMutation } from '@apollo/client';
import { ADD_ORDERS_TO_EXIST_ROUTE_MUTATION, FILTERED_JOBS, GET_JOB_QUERY } from '../Queries';
import { type AddJobResponse } from './types';

interface AddOrdersToJobPayload {
  input: AddOrdersToRouteInput;
  onCompleted: (message: string) => void;
}

interface AddOrdersToRouteInput {
  orderIds: string[];
  routeId: string;
}

export function useAddOrdersToJob() {
  const [mutate, { loading, error }] = useMutation<
    { addOrdersToRoute: AddJobResponse },
    { addOrdersToRouteInput: AddOrdersToRouteInput }
  >(ADD_ORDERS_TO_EXIST_ROUTE_MUTATION);

  async function addOrdersToJob({ input, onCompleted }: AddOrdersToJobPayload) {
    await mutate({
      variables: {
        addOrdersToRouteInput: input,
      },
      onCompleted: (data) => {
        onCompleted(data.addOrdersToRoute.message);
      },
      refetchQueries: [
        {
          query: FILTERED_JOBS,
          variables: { page: 1, perPage: 10 },
        },
        {
          query: GET_JOB_QUERY,
          variables: { paginatedRoutesId: [input.routeId], page: 1, perPage: 1 },
        },
      ],
    });
  }

  return {
    addOrdersToJob,
    loading,
    error,
  };
}
