import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Button,
  SBModal,
  Select,
  Text,
  toast,
  useDisclosure,
  HelperText,
  Input
} from '@swftbox/style-guide';
import {
  PaymentType,
  SequenceStops,
  CustomerDropInput,
  useCustomerDropMutation,
} from 'src/components/Particles';
import { Controller, useForm } from 'react-hook-form';
import FileInput from 'src/components/Atom/FileInput/FileInput';

type SequenceStopWithId = SequenceStops & { id: string };

interface Props {
  sequenceStop: SequenceStopWithId;
  onModalClose: () => void;
}

export const CustomerDropModal: FC<Props> = ({ sequenceStop, onModalClose }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [fileInputLabel, setFileInputLabel] = useState<string>('receipt');
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setError,
  } = useForm<CustomerDropInput>({
    mode: 'all',
    defaultValues: {
      paymentType: PaymentType.CASH,
      collectedBags: sequenceStop.bagCollection || undefined,
    },
  });

  const { customerDrop, loading } = useCustomerDropMutation();

  const podOrders = useMemo(() => {
    return sequenceStop.dropOrders.filter(order => {
      return !sequenceStop.droppedOrders.includes(order.swftboxTracking) &&
        order.paymentMode === 'paymentOnDelivery';
    }).map(order => order.swftboxTracking);
  }, [sequenceStop]);

  const onSubmit = handleSubmit(async (data) => {
    const prepaidOrders = sequenceStop.dropOrders.filter(order => {
      return !sequenceStop.droppedOrders.includes(order.swftboxTracking) &&
        order.paymentMode === 'prePaid';
    }).map(order => order.swftboxTracking);

    if (prepaidOrders.length > 0) {
      await customerDrop({
        routeId: sequenceStop.routeId,
        sequence: sequenceStop.sequence,
        ordersSwftboxTracking: prepaidOrders,
        collectedBags: sequenceStop.bagCollection ? Number(data.collectedBags) : undefined,
      });
      toast.success(`Dropped ${prepaidOrders.length} prepaid order(s)`);
    }

    if (podOrders.length > 0) {
      await customerDrop({
        routeId: sequenceStop.routeId,
        sequence: sequenceStop.sequence,
        ordersSwftboxTracking: podOrders,
        paymentType: data.paymentType,
        receipt: data.receipt,
        collectedBags: (sequenceStop.bagCollection && !prepaidOrders.length) ? Number(data.collectedBags) : undefined,
      });
      toast.success(`Dropped ${podOrders.length} pay on delivery order(s)`);
    }

    reset();
    onClose();
    onModalClose();
  });

  const receiptValue = watch('receipt');
  const paymentType = watch('paymentType');
  const collectedBags = watch('collectedBags');

  useEffect(() => {
    if (receiptValue instanceof File) {
      setFileInputLabel(receiptValue.name);
    } else {
      setFileInputLabel('receipt');
    }

    if (!receiptValue) {
      setError('receipt', {
        type: 'required',
        message: 'Please select receipt to upload.',
      });
    }
  }, [receiptValue]);

  return (
    <SBModal
      isOpen={isOpen}
      onClose={() => { onClose(); onModalClose() }}
      closeOnEsc
      header={<Text>{`Drop ${sequenceStop.dropOrders.length - sequenceStop.droppedOrders.length}  orders at customer location`}</Text>}
      size="xl"
      body={
        <form onSubmit={onSubmit}>
          <Box marginTop={2}>
            <Stack gap={1}>
              <HStack>
                {sequenceStop.bagCollection &&
                  <Controller
                    name="collectedBags"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <FormLabel>Collected bags</FormLabel>
                        <Input type="number" max={sequenceStop.bagCollection!} value={value} onChange={onChange} placeholder={`Max: ${sequenceStop.bagCollection}`} />
                      </FormControl>
                    )}
                  />
                }
              </HStack>
              <HStack>
                {podOrders.length &&
                  <Controller
                    name="paymentType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <FormLabel>Payment type</FormLabel>
                        <Select onChange={onChange} value={value} required>
                          <option value={PaymentType.CASH}>Cash</option>
                          <option value={PaymentType.CARD}>Card</option>
                        </Select>
                      </FormControl>
                    )}
                  />
                }
              </HStack>
              <Controller
                name="receipt"
                shouldUnregister={paymentType === PaymentType.CASH}
                control={control}
                render={({ field: { onChange }, fieldState }) => paymentType === PaymentType.CARD ? (
                  <FormControl>
                    <FileInput fileInputLabel={fileInputLabel} setFile={onChange} file={receiptValue || undefined} />
                    {fieldState.error &&
                      <HelperText variant="failed">{fieldState.error.message}</HelperText>
                    }
                  </FormControl>
                ) : <div />}
              />
              <Box>
                <Button type="submit" disabled={loading || (!!sequenceStop.bagCollection && (!collectedBags || Number(collectedBags) < 0))}>Drop orders</Button>
              </Box>
            </Stack>
          </Box>
        </form>
      }
      bordered={false}
      footer={<div />}
    />
  );
}
