import { Outlet, RouteObject } from 'react-router-dom';
import { OperationLensPage } from 'src/components/Pages';
import { ErrorBoundary } from '../../ErrorBoundary';

export const OperationLensRoutes: RouteObject = {
  path: 'operation-lens',
  element: (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <OperationLensPage />,
    },
  ],
};
