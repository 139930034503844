import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Grid,
  Text,
} from '@swftbox/style-guide';
import { useCallback, useMemo } from 'react';
import {
  JobOption,
  useJobItemsSelectionContext,
  useJobTypeContext,
} from 'src/components/Particles';
import { ItemsGrid } from '../ItemsGrid';
import { SelectedOrderItem } from './SelectedOrderItem';
import { SelectedPartnerItem } from './SelectedPartnerItem';

interface SelectedItemsProps {
  view: string;
}

enum JobsDisplayNames {
  Orders = 'Orders',
  RTOOrders = 'RTO Orders',
  Partners = 'Partners',
}

export const SelectedItems = ({ view }: SelectedItemsProps) => {
  const { jobType } = useJobTypeContext();
  const { orders, rtoOrders, retailers } = useJobItemsSelectionContext();

  const getJobType = useCallback(() => {
    if (jobType === JobOption.ROUTE) return JobsDisplayNames.Orders;

    if (jobType === JobOption.RTO) return JobsDisplayNames.RTOOrders;

    if (jobType === JobOption.PARTNER) return JobsDisplayNames.Partners;
  }, [jobType]);

  const getItemsCount = () => {
    return {
      [JobOption.ROUTE]: orders.size,
      [JobOption.RTO]: rtoOrders.size,
      [JobOption.PARTNER]: retailers.size,
    }[jobType];
  };

  const selectedItems = useMemo(() => {
    return {
      [JobOption.ROUTE]: () =>
        [...orders.values()].map((order) => <SelectedOrderItem key={order.id} order={order} />),
      [JobOption.RTO]: () =>
        [...rtoOrders.values()].map((order) => <SelectedOrderItem key={order.id} order={order} />),
      [JobOption.PARTNER]: () =>
        [...retailers.values()].map((retailer) => (
          <SelectedPartnerItem key={retailer.location.id} retailer={retailer} />
        )),
    }[jobType]();
  }, [orders, retailers, jobType, rtoOrders]);

  return (
    <Grid templateColumns="repeat(1, 1fr)" my="4">
      <Accordion allowToggle borderRadius="12px">
        <AccordionItem
          fontWeight="semibold"
          fontSize="text-sm"
          border="1px solid  #EAECF0"
          borderRadius="8px"
          overflow="hidden"
        >
          <AccordionButton bg="#36C5F1" py="3" color="#fff" _hover={{ backgroundColor: 'black' }}>
            <Text fontSize="text-sm" flex="1" textAlign="left" fontWeight="semibold">
              ({getItemsCount()}) Selected {getJobType()}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <ItemsGrid view={view} itemList={selectedItems} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Grid>
  );
};
