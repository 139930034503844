import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, SBAddIcon, SBModal, toast, useDisclosure } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import {
  InvoiceLineItemType,
  useUpsertInvoiceSetting,
  type UpsertInvoiceSettingInput,
} from 'src/components/Particles';
import { lineItemUpsertSchema } from './InvoiceLineItem.Schema';
import LineItemForm from './LineItemForm';

export function AddInvoiceLineItem() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useUpsertInvoiceSetting({
    onCompleted: (data) => {
      handleCloseModal(data.upsertInvoiceSetting.message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm<UpsertInvoiceSettingInput>({
    resolver: yupResolver(lineItemUpsertSchema),
    mode: 'all',
    defaultValues: {
      vat: 5,
      type: InvoiceLineItemType.SERVICE,
    },
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: UpsertInvoiceSettingInput) => {
    mutate({
      variables: {
        upsertInvoiceSettingInput: {
          ...data,
          rate: Number(data.rate),
          vat: Number(data.vat),
        },
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add New Line Item
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBAddIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={
          <LineItemForm
            register={register}
            errors={errors}
            getValues={getValues}
            control={control}
          />
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
