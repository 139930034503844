import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { QueriesStore } from '../../../queriesStore';
import { GET_ITEMS_QUERY, type Item } from '../Queries';

interface QueryResponse {
  Order: { items: Item[] };
}

export function useItems() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_ITEMS_QUERY);

  const getItems = useCallback(
    (id: string) => {
      QueriesStore.update('GET_ITEMS_QUERY', { orderId: id });
      void query({ variables: { orderId: id } });
    },
    [query]
  );

  return {
    getItems,
    Items: data?.Order.items ?? [],
    loading,
    error,
  };
}
