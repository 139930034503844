import { useMutation } from '@apollo/client';
import { ME } from 'src/components/Particles/resolvers/auth/auth.graphql';
import { clientOptionType } from '../../../SharedTypes';
import {
  GET_CONFIGURATIONS_QUERY,
  type ConfigurationType,
  UPDATE_CONFIGURATIONS_QUERY,
} from '../Queries';

interface UpdateConfigurationResponse {
  message: string;
}

interface UpdateConfigurationProps {
  payload: { input: ConfigurationType };
  onCompleted: (
    data: { updateConfiguration: UpdateConfigurationResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateConfiguration() {
  const [mutate, { loading, error }] = useMutation<
    { updateConfiguration: UpdateConfigurationResponse },
    { input: ConfigurationType }
  >(UPDATE_CONFIGURATIONS_QUERY);

  async function callUpdateConfiguration({ payload, onCompleted }: UpdateConfigurationProps) {
    const { input } = payload;
    await mutate({
      variables: {
        input,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_CONFIGURATIONS_QUERY,
        },
      ],
    });
  }

  return {
    callUpdateConfiguration,
    loading,
    error,
  };
}
