import { useMutation } from '@apollo/client';
import type { CreateProcedureInput, UpdateProcedureInput } from './retailer.types';
import {
  GET_RETAILER,
  CREATE_RETAILER_PROCEDURE,
  UPDATE_RETAILER_PROCEDURE,
} from './retailers.graphql';
import { type MessageResponse } from '../SharedTypes';
import { removeTypeName } from '../../Helpers';

interface CreateProcedureProps {
  payload: CreateProcedureInput;
  onCompleted: (message: string) => void;
}

interface UpdateProcedureProps {
  payload: UpdateProcedureInput;
  onCompleted: (message: string) => void;
}

export function useCreateRetailerProcedureMutation() {
  const [mutate, { loading, error }] = useMutation<
    { createProcedure: MessageResponse },
    { createProcedureInput: CreateProcedureInput }
  >(CREATE_RETAILER_PROCEDURE);

  async function createProcedure({ payload, onCompleted }: CreateProcedureProps) {
    return mutate({
      variables: {
        createProcedureInput: removeTypeName(payload),
      },
      onCompleted: (data) => {
        if (onCompleted) onCompleted(data.createProcedure.message);
      },
      refetchQueries: [
        {
          query: GET_RETAILER,
          variables: { retailerId: payload.retailerId },
        },
      ],
    });
  }

  return {
    createProcedure,
    loading,
    error,
  };
}

export function useUpdateRetailerProcedureMutation() {
  const [mutate, { loading, error }] = useMutation<
    { updateProcedure: MessageResponse },
    { updateProcedureInput: UpdateProcedureInput }
  >(UPDATE_RETAILER_PROCEDURE);

  async function updateProcedure({ payload, onCompleted }: UpdateProcedureProps) {
    return mutate({
      variables: {
        updateProcedureInput: { ...removeTypeName(payload), retailerId: undefined },
      },
      onCompleted: (data) => {
        if (onCompleted) onCompleted(data.updateProcedure.message);
      },
      refetchQueries: [
        {
          query: GET_RETAILER,
          variables: { retailerId: payload.retailerId },
        },
      ],
    });
  }

  return {
    updateProcedure,
    loading,
    error,
  };
}
