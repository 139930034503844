const degToRad = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

type Coordinates = {
  latitude: number;
  longitude: number;
}

export function calculateDistance(location1: Coordinates, location2: Coordinates, abs?: boolean): number {
  if (!location1?.latitude || !location1?.longitude || !location2?.latitude || !location2?.longitude) return 0;
  const earthRadius = 6371; // Radius of the Earth in kilometers

  const dLat = degToRad(location2.latitude - location1.latitude);
  const dLon = degToRad(location2.longitude - location1.longitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(location1.latitude)) * Math.cos(degToRad(location2.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;

  if (abs) return Math.abs(distance);

  return distance;
}
