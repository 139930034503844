import uuid from 'react-uuid';
export const statusFilter = [
  {
    id: uuid(),
    label: 'All',
    value: 'all',
    background: '#ebf9fe',
  },
  {
    id: uuid(),
    label: 'Pending',
    value: 'pending',
    background: '#f0f5fe',
  },
  {
    id: uuid(),
    label: 'Started',
    value: 'started',
    background: '#fff5e6',
  },
  {
    id: uuid(),
    label: 'Blocked',
    value: 'blocked',
    background: '#fff5e6',
  },
  {
    id: uuid(),
    label: 'Done',
    value: 'done',
    background: '#fff5e6',
  },
  {
    id: uuid(),
    label: 'Cancelled',
    value: 'cancelled',
    background: '#fff5e6',
  },
];

export type TaskFilterKey =
  | 'all'
  | 'pending'
  | 'started'
  | 'blocked'
  | 'done'
  | 'cancelled';