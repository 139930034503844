import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useFulfillments } from 'src/components/Particles';
import FulfillmentsHeader from './Components/FulfillmentsHeader';
import FulfillmentsTable from './Components/FulfillmentsTable';
import style from './style.module.scss';

export const Fulfillments = () => {
  const { fulfillments, loading } = useFulfillments();
  return (
    <Box className={style.fulfillments}>
      <FulfillmentsHeader fulfillmentsCount={fulfillments.length} />
      <WithLoader isLoading={loading}>
        <FulfillmentsTable fulfillments={fulfillments} />
      </WithLoader>
      {!loading && fulfillments.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Fulfillments Found" />
        </Flex>
      )}
    </Box>
  );
};
