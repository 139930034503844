import { FC, useMemo } from 'react';
import { HStack } from '@swftbox/style-guide';
import StartJob from './StartJob';
import { Job, JobStatus } from 'src/components/Particles';
import CancelJob from './CancelJob';
import FinishJob from './FinishJob';

interface Props {
  job?: Job;
}

export const JobActions: FC<Props> = ({ job }) => {
  const canFinish = useMemo(() => {
    const finishedStops = job?.sequenceStops.filter((sequenceStop) => {
      return sequenceStop.completedCount + sequenceStop.failedCount === sequenceStop.totalCount;
    });

    return finishedStops?.length === job?.sequenceStops.length;
  }, [job]);

  return (
    <HStack marginRight="auto">
      {job?.status === JobStatus.ASSIGNED && <StartJob job={job} />}
      {!canFinish &&
        // @ts-expect-error
        [JobStatus.NOT_ASSIGNED, JobStatus.ASSIGNED, JobStatus.STARTED].includes(job?.status) && (
          <CancelJob job={job} />
        )}
      {canFinish && job?.status === JobStatus.STARTED && <FinishJob job={job} />}
    </HStack>
  );
};
