import { useMutation } from '@apollo/client';
import { DELETE_PLAN_CUSTOMER_QUERY, GET_SUBSCRIPTION_QUERY } from '../Subscription.graphql';
import { toast } from '@swftbox/style-guide';

interface QueryResponse {
  cancelSubscription: {
    message: string;
  };
}
interface QueryInput {
  updateSubscriptionInput: { id: string };
}

interface Props {
  retailerId: string;

  subscriptionId: string;
}
export function useCancelPlanRetailerQuery({ retailerId, subscriptionId }: Props) {
  const [mutate, rest] = useMutation<QueryResponse, QueryInput>(DELETE_PLAN_CUSTOMER_QUERY);
  const handleCancelPlan = () => {
    return mutate({
      variables: {
        updateSubscriptionInput: { id: subscriptionId },
      },
      onCompleted: ({ cancelSubscription }) => toast.success(cancelSubscription.message),
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_QUERY,
          variables: {
            retailerSubscribePlanInput: {
              retailerId,
            },
          },
        },
      ],
    });
  };
  return {
    handleCancelPlan,
    ...rest,
  };
}
