import React, { useEffect } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Button,
  Text,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useCities } from 'src/components/Particles';
import { FormLabel } from '@swftbox/style-guide';

export interface City {
  city: string;
  id: string;
  standardCityName: string;
}

interface SelectCitiesProps {
  selectedCities: string[];
  existingCitiesDropProfile: string[];
  onChange: (selected: string[]) => void;
}

const SelectCities: React.FC<SelectCitiesProps> = ({
  selectedCities,
  onChange,
  existingCitiesDropProfile,
}) => {
  const { data: cities, query: getCities } = useCities();

  useEffect(() => {
    void getCities();
  }, [getCities]);

  const handleCityChange = (standardCityName: string) => {
    if (selectedCities.includes(standardCityName)) {
      onChange(selectedCities.filter((abbr) => abbr !== standardCityName));
    } else {
      onChange([...selectedCities, standardCityName]);
    }
  };

  return (
    <Box width="40%" mt={4}>
      <FormLabel>Selected Cities </FormLabel>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon fontSize={'medium'} />}
          bg="#F6F6F6"
          px={4}
          py={2}
          width="100%"
          textAlign="left"
          height="40px"
        >
          <HStack spacing={4} p={4}>
            {selectedCities.length ? (
              selectedCities.map((standardCityName) => (
                <HStack
                  bg="#D2D2D2"
                  py="1"
                  px="3"
                  borderRadius="4"
                  w="max-content"
                  key={standardCityName}
                >
                  <Text fontSize="text-md" fontWeight={'bold'}>
                    {standardCityName}
                  </Text>
                </HStack>
              ))
            ) : (
              <Text fontSize="text-md" color={'#B9B9B9'}>
                No cities selected
              </Text>
            )}
          </HStack>
        </MenuButton>
        <MenuList bg="#F6F6F6" minW={'250px'} zIndex="9999">
          {cities?.map((city: City) => {
            const disbaled =
              existingCitiesDropProfile.includes(city.standardCityName) &&
              !selectedCities.includes(city.standardCityName);
            return (
              <MenuItem
                key={city.id}
                isDisabled={disbaled}
                onClick={() => {
                  handleCityChange(city.standardCityName);
                }}
                closeOnSelect={false}
              >
                <Flex width="100%" justifyContent="space-between" px={8} py={4}>
                  <Text
                    fontSize="text-md"
                    fontWeight={'bold'}
                    color={disbaled ? '#B9B9B9' : 'black'}
                  >
                    {city.standardCityName}
                  </Text>
                  <Checkbox
                    size={'md'}
                    isChecked={selectedCities.includes(city.standardCityName)}
                    onChange={() => {
                      handleCityChange(city.standardCityName);
                    }}
                    isDisabled={disbaled}
                  />
                </Flex>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SelectCities;
