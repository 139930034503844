import { type TimeSlot } from '../pickupProfiles';

export interface DropProfile {
  id: string;
  name: string;
  key: string;
  description: string;
  cutoffTime: string;
  billingSlab: number;
  profileType: DropProfileType;
  isDefault: boolean;
  isPrimary: boolean;
  isActive: boolean;
  typeDetails: StandardDropProfile | CustomDropProfile | ExpressDropProfile;
  cities: DropProfileCity[];
  excludedDays: Days[];
}

export enum DropProfileType {
  express = 'express',
  standard = 'standard',
  custom = 'custom',
}

export enum Days {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export interface DropProfileResponse {
  message: string;
  data: DropProfile;
}

export interface CreateDropProfileInput {
  retailerId: string;
  name: string;
  description: string;
  cutoffTime: string;
  billingSlab: number | null;
  drivingTimeInMins: number;
  maximumEndOffsetInMins: number;
  excludedDays?: Days[];
  cities?: DropProfileCity[] | null;
}

export interface UpdateDropProfileInput
  extends Omit<
    CreateDropProfileInput,
    'drivingTimeInMins' | 'maximumEndOffsetInMins' | 'retailerId'
  > {
  id: string;
  customProfile: CustomDropProfile | null;
  expressProfile: ExpressDropProfile | null;
  standardProfile: StandardDropProfile | null;
  cities: DropProfileCity[] | null;
  excludedDays?: Days[];
}

export interface CreateSuperDropProfileInput {
  retailerId: string;
  cities: string[];
}

export interface ExpressDropProfile {
  drivingTimeInMins: number | null;
  maximumEndOffsetInMins: number | null;
}

export interface StandardDropProfile {
  endOfDayTime: number | null;
}

export interface CustomDropProfile {
  timeSlots?: TimeSlot[] | null;
  leadTimeInHours?: number | null;
  timeSlotsDurationInDays?: number | null;
  preScheduleSlot?: TimeSlot | null;
}

export interface DropProfileCity {
  city: string;
  cutoffTime?: string;
  billingSlab: number | null;
  isPrimary?: boolean;
  isActive?: boolean;
  groupId?: string;
}

export interface UpdateSuperDropProfilesInput {
  groupId: string;
  isPrimary?: boolean;
  isActive?: boolean;
}

export interface SuperDropPorfile {
  groupId: string;
  cityNames: string[];
  profiles: DropProfile[];
  isPrimary: boolean;
  isActive: boolean;
}
