import uuid from 'react-uuid';
import { ElasticSearchSortOptions } from 'src/components/Particles';

export const sortingOptions: {
  id: string;
  by: any;
  order: ElasticSearchSortOptions;
  name: string;
}[] = [
  { id: uuid(), order: ElasticSearchSortOptions.DESC, name: 'Newest first', by: 'createdAt' },
  { id: uuid(), order: ElasticSearchSortOptions.ASC, name: 'Oldest first', by: 'createdAt' },
];
