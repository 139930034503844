import { useMutation } from '@apollo/client';
import {
  UPDATE_ATTACHMENT_STATUS,
  UpdateAttachmentStatusInput,
  UpdateAttachmentStatusQueryResponse,
} from '../Queries';

export function useUpdateAttachmentStatus() {
  const [mutate, { data, loading, error, ...rest }] = useMutation<
    UpdateAttachmentStatusQueryResponse,
    UpdateAttachmentStatusInput
  >(UPDATE_ATTACHMENT_STATUS);

  return {
    mutate,
    data,
    loading,
    error,
    ...rest,
  };
}
