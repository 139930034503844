import {
  Box,
  useDisclosure,
  toast,
  HStack,
  SBListCheckIcon,
  IconButton,
  SBModal,
  Text,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { TaskCategoryForm } from '../../Forms';
import SVG from 'react-inlinesvg';
import Edit01Icon from 'src/assets/icons/General/Edit01.svg';
import {
  useUpdateTaskCategory,
  type UpdateTaskCategoryInput,
  TaskCategory,
} from 'src/components/Particles';
import { yupResolver } from '@hookform/resolvers/yup';
import { CategorySchema } from '../../../Validations';

interface EditTaskCategoryProps {
  taskCategory: TaskCategory;
}
export function EditTaskCategory({ taskCategory }: EditTaskCategoryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { callUpdateTaskCategory } = useUpdateTaskCategory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateTaskCategoryInput>({
    mode: 'all',
    resolver: yupResolver(CategorySchema),
    defaultValues: {
      name: taskCategory.name,
      id: taskCategory.id,
      description: taskCategory.description ?? '',
      unitType: taskCategory.unitType ?? '',
    },
  });

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    onClose();
  };

  const onConfirm = (data: UpdateTaskCategoryInput) => {
    void callUpdateTaskCategory({
      payload: data,
      onCompleted: (data) => {
        handleCloseModal(data.updateTaskCategory.message);
      },
    });
  };

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit task category"
        icon={<SVG src={Edit01Icon} stroke="currentColor" width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBListCheckIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <HStack justifyContent="space-between">
              <Text>Edit Task Category</Text>
            </HStack>
          </Box>
        }
        size="md"
        body={<TaskCategoryForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
