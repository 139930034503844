import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../../SharedTypes';
import { START_JOB_MUTATION } from '../../Queries';

export function useStartJobMutation() {
  const [mutate, { loading, error }] = useMutation<
    { startRoute: MessageResponse },
    { id: string }
  >(START_JOB_MUTATION);

  function startJob(id: string) {
    return mutate({
      variables: {
        id,
      },
      refetchQueries: [
        'PaginatedRoutes',
      ],
    });
  }

  return {
    startJob,
    loading,
    error,
  };
}
