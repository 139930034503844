import { Autocomplete } from '@swftbox/style-guide';
import { useEffect, useState, type FocusEvent } from 'react';
import {
  InvoiceLineItemType,
  SelectOptionType,
  UpdateInvoiceLineItemInput,
  useInvoicesSettingsQuery,
} from 'src/components/Particles';

interface Props {
  excludedOptions: string[];
  handleSelectLineItem: (lineItemCode: UpdateInvoiceLineItemInput) => void;
}
export const LineItemSelectInput = ({ excludedOptions, handleSelectLineItem }: Props) => {
  const { getInvoicesSettings, invoicesSettings, loading } = useInvoicesSettingsQuery();
  function onAutocompleteFocus(event: FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  const [selectedLineItem, setSelectedLineItem] = useState<string>();

  const onLineItemSelected = (option: SelectOptionType | null) => {
    console.log({ option });

    if (option == null) return;
    setSelectedLineItem(option.value);
    if (handleSelectLineItem) {
      const newLineItemData = invoicesSettings.find((lineItem) => lineItem.code === option.value);
      if (!newLineItemData) return;

      handleSelectLineItem({
        code: newLineItemData.code,
        rate: newLineItemData.rate,
        vat: newLineItemData.vat,
        description: newLineItemData.description,
        quantity: 0,
        type: InvoiceLineItemType.SERVICE,
        taxableAmount: 0,
      });
    }
  };

  useEffect(() => {
    getInvoicesSettings();
  }, []);

  let existingLineItemsOptions: SelectOptionType[] =
    invoicesSettings
      ?.filter((lineItem) => !excludedOptions.includes(lineItem.code))
      .map((lineItem) => {
        return { label: lineItem.code, value: lineItem.code };
      }) || [];

  useEffect(() => {
    existingLineItemsOptions =
      invoicesSettings
        ?.filter((lineItem) => !excludedOptions.includes(lineItem.code))
        .map((lineItem) => {
          return { label: lineItem.code, value: lineItem.code };
        }) || [];
  }, [excludedOptions, invoicesSettings]);

  return (
    <Autocomplete
      onFocus={onAutocompleteFocus}
      //@ts-ignore
      onChange={onLineItemSelected}
      options={existingLineItemsOptions}
      placeholder="Add Existing Line Item"
      isMulti={false}
      value={
        existingLineItemsOptions?.filter((option) => option.value === selectedLineItem) ?? null
      }
      isClearable={true}
    />
  );
};
