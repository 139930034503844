import {
  Autocomplete,
  Box,
  FormControl,
  FormLabel,
  HelperText,
  HStack,
  Input,
  Stack,
  Text,
  toast,
} from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FileInput from 'src/components/Atom/FileInput/FileInput';
import { DriverSelectInput } from 'src/components/Organisms/DriverSelectInput';
import {
  type Order,
  PaymentType,
  useOrderModalContext,
  PaymentMode,
} from 'src/components/Particles';
import {
  type CustomerForceDropInput,
  useForceDeliver,
} from 'src/components/Particles/resolvers/orders';
import SVG from 'react-inlinesvg';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';
import { buildPastWeekDateArray } from '../../../Utils/datetime.helper';

export function ForceDelivery({ formRef, handleClose }: { formRef: any; handleClose: any }) {
  const { data: order } = useOrderModalContext() as {
    forceActionsIsOpen: boolean;
    data: Order;
  };
  const [fileInputLabel, setFileInputLabel] = useState<string>('receipt');
  const { forceDeliverOrders } = useForceDeliver();
  const [paymentLabel, setPaymentLabel] = useState<string>('');
  const { handleSubmit, control, watch } = useForm<CustomerForceDropInput>({
    mode: 'all',
    defaultValues: {
      paymentType: undefined,
    },
  });

  const deliverDateOptions = buildPastWeekDateArray(5);
  const paymentTypeOptions = [
    { value: '', label: 'Prepaid' },
    { value: PaymentType.CASH, label: 'Cash' },
    { value: PaymentType.CARD, label: 'Card' },
    { value: 'paymentLink', label: 'Payment Link' },
  ];
  const receiptValue = watch('receipt');
  const paymentType = watch('paymentType');

  useEffect(() => {
    if (receiptValue instanceof File) {
      setFileInputLabel(receiptValue.name);
    } else {
      setFileInputLabel('receipt');
    }
  }, [receiptValue]);

  const existingPayment =
    order?.payment && ['APPROVED', 'PENDING'].includes(order?.payment?.status);

  function onConfirm({
    driverId,
    paymentAmount,
    deliverDateTime,
    receipt,
    transactionId,
  }: CustomerForceDropInput) {
    const type =
      paymentType === 'paymentLink'
        ? PaymentType.CARD
        : paymentType === ''
        ? undefined
        : paymentType;
    void forceDeliverOrders({
      customerForceDropInput: {
        swftboxTracking: order?.swftboxTracking,
        driverId,
        paymentType: type,
        paymentAmount: paymentAmount && !existingPayment ? Number(paymentAmount) : undefined,
        receipt,
        deliverDateTime,
        transactionId: Number(transactionId),
        paymentMode: !paymentType ? PaymentMode.prePaid : PaymentMode.paymentOnDelivery,
      },
      onCompleted: (message) => {
        if (message) {
          toast.success(message);
          handleClose();
        }
      },
    });
  }

  return (
    <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={4}>
      <Box marginTop={2}>
        <Stack gap={1}>
          <HStack>
            <Controller
              name="driverId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <DriverSelectInput onChange={onChange} value={value || null} />
                </FormControl>
              )}
            />
            <Controller
              name="deliverDateTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Deliver date"
                  options={deliverDateOptions}
                  value={deliverDateOptions.find((date) => date.value === value) ?? ''}
                  onChange={(val) => {
                    // @ts-expect-error, it's just a falsy warning
                    onChange(val?.value);
                  }}
                />
              )}
            />
          </HStack>
          <HStack>
            <Controller
              name="paymentType"
              control={control}
              disabled={!!existingPayment}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Payment type"
                  options={paymentTypeOptions}
                  isDisabled={!!existingPayment}
                  value={paymentTypeOptions.find((type) => type.value === value) ?? ''}
                  onChange={(val) => {
                    // @ts-expect-error, it's just a falsy warning
                    setPaymentLabel(val?.value);
                    onChange(val?.value);
                  }}
                />
              )}
            />
          </HStack>
          {paymentType && (
            <Controller
              name="paymentAmount"
              disabled={!!existingPayment}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>Payment Amount</FormLabel>
                  <Input
                    type="number"
                    defaultValue={order.paymentAmount}
                    onChange={onChange}
                    placeholder="Confirm payment amount"
                    required
                  />
                </FormControl>
              )}
            />
          )}
          {paymentLabel === 'paymentLink' && (
            <Controller
              name="transactionId"
              disabled={!!existingPayment}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>Transaction ID</FormLabel>
                  <Input type="number" onChange={onChange} placeholder="Confirm transaction ID" />
                </FormControl>
              )}
            />
          )}
          <Controller
            name="receipt"
            shouldUnregister={paymentType === PaymentType.CASH}
            control={control}
            disabled={!!existingPayment}
            render={({ field: { onChange }, fieldState }) =>
              paymentType === PaymentType.CARD && paymentLabel !== 'paymentLink' ? (
                <FormControl>
                  <FileInput
                    fileInputLabel={fileInputLabel}
                    setFile={onChange}
                    file={receiptValue ?? undefined}
                  />
                  {fieldState.error && (
                    <HelperText variant="failed">{fieldState.error.message}</HelperText>
                  )}
                </FormControl>
              ) : (
                <div />
              )
            }
          />
          {!!existingPayment && (
            <HStack
              border={`1px solid #fbc70091`}
              boxShadow="xs"
              borderRadius="8px"
              p="8px"
              w="100%"
              bg="#fbc70091"
              marginBlock={3}
            >
              <SVG src={InfoIcon} color="#fbc70091" stroke="#000" width={'40px'} height={'40px'} />
              <Text fontWeight="semibold" fontSize="text-sm">
                There's an existing PENDING or APPROVED payment for this order. You can proceed, but
                please proceed with care and ensure that the payment was correctly handled
              </Text>
            </HStack>
          )}
        </Stack>
      </Box>
      <button ref={formRef} type="submit" style={{ display: 'none' }}>
        Test
      </button>
    </Stack>
  );
}
