import { addDays } from 'date-fns';

import {
  type FilterAction,
  type FulfillmentOrdersFilters,
  type FulFillmentOrdersOrganizingFilters,
  type OrganizedFilterAction,
} from './types';
import { handleAddRemoveArray } from '../../helper';

const handleAddRemoveElement = (array: string[], ele: string): string[] | undefined => {
  let newArray: string[] = [];
  if (ele === 'clear') return undefined;
  if (array.includes(ele)) {
    newArray = array.filter((existedEle) => ele !== existedEle);
  } else {
    newArray = [...array, ele];
  }
  return newArray.length ? newArray : undefined;
};

const filtersReducer = (state: FulfillmentOrdersFilters, action: FilterAction) => {
  switch (action.type) {
    case 'ORDER_PAGE_CHANGE':
      return {
        ...state,
        page: action.payload.page,
      };

    case 'ORDER_SORT_CHANGE':
      return {
        ...state,
        sortFilter: action.payload.sortFilter ?? {},
      };

    default:
      return state;
  }
};

const organizedFiltersReducer = (
  state: FulFillmentOrdersOrganizingFilters,
  action: OrganizedFilterAction
) => {
  switch (action.type) {
    case 'ORDER_DATE_CHANGE':
      return {
        ...state,
        dateFilter: action.payload.dateFilter ?? {},
      };
    case 'ORDERS_CITIES_CHANGE':
      return {
        ...state,
        toCity: handleAddRemoveElement(state.toCity ?? [], action.payload.city),
      };
    case 'ORDERS_ZONES_CHANGE':
      return {
        ...state,
        dispatchZone: handleAddRemoveElement(state.dispatchZone ?? [], action.payload.zone),
      };
    case 'ORDERS_TYPES_CHANGE':
      return {
        ...state,
        or: {
          dropProfileKey: handleAddRemoveArray(
            state?.or?.dropProfileKey ?? [],
            action.payload.type
          ),
        },
      };
    case 'CLEAR_ORGANIZING_FILTERS':
      return {
        dateFilter: {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          isSelected: false,
          key: 'selection',
        },
      };
    default:
      return state;
  }
};

export const rootReducer = (state: any, action: any) => ({
  filters: filtersReducer(state.filters, action),
  organizedFilters: organizedFiltersReducer(state.organizedFilters, action),
});
