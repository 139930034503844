import { Grid } from '@swftbox/style-guide';
import { type ReactNode } from 'react';
import { GRID_VIEW } from '../../../Helper';

interface ItemsGridProps {
  view: string;
  itemList: ReactNode;
}

export const ItemsGrid = (props: ItemsGridProps) => {
  const { view, itemList } = props;

  return (
    <Grid
      templateColumns={view === GRID_VIEW ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'}
      gap="2"
      justifyContent="space-between"
      gridAutoRows="1fr"
      fontWeight="light"
    >
      {itemList}
    </Grid>
  );
};
