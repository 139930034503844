import { Button, CloseButton, Flex, HStack, Spacer, Text, toast } from '@swftbox/style-guide';
import { useKeydown, usePushOrderToDispatch } from 'src/components/Particles';
import { useFulfillmentOrderSelection } from 'src/components/Particles/Contexts';
import { openOrdersShippingLabels } from '../../Orders/Utils/pdf.helper';
import styles from '../style.module.scss';

export function SelectionOptions() {
  const { selectedOrders, unselectAll } = useFulfillmentOrderSelection();
  function goToPrintScreen() {
    void openOrdersShippingLabels(Array.from(selectedOrders.values()));
  }
  useKeydown('Escape', unselectAll);
  const { pushOrderToDispatch, loading } = usePushOrderToDispatch();
  const onPushToDispatch = () => {
    pushOrderToDispatch({
      payload: { ids: [...selectedOrders.keys()] },
      onCompleted: (message) => {
        toast.success(message);
        unselectAll();
      },
    });
  };
  if (!selectedOrders.size) return null;
  return (
    <HStack align="center" className={styles.tableSelection}>
      <HStack gap="10">
        <Text fontWeight="semibold" fontSize="text-sm">
          Selected Orders ({selectedOrders.size})
        </Text>
        <HStack>
          <Button
            mx={1}
            variant="link"
            color="#fff"
            onClick={onPushToDispatch}
            isLoading={loading}
            textDecor="underline"
          >
            Push to Dispatch
          </Button>
          <Button
            mx={1}
            variant="link"
            color="#fff"
            onClick={goToPrintScreen}
            textDecor="underline"
          >
            Shipping labels
          </Button>
        </HStack>
      </HStack>

      <HStack>
        <Button mx={1} variant="link" color="#fff" onClick={unselectAll} textDecor="underline">
          Deselect
        </Button>
      </HStack>
    </HStack>
  );
}
