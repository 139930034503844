import { Box, HStack, Td, Tr, VStack } from '@swftbox/style-guide';
import { memo } from 'react';
import { ElementLoader } from 'src/components/Atom';

const OrderCellLoading = ({ rowCount = 5 }: { rowCount?: number }) => {
  return (
    <>
      {Array.from({ length: rowCount }, (v, i) => {
        return (
          <Tr key={i}>
            <Td px="3">
              <HStack gap="2">
                <ElementLoader h="17px" w="17px" />
                <Box>
                  <ElementLoader w="170px" mb="1" />
                  <ElementLoader w="170px" />
                  <ElementLoader h="16px" w="170px" my="2" borderRadius="8px" />
                  <HStack>
                    <ElementLoader h="16px" w="75px" />
                    <ElementLoader h="16px" w="1px" />
                    <ElementLoader h="16px" w="25px" />
                  </HStack>
                </Box>
              </HStack>
            </Td>
            <Td px="3">
              <HStack>
                <ElementLoader h="25px" w="40px" borderRadius="4px" />
                <ElementLoader h="25px" w="180px" borderRadius="4px" />
              </HStack>
              <HStack my="2">
                <ElementLoader h="25px" w="80px" borderRadius="4px" />
                <ElementLoader h="25px" w="110px" borderRadius="4px" />
              </HStack>
              <HStack>
                <ElementLoader h="25px" w="40px" borderRadius="4px" />
                <ElementLoader h="25px" w="80px" borderRadius="4px" />
              </HStack>
            </Td>

            <Td px="3">
              <HStack justifyContent="start" minW="210px" mb="2">
                <ElementLoader w="45px" />
                <ElementLoader w="140px" />
              </HStack>
              <HStack justifyContent="start" minW="210px" mb="2">
                <ElementLoader w="45px" />
                <ElementLoader w="140px" />
              </HStack>
              <HStack justifyContent="start" minW="210px" mb="2">
                <ElementLoader w="45px" />
                <ElementLoader w="140px" />
              </HStack>
              <HStack justifyContent="start" minW="210px">
                <ElementLoader w="45px" />
                <ElementLoader w="140px" />
              </HStack>
            </Td>
            <Td px="3">
              <Box border="1px solid #D0D5DD" boxShadow="xs" borderRadius="8px" p="8px" w="280px">
                <VStack alignItems="start" spacing="2">
                  <ElementLoader width="140px" />
                  <HStack justifyContent="space-between" w="100%" position="relative" spacing="0">
                    <HStack spacing="1">
                      <ElementLoader w="13px" rounded="50%" />
                      <ElementLoader />
                    </HStack>
                    <ElementLoader w="100px" />
                  </HStack>
                  <HStack spacing="1" position="relative" alignItems="start">
                    <ElementLoader w="15px" h="15px" />
                    <Box>
                      <ElementLoader w="180px" mb="1" />
                      <ElementLoader w="180px" />
                    </Box>
                  </HStack>
                </VStack>
              </Box>
            </Td>
            <Td px="3">
              <Box border="1px solid #D0D5DD" boxShadow="xs" borderRadius="8px" p="8px" w="280px">
                <VStack alignItems="start" spacing="2">
                  <ElementLoader width="140px" />
                  <HStack justifyContent="space-between" w="100%" position="relative" spacing="0">
                    <HStack spacing="1">
                      <ElementLoader w="13px" rounded="50%" />
                      <ElementLoader />
                    </HStack>
                    <ElementLoader w="100px" />
                  </HStack>
                  <HStack spacing="1" position="relative" alignItems="start">
                    <ElementLoader w="15px" h="15px" />
                    <Box>
                      <ElementLoader w="180px" mb="1" />
                      <ElementLoader w="180px" />
                    </Box>
                  </HStack>
                </VStack>
              </Box>
            </Td>
            <Td textAlign="end" px="3">
              <ElementLoader ml="auto" width="5px" height="5px" rounded="50%" />
              <ElementLoader ml="auto" width="5px" height="5px" rounded="50%" my="1" />
              <ElementLoader ml="auto" width="5px" height="5px" rounded="50%" />
            </Td>
          </Tr>
        );
      })}
    </>
  );
};

export default memo(OrderCellLoading);
