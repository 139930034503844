import { Autocomplete, Box, Button, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import { useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import { type Order, useFailReturn } from 'src/components/Particles/resolvers/orders';
import { getRtoReasonsOptions } from '../../helper';
import { useState } from 'react';
import { OrdersStatusFilter } from '../../../order.types';

export function FailReturn() {
  const [reason, setReason] = useState<string>();
  const { failReturnIsOpen, data: order } = useOrderModalContext() as {
    failReturnIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    dispatch({ type: 'CLOSE_FAIL_RETURN' });
  };

  const { failReturnOrders, loading } = useFailReturn();

  function onConfirm() {
    if (!reason) {
      toast.error('You must choose a reason!');
      return;
    }
    void failReturnOrders({
      payload: {
        failedReturnInput: {
          orderIds: [order.id],
          reason,
        },
      },
      onCompleted: ({ failReturnOrders }) => {
        if (failReturnOrders.message) {
          handleCloseModal(failReturnOrders.message);
        }
      },
    });
  }

  if (!(order.isReverse && order.status === OrdersStatusFilter.RTN_REQUESTED)) {
    return null;
  }

  return (
    <>
      <SBModal
        isOpen={failReturnIsOpen}
        onClose={handleCloseModal}
        type="general"
        header={
          <Box pb="1vh">
            <Text>Mark As Failed Return</Text>
          </Box>
        }
        body={
          <Box>
            <Autocomplete
              onChange={(option) => {
                //  @ts-expect-error dummy
                setReason(option.value);
              }}
              options={getRtoReasonsOptions()}
              placeholder="Choose Return Failing reason..."
            />
          </Box>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={() => handleCloseModal}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
        // @ts-expect-error dummy
        scrollBehavior="outside"
      />
    </>
  );
}
