export enum IntegrationType {
  MAGENTO = 'MAGENTO',
  WOOCOMMERCE = 'WOOCOMMERCE',
  SHOPIFY = 'SHOPIFY',
  SWFTBOX = 'SWFTBOX',
  STORFOX = 'STORFOX',
}

export interface Integration {
  id: string;
  retailerId: string;
  type: IntegrationType;
  apiKey: string;
  name: string;
  isActive: boolean;
  cashOnDeliveryPaymentNames: string[];
  serviceApiKey?: string | null;
  warehouseLabel?: string | null;
}

export interface SaveIntegrationInput {
  id?: string;
  name: string;
  retailerId: string;
  type: IntegrationType;
  cashOnDeliveryPaymentNames?: string[];
  serviceApiKey?: string | null;
  warehouseLabel?: string | null;
  isActive: boolean;
}
