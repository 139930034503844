import {
  SBCrossCircleIcon,
  SBSpinnerAltIcon,
  SBCheckIcon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  SBMenuDotsVerticalIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  HStack,
  MenuItem,
  IconWText,
  SBMarkerPin06Icon,
  Box,
  SBHourglassIcon,
  SBHourglassEndIcon,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import { ITask, TaskStatus } from 'src/components/Particles';
import { AddUpdateTask, CancelTask, DeleteTask, UpdateTaskStatus } from './Modal';

interface CustomersTableProps {
  tasks: ITask[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
  isAdmin?: boolean;
}

export function TasksTable(props: CustomersTableProps) {
  const { tasks, page, total, onPageChange, isAdmin } = props;
  if (!tasks.length) return null;
  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead position="relative">
          <Tr bg="#fbfbfc">
            <Th>Partner Name</Th>
            <Th>Task Category</Th>
            <Th>Quantity</Th>
            <Th>Due Date</Th>
            <Th>Started Time</Th>
            <Th>Status</Th>
            <Th>Assignee</Th>
            <Th right="0">
              <SmallPaginate
                totalOrders={total}
                page={page ?? 1}
                perPage={50}
                onPageChange={onPageChange}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {tasks.map((task) => {
            return (
              <Tr key={task.id}>
                <Td>
                  <Text>{task.retailer.name}</Text>
                </Td>
                <Td>
                  <Text>{task.taskCategory.name}</Text>
                </Td>
                <Td>
                  <Text>{task.unitCount}</Text>
                </Td>
                <Td>
                  <Text>{format(new Date(task.dueDate), 'yyyy-MM-dd, HH:mm')}</Text>
                </Td>
                <Td>
                  <Text>
                    {task.startDate ? format(new Date(task.startDate), 'yyyy-MM-dd, HH:mm') : '-'}
                  </Text>
                </Td>
                <Td>
                  <Text>{task.status}</Text>
                </Td>
                <Td>
                  <Text>{task.assignee.name}</Text>
                </Td>
                <Td display="flex" justifyContent="center">
                  {task.status == 'STARTED' && (
                    <HStack>
                      <UpdateTaskStatus taskId={task.id} status={TaskStatus.BLOCKED}>
                        <Box>
                          <Tooltip label="Block Task" hasArrow>
                            <Button variant="solid" minW="auto">
                              <SBCrossCircleIcon width="12px" />
                            </Button>
                          </Tooltip>
                        </Box>
                      </UpdateTaskStatus>
                      <UpdateTaskStatus taskId={task.id} status={TaskStatus.COMPLETED}>
                        <Box>
                          <Tooltip label="Complete Task" hasArrow>
                            <Button variant="solid" minW="auto">
                              <SBCheckIcon width="12px" />
                            </Button>
                          </Tooltip>
                        </Box>
                      </UpdateTaskStatus>
                    </HStack>
                  )}
                  {task.status == 'PENDING' && (
                    <UpdateTaskStatus taskId={task.id} status={TaskStatus.STARTED}>
                      <Box>
                        <Tooltip label="Start Task" hasArrow>
                          <Button variant="solid" minW="auto">
                            <SBSpinnerAltIcon width="12px" />
                          </Button>
                        </Tooltip>
                      </Box>
                    </UpdateTaskStatus>
                  )}
                  {task.status == 'BLOCKED' && (
                    <UpdateTaskStatus taskId={task.id} status={TaskStatus.STARTED}>
                      <Box>
                        <Tooltip label="Resume Task" hasArrow>
                          <Button variant="solid" minW="auto">
                            <SBHourglassEndIcon width="12px" />
                          </Button>
                        </Tooltip>
                      </Box>
                    </UpdateTaskStatus>
                  )}
                </Td>
                <Td textAlign="end">
                  <Menu>
                    <MenuButton as="button">
                      <SBMenuDotsVerticalIcon width="15px" />
                    </MenuButton>
                    <MenuList>
                      {task.status == 'STARTED' && (
                        <>
                          <UpdateTaskStatus taskId={task.id} status={TaskStatus.BLOCKED}>
                            <MenuItem>
                              <IconWText
                                text="Block Task"
                                spacing="20px"
                                aria-label="block task"
                                Icon={<SBCrossCircleIcon width="15px" />}
                              />
                            </MenuItem>
                          </UpdateTaskStatus>
                          <UpdateTaskStatus taskId={task.id} status={TaskStatus.COMPLETED}>
                            <MenuItem>
                              <IconWText
                                text="Complete Task"
                                spacing="20px"
                                aria-label="Complete task"
                                Icon={<SBCheckIcon width="15px" />}
                              />
                            </MenuItem>
                          </UpdateTaskStatus>
                        </>
                      )}
                      {task.status == 'PENDING' && (
                        <UpdateTaskStatus taskId={task.id} status={TaskStatus.STARTED}>
                          <MenuItem>
                            <IconWText
                              text="Start Task"
                              spacing="20px"
                              aria-label="start task"
                              Icon={<SBSpinnerAltIcon width="15px" />}
                            />
                          </MenuItem>
                        </UpdateTaskStatus>
                      )}
                      {task.status == 'BLOCKED' && (
                        <UpdateTaskStatus taskId={task.id} status={TaskStatus.STARTED}>
                          <MenuItem>
                            <IconWText
                              text="Resume Task"
                              spacing="20px"
                              aria-label="resume task"
                              Icon={<SBHourglassEndIcon width="15px" />}
                            />
                          </MenuItem>
                        </UpdateTaskStatus>
                      )}
                      {isAdmin && (
                        <>
                          <AddUpdateTask task={task} />
                          <CancelTask taskId={task.id} />
                          <DeleteTask taskId={task.id} />
                        </>
                      )}
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
