import * as yup from 'yup';
import 'yup-phone-lite';

export const CreateDriverSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup.string().email('Please Enter a valid email').trim(),
    phone: yup
      .string()
      .phone(undefined, 'Please Enter a valid phone number')
      .required('Phone number is required'),
    name: yup
      .string()
      .min(2, 'must be at least 2 characters')
      .max(50, 'must be at most 50 characters')
      .trim()
      .required('Name is required'),
  }),
  vehicle: yup
    .object()
    .shape({
      plateNumber: yup
        .string()
        .max(50, 'must be at most 50 characters')
        .optional()
        .trim()
        .nullable(),
      contractor: yup
        .string()
        .max(50, 'must be at most 50 characters')
        .optional()
        .trim()
        .nullable(),
      model: yup.string().max(50, 'must be at most 50 characters').optional().trim().nullable(),
      type: yup.string().max(50, 'must be at most 50 characters').optional().trim().nullable(),
    })
    .optional(),
  employmentType: yup
    .string()
    .oneOf(['FREELANCE', 'SWFTBOX', 'OPERATOR', 'PRIMEX'])
    .required('Please select the Employment type'),
  gender: yup.string().oneOf(['MALE', 'FEMALE', 'OTHER']).required('Please select the gender'),
  operator: yup.number().positive().min(1).optional(),
  birthday: yup.date().typeError('Invalid date format').required(),
  licenseNumber: yup.string().max(50, 'must be at most 50 characters').trim().optional(),
  nationalId: yup.string().max(50, 'must be at most 50 characters').trim().optional(),
});
