import { Box, IconWText, SBEditIcon, SBModal, Spinner, useDisclosure } from '@swftbox/style-guide';
import { useEffect } from 'react';
import { useInvoiceQuery, useRetailerQuery } from 'src/components/Particles';
import { Invoice } from '../Invoice';

interface InvoiceProps {
  id: string;
}

export const EditInvoiceModal = ({ id }: InvoiceProps) => {
  const { query, invoice, loading } = useInvoiceQuery({ id });
  const { getRetailer, retailer, loading: gettingRetailer } = useRetailerQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getInvoice = () => {
    void query();
  };
  useEffect(() => {
    if (isOpen) getInvoice();
  }, [isOpen]);

  useEffect(() => {
    if (!invoice) return;
    getRetailer(invoice?.retailerId);
  }, [invoice]);
  return (
    <>
      <IconWText
        text="Edit Invoice"
        spacing="20px"
        Icon={<SBEditIcon width="15px" />}
        onClick={onOpen}
        aria-label="Edit Invoice"
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<></>}
        size="6xl"
        body={
          loading || gettingRetailer || !invoice || !retailer ? (
            <Box>
              <Spinner />
            </Box>
          ) : (
            <Invoice retailer={retailer} invoice={invoice} onClose={onClose} />
          )
        }
        bordered={false}
        footer={<></>}
      />
    </>
  );
};
