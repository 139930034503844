import {
  Box,
  Button,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
  SBAddIcon,
  IconWText,
} from '@swftbox/style-guide';
import { type Order, useCancelOrders } from 'src/components/Particles/resolvers/orders';

interface CancelOrderProps {
  order: Order;
}

export function CancelOrder({ order }: CancelOrderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cancelOrder, loading } = useCancelOrders();

  function onCancelConfirm() {
    void cancelOrder({
      payload: { ids: [order.id] },
      onCompleted: ({ cancelOrder }) => {
        if (cancelOrder.message) {
          toast.success(cancelOrder.message);
          onClose();
        }
      },
    });
  }

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconWText
          text="Cancel"
          spacing="20px"
          color="red.normal"
          aria-label="cancel order"
          Icon={<SBAddIcon width="15px" />}
        />
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Cancel Order</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to cancel order{' '}
            <Text as="span" fontWeight="semibold">
              {order.swftboxTracking}
            </Text>{' '}
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onCancelConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
