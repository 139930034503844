import { Flex, Box, Badge, Spacer, Text } from '@swftbox/style-guide';

interface PurchasesHeaderProps {
  purchasesCount: number;
}

export function PurchasesHeader(props: PurchasesHeaderProps) {
  const { purchasesCount } = props;
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Payment Transactions
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {purchasesCount} transactions
          </Badge>
        </Text>
      </Box>
      <Spacer />
    </Flex>
  );
}
