import { useMutation } from '@apollo/client';
import { type FilterQueryResponse } from '../../orders';
import { FILTER_ORDERS } from '../../orders/orders.graphql';
import { QueriesStore } from '../../queriesStore';
import { type CreateRouteIdsInput } from '../../SharedTypes';
import { ADD_ORDERS_ROUTE_MUTATION, FILTERED_JOBS } from '../Queries';
import { type QueryJobsResponse } from './Jobs.service';

interface CreatePickupJobPayload {
  ids: string[];
  onCompleted: (message: string) => void;
}

interface AddJobResponse {
  message: string;
  data: {
    id: string;
    status: string;
    name: string;
    driverId: string;
    driver: {
      user: {
        name: string;
      };
    };
  };
}

export function useCreateOrdersJob() {
  const [mutate, { loading, error }] = useMutation<
    { createOrdersRoute: AddJobResponse },
    { createOrdersRouteInput: CreateRouteIdsInput }
  >(ADD_ORDERS_ROUTE_MUTATION);

  async function createOrdersJob({ ids, onCompleted }: CreatePickupJobPayload) {
    await mutate({
      variables: {
        createOrdersRouteInput: { ids },
      },

      update: (cache, result, context) => {
        if (result.data && onCompleted) {
          onCompleted(result.data.createOrdersRoute.message);
        }
        const existingOrders: FilterQueryResponse | null = cache.readQuery({
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        });
        const newOrders = existingOrders?.filterOrders.data.map((order) =>
          context.variables?.createOrdersRouteInput.ids.includes(order.id)
            ? {
                ...order,
                routeId: result.data?.createOrdersRoute.data.id,
              }
            : order
        );
        cache.writeQuery({
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
          data: { filterOrders: { ...existingOrders?.filterOrders, data: newOrders } },
        });

        const existingJobs: QueryJobsResponse | null = cache.readQuery({
          query: FILTERED_JOBS,
          variables: QueriesStore.get('JOBS'),
        });
        cache.writeQuery({
          query: FILTERED_JOBS,
          variables: QueriesStore.get('JOBS'),
          data: {
            filterRoutes: {
              ...existingJobs?.filterRoutes,
              data: [
                ...(existingJobs?.filterRoutes?.data ?? []),
                result.data?.createOrdersRoute.data,
              ],
            },
          },
        });
      },
    });
  }

  return {
    createOrdersJob,
    loading,
    error,
  };
}
