import { useCallback, useMemo, useState } from 'react';
import { JobOption, JobTypeContext } from '../../../Contexts/';
import { type IProviderProps } from '../../types';

export const JobTypeProvider = ({ children }: IProviderProps) => {
  const [jobType, setJobType] = useState<JobOption>(JobOption.PARTNER);

  const selectJobType = useCallback(
    (selectedJobType: JobOption) => {
      setJobType(selectedJobType);
    },
    [jobType]
  );

  const contextValue = useMemo(() => {
    return { jobType, selectJobType };
  }, [jobType, selectJobType]);

  return <JobTypeContext.Provider value={contextValue}>{children}</JobTypeContext.Provider>;
};
