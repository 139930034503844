import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { format } from 'date-fns';
import { type CarriyoIntegrationCarrier } from 'src/components/Particles';
import { EditCarrier } from './Modal';

interface CarriersTableProps {
  carriers: CarriyoIntegrationCarrier[];
}

export function CarriersTable(props: CarriersTableProps) {
  const { carriers } = props;

  if (!carriers.length) return null;

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead>
          <Tr bg="#fbfbfc">
            <Th>Acc. Name</Th>
            <Th>Acc. Id</Th>
            <Th>Created At</Th>
            <Th>Is Active</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {carriers.map((carrier) => {
            return (
              <Tr key={carrier.id}>
                <Td>
                  <Text>{carrier.accountName}</Text>
                </Td>
                <Td>
                  <Text>{carrier.accountId}</Text>
                </Td>
                <Td>
                  <Text>
                    {' '}
                    {format(new Date(carrier.createdAt), 'HH:MM\xa0\xa0\xa0MMM dd, yyyy')}
                  </Text>
                </Td>
                <Td>
                  <Text>{carrier.isActive ? 'Yes' : 'No'}</Text>
                </Td>
                <Td>
                  <Flex gap={4} justifyContent="flex-end">
                    <EditCarrier carrier={carrier} />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
