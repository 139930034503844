import {
  Box,
  Text,
  Input,
  Autocomplete,
  Button,
  FormLabel,
  HStack,
  Stack,
  FormControl,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { ShiftTime } from 'src/components/Particles';

interface Props {
  shiftTime?: ShiftTime;
  updating: boolean;
  onClose: () => void;
  onSaveForm: (data: ShiftTime) => void;
}

const dayOptions = [
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
];

export const ShiftTimeForm = (props: Props) => {
  const { onSaveForm, shiftTime, onClose, updating } = props;
  const { handleSubmit, reset, control } = useForm<ShiftTime>({
    mode: 'all',
    defaultValues:{
      day: shiftTime?.day,
      from: shiftTime?.from,
      to: shiftTime?.to
    }
  });

  const onSubmit = handleSubmit(async (data) => {
    onSaveForm(data);
    reset();
    onClose && onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <Box mb={5}>
        <Text fontWeight={'bold'}>{shiftTime ? 'Edit shift time' : 'Create new shift time'}</Text>
      </Box>
      <Stack gap={4}>
        <HStack>
          <Controller
            name="day"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                label="Day"
                options={dayOptions}
                value={dayOptions.find((day) => day.value === value) || ''}
                onChange={(val) => {
                  // @ts-expect-error, it's just a falsy warning
                  onChange(val?.value);
                }}
              />
            )}
          />
        </HStack>
        <HStack>
          <Controller
            name="from"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>From</FormLabel>
                <Input type="time" value={value || ''} onChange={onChange} required />
              </FormControl>
            )}
          />
          <Controller
            name="to"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>To</FormLabel>
                <Input type="time" value={value || ''} onChange={onChange} required />
              </FormControl>
            )}
          />
        </HStack>
        <Stack spacing="3" w="100%" direction={['column', 'row']}>
          <Button variant="outline" w="100%" onClick={onClose} isDisabled={updating}>
            Cancel
          </Button>
          <Button w="100%" type="submit" isDisabled={updating}>
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
