import { FC } from 'react';
import { Badge, BadgeProps } from '@swftbox/style-guide';

export interface StyledBadgeProps extends BadgeProps {}

export const StyledBadge: FC<StyledBadgeProps> = ({ children, ...props }) => {
  return (
    <Badge
      fontSize="x-small"
      p="4px 12px"
      fontWeight="medium"
      borderRadius="8px"
      colorScheme="gray"
      letterSpacing="1px"
      {...props}
    >
      {children}
    </Badge>
  )
}