import { SBInput, Stack, Grid, SBTextArea } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { type AddTaskCategoryInput } from 'src/components/Particles';

interface TaskCategoryFormProps {
  register: UseFormRegister<any>;
  errors?: FieldErrors<AddTaskCategoryInput>;
  viewModeOnly?: boolean;
}
export function TaskCategoryForm(props: TaskCategoryFormProps) {
  const { register, errors, viewModeOnly } = props;

  return (
    <Grid gridTemplate={{ base: 'auto 100% / 100%' }} justifyContent="space-between">
      <Stack gap={4}>
        <SBInput
          label="Category Name*"
          placeholder="Add category's Name"
          {...register('name')}
          error={errors?.name?.message}
          isDisabled={viewModeOnly}
        />
        <SBTextArea
          rows={3}
          placeholder="Add category's Description"
          {...register('description')}
          label="Category Description"
          error={errors?.description?.message}
          isDisabled={viewModeOnly}
        />

        <SBInput
          label="Unit Type"
          placeholder="Add category's Unit Type"
          {...register('unitType')}
          error={errors?.unitType?.message}
          isDisabled={viewModeOnly}
        />
      </Stack>
    </Grid>
  );
}
