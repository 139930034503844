import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import AddCategory from './Modal/AddCategory/AddCategory';

interface CategoriesHeaderProps {
  categoriesCount: number;
}
export default function CategoriesHeader(props: CategoriesHeaderProps) {
  const { categoriesCount } = props;
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Categories
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {categoriesCount <= 1 ? `${categoriesCount} Category` : `${categoriesCount} Categories`}
          </Badge>
        </Text>
      </Box>
      <Spacer />
      <AddCategory />
    </Flex>
  );
}
