import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import { ADD_CATEGORY_QUERY, GET_CATEGORIES_QUERY, type AddCategoryInput } from '../Queries';

interface AddCategoryResponse {
  message: string;
}
interface CallAddCategoryProps {
  payload: AddCategoryInput;
  onCompleted: (
    data: { createCategory: AddCategoryResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useAddCategory() {
  const [mutate, { loading, error }] = useMutation<
    { createCategory: AddCategoryResponse },
    { input: AddCategoryInput }
  >(ADD_CATEGORY_QUERY);

  async function callAddCategory({ payload, onCompleted }: CallAddCategoryProps) {
    await mutate({
      variables: {
        input: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_CATEGORIES_QUERY,
        },
      ],
    });
  }

  return {
    callAddCategory,
    loading,
    error,
  };
}
