import { useMemo } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { NotificationTrigger, NotificationTriggerInput } from './notificationsTriggers.type';
import {
  UPDATE_NOTIFICATIONS_TRIGGERS,
  GET_NOTIFICATION_TRIGGERS,
} from './notificationsTriggers.graphql';

interface Response {
  message: string;
  success: boolean;
}

export function useUpdateNotificationsTriggersMutation() {
  const [mutate, { data, loading, error, reset }] = useMutation<
    { updateNotificationTriggers: Response },
    { notificationTriggerInput: NotificationTriggerInput }
  >(UPDATE_NOTIFICATIONS_TRIGGERS);

  async function updateNotificationsTriggers(payload: NotificationTriggerInput) {
    return mutate({
      variables: {
        notificationTriggerInput: payload,
      },
      refetchQueries: ['GetNotificationTriggers'],
    });
  }

  return {
    updateNotificationsTriggers,
    reset,
    retailer: data?.updateNotificationTriggers,
    loading,
    error,
  };
}

export function useGetNotificationTriggersQuery(retailerId: string) {
  const [getNotificationTriggers, { data, ...rest }] = useLazyQuery<
    { notificationTriggers: { trigger: keyof typeof NotificationTrigger }[] },
    { retailerId: string }
  >(GET_NOTIFICATION_TRIGGERS, {
    variables: {
      retailerId,
    },
  });

  const triggers = useMemo(() => {
    const triggers = Object.keys(NotificationTrigger).reduce(
      (acc, trigger) => ({
        ...acc,
        [trigger]: false,
      }),
      {}
    ) as Record<keyof typeof NotificationTrigger, boolean>;

    data?.notificationTriggers.forEach((item) => {
      triggers[item.trigger] = true;
    });

    return triggers;
  }, [data]);

  return {
    getNotificationTriggers,
    triggers,
    ...rest,
  };
}
