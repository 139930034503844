import { useMemo } from 'react';
import { Button, HStack } from '@swftbox/style-guide';
import { RetailerSelectInput } from 'src/components/Organisms';

interface PurchaseFilterProps {
  onFilterChange: (key: string, filterValue: string) => void;
  setRetailerId: (id: string | null) => void;
  retailerId?: string | null;
  status?: string;
  referenceType?: string;
}

export function Filters(props: PurchaseFilterProps) {
  const { onFilterChange, setRetailerId, retailerId, ...rest } = props;

  const statuses = useMemo(() => ([
    { label: 'All', value: '' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Completed', value: 'COMPLETED' },
  ]), []);

  const referenceTypes = useMemo(() => ([
    { label: 'All', value: '' },
    { label: 'Subscription', value: 'PLAN_SUBSCRIPTION' },
    { label: 'Payment Link', value: 'ORDER' },
  ]), []);

  return (
    <HStack p={5}>
      <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2">
        <HStack
          border="1px solid #D0D5DD;"
          h="40px"
          lineHeight="40px"
          borderRadius="8px"
          shadow="xs"
          overflow="hidden"
          spacing="0"
        >
          {statuses.map((filter, index) => (
            <Button
              p="8px 12px"
              fontSize="x-small"
              key={index}
              variant="grouped"
              bg={rest.status === filter.value ? '#63c3ec' : 'transparent'}
              color={rest.status === filter.value ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => {
                onFilterChange('status', filter.value);
              }}
            >
              {filter.label}
            </Button>
          ))}
        </HStack>
      </HStack>

      <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2">
        <HStack
          border="1px solid #D0D5DD;"
          h="40px"
          lineHeight="40px"
          borderRadius="8px"
          shadow="xs"
          overflow="hidden"
          spacing="0"
        >
          {referenceTypes.map((filter, index) => (
            <Button
              p="8px 12px"
              fontSize="x-small"
              key={index}
              variant="grouped"
              bg={rest.referenceType === filter.value ? '#63c3ec' : 'transparent'}
              color={rest.referenceType === filter.value ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => {
                onFilterChange('referenceType', filter.value);
              }}
            >
              {filter.label}
            </Button>
          ))}
        </HStack>
      </HStack>

      <RetailerSelectInput
        value={retailerId ?? null}
        noLabel={true}
        onChange={(selectedRetailerId) => {
          setRetailerId(selectedRetailerId);
        }}
      />
    </HStack>
  );
}
