import {
  Grid,
  GridItem,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import React from 'react';
import { Feature } from 'src/components/Particles';
import { AddFeature, EditFeature, RemoveFeature } from '../Modals';

interface FeaturesListProps {
  features: Feature[];
}

const toSentenceCase = (str: string): string => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const FeaturesList = React.memo(({ features }: FeaturesListProps) => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem
        colSpan={12}
        boxShadow="sm"
        bg="#fff"
        border=" 1px solid #EAECF0"
        borderRadius="12px"
      >
        <Grid templateColumns={`repeat(12, 1fr)`} gap="6" p="6">
          <GridItem colSpan={6}>
            <Text fontSize="text-md" fontWeight="medium" color="gray.700">
              Order Features
            </Text>
          </GridItem>
          <GridItem colSpan="auto" gridColumnStart={12}>
            <HStack>
              <AddFeature />
            </HStack>
          </GridItem>
        </Grid>
        <TableContainer whiteSpace="normal">
          <Table variant="striped" overflow="auto">
            <Thead>
              <Tr bg="#fbfbfc">
                <Th left="0">Feature Name</Th>
                <Th left="0">Billing</Th>
                <Th left="0">Category</Th>
                <Th left="0">Description</Th>
                <Th left="0">Is Configurable?</Th>
                <Th left="0"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {features.map((feature) => (
                <Tr key={feature.id}>
                  <Td left="0" top="0">
                    <Text>{feature?.name}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{feature?.billing}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{toSentenceCase(feature?.category || '')}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{feature?.description}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{feature?.isConfigurable ? 'Yes' : 'No'}</Text>
                  </Td>
                  <Td right="0">
                    <HStack>
                      <EditFeature feature={feature} />
                      <RemoveFeature feature={feature} />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </GridItem>
    </Grid>
  );
});
