import { Button, HStack } from '@swftbox/style-guide';
import { useState } from 'react';
import { RetailerSelectInput } from 'src/components/Organisms';
import { UserSelectInput } from 'src/components/Organisms/AssigneeSelectInput';
import { statusFilter, type TaskFilterKey } from './TasksFilter.helper';

interface TasksFilterProps {
  onFilterChange: (filterKey: TaskFilterKey) => void;
  retailerId?: string | null;
  setRetailerId: (id: string | null) => void;
  assigneeId?: string | null;
  setAssigneeId: (id: string | null) => void;
  isAdmin: boolean;
}

export function TasksFilter(props: TasksFilterProps) {
  const { onFilterChange, retailerId, setRetailerId, assigneeId, setAssigneeId, isAdmin } = props;
  const [active, setActive] = useState<string>();

  return (
    <>
      <HStack spacing="2" m="5" className="Numbers" flexWrap="wrap" gap="2">
        <HStack
          border="1px solid #D0D5DD;"
          h="40px"
          lineHeight="40px"
          borderRadius="8px"
          shadow="xs"
          overflow="hidden"
          spacing="0"
        >
          <>
            {statusFilter.map((filterItem) => (
              <Button
                p="8px 12px"
                fontSize="x-small"
                key={filterItem.id}
                variant="grouped"
                bg={active === filterItem.value ? '#63c3ec' : 'transparent'}
                color={active === filterItem.value ? '#fff' : 'gray.900'}
                _focus={{ opacity: 0.85 }}
                _hover={{ opacity: 0.85 }}
                onClick={() => {
                  onFilterChange(filterItem.value as TaskFilterKey);
                  setActive(filterItem.value);
                }}
              >
                {`${filterItem.label}`}
              </Button>
            ))}
          </>
        </HStack>
      </HStack>
      <HStack spacing="2" m="5" gap="2" w={'40%'}>
        <RetailerSelectInput
          noLabel
          value={retailerId ?? null}
          onChange={(selectedRetailerId) => {
            setRetailerId(selectedRetailerId);
          }}
        />
        {isAdmin && (
          <UserSelectInput
            noLabel
            value={assigneeId ?? null}
            onChange={(selectedAssigneeId) => {
              setAssigneeId(selectedAssigneeId);
            }}
          />
        )}
      </HStack>
    </>
  );
}
