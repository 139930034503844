import { useLazyQuery } from '@apollo/client';
import { ORDER_TIMELINE } from './orders.graphql';
import { type OrderTimeline } from './orders.types';

interface QueryResponse {
  orderTimeline: OrderTimeline[];
}
interface QueryArgs {
  orderId: string;
}

export function useOrderTimelinesQuery() {
  const [getOrderTimeline, { data, loading }] = useLazyQuery<QueryResponse, QueryArgs>(
    ORDER_TIMELINE
  );

  return {
    getOrderTimeline,
    orderTimeline: data?.orderTimeline ?? [],
    loading,
  };
}
