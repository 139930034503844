export enum CommentType {
  FAILED = 'FAILED',
  RTO = 'RTO',
  DELIVERED = 'DELIVERED',
  OPS = 'OPS',
  RETAILER = 'RETAILER',
  DRIVER = 'DRIVER',
  MISSED = 'MISSED',
  CS_FLAG = 'CS_FLAG',
  MERCHANT_FLAG = 'MERCHANT_FLAG',
  STARRED_FLAG = 'STARRED_FLAG',
  OVERSIZED = 'OVERSIZED',
}
export interface Comment {
  id: string;
  userId: string;
  orderId: string;
  comment: string;
  type?: CommentType;
  attachments: string[];
  user: {
    id: string;
    name: string;
  };
  userType: string;
  createdAt?: string;
}

export interface SaveCommentInput extends Omit<Comment, 'id' | 'userId' | 'userType' | 'user'> {
  id?: string | undefined;
}
