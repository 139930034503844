import {
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  SBInput,
  Stack,
  Text,
} from '@swftbox/style-guide';
import {
  type FieldErrors,
  type UseFormRegister,
  type Control,
  useFieldArray,
} from 'react-hook-form';
import { Permission, ucFirst } from 'src/components/Particles';

interface FormValue {
  name: string;
  permissions: { name: string }[];
}

interface RoleFormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
  allPermissions: Permission[];
  control: Control<any>;
  disableNameEdit?: boolean;
}

export default function RoleForm(props: RoleFormProps) {
  const { register, errors, control, allPermissions, disableNameEdit } = props;

  const { fields: permissionFields, replace: setPermissions } = useFieldArray({
    control,
    name: 'permissions',
  });
  const handlePermissionCheckboxChange = (selectedPermissions: string[]) => {
    const selectedPermissionsData = allPermissions.filter((permission) =>
      selectedPermissions.includes(String(permission.id))
    );
    setPermissions(selectedPermissionsData.map((permission) => ({ name: String(permission.id) })));
  };

  return (
    <Stack gap={4}>
      <SBInput
        label="Role Name*"
        placeholder="Add Role's Name"
        {...register('name')}
        error={errors.name?.message}
        isDisabled={disableNameEdit}
      />
      <FormControl>
        <FormLabel fontSize={14} marginBottom={2}>
          Permissions*{' '}
          <Text as="span" fontSize={'smaller'} color={'#E53E3E'}>
            {errors.permissions?.message}
          </Text>
        </FormLabel>

        <CheckboxGroup
          value={permissionFields.map((field) => field.name)}
          onChange={handlePermissionCheckboxChange}
        >
          <Flex
            maxWidth="100%"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {allPermissions?.map((permission) => (
              <Checkbox
                key={`permission-${permission.id}`}
                value={String(permission.id)}
                marginBottom={2}
                minWidth="200px"
              >
                <Text fontSize={14}>
                  {permission.name
                    .split('-')
                    .map((str) => ucFirst(str))
                    .join(' ')}
                </Text>
              </Checkbox>
            ))}
          </Flex>
        </CheckboxGroup>
      </FormControl>
    </Stack>
  );
}
