import { gql } from '@apollo/client';

export const GET_INTEGRATIONS = gql`
  query RetailerIntegrations($retailerId: UUID!) {
    integrationConfigs(retailerId: $retailerId) {
      id
      retailerId
      type
      apiKey
      isActive
      name
      cashOnDeliveryPaymentNames
      serviceApiKey
      warehouseLabel
    }
  }
`;

export const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegrationConfig($updateIntegrationConfigInput: UpdateIntegrationConfigInput!) {
    updateIntegrationConfig(updateIntegrationConfigInput: $updateIntegrationConfigInput) {
      data {
        name
        apiKey
        serviceApiKey
        warehouseLabel
        id
        isActive
        retailerId
        type
      }
      message
    }
  }
`;

export const CREATE_INTEGRATION = gql`
  mutation CreateIntegration($createIntegrationConfigInput: CreateIntegrationConfigInput!) {
    createIntegrationConfig(createIntegrationConfigInput: $createIntegrationConfigInput) {
      message
    }
  }
`;
