import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { FILTERED_JOBS } from '../Queries';
import { type JobsProps, type JobsResponse } from './types';

export interface QueryJobsResponse {
  filterRoutes: JobsResponse;
}

export function useJobs({ page = 1, perPage = 10, ...rest }: JobsProps) {
  const [query, { data, loading, error }] = useLazyQuery<QueryJobsResponse>(FILTERED_JOBS, {
    variables: { page, perPage, ...rest },
    pollInterval: 30000,
  });

  useEffect(() => {
    QueriesStore.update('JOBS', { page, perPage, ...rest });
  }, [page, perPage, rest]);

  const getJobs = useCallback(
    (variables: Partial<JobsProps>) => {
      void query({
        variables,
      });
    },
    [query]
  );

  const jobsData = data?.filterRoutes;

  const { data: jobs, pageCount = 0, total = 0 } = jobsData || {};

  return {
    getJobs,
    jobs: jobs ?? [],
    pageCount,
    total,
    loading,
    error,
  };
}
