import { Box, FormLabel, HStack, VStack, Text } from '@swftbox/style-guide';
import { ReactNode, memo } from 'react';

interface Props {
  title?: string;
  description?: string;
  children: ReactNode;
  fullWidthTitle?: boolean;
  noBorder?: boolean;
  fullWidthSection?: ReactNode;
}
export const SectionRow = memo(
  ({ title, description, children, fullWidthTitle, noBorder, fullWidthSection }: Props) => {
    return (
      <VStack
        py="20px"
        borderBottom="1px solid"
        borderColor={noBorder ? 'transparent' : 'gray.200'}
        _first={{ paddingTop: 0 }}
        _last={{ paddingBottom: 0, borderColor: 'transparent' }}
        gap="4"
        spacing={0}
        w="100%"
      >
        <HStack
          align="start"
          gap="8"
          spacing={0}
          flexWrap={fullWidthTitle ? 'wrap' : 'nowrap'}
          w="100%"
        >
          {title && (
            <Box w={fullWidthTitle ? '100%' : '200px'} flexShrink="0">
              <FormLabel color="gray.700" fontWeight="semibold" fontSize="text-sm">
                {title}
              </FormLabel>
              {description && (
                <Text color="gray.500" fontSize="text-sm">
                  {description}
                </Text>
              )}
            </Box>
          )}
          <VStack alignItems="start" spacing="4" w="100%">
            {children}
          </VStack>
        </HStack>
        {fullWidthSection}
      </VStack>
    );
  }
);
