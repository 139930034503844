import {
  Box,
  Grid,
  GridItem,
  Text,
  Badge,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Button,
} from '@swftbox/style-guide';
import { type FC } from 'react';
import {
  type DropProfile,
  type DropProfileCity,
  DropProfileType,
  type TimeSlot,
} from 'src/components/Particles';

interface Props {
  back: () => void;
  dropProfile: DropProfile;
}

export const DropProfileDetails: FC<Props> = ({ back, dropProfile }) => {
  return (
    <Box>
      <Box
        marginBottom={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Text fontSize={16}>Drop profile details</Text>
        {dropProfile.isDefault && (
          <Badge
            p="2px 8px"
            marginLeft={2}
            fontWeight="medium"
            borderRadius="8px"
            textAlign={'center'}
            fontSize="x-small"
            variant="solid"
            colorScheme="gray"
          >
            Default
          </Badge>
        )}
        {dropProfile.isPrimary && (
          <Badge
            p="2px 8px"
            fontWeight="medium"
            borderRadius="8px"
            marginLeft={2}
            textAlign={'center'}
            fontSize="x-small"
            variant="solid"
            colorScheme="blue"
          >
            Primary
          </Badge>
        )}
      </Box>
      <hr />
      <Grid templateColumns="repeat(3, 1fr)" rowGap={2} marginTop={2}>
        <GridItem>
          <Text fontWeight="bold">Name</Text>
          <Text>{dropProfile.name}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Type</Text>
          <Text>{dropProfile.profileType}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Cutoff time</Text>
          <Text>{dropProfile.cutoffTime}</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Billing slab</Text>
          <Text>{dropProfile.billingSlab}</Text>
        </GridItem>
        {dropProfile.profileType === DropProfileType.standard && (
          <>
            <GridItem>
              <Text fontWeight="bold">End of day</Text>
              <Text>{dropProfile.typeDetails.endOfDayTime}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Extra days</Text>
              <Text>{dropProfile.typeDetails.extraDays}</Text>
            </GridItem>
          </>
        )}
        {dropProfile.profileType === DropProfileType.express && (
          <>
            <GridItem>
              <Text fontWeight="bold">Driving time</Text>
              <Text>{dropProfile.typeDetails.drivingTimeInMins} min</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Maximum end offset</Text>
              <Text>{dropProfile.typeDetails.maximumEndOffsetInMins} min</Text>
            </GridItem>
          </>
        )}

        {dropProfile.profileType === DropProfileType.custom && (
          <>
            <GridItem>
              <Text fontWeight="bold">Lead time</Text>
              <Text>{dropProfile.typeDetails.leadTimeInHours} hr</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Timeslot duration</Text>
              <Text>{dropProfile.typeDetails.timeSlotsDurationInDays} days</Text>
            </GridItem>
          </>
        )}
      </Grid>
      <GridItem>
        <Text fontWeight="bold">Excluded Days</Text>
        <Text>{dropProfile.excludedDays?.join(', ') ?? '--'}</Text>
      </GridItem>

      <Grid templateColumns="repeat(2, 1fr)" rowGap={2} marginTop={2}>
        {dropProfile.profileType === DropProfileType.custom &&
          dropProfile.typeDetails.timeSlots.length && (
            <GridItem marginTop={4}>
              <Box marginBottom={2}>
                <Text fontSize={16}>Time slots</Text>
              </Box>
              <hr />
              {dropProfile.typeDetails.timeSlots.map((timeSlot: TimeSlot, index: number) => (
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  rowGap={2}
                  marginTop={2}
                  key={`cdp-${dropProfile.id}-slot-${index}`}
                >
                  <GridItem>
                    <Text fontWeight="bold">From</Text>
                    <Text>{timeSlot.from}</Text>
                  </GridItem>
                  <GridItem>
                    <Text fontWeight="bold">To</Text>
                    <Text>{timeSlot.to}</Text>
                  </GridItem>
                </Grid>
              ))}
            </GridItem>
          )}
        {dropProfile.profileType === DropProfileType.custom &&
          dropProfile.typeDetails.preScheduleSlot && (
            <GridItem marginTop={4}>
              <Box marginBottom={2}>
                <Text fontSize={16}>Pre-schedule slot</Text>
              </Box>
              <hr />
              <Grid templateColumns="repeat(2, 1fr)" rowGap={2} marginTop={2}>
                <GridItem>
                  <Text fontWeight="bold">From</Text>
                  <Text>{dropProfile.typeDetails.preScheduleSlot.from}</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="bold">To</Text>
                  <Text>{dropProfile.typeDetails.preScheduleSlot.to}</Text>
                </GridItem>
              </Grid>
            </GridItem>
          )}
      </Grid>
      {dropProfile.cities?.length && (
        <TableContainer whiteSpace="normal" pb="4" pt="4">
          <Table variant="striped">
            <Thead>
              <Tr bg="#fbfbfc" color="neutral.normal">
                <Th>
                  <Text>City</Text>
                </Th>
                <Th>
                  <Text>Cutoff time</Text>
                </Th>
                <Th>
                  <Text>Billing slab</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {dropProfile.cities.map((city: DropProfileCity, index: number) => {
                return (
                  <Tr key={dropProfile.id} title={dropProfile.description}>
                    <Td>
                      <Text>{city.city}</Text>
                    </Td>

                    <Td>
                      <Text maxW="100%" fontWeight="semibold">
                        {city.cutoffTime.replace(new RegExp(':00$'), '')}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{city.billingSlab}</Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Button
        variant="outline"
        onClick={() => {
          back();
        }}
      >
        back
      </Button>
    </Box>
  );
};
