import { useMutation } from '@apollo/client';
import { UPDATE_USER_STATUS, GET_USERS } from './users.graphql';
import { UpdateUserStatusInput } from './users.types';

interface UpdateUserStatusResponse {
  id: string;
  userId: string;
}

export function useUpdateUserStatusMutation() {
  const [mutate, { data, loading, error }] = useMutation<{ updateUserStatus: UpdateUserStatusResponse },{ updateUserStatusInput: UpdateUserStatusInput }>(UPDATE_USER_STATUS, {
    variables: {
      updateUserStatusInput: {},
    },
    refetchQueries: [{query: GET_USERS, variables: {} }]
  })

  async function updateUserStatus(payload: UpdateUserStatusInput) {
    await mutate({
      variables: {
        updateUserStatusInput: payload,
      },
      refetchQueries: [{query: GET_USERS}]
    });
  }

  return {
    updateUserStatus,
    isUserUpdated: Boolean(data?.updateUserStatus) && !error,
    updating: loading,
    error,
  };
}
