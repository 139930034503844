import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Fade,
  FormLabel,
  HStack,
  SBEditIcon,
  SBInput,
  SBModal,
  SBTextArea,
  Stack,
  Switch,
  Text,
  toast,
} from '@swftbox/style-guide';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CommentType,
  type Order,
  useOrderModalActions,
  useOrderModalContext,
  useUpdateLocation,
  useUpdateOrder,
} from 'src/components/Particles';
import AddCommentModal from '../Comment/AddCommentModa';
import { UpdateOrderSchema } from './order.schema';
import { NavigationTabs } from 'src/components/Organisms';

interface UpdateOrderForm {
  notes?: string;
  requireDeliveryNote: boolean;
  packageCount: number;
  isInternational?: boolean;
  b2bHandling?: boolean;
  oversized?: boolean;
  chilled?: boolean;
  isRemote?: boolean;
  isOutOfZone?: boolean;
}
enum MainTabsEnum {
  OrderDetails = 'ORDER_DETAILS',
  ZoneHandling = 'ZONE_HANDLING',
}
type Tab = { name: string; id: string };

export const mainTabs: Tab[] = [
  { name: 'Order Details', id: MainTabsEnum.OrderDetails },
  { name: 'Zone Handling', id: MainTabsEnum.ZoneHandling },
];

export function EditOrderModal() {
  const { editOrderIsOpen, data: order } = useOrderModalContext() as {
    editOrderIsOpen: boolean;
    data: Order;
  };
  const [allowToMove, setAllowToMove] = useState({ page: '', isAllowed: true });
  const [activeTab, setActiveTab] = useState<MainTabsEnum>(MainTabsEnum.OrderDetails);
  const dispatch = useOrderModalActions();
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UpdateOrderForm>({
    mode: 'all',
    resolver: yupResolver(UpdateOrderSchema),
    defaultValues: { ...order, oversized: order.oversized || false },
  });

  const { updateOrder } = useUpdateOrder();
  const { updateLocation } = useUpdateLocation();
  const b2bHandling = watch('b2bHandling');
  const oversized = watch('oversized');

  useEffect(() => {
    if (b2bHandling) {
      setValue('requireDeliveryNote', true);
    }
  }, [b2bHandling, setValue]);

  useEffect(() => {
    reset({
      notes: order.notes,
      requireDeliveryNote: order.requireDeliveryNote,
      packageCount: order.packageCount,
      isInternational: order.isInternational,
      b2bHandling: order.b2bHandling,
      oversized: order.oversized,
      chilled: order.chilled,
      isRemote: order.isRemote,
      isOutOfZone: order.isOutOfZone,
    });
  }, [editOrderIsOpen, order, reset]);

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    dispatch({ type: 'CLOSE_EDIT_ORDER' });
  };

  function onConfirm(data: UpdateOrderForm) {
    const { isRemote, isInternational, isOutOfZone, ...rest } = data;
    if (activeTab === MainTabsEnum.OrderDetails)
      void updateOrder({
        payload: {
          ...rest,
          id: order.id,
          b2bHandling: b2bHandling || false,
          requireDeliveryNote: data.b2bHandling ?? data.requireDeliveryNote,
          oversized: oversized || false,
        },
        onCompleted: ({ updateOrder }) => {
          handleCloseModal(updateOrder.message);
        },
      });

    if (activeTab === MainTabsEnum.ZoneHandling)
      void updateLocation({
        payload: {
          isRemote,
          isInternational,
          isOutOfZone,
          id: order.to.id,
          verificationSource: undefined,
        },
        onCompleted: (message: string) => {
          handleCloseModal(message);
        },
      });
  }

  const handleChangeTab = useCallback(
    (tabId: string) => {
      if (!allowToMove?.isAllowed) {
        setAllowToMove({ isAllowed: false, page: tabId });
      } else {
        setActiveTab(tabId as MainTabsEnum);
      }
    },
    [allowToMove]
  );

  return (
    <>
      <SBModal
        isOpen={editOrderIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit Order</Text>
          </Box>
        }
        size="xl"
        body={
          <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={2}>
            <NavigationTabs
              tabs={mainTabs}
              handleChange={handleChangeTab}
              active={activeTab}
              parentStyle={{ marginBottom: '4px' }}
              variant="outline"
            />
            {activeTab === MainTabsEnum.OrderDetails && (
              <>
                <SBInput
                  crossOrigin={undefined}
                  type="number"
                  label="Package Count *"
                  {...register('packageCount')}
                  error={errors.packageCount?.message}
                />
                <SBTextArea
                  label="Order remarks"
                  {...register('notes')}
                  error={errors.notes?.message}
                />
                <HStack spacing={6}>
                  <FormLabel htmlFor="requireDeliveryNote">Require a delivery note?</FormLabel>
                  <Switch id="requireDeliveryNote" {...register('requireDeliveryNote')} />
                </HStack>
                <HStack spacing={12}>
                  <FormLabel htmlFor="b2bHandling">B2B Order?</FormLabel>
                  <Switch id="b2bHandling" {...register('b2bHandling')} />
                </HStack>
                <HStack spacing={12}>
                  <FormLabel htmlFor="Oversized">Oversized?</FormLabel>
                  <Switch id="Oversized" {...register('oversized')} />
                  {!!oversized && (
                    <HStack mt={4}>
                      <Fade in={oversized} unmountOnExit>
                        <Box bg="#EFEFEF" borderRadius="4" w="max-content">
                          <HStack spacing="0">
                            <AddCommentModal
                              order={order}
                              type={CommentType.OVERSIZED}
                              buttonText={'Add Comment'}
                            />
                          </HStack>
                        </Box>
                      </Fade>
                    </HStack>
                  )}
                </HStack>
                <HStack spacing={12}>
                  <FormLabel htmlFor="chilled">Chilled Order?</FormLabel>
                  <Switch id="chilled" {...register('chilled')} />
                </HStack>
              </>
            )}
            {activeTab === MainTabsEnum.ZoneHandling && (
              <>
                <HStack spacing={6}>
                  <Switch id="isInternational" {...register('isInternational')} />
                  <FormLabel htmlFor="isInternational">International</FormLabel>
                </HStack>
                <HStack spacing={6}>
                  <Switch id="isRemote" {...register('isRemote')} />
                  <FormLabel htmlFor="isRemote">Remote</FormLabel>
                </HStack>
                <HStack spacing={6}>
                  <Switch id="isOutOfZone" {...register('isOutOfZone')} />
                  <FormLabel htmlFor="isOutOfZone">Out of Zone</FormLabel>
                </HStack>
              </>
            )}
          </Stack>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
