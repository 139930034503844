import { addDays } from 'date-fns';
import { createContext, type Dispatch, useContext } from 'react';
import { OrdersPageFilter } from 'src/components/Pages/Orders/order.types';

import {
  type FilterAction,
  type OrdersFilters,
  type OrdersOrganizingFilters,
  type OrganizedFilterAction,
} from '../../providers';

interface OrdersFiltersContextProps {
  filters: OrdersFilters;
  organizedFilters: OrdersOrganizingFilters;
}
const initialOrderFiltersRoot = {
  filters: {
    page: 1,
    stateStageFilter: {},
    dateFilter: {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      isSelected: false,
      key: 'selection',
    },
    sortFilter: {},
    advancedFilters: {},
    state: OrdersPageFilter.ALL,
    status: [],
    validationStatus: [],
    failedAttemptCount: undefined,
    statusId: [],
    flagType: [],
    isStarrred: undefined,
  },
  organizedFilters: {},
};

export const OrdersFiltersContext = createContext<OrdersFiltersContextProps>({
  ...initialOrderFiltersRoot,
});

type OrdersFiltersActionsContextProps = Dispatch<FilterAction | OrganizedFilterAction>;

export const OrdersFiltersActionsContext = createContext<OrdersFiltersActionsContextProps | null>(
  null
);
export function useOrdersFiltersActions() {
  const dispatch = useContext(OrdersFiltersActionsContext);
  return dispatch as OrdersFiltersActionsContextProps;
}
export function useOrdersFilters() {
  return useContext(OrdersFiltersContext);
}

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
