import { useMutation } from '@apollo/client';
import { MessageResponse } from '../../../SharedTypes';
import { ConfirmPickupInput, PICKUP_MUTATION } from '../../Queries';

export function usePickupMutation() {
  const [mutate, { loading, error }] = useMutation<
    { confirmPickup: MessageResponse },
    { confirmPickupInput: ConfirmPickupInput }
  >(PICKUP_MUTATION);

  async function pickup(confirmPickupInput: ConfirmPickupInput) {
    return mutate({
      variables: {
        confirmPickupInput,
      },
      refetchQueries: [
        'PaginatedRoutes',
      ],
    });
  }

  return {
    pickup,
    loading,
    error,
  };
}
