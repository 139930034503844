import { Box, Button, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import { useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import {
  type Order,
  useCancelInternationalOrders,
} from 'src/components/Particles/resolvers/orders';

export function CancelInternationalOrder() {
  const { internationalCancelIsOpen, data: order } = useOrderModalContext() as {
    internationalCancelIsOpen: boolean;
    data: Order;
  };

  const dispatch = useOrderModalActions();

  const { cancelInternationalOrder, loading } = useCancelInternationalOrders();

  function onConfirm() {
    void cancelInternationalOrder({
      swftboxTracking: order.swftboxTracking,
      onCompleted: (message) => {
        if (message) {
          toast.success(message);
          dispatch({ type: 'CLOSE_INTERNATIONAL_SHIPMENT_CANCEL' });
        }
      },
    });
  }

  return (
    <>
      <SBModal
        isOpen={internationalCancelIsOpen}
        onClose={() => {
          dispatch({ type: 'CLOSE_INTERNATIONAL_SHIPMENT_CANCEL' });
        }}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Release & Cancel on Carriyo</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to release international order from carriyo{' '}
            <Text as="span" fontWeight="semibold">
              {order.swftboxTracking}
            </Text>{' '}
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button
              variant="outline"
              w="100%"
              onClick={() => {
                dispatch({ type: 'CLOSE_INTERNATIONAL_SHIPMENT_CANCEL' });
              }}
            >
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
