import { If, Spinner } from '@swftbox/style-guide';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PartnerList } from 'src/components/Pages/Jobs/Components/AddNewJob/Components/ItemList/PartnerList';
import { JobOption, useJobTypeContext } from 'src/components/Particles';
import { OrderList } from './OrderList';

interface ItemsListProps {
  searchText: string | undefined;
  revealAllLocations: boolean;
}
export function ItemList({ searchText, revealAllLocations }: ItemsListProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const observerRef = useRef(null);

  const { jobType } = useJobTypeContext();

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !isLoading && !isLastPage) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    },
    [isLoading, isLastPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    const currentObserverCopy = observerRef.current;
    return () => {
      if (currentObserverCopy) {
        observer.unobserve(currentObserverCopy);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    if (!!searchText || jobType) {
      setPageNumber(1);
      setIsLastPage(false);
    }
  }, [jobType, searchText]);

  const stopFetching = useCallback(() => {
    setIsLastPage(true);
  }, []);

  return (
    <>
      <If condition={jobType === JobOption.ROUTE}>
        <OrderList
          type={JobOption.ROUTE}
          page={pageNumber}
          setIsLoading={setIsLoading}
          stopFetching={stopFetching}
          searchText={searchText}
        />
      </If>
      <If condition={jobType === JobOption.RTO}>
        <OrderList
          type={JobOption.RTO}
          page={pageNumber}
          setIsLoading={setIsLoading}
          stopFetching={stopFetching}
          searchText={searchText}
        />
      </If>
      <If condition={jobType === JobOption.PARTNER}>
        <PartnerList
          page={pageNumber}
          revealAllLocations={revealAllLocations}
          setIsLoading={setIsLoading}
          stopFetching={stopFetching}
          searchText={searchText}
        />
      </If>
      <If condition={isLoading}>
        <Spinner size="xl" />
      </If>
      <div ref={observerRef} />
    </>
  );
}
