import { useMutation } from '@apollo/client';
import { VERIFY_OTP } from './auth.graphql';
import { type LoginResponse, type VerifyOTPInput } from './auth.types';

interface VerifyOTPPayload {
  identifier: string;
  action?: 'LOGIN';
  code: string;
}

export function useVerifyOTPMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    {
      verifyOtp: LoginResponse;
    },
    { verifyOtpInput: VerifyOTPInput }
  >(VERIFY_OTP);

  async function verifyOTP({ identifier, code, action }: VerifyOTPPayload) {
    await mutate({
      variables: {
        verifyOtpInput: {
          identifier,
          code,
          action: action ?? 'LOGIN',
        },
      },
    });
  }

  return {
    verifyOTP,
    user: data?.verifyOtp.user,
    token: data?.verifyOtp.token,
    loading,
    error,
  };
}
