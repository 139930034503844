import * as yup from 'yup';
import { createMinMaxOptionalValidation } from './helper';

export const CommonLocationSchema = yup.object().shape({
  city: yup.string().min(1, 'Too short').required('City is required'),
  country: yup.string().min(1, 'Too short!').required('Country is required'),
  addressLine1: yup
    .string()
    .min(10, 'Must exceed 9 characters')
    .max(100, 'Too long, try shorter one')
    .required('Address line 1 is required'),
  addressLine2: yup
    .string()
    .test('address line 2 test', createMinMaxOptionalValidation(10, 100))
    .optional(),
  coordinates: yup
    .object()
    .shape({
      latitude: yup.number().required(),
      longitude: yup.number().required(),
    })
    .optional(),
});
