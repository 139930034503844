import fontkit from '@pdf-lib/fontkit';
import { type PDFButton, PDFDocument, type PDFFont, type PDFForm } from 'pdf-lib';
import qrcode from 'qrcode';
import Arial from 'src/assets/fonts/arial.ttf';
import { type Order, ShippingLabelSize } from 'src/components/Particles';
import { Config } from 'src/config';

function orEmptyString(str?: string, placeholder = '') {
  return str ?? placeholder;
}

async function getSwftboxPDFTemplate(shippingLabelSize: ShippingLabelSize = ShippingLabelSize.A6) {
  if (shippingLabelSize === ShippingLabelSize.SQUARE) {
    return import('src/assets/pdfTemplates/swftbox/square.pdf').then((r) => r.default);
  } else if (shippingLabelSize === ShippingLabelSize.MINI) {
    return import('src/assets/pdfTemplates/swftbox/mini.pdf').then((r) => r.default);
  } else if (shippingLabelSize === ShippingLabelSize.ALTERNATE) {
    return import('src/assets/pdfTemplates/swftbox/A6_ALTERNATE.pdf').then((r) => r.default);
  } else {
    return import('src/assets/pdfTemplates/swftbox/a6.pdf').then((r) => r.default);
  }
}

async function getPrimexPDFTemplate(shippingLabelSize: ShippingLabelSize = ShippingLabelSize.A6) {
  if (shippingLabelSize === ShippingLabelSize.SQUARE) {
    return import('src/assets/pdfTemplates/primex/square.pdf').then((r) => r.default);
  } else if (shippingLabelSize === ShippingLabelSize.MINI) {
    return import('src/assets/pdfTemplates/primex/mini.pdf').then((r) => r.default);
  } else if (shippingLabelSize === ShippingLabelSize.ALTERNATE) {
    return import('src/assets/pdfTemplates/primex/A6_ALTERNATE.pdf').then((r) => r.default);
  } else {
    return import('src/assets/pdfTemplates/primex/a6.pdf').then((r) => r.default);
  }
}

async function getPDFTemplate(shippingLabelSize: ShippingLabelSize = ShippingLabelSize.A6) {
  const template = Config.PRIMEXPRESS_BUILD
    ? await getPrimexPDFTemplate(shippingLabelSize)
    : await getSwftboxPDFTemplate(shippingLabelSize);

  return fetch(template).then(async (r) => r.arrayBuffer());
}

async function getFont() {
  return fetch(Arial).then(async (r) => r.arrayBuffer());
}

function removeEmojis(inputText: string): string {
  const emojiRegex = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;
  return inputText.replace(emojiRegex, '');
}

function removeParagraphFormatting(inputText: string): string {
  const paragraphFormattingUnicode = /[\u202A-\u202E\u2066-\u2069]/g;
  return inputText.replace(paragraphFormattingUnicode, '');
}
function formatAddress(location: any, isAlternate = false) {
  const area = location.area && location.area !== 'Not Mapped' ? location.area : '';
  const elements = [
    orEmptyString(location.company),
    orEmptyString(location.addressLine1),
    orEmptyString(location.building),
    orEmptyString(location.addressLine2),
    !isAlternate ? orEmptyString(area) : '',
    !isAlternate ? orEmptyString(location.city) : '',
    !isAlternate ? orEmptyString(location.countryCode) : '',
  ];

  // Filter out any empty or undefined values and then join with ' - '
  return formatText(elements.filter(Boolean).join(' - '));
}

const formatText = (text: string) => {
  const arabicScriptRange = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;

  const lines = removeParagraphFormatting(text).split(/\n/);
  let formattedText = '';

  for (const line of lines) {
    let currentDirection = '';
    let sentenceLine = '';
    const emojiFreeLine = removeEmojis(line).trim();
    const words = emojiFreeLine.split(/\s+/);

    for (const word of words) {
      for (const char of word) {
        const charDirection = arabicScriptRange.test(char) ? 'rtl' : 'ltr';
        if (currentDirection !== charDirection && sentenceLine.trim() !== '') {
          formattedText += sentenceLine + '\n';
          sentenceLine = '';
        }
        sentenceLine += char;
        currentDirection = charDirection;
      }
      sentenceLine += ' ';
    }

    formattedText += sentenceLine.trim() + '\n';
  }
  return formattedText.trim();
};

export async function openOrdersShippingLabels(
  orders: Order[],
  pdfName = 'shippingLabels.pdf',
  shippingLabelSize: ShippingLabelSize = ShippingLabelSize.A6
) {
  const template = await getPDFTemplate(shippingLabelSize);

  const combinedPdfDoc = await PDFDocument.create();
  combinedPdfDoc.registerFontkit(fontkit);
  const fontBytes = await getFont();

  await fillPDFFields(orders, combinedPdfDoc, template, fontBytes, shippingLabelSize);

  combinedPdfDoc.setTitle(pdfName);
  const combinedPdfBytes = await combinedPdfDoc.save({ updateFieldAppearances: true });
  const pdfBlob = new Blob([combinedPdfBytes], { type: 'application/pdf' });

  // Create a URL for the Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Open the URL in a new tab
  window.open(pdfUrl, '_blank');
}

async function fillPDFFields(
  orders: Order[],
  combinedPdfDoc: PDFDocument,
  template: ArrayBuffer,
  fontBytes: ArrayBuffer,
  shippingLabelSize: ShippingLabelSize
) {
  for (const order of orders) {
    for (let i = 0; i < order.packageCount; i++) {
      const templatePdfDoc = await PDFDocument.load(template);

      templatePdfDoc.registerFontkit(fontkit);
      const font = await templatePdfDoc.embedFont(fontBytes, {
        customName: 'Arial',
        subset: true,
      });

      const form = templatePdfDoc.getForm();

      if (shippingLabelSize !== ShippingLabelSize.ALTERNATE)
        await addCommonPDFFields(templatePdfDoc, form, font, order, i);

      if ([ShippingLabelSize.SQUARE, ShippingLabelSize.A6].includes(shippingLabelSize)) {
        addSquarePDFFields(form, order);
      }

      if (shippingLabelSize === ShippingLabelSize.A6) {
        await addA6PDFFields(templatePdfDoc, form, font, order, i);
      }
      if (shippingLabelSize === ShippingLabelSize.ALTERNATE) {
        await addAlternatePDFFields(templatePdfDoc, form, font, order, i);
      }

      form.flatten();

      const pagesToCopy = await combinedPdfDoc.copyPages(
        templatePdfDoc,
        templatePdfDoc.getPageIndices()
      );

      pagesToCopy.forEach((page) => {
        combinedPdfDoc.addPage(page);
      });
    }
  }
}

async function addA6PDFFields(
  templatePdfDoc: PDFDocument,
  form: PDFForm,
  font: PDFFont,
  order: Order,
  packageCount: number
) {
  const retailerLocation = order.isReverse ? order.to : order.from;
  form
    .getTextField('partnerLocationName')
    .setText(
      removeParagraphFormatting(orEmptyString(retailerLocation.name ?? retailerLocation.identifier))
    );
  form.getTextField('partnerLocationName').updateAppearances(font);
  form
    .getTextField('partnerLocationAddress')
    .setText(
      formatText(
        `${retailerLocation.phone ? retailerLocation.phone + ' -' : ''} ${
          retailerLocation.addressLine1
        } ${orEmptyString(retailerLocation.addressLine2)}`
      )
    );

  form.getTextField('partnerLocationAddress').updateAppearances(font);

  const customerLocation = order.isReverse ? order.from : order.to;
  const dispatchCity =
    customerLocation.dispatchCity !== 'Not Mapped' ? customerLocation.dispatchCity : '';
  const dispatchZone =
    customerLocation.dispatchZone !== 'Not Mapped' ? customerLocation.dispatchZone : '';

  form.getTextField('customerCity').setText(orEmptyString(dispatchCity ?? customerLocation.city));
  form.getTextField('customerCity').updateAppearances(font);

  form.getTextField('dispatchZone').setText(orEmptyString(dispatchZone));
  form.getTextField('dispatchZone').updateAppearances(font);

  form.getTextField('customerName').setText(removeParagraphFormatting(order.customer.name));
  form.getTextField('customerName').updateAppearances(font);

  form
    .getTextField('customerPhone')
    .setText(removeParagraphFormatting(customerLocation.phone ?? order.customer.phone));
  form.getTextField('customerPhone').updateAppearances(font);

  form.getTextField('customerAddress').setText(formatAddress(customerLocation));

  form.getTextField('customerPhone').updateAppearances(font);

  form.getTextField('customerAddress').updateAppearances(font);

  form.getTextField('city_1').updateAppearances(font);
  form.getTextField('area_1').updateAppearances(font);
  form.getTextField('country_code_1').updateAppearances(font);

  await addQRCodeToField(
    form.getButton('qrCode_0'),
    templatePdfDoc,
    `${order.swftboxTracking}<>${packageCount + 1}`
  );
}

async function addAlternatePDFFields(
  templatePdfDoc: PDFDocument,
  form: PDFForm,
  font: PDFFont,
  order: Order,
  packageCount: number
) {
  const customerLocation = order.isReverse ? order.from : order.to;
  const dispatchCity =
    customerLocation.dispatchCity !== 'Not Mapped' ? customerLocation.dispatchCity : '';
  const dispatchZone =
    customerLocation.dispatchZone !== 'Not Mapped' ? customerLocation.dispatchZone : '';
  const area = customerLocation.area !== 'Not Mapped' ? customerLocation.area : '';

  form.getTextField('swftboxTracking').setText(order.swftboxTracking);
  form.getTextField('packageCount').setText(`${packageCount + 1}/${order.packageCount}`);

  const swftboxTracking0Text = order.scheduledCustomerSlot
    ? `${order.scheduledCustomerSlot.from} - ${order.scheduledCustomerSlot.to}`
    : order.swftboxTracking;

  form.getTextField('swftboxTracking_0').setText(swftboxTracking0Text);

  form.getTextField('customerCity_1').setText(orEmptyString(dispatchCity));
  form.getTextField('customerCity_1').updateAppearances(font);
  form.getTextField('dispatchZone_1').setText(orEmptyString(dispatchZone));
  form.getTextField('dispatchZone_1').updateAppearances(font);
  form.getTextField('customerName_1').setText(removeParagraphFormatting(order.customer.name));
  form.getTextField('customerName_1').updateAppearances(font);
  form
    .getTextField('customerPhone_1')
    .setText(removeParagraphFormatting(customerLocation.phone ?? order.customer.phone));
  form.getTextField('customerPhone_1').updateAppearances(font);

  const customerAddress1 = `${orEmptyString(customerLocation.company)} ${
    customerLocation.addressLine1
  } ${orEmptyString(customerLocation.building)} ${orEmptyString(customerLocation.addressLine2)}`;

  const customerAddressInput = form.getTextField('customerAddress_1');

  customerAddressInput.setText(formatText(customerAddress1));

  customerAddressInput.updateAppearances(font);

  form.getTextField('area_1').setText(orEmptyString(area));
  form.getTextField('city_1').setText(orEmptyString(customerLocation.city));
  form.getTextField('country_code_1').setText(orEmptyString(customerLocation.countryCode));

  form.getTextField('city_1').updateAppearances(font);
  form.getTextField('area_1').updateAppearances(font);
  form.getTextField('country_code_1').updateAppearances(font);

  form
    .getTextField('orderReference')
    .setText(removeParagraphFormatting(`${replaceInvalidChars(order.reference)}`));
  form.getTextField('paymentType').setText(order.paymentMode === 'prePaid' ? 'Prepaid' : 'COD');
  if (order.paymentAmount > 0) {
    form
      .getTextField('paymentAmount')
      .setText(String(order.paymentAmount) + ' ' + String(order.currency));
  } else {
    form.getTextField('paymentAmount').setText('');
  }

  form.getTextField('orderRemarks').setText(formatText(order.notes ?? ''));
  form.getTextField('orderRemarks').updateAppearances(font);

  await addQRCodeToField(
    form.getButton('qrCode_1'),
    templatePdfDoc,
    `${order.swftboxTracking}<>${packageCount + 1}`
  );

  form.getTextField('packageCount_0').setText(`${packageCount + 1}/${order.packageCount}`);
}

async function addCommonPDFFields(
  templatePdfDoc: PDFDocument,
  form: PDFForm,
  font: PDFFont,
  order: Order,
  packageCount: number
) {
  const customerLocation = order.isReverse ? order.from : order.to;
  const dispatchCity =
    customerLocation.dispatchCity !== 'Not Mapped' ? customerLocation.dispatchCity : '';
  const dispatchZone =
    customerLocation.dispatchZone !== 'Not Mapped' ? customerLocation.dispatchZone : '';
  const area = customerLocation.area !== 'Not Mapped' ? customerLocation.area : '';

  form.getTextField('swftboxTracking').setText(order.swftboxTracking);
  form.getTextField('packageCount').setText(`${packageCount + 1}/${order.packageCount}`);

  const swftboxTracking0Text = order.scheduledCustomerSlot
    ? `${order.scheduledCustomerSlot.from} - ${order.scheduledCustomerSlot.to}`
    : order.swftboxTracking;

  form.getTextField('swftboxTracking_0').setText(swftboxTracking0Text);

  form.getTextField('customerCity_1').setText(orEmptyString(dispatchCity));
  form.getTextField('customerCity_1').updateAppearances(font);
  form.getTextField('dispatchZone_1').setText(orEmptyString(dispatchZone));
  form.getTextField('dispatchZone_1').updateAppearances(font);
  form.getTextField('customerName_1').setText(removeParagraphFormatting(order.customer.name));
  form.getTextField('customerName_1').updateAppearances(font);

  const customerAddress1 = formatAddress(customerLocation, true);

  const customerAddressInput = form.getTextField('customerAddress_1');

  customerAddressInput.setText(formatText(customerAddress1));

  customerAddressInput.updateAppearances(font);

  const partnerName =
    order.retailer.showBrand && order.brandName ? order.brandName : order.retailer.name;

  form.getTextField('partnerName').setText(removeParagraphFormatting(partnerName));
  form.getTextField('partnerName').updateAppearances(font);

  form
    .getTextField('orderReference')
    .setText(removeParagraphFormatting(`${replaceInvalidChars(order.reference)}`));
  form.getTextField('paymentType').setText(order.paymentMode === 'prePaid' ? 'Prepaid' : 'COD');
  if (order.paymentAmount > 0) {
    form
      .getTextField('paymentAmount')
      .setText(String(order.paymentAmount) + ' ' + String(order.currency));
  } else {
    form.getTextField('paymentAmount').setText('');
  }

  form.getTextField('orderRemarks').setText(formatText(order.notes ?? ''));
  form.getTextField('orderRemarks').updateAppearances(font);

  form.getTextField('area_1').setText(orEmptyString(area));
  form.getTextField('city_1').setText(customerLocation.city ?? ' ');
  form.getTextField('country_code_1').setText(customerLocation.countryCode ?? ' ');

  form.getTextField('city_1').updateAppearances(font);
  form.getTextField('area_1').updateAppearances(font);
  form.getTextField('country_code_1').updateAppearances(font);

  await addQRCodeToField(
    form.getButton('qrCode_1'),
    templatePdfDoc,
    `${order.swftboxTracking}<>${packageCount + 1}`
  );
}

function addSquarePDFFields(form: PDFForm, order: Order) {
  const customerLocation = order.isReverse ? order.from : order.to;

  form
    .getTextField('customerPhone_1')
    .setText(removeParagraphFormatting(customerLocation.phone ?? order.customer.phone));
}

async function addQRCodeToField(field: PDFButton, doc: PDFDocument, qrCodeText: string) {
  const widget = field.acroField.getWidgets()[0];

  const { width: widthInPoints } = widget.getRectangle();

  const dpi = 82;

  const pixelsPerInch = 72;

  const widthInPixels = widthInPoints * (dpi / pixelsPerInch);

  const base64Image = await qrcode.toDataURL(qrCodeText, { width: widthInPixels, margin: 0.7 });
  const image = await doc.embedPng(base64Image);
  field.setImage(image);
}

function replaceInvalidChars(text: string) {
  return text.replace('�', '?');
}
