import { Outlet, RouteObject } from 'react-router-dom';
import { PaymentsPage, SettlementsPage } from 'src/components/Pages';
import { ErrorBoundary } from '../../ErrorBoundary';

export const PaymentRoutes: RouteObject = {
  path: 'payments',
  element: (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <PaymentsPage />,
    },
    {
      path: 'settlements',
      element: <SettlementsPage />,
    },
  ],
};