import { Box, Button, HStack, IconWText, Text, Tooltip } from '@swftbox/style-guide';
import { ProcedureType, type Order } from 'src/components/Particles';
import CommentModal from '../Modal/Comment/CommentModal';
import AddCommentModal from '../Modal/Comment/AddCommentModa';
import SVG from 'react-inlinesvg';
import AttachmentIcon from 'src/assets/icons/Editor/attachment-01.svg';
import ShoppingBag04 from 'src/assets/icons/eCommerce/shopping-bag-01.svg';
import OrderItemsModal from '../Modal/OrderItem/OrderItems.Modal';
import { EditOrderPackageCount, EditOrderRemarks } from '../Modal/EditOrder';
import { AttachmentsModal } from '../Modal/Attachments';
import { RevealSOPs } from 'src/components/Organisms';
import B2bIcon from 'src/assets/icons/b2b.svg';
import InternationalIcon from 'src/assets/icons/international 2.svg';
import RemoteIcon from 'src/assets/icons/remote.svg';
import ChilledIcon from 'src/assets/icons/chilled.svg';

interface FulfillmentPlanProps {
  order: Order;
}
export const FulfillmentPlan = ({ order }: FulfillmentPlanProps) => {
  const itemsCount = order.items.reduce((total, item) => total + item.quantity, 0);
  const ViewItemsComponent = (
    <Box bg="#EFEFEF" px="2" borderRadius="4" w="max-content">
      <Tooltip label="Items Count" hasArrow>
        <Button variant="link">
          <IconWText
            text={`x${itemsCount.toString()}`}
            Icon={<SVG src={ShoppingBag04} width="12px" stroke="currentColor" />}
          />
        </Button>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <HStack gap="1">
        <EditOrderPackageCount orderId={order.id} packageCount={order.packageCount} />
        <OrderItemsModal orderId={order.id} viewItemsComponent={ViewItemsComponent} />
      </HStack>
      <HStack h="45px">
        <Box bg="#EFEFEF" p="1" borderRadius="4" w="max-content">
          <HStack>
            <CommentModal order={order} />
            <Text>|</Text>
            <AddCommentModal order={order} />
          </HStack>
        </Box>
      </HStack>
      <HStack h="25px">
        {(!!order.notes || !!order.fulfillmentRemarks) && (
          <EditOrderRemarks
            orderId={order.id}
            notes={order.notes}
            fulfillmentNotes={order.fulfillmentRemarks}
          />
        )}
        {!!order.attachments?.length && (
          <Box cursor="pointer">
            <AttachmentsModal
              attachments={order.attachments || []}
              attachmentsURLs={order.attachments || []}
              button={
                <Tooltip label="view Attachment" hasArrow>
                  <Box bg="#EFEFEF" px="3" borderRadius="4" w="max-content" h="25px">
                    <SVG src={AttachmentIcon} width="12px" stroke="currentColor" />
                  </Box>
                </Tooltip>
              }
            />
          </Box>
        )}
        {order.retailer.procedures?.length &&
          order.retailer.procedures.some((sop) => sop.type === ProcedureType.FULFILLMENT) && (
            <RevealSOPs
              SOPTypes={[ProcedureType.FULFILLMENT]}
              procedures={order.retailer.procedures}
            />
          )}
        {!!order.b2bHandling && (
          <AttachmentsModal
            attachments={order.b2bInvoice ?? []}
            attachmentsURLs={order.b2bInvoice ?? []}
            button={
              <Tooltip label="B2B Invoice" hasArrow>
                <Box
                  bg="#EFEFEF"
                  py="1"
                  px="3"
                  borderRadius="4"
                  display="flex"
                  w="max-content"
                  color="primary.900"
                >
                  <SVG src={B2bIcon} width="20px" height={'20px'} />
                </Box>
              </Tooltip>
            }
          />
        )}
        {!!order.isRemote && (
          <Tooltip label={'Remote'} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <SVG src={RemoteIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.chilled && (
          <Tooltip label={'Chilled'} hasArrow>
            <Box
              bg="blue.500"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <SVG src={ChilledIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.isInternational && (
          <Tooltip label={'International'} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <SVG src={InternationalIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
      </HStack>
    </>
  );
};
