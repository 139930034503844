import { useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Job, JobStatus, STOP_TIMELINE, type StopTimeline } from '../Queries';
import { calculateDistance } from 'src/components/Particles/Helpers';

interface QueryResponse {
  getRouteStopTimeline: StopTimeline[];
}

interface QueryArgs {
  routeId?: string;
}

export function useStopTimelineQuery({ routeId }: QueryArgs) {
  const [query, { data, loading }] = useLazyQuery<QueryResponse, QueryArgs>(
    STOP_TIMELINE,
    {
      variables: { routeId },
    },
  );

  return {
    getStopTimeline: query,
    stopTimeline: data?.getRouteStopTimeline ?? [],
    loading,
  };
}

const checkSequenceAdherence = (planned?: number | null, actual?: number | null) => {
  if (planned) return planned === actual;
  return !!actual;
}

export function useJobAdherence(job: Job) {
  const { stopTimeline, getStopTimeline } = useStopTimelineQuery({ routeId: job?.id });

  useEffect(() => {
    if (job?.status !== JobStatus.COMPLETED) return;
    getStopTimeline({ variables: { routeId: job?.id } });
  }, [job?.id, getStopTimeline]);

  const timelineWithAdherence = useMemo(() => {
    const uniqueTimelineMap = stopTimeline
      ?.filter(item => item.status === 'COMPLETED')
      ?.reduce((cumm, item) => {
        if (!cumm.has(item.stop.sequence)) {
          cumm.set(item.stop.sequence, {
            ...item,
            sequenceAdhered: checkSequenceAdherence(item.stop?.optimizerSequence || item.stop?.originalSequence, item.stop?.sequence),
            locationAdhered: calculateDistance(item.stop.coordinates, item.coordinates!, true) < 50,
          });
        }
        return cumm;
      }, new Map());

      return Array.from(uniqueTimelineMap.values());
  }, [stopTimeline]);

  // Planned coordinates path
  const plannedPath = useMemo(() => {
    return timelineWithAdherence?.map(item => ({
      lat: item.stop.coordinates?.latitude,
      lng: item.stop.coordinates?.longitude,
    }));
  }, [timelineWithAdherence]);

  // Driver actual coordinates path
  const actualPath = useMemo(() => {
    return timelineWithAdherence?.map(item => ({
      lat: item.coordinates?.latitude || item.stop.coordinates?.latitude,
      lng: item.coordinates?.longitude || item.stop.coordinates?.latitude,
    }));
  }, [timelineWithAdherence]);

  const { sequenceAdherencePercentage, locationAdherencePercentage } = useMemo(() => {
    const { sequenceAdheredCount, locationAdheredCount } = timelineWithAdherence.reduce((cumm, item) => {
      if (item.sequenceAdhered) cumm.sequenceAdheredCount++;
      if (item.locationAdhered) cumm.locationAdheredCount++;
      return cumm;
    }, {
      sequenceAdheredCount: 0,
      locationAdheredCount: 0,
    });

    return {
      sequenceAdherencePercentage: Math.round(100 * sequenceAdheredCount/timelineWithAdherence.length),
      locationAdherencePercentage: Math.round(100 * locationAdheredCount/timelineWithAdherence.length),
    };
  }, [timelineWithAdherence]);

  console.log({
    sequenceAdherencePercentage,
    locationAdherencePercentage,
  });

  console.log(timelineWithAdherence);

  console.log({
    actualPath,
    plannedPath,
  });

  return {
    timelineWithAdherence,
    plannedPath,
    actualPath,
    sequenceAdherencePercentage,
    locationAdherencePercentage,
  };
}