import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, SBModal, useDisclosure, toast, SBClockIcon } from '@swftbox/style-guide';
import { endOfDay, format, startOfDay, subWeeks } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useSyncOrders } from 'src/components/Particles';
import { SyncOrdersSchema } from './syncOrders.schema';

import SyncOrdersForm from './SyncOrdersForm';

interface FormValue {
  from: string;
  to: string;
}

export function SyncOrders() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { syncOrders } = useSyncOrders();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValue>({
    mode: 'all',
    resolver: yupResolver(SyncOrdersSchema),
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = ({ from, to }: FormValue) => {
    void syncOrders({
      onCompleted: (data) => {
        handleCloseModal(data.fixOrdersElasticSync.message)
      },
      payload: {
        from: format(startOfDay(new Date(from)), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        to: format(endOfDay(new Date(to)), "yyyy-MM-dd'T'HH:mm:ss'Z'")
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        Sync Orders
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        // @ts-expect-error
        scrollBehavior="outside"
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBClockIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={<SyncOrdersForm errors={errors} control={control} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
