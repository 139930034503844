import { Box, Flex } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { Filters } from './Components/Filters';
import styles from './style.module.scss';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  PaymentEntityType,
  usePurchaseQuery,
  type PurchaseFilterArgs,
} from 'src/components/Particles';
import { PurchasesHeader } from './Components/PurchaseHeader';
import { PurchasesTable } from './Components/PurchaseTable';

export function PurchasePage() {
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<PurchaseFilterArgs>({
    perPage: 50,
    page,
    orderBy: 'createdAt',
    order: 'DESC',
  });

  const [retailerId, setRetailerId] = useState<string | null>();
  const { purchases, loading } = usePurchaseQuery(filters);

  const onFilterChange = (key: string, selectedFilter: string) => {
    setFilters({ ...filters, [key]: selectedFilter || undefined });
  };

  useEffect(() => {
    const entityFilters = retailerId
      ? {
          entityId: retailerId,
          entityType: PaymentEntityType.RETAILER,
        }
      : { entityId: undefined, entityType: undefined };

    setFilters((filters) => ({
      ...filters,
      ...entityFilters,
      referenceType: filters.referenceType || undefined,
      page: page,
    }));
  }, [retailerId, page]);

  return (
    <Box className={styles.purchasePage}>
      <PurchasesHeader purchasesCount={purchases?.total ?? 0} />
      <Filters
        onFilterChange={onFilterChange}
        retailerId={retailerId}
        setRetailerId={setRetailerId}
        status={filters.status}
        referenceType={filters.referenceType}
      />
      <PurchasesTable
        purchases={purchases?.data ?? []}
        page={page}
        total={purchases?.total ?? 0}
        onPageChange={(page) => {
          setPage(page);
        }}
        perPage={50}
      />
      {!loading && !purchases?.total && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Invoices Found" />
        </Flex>
      )}
    </Box>
  );
}
