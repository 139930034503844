import { useMutation } from '@apollo/client';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type Retailer, type UpdateRetailerInput } from './retailer.types';
import { GET_RETAILER, GET_RETAILERS, UPDATE_RETAILER } from './retailers.graphql';

export function useUpdateRetailerMutation() {
  const [mutate, { data, loading, error, reset }] = useMutation<
    { updateRetailer: Retailer },
    { updateRetailerInput: UpdateRetailerInput }
  >(UPDATE_RETAILER);

  async function updateRetailer(payload: UpdateRetailerInput) {
    return mutate({
      variables: {
        updateRetailerInput: payload,
      },
      refetchQueries: [
        {
          query: GET_RETAILERS,
          variables: QueriesStore.get('GET_RETAILERS'),
        },
        {
          query: GET_RETAILER,
          variables: { retailerId: payload.id },
        },
      ],
    });
  }

  return {
    updateRetailer,
    reset,
    retailer: data?.updateRetailer,
    loading,
    error,
  };
}
