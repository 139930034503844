import { Button } from '@swftbox/style-guide';
import { useEffect } from 'react';
import { AttachmentLens } from 'src/components/Particles';
import SVG from 'react-inlinesvg';
import rightIcon from 'src/assets/icons/Arrows/right.svg';
import leftIcon from 'src/assets/icons/Arrows/left.svg';
type Props = {
  isOpen: boolean;
  attachments: AttachmentLens[];
  currentIndex?: number;
  setCurrentIndex: (index: number) => void;
  handleAddMore?: () => void;
  total?: number;
};
export const NavigateNextAndPrevious = ({
  isOpen,
  attachments,
  currentIndex = 0,
  setCurrentIndex,
  handleAddMore,
  total,
}: Props) => {
  const handleNext = () => {
    if (currentIndex < attachments.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
    if (currentIndex === attachments.length - 1 && handleAddMore) {
      handleAddMore();
    }
  };

  // Navigate to the previous image
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        handleNext();
      }
      if (event.key === 'ArrowLeft') {
        handlePrevious();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, currentIndex, attachments]);
  return (
    <div>
      {(currentIndex < attachments.length - 1 ||
        (!!total && !!attachments.length && total > attachments.length)) && (
        <Button
          position="absolute"
          right="0"
          top="10%"
          zIndex={2}
          h="90%"
          display="flex"
          justifyContent="center"
          bg="transparent"
          borderRadius="0"
          px="3"
          _hover={{
            bg: '#ffffff2e',
          }}
          _focus={{
            bg: '#ffffff2e',
          }}
          alignItems="center"
          transition="all 0.3s"
          onClick={handleNext}
        >
          <SVG src={rightIcon} width="20px" height="20px" />
        </Button>
      )}

      {currentIndex > 0 && (
        <Button
          position="absolute"
          left="0"
          top="0"
          zIndex={2}
          h="100%"
          display="flex"
          justifyContent="center"
          bg="transparent"
          borderRadius="0"
          px="3"
          _hover={{
            bg: '#ffffff2e',
          }}
          _focus={{
            bg: 'unset',
          }}
          alignItems="center"
          transition="all 0.3s"
          onClick={handlePrevious}
        >
          <SVG src={leftIcon} width="20px" height="20px" />
        </Button>
      )}
    </div>
  );
};
