import { Box, Map, MapMarker, MapPath } from '@swftbox/style-guide';
import { type SequenceStops } from 'src/components/Particles/resolvers/Jobs';
import { Config } from 'src/config';

interface JobDetailsMapProps {
  stops: SequenceStops[];
}

export const JobDetailsMap = ({ stops }: JobDetailsMapProps) => {
  return (
    <Box position="relative" height={'95%'}>
      <Map
        transition="all 0.3s"
        h="100%"
        center={Config.DEFAULT_MAP_CENTER}
        zoom={10}
        zoomControl={false}
      >
        {stops.slice(0, stops.length - 1).flatMap((stop, index) => (
          <MapPath
            key={stop.sequence}
            inProgress={+stop.completedCount !== +stop.totalCount}
            path={[
              {
                lat: stop.coordinates?.latitude,
                lng: stop.coordinates?.longitude,
              },
              {
                lat: stops[index + 1].coordinates?.latitude,
                lng: stops[index + 1].coordinates?.longitude,
              },
            ]}
          />
        ))}

        {stops.map((stop) => {
          return (
            <MapMarker
              key={stop.sequence}
              // @ts-expect-error it's just to ignore dummy ts warning
              label={stop?.sequence.toString()}
              position={{
                lat: +stop?.coordinates?.latitude,
                lng: +stop?.coordinates?.longitude,
              }}
            />
          );
        })}
      </Map>
    </Box>
  );
};
