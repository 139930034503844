import { useMutation } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import { clientOptionType } from '../../SharedTypes';
import {
  LIST_INVOICES,
  type SingleInvoiceMutationResponse,
  UPDATE_INVOICE,
  type UpdateInvoiceInput,
} from '../Queries';

interface Props {
  onCompleted: (
    data: { updateInvoice: SingleInvoiceMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export const useUpdateInvoiceMutation = ({ onCompleted }: Props) => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { updateInvoice: SingleInvoiceMutationResponse },
    { updateInvoiceInput: UpdateInvoiceInput }
  >(UPDATE_INVOICE, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_INVOICES,
        variables: QueriesStore.get('LIST_INVOICES'),
        pollInterval: 5000,
      },
    ],
  });

  return {
    mutate,
    reset,
    loading,
    error,
    ...rest,
  };
};
