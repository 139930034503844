import { SBAlertsCenter, SwftboxThemeProvider } from '@swftbox/style-guide';
import '@swftbox/style-guide/core/style.css';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';
import {
  AuthenticationProvider,
  ErrorBoundary,
  GraphQLProvider,
  SearchProvider,
  SidebarExpandProvider,
} from './components/Particles';
import { router } from './components/Particles/routes/index.routes';
import LanguageProvider from './components/Particles/utilities/languages/LanguageProvider';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://70a5ed52433f9e64cdda7f298f5d6746@o4506230129098752.ingest.sentry.io/4506230130606080',
  environment: import.meta?.env?.VITE_APP_ENV || 'development',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/devbe.swftbox.\.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

console.log('🚀 Birdseye v. 20240905');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary showDialog fallback={<p>An error has occurred</p>}>
    <GraphQLProvider>
      <AuthenticationProvider>
        <SwftboxThemeProvider>
          <LanguageProvider>
            <SidebarExpandProvider>
              <ErrorBoundary>
                <Helmet titleTemplate="%s - Birdseye" defaultTitle="Operations Portal" />
                <SearchProvider>
                  <RouterProvider router={router} />
                </SearchProvider>
                <SBAlertsCenter />
              </ErrorBoundary>
            </SidebarExpandProvider>
          </LanguageProvider>
        </SwftboxThemeProvider>
      </AuthenticationProvider>
    </GraphQLProvider>
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
);
