import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBCalendarIcon,
  SBModal,
  Text,
  useDisclosure,
} from '@swftbox/style-guide';
import { addMinutes, format } from 'date-fns';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
import SVG from 'react-inlinesvg';
import uuid from 'react-uuid';
import HomeTypeIcon from 'src/assets/icons/orders/home.svg';
import {
  formatDate,
  useConfigurations,
  type Order,
  DropProfileType,
} from 'src/components/Particles';
import { useRescheduleOrder } from 'src/components/Particles/resolvers/orders/reschedule.service';
import SelectTime from './SelectTime';

interface RescheduleModalProps {
  order: Order;
}
const options = [
  {
    colSpan: 12,
    label: 'All Day',
    start: '00:00',
    end: '23:59',
    id: 'all',
  },
  {
    colSpan: 6,
    label: '09:00 - 15:00',
    start: '09:00',
    end: '15:00',
    id: uuid(),
  },
  {
    colSpan: 6,
    label: '15:00 - 21:00',
    start: '15:00',
    end: '21:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '09:00 - 12:00',
    start: '09:00',
    end: '12:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '12:00 - 15:00',
    start: '12:00',
    end: '15:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '15:00 - 18:00',
    start: '15:00',
    end: '18:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '18:00 - 21:00',
    start: '18:00',
    end: '21:00',
    id: uuid(),
  },
];

const handleScheduleBG = (expectedEnd: any) => {
  if (!expectedEnd) return '#EFEFEF';
  //  @ts-expect-error
  const diff = new Date(expectedEnd) - new Date();
  const minutes = Math.floor(diff / 1000 / 60);
  if (minutes <= 30) return 'rgba(255, 142, 172, 1)';
  if (minutes <= 120) return 'rgba(255, 231, 142, 1)';
  return '#EFEFEF';
};
export function RescheduleModal({ order }: RescheduleModalProps) {
  const { configurations } = useConfigurations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useRescheduleOrder(order.id);
  const [date, setDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState({
    start: '',
    end: '',
    id: options[0].id,
  });

  const handleConfirm = () => {
    void mutate({
      variables: {
        rescheduleOrderInput: {
          orderId: order.id,
          customerDateStart: addMinutes(
            new Date(
              `${format(new Date(date), 'yyyy-MM-dd')}T${
                selectedTime.id === 'all'
                  ? configurations.find((config) => config?.key === 'defaultDayStartTime')?.value
                  : selectedTime.start
              }:00.001Z`
            ),
            new Date().getTimezoneOffset()
          ),

          customerDateEnd: addMinutes(
            new Date(
              `${format(new Date(date), 'yyyy-MM-dd')}T${
                selectedTime.id === 'all'
                  ? configurations.find((config) => config?.key === 'defaultDayEndTime')?.value
                  : selectedTime.end
              }:00.001Z`
            ),
            new Date().getTimezoneOffset()
          ),
        },
      },
      onCompleted(data) {
        onClose();
      },
    });
  };

  const expectedDateStart = order.isReverse
    ? order?.timing?.expectedPickupDateStart
    : order?.timing?.expectedDropDateStart;
  const expectedDateEnd = order.isReverse
    ? order?.timing?.expectedPickupDateEnd
    : order?.timing?.expectedDropDateEnd;

  const isStandard = order.dropProfile?.profileType === DropProfileType.standard;

  return (
    <>
      {order.status === 'draft' && order.scheduledPickupSlot ? (
        <Box bg="#EFEFEF" py="1" px="2" borderRadius="4" w="max-content">
          <HStack>
            <SVG src={HomeTypeIcon} />
            <Text fontSize="text-sx" fontWeight="bold">
              {'~' + order.scheduledPickupSlot.from}
            </Text>
            <Text fontSize="x-small" fontWeight="semibold" pe="8px">
              {formatDate(order.scheduledPickupSlot.date, 'MMM dd, yyyy') + ' schd.'}
            </Text>
          </HStack>
        </Box>
      ) : (
        <Box
          bg={handleScheduleBG(expectedDateEnd)}
          py="1"
          px="2"
          borderRadius="4"
          w="max-content"
          onClick={onOpen}
        >
          <HStack>
            <SVG src={HomeTypeIcon} />
            {!expectedDateStart && (
              <Text fontWeight="bold" align="center" minW="150px" pe="2">
                -
              </Text>
            )}
            {expectedDateStart && (
              <>
                {isStandard ? (
                  <Text fontSize="text-sx" fontWeight="bold">
                    Before {formatDate(expectedDateEnd, 'HH:mm')}
                  </Text>
                ) : (
                  <Text fontSize="text-sx" fontWeight="bold">
                    {formatDate(expectedDateStart, 'HH:mm')} -{' '}
                    {formatDate(expectedDateEnd, 'HH:mm')}
                  </Text>
                )}
                <Text fontSize="x-small" fontWeight="semibold" pe="8px">
                  {formatDate(expectedDateEnd, 'MMM dd, yyyy')}
                </Text>
              </>
            )}
          </HStack>
        </Box>
      )}

      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        header={
          <Box pb="1vh">
            <Text>Reschedule Customer Time slot</Text>
          </Box>
        }
        body={
          <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={7}>
            <GridItem colSpan={12}>
              <Box>
                <Text fontSize="text-sm" fontWeight="medium" color="gray.700" mb="1">
                  Select a date
                </Text>
                <HStack w="100%">
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="outline"
                      w="100%"
                      borderColor={'#00C2FF'}
                      bg={'#EFFBFF'}
                      color={'#00C2FF'}
                      _hover={{ opacity: '0.7' }}
                      _focus={{ opacity: '0.7' }}
                    >
                      <HStack justifyContent="space-between">
                        <Box />
                        <Box>{format(new Date(date), 'MMM dd, yyyy')}</Box>
                        <SBCalendarIcon width="15px" />
                      </HStack>
                    </MenuButton>
                    <MenuList>
                      <Calendar
                        onChange={(item) => {
                          setDate(item);
                        }}
                        date={date}
                      />
                    </MenuList>
                  </Menu>
                </HStack>
              </Box>
            </GridItem>
            <SelectTime
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              options={options}
            />
          </Grid>
        }
        //  @ts-expect-error
        scrollBehavior="outside"
        size="2xl"
        handleConfirm={handleConfirm}
      />
    </>
  );
}
