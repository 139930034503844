import {
  Button,
  HelperText,
  SBCommentIcon,
  SBModal,
  SBTextArea,
  Stack,
  Text,
  Tooltip,
  Box,
  useDisclosure,
  toast,
} from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import AddCommentIcon from 'src/assets/icons/orders/plus.svg';
import { type CommentType, useAddCommentMutation, type Order } from 'src/components/Particles';
import FileInput from 'src/components/Atom/FileInput/FileInput';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
interface AddCommentModalProps {
  order: Order;
  type?: CommentType;
  buttonText?: string;
}
export default function AddCommentModal(props: AddCommentModalProps) {
  const { order, type, buttonText } = props;
  const [file, setFile] = useState<File | undefined>();

  const { addComment, message, loading } = useAddCommentMutation();
  const {
    handleSubmit,
    register,
    setFocus,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    defaultValues: {},
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onConfirm = (data: any) => {
    void addComment({
      orderId: order.id,
      comment: data.comment,
      ...(!!type && { type }),
      attachments: [],
      onCompleted: () => {
        onClose?.();
        toast.success(message);
      },
    });
  };
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFocus('comment');
      }, 100); // set focus on the input field with the name 'packageCount'
    }
  }, [isOpen, setFocus]);
  return (
    <>
      <Tooltip label="Add Comment" hasArrow>
        <Button variant="link" onClick={onOpen} p="1" color="primary.900">
          {buttonText ? (
            <Text pl={5} pr={5}>
              {buttonText}
            </Text>
          ) : (
            <SVG src={AddCommentIcon} fill="currentColor" />
          )}
        </Button>
      </Tooltip>

      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBCommentIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Add Comment</Text>
          </Box>
        }
        size="2xl"
        body={
          <>
            <Stack gap={4}>
              <SBTextArea
                rows={2}
                placeholder="ex: don't forget to call before leave"
                {...register('comment')}
                label="Comment*"
              />
              <Stack>
                <FileInput file={file} setFile={setFile} fileInputLabel="Add Attachments" />
                {errors?.attachments && (
                  <HelperText variant="error">{errors?.attachments?.message}</HelperText>
                )}
              </Stack>
            </Stack>
          </>
        }
        bordered={false}
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              cancel
            </Button>
            <Button w="100%" isLoading={loading} onClick={handleSubmit(onConfirm)}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
