import {
  Box,
  Fade,
  FormLabel,
  HStack,
  SBEditIcon,
  SBInput,
  SBModal,
  Stack,
  Switch,
  Text,
  toast,
} from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';
import {
  Order,
  OrderPaymentMethod,
  useOrderModalActions,
  useOrderModalContext,
  useUpdateOrderPayment,
  type UpdateOrderPaymentInput,
  PaymentStatus,
} from 'src/components/Particles';

export function EditOrderPaymentModal() {
  const { editPaymentIsOpen, data: order } = useOrderModalContext() as {
    editPaymentIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();
  const [isPaymentOnDelivery, setIsPaymentOnDelivery] = useState(
    order.paymentMode === OrderPaymentMethod.PAYMENT_ON_DELIVERY
  );
  const [paymentAmount, setPaymentAmount] = useState(order.paymentAmount ?? 0);

  const { updateOrder } = useUpdateOrderPayment();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    dispatch({ type: 'CLOSE_EDIT_PAYMENT_ORDER' });
  };
  useEffect(() => {
    if (editPaymentIsOpen) {
      setIsPaymentOnDelivery(order.paymentMode === OrderPaymentMethod.PAYMENT_ON_DELIVERY);
      setPaymentAmount(order.paymentAmount ?? 0);
    }
  }, [editPaymentIsOpen]);
  function onConfirm() {
    if (isPaymentOnDelivery && !paymentAmount) {
      toast.error('Please enter payment amount');
      return;
    }

    const payload: UpdateOrderPaymentInput = {
      id: order.id,
      paymentMode: isPaymentOnDelivery
        ? OrderPaymentMethod.PAYMENT_ON_DELIVERY
        : OrderPaymentMethod.PREPAID,
      paymentAmount: isPaymentOnDelivery ? paymentAmount : null,
    };

    void updateOrder({
      payload,
      onCompleted: (message) => {
        handleCloseModal(message);
      },
    });
  }

  const isEditPaymentEnabled =
    !order?.status?.includes('rto') &&
    !order?.status?.includes('Rto') &&
    !['delivered', 'cancelled'].includes(order?.status) &&
    !(
      order.payment &&
      [PaymentStatus.APPROVED, PaymentStatus.PENDING].includes(order.payment?.status)
    );

  const showBanner =
    order.payment &&
    [PaymentStatus.APPROVED, PaymentStatus.PENDING].includes(order.payment?.status);

  return (
    <>
      <SBModal
        isOpen={editPaymentIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit Order Payment</Text>
          </Box>
        }
        size="xl"
        body={
          <Stack h={order.payment ? '15vh' : '10vh'}>
            {showBanner && (
              <HStack
                border={`1px solid #fbc70091`}
                boxShadow="xs"
                borderRadius="8px"
                p="8px"
                w="100%"
                bg="#fbc70091"
                marginBlock={3}
              >
                <SVG src={InfoIcon} color="#fbc70091" stroke="#000" />
                <Text fontWeight="semibold" fontSize="text-sm">
                  It seems that there is a payment associated with this order.
                </Text>
              </HStack>
            )}
            <HStack spacing={6}>
              <FormLabel htmlFor="isPaymentOnDelivery">Payment on delivery?</FormLabel>
              <Switch
                id="isPaymentOnDelivery"
                isChecked={isPaymentOnDelivery}
                onChange={() => {
                  setIsPaymentOnDelivery((prev) => !prev);
                }}
                disabled={!isEditPaymentEnabled}
              />
              <Fade in={isPaymentOnDelivery}>
                <SBInput
                  type="number"
                  width="10vw"
                  placeholder="Amount"
                  value={String(paymentAmount)}
                  onChange={(event) => {
                    setPaymentAmount(Number(event.target.value ?? 0));
                  }}
                  isDisabled={!isEditPaymentEnabled}
                />
              </Fade>
            </HStack>
          </Stack>
        }
        footer={!isEditPaymentEnabled ? <></> : undefined}
        handleConfirm={onConfirm}
      />
    </>
  );
}
