import { useMutation } from '@apollo/client';
import { MessageResponse } from '../../../SharedTypes';
import { RetailerDropInput, RETAILER_DROP_MUTATION } from '../../Queries';

export function useRetailerDropMutation() {
  const [mutate, { loading, error }] = useMutation<
    { dropAtRetailer: MessageResponse },
    { retailerDropInput: RetailerDropInput }
  >(RETAILER_DROP_MUTATION);

  async function retailerDrop(retailerDropInput: RetailerDropInput) {
    return mutate({
      variables: {
        retailerDropInput,
      },
      refetchQueries: [
        'PaginatedRoutes',
      ],
    });
  }

  return {
    retailerDrop,
    loading,
    error,
  };
}
