import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  SBTrashIcon,
  Td,
  Tooltip,
  Tr,
} from '@swftbox/style-guide';
import { memo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { type UpdateInvoiceLineItemInput } from 'src/components/Particles';
import styles from '../../style.module.scss';

interface AdjustmentItemProps {
  adjustmentLineItem: UpdateInvoiceLineItemInput;
  index: number;
  remove: UseFieldArrayRemove;
  onQuantityOrRateUpdated: () => void;
}
export const AdjustmentItem = memo(
  ({ adjustmentLineItem, index, remove, onQuantityOrRateUpdated }: AdjustmentItemProps) => {
    const { control } = useFormContext();
    return (
      <Tr borderTop={'none'}>
        <Td></Td>
        <Td>
          <Flex alignItems="center">
            {adjustmentLineItem.quantity < 0 ? 'Less' : 'Add'}:{' '}
            <Controller
              name={`adjustments.${index}.description`}
              control={control}
              defaultValue={adjustmentLineItem.description}
              render={({ field }) => (
                <Editable defaultValue={field.value} onSubmit={field.onChange} m={1}>
                  <EditablePreview className={styles.editableFieldPreview} />
                  <EditableInput className={styles.editableFieldInput} />
                </Editable>
              )}
            />
          </Flex>
        </Td>
        <Td colSpan={5}></Td>
        <Td>
          <Controller
            name={`adjustments.${index}.rate`}
            control={control}
            defaultValue={adjustmentLineItem.rate}
            render={({ field }) => (
              <Editable
                defaultValue={String(field.value)}
                onSubmit={(value) => {
                  field.onChange(parseFloat(value));
                  onQuantityOrRateUpdated();
                }}
              >
                <EditablePreview className={styles.editableFieldPreview} />
                <EditableInput className={styles.editableFieldInput} />
              </Editable>
            )}
          />
        </Td>
        <Td>
          <Tooltip label="Remove Adjustment" hasArrow>
            <IconButton
              size="small"
              variant="link"
              _hover={{ opacity: '0.5' }}
              aria-label="remove"
              color="gray.400"
              onClick={() => remove(index)}
              icon={<SBTrashIcon width="15px" />}
            />
          </Tooltip>
        </Td>
      </Tr>
    );
  }
);
