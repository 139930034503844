import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../SharedTypes';
import { ASSIGN_JOB_MUTATION, type AssignJobInput } from '../Queries';

interface AssignJobProps {
  payload: AssignJobInput;
  onCompleted?: (message: string) => void;
}

export function useAssignJob() {
  const [mutate, { loading, error }] = useMutation<
    { assignRoute: MessageResponse },
    { assignRouteInput: AssignJobInput }
  >(ASSIGN_JOB_MUTATION);

  async function assign({ payload, onCompleted }: AssignJobProps) {
    const { routeId, driverId } = payload;
    await mutate({
      variables: {
        assignRouteInput: {
          routeId,
          driverId,
        },
      },
      onCompleted: (data) => {
        if (onCompleted) onCompleted(data.assignRoute.message);
      },
      refetchQueries: ['PaginatedRoutes'],
    });
  }

  return {
    assign,
    loading,
    error,
  };
}
