import { createBrowserRouter } from 'react-router-dom';
import { DriversTrackingRoutes } from 'src/components/Particles/routes/Modules/DriversTracking.Module';
import { FulfillmentRoutes } from 'src/components/Particles/routes/Modules/Fulfillment.Module';
import App from '../../../App';
import { PrivateTemplate, PublicTemplate } from '../../Template';
import {
  DriversRoutes,
  ExpensesRoutes,
  JobsRoutes,
  LoginRoutes,
  OrdersRoutes,
  PartnersRoutes,
  PaymentRoutes,
  ReportsRoutes,
  UsersRoutes,
  TasksRoutes,
  AnalyticsRoutes,
  BillingRoutes,
} from './Modules';
import { SettingsRoutes } from './Modules/Settings.Module';
import { InvoicePage } from 'src/components/Pages/Billing/Components';
import { OperationLensRoutes } from './Modules/OperationLens.Module';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <PublicTemplate />,
        children: [LoginRoutes],
      },
      {
        path: '/',
        element: <PrivateTemplate />,
        children: [
          DriversTrackingRoutes,
          OrdersRoutes,
          FulfillmentRoutes,
          BillingRoutes,
          JobsRoutes,
          UsersRoutes,
          PartnersRoutes,
          DriversRoutes,
          PaymentRoutes,
          ReportsRoutes,
          ExpensesRoutes,
          OperationLensRoutes,
          SettingsRoutes,
          TasksRoutes,
          AnalyticsRoutes,
        ],
      },
      {
        path: '/invoice/:key',
        element: <InvoicePage />,
      },
    ],
    errorElement: <div>Cannot found Routes</div>,
  },
]);
