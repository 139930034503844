import { useMutation } from '@apollo/client';
import { removeTypeName } from '../../Helpers';
import { FILTER_ORDERS } from '../orders/orders.graphql';
import { QueriesStore } from '../queriesStore';
import { type MessageResponse } from '../SharedTypes';
import { UPDATE_CUSTOMER } from './users.graphql';
import { type UpdateCustomerInput } from './users.types';

interface UpdateUserProps {
  payload: UpdateCustomerInput;
  onCompleted?: (message: string) => void;
}

export function useUpdateCustomer() {
  const [mutate, { loading, error }] = useMutation<
    { updateCustomer: MessageResponse },
    { updateCustomerInput: UpdateCustomerInput }
  >(UPDATE_CUSTOMER);

  async function updateCustomer({ payload, onCompleted }: UpdateUserProps) {
    await mutate({
      variables: {
        updateCustomerInput: removeTypeName(payload),
      },
      onCompleted: ({ updateCustomer }) => {
        if (onCompleted) onCompleted(updateCustomer.message);
      },
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
      ],
    });
  }

  return {
    updateCustomer,
    loading,
    error,
  };
}
