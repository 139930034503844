import { Checkbox } from '@swftbox/style-guide';
import { type Order, useOrderSelection } from 'src/components/Particles';

interface OrderCheckboxAllProps {
  orders: Order[];
}

export function OrderCheckboxAll(props: OrderCheckboxAllProps) {
  const { orders } = props;
  const { selectedOrders, unselectAll, selectAll } = useOrderSelection();

  function toggleSelection() {
    if (selectedOrders.size !== orders.length) {
      selectAll(orders);
    } else {
      unselectAll();
    }
  }
  return (
    <Checkbox
      isIndeterminate={!!selectedOrders.size && selectedOrders.size !== orders.length}
      isChecked={!!selectedOrders.size && selectedOrders.size === orders.length}
      onChange={toggleSelection}
    />
  );
}
