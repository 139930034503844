import { Box, useDisclosure } from '@swftbox/style-guide';
import React, { useEffect, useState } from 'react';

import { type Item, useItems } from 'src/components/Particles';
import { OrderItemsTable } from './Components';
import RemoveItem from './Components/RemoveItem/RemoveItem';

interface ViewItemsProps {
  orderId: string;
  viewItemsComponent: React.ReactElement<any>;
}
export default function OrderItemsModal(props: ViewItemsProps) {
  const { orderId, viewItemsComponent } = props;
  const [removeItem, setRemoveItem] = useState<Item | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: removeIsOpen, onOpen: removeOnOpen, onClose: removeOnClose } = useDisclosure();

  const { Items, loading, getItems } = useItems();
  const [sortedItems, setSortedItems] = useState<Item[]>([]);

  const handleRemoveItem = (item: Item) => {
    setRemoveItem(item);
    removeOnOpen();
    onClose();
  };

  const handleCloseForm = () => {
    setRemoveItem(undefined);
    removeOnClose();
    onOpen();
  };

  const handleClose = () => {
    setRemoveItem(undefined);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      getItems(orderId);
    }
  }, [orderId, getItems, isOpen]);

  const clickableViewItemsComponent = React.cloneElement(viewItemsComponent, {
    onClick: onOpen,
  });
  useEffect(() => {
    if (Items.length) {
      const ItemsClose = JSON.parse(JSON.stringify(Items));
      setSortedItems([
        ...ItemsClose.sort((a: Item, b: Item) => ((a.sku as string) < (b.sku as string) ? -1 : 1)),
      ]);
    }
  }, [Items]);
  return (
    <>
      {clickableViewItemsComponent}

      <Box>
        <RemoveItem
          item={removeItem}
          onClose={handleCloseForm}
          isOpen={removeIsOpen && !!removeItem}
        />
        <OrderItemsTable
          items={sortedItems}
          onClose={handleClose}
          onRemoveItem={handleRemoveItem}
          isOpen={isOpen}
          orderId={orderId}
          loading={loading}
        />
      </Box>
    </>
  );
}
