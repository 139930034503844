import { useMutation } from '@apollo/client';
import { UPDATE_DEMO_SETTING } from './DemoSetting.graphql';
interface RequestPayloadType {
  revealInventoryPage: boolean;
  id: string;
}
interface Response {
  message: string;
  data: RequestPayloadType;
}

export function useUpdateDemoSettingMutation() {
  const [mutate, { data, loading, error, reset }] = useMutation<
    { updateRetailer: Response },
    { updateRetailerInput: RequestPayloadType }
  >(UPDATE_DEMO_SETTING);

  async function updateDemoSetting(payload: RequestPayloadType) {
    return mutate({
      variables: {
        updateRetailerInput: payload,
      },
    });
  }

  return {
    updateDemoSetting,
    reset,
    retailer: data?.updateRetailer,
    loading,
    error,
  };
}
