import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import { InvoiceType } from 'src/components/Particles';
import { Config } from 'src/config';

export const fetchInvoiceData = async (invoiceId: string, invoiceType: InvoiceType) => {
  const invoiceKey = btoa(invoiceId);
  const url = `${Config.GRAPHQL_ENDPOINT.replace(
    'graphql',
    ''
  )}api/invoices/${invoiceType}/${invoiceKey}`;

  try {
    const response = await axios.get(url);
    if (!response.data) {
      console.error('No data returned for invoice:', invoiceId);
      return null;
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching the template:', error);
    return null;
  }
};

export async function downloadHTMLAsPDF(htmlContent: string, filename: string) {
  const container = document.createElement('div');
  container.innerHTML = htmlContent;
  document.body.appendChild(container);
  container.style.width = '1214px'

  const canvas = await html2canvas(container, { scale: 3 });
  const imgData = canvas.toDataURL('image/jpeg');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 210; // A4 width in mm
  const pageHeight = 295; // A4 height in mm
  const margin = 10;

  const imgHeight = (canvas.height * (imgWidth - 2 * margin)) / canvas.width;
  let heightLeft = imgHeight;
  let position = margin;

  pdf.addImage(imgData, 'JPEG', margin, position, imgWidth - 2 * margin, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft > 0) {
    position = heightLeft - imgHeight;
    pdf.addPage();
    pdf.addImage(imgData, 'JPEG', margin, position, imgWidth - 2 * margin, imgHeight);
    heightLeft -= pageHeight;
  }

  pdf.save(filename);

  document.body.removeChild(container);
}
