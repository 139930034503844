import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { type CompanyStatus, type SimpleRetailer } from './retailer.types';
import { GET_SIMPLE_RETAILERS } from './retailers.graphql';

interface QueryResponse {
  paginatedRetailers: {
    data: SimpleRetailer[];
    total: number;
    pageCount: number;
  };
}

interface QueryVariables {
  page: number;
  perPage: number;
  status?: CompanyStatus[];
  name?: string;
  keyword?: string;
}

export function useSimpleRetailersQuery() {
  const [query, { data, loading, error, called }] =
    useLazyQuery<QueryResponse>(GET_SIMPLE_RETAILERS);

  const getSimpleRetailers = useCallback(
    (variables: QueryVariables) => {
      void query({
        variables,
      });
    },
    [query]
  );

  return {
    getSimpleRetailers,
    retailers: data?.paginatedRetailers?.data ?? [],
    totalRetailers: data?.paginatedRetailers?.total ?? 0,
    pageCount: data?.paginatedRetailers?.pageCount ?? 0,
    loading,
    called,
    error,
  };
}
