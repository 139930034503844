import { Button, toast } from "@swftbox/style-guide";
import { FC } from "react";
import { Job, useStartJobMutation } from "src/components/Particles";

interface Props {
  job: Job;
}

export const StartJob: FC<Props> = ({ job }) => {

  const { startJob, loading } = useStartJobMutation();

  const handleClick = async () => {
    const response = await startJob(job.id);
    toast.success(response.data?.startRoute.message);
  }

  return (
    <Button type="button" onClick={handleClick} disabled={loading}>
      Start Job
    </Button>
  );
}

export default StartJob;