import { Box, Flex, useDisclosure } from '@swftbox/style-guide';
import { GenerateReportModal, PageHeader, ReportsTable } from './Components';
import {
  type ReportStatus,
  type ReportType,
  type ReportsFilterArgs,
  SortOptions,
  useReportsQuery,
} from 'src/components/Particles';
import style from './style.module.scss';
import { Filters } from './Components/Filters';
import { useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';

export function ReportsPage() {
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<ReportsFilterArgs>({
    perPage: 50,
    page: 1,
    orderBy: 'createdAt',
    order: SortOptions.DESC,
  });
  const [retailerId, setRetailerId] = useState<string | null>();
  const [driverId, setDriverId] = useState<string | null>();
  const [assigneeId, setAssigneeId] = useState<string | null>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { reports, loading, total } = useReportsQuery(filters);

  const onFilterChange = (key: 'status' | 'type', selectedFilter: ReportType | ReportStatus) => {
    setFilters({ ...filters, [key]: selectedFilter });
  };

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      retailerId: retailerId as string | undefined,
      driverId: driverId as string | undefined,
      assigneeId: assigneeId as string | undefined,
    }));
  }, [retailerId, driverId, assigneeId]);

  return (
    <Box className={style.reportsPage}>
      <PageHeader
        title="Report"
        count={total ?? 0}
        buttonText={'Generate Report'}
        onButtonClick={onOpen}
      />
      <Filters
        onFilterChange={onFilterChange}
        retailerId={retailerId}
        setRetailerId={setRetailerId}
        driverId={driverId}
        setDriverId={setDriverId}
        assigneeId={assigneeId}
        setAssigneeId={setAssigneeId}
      />
      <ReportsTable
        reports={reports ?? []}
        page={page}
        total={total ?? 0}
        onPageChange={(page) => {
          setPage(page);
        }}
      />
      {!loading && !reports?.length && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Reports Found" />
        </Flex>
      )}
      <GenerateReportModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </Box>
  );
}
