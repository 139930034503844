export enum OrdersPageFilter {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  READY = 'READY',
  ACTIVE = 'ACTIVE',
  DELIVERED = 'DELIVERED',
  RTO = 'RTO',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  // new Filters
  ASSIGNED = 'ASSIGNED',
  IN_TRANSIT = 'IN_TRANSIT',
  TRANSIT_ASSIGNED = 'TRANSIT_ASSIGNED',
  RTO_COMPLETE = 'RTO_COMPLETE',
  PENDING_RECEIVE = 'PENDING_RECEIVE',
}
export const OrderStatusAfterAssigned = [
  'pickupsToReceive',
  'processing',
  'transitAssigned',
  'inTransit',
  'failedAttempt',
  'cancelled',
  'rtoInTransit',
  'delivered',
  'assigned',
  'delivering',
  'pickedUp',
  'pickupAssigned',
  'attempted',
  'rtnAssigned',
  'rtnPickedUp',
  'rtnAtDepot',
  'rtnCompleted',
  'rtnToPartnerAssigned',
  'rtoAssigned',
  'rtoPickedUp',
  'rtoComplete',
  'rtnToPartner',
  'fulfillmentRtoComplete',
];
export enum OrdersStatusFilter {
  RTN_RTO = 'RTO_RTN',
  ALL = 'allOrders',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CLOSED = 'closed',
  PICKUPS_TO_RECEIVE = 'pickupsToReceive',
  REQUESTED = 'requested',
  PROCESSING = 'processing',
  READY_FOR_PICKUP = 'readyForPickup',
  READY = 'ready',
  TRANSIT_ASSIGNED = 'transitAssigned',
  IN_TRANSIT = 'inTransit',
  FLAGGED = 'flagged',
  CS_CHECK = 'csCheck',
  STARRED = 'starred',
  FAILED_ATTEMPT = 'failedAttempt',
  CANCELLED = 'cancelled',
  RTO = 'rto',
  RTO_IN_TRANSIT = 'rtoInTransit',
  DRAFT = 'draft',
  IN_FULFILLMENT = 'inFulfillment',
  DELIVERED = 'delivered',
  ASSIGNED = 'assigned',
  DELIVERING = 'delivering',
  PICKED_UP = 'pickedUp',
  PICKUP_REQUESTED = 'pickupRequested',
  PICKUP_ASSIGNED = 'pickupAssigned',
  UNASSIGNED = 'unassigned',
  ATTEMPTED = 'attempted',
  RTN_REQUESTED = 'rtnRequested',
  RTN_ASSIGNED = 'rtnAssigned',
  RTN_PICKED_UP = 'rtnPickedUp',
  RTN_AT_DEPOT = 'rtnAtDepot',
  RTN_COMPLETED = 'rtnCompleted',
  RTN_TO_PARTNER_ASSIGNED = 'rtnToPartnerAssigned',
  RTN_TO_PARTNER = 'rtnToPartner',
  RTN_FAILED = 'rtnFailed',
  RTO_INITIATED = 'rtoInitiated',
  RTO_ASSIGNED = 'rtoAssigned',
  RTO_PICKED_UP = 'rtoPickedUp',
  RTO_COMPLETE = 'rtoComplete',
  FULFILLMENT_RTO_COMPLETE = 'fulfillmentRtoComplete',
  ELIGIBLE_RTO = 'eligibleRTO',
}

export enum OrdersPageSort {
  URGENT = 'URGENT',
  OLDEST = 'OLDEST',
}

export interface IAdvancedFilters {
  retailerId?: string;
  dropProfileType?: string;
  dropProfile?: string;
  dispatchZone?: string[];
  partnerCategoryIds?: string[];

  or?: {
    dropProfileKey?: string[];
    isReverse?: boolean;
  };
  toCity?: string[];
  fromCity?: string[];
  isReverse?: boolean;
}
