import { useQuery } from '@apollo/client';
import { LIST_AREAS, type Area } from '../Queries';

interface ListAreasResponse {
  areas: Area[];
}

export function useAreasQuery() {
  const { data, loading, error } = useQuery<ListAreasResponse>(LIST_AREAS);

  return {
    areas: data?.areas ?? [],
    loading,
    error,
  };
}
