import { createContext, useContext } from 'react';
import {
  type Address,
  type SimpleOrder,
  type SimpleRetailer,
} from 'src/components/Particles/resolvers';

export enum CreateJobListItemType {
  ORDER = 'order',
  RTO_ORDER = 'rtoOrder',
  RETAILER = 'retailer',
}

interface ItemsSelectionContextProps {
  orders: Map<string, SimpleOrder>;
  rtoOrders: Map<string, SimpleOrder>;
  retailers: Map<string, SimpleRetailer & { location: Address }>;
  toggleSelection: (type: CreateJobListItemType, item: SimpleOrder | SimpleRetailer) => void;
}

export const JobItemsSelectionContext = createContext<ItemsSelectionContextProps>({
  orders: new Map(),
  rtoOrders: new Map(),
  retailers: new Map(),
  toggleSelection: console.log,
});

export const useJobItemsSelectionContext = () =>
  useContext<ItemsSelectionContextProps>(JobItemsSelectionContext);
