import { Box } from '@swftbox/style-guide';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/components/Particles';

export const Settings = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    if (!!user?.roles?.length) {
      if (user.roles.includes('SuperAdmin') && !pathname.startsWith('/settings/'))
        return navigate('/settings/configuration');
      if (
        !user.roles.includes('SuperAdmin') &&
        (user.roles.includes('Admin') ||
          (user?.roles?.includes('Dispatcher') && !pathname.startsWith('/settings/')))
      )
        return navigate('/settings/password');
    }
  }, [user]);
  return (
    <Box pt="8">
      <Outlet />
    </Box>
  );
};
