import { useState, useEffect } from 'react';
import { Box, Text } from '@swftbox/style-guide';
import CommentsTable from './CommentsTable';
import CommentForm from './CommentForm';
import { type Comment, useCommentsQuery } from 'src/components/Particles';

interface Props {
  orderId: string;
}

export default function Comments(props: Readonly<Props>) {
  const [showForm, setShowForm] = useState(false);
  const { orderId } = props;
  const [editComment, setEditComment] = useState<Comment>();
  const { getComments, comments, loading } = useCommentsQuery(orderId);

  useEffect(() => {
    getComments({ orderId });
  }, [orderId, getComments]);

  const handleEditComment = (comment: Comment) => {
    setEditComment(comment);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditComment(undefined);
  };

  return (
    <Box>
      {!showForm ? (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
          >
            <Text fontWeight={'bold'}></Text>
          </Box>
          <CommentsTable comments={comments} editComment={handleEditComment} loading={loading} />
        </>
      ) : (
        <CommentForm orderId={orderId} comment={editComment} onClose={handleCloseForm} />
      )}
    </Box>
  );
}
