import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  IconButton,
  SBModal,
  toast,
  useDisclosure,
  Text,
  SBEditIcon,
  HStack,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import Edit01Icon from 'src/assets/icons/General/Edit01.svg';
import { CarrierSchema } from './carrier.schema';
import { CarrierForm } from './carrier.form';
import {
  useUpdateCarriyoIntegrationCarrier,
  type UpdateCarriyoIntegrationCarrierInput,
} from 'src/components/Particles';

interface EditCarrierProps {
  carrier: UpdateCarriyoIntegrationCarrierInput;
}

export function EditCarrier({ carrier }: EditCarrierProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateCarriyoIntegrationCarrier } = useUpdateCarriyoIntegrationCarrier();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateCarriyoIntegrationCarrierInput>({
    mode: 'all',
    resolver: yupResolver(CarrierSchema),
    defaultValues: { ...carrier },
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = (data: UpdateCarriyoIntegrationCarrierInput) => {
    void updateCarriyoIntegrationCarrier({
      updateCarriyoIntegrationCarrierInput: {
        id: data.id,
        accountId: data.accountId,
        accountName: data.accountName,
        isActive: data.isActive,
      },
      onCompleted: (message) => {
        handleCloseModal(message);
      },
    });
  };

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit Carrier"
        icon={<SVG src={Edit01Icon} stroke="currentColor" width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <HStack justifyContent="space-between">
              <Text>Edit Carrier</Text>
            </HStack>
          </Box>
        }
        size="xl"
        body={<CarrierForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
