import { addDays, endOfDay, startOfDay } from 'date-fns/esm';
import { type ReactNode, useReducer } from 'react';
import { OrdersPageFilter } from 'src/components/Pages/Orders/order.types';
import {
  OrdersFiltersActionsContext,
  OrdersFiltersContext,
} from 'src/components/Particles/Contexts/Order';
import { rootReducer } from './Filters';
import { Config } from 'src/config';

export const initialOrderFiltersRoot = {
  filters: {
    page: 1,
    stateStageFilter: {},
    dateFilter: {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      isSelected: true,
    },
    sortFilter: {},
    advancedFilters: {},
    status: [],
    statusId: [],
    state: OrdersPageFilter.ALL,
  },
  organizedFilters: {
    dateFilter: {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      isSelected: !Config.PRIMEXPRESS_BUILD,
      key: 'createdAt',
    },
  },
};

export function OrderFiltersProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(rootReducer, initialOrderFiltersRoot);

  return (
    <OrdersFiltersActionsContext.Provider value={dispatch}>
      <OrdersFiltersContext.Provider value={state}>{children} </OrdersFiltersContext.Provider>
    </OrdersFiltersActionsContext.Provider>
  );
}
