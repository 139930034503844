import { Helmet } from 'react-helmet';
import { Config } from 'src/config';
import favSwftbox from 'src/assets/fav/favicon.ico';
import favPrimex from 'src/assets/fav/Primex_favicon.ico';
export const MainHelmet = () => {
  return (
    <Helmet>
      <link
        href={Config.PRIMEXPRESS_BUILD ? favPrimex : favSwftbox}
        rel="icon"
        type="image/svg+xml"
      />
    </Helmet>
  );
};
