import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers(
    $page: Int
    $roles: [String!]
    $status: AccountStatus
    $perPage: Int
    $excludeRoles: [String!]
    $order: OrderSort
    $orderBy: String
    $keyword: String
    $retailerUserRoles: [RetailerUserRole!]
    $retailerId: UUID
  ) {
    paginatedUsers(
      page: $page
      roles: $roles
      status: $status
      perPage: $perPage
      excludeRoles: $excludeRoles
      order: $order
      orderBy: $orderBy
      keyword: $keyword
      retailerUserRoles: $retailerUserRoles
      retailerId: $retailerId
    ) {
      data {
        id
        name
        phone
        email
        status
        roles
        permissions
        emailVerifiedAt
      }
      total
      pageCount
    }
  }
`;

export const GET_USER = gql`
  query User($userId: UUID!) {
    user(id: $userId) {
      id
      createdAt
      updatedAt
      deletedAt
      name
      email
      phone
      status
      roles
      permissions
    }
  }
`;

export const GET_USERS_SIMPLE_LIST = gql`
  query Users($roles: [String!]) {
    users(roles: $roles) {
      id
      name
    }
  }
`;


export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      createdAt
      updatedAt
      deletedAt
      name
      email
      phone
      status
      roles
      permissions
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($updateUserStatusInput: UpdateUserStatusInput!) {
    updateUserStatus(UpdateUserStatusInput: $updateUserStatusInput) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($updateCustomerInput: UpdateCustomerInput!) {
    updateCustomer(UpdateCustomerInput: $updateCustomerInput) {
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation RemoveUser($removeUserId: UUID!) {
    removeUser(id: $removeUserId) {
      success
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
      createdAt
      updatedAt
      deletedAt
      name
      email
      phone
      status
      roles
      permissions
    }
  }
`;
