import {
  Grid,
  GridItem,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import React from 'react';
import { type InvoiceSetting } from 'src/components/Particles';
import { AddInvoiceLineItem, EditInvoiceLineItem, RemoveLineItem } from '../Modals';

interface InvoiceLineItemsListProps {
  invoiceLineItems: InvoiceSetting[];
}

export const InvoiceLineItemsList = React.memo(
  ({ invoiceLineItems }: InvoiceLineItemsListProps) => {
    return (
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem
          colSpan={12}
          boxShadow="sm"
          bg="#fff"
          border=" 1px solid #EAECF0"
          borderRadius="12px"
        >
          <Grid templateColumns={`repeat(12, 1fr)`} gap="6" p="6">
            <GridItem colSpan={6}>
              <Text fontSize="text-md" fontWeight="medium" color="gray.700">
                Invoice Line Items Settings
              </Text>
            </GridItem>
            <GridItem colSpan="auto" gridColumnStart={12}>
              <HStack>
                <AddInvoiceLineItem />
              </HStack>
            </GridItem>
          </Grid>
          <TableContainer whiteSpace="normal">
            <Table variant="striped" overflow="auto">
              <Thead>
                <Tr bg="#fbfbfc">
                  <Th left="0">Code</Th>
                  <Th left="0">Rate</Th>
                  <Th left="0">Description</Th>
                  <Th left="0">VAT</Th>
                  <Th left="0">Type</Th>
                  <Th left="0"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoiceLineItems.map((invoiceLineItem) => (
                  <Tr key={invoiceLineItem.id}>
                    <Td left="0" top="0">
                      <Text>{invoiceLineItem?.code}</Text>
                    </Td>
                    <Td left="0" top="0">
                      <Text>{invoiceLineItem?.rate}</Text>
                    </Td>
                    <Td left="0" top="0">
                      <Text>{invoiceLineItem?.description}</Text>
                    </Td>
                    <Td left="0" top="0">
                      <Text>{invoiceLineItem?.vat}</Text>
                    </Td>
                    <Td left="0" top="0">
                      <Text>{invoiceLineItem?.type}</Text>
                    </Td>
                    <Td right="0">
                      <HStack>
                        <EditInvoiceLineItem invoiceLineItem={invoiceLineItem} />
                        {!invoiceLineItem.isDefault && (
                          <RemoveLineItem invoiceLineItem={invoiceLineItem} />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
      </Grid>
    );
  }
);
