import { useState, useEffect } from 'react';
import { Box, Text, Button, SBAddIcon } from '@swftbox/style-guide';
import CommentsTable from './CommentsTable';
import CommentForm from './CommentForm';
import { Comment, useCommentsQuery } from 'src/components/Particles';

interface Props {
  orderId: string;
  comments: Comment[];
}

export default function Comments(props: Props) {
  const [showForm, setShowForm] = useState(false);
  const { orderId, comments } = props;
  const [editComment, setEditComment] = useState<Comment>();

  const handleEditComment = (comment: Comment) => {
    setEditComment(comment);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditComment(undefined);
  };

  return (
    <Box>
      {!showForm ? (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
          >
            <Text fontWeight={'bold'}></Text>
            {/* <Button variant="outline" size="sm" onClick={setShowForm.bind(null, true)}>
              <SBAddIcon width="15px" />
              <Text>Add new</Text>
            </Button> */}
          </Box>
          <CommentsTable comments={comments} editComment={handleEditComment} />
        </>
      ) : (
        <CommentForm orderId={orderId} comment={editComment} onClose={handleCloseForm} />
      )}
    </Box>
  );
}
