import { useMutation } from '@apollo/client';
import {
  GET_FEEDBACK_QUESTIONS_QUERY,
  UPDATE_FEEDBACK_QUESTION,
  type UpdateQuestion,
} from '../Queries';
import { type clientOptionType } from '../../../SharedTypes';

interface EditQuestionResponse {
  message: string;
}
interface EditQuestionsProps {
  onCompleted: (
    data: { updateFeedbackQuestion: EditQuestionResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useEditQuestion({ onCompleted }: EditQuestionsProps) {
  const [mutate, { error, loading }] = useMutation<
    { updateFeedbackQuestion: EditQuestionResponse },
    { input: UpdateQuestion }
  >(UPDATE_FEEDBACK_QUESTION, {
    onCompleted,
    refetchQueries: [
      {
        query: GET_FEEDBACK_QUESTIONS_QUERY,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
