import { Box, Button, Flex, HStack } from '@swftbox/style-guide';
import { FC, ReactNode } from 'react';
import { DriverSelectInput } from 'src/components/Organisms/DriverSelectInput';
import { RetailerSelectInput } from 'src/components/Organisms/RetailerSelectInput';
import { SettlementType, SettlementFilterArgs, SubjectType } from 'src/components/Particles';

interface Props extends SettlementFilterArgs {
  onChange: (filter: SettlementFilterArgs) => void;
  showExpensesFilter?: boolean;
}

const settlementStatuses = [
  {
    header: 'All',
    value: undefined,
  },
  {
    header: 'Approved',
    value: 'APPROVED',
  },
  {
    header: 'Pending',
    value: 'PENDING',
  },
];

const ButtonGroup: FC<{ children: ReactNode }> = ({ children }) => (
  <HStack
    border="1px solid #D0D5DD;"
    h="40px"
    lineHeight="40px"
    borderRadius="8px"
    shadow="xs"
    overflow="hidden"
    w="fit-content"
    spacing="0"
    marginRight={2}
  >
    {children}
  </HStack>
);

export const SettlementsFilter: FC<Props> = ({ onChange, ...props }) => {
  const { type, subjectId, subjectType, status, showExpensesFilter } = props;

  return (
    <>
      <Flex align="center" p="20px 24px 0">
        <ButtonGroup>
          {settlementStatuses.map((statusType) => (
            <Button
              key={statusType.header}
              variant="grouped"
              p="8px 12px"
              fontSize="x-small"
              bg={statusType.value === status ? '#63c3ec' : 'transparent'}
              color={statusType.value === status ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => onChange({ ...props, status: statusType.value })}
            >
              {statusType.header}
            </Button>
          ))}
        </ButtonGroup>
        <ButtonGroup>
          <Button
            variant="grouped"
            p="8px 12px"
            fontSize="x-small"
            bg={subjectType === undefined ? '#63c3ec' : 'transparent'}
            color={subjectType === undefined ? '#fff' : 'gray.900'}
            _focus={{ opacity: 0.85 }}
            _hover={{ opacity: 0.85 }}
            onClick={() => onChange({ ...props, subjectType: undefined })}
          >
            All
          </Button>
          {Object.values(SubjectType).map((type) => (
            <Button
              key={`settlement-${type}`}
              variant="grouped"
              p="8px 12px"
              fontSize="x-small"
              bg={type === subjectType ? '#63c3ec' : 'transparent'}
              color={type === subjectType ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => onChange({ ...props, subjectType: type })}
            >
              {type}
            </Button>
          ))}
        </ButtonGroup>

        {showExpensesFilter && (
          <ButtonGroup>
            <Button
              variant="grouped"
              p="8px 12px"
              fontSize="x-small"
              bg={type === undefined ? '#63c3ec' : 'transparent'}
              color={type === undefined ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => onChange({ ...props, type: undefined, expenses: true })}
            >
              All
            </Button>
            {Object.values(SettlementType).map((settlementType) => {
              if (
                settlementType !== SettlementType.ORDER &&
                settlementType !== SettlementType.DRIVER_COMPENSATION
              ) {
                return (
                  <Button
                    key={`settlement-${settlementType}`}
                    variant="grouped"
                    p="8px 12px"
                    fontSize="x-small"
                    bg={type === settlementType ? '#63c3ec' : 'transparent'}
                    color={type === settlementType ? '#fff' : 'gray.900'}
                    _focus={{ opacity: 0.85 }}
                    _hover={{ opacity: 0.85 }}
                    onClick={() => onChange({ ...props, type: settlementType, expenses: false })}
                  >
                    {settlementType}
                  </Button>
                );
              }
            })}
          </ButtonGroup>
        )}
      </Flex>
      <Box p="20px 24px">
        {subjectType === SubjectType.DRIVER && (
          <DriverSelectInput
            onChange={(value) => onChange({ ...props, subjectId: value || undefined })}
            value={subjectId || null}
          />
        )}
        {subjectType === SubjectType.RETAILER && (
          <RetailerSelectInput
            onChange={(value) => onChange({ ...props, subjectId: value || undefined })}
            value={subjectId || null}
          />
        )}
      </Box>
    </>
  );
};
