import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  FormLabel,
  HelperText,
  HStack,
  If,
  Radio,
  RadioGroup,
  SBInput,
  Stack,
  toast,
  VStack,
} from '@swftbox/style-guide';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  uploadFile,
  useAddDriverMutation,
  useGetUploadUrlQuery,
  type GetUploadUrlVariables,
} from 'src/components/Particles';
import {
  getExtensionValue,
  splitFileName,
  useOperatorsQuery,
  useUpdateDriverMutation,
} from 'src/components/Particles/resolvers';
import FileInput from '../../../../Atom/FileInput/FileInput';
import { type DriverFormProps, type FormValues } from '../driver.types';
import { CreateDriverSchema } from '../Validation';
import { Config } from 'src/config';

export function DriverForm(props: DriverFormProps) {
  const { mode, data, onClose } = props;
  const defaultValues =
    mode === 'create'
      ? { gender: 'MALE', employmentType: Config.PRIMEXPRESS_BUILD ? 'PRIMEX' : 'SWFTBOX' }
      : { ...data };
  const {
    register,
    formState: { errors },
    setError,
    watch,
    handleSubmit,
    setValue,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(CreateDriverSchema),
    mode: 'all',
    defaultValues,
  });
  const { getOperators, operators } = useOperatorsQuery();
  const { createDriver, loading, isUserAdded, error: addError } = useAddDriverMutation();
  const { updateDriver, updating, isUserUpdated, error: updateError } = useUpdateDriverMutation();

  const { getUploadUrl } = useGetUploadUrlQuery();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [photo, setPhoto] = useState<File | undefined>();
  const [licensePhoto, setLicensePhoto] = useState<File | undefined>();
  const [idPhoto, setIdPhoto] = useState<File | undefined>();
  function onPhoneChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const phone = value.includes('+') ? value : `+${value}`;
    setValue('user.phone', phone);
  }

  const onSubmit = handleSubmit(async (submittedData) => {
    setIsUploadingFiles(true);

    const { canProceed, photoUploadArgs, licensePhotoUploadArgs, idPhotoUploadArgs } =
      prepareFilesData();
    if (!canProceed) {
      setIsUploadingFiles(false);
      return;
    }
    const uploadPromises = [];
    let photoFileUploadURL, licensePhotoFileUploadURL, idPhotoFileUploadURL;
    if (licensePhotoUploadArgs)
      licensePhotoFileUploadURL = await getUploadUrl(licensePhotoUploadArgs).catch(() => null);
    if (idPhotoUploadArgs)
      idPhotoFileUploadURL = await getUploadUrl(idPhotoUploadArgs).catch(() => null);
    if (photoUploadArgs) photoFileUploadURL = await getUploadUrl(photoUploadArgs).catch(() => null);

    if (licensePhotoFileUploadURL && licensePhoto) {
      uploadPromises.push(uploadFile(licensePhotoFileUploadURL.url, licensePhoto));
    }
    if (photoFileUploadURL && photo) {
      uploadPromises.push(uploadFile(photoFileUploadURL.url, photo));
    }
    if (idPhotoFileUploadURL && idPhoto) {
      uploadPromises.push(uploadFile(idPhotoFileUploadURL.url, idPhoto));
    }
    const areUploaded = await Promise.all(uploadPromises).catch(() => false);

    setIsUploadingFiles(false);
    if (!areUploaded) {
      toast.error('Cannot upload your files');
      return;
    }

    if (mode !== 'create' && !!data) {
      const payload = {
        id: data.id,
        isOnline: data.isOnline,
        birthday: moment(submittedData.birthday).format('YYYY-MM-DD'),
        gender: submittedData.gender,
        employmentType: submittedData.employmentType,
        operatorId:
          submittedData.employmentType === 'OPERATOR' ? submittedData.operatorId : undefined,
        licenseNumber: submittedData.licenseNumber,
        nationalId: submittedData.nationalId,
        photo: photoFileUploadURL ? photoFileUploadURL.filePath : submittedData.photo,
        licensePhoto: licensePhotoFileUploadURL
          ? licensePhotoFileUploadURL.filePath
          : submittedData.licensePhoto,
        nationalIdPhoto: idPhotoFileUploadURL
          ? idPhotoFileUploadURL.filePath
          : submittedData.nationalIdPhoto,
        vehicle: {
          contractor: submittedData.vehicle.contractor,
          type: submittedData.vehicle.type,
          model: submittedData.vehicle.model,
          plateNumber: submittedData.vehicle.plateNumber,
        },
        user: {
          name: submittedData.user.name,
          email: submittedData.user.email,
          phone: submittedData.user.phone,
        },
      };

      await updateDriver(payload);
    } else {
      await createDriver({
        ...submittedData,
        operatorId:
          submittedData.employmentType === 'OPERATOR' ? submittedData.operatorId : undefined,
        photo: photoFileUploadURL?.filePath,
        licensePhoto: licensePhotoFileUploadURL?.filePath,
        nationalIdPhoto: idPhotoFileUploadURL?.filePath,
        birthday: moment(submittedData.birthday).format('YYYY-MM-DD'),
      });
    }
  });
  const watchedData = watch();

  useEffect(() => {
    if (isUserAdded || isUserUpdated) {
      const message = 'Driver ' + (mode === 'create' ? 'added' : 'updated') + ' successfully';
      toast.success(message);
      onClose();
    }
  }, [isUserAdded, isUserUpdated]);

  function prepareFilesData() {
    let photoUploadArgs: GetUploadUrlVariables | undefined;
    if (photo) {
      const { extension: photoFileExtension } = splitFileName(photo.name);
      if (!photoFileExtension) {
        setError('photo', { message: 'Cannot obtain file extension' });
        return { canProceed: false };
      }
      photoUploadArgs = {
        fileType: getExtensionValue(photoFileExtension),
        fileDirectory: 'PROFILE_IMAGES',
      };
    }
    let licensePhotoUploadArgs: GetUploadUrlVariables | undefined;
    if (licensePhoto) {
      const { extension: licensePhotoFileExtension } = splitFileName(licensePhoto.name);
      if (!licensePhotoFileExtension) {
        setError('licensePhoto', { message: 'Cannot obtain file extension' });
        return { canProceed: false };
      }

      licensePhotoUploadArgs = {
        fileType: getExtensionValue(licensePhotoFileExtension),
        fileDirectory: 'LICENCES',
      };
    }

    let idPhotoUploadArgs: GetUploadUrlVariables | undefined;
    if (idPhoto) {
      const { extension: idPhotoFileExtension } = splitFileName(idPhoto.name);
      if (!idPhotoFileExtension) {
        setError('nationalIdPhoto', { message: 'Cannot obtain file extension' });
        return { canProceed: false };
      }
      idPhotoUploadArgs = {
        fileType: getExtensionValue(idPhotoFileExtension),
        fileDirectory: 'NATIONAL_IDS',
      };
    }
    return {
      canProceed: true,
      photoUploadArgs,
      licensePhotoUploadArgs,
      idPhotoUploadArgs,
    };
  }

  const operatorsOptions: { label: string; value: string }[] | [] =
    operators?.map((operator) => ({
      label: operator.name,
      value: operator.id,
    })) || [];

  useEffect(() => {
    getOperators();
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={4}>
        <VStack width={'50%'} alignItems="flex-start">
          <FormLabel>Driver Portrait(Optional)</FormLabel>
          <FileInput width={'100%'} file={photo} setFile={setPhoto} fileInputLabel="" />
          {errors?.photo && <HelperText variant="error">{errors?.photo?.message}</HelperText>}
        </VStack>

        <HStack>
          <SBInput
            label="Name"
            placeholder="Add Driver's Name"
            {...register('user.name')}
            error={errors.user?.name?.message}
          />
          <SBInput
            label="Birthday"
            placeholder="Add Driver's Birthday"
            {...register('birthday')}
            type="date"
            error={errors.birthday?.message}
          />
        </HStack>
        <HStack>
          <SBInput
            label="Phone"
            placeholder="Add Driver's Phone"
            {...register('user.phone')}
            onChange={onPhoneChange}
            error={errors.user?.phone?.message}
          />
          <SBInput
            label="Email"
            placeholder="Add Driver's Email"
            {...register('user.email')}
            error={errors.user?.email?.message}
          />
        </HStack>
        <HStack>
          <SBInput
            label="License Number"
            placeholder="Add Driver's License Number"
            {...register('licenseNumber')}
            error={errors.licenseNumber?.message}
          />
          <SBInput
            label="National Id"
            placeholder="Add Driver's National Id"
            {...register('nationalId')}
            error={errors.nationalId?.message}
          />
        </HStack>
        <HStack>
          <VStack width={'50%'} alignItems="flex-start">
            <FormLabel>Drivers License Scan(Optional)</FormLabel>
            <FileInput
              width={'100%'}
              file={licensePhoto}
              setFile={setLicensePhoto}
              fileInputLabel=""
            />
            {errors?.licensePhoto && (
              <HelperText variant="error">{errors?.licensePhoto?.message}</HelperText>
            )}
          </VStack>

          <VStack width={'50%'} alignItems="flex-start">
            <FormLabel>National ID Scan(Optional)</FormLabel>
            <FileInput width={'100%'} file={idPhoto} setFile={setIdPhoto} fileInputLabel="" />
            {errors?.nationalIdPhoto && (
              <HelperText variant="error">{errors?.nationalIdPhoto?.message}</HelperText>
            )}
          </VStack>
        </HStack>
        <FormLabel>Gender</FormLabel>
        <Controller
          name="gender"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup onChange={onChange} value={value}>
              <Stack spacing={5} direction="row">
                <Radio value="MALE" size="sm">
                  Male
                </Radio>
                <Radio value="FEMALE" size="sm">
                  Female
                </Radio>
              </Stack>
            </RadioGroup>
          )}
        />
        <HStack>
          <VStack width={'50%'} align="start">
            <FormLabel>Employment Type</FormLabel>
            <Controller
              name="employmentType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup onChange={onChange} value={value}>
                  <Stack spacing={5} direction="row">
                    <Radio value={Config.PRIMEXPRESS_BUILD ? 'PRIMEX' : 'SWFTBOX'} size="sm">
                      {Config.PRIMEXPRESS_BUILD ? 'Primex' : 'Swftbox'}
                    </Radio>
                    <Radio value="FREELANCE" size="sm">
                      Freelance
                    </Radio>
                    <Radio value="OPERATOR" size="sm">
                      Operator
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </VStack>
          <VStack width={'50%'}>
            <If
              children={
                <Controller
                  name="operatorId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(val) => {
                        // @ts-expect-error, it's just a falsy warning
                        onChange(val?.value);
                      }}
                      value={operatorsOptions.find((option) => option.value === value) || ''}
                      options={operatorsOptions}
                      label="Operator"
                      placeholder="Select The Operator"
                      error={errors.operatorId?.message}
                    />
                  )}
                />
              }
              condition={watchedData.employmentType === 'OPERATOR'}
            />
          </VStack>
        </HStack>
        <FormLabel style={{ paddingBottom: '1rem' }} fontSize={'sm'}>
          Vehicle Details
        </FormLabel>
        <HStack>
          <SBInput
            label="Plate Number"
            placeholder="Plate Number"
            {...register('vehicle.plateNumber')}
            error={errors.vehicle?.plateNumber?.message}
          />
          <SBInput
            label="Contractor"
            placeholder="Contractor"
            {...register('vehicle.contractor')}
            error={errors.vehicle?.contractor?.message}
          />
        </HStack>
        <HStack>
          <SBInput
            label="Model"
            placeholder="Model"
            {...register('vehicle.model')}
            error={errors.vehicle?.model?.message}
          />
          <SBInput
            label="Type"
            placeholder="Type"
            {...register('vehicle.type')}
            error={errors.vehicle?.type?.message}
          />
        </HStack>
        <Stack spacing="3" w="100%" direction={['column', 'row']}>
          <Button variant="outline" w="100%" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" type="submit">
            Confirm
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
