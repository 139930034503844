import { SBInput, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';

interface FormValue {
  name: string;
}

interface LocationFormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
}

export default function LocationForm(props: LocationFormProps) {
  const { register, errors } = props;

  return (
    <Stack gap={4}>
      <SBInput
        crossOrigin={undefined}
        label="Category Name*"
        placeholder="Add Category's name"
        {...register('name')}
        error={errors.name?.message}
      />
    </Stack>
  );
}
