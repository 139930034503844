import { Box, Button, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import {
  type Order,
  useOrderModalContext,
  useOrderModalActions,
  useOrderSelection,
} from 'src/components/Particles';
import { useCancelOrders } from 'src/components/Particles/resolvers/orders';

export function CancelOrderModal() {
  const { cancelOrderIsOpen, data: orders } = useOrderModalContext() as {
    cancelOrderIsOpen: boolean;
    data: Order[];
  };

  const dispatch = useOrderModalActions();
  const { selectedOrders, unselectAll } = useOrderSelection();

  const { cancelOrder, loading } = useCancelOrders();

  function onCancelConfirm() {
    void cancelOrder({
      payload: { ids: orders.map((order) => order.id) },
      onCompleted: ({ cancelOrder }) => {
        if (cancelOrder.message) {
          toast.success(cancelOrder.message);
          handleCloseModal();
        }
      },
    });
  }
  const handleCloseModal = () => {
    dispatch({ type: 'CLOSE_CANCEL_ORDER' });
    if (selectedOrders.size) {
      unselectAll();
    }
  };

  return (
    <>
      <SBModal
        isOpen={cancelOrderIsOpen}
        onClose={handleCloseModal}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>{orders.length === 1 ? 'Cancel Order' : 'Cancel Orders'}</Text>
          </Box>
        }
        body={
          orders.length === 1 ? (
            <Text fontSize="text-md" color="gray.700">
              You are about to cancel order{' '}
              <Text as="span" fontWeight="semibold">
                {orders[0].swftboxTracking}
              </Text>{' '}
              !
            </Text>
          ) : (
            <Text fontSize="text-md" color="gray.700">
              Are you sure you want to cancel these orders ?
            </Text>
          )
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={handleCloseModal}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onCancelConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
