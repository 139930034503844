import { type RouteObject } from 'react-router-dom';
import { BillingPage } from 'src/components/Pages';
import { InvoicesPage, PurchasePage } from 'src/components/Pages/Billing/Components';
import { ErrorBoundary } from '../../ErrorBoundary';

export const BillingRoutes: RouteObject = {
  path: 'billing',
  element: (
    <ErrorBoundary>
      <BillingPage />
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <InvoicesPage />,
    },
    {
      path: 'transactions',
      element: <PurchasePage />,
    },
  ],
};
