import { useQuery } from '@apollo/client';
import { CARRIYO_INTEGRATION_CARRIERS } from '../orders.graphql';
import { type CarriyoIntegrationCarrier } from '../orders.types';

interface QueryResponse {
  carriyoIntegrationCarriers: CarriyoIntegrationCarrier[];
}

export function useCarriyoIntegrationCarriersQuery() {
  const { data, loading } = useQuery<QueryResponse>(CARRIYO_INTEGRATION_CARRIERS);

  return {
    carriyoCarriers: data?.carriyoIntegrationCarriers ?? [],
    loading,
  };
}
