import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../SharedTypes';
import { UPDATE_ORDER_PAYMENT } from './orders.graphql';
import { type UpdateOrderPaymentInput } from './orders.types';

interface UpdateOrderProps {
  payload: UpdateOrderPaymentInput;
  onCompleted?: (message: string) => void;
}

export function useUpdateOrderPayment() {
  const [mutate, { loading, error }] = useMutation<
    { updateOrderPayment: MessageResponse },
    { updateOrderPaymentInput: UpdateOrderPaymentInput }
  >(UPDATE_ORDER_PAYMENT);

  async function updateOrder({ payload, onCompleted }: UpdateOrderProps) {
    await mutate({
      variables: {
        updateOrderPaymentInput: payload,
      },
      onCompleted: ({ updateOrderPayment }) => {
        if (onCompleted) onCompleted(updateOrderPayment.message);
      },
    });
  }

  return {
    updateOrder,
    loading,
    error,
  };
}
