import * as yup from 'yup';

const TOO_SHORT_MSG = 'Too short!';

const removeEmptyString = (currentValue: any, originalValue: any) => {
  if (typeof originalValue === 'string' && originalValue.trim() === '') {
    return undefined;
  }
  return currentValue;
};

export const CategorySchema = yup.object().shape({
  name: yup.string().min(3, TOO_SHORT_MSG).required('Name is required'),
  description: yup.string().transform(removeEmptyString).optional(),
  unitType: yup.string().transform(removeEmptyString).optional(),
});
