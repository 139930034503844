import { useLazyQuery } from '@apollo/client';
import { ROUTE_TIMELINE } from '../Queries';
import { type RouteTimeline } from './types';

interface QueryResponse {
  routeTimeline: RouteTimeline[];
}
interface QueryArgs {
  routeId: string;
}

export function useRouteTimelinesQuery() {
  const [getRouteTimeline, { data, loading }] = useLazyQuery<QueryResponse, QueryArgs>(
    ROUTE_TIMELINE
  );

  return {
    getRouteTimeline,
    routeTimeline: data?.routeTimeline ?? [],
    loading,
  };
}
