import { gql } from '@apollo/client';

export const LIST_AREAS = gql`
  query Areas {
    areas {
      id
      createdAt
      updatedAt
      deletedAt
      standardCityName
      city
      standardAreaName
      pickupEligible
      zone
      standardZoneName
      zoneId
      areaId
      outOfZone
      remote
      countryCode
    }
  }
`;

export const UPDATE_AREA = gql`
  mutation UpdateArea($updateAreaInput: UpdateAreaInput!) {
    updateArea(updateAreaInput: $updateAreaInput) {
      message
    }
  }
`;
