import { useMutation } from '@apollo/client';
import {
  FILTER_ORDERS,
  FORCE_BACK_TO_REQUESTED,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';

interface ForceToRequestedResponse {
  message: string;
}

interface ForceBackToRequestedProps {
  payload: { swftboxTracking: string };
  onCompleted: (message: string) => void;
}

export function useForceBackToRequested() {
  const [mutate, { loading, error }] = useMutation<
    { forceToRequested: ForceToRequestedResponse },
    { forceToRequestedInput: { swftboxTracking: string } }
  >(FORCE_BACK_TO_REQUESTED);

  async function forceBackToRequestedOrders({ payload, onCompleted }: ForceBackToRequestedProps) {
    console.log({ payload });
    await mutate({
      variables: {
        forceToRequestedInput: payload,
      },
      onCompleted: ({ forceToRequested }) => {
        onCompleted(forceToRequested.message);
      },
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
      ],
    });
  }

  return {
    forceBackToRequestedOrders,
    loading,
    error,
  };
}
