import { Box, Button, HStack, Menu, MenuButton, MenuList } from '@swftbox/style-guide';
import { memo, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import ArrowDownIcon from 'src/assets/icons/Arrows/chervonDown.svg';
import {
  arrayContainsAny,
  flattenMapValues,
  mapHasAnyOfArrayElements,
  toggleMapAddRemove,
} from 'src/components/Pages/Orders/Components/Filters/StatusFilters/helper';
import { useOrdersFilters } from 'src/components/Particles';

interface FilterWithOptionsProps {
  state: any;
  onSelect: (item: any) => void;
}

const FilterWithOptions = memo(({ onSelect, state }: FilterWithOptionsProps) => {
  const {
    filters: { statusId },
  } = useOrdersFilters();
  const [selectedValues, setSelectedValues] = useState(new Map());
  const isStatusContainOneOfValues = useMemo(
    () => arrayContainsAny(statusId, state?.childrenIds),
    [statusId, state?.childrenIds]
  );

  const handleSelect = (element: any) => {
    let updateMap = toggleMapAddRemove(selectedValues, element) as Map<string, any[]>;
    setSelectedValues(updateMap);
    onSelect({
      payload: { status: flattenMapValues(updateMap) },
      id: Array.from(updateMap.keys()),
    });
  };
  const handleSelectAll = () => {
    const updateMap = selectedValues;
    state.children.forEach((s: any) => updateMap.set(s.id, s.payload.status));
    setSelectedValues(updateMap);
    onSelect({
      payload: { status: flattenMapValues(updateMap) },
      id: Array.from(updateMap.keys()),
    });
  };

  const handleClearAll = () => {
    setSelectedValues(new Map());
    onSelect({ payload: { status: [] }, id: [] });
  };

  useEffect(() => {
    if (!isStatusContainOneOfValues && !statusId?.includes(state.checkBoxItem.id)) {
      setSelectedValues(new Map());
    }
  }, [isStatusContainOneOfValues, statusId, state.checkBoxItem.id]);

  return (
    <Box
      position="relative"
      whiteSpace="nowrap"
      transition="all 0.3s"
      h="40px"
      borderRadius="8px"
      boxShadow="xs"
      fontWeight="bold"
      display="flex"
      cursor="pointer"
      alignItems="center"
      fontSize="x-small"
    >
      <Menu closeOnSelect={false}>
        <MenuButton
          transition="all 0.3s"
          _hover={{ opacity: '0.3' }}
          border="1px solid"
          borderRight="none"
          w="42px"
          h="100%"
          bg={isStatusContainOneOfValues ? '#63C3EC' : '#fff'}
          color={isStatusContainOneOfValues ? '#fff' : 'gray.700'}
          borderColor={isStatusContainOneOfValues ? '#63C3EC' : '#D0D5DD'}
          borderRadius="8px 0px 0px 8px"
        >
          <HStack
            as="span"
            w="100"
            justifyContent="center"
            h="40px"
            borderRight="1px solid"
            borderColor={
              mapHasAnyOfArrayElements(selectedValues, state?.childrenIds) ? '#fff' : '#D0D5DD'
            }
          >
            <SVG src={ArrowDownIcon} fill="currentColor" stroke="transparent" />
          </HStack>
        </MenuButton>
        <MenuList borderRadius="8px" bg="#fff" border="1px solid #EAECF8" padding="12px" w="200px">
          <Box maxH="200px" overflowY="auto" display="flex" flexDir="column" px="1">
            {/* @ts-expect-error */}
            {state?.children?.map((item) => (
              <Button
                w="100%"
                display="flex"
                justifyContent="start"
                variant="link"
                p="2"
                mb="1"
                key={item.id}
                _hover={{ opacity: '0.5', bg: '#63C3EC', color: '#fff' }}
                onClick={(e) => {
                  handleSelect(item);
                }}
                bg={statusId?.includes(item?.id) ? '#63C3EC' : '#fff'}
                color={statusId?.includes(item?.id) ? '#fff' : 'gray.700'}
              >
                {item.name}
              </Button>
            ))}
          </Box>
          <HStack justifyContent="space-between" px="3" mt="2">
            <Button
              variant="link"
              color="gray.700"
              fontSize="text-sm"
              width="fit-content"
              boxShadow="none"
              textDecor="underline"
              onClick={() => {
                handleSelectAll();
              }}
              _hover={{ opacity: '0.7' }}
            >
              Select All
            </Button>
            <Button
              variant="link"
              color="gray.700"
              fontSize="text-sm"
              width="fit-content"
              boxShadow="none"
              textDecor="underline"
              onClick={() => {
                handleClearAll();
              }}
              _hover={{ opacity: '0.7' }}
            >
              Clear
            </Button>
          </HStack>
        </MenuList>
      </Menu>
      <Box
        border="1px solid "
        borderLeft="none"
        bg={statusId?.includes(state?.checkBoxItem?.id) ? '#63C3EC' : '#fff'}
        color={statusId?.includes(state?.checkBoxItem?.id) ? '#fff' : 'gray.700'}
        borderColor={statusId?.includes(state?.checkBoxItem?.id) ? '#63C3EC' : '#D0D5DD'}
        ps="3"
        borderRadius="0px 8px 8px 0px"
        h="100%"
        display="flex"
        alignItems="center"
        pe="4"
        onClick={(e) => {
          handleSelect(state.checkBoxItem);
        }}
      >
        {state?.checkBoxItem?.name}
      </Box>
    </Box>
  );
});

export default FilterWithOptions;
