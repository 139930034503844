import { useMutation } from '@apollo/client';
import { UPDATE_SHIFT_TIME, GET_DRIVERS } from './drivers.graphql';
import { UpdateShiftTimeInput } from './drivers.types';
import { QueriesStore } from '../queriesStore';

interface UpdateShiftTimeResponse {
  updatedAt: string;
}

export function useUpdateShiftTimeMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    { updateShiftTime: UpdateShiftTimeResponse },
    { updateShiftTimesInput: UpdateShiftTimeInput }
  >(UPDATE_SHIFT_TIME, {
    variables: {
      updateShiftTimesInput: {},
    },
    refetchQueries: [{ query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }],
  });

  async function updateShiftTime(payload: UpdateShiftTimeInput) {
    await mutate({
      variables: {
        updateShiftTimesInput: payload,
      },
      refetchQueries: [{ query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }],
    });
  }

  return {
    updateShiftTime,
    isUpdated: Boolean(data?.updateShiftTime) && !error,
    updating: loading,
    error,
  };
}
