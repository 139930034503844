import { Box } from '@swftbox/style-guide';
import { useMemo, useState } from 'react';
import { NavigationTabs } from 'src/components/Organisms';
import { Config } from 'src/config';

type DashboardType = 'sla' | 'stats' | 'late' | 'hourOfDay';

const embedConfig = {
  swftbox: {
    sla: 'https://management.swftbox.com/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/f594d240-e64f-11ee-bd33-cf3f472fbd50?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1M%2Cto%3Anow))&show-query-input=true&show-time-filter=true',
    stats: 'https://management.swftbox.com/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/38be5040-e651-11ee-bd33-cf3f472fbd50?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1M%2Cto%3Anow))&show-query-input=true&show-time-filter=true',
    late: 'https://management.swftbox.com/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/6c95b940-4b3b-11ef-b95a-672d5013414f?embed=true&_g=()&show-query-input=true&show-time-filter=true',
    hourOfDay: 'https://management.swftbox.com/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/d9a7fc50-5cac-11ef-b95a-672d5013414f?embed=true&_g=()&show-query-input=true&show-time-filter=true',
  },
  primex: {
    sla: 'https://management.swftbox.com/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/61e08e70-e651-11ee-bd33-cf3f472fbd50?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1M%2Cto%3Anow))&show-query-input=true&show-time-filter=true',
    stats: 'https://management.swftbox.com/kibana/app/dashboards?auth_provider_hint=anonymous1#/view/76e6f110-e651-11ee-bd33-cf3f472fbd50?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1M%2Cto%3Anow))&show-query-input=true&show-time-filter=true',
    late: '',
    hourOfDay: '',
  },
};

export function AnalyticsPage() {
  const [activeTab, setActiveTab] = useState<DashboardType>('sla');

  const platform = Config.PRIMEXPRESS_BUILD ? 'primex' : 'swftbox';

  const tabs = useMemo(() => {
    return [
      { name: 'SLA Performance', id: 'sla' },
      { name: 'Daily Stats', id: 'stats' },
      ...(platform === 'swftbox' ? [{ name: 'Late Deliveries', id: 'late' }] : []),
      ...(platform === 'swftbox' ? [{ name: 'Deliveries per Hour of Day', id: 'hourOfDay' }] : []),
    ];
  }, [platform]) as { name: string, id: DashboardType }[];

  return (
    <Box>
      <NavigationTabs tabs={tabs} active={activeTab} handleChange={(tab) => setActiveTab(tab as DashboardType)} />
      <iframe style={{ height: '100vh' }} src={embedConfig[platform][activeTab]} height="600" width="100%"></iframe>
    </Box>
  );
}
