import {
  Grid,
  GridItem,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import { type Area } from 'src/components/Particles';
import { EditArea } from './Modals';

interface AreasListProps {
  areas: Area[];
}

export const AreasList = ({ areas }: AreasListProps) => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem
        colSpan={12}
        boxShadow="sm"
        bg="#fff"
        border=" 1px solid #EAECF0"
        borderRadius="12px"
      >
        <Grid templateColumns={`repeat(12, 1fr)`} gap="6" p="6">
          <GridItem colSpan={6}>
            <Text fontSize="text-md" fontWeight="medium" color="gray.700">
              Areas & Zones Management
            </Text>
          </GridItem>
        </Grid>
        <TableContainer whiteSpace="normal">
          <Table variant="striped" overflow="auto">
            <Thead>
              <Tr bg="#fbfbfc">
                <Th left="0">Dispatch City</Th>
                <Th left="0">Area Name</Th>
                <Th left="0">Area ID</Th>
                <Th left="0">Zone</Th>
                <Th left="0">Eligible pickup location</Th>
                <Th left="0">Out of Zone</Th>
                <Th left="0">Remote</Th>
                <Th left="0"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {areas.map((area) => (
                <Tr key={area.id}>
                  <Td left="0" top="0">
                    <Text>{area.standardCityName}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{area.standardAreaName}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{area.areaId}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{area.zone}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{area.pickupEligible ? 'Yes' : 'No'}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{area.outOfZone ? 'Yes' : 'No'}</Text>
                  </Td>
                  <Td left="0" top="0">
                    <Text>{area.remote ? 'Yes' : 'No'}</Text>
                  </Td>
                  <Td right="0">
                    <HStack>
                      <EditArea area={area} />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </GridItem>
    </Grid>
  );
};
