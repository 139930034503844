import { useQuery } from '@apollo/client';
import { QueriesStore } from '../queriesStore';
import { GET_PURCHASES } from './payments.graphql';
import { type PurchaseFilterArgs, type Purchase } from './payments.type';

interface QueryResponse {
  paginatedPurchases: {
    total: number;
    currentPage: number;
    nextPage: number | null;
    pageCount: number;
    data: Purchase[];
  };
}

export function usePurchaseQuery(filter: PurchaseFilterArgs) {
  QueriesStore.update('GET_PURCHASES', { ...filter });
  const { data, loading, error, ...rest } = useQuery<QueryResponse, PurchaseFilterArgs>(
    GET_PURCHASES,
    {
      variables: filter,
    }
  );

  return {
    purchases: data?.paginatedPurchases ?? null,
    loading,
    error,
    ...rest,
  };
}
