import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_LOCATIONS_QUERY } from './retailers.graphql';
import { type Location } from '../setting';

interface LocationsResponse {
  locations: Location[];
}

export function useLocations(entityType: string, entityId: string) {
  const [isEligible, setIsEligible] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState<Location | undefined>(undefined);
  const { data, loading, error } = useQuery<
    LocationsResponse,
    { entityType: string; entityId: string }
  >(GET_LOCATIONS_QUERY, {
    variables: { entityType, entityId },
    onCompleted: ({ locations }) => {
      setIsEligible(!!locations.find((location) => location?.pickupEligible));
      if (locations.length > 1) {
        setDefaultLocation(locations.find((location) => !!location.isDefault));
      } else if (locations.length === 1) {
        setDefaultLocation(locations[0]);
      }
    },
  });

  return {
    locations: data?.locations ?? [],
    defaultLocation,
    isEligible,
    loading,
    error,
  };
}
