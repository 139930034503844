import { Box, Grid, GridItem, HStack, Text } from '@swftbox/style-guide';
import { useConfigurations } from 'src/components/Particles';
import { ConfigurationsList } from './Components';
import { useMemo } from 'react';
import { subscriptionConfigKeys } from '../../Helper';
import { AddConfiguration, SyncOrders } from './Components/Modals';
export const Configurations = () => {
  const { configurations } = useConfigurations();
  const removedSubscriptionConfig = useMemo(() => {
    const cloneConfig = [...configurations];

    return cloneConfig.filter(
      (config) =>
        !subscriptionConfigKeys.includes(config?.key) && config.key !== 'showSubscriptions'
    );
  }, [configurations]);
  return (
    <>
      <Box pb="8">
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem
            colSpan={12}
            boxShadow="sm"
            bg="#fff"
            border=" 1px solid #EAECF0"
            borderRadius="12px"
          >
            <Grid templateColumns="repeat(12, 1fr)" gap="6" p="6">
              <GridItem colSpan={6}>
                <Text fontSize="text-md" fontWeight="medium" color="gray.700">
                  Configuration
                </Text>
                <Text fontSize="text-sm" fontWeight="regular" color="gray.600">
                  Update your Configuration.
                </Text>
              </GridItem>
              <GridItem colSpan="auto" gridColumnStart={12}>
                <HStack>
                  <SyncOrders />
                  <AddConfiguration />
                </HStack>
              </GridItem>
              <ConfigurationsList configurations={removedSubscriptionConfig} />
            </Grid>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};
