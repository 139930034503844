import Slider from 'rc-slider';
import { useCallback } from 'react';
import { marks } from './helper';
import { useOrdersFiltersActions } from 'src/components/Particles';
import { addDays, addYears, endOfHour, parse, set } from 'date-fns';
const createDateTime = (timeString: string) => {
  // Get today's date with time set to 00:00:00
  const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

  // Parse the time from the slider
  const timeParts = parse(timeString, 'HH:mm', new Date());

  // Combine today's date with the time from the slider
  const combinedDateTime = set(today, {
    hours: timeParts.getHours(),
    minutes: timeParts.getMinutes(),
  });

  return combinedDateTime;
};
function getRangeFromValues(values: number[]) {
  const getMark = (value: number) => {
    if (value <= 0) return 'OLD';
    if (value >= 18) return 'FUTURE';
    //  @ts-expect-error dum
    return marks[value];
  };

  return { startPoint: getMark(values[0]), endPoint: getMark(values[1] - 1) };
}
const SliderFilter = () => {
  const dispatch = useOrdersFiltersActions();
  const handleChange = useCallback(
    (status: number[] | number) => {
      const { startPoint, endPoint } = getRangeFromValues(status as number[]);
      const dateFilter = {
        startDate: determineStartDate(startPoint),
        endDate: endOfHour(determineEndDate(endPoint)),
        isSelected: true,
        key: 'expectedDropEnd',
      };
      dispatch({
        type: 'ORDER_DATE_CHANGE',
        payload: { dateFilter },
      });
    },
    [dispatch]
  );
  return (
    <Slider
      className="slider-be"
      range
      defaultValue={[1, 18]}
      draggableTrack
      dots
      onChange={handleChange}
      min={0} // Start of the slider
      max={19} // End of the slider
    />
  );
};

export default SliderFilter;

function determineStartDate(label: string) {
  switch (label) {
    case 'OLD':
      return addYears(new Date(), -2);
    case 'FUTURE':
      return addDays(new Date(), 1);
    default:
      return createDateTime(label);
  }
}

function determineEndDate(label: string) {
  switch (label) {
    case 'FUTURE':
      return addYears(new Date(), 1);
    case 'OLD':
      return addDays(new Date(), -1);
    case '2:00':
      return createDateTime('8:00');
    default:
      return createDateTime(label);
  }
}
