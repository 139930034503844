import {
  Box,
  HStack,
  IconButton,
  SBAddIcon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@swftbox/style-guide';
import { memo, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import MinusIcon from 'src/assets/icons/General/minus-icon.svg';
import { Invoice, InvoiceLineItemType, UpdateInvoiceLineItemInput } from 'src/components/Particles';
import { AdjustmentItem } from './AdjustmentLineItem';
import { LineItem } from './LineItem';
import { LineItemSelectInput } from './LineItemSelectInput';
import { SubTotal } from './SubTotal';
import { TransactionItem } from './TransactionItem';

interface LineItemsProps {
  invoiceData: Invoice;
}

export const LineItems = memo(({ invoiceData }: LineItemsProps) => {
  const { control, watch } = useFormContext();

  const {
    fields: serviceLineItemsFields,
    append: appendServiceLineItemsFields,
    remove: removeServiceLineItemsFields,
  } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const {
    fields: transactionItemsFields,
    append: appendTransactionItemsFields,
    remove: removeTransactionItemsFields,
  } = useFieldArray({
    control,
    name: 'transactionItems',
  });

  const {
    fields: adjustmentsFields,
    append: appendAdjustmentsFields,
    remove: removeAdjustmentsFields,
  } = useFieldArray({
    control,
    name: 'adjustments',
  });

  const [lineItems, transactionItems, adjustments] = watch([
    'lineItems',
    'transactionItems',
    'adjustments',
  ]);

  const [lineItemsTotals, setLineItemsTotals] = useState({
    taxableAmount: 0,
    vatAmount: 0,
    subtotal: 0,
  });

  const [transactionsTotals, setTransactionsTotals] = useState({
    taxableAmount: 0,
    vatAmount: 0,
    subtotal: 0,
  });

  const [adjustmentsTotal, setAdjustmentsTotal] = useState(0);

  const [netTotal, setNetTotal] = useState(
    lineItemsTotals.subtotal + transactionsTotals.subtotal + adjustmentsTotal
  );

  const updateSubtotals = (lineItems: UpdateInvoiceLineItemInput[]) => {
    let taxableAmount = 0;
    let vatAmount = 0;

    lineItems.forEach((lineItem: UpdateInvoiceLineItemInput) => {
      const itemTaxableAmount = lineItem.rate * lineItem.quantity;
      taxableAmount += itemTaxableAmount;
      vatAmount += (itemTaxableAmount * lineItem.vat) / 100;
    });

    const subtotal = taxableAmount + vatAmount;
    return { taxableAmount, vatAmount, subtotal };
  };

  const updateLineItemsTotals = () => {
    setLineItemsTotals(updateSubtotals(lineItems));
  };

  const updateTransactionsTotals = () => {
    setTransactionsTotals(updateSubtotals(transactionItems));
  };

  const updateAdjustmentsTotals = () => {
    let total = 0;
    adjustments.forEach((adjustment: UpdateInvoiceLineItemInput) => {
      total = total + adjustment.rate * adjustment.quantity;
    });
    setAdjustmentsTotal(total || 0);
  };

  const updateNetTotal = () => {
    setNetTotal(lineItemsTotals.subtotal + transactionsTotals.subtotal + adjustmentsTotal);
  };

  useEffect(() => {
    updateLineItemsTotals();
  }, [lineItems]);

  useEffect(() => {
    updateTransactionsTotals();
  }, [transactionItems]);

  useEffect(() => {
    updateAdjustmentsTotals();
  }, [adjustments]);

  useEffect(() => {
    updateNetTotal();
  }, [lineItemsTotals, transactionsTotals, adjustmentsTotal]);

  const addNewExistingLineItem = (lineItem: UpdateInvoiceLineItemInput) => {
    appendServiceLineItemsFields(lineItem);
  };

  return (
    <Table variant="simple" mt={4}>
      <Thead>
        <Th>Code</Th>
        <Th>Description</Th>
        <Th>Quantity</Th>
        <Th>Unit Price</Th>
        <Th>Taxable Amount</Th>
        <Th>VAT %</Th>
        <Th>VAT Amount</Th>
        <Th>Total Amount (AED)</Th>
        <Th></Th>
      </Thead>
      <Tbody whiteSpace={'nowrap'}>
        {serviceLineItemsFields.map((item, index) => (
          <LineItem
            key={item.id}
            item={item as UpdateInvoiceLineItemInput}
            index={index}
            remove={removeServiceLineItemsFields}
            onQuantityOrRateUpdated={updateLineItemsTotals}
          />
        ))}
        <Tr>
          <Td colSpan={6}></Td>
          <Td colSpan={2}>
            <LineItemSelectInput
              handleSelectLineItem={addNewExistingLineItem}
              excludedOptions={
                (serviceLineItemsFields as UpdateInvoiceLineItemInput[])
                  .map((lineItem) => lineItem.code)
                  .filter((code) => code !== undefined) || []
              }
            />
          </Td>
          <Td>
            <Tooltip label="Add New Line Item" hasArrow>
              <IconButton
                size="small"
                variant="link"
                _hover={{ opacity: '0.5' }}
                aria-label="Add"
                color="gray.400"
                onClick={() =>
                  appendServiceLineItemsFields({
                    code: 'Add Code',
                    description: 'Add Description',
                    quantity: 0,
                    rate: 0,
                    taxableAmount: 0,
                    total: 0,
                    vat: 5,
                    vatAmount: 0,
                    type: InvoiceLineItemType.SERVICE,
                  })
                }
                icon={<SBAddIcon width="15px" />}
              />
            </Tooltip>
          </Td>
        </Tr>
        <SubTotal
          taxableAmount={lineItemsTotals.taxableAmount}
          totalAmount={lineItemsTotals.subtotal}
          vatAmount={lineItemsTotals.vatAmount}
        />
        <Box height={'50px'} />
        {transactionItemsFields.map((transactionItem, index) => (
          <TransactionItem
            key={transactionItem.id}
            transactionLineItem={transactionItem as UpdateInvoiceLineItemInput}
            index={index}
            remove={removeTransactionItemsFields}
            onQuantityOrRateUpdated={updateTransactionsTotals}
          />
        ))}
        <Tr>
          <Td colSpan={8}></Td>
          <Td>
            <Tooltip label="Add New Transaction Fee" hasArrow>
              <IconButton
                size="small"
                variant="link"
                _hover={{ opacity: '0.5' }}
                aria-label="Add"
                color="gray.400"
                onClick={() =>
                  appendTransactionItemsFields({
                    code: null,
                    description: 'Add Description',
                    quantity: 1,
                    rate: 0,
                    taxableAmount: 0,
                    total: 0,
                    vat: 0,
                    vatAmount: 0,
                    type: InvoiceLineItemType.TRANSACTION,
                  })
                }
                icon={<SBAddIcon width="15px" />}
              />
            </Tooltip>
          </Td>
        </Tr>
        <SubTotal
          taxableAmount={transactionsTotals.taxableAmount + lineItemsTotals.taxableAmount}
          totalAmount={transactionsTotals.subtotal + lineItemsTotals.subtotal}
          vatAmount={transactionsTotals.vatAmount + lineItemsTotals.vatAmount}
        />
        <Box height={'50px'} />
        <Tr>
          <Td></Td>
          <Td colSpan={8}>Adjustments</Td>
        </Tr>
        {adjustmentsFields.map((adjustmentItem, index) => (
          <AdjustmentItem
            key={adjustmentItem.id}
            adjustmentLineItem={adjustmentItem as UpdateInvoiceLineItemInput}
            index={index}
            remove={removeAdjustmentsFields}
            onQuantityOrRateUpdated={updateAdjustmentsTotals}
          />
        ))}
        <Tr>
          <Td colSpan={8}></Td>
          <Td colSpan={1} h={'20px'}>
            <HStack>
              <Tooltip label="Add New Positive Adjustment" hasArrow>
                <IconButton
                  size="small"
                  variant="link"
                  _hover={{ opacity: '0.5' }}
                  aria-label="Add"
                  color="gray.400"
                  onClick={() =>
                    appendAdjustmentsFields({
                      code: null,
                      description: 'Add Description',
                      quantity: 1,
                      rate: 0,
                      taxableAmount: 0,
                      total: 0,
                      vat: 0,
                      vatAmount: 0,
                      type: InvoiceLineItemType.ADJUSTMENT,
                    })
                  }
                  icon={<SBAddIcon width="15px" />}
                />
              </Tooltip>
              <Tooltip label="Add New Negative Adjustment" hasArrow>
                <IconButton
                  size="small"
                  variant="link"
                  _hover={{ opacity: '0.5' }}
                  aria-label="Add"
                  color="gray.400"
                  onClick={() =>
                    appendAdjustmentsFields({
                      code: null,
                      description: 'Add Description',
                      quantity: -1,
                      rate: 0,
                      taxableAmount: 0,
                      total: 0,
                      vat: 0,
                      vatAmount: 0,
                      type: InvoiceLineItemType.ADJUSTMENT,
                    })
                  }
                  icon={<SVG src={MinusIcon} width="15px" stroke="#98A2B2" />}
                />
              </Tooltip>
            </HStack>
          </Td>
        </Tr>
        <Tr borderBottom={'none'}>
          <Td></Td>
          <Td fontWeight="bold">Net Total</Td>
          <Td colSpan={5}></Td>
          <Td colSpan={2}>
            <Text fontWeight={'bold'}>
              {netTotal < 0 ? `(${(netTotal * -1).toFixed(2)})` : netTotal.toFixed(2)}
            </Text>
          </Td>
        </Tr>
        <Tr borderBottom={'none'}>
          <Td>
            <Text>Due Date</Text>
          </Td>
          <Td>
            <Text>{`${invoiceData.dueDateInDays} Days from Invoice Date`}</Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
});
