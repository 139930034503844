import { gql } from '@apollo/client';

export const GET_TASK_CATEGORIES = gql`
  query TaskCategories {
    taskCategories {
      id
      createdAt
      name
      description
      unitType
    }
  }
`;

export const ADD_TASK_CATEGORY = gql`
  mutation CreateTaskCategory($createTaskCategoryInput: CreateTaskCategoryInput!) {
    createTaskCategory(createTaskCategoryInput: $createTaskCategoryInput) {
      message
    }
  }
`;

export const UPDATE_TASK_CATEGORY = gql`
  mutation UpdateTaskCategory($updateTaskCategoryInput: UpdateTaskCategoryInput!) {
    updateTaskCategory(updateTaskCategoryInput: $updateTaskCategoryInput) {
      message
    }
  }
`;

export const REMOVE_TASK_CATEGORY = gql`
  mutation RemoveTaskCategory($removeTaskCategoryId: UUID!) {
    removeTaskCategory(id: $removeTaskCategoryId) {
      message
    }
  }
`;
