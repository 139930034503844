import { Badge, Box, HStack, Text, Tooltip, VStack } from '@swftbox/style-guide';
import BlueUpIcon from 'src/assets/icons/General/blueUp.svg';
import BlueHouseIcon from 'src/assets/icons/General/bluehouse.svg';
import PurpleDownIcon from 'src/assets/icons/General/purpleDown.svg';

import LastMileIcon from 'src/assets/icons/General/lastMile.svg';
import SVG from 'react-inlinesvg';
interface JobTypeProps {
  label?: string;
}

const PARTNER_PICKUP_BASE_LABEL = 'Partner_Pickup';
const PARTNER_RTO_BASE_LABEL = 'Partner_RTO';
const PARTNER_DELIVERY_BASE_LABEL = 'Partner_Delivery';
const CUSTOMER_PICKUP = 'Customer_Pickup';
const DELIVERY = 'Delivery';

export function JobType(props: JobTypeProps) {
  const { label: rawLabels } = props;
  if (!rawLabels) return null;

  const splitLabels = rawLabels.split(',');
  const labels = splitLabels
    .sort((a, b) => a.length - b.length)
    .map((label) => {
      if (label.includes(DELIVERY) || label.includes(CUSTOMER_PICKUP)) {
        return (
          <StyledBadge bg="#FFF2C9" color="#F6CA45" key={label}>
            <HStack spacing="1.5">
              <SVG src={LastMileIcon} width="13px" />
              <Text> Last Mile</Text>
            </HStack>
          </StyledBadge>
        );
      }
      if (label.includes(PARTNER_DELIVERY_BASE_LABEL) || label.includes(PARTNER_RTO_BASE_LABEL)) {
        return (
          <StyledBadge bg="#F6DCFF" color="#6764EC" key={label}>
            <HStack spacing="2">
              <HStack spacing="0.5">
                <SVG src={PurpleDownIcon} width="10px" />
                <SVG src={BlueHouseIcon} width="11px" fill="#6764EC" />
              </HStack>
              <Text ml="2">{label.split(':').at(-1)}</Text>
            </HStack>
          </StyledBadge>
        );
      }
      if (label.includes(PARTNER_PICKUP_BASE_LABEL)) {
        return (
          <StyledBadge bg="#DCF4FF" color="#64C3EC" key={label}>
            <HStack spacing="2">
              <HStack spacing="0.5">
                <SVG src={BlueUpIcon} width="11px" />
                <SVG src={BlueHouseIcon} width="11px" />
              </HStack>
              <Text ml="2">{label.split(':').at(-1)}</Text>
            </HStack>
          </StyledBadge>
        );
      }
      return null;
    });

  return (
    <Box width="fit-content">
      <Tooltip
        hasArrow
        background="rgba(0,0,0,0.3)"
        backdropFilter="blur(1px)"
        p="1vh 1vw"
        label={
          <VStack spacing="1" alignItems="start" width="fit-content">
            <StyledBadge bg="#F6DCFF" color="#6764EC">
              Partner Delivery
            </StyledBadge>
            <StyledBadge bg="#FFF2C9" color="#F6CA45">
              Customer Pickup
            </StyledBadge>
            <StyledBadge bg="#DCF4FF" color="#64C3EC">
              Partner Pickup
            </StyledBadge>
            <StyledBadge bg="#FFF2C9" color="#F6CA45">
              Delivery
            </StyledBadge>
            <StyledBadge bg="#F6DCFF" color="#6764EC">
              RTO
            </StyledBadge>
          </VStack>
        }
      >
        <VStack spacing="1" alignItems="start" mb="1">
          {labels}
        </VStack>
      </Tooltip>
    </Box>
  );
}

const StyledBadge = ({ children, color, bg }: any) => (
  <Badge
    background={bg}
    border={`1.5px solid ${color}`}
    color={color}
    rounded="16px"
    fontSize="x-small"
    fontWeight="semibold"
    px="4"
    py="2px"
  >
    {children}
  </Badge>
);
