import { Box, CloseButton, HStack, Text, SBUploadIcon, Image } from '@swftbox/style-guide';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileInputProps {
  file?: File | string;
  setFile: (file?: File) => void;
  fileInputLabel: string;
  width?: number | string;
}
export default function FileInput(props: FileInputProps) {
  const { file, setFile, fileInputLabel, width } = props;

  function clearFile() {
    setFile(undefined);
  }

  const onFileDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
    if (!acceptedFiles.length) return;
    setFile(acceptedFiles[0]);
  }, []);

  const {
    getRootProps: getFileRootProps,
    getInputProps: getFileInputProps,
    isDragActive: isFileDragActive,
  } = useDropzone({
    onDrop: onFileDrop,
    maxFiles: 1,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
  });

  const getFileName = () => {
    if (!file || typeof file === 'string') {
      return null;
    }

    return file.name;
  };

  return (
    <Box
      {...getFileRootProps()}
      h="100%"
      w={width || 'auto'}
      border="1px solid #EAECF0"
      background="#fff"
      borderRadius="12px"
      p="16px"
      textAlign="center"
      cursor="pointer"
    >
      <input {...getFileInputProps()} />
      {isFileDragActive && <Text>Drop the files here ...</Text>}
      {file && (
        <HStack justifyContent="center">
          <Text>{getFileName()}</Text>
          {typeof file === 'string' && <Image src={file} maxWidth={200} />}
          <CloseButton onClick={clearFile} />{' '}
        </HStack>
      )}
      {!isFileDragActive && !file && (
        <Box>
          <Box
            mb="4"
            mx="auto"
            border="8px solid #F2F4F7"
            w="48px"
            h="48px"
            borderRadius="28px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="#EDEDED"
            color="black"
          >
            <SBUploadIcon />
          </Box>
          <Text mb="1" fontSize="x-small">
            Drag or Click To Upload
            <Text as="span" fontWeight="600" mx="1">
              {fileInputLabel}
            </Text>
          </Text>
        </Box>
      )}
    </Box>
  );
}
