import { useQuery } from '@apollo/client';
import { GET_SYSTEM_NOTIFICATION_TRIGGERS, type NotificationTriggerData } from '../Queries';

interface SystemNotficationsTriggersResponse {
  SystemNotficationsTriggers: NotificationTriggerData[];
}

export function useSystemNotficationsTriggers() {
  const { data, loading, error } = useQuery<SystemNotficationsTriggersResponse>(
    GET_SYSTEM_NOTIFICATION_TRIGGERS
  );
  return {
    systemNotificationsTriggers: data?.SystemNotficationsTriggers,
    loading,
    error,
  };
}
