import { makeVar, ReactiveVar, useLazyQuery } from '@apollo/client';
import { GET_ORDERS_MILESTONE } from './orders.graphql';
export interface LastMileStoneType {
  isMilestone: boolean;
  orderUpdates: string[];
  orderId: string;
  action: string;
  date: string;
  driver: {
    name: string;
  };
}
interface MilestoneHashMap {
  [key: string]: LastMileStoneType;
}
interface QueryResponse {
  ordersLatestMilestones: LastMileStoneType[];
}
interface OrdersArg {
  orderIds: { ids: string[] };
  onCompleted?: (orders: any) => void;
}
export const hashMapVar: ReactiveVar<MilestoneHashMap> = makeVar({});

export function useOrdersMilestone({ onCompleted }: { onCompleted?: (orders: any) => void }) {
  const [getMileStones, { ...rest }] = useLazyQuery<QueryResponse, OrdersArg>(
    GET_ORDERS_MILESTONE,
    {
      onCompleted: (data) => {
        if (onCompleted) {
          const hashMap: MilestoneHashMap = data.ordersLatestMilestones.reduce<MilestoneHashMap>(
            (prev, next) => {
              const hashObject = prev;
              if (!hashObject[next.orderId]) {
                hashObject[next.orderId] = next;
              }
              return hashObject;
            },
            {}
          );
          hashMapVar(hashMap);
          onCompleted(hashMap);
        }
      },
    }
  );

  return {
    getMileStones,
    ...rest,
  };
}
