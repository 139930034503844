import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Spinner,
} from '@swftbox/style-guide';
import { FC } from 'react';
import { PickupProfile } from 'src/components/Particles';
import { ActionButtons } from './ActionButtons';

interface Props {
  pickupProfiles: PickupProfile[];
  editPickupProfile: (pickupProfile: PickupProfile) => void;
  loading: boolean;
}

const tableHeaders = [
  { title: '#', subtitle: '', id: 'id' },
  { title: 'Day', subtitle: '', id: 'day' },
  { title: 'From', subtitle: '', id: 'from' },
  { title: 'To', subtitle: '', id: 'to' },
  { title: 'Cutoff time', subtitle: '', id: 'cutoff' },
  { title: '', subtitle: '', id: 'actions' },
];

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const PickupProfilesTable: FC<Props> = ({ pickupProfiles, editPickupProfile, loading }) => {
  const onEditPickupProfile = (pickupProfile: PickupProfile) => {
    editPickupProfile(pickupProfile);
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead>
            <Tr bg="#fbfbfc" color="neutral.normal">
              {tableHeaders.map((header) => (
                <Th key={header.id}>
                  <Text>{header.title}</Text>
                  <Text color="neutral.normal">{header.subtitle}</Text>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {pickupProfiles?.map((pickupProfile: PickupProfile, index: number) => {
              return (
                <Tr key={pickupProfile.id}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <Text>{capitalizeFirstLetter(pickupProfile.day)}</Text>
                  </Td>
                  <Td>
                    <Text>{pickupProfile.slot.from}</Text>
                  </Td>
                  <Td>
                    <Text>{pickupProfile.slot.to}</Text>
                  </Td>

                  <Td>
                    <Text maxW="100%" fontWeight="semibold">
                      {pickupProfile.cutoffTime.replace(new RegExp(':00$'), '')}
                    </Text>
                  </Td>
                  <Td>
                    <ActionButtons pickupProfile={pickupProfile} onEdit={onEditPickupProfile} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
