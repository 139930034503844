import { Box } from '@swftbox/style-guide';
import { type Dispatch, type SetStateAction, useEffect } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  CreateJobListItemType,
  JobOption,
  OrderShippingFlag,
  OrderStage,
  OrderState,
  type Order,
  useFilterOrdersQuery,
} from 'src/components/Particles';
import { ListItemWrapper } from '../ListItemWrapper';
import { OrderItem } from './OrderItem';

const orderStates = [OrderState.warehouse, OrderState.requested];

const rtoOrderStates = [OrderState.warehouse];

interface OrderListProps {
  page: number;
  stopFetching: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  type: Omit<JobOption, 'PARTNER'>;
  searchText: string | undefined;
}

const ordersQueryInitialVariables = {
  perPage: 20,
  shippingFlag: OrderShippingFlag.routeReady, //wont apply if there is search text !
};

export const OrderList = (props: OrderListProps) => {
  const { type, page, stopFetching, setIsLoading, searchText } = props;

  const { getOrders, pageCount, loading, orders } = useFilterOrdersQuery(
    ordersQueryInitialVariables
  );

  useEffect(() => {
    getOrders({
      search: searchText,
      page,
      states: type === JobOption.RTO ? rtoOrderStates : orderStates,
      ...(type === JobOption.RTO && { stages: [OrderStage.rto] }), //wont apply if there is search text !
    });
  }, [page, type, searchText]);

  useEffect(() => {
    if (!loading && page >= pageCount) {
      stopFetching();
    }
  }, [pageCount, page, loading, stopFetching]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  return (
    <>
      {orders.map((order: Order) => (
        <ListItemWrapper
          key={order.id}
          type={CreateJobListItemType.ORDER}
          item={order}
          itemElement={<OrderItem order={order} />}
        />
      ))}
      {!orders.length && (
        <Box position="relative">
          <Box position="absolute" w="100%">
            <EmptyTable title="No Orders Found" />{' '}
          </Box>
        </Box>
      )}
    </>
  );
};
