import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalOverlay,
  Text,
} from '@swftbox/style-guide';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDiscardAndMove: () => void;
}
export const ConfirmPageLeave = ({ onClose, isOpen, handleDiscardAndMove }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Leave</ModalHeader>
        <ModalCloseButton />
        <ModalBody mt="3">
          <Text color="gray.500" fontWeight="semibold">
            Are you sure you want to leave this page? Any unsaved changes will be lost. Please make
            sure you have saved your work before proceeding.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            color="error.500"
            boxShadow="none"
            mr={3}
            onClick={handleDiscardAndMove}
            w="100%"
          >
            Leave & Discard Changes
          </Button>
          <Button w="100%" onClick={onClose}>
            Close & Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
