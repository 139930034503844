import { useMutation } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import {
  type InvoiceMutationResponse,
  LIST_INVOICES,
  UPDATE_INVOICE_STATUS,
  type UpdateInvoiceStatusInput,
} from '../Queries';
import { clientOptionType } from '../../SharedTypes';

interface Props {
  onCompleted: (
    data: { changeInvoiceStatus: InvoiceMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export const useUpdateInvoiceStatusMutation = ({ onCompleted }: Props) => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { changeInvoiceStatus: InvoiceMutationResponse },
    { changeInvoiceStatusInput: UpdateInvoiceStatusInput }
  >(UPDATE_INVOICE_STATUS, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_INVOICES,
        variables: QueriesStore.get('LIST_INVOICES'),
        pollInterval: 5000,
      },
    ],
  });

  return {
    mutate,
    reset,
    loading,
    error,
    ...rest,
  };
};
