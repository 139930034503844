import {
  Checkbox,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  toast,
} from '@swftbox/style-guide';
import { type Role, type Permission, useUpdateRole } from 'src/components/Particles';
import { AddRole, RemoveRole, EditRole } from '../Modals';
import React, { useCallback, useMemo } from 'react';
import { groupPermissions } from '../helper';

interface RolesListProps {
  roles: Role[];
  permissions: Permission[];
}

export const RolesList = React.memo(({ roles, permissions }: RolesListProps) => {
  const { permissionsMap, permissionGroups } = useMemo(
    () => groupPermissions(permissions),
    [permissions]
  );

  const { mutate } = useUpdateRole({
    onCompleted: (data) => {
      toast.success('Role updated successfully');
    },
  });
  const updateRole = useCallback(
    (role: Role, permissionId: number) => {
      let newPermissions = role?.permissions?.map((permission) => permission.id) || [];
      const updatedPermissions = newPermissions.includes(permissionId)
        ? newPermissions.filter((p) => p !== permissionId)
        : [...newPermissions, permissionId];
      mutate({
        variables: {
          updateRoleInput: { id: role.id, permissions: updatedPermissions, name: role.name },
        },
      });
    },
    [mutate]
  );

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem
        colSpan={12}
        boxShadow="sm"
        bg="#fff"
        border=" 1px solid #EAECF0"
        borderRadius="12px"
      >
        <Grid templateColumns={`repeat(12, 1fr)`} gap="6" p="6">
          <GridItem colSpan={6}>
            <Text fontSize="text-md" fontWeight="medium" color="gray.700">
              Roles and Permissions
            </Text>
            <Text fontSize="text-sm" fontWeight="regular" color="gray.600">
              Add/ Update user roles and permissions.
            </Text>
          </GridItem>
          <GridItem colSpan="auto" gridColumnStart={12}>
            <HStack>
              <AddRole permissions={permissions} />
            </HStack>
          </GridItem>
        </Grid>
        <TableContainer whiteSpace="normal">
          <Table variant="striped" overflow="auto">
            <Thead>
              <Tr bg="#fbfbfc">
                <Th position="sticky" left="0" zIndex="2">
                  Roles
                </Th>
                {permissionGroups.map((group, index) => (
                  <Th key={group} colSpan={1}>
                    {group}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {roles.map((role) => (
                <Tr key={role.id}>
                  <Td position="sticky" left="0" zIndex="2" top="0">
                    <>
                      <Text>{role?.name}</Text>
                      <HStack>
                        <EditRole role={role} permissions={permissions} />
                        <RemoveRole role={role} />
                      </HStack>
                    </>
                  </Td>
                  {permissionGroups.map((group) => {
                    const permissions = permissionsMap[group];
                    return (
                      <Td key={group}>
                        <VStack align={'flex-start'}>
                          {permissions.map((permission) => (
                            <HStack key={permission.id} spacing={5}>
                              <Checkbox
                                id={permission.id}
                                onChange={() => updateRole(role, permission.id)}
                                isChecked={role.permissions?.some((p) => p.id === permission.id)}
                                size={'sm'}
                              />
                              <FormLabel htmlFor={permission.id} cursor="pointer">
                                {permission.name}
                              </FormLabel>
                            </HStack>
                          ))}
                        </VStack>
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </GridItem>
    </Grid>
  );
});
