import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  SBAddIcon,
  useDisclosure,
} from '@swftbox/style-guide';
import { type ChangeEvent, useState } from 'react';
import { CreateJobProvider } from 'src/components/Particles';
import { AddJobBody, ItemList, SubmitButton } from './Components';

export const AddNewJob = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [revealAllLocations, setRevealAllLocations] = useState<boolean>(false);

  function onSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value);
  }

  const handleClose = () => {
    setSearchText(undefined);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Job
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <CreateJobProvider>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Create Job</DrawerHeader>

            <DrawerBody>
              <AddJobBody
                onSearchChange={onSearchChange}
                revealAllLocations={revealAllLocations}
                setRevealAllLocations={setRevealAllLocations}
                itemList={
                  <ItemList searchText={searchText} revealAllLocations={revealAllLocations} />
                }
              />
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
              <Button variant="outline" mr={3} onClick={handleClose}>
                Cancel
              </Button>
              <SubmitButton close={handleClose} />
            </DrawerFooter>
          </CreateJobProvider>
        </DrawerContent>
      </Drawer>
    </>
  );
};
