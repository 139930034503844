import { If, SlideFade, Text, Image } from '@swftbox/style-guide';
import { useState } from 'react';
import SVG from 'react-inlinesvg';
import SwftboxLogo from 'src/assets/icons/Logo/swftbox.svg';
import PrimexLogo from 'src/assets/images/logo-primex.png';
import {
	type AuthUser,
	useAuthContext,
	usePasswordLoginMutation,
	useSendOTPMutation,
	useVerifyOTPMutation
} from 'src/components/Particles';
import StepOneForm from './FormSide/StepOneForm';
import StepTwoForm from './FormSide/StepTwoForm';
import { Config } from 'src/config';

const FormSide = () => {
	const [step, setStep] = useState(1);
	const [identifier, setIdentifier] = useState('');
	const { sendOtp, loading: isSendingOTP } = useSendOTPMutation();
	const { verifyOTP, ...submitOTPResult } = useVerifyOTPMutation();
	const { login, ...loginResult } = usePasswordLoginMutation();
	const { onUserLogin } = useAuthContext();

	function back() {
		setStep(1);

	}

	function onIdentifierSubmit(value: string) {
		setIdentifier(value);
		setStep(2);
	}

	async function useOTP() {
		const isEmail = identifier.includes('@');
		const payload = {
			...(!isEmail && { phone: identifier }),
			...(isEmail && { email: identifier }),
		};
		await sendOtp(payload);
	}

	async function onOTP(otp: string) {
		const payload = {
			identifier,
			code: otp,
		};
		await verifyOTP(payload);
	}

	async function onLogin(password: string) {
		const payload = {
			identifier,
			password,
		};
		login(payload).catch(console.log);
	}

	if (
		Boolean(submitOTPResult.user && submitOTPResult.token) ||
		Boolean(loginResult.user && loginResult.token)
	) {
		onUserLogin({
			user: (submitOTPResult.user ?? loginResult.user) as AuthUser,
			token: String(submitOTPResult.token ?? loginResult.token),
		});
	}

	return (
		<>
			{Config.PRIMEXPRESS_BUILD ?
				<Image src={PrimexLogo} width={200} alt="Primexpress Logo" mt="24px" /> :
				<SVG src={SwftboxLogo}/>
			}
			<Text color="gray.900" fontSize="display-md" fontWeight="semibold" my="24px">
				Sign In
			</Text>
			<If condition={step === 1}>
				<SlideFade
					in={step === 1}
					exit={{ opacity: 0, translateX: '-1000px' }}
					transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
					offsetY="0px"
					offsetX="-1000px"
					unmountOnExit
				>
					<StepOneForm onSubmit={onIdentifierSubmit} identifier={identifier}/>
				</SlideFade>
			</If>
			<If condition={step === 2}>
				<SlideFade
					in={step === 2}
					transition={{ enter: { duration: 0.5, delay: 0 }, exit: { duration: 0 } }}
					offsetY="0px"
					offsetX="1000px"
					exit={{ opacity: 0, translateX: '1000px' }}
					unmountOnExit
				>
					<StepTwoForm
						identifier={identifier}
						isSendingOTP={isSendingOTP || submitOTPResult.loading || loginResult.loading}
						back={back}
						onOTP={onOTP}
						useOTP={useOTP}
						onLogin={onLogin}
					/>
				</SlideFade>
			</If>
		</>
	);
};

export default FormSide;
