import { gql } from "@apollo/client";

export const GET_FULFILLMENTS = gql`
query Fulfillments {
    fulfillments {
      id
      name
    }
  }
`;
