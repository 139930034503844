import { useMutation } from '@apollo/client';
import { FILTERED_JOBS, GET_JOB_QUERY, REMOVE_ORDER_FROM_JOB_MUTATION } from '../Queries';
import { type AddJobResponse } from './types';

interface RemoveOrdersFromJobPayload {
  input: RemoveOrderFromRouteInput;
  onCompleted: (message: string) => void;
}

interface RemoveOrderFromRouteInput {
  swftboxTracking: string;
  routeId: string;
}

export function useRemoveOrderFromJob() {
  const [mutate, { loading, error }] = useMutation<
    { unrouteOrder: AddJobResponse },
    { unrouteOrderInput: RemoveOrderFromRouteInput }
  >(REMOVE_ORDER_FROM_JOB_MUTATION);

  async function removeOrdersFromJob({ input, onCompleted }: RemoveOrdersFromJobPayload) {
    await mutate({
      variables: {
        unrouteOrderInput: input,
      },
      onCompleted: (data) => {
        onCompleted(data.unrouteOrder.message);
      },
      refetchQueries: [
        {
          query: FILTERED_JOBS,
          variables: { page: 1, perPage: 10 },
        },
        {
          query: GET_JOB_QUERY,
          variables: { paginatedRoutesId: [input.routeId], page: 1, perPage: 1 },
        },
      ],
    });
  }

  return {
    removeOrdersFromJob,
    loading,
    error,
  };
}
