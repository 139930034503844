import { gql } from '@apollo/client';

export const GET_COMMENTS = gql`
  query Comments($orderId: UUID) {
    comments(orderId: $orderId) {
      id
      userId
      orderId
      comment
      attachments
      user {
        id
        name
      }
      userType
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation CreateComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) {
      message
      data {
        id
        comment
      }
    }
  }
`;
