import { useMutation } from '@apollo/client';
import {
  CANCEL_ORDER,
  FILTER_ORDERS,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type clientOptionType } from '../SharedTypes';
import { type Order } from './orders.types';

interface CancelOrderResponse {
  message: string;
  data: Order[];
}
interface CancelOrderProps {
  payload: {
    ids: string[];
  };
  onCompleted: (
    data: { cancelOrder: CancelOrderResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useCancelOrders() {
  const [mutate, { loading, error }] = useMutation<
    { cancelOrder: CancelOrderResponse },
    { orderIds: { ids: string[] } }
  >(CANCEL_ORDER);

  async function cancelOrder({ payload, onCompleted }: CancelOrderProps) {
    await mutate({
      variables: {
        orderIds: {
          ids: payload.ids,
        },
      },
      onCompleted,
      refetchQueries: ['FilterOrders'],
    });
  }

  return {
    cancelOrder,
    loading,
    error,
  };
}
