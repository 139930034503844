import { type ComponentNode, renderComponent } from './RenderTermsJSON';

interface TermsContentProps {
  termsContent?: string;
}

function TermsContent({ termsContent }: TermsContentProps) {
  if (!termsContent) return null;
  const contentJson = JSON.parse(termsContent) as ComponentNode;
  return <>{renderComponent(contentJson)}</>;
}

export default TermsContent;
