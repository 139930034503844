import { yupResolver } from '@hookform/resolvers/yup';
import { Box, SBEditIcon, SBInput, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import { useEffect, type ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import {
  type UpdateCustomerInput,
  useUpdateCustomer,
  useOrderModalContext,
  useOrderModalActions,
  Order,
} from 'src/components/Particles';
import { UpdateOrderCustomerSchema } from './order.schema';

type FormProps = Omit<Omit<UpdateCustomerInput, 'id'>, 'retailerId'>;

export function EditCustomerModal() {
  const { editCustomerIsOpen, data: order } = useOrderModalContext() as {
    editCustomerIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({
    mode: 'all',
    resolver: yupResolver(UpdateOrderCustomerSchema),
    defaultValues: { ...(order?.customer || {}) },
  });

  const { updateCustomer } = useUpdateCustomer();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    dispatch({ type: 'CLOSE_EDIT_CUSTOMER' });
  };

  function onConfirm(data: FormProps) {
    const payload: UpdateCustomerInput = {
      ...data,
      id: order?.customer?.id,
      retailerId: order?.retailer?.id,
    };

    void updateCustomer({
      payload,
      onCompleted: (message) => {
        handleCloseModal(message);
      },
    });
  }

  function onPhoneChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const phone = value.includes('+') ? value : `+${value}`;
    setValue('phone', phone);
  }
  useEffect(() => {
    reset({
      email: order?.customer?.email,
      phone: order?.customer?.phone,
      name: order?.customer?.name,
    });
  }, [editCustomerIsOpen]);
  return (
    <>
      <SBModal
        isOpen={editCustomerIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit Customer</Text>
          </Box>
        }
        size="xl"
        body={
          <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={4}>
            <SBInput
              type="text"
              label="Name *"
              {...register('name')}
              error={errors.name?.message}
            />

            <SBInput
              type="text"
              label="Phone *"
              {...register('phone')}
              onChange={onPhoneChange}
              error={errors.phone?.message}
            />

            <SBInput
              type="text"
              label="Email"
              {...register('email')}
              error={errors.phone?.message}
            />
          </Stack>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
