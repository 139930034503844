import { Checkbox } from '@swftbox/style-guide';
import { type Order, useOrderSelection } from 'src/components/Particles';

interface OrderCheckboxProps {
  order: Order;
}

export function OrderCheckbox(props: OrderCheckboxProps) {
  const { order } = props;
  const { select, selectedOrders, unselect } = useOrderSelection();

  function toggleSelection() {
    if (selectedOrders.has(order.id)) {
      unselect(order.id);
    } else {
      select(order);
    }
  }

  return <Checkbox isChecked={selectedOrders.has(order.id)} onChange={toggleSelection} />;
}
