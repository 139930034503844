import { type ReactNode } from 'react';

export interface IProviderProps {
  children: ReactNode;
}

export const handleAddRemoveElement = (array: string[], ele: string): string[] | undefined => {
  let newArray: string[] = [];
  if (ele === 'clear') return undefined;
  if (array.includes(ele)) {
    newArray = array.filter((existedEle) => ele !== existedEle);
  } else {
    newArray = [...array, ele];
  }
  return newArray.length ? newArray : undefined;
};

export const handleAddRemoveArray = (
  array: string[],
  ele: string[] | 'clear'
): string[] | undefined => {
  if (ele === 'clear') return undefined;
  let newArray: string[] = [...array];
  for (let element of ele) {
    if (newArray.includes(element)) {
      newArray = newArray.filter((existedEle) => element !== existedEle);
    } else {
      newArray = [...newArray, element];
    }
  }
  return newArray.length ? newArray : undefined;
};
