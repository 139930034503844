import * as yup from 'yup';

export const UpdateOrderSchema = yup.object().shape({
  requireDeliveryNote: yup.boolean().optional(),
  packageCount: yup
    .number()
    .typeError('Package count must be a number')
    .positive('Package count must be positive')
    .min(1, 'At least one package is required')
    .required('Package count is required'),
  notes: yup
    .string()
    .min(3, 'Notes must be at least 3 characters long')
    .max(500, 'Notes cannot be longer than 500 characters')
    .nullable()
    .optional(),
});

