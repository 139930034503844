import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import SBEmailICon from 'src/assets/SBEmailIcon.svg';
import SBOTP from 'src/components/Molecules/SBOTP/SBOTP';

interface OTPModalProps {
  onDone: (otp: string) => void | Promise<void>;
  useOTP: () => void | Promise<void>;
  isLoading: boolean;
  identifier: string;
}

const OTPModal = (props: OTPModalProps) => {
  const { identifier, isLoading, onDone, useOTP: openOTP } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onOTPLoginClick() {
    void openOTP();
    onOpen();
  }

  return (
    <>
      <Button onClick={onOTPLoginClick} variant="link" boxShadow="none" color="primary.900">
        Login Via OTP
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="inside"
        size="md"
        motionPreset="slideInBottom"
      >
        <section>
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
          <ModalContent>
            <ModalHeader textAlign="center">
              <Box
                mb="4"
                mx="auto"
                border="8px solid #F2F4F7"
                w="48px"
                h="48px"
                borderRadius="28px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="#EDEDED"
                color="black"
              >
                <SVG src={SBEmailICon} />
              </Box>
              Please check your Email / Phone Number.
              <Text color="gray.500" mb="4" fontWeight="regular" fontSize="text-sm">
                We've sent a code to {identifier}
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mb="2">
                <SBOTP onDone={onDone} />
              </Box>
              <HStack>
                <Text>Didn’t get a code? </Text>
                <Button boxShadow="none" variant="link">
                  Click to resend.
                </Button>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <Stack spacing="3" w="100%" direction={['column', 'row']}>
                <Button
                  variant="outline"
                  w="100%"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button w="100%" isLoading={isLoading}>
                  Verify
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </section>
      </Modal>
    </>
  );
};

export default OTPModal;
