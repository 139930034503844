import { createContext, type Dispatch, useContext } from 'react';

import { type OrderActionModalsAction, type OrderActionModalsState } from '../../types';

type OrderModalActionsContextProps = Dispatch<OrderActionModalsAction>;

const initialOrderModalRoot: OrderActionModalsState = {
  data: {},
  customerAddressIsOpen: false,
  orderDetailsIsOpen: false,
  editOrderIsOpen: false,
  markAsRTOIsOpen: false,
  undoRTOIsOpen: false,
  receiveOrderIsOpen: false,
  editPaymentIsOpen: false,
  editCustomerIsOpen: false,
  editDispatchZoneIsOpen: false,
  forceActionsIsOpen: false,
  cancelOrderIsOpen: false,
  addressHistoryIsOpen: false,
  failReturnIsOpen: false,
  internationalShipmentIsOpen: false,
  internationalErrorsIsOpen: false,
  proceduresIsOpen: false,
  internationalCancelIsOpen: false,
};

export const OrderModalContext = createContext<OrderActionModalsState>({
  ...initialOrderModalRoot,
});

export const OrderModalActionsContext = createContext<OrderModalActionsContextProps | null>(null);
export function useOrderModalActions() {
  const dispatch = useContext(OrderModalActionsContext);
  return dispatch as OrderModalActionsContextProps;
}
export function useOrderModalContext() {
  return useContext(OrderModalContext);
}

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
