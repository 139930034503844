import { useMutation } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import { type IdsInput } from '../../SharedTypes';
import { ADD_PARTNER_ROUTE_MUTATION, FILTERED_JOBS } from '../Queries';
import { type AddJobResponse } from './types';

interface CreatePickupJobPayload {
  ids: string[];
  onCompleted: (message: string) => void;
}

export function useCreatePickupJob() {
  const [mutate, { loading, error }] = useMutation<
    { createPickupRoute: AddJobResponse },
    { createPickupRouteInput: IdsInput }
  >(ADD_PARTNER_ROUTE_MUTATION);

  async function createPickupJob({ ids, onCompleted }: CreatePickupJobPayload) {
    await mutate({
      variables: {
        createPickupRouteInput: { ids },
      },
      onCompleted: (data) => {
        onCompleted(data.createPickupRoute.message);
      },
      refetchQueries: [
        {
          query: FILTERED_JOBS,
          variables: { page: 1, perPage: 10, ...QueriesStore.get('JOBS') },
        },
      ],
    });
  }

  return {
    createPickupJob,
    loading,
    error,
  };
}
