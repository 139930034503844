import { Autocomplete, SBInput } from '@swftbox/style-guide';
import { type ChangeEvent, type FocusEvent } from 'react';
import { type FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  type UseFormClearErrors,
  type UseFormHandleSubmit,
  type UseFormRegister,
  type UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { type CreateUserPayload } from 'src/components/Particles';
import styles from '../../../style.module.scss';

interface SelectOptionType {
  label: string;
  value: string;
}

interface CreateUserProps {
  register: UseFormRegister<CreateUserPayload>;
  setValue: UseFormSetValue<CreateUserPayload>;
  handleSubmit: UseFormHandleSubmit<CreateUserPayload>;
  errors: FieldErrors<CreateUserPayload>;
  clearErrors: UseFormClearErrors<CreateUserPayload>;
  onDone: (payload: CreateUserPayload) => void | Promise<void>;
  roles: SelectOptionType[];
}

export function UserForm(props: CreateUserProps) {
  const { register, handleSubmit, setValue, errors, clearErrors, onDone, roles } = props;

  function onRoleSelected(selectedRole: SelectOptionType | readonly SelectOptionType[] | null) {
    if (selectedRole == null) return;
    if (selectedRole instanceof Array) {
      const roles = selectedRole.map((role) => role.value);
      setValue('roles', roles);
    } else {
      setValue('roles', [selectedRole?.value]);
    }
  }

  function onPhoneChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const phone = value.includes('+') ? value : `+${value}`;
    setValue('phone', phone);
    clearErrors('phone');
  }

  function onAutocompleteFocus(event: FocusEvent<HTMLInputElement>) {
    // This setTimeout to wait till the menu opens
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  return (
    <form onSubmit={handleSubmit(onDone)} className={styles.addUserForm}>
      <SBInput
        crossOrigin={undefined}
        label="Name *"
        placeholder="ex: John"
        {...register('name')}
        error={errors.name?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Phone *"
        placeholder="ex: +97100000000"
        {...register('phone')}
        onChange={onPhoneChange}
        error={errors.phone?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Email*"
        placeholder="ex: john@example.com"
        {...register('email')}
        onChange={(e) => (e.target.value = e.target.value.toLowerCase())}
        error={errors.email?.message}
      />
      <Autocomplete
        label="Role"
        onFocus={onAutocompleteFocus}
        onChange={onRoleSelected}
        options={roles}
      />
    </form>
  );
}
