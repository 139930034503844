import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  HStack,
  Image,
  Link,
  SBInput,
  SBTextArea,
  Text,
  VStack,
  toast,
} from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Document, Page, pdfjs } from 'react-pdf';
import { type DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import {
  useUpdateRetailerMutation,
  type LegalInfo,
  useAuthContext,
  Retailer,
} from 'src/components/Particles';
import { LegalInfoSchema } from '../../../../Components/Modals/Validation';
import { isEqual } from '../../../Helper';
import { SectionRow, SubmitAndCancel } from '../../Shared';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PartnerSettingsProps {
  id: string;
  retailer: Retailer;
  legalInfo: LegalInfo;
  email: string;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
}

interface FormValues {
  id: string;
  legalInfo: LegalInfo;
  email: string;
}

enum LegalInfoOption {
  REG_CERT = 'REG_CERT',
  TRN_CERT = 'TRN_CERT',
  FULL_FORM = 'FULL_FORM',
}

export function LegalInfoSettings(props: PartnerSettingsProps) {
  const { retailer, id, legalInfo, email, setAllowToMove } = props;
  const { registrationCertificate, trnCertificate } = legalInfo || {};
  const [show, setShow] = useState<LegalInfoOption>(LegalInfoOption.FULL_FORM);
  const [numPages, setNumPages] = useState<number>();
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      id,
      legalInfo: {
        id: legalInfo?.id,
        legalAddress: legalInfo?.legalAddress,
        retailerId: id,
      },
      email,
    },
    resolver: yupResolver(LegalInfoSchema),
  });

  const { updateRetailer, loading } = useUpdateRetailerMutation();

  const onSubmit = handleSubmit(async (data) => {
    await updateRetailer(data).then((data) => reset(getValues()));
    toast.success('Retailer settings updated successfully');
  });

  function onDocumentLoadSuccess({ numPages }: DocumentCallback) {
    setNumPages(numPages);
  }

  const watchedValues = watch();
  const hasChanges =
    isDirty &&
    (!isEqual(watchedValues.legalInfo?.legalAddress, legalInfo?.legalAddress) ||
      !isEqual(watchedValues.email, email));
  useEffect(() => {
    if (hasChanges) {
      setAllowToMove({ page: '', isAllowed: false });
    } else {
      setAllowToMove({ page: '', isAllowed: true });
    }
  }, [hasChanges]);
  return (
    <>
      {show === LegalInfoOption.FULL_FORM && (
        <Box as="form" onSubmit={onSubmit} h="100%">
          <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
            <Box>
              <SectionRow
                title="Legal Details"
                description="These are the details that are associated with account ownership and legal setup."
                children={
                  <>
                    <SBInput
                      label="Account Owner's Name"
                      type="text"
                      value={retailer?.ownerUser?.name || retailer.name}
                      isDisabled
                      placeholder="ex: swftbox@swftbox.com"
                      crossOrigin
                    />
                    <SBInput
                      label="Account Owner Phone Number"
                      type="text"
                      value={retailer?.ownerUser?.phone || retailer.phone}
                      isDisabled
                      placeholder="ex: swftbox@swftbox.com"
                      crossOrigin
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SBInput
                          label="Account Owner Email"
                          type="text"
                          value={value || ''}
                          onChange={onChange}
                          placeholder="ex: swftbox@swftbox.com"
                          crossOrigin
                        />
                      )}
                    />
                    <Controller
                      name="legalInfo.legalAddress"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SBTextArea
                          rows={3}
                          value={value ?? ''}
                          onChange={onChange}
                          placeholder="Legal Address"
                          label=" Company Legal Address"
                        />
                      )}
                    />
                    <HStack spacing="3" w="100%">
                      <Button
                        w="50%"
                        onClick={() => {
                          setShow(LegalInfoOption.REG_CERT);
                        }}
                      >
                        Trade License
                      </Button>
                      <Button
                        w="50%"
                        onClick={() => {
                          setShow(LegalInfoOption.TRN_CERT);
                        }}
                      >
                        Vat Certificate
                      </Button>
                    </HStack>
                  </>
                }
              />
            </Box>

            <SubmitAndCancel isHidden={hasChanges} reset={reset} loading={loading} />
          </VStack>
        </Box>
      )}
      {show === LegalInfoOption.REG_CERT && (
        <Box>
          {!registrationCertificate && (
            <Text fontWeight="600" my="4" textAlign="center">
              No Trade License was provided
            </Text>
          )}
          {registrationCertificate && (
            <Box mb="3">
              {registrationCertificate?.includes('pdf') ? (
                <Document file={registrationCertificate} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Link key={el} href={registrationCertificate} isExternal>
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={200}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </Link>
                  ))}
                </Document>
              ) : (
                <Link href={registrationCertificate} isExternal>
                  <Image
                    // boxSize="150px"
                    objectFit="cover"
                    src={registrationCertificate}
                    alt="Trade License"
                  />
                </Link>
              )}
            </Box>
          )}
          <HStack spacing="3" w="100%">
            <Button
              variant="outline"
              w="100%"
              onClick={() => {
                setShow(LegalInfoOption.FULL_FORM);
              }}
            >
              Back
            </Button>
          </HStack>
        </Box>
      )}
      {show === LegalInfoOption.TRN_CERT && (
        <Box>
          {!trnCertificate && (
            <Text fontWeight="600" my="4" textAlign="center">
              No Vat Certificate was provided
            </Text>
          )}
          {trnCertificate && (
            <Box mb="3">
              {trnCertificate?.includes('pdf') ? (
                <Document file={trnCertificate} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Link key={el} href={trnCertificate} isExternal>
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={200}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </Link>
                  ))}
                </Document>
              ) : (
                <Link href={trnCertificate} isExternal me="2">
                  <Image
                    // boxSize="150px"
                    objectFit="cover"
                    src={trnCertificate}
                    alt="Vat Certificate"
                  />
                </Link>
              )}
            </Box>
          )}
          <HStack spacing="3" w="100%">
            <Button
              variant="outline"
              w="100%"
              onClick={() => {
                setShow(LegalInfoOption.FULL_FORM);
              }}
            >
              Back
            </Button>
          </HStack>
        </Box>
      )}
    </>
  );
}
