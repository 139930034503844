import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBCalendarIcon,
  SBModal,
  Text,
  useDisclosure,
  VStack,
} from '@swftbox/style-guide';
import { addMinutes, format } from 'date-fns';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
import SVG from 'react-inlinesvg';
import uuid from 'react-uuid';
import HomeTypeIcon from 'src/assets/icons/orders/home.svg';
import { formatDate, type Order, pendingSLALabel } from 'src/components/Particles';
import { useRescheduleOrder } from 'src/components/Particles/resolvers/orders/reschedule.service';
import SelectTime from './SelectTime';
import { UpdateDropProfile } from '../../../../Orders/Components/Modal';

interface RescheduleModalProps {
  order: Order;
}

const options = [
  {
    colSpan: 12,
    label: 'All Day',
    start: '00:00',
    end: '23:59',
    id: uuid(),
  },
  {
    colSpan: 6,
    label: '09:00 - 15:00',
    start: '09:00',
    end: '15:00',
    id: uuid(),
  },
  {
    colSpan: 6,
    label: '15:00 - 21:00',
    start: '15:00',
    end: '21:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '09:00 - 12:00',
    start: '09:00',
    end: '12:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '12:00 - 15:00',
    start: '12:00',
    end: '15:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '15:00 - 18:00',
    start: '15:00',
    end: '18:00',
    id: uuid(),
  },
  {
    colSpan: 3,
    label: '18:00 - 21:00',
    start: '18:00',
    end: '21:00',
    id: uuid(),
  },
];

export function RescheduleModal({ order }: RescheduleModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useRescheduleOrder(order.id);
  const [date, setDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState({
    start: '',
    end: '',
    id: options[0].id,
  });

  const handleConfirm = () => {
    void mutate({
      variables: {
        rescheduleOrderInput: {
          orderId: order.id,
          customerDateStart: addMinutes(
            new Date(`${format(new Date(date), 'yyyy-MM-dd')}T${selectedTime.start}:00.001Z`),
            new Date().getTimezoneOffset()
          ),

          customerDateEnd: addMinutes(
            new Date(`${format(new Date(date), 'yyyy-MM-dd')}T${selectedTime.end}:00.001Z`),
            new Date().getTimezoneOffset()
          ),
        },
      },
      onCompleted(data) {
        onClose();
      },
    });
  };

  const expectedDateStart = order.isReverse
    ? order?.timing?.expectedPickupDateStart
    : order?.timing?.expectedDropDateStart;
  const expectedDateEnd = order.isReverse
    ? order?.timing?.expectedPickupDateEnd
    : order?.timing?.expectedDropDateEnd;

  return (
    <>
      <VStack alignItems="flex-start" w="fit-content" h="80px">
        <Box bg="#EFEFEF" p="1" borderRadius="4" w="max-content">
          <HStack onClick={onOpen}>
            <SVG src={HomeTypeIcon} />
            {!expectedDateStart && (
              <Text fontWeight="bold" align="center" minW="150px" pe="2">
                -
              </Text>
            )}
            {expectedDateStart && (
              <>
                <Text textDecoration="underline" fontWeight="bold">
                  {formatDate(expectedDateStart, 'HH:mm')} - {formatDate(expectedDateEnd, 'HH:mm')}
                </Text>
                <Text fontSize="x-small" fontWeight="semibold" pe="8px">
                  {formatDate(expectedDateEnd, 'MMM dd, yyyy')}
                </Text>
              </>
            )}
          </HStack>
        </Box>
        <HStack justifyContent="end" fontSize="8px" w="100%" color="gray.500">
          <Text>SLA</Text>
          {!order?.timing?.slaStart && <Text> {pendingSLALabel(order)} </Text>}
          {order?.timing?.slaStart && (
            <>
              <Text>
                {formatDate(order?.timing?.slaStart, 'HH:mm')} -{' '}
                {formatDate(order?.timing?.slaEnd, 'HH:mm')}
              </Text>
              <Text>{formatDate(order?.timing?.slaEnd, 'MMM dd, yyyy')}</Text>
            </>
          )}
        </HStack>
        <HStack justifyContent="end" fontSize="8px" w="100%" color="gray.500">
          <Text>Created</Text>
          <Text> {formatDate(order?.timing?.inputDate, 'HH:mm MMM dd, yyyy')}</Text>
        </HStack>
        <HStack justifyContent={'end'} fontSize="8px" w="100%" color="gray.500">
          <Text>Type</Text>
          {order.isReverse ? (
            <Text fontSize="x-small">Return</Text>
          ) : (
            <UpdateDropProfile order={order} />
          )}
        </HStack>
      </VStack>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        header={
          <Box pb="1vh">
            <Text>Reschedule Customer Time slot</Text>
          </Box>
        }
        body={
          <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={7}>
            <GridItem colSpan={12}>
              <Box>
                <Text fontSize="text-sm" fontWeight="medium" color="gray.700" mb="1">
                  Select a date
                </Text>
                <HStack w="100%">
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="outline"
                      w="100%"
                      borderColor={'#00C2FF'}
                      bg={'#EFFBFF'}
                      color={'#00C2FF'}
                      _hover={{ opacity: '0.7' }}
                      _focus={{ opacity: '0.7' }}
                    >
                      <HStack justifyContent="space-between">
                        <Box />
                        <Box>{format(new Date(date), 'MMM dd, yyyy')}</Box>
                        <SBCalendarIcon width="15px" />
                      </HStack>
                    </MenuButton>
                    <MenuList>
                      <Calendar
                        onChange={(item) => {
                          setDate(item);
                        }}
                        date={date}
                      />
                    </MenuList>
                  </Menu>
                </HStack>
              </Box>
            </GridItem>
            <SelectTime
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              options={options}
            />
          </Grid>
        }
        //  @ts-expect-error
        scrollBehavior="outside"
        size="2xl"
        handleConfirm={handleConfirm}
      />
    </>
  );
}
