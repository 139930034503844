import {
  Badge,
  Box,
  HStack,
  IconWText,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBMenuDotsVerticalIcon,
  Text,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import checkIcon from 'src/assets/icons/check.svg';
import { AttachmentLens } from 'src/components/Particles';
type Props = {
  attachment: AttachmentLens;
  handleUpdateStatus: (status: 'passed' | 'rejected', id: string) => void;
  setOpenModal: React.Dispatch<
    React.SetStateAction<{
      isModalOpen: boolean;
      attachment: null;
    }>
  >;
};
export const statusBadgeVariant = (status: 'pending' | 'passed' | 'rejected') => {
  if (status === 'pending')
    return { shadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #F79009', color: '#F79009' };
  if (status === 'passed')
    return { shadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #17B26A', color: '#17B26A' };
  if (status === 'rejected')
    return { shadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #F04438', color: '#F04438' };
  return { shadow: '', color: 'error' };
};
export const tagsBadgeVariant = (badge: 'badUpload' | 'needReview' | 'autoPassed') => {
  if (badge === 'badUpload') return { name: 'Bad Upload', variant: 'error' };
  if (badge === 'needReview') return { name: 'Need Review', variant: 'orange' };
  if (badge === 'autoPassed') return { name: 'Auto Passed', variant: 'success' };
  return { name: badge, variant: 'gray' };
};
export const LensCard = ({ setOpenModal, attachment, handleUpdateStatus }: Props) => {
  return (
    <Box>
      <Box position="relative">
        <Image
          boxShadow={statusBadgeVariant(attachment.status)?.shadow}
          src={attachment?.attachment}
          alt="text"
          h="240px"
          borderRadius="16px"
          objectFit="cover"
          objectPosition="50%"
          w="100%"
          cursor={'pointer'}
          onClick={() => setOpenModal({ attachment: attachment, isModalOpen: true })}
        />
        <Badge
          position="absolute"
          top="12px"
          left="12px"
          textTransform="capitalize"
          variant="modern"
          color={statusBadgeVariant(attachment.status).color}
        >
          {attachment.status}{' '}
        </Badge>
      </Box>
      <HStack justifyContent="space-between" alignItems="start" my="5">
        <Text color="brand.700" fontWeight="semibold" fontSize="text-xs">
          {attachment?.comment} • {format(new Date(attachment.createdAt), 'dd MMM yyyy')}
        </Text>
        <Menu placement="left-start">
          <MenuButton as="button">
            <SBMenuDotsVerticalIcon width="12px" color="#957A01" />
          </MenuButton>
          <MenuList>
            {attachment.status !== 'passed' && (
              <MenuItem onClick={() => handleUpdateStatus('passed', attachment.id)} color="green">
                <IconWText
                  text="Pass"
                  spacing="12px"
                  Icon={<SVG src={checkIcon} stroke="green" width="25px" />}
                />
              </MenuItem>
            )}
            {attachment.status !== 'rejected' && (
              <MenuItem onClick={() => handleUpdateStatus('rejected', attachment.id)}>
                <IconWText
                  text="Reject"
                  spacing="12px"
                  color="red"
                  Icon={<SVG src={XIcon} stroke="red" width="25px" />}
                />
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </HStack>
      <HStack flexWrap="wrap">
        {/* <Badge variant={statusBadgeVariant(attachment?.status)} size="md">
          {attachment?.status}
        </Badge> */}

        {attachment?.photoLabelTags?.map((name) => (
          <Badge variant={tagsBadgeVariant(name).variant} size="md">
            {tagsBadgeVariant(name).name}
          </Badge>
        ))}
      </HStack>
    </Box>
  );
};
