import uuid from 'react-uuid';
import { OrdersStatusFilter } from '../../../order.types';
import { camelCaseToCapitalizedSpace } from './helper';

interface Status {
  id: OrdersStatusFilter | string;
  childrenIds?: string[];
  children: { id: string; name: string; payload: { status: string[] } }[];
  checkBoxItem?: {
    id: OrdersStatusFilter | string;
    name: string;
    payload: { status: string[] };
  };
}

const pickupStatus: Status = {
  id: OrdersStatusFilter.PICKUP_REQUESTED,
  childrenIds: [
    OrdersStatusFilter.PICKUP_ASSIGNED,
    OrdersStatusFilter.PICKED_UP,
    OrdersStatusFilter.RTN_REQUESTED,
    OrdersStatusFilter.RTN_PICKED_UP,
  ],
  checkBoxItem: {
    id: OrdersStatusFilter.PICKUP_REQUESTED,
    name: camelCaseToCapitalizedSpace(OrdersStatusFilter.PICKUP_REQUESTED),
    payload: { status: [OrdersStatusFilter.PICKUP_REQUESTED] },
  },
  children: [
    {
      id: OrdersStatusFilter.PICKUP_ASSIGNED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.PICKUP_ASSIGNED),
      payload: { status: [OrdersStatusFilter.PICKUP_ASSIGNED] },
    },
    {
      id: OrdersStatusFilter.PICKED_UP,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.PICKED_UP),
      payload: { status: [OrdersStatusFilter.PICKED_UP] },
    },
    {
      id: OrdersStatusFilter.RTN_REQUESTED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_REQUESTED),
      payload: { status: [OrdersStatusFilter.RTN_REQUESTED, OrdersStatusFilter.RTN_ASSIGNED] },
    },
    {
      id: OrdersStatusFilter.RTN_PICKED_UP,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_PICKED_UP),
      payload: { status: [OrdersStatusFilter.RTN_PICKED_UP, OrdersStatusFilter.RTN_PICKED_UP] },
    },
  ],
};
const RTO_RTNStatus: Status = {
  id: uuid(),
  checkBoxItem: {
    id: OrdersStatusFilter.RTN_RTO,
    name: 'RTN / RTO',
    payload: {
      status: [
        OrdersStatusFilter.RTN_REQUESTED,
        OrdersStatusFilter.RTN_ASSIGNED,
        OrdersStatusFilter.RTN_PICKED_UP,
        OrdersStatusFilter.RTN_AT_DEPOT,
        OrdersStatusFilter.RTN_TO_PARTNER,
        OrdersStatusFilter.RTN_FAILED,
        OrdersStatusFilter.RTN_COMPLETED,
        OrdersStatusFilter.RTO_INITIATED,
        OrdersStatusFilter.RTO_ASSIGNED,
        OrdersStatusFilter.RTO_PICKED_UP,
        OrdersStatusFilter.RTO_COMPLETE,
        OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE,
      ],
    },
  },
  childrenIds: [
    OrdersStatusFilter.RTN_REQUESTED,
    OrdersStatusFilter.RTN_ASSIGNED,
    OrdersStatusFilter.RTN_PICKED_UP,
    OrdersStatusFilter.RTN_AT_DEPOT,
    OrdersStatusFilter.RTN_TO_PARTNER,
    OrdersStatusFilter.RTN_FAILED,
    OrdersStatusFilter.RTN_COMPLETED,
    OrdersStatusFilter.RTN_ASSIGNED,
    OrdersStatusFilter.RTO_INITIATED,
    OrdersStatusFilter.RTO_ASSIGNED,
    OrdersStatusFilter.RTO_COMPLETE,
    OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE,
  ],
  children: [
    {
      id: OrdersStatusFilter.RTN_REQUESTED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_REQUESTED),
      payload: { status: [OrdersStatusFilter.RTN_REQUESTED] },
    },
    {
      id: OrdersStatusFilter.RTN_ASSIGNED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_ASSIGNED),
      payload: { status: [OrdersStatusFilter.RTN_ASSIGNED] },
    },
    {
      id: OrdersStatusFilter.RTN_PICKED_UP,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_PICKED_UP),
      payload: { status: [OrdersStatusFilter.RTN_PICKED_UP] },
    },
    {
      id: OrdersStatusFilter.RTN_AT_DEPOT,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_AT_DEPOT),
      payload: { status: [OrdersStatusFilter.RTN_AT_DEPOT] },
    },
    {
      id: OrdersStatusFilter.RTN_TO_PARTNER,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_TO_PARTNER),
      payload: { status: [OrdersStatusFilter.RTN_TO_PARTNER] },
    },
    {
      id: OrdersStatusFilter.RTN_FAILED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_FAILED),
      payload: { status: [OrdersStatusFilter.RTN_FAILED] },
    },
    {
      id: OrdersStatusFilter.RTN_COMPLETED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTN_COMPLETED),
      payload: { status: [OrdersStatusFilter.RTN_COMPLETED] },
    },
    {
      id: OrdersStatusFilter.RTO_INITIATED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTO_INITIATED),
      payload: { status: [OrdersStatusFilter.RTO_INITIATED] },
    },
    {
      id: OrdersStatusFilter.RTO_ASSIGNED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTO_ASSIGNED),
      payload: { status: [OrdersStatusFilter.RTO_ASSIGNED, OrdersStatusFilter.RTO_PICKED_UP] },
    },
    {
      id: OrdersStatusFilter.RTO_COMPLETE,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.RTO_COMPLETE),
      payload: {
        status: [OrdersStatusFilter.RTO_COMPLETE, OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE],
      },
    },
  ],
};
export const singleStatus: Status = {
  id: OrdersStatusFilter.ACTIVE,
  checkBoxItem: undefined,
  children: [
    {
      id: OrdersStatusFilter.ACTIVE,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.ACTIVE),
      payload: {
        status: [
          'unassigned',
          'rtnRequested',
          'assigned',
          'rtnAssigned',
          'rtnPickedUp',
          'delivering',
          'pickupRequested',
        ],
      },
    },
    {
      id: OrdersStatusFilter.UNASSIGNED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.UNASSIGNED),
      payload: {
        status: ['unassigned', 'rtnRequested', 'pickupRequested'],
      },
    },
    {
      id: OrdersStatusFilter.ASSIGNED,
      payload: {
        status: ['assigned', 'rtoAssigned', 'rtnAssigned'],
      },
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.ASSIGNED),
    },
    {
      id: OrdersStatusFilter.DELIVERING,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.DELIVERING),
      payload: { status: ['delivering', 'rtnPickedUp'] },
    },
  ],
};

const inactiveStatus: Status = {
  id: uuid(),
  checkBoxItem: {
    id: OrdersStatusFilter.INACTIVE,
    name: camelCaseToCapitalizedSpace(OrdersStatusFilter.INACTIVE),
    payload: {
      status: [
        OrdersStatusFilter.DRAFT,
        OrdersStatusFilter.IN_FULFILLMENT,
        OrdersStatusFilter.CANCELLED,
      ],
    },
  },
  childrenIds: [
    OrdersStatusFilter.DRAFT,
    OrdersStatusFilter.IN_FULFILLMENT,
    OrdersStatusFilter.CANCELLED,
    OrdersStatusFilter.ALL,
  ],
  children: [
    {
      id: OrdersStatusFilter.DRAFT,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.DRAFT),
      payload: { status: [OrdersStatusFilter.DRAFT] },
    },
    {
      id: OrdersStatusFilter.IN_FULFILLMENT,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.IN_FULFILLMENT),
      payload: { status: [OrdersStatusFilter.IN_FULFILLMENT] },
    },
    {
      id: OrdersStatusFilter.CANCELLED,
      name: camelCaseToCapitalizedSpace(OrdersStatusFilter.CANCELLED),
      payload: { status: [OrdersStatusFilter.CANCELLED] },
    },
    // {
    //   id: OrdersStatusFilter.ALL,
    //   name: camelCaseToCapitalizedSpace(OrdersStatusFilter.ALL),
    //   payload: { status: undefined },
    // },
  ],
};
export const statusList = [
  {
    id: OrdersStatusFilter.STARRED,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.STARRED,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.STARRED),
        payload: { status: [], flagType: ['star'] },
      },
    ],
  },
  { ...pickupStatus },
  {
    id: OrdersStatusFilter.PICKUPS_TO_RECEIVE,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.PICKUPS_TO_RECEIVE,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.PICKUPS_TO_RECEIVE),
        payload: { status: [], stopStatus: ['pending'] },
      },
    ],
  },
  { ...singleStatus },
  {
    id: OrdersStatusFilter.ATTEMPTED,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.ATTEMPTED,
        name: 'Attempted / Missed',
        payload: { status: ['attempted'] },
      },
    ],
  },
  {
    id: OrdersStatusFilter.ELIGIBLE_RTO,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.ELIGIBLE_RTO,
        name: 'Eligible RTO',
        payload: { failedAttemptCount: 3 },
      },
    ],
  },
  { ...RTO_RTNStatus },
  {
    id: OrdersStatusFilter.CLOSED,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.CLOSED,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.CLOSED),
        payload: {
          status: [
            OrdersStatusFilter.DELIVERED,
            OrdersStatusFilter.RTN_COMPLETED,
            OrdersStatusFilter.RTO_COMPLETE,
            OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE,
          ],
        },
      },
    ],
  },
  { ...inactiveStatus },
  {
    id: OrdersStatusFilter.FLAGGED,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.FLAGGED,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.FLAGGED),
        payload: {
          status: [],
          flagType: ['merchant'],
        },
      },
      {
        id: OrdersStatusFilter.CS_CHECK,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.CS_CHECK),
        payload: { status: [], flagType: ['cs'] },
      },
    ],
  },
];

export enum FilterActionSelection {
  CLEAR = 'clear',
  SELECT_ALL = 'selectAll',
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT = 'multiSelect',
}
