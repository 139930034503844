import { gql } from '@apollo/client';

export const GET_FULFILLMENTS_QUERY = gql`
  query Fulfillments {
    fulfillments {
      name
      deletedAt
      createdAt
      id
      updatedAt
      location {
        country
        city
        addressLine1
        addressLine2
        addressType
        isDefault
        area
        building
        street
        landMark
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_FULFILLMENT_QUERY = gql`
  query GetRetailerLocations($locationId: UUID!) {
    location(id: $locationId) {
      id
      country
      city
      addressLine1
      addressLine2
      addressType
      area
      street
      building
      landMark
      coordinates {
        latitude
        longitude
      }
      entityId
      entityType
      isDefault
    }
  }
`;

export const ADD_FULFILLMENT_QUERY = gql`
  mutation CreateFulfillment($createFulfillmentInput: CreateFulfillmentInput!) {
    createFulfillment(createFulfillmentInput: $createFulfillmentInput) {
      message
    }
  }
`;
export const UPDATE_FULFILLMENT_QUERY = gql`
  mutation UpdateFulfillment($input: UpdateFulfillmentInput!) {
    updateFulfillment(input: $input) {
      message
    }
  }
`;

export const DELETE_FULFILLMENT_QUERY = gql`
  mutation RemoveFulfillment($removeFulfillmentId: UUID!) {
    removeFulfillment(id: $removeFulfillmentId) {
      message
    }
  }
`;
