import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { DELETE_FEEDBACK_QUESTION, GET_FEEDBACK_QUESTIONS_QUERY } from '../Queries';

interface DeleteQuestionResponse {
  message: string;
}
interface DeleteQuestionsProps {
  onCompleted: (
    data: { removeFeedbackQuestion: DeleteQuestionResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useDeleteQuestion({ onCompleted }: DeleteQuestionsProps) {
  const [mutate, { error, loading }] = useMutation<
    { removeFeedbackQuestion: DeleteQuestionResponse },
    { removeFeedbackQuestionId: string }
  >(DELETE_FEEDBACK_QUESTION, {
    onCompleted,
    refetchQueries: [
      {
        query: GET_FEEDBACK_QUESTIONS_QUERY,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
