// import { faker } from '@faker-js/faker';
// import { type Retailer } from 'src/components/Particles';

// const getSMSTriggers = () =>
//   [
//     ...new Set(
//       Array.from({ length: Number(faker.random.numeric(1)) }).map(() =>
//         faker.helpers
//           .shuffle(['ORDER CREATED', 'ORDER UPDATED', 'ORDER ASSIGNED', 'ORDER DELIVERED', null])
//           .at(0)
//       )
//     ),
//   ].filter((value) => Boolean(value));

// const getRetailer = () =>
// ({
//   id: faker.database.mongodbObjectId(),
//   reference: faker.commerce.productName(),
//   name: faker.name.fullName(),
//   jobType: faker.name.jobTitle(),
//   email: faker.internet.email(),
//   phone: faker.phone.number(),
//   // fulfillment: Number(faker.random.numeric(1)) > 5 ? 'Dubai Fulfillment Center' : null,
//   status: faker.helpers.shuffle(['Active', 'Pending', 'Off Boarded', 'Disabled']).at(0),
//   ownerUser: {
//     name: faker.name.fullName(),
//     phone: faker.phone.number(),
//   },
//   smsTriggers: getSMSTriggers(),
//   orderBalance: 0,
//   isDraftEnabled: false,
//   isMultiLocationAllowed: false
// } as Retailer);

// export const MockedRetailers = Array.from({ length: Number(faker.random.numeric(2)) })
//   .map(() => null)
//   .map(getRetailer);

export const StatsMocking = [
  {
    header: 'All',
    value: undefined,
  },
  {
    header: 'Not Assigned',
    value: 'notAssigned',
  },
  {
    header: 'Assigned',
    value: 'assigned',
  },
  {
    header: 'Started',
    value: 'started',
  },
  {
    header: 'Completed',
    value: 'completed',
  },
  {
    header: 'Failed',
    value: 'failed',
  },
];

export const TypesMocking = [
  {
    header: 'Partner Pickup',
    value: 'Partner_Pickup',
  },
  {
    header: 'Last Mile',
    value: 'Delivery',
  },
  {
    header: 'RTO',
    value: 'rto',
  },
];
