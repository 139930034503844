import { Box, Button, SBModal, Stack, toast, useDisclosure, Text } from '@swftbox/style-guide';
import { type FC } from 'react';
import { type Job, useCancelJobMutation } from 'src/components/Particles';

interface Props {
  job?: Job;
}

export const CancelJob: FC<Props> = ({ job }) => {
  const { cancelJob, loading } = useCancelJobMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfirm = async () => {
    const response = await cancelJob(job?.id);
    toast.success(response.data?.cancelRoute?.message);
  };

  return (
    <>
      <Button type="button" onClick={onOpen} disabled={loading}>
        Cancel Job
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Cancel Job</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to Cancel Job with reference
            <Text as="span" fontWeight="semibold">
              {' '}
              {job?.reference}
            </Text>
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default CancelJob;
