import { useLazyQuery } from '@apollo/client';
import { LIST_INVOICE_SETTINGS, InvoiceSetting } from '../Queries';

interface InvoicesSettingsResponse {
  invoiceSettings: InvoiceSetting[];
}

export function useInvoicesSettingsQuery() {
  const [query, { data, loading, error }] =
    useLazyQuery<InvoicesSettingsResponse>(LIST_INVOICE_SETTINGS);

  function getInvoicesSettings() {
    return query();
  }

  return {
    getInvoicesSettings,
    invoicesSettings: data?.invoiceSettings || [],
    loading,
    error,
  };
}
