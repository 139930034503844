import { Box, Grid, HStack, Spinner, Text } from '@swftbox/style-guide';
import clsx from 'clsx';
import { format } from 'date-fns';
import './StepperHistory.scss';
import { formatStatus } from 'src/components/Pages/Orders/Components/Modal/OrderDetails/Components/Tabs/helper';
import { type OptimizerStatus } from 'src/components/Particles';
import { formatJobAcions } from './Helper';

interface StepsProps {
  steps: Array<{
    id: string;
    routeId: string;
    createdAt: Date;
    status: string;
    source: string;
    reference: string;
    label?: string;
    nextStopSequence: number;
    optimizerStatus?: OptimizerStatus;
    driverId?: string;
    userId?: string;
    action: string;
    description: string;
    driver?: {
      id: string;
      user?: {
        name: string;
        roles: string[];
      };
    };
    actionByUser?: {
      name: string;
      roles: string[];
    };
  }>;
  loading: boolean;
}

export const JobStepperHistory = ({ steps, loading }: StepsProps) => {
  return (
    <Box bg="gray.100" p="5" borderRadius="8px">
      {loading ? (
        <HStack w="100%" justifyContent="center" minH="200px">
          <Spinner />
        </HStack>
      ) : (
        <>
          {steps?.map((step, i) => (
            <Grid
              key={`${i}`}
              gridTemplateColumns="30% repeat(auto-fit, minmax(30px, 23.333%))"
              _notLast={{ minH: '60px' }}
              className={clsx({
                history_item: true,
                done: i !== 0,
                active: i === 0,
                failed: false,
              })}
            >
              <Text>{formatJobAcions(step.action)}</Text>
              <Text>{formatStatus(step.status)}</Text>
              {step.createdAt ? (
                <HStack
                  fontWeight="semibold"
                  spacing="2"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Text fontSize="text-sm">{format(new Date(step.createdAt), 'hh:mm a')}</Text>
                  <Text fontSize="x-small" pt="2px">
                    {format(new Date(step.createdAt), 'MMM dd, yyyy')}
                  </Text>
                </HStack>
              ) : (
                <div />
              )}
              <Box textAlign="end">
                <Text fontWeight="semibold" letterSpacing="0.5px">
                  {step.source ? step.source : step.actionByUser?.name}
                </Text>
                {!step.source && <Text letterSpacing="1px">{step.actionByUser?.roles[0]}</Text>}
              </Box>
              {step.description && (
                <Box
                  gridColumn="1/3"
                  className="history_comment"
                  _notLast={{ marginBottom: '10px' }}
                >
                  {step.description}
                </Box>
              )}
            </Grid>
          ))}
        </>
      )}
    </Box>
  );
};
