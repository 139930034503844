import { Box, Button, Flex, Spacer, Tooltip, WithLoader } from '@swftbox/style-guide';
import { useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import ResetIcon from 'src/assets/icons/Arrows/refresh.svg';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { getJobsSearch } from 'src/components/Pages/Jobs/Helper';
import {
  ElasticSearchSortOptions,
  OrderModalProvider,
  useJobsFilters,
  useSearch,
} from 'src/components/Particles';
import {
  type DateRangeFilter,
  JobSortableFields,
  JobStatusFilter,
  type JobTypeFilter,
  useJobs,
} from 'src/components/Particles/resolvers/Jobs';
import { JobsDateFilter, JobsFilter, JobsHeader, JobsTable } from './Components';
import style from './style.module.scss';
import { OrderDetailsModal, ProceduresModal } from '../Orders/Components/Modal';
import { AddressHistoryModal } from '../Orders/Components/Modal/AddressHistory';

export function JobsPage() {
  const perPage = 50;
  const { searchText } = useSearch();
  const { organizedFilters } = useJobsFilters();
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState<JobStatusFilter[]>([
    JobStatusFilter.NotAssigned,
    JobStatusFilter.Assigned,
    JobStatusFilter.Started,
  ]);
  const [sorting, setSorting] = useState({
    by: JobSortableFields.createdAt,
    order: ElasticSearchSortOptions.DESC,
  });
  const onPageSorting = useCallback(
    ({ by, order }: { by: JobSortableFields; order: ElasticSearchSortOptions }) => {
      setSorting({ by, order });
    },
    []
  );
  const [typeFilter, setTypeFilter] = useState<JobTypeFilter[] | undefined>();
  const [dateFilter, setDateFilter] = useState<DateRangeFilter | undefined>();
  const [isExpress, setIsExpress] = useState<boolean | undefined>();
  const [hasChilledOrders, setHasChilledOrders] = useState<boolean | undefined>();

  const resetFilters = () => {
    setIsExpress(undefined);
    setHasChilledOrders(undefined);
    setDateFilter(undefined);
    setStatusFilter([
      JobStatusFilter.NotAssigned,
      JobStatusFilter.Assigned,
      JobStatusFilter.Started,
    ]);
    setTypeFilter(undefined);
  };

  const { getJobs, jobs, loading, total } = useJobs({
    page,
    perPage,
    status: statusFilter.length ? statusFilter : undefined,
    order: sorting.order,
    orderBy: sorting.by,
    ...((!!searchText || !!typeFilter) && {
      search: getJobsSearch(searchText, typeFilter),
    }),
    isExpress,
    hasChilledOrders,
  });

  useEffect(() => {
    getJobs({ page, ...dateFilter, ...organizedFilters });
  }, [getJobs, page, sorting, searchText, dateFilter, organizedFilters]);

  function onPageChange(selectedItem: number) {
    setPage(selectedItem);
  }

  function onStatusChange(filter?: JobStatusFilter) {
    if (filter) setStatusFilter([filter]);
    else setStatusFilter([]);
  }

  function onTypeChange(filter?: JobTypeFilter) {
    if (filter === typeFilter?.at(0) || !filter) setTypeFilter(undefined);
    else setTypeFilter([filter]);
  }

  function onDateSelectionChange(dates?: DateRangeFilter) {
    setDateFilter(dates ?? undefined);
  }

  function onFilterChange(name?: string, value?: boolean) {
    if (name === 'express') setIsExpress(value);
    setHasChilledOrders(value ? true : undefined);
  }

  return (
    <>
      <OrderModalProvider>
        <Box className={style.jobs}>
          <JobsHeader jobsCount={total} />
          <Flex align="center" p="20px 24px">
            <JobsFilter
              onStatusChange={onStatusChange}
              statusFilter={statusFilter}
              onTypeChange={onTypeChange}
              typeFilter={typeFilter}
              onExpressFilter={(value) => {
                onFilterChange('express', value);
              }}
              onChilledFilter={(value) => {
                onFilterChange('chilled', value);
              }}
              isExpressFilter={isExpress}
              hasChilledFilter={hasChilledOrders}
            />
            <Spacer />
            <JobsDateFilter onDateRangeChange={onDateSelectionChange} />
            <Tooltip label="Reset filters" hasArrow>
              <Button
                variant="link"
                color="gray.700"
                onClick={resetFilters}
                border="1px solid"
                borderColor="#D0D5DD"
                boxShadow="xs"
                height="40px"
                ml="2"
              >
                <SVG src={ResetIcon} stroke="currentColor" fill="transparent" width="16px" />
              </Button>
            </Tooltip>
          </Flex>

          <WithLoader isLoading={loading && !jobs?.length}>
            <JobsTable
              jobs={jobs}
              page={page}
              perPage={perPage}
              total={total}
              onPageChange={onPageChange}
              onPageSorting={onPageSorting}
              sorting={sorting}
            />

            {!loading && !jobs?.length && (
              <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
                <EmptyTable title="No Routes Found" />
              </Flex>
            )}

            <Flex justify="end">
              {/* <SBPaginate pageCount={pageCount} onPageChange={onPageChange} /> */}
            </Flex>
          </WithLoader>
        </Box>
        <OrderDetailsModal />
        <AddressHistoryModal />
        <ProceduresModal />
      </OrderModalProvider>
    </>
  );
}
