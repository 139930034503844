import { Box, Button, Grid, GridItem, HStack, SBInput, Text, toast } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import {
  useUpdateConfiguration,
  type ConfigurationType,
} from 'src/components/Particles/resolvers/setting/Configurations';
import { AddConfiguration, SyncOrders } from '../Modals';

interface ConfigurationsListProps {
  configurations: ConfigurationType[];
}
const createHashOfConfig = (configurations: ConfigurationType[]) => {
  return configurations.reduce(
    (prev, next) => ({
      ...prev,
      [next.key]: { key: next.key, id: next.id, value: next.value },
    }),
    {} as ConfigurationType[]
  );
};
export const ConfigurationsList = ({ configurations }: ConfigurationsListProps) => {
  const { callUpdateConfiguration } = useUpdateConfiguration();
  const [configurationsHash, setConfigurationsHash] = useState<any>(() =>
    createHashOfConfig(configurations)
  );
  const onConfirm = (data: ConfigurationType) => {
    void callUpdateConfiguration({
      payload: {
        input: {
          key: data.key,
          id: data.id,
          value: data.value,
        },
      },
      onCompleted: (data) => {
        toast.success(data?.updateConfiguration?.message);
      },
    });
  };

  const handleChange = (config: ConfigurationType, value: any) => {
    setConfigurationsHash({ ...configurationsHash, [config.key]: { ...config, value } });
  };

  useEffect(() => {
    setConfigurationsHash(createHashOfConfig(configurations));
  }, [configurations]);

  return (
    <>
      {configurations?.map((config) => (
        <GridItem colSpan={12} key={config.id}>
          <HStack>
            <SBInput
              label={config?.key}
              placeholder="ex: Value"
              value={configurationsHash[config.key]?.value}
              onChange={(e) => handleChange(config, e.target.value)}
              crossOrigin
            />
            <Box pt="5">
              <Button onClick={() => onConfirm(configurationsHash[config?.key])}>
                Save changes
              </Button>
            </Box>
          </HStack>
        </GridItem>
      ))}
    </>
  );
};
