export const headers = [
  {
    name: 'Reference',
    style: { color: 'neutral.normal', textAlign: 'center' },
    action: () => null,
  },
  {
    name: 'Dispatch Plan',
    style: { color: 'neutral.normal' },
    action: () => null,
  },
  {
    name: 'Fulfillment Items',
    style: { color: 'neutral.normal' },
    action: () => null,
  },
  {
    name: 'Fulfillment / Gift Note',
    style: { color: 'neutral.normal', width: '150px' },
    action: () => null,
  },
  {
    name: 'Drop off',
    style: { color: 'neutral.normal', width: '350px' },
    action: () => null,
  },
];

export const detailsModalMocking = {
  order: {},
  origin: {
    id: 1,
    name: 'Company XYZ',
    phone: 79165023,
    address: 'Hamra Street facing max stores, Beirut, Lebanon',
    lat: 25.456744,
    lng: 55.676685,
  },
  destination: {
    id: 2,
    name: 'Asala El Ghandour',
    phone: 79165023,
    address: 'Hamra Street facing max stores, Beirut, Lebanon',
    lat: 25.485065961813522,
    lng: 55.71342053575056,
  },
  orderHistory: [
    {
      id: 1,
      status: 'Requested',
      time: '10:00',
      date: '2022-1-19',
      name: 'Asala',
      type: 'Admin',
      notes: '',
      isActive: false,
      isDone: true,
    },
    {
      id: 2,
      status: 'Assigned',
      time: '11:00',
      date: '2022-1-19',
      name: 'Asala',
      type: 'Admin',
      notes: 'this is a note',
      isActive: false,
      isDone: true,
    },
    {
      id: 3,
      status: 'Accepted',
      time: '12:00',
      date: '2022-1-19',
      name: 'Asala',
      type: 'Admin',
      notes:
        'Please Leave the order on door, Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta libero atque animi quas aut rem quos repellendus nobis. Impedit, expedita praesentium laudantium delectus ea quod commodi quo recusandae distinctio vel? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad, quia. Cupiditate commodi laboriosam inventore culpa soluta id eligendi illo impedit saepe consequatur, odit rem, harum mollitia a maiores veritatis praesentium',
      isActive: true,
      isDone: false,
    },
    {
      id: 4,
      status: 'Delivered',
      time: '13:00',
      date: '2022-1-19',
      name: 'Asala',
      type: 'Admin',
      notes: '',
      isActive: false,
      isDone: false,
    },
  ],
  stations: [
    {
      id: 1,
      isUser: false,
      name: 'Twitter Main Office',
      phone: '971590000997',
      address: 'LEVEL 47, TOWER 3 OFFICE TOWER), ETIHAD TOWERS WEST CORNICHE ROAD',
      lat: 25.456744,
      lng: 55.676685,
      isOrigin: true,
    },

    {
      id: 2,
      isUser: true,
      name: 'Swftbox Client XYZ',
      phone: '971590000997',
      address: 'LEVEL 47, TOWER 3 OFFICE TOWER), ETIHAD TOWERS WEST CORNICHE ROAD',

      lat: 25.485065961813522,
      lng: 55.71342053575056,
      isOrigin: false,
    },
  ],
  note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
};
