import * as yup from 'yup';

export const CreateUpdateTaskSchema = yup.object().shape({
  retailerId: yup.string().required('Retailer ID is required'),
  assigneeId: yup.string().required('Assignee ID is required'),
  taskCategoryId: yup.string().required('Task Category ID is required'),
  unitCount: yup
    .number()
    .typeError('Unit Count must be a number')
    .positive('Unit Count must be a positive number')
    .required('Unit Count is required'),
  dueDate: yup.date().typeError('Due Date must be a valid date').required('Due Date is required'),
  description: yup
    .string()
    .transform((currentValue, originalValue) => {
      if (typeof originalValue === 'string' && originalValue.trim() === '') {
        return undefined;
      }
      return currentValue;
    })
    .optional(),
  status: yup.string().optional(),
});
