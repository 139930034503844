import { Button, HStack, If } from '@swftbox/style-guide';
import { useState } from 'react';
import { filters, type UserFilterKey } from './UsersFilter.helper';
import { RetailerSelectInput } from 'src/components/Organisms';

interface UsersFilterProps {
  onFilterChange: (filterKey: UserFilterKey) => void;
  setRetailerId: (id: string | null) => void;
  retailerId: string;
}

export function UsersFilter(props: UsersFilterProps) {
  const { onFilterChange, setRetailerId, retailerId } = props;
  const [active, setActive] = useState<string>('all');

  return (
    <>
      <HStack spacing="2" m="5" className="Numbers" flexWrap="wrap" gap="2">
        {filters.map((filter) => (
          <HStack
            key={filter.id}
            border="1px solid #D0D5DD;"
            h="40px"
            lineHeight="40px"
            borderRadius="8px"
            shadow="xs"
            overflow="hidden"
            spacing="0"
          >
            <>
              {filter.children.map((filterItem) => (
                <Button
                  p="8px 12px"
                  fontSize="x-small"
                  key={filterItem.id}
                  variant="grouped"
                  bg={active === filterItem.value ? '#63c3ec' : 'transparent'}
                  color={active === filterItem.value ? '#fff' : 'gray.900'}
                  _focus={{ opacity: 0.85 }}
                  _hover={{ opacity: 0.85 }}
                  onClick={() => {
                    onFilterChange(filterItem.value as UserFilterKey);
                    setActive(filterItem.value);
                  }}
                >
                  {`${filter.name} ${filterItem.label}`}
                </Button>
              ))}
            </>
          </HStack>
        ))}
      </HStack>
      <If
        condition={['crAdmin', 'crUser', 'crOperations'].includes(active)}
        children={
          <HStack spacing="2" m="5" className="Numbers" flexWrap="wrap" gap="2" w={'30%'}>
            <RetailerSelectInput
              value={retailerId}
              onChange={(selectedRetailerId) => {
                setRetailerId(selectedRetailerId);
              }}
            />
          </HStack>
        }
      />
    </>
  );
}
