import { SBInput, Stack } from '@swftbox/style-guide';
import { useForm, type UseFormRegister, type UseFormSetValue } from 'react-hook-form';
import { type location } from 'src/components/Particles';

interface FormValues {
  id: string;
  name: string;
  location: location;
}
interface FulfillmentFormProps {
  fulfillment: FormValues;
}

export default function FulfillmentForm(props: FulfillmentFormProps) {
  const { fulfillment } = props;
  const {
    register,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: { ...fulfillment },
  });
  return (
    <Stack gap={4}>
      <SBInput
        label="Fulfillment Name*"
        {...register('name')}
        isDisabled
        
        
      />
      <SBInput
        label="Address Line 1*"
        {...register('location.addressLine1')}
        isDisabled
        
        
      />

      <SBInput
        label="Address Line 2:"
        {...register('location.addressLine2')}
        isDisabled
        
        
      />

      <SBInput
        label="Area"
        {...register('location.area')}
        isDisabled
        
        
      />

      <SBInput
        label="Street"
        {...register('location.street')}
        isDisabled
        
        
      />

      <SBInput
        label="Building"
        {...register('location.building')}
        isDisabled
        
        
      />

      <SBInput
        label="Landmark"
        {...register('location.landMark')}
        isDisabled
        
        
      />
      <SBInput
        label="City*"
        {...register('location.city')}
        isDisabled
        
        
      />
      <SBInput
        label="Country*"
        {...register('location.country')}
        isDisabled
        
        
      />
    </Stack>
  );
}
