import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { UPDATE_AREA, type UpdateAreaInput } from '../Queries';

interface updateAreaResponse {
  message: string;
}

interface UpdateAreaProps {
  onCompleted: (data: { updateArea: updateAreaResponse }, clientOptions?: clientOptionType) => void;
}

export function useUpdateArea({ onCompleted }: UpdateAreaProps) {
  const [mutate, { error, loading }] = useMutation<
    { updateArea: updateAreaResponse },
    { updateAreaInput: UpdateAreaInput }
  >(UPDATE_AREA, {
    onCompleted,
    refetchQueries: ['Areas'],
  });

  return {
    updateAreaMutation: mutate,
    loading,
    error,
  };
}
