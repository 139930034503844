import { Autocomplete, Box, Button, Grid, GridItem, HStack, Text } from '@swftbox/style-guide';
import { Dispatch, SetStateAction } from 'react';
type TimeOption = {
  colSpan: number;
  label: string;
  start: string;
  end: string;
  id: string;
};
interface TimeSelectionProps {
  option: TimeOption;
  active: boolean;
  handleSelect: (option?: TimeOption) => void;
}
const TimeSelection = ({ option, active, handleSelect }: TimeSelectionProps) => {
  return (
    <Button
      w="100%"
      variant="link"
      borderColor={active ? '#00C2FF' : '#D0D5DD'}
      bg={active ? '#EFFBFF' : '#fff'}
      color={active ? '#00C2FF' : 'gray.700'}
      h="40px"
      shadow="xs"
      onClick={() => handleSelect(option)}
    >
      {option.label}
    </Button>
  );
};
interface SelectTimeProps {
  selectedTime: {
    start: string;
    end: string;
    id: string;
  };
  setSelectedTime: Dispatch<
    SetStateAction<{
      start: string;
      end: string;
      id: string;
    }>
  >;
  options: TimeOption[];
}
const SelectTime = ({ selectedTime, setSelectedTime, options }: SelectTimeProps) => {
  return (
    <>
      <GridItem colSpan={12}>
        <Text fontSize="text-sm" fontWeight="medium" color="gray.700" mb="1">
          Select a Time
        </Text>
        <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={7}>
          {options.map((option) => (
            <GridItem colSpan={option.colSpan}>
              <TimeSelection
                option={option}
                active={option.id === selectedTime.id}
                handleSelect={() =>
                  setSelectedTime({ start: option.start, end: option.end, id: option.id })
                }
              />
            </GridItem>
          ))}
        </Grid>
      </GridItem>

      <GridItem colSpan={12}>
        <HStack
          w="100%"
          border="1px solid"
          borderRadius="8px"
          borderColor={'custom' === selectedTime.id ? '#00C2FF' : '#D0D5DD'}
          bg={'custom' === selectedTime.id ? '#EFFBFF' : '#fff'}
          color={'custom' === selectedTime.id ? '#00C2FF' : 'gray.700'}
          fontWeight="semibold"
          height="60px"
          justifyContent="center"
          shadow="xs"
        >
          <HStack>
            <Text mx="3">Custom</Text>
            <Box w="200px">
              <Autocomplete
                onChange={(time) =>
                  //  @ts-expect-error
                  setSelectedTime({ ...selectedTime, start: time.id, id: 'custom' })
                }
                options={getTimeSlots().map((time) => ({ id: time, label: time }))}
                placeholder="HH:MM - Start Time"
                value={
                  'custom' === selectedTime.id
                    ? getTimeSlots()?.flatMap((option) =>
                        option === selectedTime.start ? { id: option, label: option } : []
                      )
                    : null
                }
              />
            </Box>
            <Box w="200px">
              <Autocomplete
                onChange={(time) =>
                  //  @ts-expect-error
                  setSelectedTime({ ...selectedTime, end: time.id as string, id: 'custom' })
                }
                options={getTimeSlots().map((time) => ({ id: time, label: time }))}
                placeholder="HH:MM - End Time"
                value={
                  'custom' === selectedTime.id
                    ? getTimeSlots()?.flatMap((option) =>
                        option === selectedTime.end ? { id: option, label: option } : []
                      )
                    : null
                }
              />
            </Box>
          </HStack>
        </HStack>
      </GridItem>
    </>
  );
};

export default SelectTime;

function getTimeSlots() {
  let timeSlots = [];
  for (let hours = 2; hours <= 23; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      let timeSlot = '';
      if (hours < 10) timeSlot += '0';
      timeSlot += hours.toString();
      timeSlot += ':';
      if (minutes < 10) timeSlot += '0';
      timeSlot += minutes.toString();
      timeSlots.push(timeSlot);
    }
  }
  return timeSlots;
}
