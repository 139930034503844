import { createContext, useContext } from 'react';
import { type Order, type SimpleOrder } from 'src/components/Particles/resolvers';

interface OrderItemsSelectionContextProps {
  orders: Map<string, SimpleOrder | Order>;
  toggleSelection: (item: SimpleOrder | Order) => void;
}

export const OrderJobItemsSelectionContext = createContext<OrderItemsSelectionContextProps>({
  orders: new Map(),
  toggleSelection: console.log,
});

export const useOrderJobItemsSelectionContext = () =>
  useContext<OrderItemsSelectionContextProps>(OrderJobItemsSelectionContext);
