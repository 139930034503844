import { Box } from '@swftbox/style-guide';

import { OrdersHeader, OrdersTable } from './Components';
import style from './style.module.scss';

export function Fulfillment() {
  return (
    <>
      <Box className={style.order_main}>
        <OrdersHeader />
        <OrdersTable />
      </Box>
    </>
  );
}
