import { GroupOrder, stateBadgeColor } from 'src/components/Particles';
import { OrderHistory } from './OrderHistory';
import { Badge, Box, HStack, IconWText, Stack, Tooltip, Text } from '@swftbox/style-guide';
import EmptyBox from 'src/assets/emptyBox.svg';
import { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { ExchangeBadge } from '../../../../Common';

interface ExchangeDetailsProps {
  groupOrder?: GroupOrder;
}
export default function ExchangeDetails({ groupOrder }: ExchangeDetailsProps) {
  const statusDetails = useMemo(
    () =>
      stateBadgeColor(groupOrder?.status as string, groupOrder?.state ?? '', groupOrder?.isReverse),
    [groupOrder]
  );
  return (
    <>
      {groupOrder && (
        <>
          <Box bg="gray.100" borderRadius="8px" p="5" mb="4">
            <Stack justifyContent="space-between" direction={{ base: 'column', lg: 'row' }}>
              <Box color="primary.700" fontSize="text-lg">
                <Text mb="2" color="gray.700" fontSize="text-sm">
                  {groupOrder?.swftboxTracking} - {groupOrder?.reference}
                </Text>
                <HStack gap="1" spacing="0" wrap="wrap">
                  <Tooltip hasArrow label="Package count">
                    <Badge
                      h="26px"
                      px="15px"
                      lineHeight="26px"
                      fontWeight="medium"
                      borderRadius="12px"
                      fontSize="x-small"
                      variant="outline"
                      color="black"
                      letterSpacing="2px"
                    >
                      <IconWText
                        color="black"
                        text={`x${groupOrder?.packageCount?.toString() ?? ''}`}
                        Icon={<SVG src={PackageIcon} width="15px" stroke="currentColor" />}
                      />
                    </Badge>
                  </Tooltip>
                  <ExchangeBadge state={groupOrder.state} isReverse={groupOrder.isReverse} />
                </HStack>
              </Box>
              <Box>
                <Badge
                  py="1px"
                  fontWeight="medium"
                  borderRadius="8px"
                  mb="2"
                  width="100%"
                  p="4px 8px"
                  textAlign="center"
                  fontSize="text-sm"
                  border="1px solid"
                  borderColor={statusDetails.border ?? 'transparent'}
                  bg={statusDetails.bg}
                  color={statusDetails.color}
                >
                  {statusDetails.text}
                </Badge>
              </Box>
            </Stack>
          </Box>
          <OrderHistory orderId={groupOrder.id} />{' '}
        </>
      )}
      {!groupOrder && (
        <Box bg="gray.100" borderRadius="8px" p="5" minH="200px">
          <Box w="100%" h="200px" justifyContent="center" alignItems="center" display="flex">
            {<EmptyTable icon={EmptyBox} title="No Exchange History" />}
          </Box>
        </Box>
      )}
    </>
  );
}
