import { off, onValue, ref, type DatabaseReference } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from 'src/components/Particles';
import { Config, FBDatabase, authenticateFirebase } from 'src/config';

export interface Footprint {
  driverId: string;
  driverName: string;
  latitude: number;
  longitude: number;
  isOnline: boolean;
}

let liveFootprintsRef: DatabaseReference | null = null;

function getLiveFootprintsRef() {
  try {
    if (liveFootprintsRef) return liveFootprintsRef;
    const collectionName = Config.Firebase.FOOTPRINTS_COLLECTION || 'live-footprints';
    liveFootprintsRef = ref(FBDatabase, collectionName);
    return liveFootprintsRef;
  } catch (error) {
    return null;
  }
}

export function useFootprintService() {
  const [footprints, setFootprints] = useState<Footprint[]>([]);
  const { user } = useAuthContext();
  useEffect(() => {
    authenticateFirebase(user);
  }, [user]);

  const updateFootprints = useCallback((snapshot: Record<string, Footprint>) => {
    const footprints = Object.values(snapshot);
    setFootprints(footprints);
  }, []);

  useEffect(() => {
    const footprintRef = getLiveFootprintsRef();
    if (!footprintRef) return;
    onValue(footprintRef, (snapshot) => {
      const data = snapshot.val();
      updateFootprints(data);
    });

    return () => {
      if (footprintRef) {
        off(footprintRef);
      }
    };
  }, [updateFootprints]);

  return footprints.filter((footprint) => footprint.isOnline);
}
