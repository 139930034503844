import { useMutation } from '@apollo/client';
import {
  CARRIYO_INTEGRATION_CARRIERS,
  UPDATE_CARRIYO_INTEGRATION_CARRIER,
} from '../orders.graphql';
import { type UpdateCarriyoIntegrationCarrierInput } from '../orders.types';

interface UpdateCarriyoIntegrationCarrierResponse {
  message: string;
}

interface UpdateCarriyoIntegrationCarrierProps {
  updateCarriyoIntegrationCarrierInput: UpdateCarriyoIntegrationCarrierInput;
  onCompleted: (message: string) => void;
}

export function useUpdateCarriyoIntegrationCarrier() {
  const [mutate, { loading, error }] = useMutation<
    { updateCarriyoIntegrationCarrier: UpdateCarriyoIntegrationCarrierResponse },
    { updateCarriyoIntegrationCarrierInput: UpdateCarriyoIntegrationCarrierInput }
  >(UPDATE_CARRIYO_INTEGRATION_CARRIER);

  async function updateCarriyoIntegrationCarrier({
    updateCarriyoIntegrationCarrierInput,
    onCompleted,
  }: UpdateCarriyoIntegrationCarrierProps) {
    await mutate({
      variables: {
        updateCarriyoIntegrationCarrierInput,
      },
      onCompleted: (data) => {
        onCompleted(data.updateCarriyoIntegrationCarrier.message);
      },
      refetchQueries: [CARRIYO_INTEGRATION_CARRIERS],
    });
  }

  return {
    updateCarriyoIntegrationCarrier,
    loading,
    error,
  };
}
