import { useMutation } from '@apollo/client';
import { GET_ITEMS_QUERY, type UpdateItemInput, UPDATE_ITEMS_QUERY } from '../Queries';
import { QueriesStore } from '../../../queriesStore';
import { clientOptionType } from '../../../SharedTypes';

interface UpdateItemResponse {
  message: string;
}

interface UpdateItemProps {
  payload: UpdateItemInput[];
  onCompleted: (
    data: { updateItems: UpdateItemResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateItem() {
  const [mutate, { loading, data, error }] = useMutation<
    { updateItems: UpdateItemResponse },
    { updateItemInput: UpdateItemInput[] }
  >(UPDATE_ITEMS_QUERY);

  async function callUpdateItem({ payload, onCompleted }: UpdateItemProps) {
    await mutate({
      variables: {
        updateItemInput: payload,
      },
      onCompleted,
      refetchQueries: [
        'FilterOrders',
        {
          query: GET_ITEMS_QUERY,
          variables: QueriesStore.get('GET_ITEMS_QUERY'),
        },
      ],
    });
  }

  return {
    callUpdateItem,
    data: data?.updateItems.message,
    loading,
    error,
  };
}
