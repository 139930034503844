import {
  type MenuItemProps,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Checkbox,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { useState, useMemo } from 'react';
import {
  useJobs,
  JobStatusFilter,
  ElasticSearchSortOptions,
  JobSortableFields,
} from 'src/components/Particles';

interface DriverJobsProps extends MenuItemProps {
  driverId?: string;
  handleSelectedJob?: (jobId: string) => void;
}
export function DriverJobs(props: Readonly<DriverJobsProps>) {
  const { driverId, handleSelectedJob } = props;
  const [jobId, setJobId] = useState<string>('');
  const [newJobRequired, setNewJobRequired] = useState<boolean>(false);

  const { getJobs, jobs } = useJobs({
    page: 1,
    perPage: 10,
    status: [JobStatusFilter.Assigned, JobStatusFilter.Started],
    order: ElasticSearchSortOptions.DESC,
    orderBy: JobSortableFields.createdAt,
  });

  useMemo(() => {
    if (driverId) getJobs({ driverId: [driverId] });
  }, [getJobs, driverId]);

  const onJobSelected = (selectedId: string) => {
    if (newJobRequired) return;
    setJobId(selectedId);
    if (handleSelectedJob) handleSelectedJob(selectedId);
  };
  const onNewJobRequiredChange = () => {
    if (!newJobRequired) {
      setJobId('');
      if (handleSelectedJob) handleSelectedJob('');
    }
    setNewJobRequired(!newJobRequired);
  };
  if (!jobs.length) return null;
  return (
    <Flex direction="column" gap="0vh">
      <Flex direction="row" gap="0vh" mt="2vh">
        <TableContainer maxH={'400px'} overflowY={'scroll'} overflowX={'hidden'}>
          <Table>
            <Thead>
              <Tr bg="#fbfbfc">
                <Th
                  colSpan={12}
                  textAlign={'center'}
                  color={newJobRequired ? 'gray.300' : 'primary.500'}
                  fontSize={'medium'}
                >
                  Assigned Jobs
                </Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={'800'}>
              {jobs?.map((job) => {
                const isSelected = job.id === jobId;
                return (
                  <Tr
                    key={job.id}
                    backgroundColor={
                      isSelected ? '#2ab5ec' : !newJobRequired ? 'white' : 'gray.100'
                    }
                    onClick={(e) => {
                      if (!newJobRequired) {
                        onJobSelected(job.id);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    cursor={!newJobRequired ? 'pointer' : 'default'}
                  >
                    <Td textColor={!newJobRequired ? 'primary' : 'gray.300'}>{job.reference}</Td>
                    <Td textColor={!newJobRequired ? 'primary' : 'gray.300'}>
                      {job.extraProps?.ordersCount} Order(s)
                    </Td>
                    <Td textColor={!newJobRequired ? 'primary' : 'gray.300'}>
                      Created At: {format(new Date(job.createdAt), 'HH:mm MMM dd, yyyy')}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Checkbox
        onChange={onNewJobRequiredChange}
        isChecked={newJobRequired}
        size={'sm'}
        mt="3"
        mr="3"
        color={'primary.500'}
        alignSelf={'flex-end'}
      >
        <Text color="primary.500" fontSize={'14px'}>
          Create new job
        </Text>
      </Checkbox>
    </Flex>
  );
}
