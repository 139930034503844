import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import { type Question } from 'src/components/Particles';
import { EditQuestion } from './EditQuestion';
import { DeleteQuestion } from './DeleteQuestion/DeleteQuestion';

interface QuestionsTableProps {
  questions: Question[];
}

export default function QuestionsTable(props: QuestionsTableProps) {
  const { questions } = props;

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead>
          <Tr bg="#fbfbfc">
            <Th>Sequence</Th>
            <Th>Question</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {questions.map((question: Question) => {
            return (
              <Tr key={question.id}>
                <Td>
                  <Text>{question.sequence}</Text>
                </Td>
                <Td>
                  <Text>{question.question}</Text>
                </Td>
                <Td textAlign="end">
                  <HStack justifyContent="end">
                    <EditQuestion question={question} />
                    <DeleteQuestion questionId={question.id} />
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
