import { Button, Tooltip } from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import { useOrdersFilters, useOrdersFiltersActions } from 'src/components/Particles';

export const ClearFilter = () => {
  const {
    filters: { statusId },
  } = useOrdersFilters();
  const dispatch = useOrdersFiltersActions();
  if (!statusId?.length) return null;

  return (
    <Tooltip label="Reset filters" hasArrow>
      <Button
        variant="link"
        color="gray.700"
        onClick={() => {
          dispatch({
            type: 'ORDER_STATUS_MAP_CHANGE',
            payload: { status: [] },
            id: [],
          });
        }}
        border="1px solid"
        borderColor="rgba(255, 101, 101, 1)"
        boxShadow="xs"
        height="40px"
        minW="40px"
      >
        <SVG src={XIcon} stroke="rgba(255, 101, 101, 1)" fill="transparent" width="16px" />
      </Button>
    </Tooltip>
  );
};
