import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../SharedTypes';
import { ASSIGN_LASTMILE_JOB_MUTATION } from '../Queries';

interface AssignLastMileJobProps {
  payload: { driverId: string; isFree: boolean };
  onCompleted?: (message: string) => void;
}

export function useAssignLastMileJob() {
  const [mutate, { loading, error }] = useMutation<
    { createLastMileRoute: MessageResponse },
    { lastMileRouteInput: { driverId: string; isFree: boolean } }
  >(ASSIGN_LASTMILE_JOB_MUTATION);

  async function assignLastMile({ payload, onCompleted }: AssignLastMileJobProps) {
    const { driverId, isFree } = payload;
    await mutate({
      variables: {
        lastMileRouteInput: {
          driverId,
          isFree,
        },
      },
      onCompleted: (data) => {
        if (onCompleted) onCompleted(data.createLastMileRoute.message);
      },
    });
  }

  return {
    assignLastMile,
    loading,
    error,
  };
}
