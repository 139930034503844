import {
  Box,
  HStack,
  Button,
  SBAddIcon,
  Grid,
  GridItem,
  FormControl,
  Autocomplete,
  Input,
  IconButton,
  SBTrashIcon,
  Text,
} from '@swftbox/style-guide';
import { type FC, useEffect } from 'react';
import { type DropProfileCity, useCities } from 'src/components/Particles';

interface Props {
  cities: DropProfileCity[];
  editable?: boolean;
  existingCitiesDropProfile: string[];
  onChange?: (cities: DropProfileCity[]) => void;
}

export const DropProfileCities: FC<Props> = ({
  cities,
  onChange,
  editable,
  existingCitiesDropProfile,
}) => {
  const { data: citiesList, query: getCities } = useCities();

  useEffect(() => {
    void getCities();
  }, []);

  const addNewCity = () => {
    onChange?.([
      ...cities,
      {
        city: '',
        cutoffTime: '',
        billingSlab: null,
      },
    ]);
  };

  const deleteCity = (index: number) => {
    onChange?.(cities.filter((_, i) => i !== index));
  };

  const handleInputName = (inputName: string, event: any) => {
    if (inputName === 'billingSlab') return +event.target.value;
    if (inputName === 'city') return event?.value;
    return event.target.value;
  };
  const handleInputChange = (inputName: string, index: number, e: any) => {
    onChange?.(
      cities.map((dropProfileCity, i) => {
        if (i !== index) {
          return dropProfileCity;
        }

        return {
          ...dropProfileCity,
          [inputName]: handleInputName(inputName, e),
        };
      })
    );
  };

  return (
    <Box position="relative">
      <HStack justifyContent="space-between" mb="3">
        <Text fontSize="text-sm" fontWeight="semibold">
          City config
        </Text>
        {editable && (
          <Button size="sm" fontSize="text-sm" onClick={addNewCity}>
            <SBAddIcon width="15px" />
            <Text fontWeight="normal">Add city</Text>
          </Button>
        )}
      </HStack>
      <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4} marginTop={4}>
        <GridItem colSpan={5}>City</GridItem>
        <GridItem colSpan={3}>Cutoff time</GridItem>
        <GridItem colSpan={3}>Billing slab</GridItem>
        <GridItem colSpan={1}></GridItem>
      </Grid>
      {cities?.map((city, index) => (
        <Grid
          gridTemplateColumns="repeat(12,1fr)"
          gap="2"
          rowGap={4}
          key={index}
          marginTop={4}
          alignItems="center"
        >
          <GridItem colSpan={5}>
            {!editable ? (
              city.city
            ) : (
              <FormControl>
                <Autocomplete
                  onChange={(option) => {
                    handleInputChange('city', index, option);
                  }}
                  value={citiesList?.flatMap((option) =>
                    option.standardCityName === city.city
                      ? {
                          label: `${option.city} (${option.standardCityName})`,
                          value: option.standardCityName,
                        }
                      : []
                  )}
                  options={citiesList
                    .filter(
                      (cities) => !existingCitiesDropProfile.includes(cities.standardCityName)
                    )
                    .map((city) => ({
                      label: `${city.city} (${city.standardCityName})`,
                      value: city.standardCityName,
                    }))}
                  isClearable
                  isSearchable
                />
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={3}>
            {!editable ? (
              city.cutoffTime
            ) : (
              <FormControl>
                <Input
                  type="time"
                  value={city.cutoffTime}
                  onChange={handleInputChange.bind(null, 'cutoffTime', index)}
                  placeholder="Cutoff time"
                  required
                />
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={3}>
            {' '}
            {!editable ? (
              city.billingSlab
            ) : (
              <FormControl>
                <Input
                  type="number"
                  value={city.billingSlab ?? ''}
                  onChange={handleInputChange.bind(null, 'billingSlab', index)}
                  placeholder="Billing slab"
                />
              </FormControl>
            )}
          </GridItem>
          <GridItem colSpan={1}>
            {editable && (
              <IconButton
                aria-label="delete-city"
                icon={<SBTrashIcon width="16px" />}
                color="red"
                variant="link"
                onClick={deleteCity.bind(null, index)}
              />
            )}
          </GridItem>
        </Grid>
      ))}
      {!cities?.length && (
        <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4} marginTop={4}>
          <GridItem colSpan={12}>
            <Text align="center">No city configs added</Text>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default DropProfileCities;
