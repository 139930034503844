import { useQuery } from '@apollo/client';
import { GET_FULFILLMENTS_QUERY, type FulfillmentCenter } from '../Queries';

interface FulfillmentsResponse {
  fulfillments: FulfillmentCenter[];
}

export function useFulfillments() {
  const { data, loading, error } = useQuery<FulfillmentsResponse>(GET_FULFILLMENTS_QUERY);
  return {
    fulfillments: data?.fulfillments ?? [],
    loading,
    error,
  };
}
