import {
  Box,
  Button,
  useDisclosure,
  Image,
  Link,
  SBModal,
  Stack,
  Text,
} from '@swftbox/style-guide';
import { type ReactNode } from 'react';

interface MediaModalProps {
  mediaType: 'image' | 'video';
  mediaHref: string;
  mediaDescription?: string;
  openButton: ReactNode;
}

export function MediaContainer({
  mediaType,
  mediaHref,
  mediaDescription,
  openButton,
}: MediaModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} variant="link" _hover={{ textDecor: 'none' }}>
        {openButton}
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<></>}
        size="2xl"
        body={
          <Stack gap={2} align="center">
            {mediaDescription && (
              <Text color="gray.700" fontSize="text-md" fontWeight="semibold">
                {mediaDescription}
              </Text>
            )}
            {mediaType === 'image' ? (
              <Link href={mediaHref} isExternal>
                <Image boxSize="500px" objectFit="contain" src={mediaHref} alt="Media Image" />
              </Link>
            ) : (
              <Link href={mediaHref} isExternal>
                <Box>
                  <video width="350" height="350" controls>
                    <source src={mediaHref} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              </Link>
            )}
          </Stack>
        }
        footer={
          <Button onClick={onClose} variant="outline" w="100%" color="primary.900">
            Close
          </Button>
        }
        bordered={false}
      />
    </>
  );
}
