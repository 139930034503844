import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { QueriesStore } from '../queriesStore';
import { type Retailer } from './retailer.types';
import { GET_RETAILERS } from './retailers.graphql';

interface QueryResponse {
  paginatedRetailers: {
    data: Retailer[];
    total: number;
    pageCount: number;
  };
}

interface QueryVariables {
  page: number;
  perPage?: number;
  status?: string[];
  keyword?: string;
}

export function useRetailersQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_RETAILERS);

  const getRetailers = useCallback(
    (variables: QueryVariables) => {
      QueriesStore.update('GET_RETAILERS', { ...variables, page: 1 });
      void query({
        variables,
      });
    },
    [query]
  );

  return {
    getRetailers,
    retailers: data?.paginatedRetailers?.data ?? [],
    totalRetailers: data?.paginatedRetailers?.total ?? 0,
    pageCount: data?.paginatedRetailers?.pageCount ?? 0,
    loading,
    error,
  };
}
