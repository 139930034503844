import { type ReactNode, useCallback, useState } from 'react';
import { OrdersSelectionContext } from 'src/components/Particles/Contexts/Order';
import { type Order } from 'src/components/Particles/resolvers';

export function OrderSelectionProvider({ children }: { children: ReactNode }) {
  const [selectedOrders, setSelectedOrders] = useState<Map<string, Order>>(new Map());
  const [detailedOrder, setDetailedOrder] = useState<Order>();

  const select = useCallback(
    (order: Order) => {
      const newSelections = new Map([...selectedOrders.entries(), [order.id, order]]);
      setSelectedOrders(newSelections);
    },
    [selectedOrders]
  );

  const unselect = useCallback(
    (id: string) => {
      const updatedSelectedOrders: Array<[string, Order]> = [];
      for (const [selectedId, order] of selectedOrders.entries()) {
        if (selectedId !== id) {
          updatedSelectedOrders.push([selectedId, order]);
        }
      }
      setSelectedOrders(new Map(updatedSelectedOrders));
    },
    [selectedOrders]
  );

  const unselectAll = useCallback(() => {
    setSelectedOrders(new Map());
  }, []);
  const selectAll = useCallback((orders: Order[]) => {
    setSelectedOrders(new Map(orders.map((order) => [order.id, order])));
  }, []);

  const toggleDetailedOrder = useCallback(
    (order?: Order) => {
      setDetailedOrder(order);
    },
    [setDetailedOrder]
  );

  return (
    <OrdersSelectionContext.Provider
      value={{
        selectedOrders,
        select,
        unselect,
        unselectAll,
        toggleDetailedOrder,
        detailedOrder,
        selectAll,
      }}
    >
      {' '}
      {children}{' '}
    </OrdersSelectionContext.Provider>
  );
}
