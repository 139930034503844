import { useMutation } from '@apollo/client';
import { clientOptionType, SortOptions } from '../../SharedTypes';
import {
  CREATE_INVOICE,
  LIST_INVOICES,
  type CreateInvoiceInput,
  type InvoiceMutationResponse,
} from '../Queries';

interface Props {
  onCompleted: (
    data: { createInvoice: InvoiceMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export const useGenerateInvoiceMutation = ({ onCompleted }: Props) => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { createInvoice: InvoiceMutationResponse },
    { createInvoiceInput: CreateInvoiceInput }
  >(CREATE_INVOICE, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_INVOICES,
        variables: { perPage: 50, page: 1, orderBy: 'createdAt', order: SortOptions.DESC },
        pollInterval: 5000,
      },
    ],
  });

  return {
    mutate,
    reset,
    loading,
    error,
    ...rest,
  };
};
