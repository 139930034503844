import { gql } from '@apollo/client';

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($updateLocationInput: UpdateLocationInput!) {
    updateLocation(updateLocationInput: $updateLocationInput) {
      message
      data {
        id
        country
        city
        addressLine1
        addressLine2
        addressType
        area
        street
        building
        landMark
        coordinates {
          longitude
          latitude
        }
        entityId
        entityType
        isDefault
        isVerified
        dispatchZone
        dispatchCity
        isInternational
        phone
      }
    }
  }
`;
