import {
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PencilIcon,
  SBMenuDotsVerticalIcon,
  SBUserRemoveIcon,
  SBUserDeleteIcon,
  SBCrossCircleIcon,
  SBUserIcon,
  SBCheckIcon,
  SBCalendarIcon,
  If,
  SBUserTimeIcon,
} from '@swftbox/style-guide';
import { actionsColors } from './helper';

interface DriversActionsButtonsProps {
  isOnline: boolean;
  deleteDriver: () => void;
  updateDriverStatus: (status: string) => void;
  updateUserStatus: (status: string) => void;
  status: string;
  driverStatus: string;
  onOpen: () => void;
  onMenuOpen: () => void;
  openShiftTimingForm: (val: boolean) => void;
  goOnBreak: (val: boolean) => void;
}
export default function DriversActionButtons(props: DriversActionsButtonsProps) {
  const {
    // deleteDriver,
    updateDriverStatus,
    updateUserStatus,
    status,
    driverStatus,
    onOpen,
    onMenuOpen,
    isOnline,
    goOnBreak,
    openShiftTimingForm,
  } = props;

  return (
    <Menu>
      <MenuButton as="button" onClick={onMenuOpen}>
        <SBMenuDotsVerticalIcon width="15px" />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => {
            openShiftTimingForm(false);
            onOpen();
          }}
        >
          <IconWText
            text="Edit"
            spacing="20px"
            Icon={<PencilIcon width="15px" viewBox="0 0 16 16" />}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            openShiftTimingForm(true);
            onOpen();
          }}
        >
          <IconWText
            text="Edit Schedule Timing"
            spacing="20px"
            Icon={<SBCalendarIcon width="15px" />}
          />
        </MenuItem>
        <If
          children={
            <MenuItem>
              <IconWText
                text="Go on Break"
                spacing="20px"
                onClick={() => {
                  goOnBreak(false);
                }}
                Icon={<SBUserTimeIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={isOnline}
        />
        <If
          children={
            <MenuItem>
              <IconWText
                text="Back to work"
                spacing="20px"
                onClick={() => {
                  goOnBreak(true);
                }}
                Icon={<SBUserTimeIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={!isOnline}
        />
        <If
          children={
            <MenuItem>
              <IconWText
                text="Deactivate"
                spacing="20px"
                color={actionsColors.DISABLED}
                onClick={() => {
                  updateUserStatus('DISABLED');
                }}
                Icon={<SBUserRemoveIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={status !== 'DISABLED'}
        />
        <If
          children={
            <MenuItem>
              <IconWText
                text="Activate"
                spacing="20px"
                color={actionsColors.ACTIVE}
                onClick={() => {
                  updateUserStatus('ACTIVE');
                }}
                Icon={<SBUserIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={status !== 'ACTIVE'}
        />
        <If
          children={
            <MenuItem>
              <IconWText
                text="Disable"
                spacing="20px"
                color={actionsColors.DISABLED}
                onClick={() => {
                  updateDriverStatus('DISABLED');
                }}
                Icon={<SBCrossCircleIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={driverStatus !== 'DISABLED'}
        />
        <If
          children={
            <MenuItem>
              <IconWText
                text="Enable"
                spacing="20px"
                color={actionsColors.ENABLED}
                onClick={() => {
                  updateDriverStatus('ENABLED');
                }}
                Icon={<SBCheckIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={driverStatus !== 'ENABLED'}
        />
        <If
          children={
            <MenuItem>
              <IconWText
                text="Mark Offboarded"
                spacing="20px"
                color={actionsColors.OFFBOARDED}
                onClick={() => {
                  updateDriverStatus('OFFBOARDED');
                }}
                Icon={<SBUserRemoveIcon width="15px" />}
              />
            </MenuItem>
          }
          condition={driverStatus !== 'OFFBOARDED'}
        />
        {/* <MenuItem>
          <IconWText
            text="Delete"
            spacing="20px"
            color="red.normal"
            onClick={deleteDriver}
            Icon={<SBUserDeleteIcon width="15px" />}
          />
        </MenuItem> */}
      </MenuList>
    </Menu>
  );
}
