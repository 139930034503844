import { Box, IconButton, SBModal, useDisclosure, Text, SBEyeIcon } from '@swftbox/style-guide';
import { UpdateTaskCategoryInput, type TaskCategory } from 'src/components/Particles';
import { TaskCategoryForm } from '../../Forms';
import { useForm } from 'react-hook-form';

interface ViewTaskCategoryProps {
  taskCategory: TaskCategory;
}
export function ViewTaskCategory({ taskCategory }: ViewTaskCategoryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register } = useForm<UpdateTaskCategoryInput>({
    mode: 'all',
    defaultValues: {
      name: taskCategory.name,
      id: taskCategory.id,
      description: taskCategory.description,
      unitType: taskCategory.unitType,
    },
  });
  function onConfirm() {}

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'blue' }}
        aria-label="view Category"
        icon={<SBEyeIcon width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEyeIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>View Task Category</Text>
          </Box>
        }
        size="md"
        body={<TaskCategoryForm viewModeOnly={true} register={register} />}
        bordered={false}
        handleConfirm={onConfirm}
        footer={<></>}
      />
    </>
  );
}
