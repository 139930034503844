import { Button, HStack } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import {
  useFulfillmentOrdersFilters,
  useFulfillmentOrdersFiltersActions,
} from 'src/components/Particles';
import { DeliveryProfiles } from '../../Orders/Components/OrganizingFilters/constants';

export const DropProfileType = () => {
  const [active, setActive] = useState<string[]>([]);
  const dispatch = useFulfillmentOrdersFiltersActions();
  const {
    organizedFilters: { or },
  } = useFulfillmentOrdersFilters();

  useEffect(() => {
    if (!or?.dropProfileKey?.length) {
      setActive(['']);
    }
  }, [or]);

  const handleActive = (id: string) => {
    if (active?.includes(id)) {
      setActive(active.filter((ele) => ele !== id));
    } else {
      setActive([...active, id]);
    }
  };

  return (
    <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2">
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        spacing="0"
      >
        {DeliveryProfiles?.filter(dp => !['returns', 'exchanges'].includes(dp.id)).map((state) => (
          <Button
            p="8px 12px"
            fontSize="x-small"
            key={state.id}
            variant="grouped"
            bg={active.includes(state.id) ? '#63c3ec' : 'transparent'}
            color={active.includes(state.id) ? '#fff' : 'gray.900'}
            _focus={{ opacity: 0.85 }}
            _hover={{ opacity: 0.85 }}
            onClick={() => {
              dispatch({
                type: 'ORDERS_TYPES_CHANGE',
                payload: { type: state.value },
              });
              handleActive(state.id);
            }}
          >
            {`${state.name}`}
          </Button>
        ))}
      </HStack>
    </HStack>
  );
};
