import { gql } from '@apollo/client';

export const GET_SETTLEMENTS = gql`
  query PaginatedSettlements(
    $subjectId: UUID
    $subjectType: SettlementSubjectType
    $type: SettlementType
    $page: Int!
    $perPage: Int
    $status: PaymentStatus
    $expenses: Boolean
    $orderBy: String
    $order: OrderSort
  ) {
    paginatedSettlements(
      subjectId: $subjectId
      subjectType: $subjectType
      type: $type
      page: $page
      perPage: $perPage

      status: $status
      expenses: $expenses
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        type
        subjectType
        subjectId
        amount
        currency
        receipt
        description
        approvedAt
        approvedBy
        createdAt
        driver {
          id
          user {
            name
          }
        }
        retailer {
          id
          name
        }
        status
      }
      total
      currentPage
      nextPage
    }
  }
`;

export const CREATE_SETTLEMENT = gql`
  mutation createSettlement($createSettlementInput: CreateSettlementInput!) {
    createSettlement(createSettlementInput: $createSettlementInput) {
      id
      subjectId
      subjectType
      amount
      type
      receipt
    }
  }
`;

export const UPDATE_SETTLEMENT = gql`
  mutation UpdateSettlement($updateSettlementInput: UpdateSettlementInput!) {
    updateSettlement(updateSettlementInput: $updateSettlementInput) {
      id
      approvedBy
    }
  }
`;

export const REMOVE_SETTLEMENT = gql`
  mutation RemoveSettlement($id: UUID!) {
    removeSettlement(id: $id) {
      message
      success
    }
  }
`;
