import { useQuery, useMutation } from '@apollo/client';
import {
  GET_SETTLEMENTS,
  CREATE_SETTLEMENT,
  REMOVE_SETTLEMENT,
  UPDATE_SETTLEMENT,
} from './settlements.graphql';
import {
  Settlement,
  SettlementFilterArgs,
  CreateSettlementInput,
  UpdateSettlementInput,
} from './payments.type';
import { useFileUpload, UploadDirectory } from '../upload';
import { QueriesStore } from '../queriesStore';

interface QueryResponse {
  paginatedSettlements: {
    total: number;
    perPage: number;

    currentPage: number;
    nextPage: number | null;
    pageCount: number;
    data: Settlement[];
  };
}

export function useSettlementsQuery(filter: SettlementFilterArgs) {
  QueriesStore.update('GET_SETTLEMENTS', { ...filter });
  const { data, loading, error, ...rest } = useQuery<QueryResponse, SettlementFilterArgs>(
    GET_SETTLEMENTS,
    {
      variables: filter,
    }
  );

  return {
    settlements: data?.paginatedSettlements || null,
    loading,
    error,
    ...rest,
  };
}

export const useCreateSettlementMutation = () => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { createSettlement: Settlement },
    { createSettlementInput: CreateSettlementInput }
  >(CREATE_SETTLEMENT);

  const { uploadFile } = useFileUpload({ directory: UploadDirectory.RECEIPTS });

  async function createSettlement(createSettlementInput: CreateSettlementInput) {
    let receipt = null;
    if (createSettlementInput.receipt instanceof File) {
      const { filePath } = await uploadFile(createSettlementInput.receipt);
      receipt = filePath;
    }

    return mutate({
      variables: {
        createSettlementInput: {
          ...createSettlementInput,
          amount: Number(createSettlementInput.amount),
          receipt,
        },
      },
      refetchQueries: [{ query: GET_SETTLEMENTS, variables: QueriesStore.get('GET_SETTLEMENTS') }],
    });
  }

  return {
    createSettlement,
    reset,
    loading,
    error,
    ...rest,
  };
};

export const useUpdateSettlementMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { updateSettlement: Settlement },
    { updateSettlementInput: UpdateSettlementInput }
  >(UPDATE_SETTLEMENT);

  const { uploadFile } = useFileUpload({ directory: UploadDirectory.RECEIPTS });

  async function updateSettlement(updateSettlementInput: UpdateSettlementInput) {
    let receipt = updateSettlementInput.receipt;

    if (typeof receipt === 'string') {
      // If receipt is string, it was not updated, no need to update it
      receipt = undefined;
    } else if (typeof receipt === 'undefined') {
      // This happens when the user clears the file input
      // Set as null to be removed in backend
      receipt = null;
    } else if (updateSettlementInput.receipt instanceof File) {
      const { filePath } = await uploadFile(updateSettlementInput.receipt);
      receipt = filePath;
    }

    return mutate({
      variables: {
        updateSettlementInput: {
          ...updateSettlementInput,
          amount: Number(updateSettlementInput.amount),
          receipt,
        },
      },
      refetchQueries: [{ query: GET_SETTLEMENTS, variables: QueriesStore.get('GET_SETTLEMENTS') }],
    });
  }

  return {
    updateSettlement,
    reset,
    loading,
    error,
  };
};

export const useUpdateSettlementStatusMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { updateSettlement: Settlement },
    { updateSettlementInput: UpdateSettlementInput }
  >(UPDATE_SETTLEMENT);

  async function updateSettlementStatus(updateSettlementInput: UpdateSettlementInput) {
    return mutate({
      variables: {
        updateSettlementInput: { ...updateSettlementInput },
      },
      refetchQueries: [{ query: GET_SETTLEMENTS, variables: QueriesStore.get('GET_SETTLEMENTS') }],
    });
  }

  return {
    updateSettlementStatus,
    reset,
    loading,
    error,
  };
};

export const useRemoveSettlementMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { removeSettlement: { success: boolean; message: string } },
    { id: string }
  >(REMOVE_SETTLEMENT);

  async function removeSettlement(id: string) {
    return mutate({
      variables: {
        id,
      },
      refetchQueries: [{ query: GET_SETTLEMENTS, variables: QueriesStore.get('GET_SETTLEMENTS') }],
    });
  }

  return {
    removeSettlement,
    reset,
    loading,
    error,
  };
};
