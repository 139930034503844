import { useLazyQuery } from '@apollo/client';
import { LIST_FEATURES, type Feature } from '../Queries';

interface FeaturesResponse {
  features: Feature[];
}

export function useFeaturesQuery() {
  const [query, { data, loading, error }] = useLazyQuery<FeaturesResponse>(
    LIST_FEATURES
  );

  function getFeatures() {
    return query();
  }

  return {
    getFeatures,
    features: data?.features || [],
    loading,
    error,
  };
}