import { Box } from '@swftbox/style-guide';
import { InvoiceLineItemsList } from './Components';
import { useEffect } from 'react';
import { useInvoicesSettingsQuery } from 'src/components/Particles';

export const Invoices = () => {
  const { invoicesSettings, getInvoicesSettings, loading } = useInvoicesSettingsQuery();

  useEffect(() => {
    getInvoicesSettings();
  }, []);

  return (
    <>
      <Box pb="8">
        <InvoiceLineItemsList invoiceLineItems={invoicesSettings}/>
      </Box>
    </>
  );
};
