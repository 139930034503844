import { useMutation } from '@apollo/client';
import { removeTypeName } from 'src/components/Particles/Helpers';
import { clientOptionType } from '../../../SharedTypes';
import {
  UPDATE_TASK_CATEGORY,
  GET_TASK_CATEGORIES,
  type UpdateTaskCategoryInput,
  type AddTaskCategoryResponse,
} from '../Queries';

interface UpdateTaskCategoryProps {
  payload: UpdateTaskCategoryInput;
  onCompleted: (
    data: { updateTaskCategory: AddTaskCategoryResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateTaskCategory() {
  const [mutate, { data, error, loading }] = useMutation<
    { updateTaskCategory: AddTaskCategoryResponse },
    { updateTaskCategoryInput: UpdateTaskCategoryInput }
  >(UPDATE_TASK_CATEGORY);

  const callUpdateTaskCategory = ({ payload, onCompleted }: UpdateTaskCategoryProps) => {
    return mutate({
      variables: { updateTaskCategoryInput: payload },
      refetchQueries: [
        {
          query: GET_TASK_CATEGORIES,
        },
      ],
      onCompleted,
    });
  };

  return {
    callUpdateTaskCategory,
    data,
    error,
    loading,
  };
}
