import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SUBSCRIPTION_QUERY } from './Subscription.graphql';
import { type RetailerSubscription } from './retailer.types';

interface QueryResponse {
  retailerSubscription: RetailerSubscription;
}
interface QueryInput {
  retailerSubscribePlanInput: {
    retailerId: string;
  };
}

export function useRetailerSubscriptionQuery() {
  const [query, rest] = useLazyQuery<QueryResponse, QueryInput>(GET_SUBSCRIPTION_QUERY);
  return {
    query,
    ...rest,
  };
}
