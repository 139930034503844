import { Tbody, Td, Tr, toast } from '@swftbox/style-guide';
import { memo, useCallback } from 'react';
import EmptyBox from 'src/assets/emptyBox.svg';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useSyncOrders, type Order } from 'src/components/Particles';
import CellModals from './TableCells/CellModals';
import CellSelected from './TableCells/CellSelected';
import OrderTableCell from './TableCells/TableCell';
import OrderCellLoading from './TableCells/CellLoading';
interface TableBodyProps {
  orders: Order[];
  loading: boolean;
  called: boolean;
  displayedRows: number;
}

const NonMemorizedTableBody = ({ orders, loading, called, displayedRows }: TableBodyProps) => {
  const { syncOrders } = useSyncOrders();

  const performSyncOrders = useCallback(
    (order: Order) => {
      void syncOrders({
        onCompleted: (data) => {
          toast.success(data.fixOrdersElasticSync.message);
        },
        payload: {
          ordersSwftboxTracking: [order.swftboxTracking],
        },
      });
    },
    [syncOrders]
  );

  return (
    <Tbody>
      {orders?.length === 0 && (
        <>
          {loading && <OrderCellLoading />}

          {called && !loading && (
            <Tr>
              <Td
                w="100%"
                h="50vh"
                justifyContent="center"
                alignItems="center"
                colSpan={8}
                textAlign="center"
              >
                <EmptyTable icon={EmptyBox} title="No Orders Found" />
              </Td>
            </Tr>
          )}
        </>
      )}
      {orders.slice(0, displayedRows)?.map((order: Order, index) => (
        <CellSelected order={order} key={order.id}>
          <OrderTableCell order={order} performSyncOrders={performSyncOrders} />
        </CellSelected>
      ))}
      {displayedRows <= orders.length && !loading && called && <OrderCellLoading rowCount={1} />}
      <CellModals />
    </Tbody>
  );
};

export default memo(NonMemorizedTableBody);
