import { Box } from '@swftbox/style-guide';
import { type FC } from 'react';
import AuthCode, { type AuthCodeProps } from 'react-auth-code-input';
import style from './style.module.scss';

interface SBOTPProps extends Omit<AuthCodeProps, 'onChange'> {
  onDone?: (result: string) => void | Promise<void>;
  onChange?: (res: string) => void;
}

const SBOTP: FC<SBOTPProps> = ({ onDone, onChange, ...props }) => {
  const handleOnChange = (res: string) => {
    if (res.length === 4 && onDone) {
      void onDone(res);
    }
    if (onChange) {
      onChange(res);
    }
  };

  return (
    <Box>
      <AuthCode
        onChange={handleOnChange}
        containerClassName={style.otp}
        inputClassName={style.otp_input}
        allowedCharacters="numeric"
        length={4}
        placeholder="-"
        {...props}
      />
    </Box>
  );
};

export default SBOTP;
