import { useEffect } from 'react';
import { GroupOrder, useItems, type Item } from 'src/components/Particles';
import { Box, Grid, GridItem } from '@swftbox/style-guide';
import { ItemsTable } from './ItemsTable';

interface OrderDetailsItemsProps {
  orderId?: string;
  groupOrder?: GroupOrder;
}

export const OrderDetailsItems = ({ orderId, groupOrder }: OrderDetailsItemsProps) => {
  const { Items, getItems, loading } = useItems();
  const {
    Items: groupOrderItems,
    getItems: getGroupOrderItems,
    loading: gettingGroupOrderItems,
  } = useItems();
  useEffect(() => {
    if (orderId) {
      getItems(orderId);
    }
    if (groupOrder?.id) {
      getGroupOrderItems(groupOrder.id);
    }
  }, [orderId, groupOrder]);

  return (
    <>
      <Box bg="gray.100" borderRadius="8px" p="3" minH="200px">
        <ItemsTable Items={Items} loading={loading} />
      </Box>
      {!!groupOrderItems?.length && (
        <Box bg="gray.100" borderRadius="8px" p="3" minH="200px" mt="4">
          <Grid
            color="gray.700"
            fontWeight="semibold"
            borderColor="gray.500"
            gap="3"
            position="sticky"
            top="0px"
            zIndex="100"
            _notFirst={{ mt: '2.5', pt: '2.5', borderTop: '3px solid white' }}
            gridTemplateColumns="repeat(1,1fr) 15px"
          >
            <GridItem colSpan={1}>{groupOrder?.isReverse ? 'Return' : 'Exchange'} Items</GridItem>
          </Grid>
          <ItemsTable Items={groupOrderItems as Item[]} loading={gettingGroupOrderItems} />
        </Box>
      )}
    </>
  );
};
