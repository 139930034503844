import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  HStack,
  IconWText,
  MenuItem,
  SBAddIcon,
  SBEditIcon,
  SBInput,
  SBModal,
  SBTextArea,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import {
  useRetailersQuery,
  useUsersListQuery,
  useTaskCategoriesQuery,
  useCreateTaskMutation,
  type CreateTaskInput,
  type ITask,
  useUpdateTaskInfoMutation,
} from 'src/components/Particles';
import { CreateUpdateTaskSchema } from '../../Validation';
import { useEffect } from 'react';

interface AddUpdateTaskProps {
  task?: ITask;
}

export function AddUpdateTask({ task }: AddUpdateTaskProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createTask } = useCreateTaskMutation();
  const { updateTaskInfo } = useUpdateTaskInfoMutation();

  const { retailers, getRetailers } = useRetailersQuery();
  const { users, getUsers } = useUsersListQuery();
  const { taskCategories } = useTaskCategoriesQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<CreateTaskInput>({
    resolver: yupResolver(CreateUpdateTaskSchema),
    defaultValues: {
      retailerId: task?.retailerId,
      assigneeId: task?.assigneeId,
      taskCategoryId: task?.taskCategoryId,
      unitCount: task?.unitCount,
      dueDate: task?.dueDate,
      description: task?.description,
      status: task?.status,
    },
  });

  useEffect(() => {
    getRetailers({ page: 1, perPage: 200 });
    getUsers(['Dispatcher']);
  }, []);

  async function onConfirm(data: CreateTaskInput) {
    if (task) {
      void updateTaskInfo({
        payload: {
          ...data,
          id: task.id,
        },
        onCompleted: ({ updateTask }) => {
          if (updateTask.message) {
            toast.success(updateTask.message);
            handleClose();
          }
        },
      });
    } else {
      void createTask({
        payload: data,
        onCompleted: ({ createTask }) => {
          if (createTask.message) {
            toast.success(createTask.message);
            handleClose();
          }
        },
      });
    }
  }

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <>
      {task ? (
        <MenuItem onClick={onOpen}>
          <IconWText
            text="Update Task"
            spacing="20px"
            aria-label="update task"
            Icon={<SBEditIcon width="15px" />}
          />
        </MenuItem>
      ) : (
        <Button onClick={onOpen} fontSize="x-small">
          <SBAddIcon width="15px" /> Add Task
        </Button>
      )}
      <SBModal
        isOpen={isOpen}
        onClose={handleClose}
        closeOnEsc
        header={
          <Box pb="2vh">
            <Text>Create Task</Text>
          </Box>
        }
        size="xl"
        body={
          <form onSubmit={handleSubmit(onConfirm)}>
            <HStack pb="3%">
              <Controller
                name="retailerId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const retailerOptions = retailers?.map((retailer) => ({
                    label: retailer.name,
                    value: retailer.id,
                  }));
                  return (
                    <Autocomplete
                      label="Partner"
                      options={retailerOptions}
                      value={retailerOptions?.find((option) => option.value === value)}
                      placeholder="Select partner"
                      onChange={(val) => {
                        // @ts-expect-error, it's just a falsy warning
                        onChange(val?.value);
                      }}
                      error={errors.retailerId?.message}
                    />
                  );
                }}
              />
              <Controller
                name="assigneeId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const assigneeOptions = users?.map((user) => ({
                    label: user.name,
                    value: user.id,
                  }));
                  return (
                    <Autocomplete
                      label="Assignee"
                      options={assigneeOptions}
                      value={assigneeOptions?.find((option) => option.value === value)}
                      placeholder="Select assignee"
                      onChange={(val) => {
                        // @ts-expect-error, it's just a falsy warning
                        onChange(val?.value);
                      }}
                      error={errors.assigneeId?.message}
                    />
                  );
                }}
              />
            </HStack>
            <HStack pb="3%">
              <Controller
                name="taskCategoryId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const categoriesOptions = taskCategories?.map((taskCategory) => ({
                    label: `${taskCategory.name} (${taskCategory.unitType})`,
                    value: taskCategory.id,
                  }));
                  return (
                    <Autocomplete
                      label="Job Type"
                      placeholder="Select task category"
                      options={categoriesOptions}
                      value={categoriesOptions?.find((option) => option.value === value)}
                      onChange={(val) => {
                        // @ts-expect-error, it's just a falsy warning
                        onChange(val?.value);
                      }}
                      error={errors.taskCategoryId?.message}
                    />
                  );
                }}
              />
              <SBInput
                label="Unit Count"
                placeholder="ex. 25.8"
                {...register('unitCount')}
                error={errors.unitCount?.message}
              />
            </HStack>
            <HStack pb="3%">
              <SBInput
                label="Due Date"
                type="date"
                placeholder="choose due date"
                {...register('dueDate')}
                error={errors.dueDate?.message}
              />
            </HStack>
            <SBTextArea
              label="Description"
              placeholder="write detailed description for the task"
              {...register('description')}
              error={errors.description?.message}
            />
          </form>
        }
        bordered={false}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
