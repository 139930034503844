import { Button, SBInput } from '@swftbox/style-guide';
import { type ChangeEvent, type FormEvent, useState, useEffect } from 'react';

interface StepOneFormInterface {
  onSubmit: (identifier: string) => void;
  identifier?: string;
}

const StepOneForm = ({ onSubmit, identifier: sentIdentifier }: StepOneFormInterface) => {
  const [identifier, setIdentifier] = useState(sentIdentifier ?? '');

  function cleanupIdentifier(identifier: string): string {
    const cleanedString = identifier.replace(/\s/g, '');
    if (/^\d+$/.test(cleanedString)) {
      if (!cleanedString.startsWith('+')) {
        return `+${cleanedString}`;
      }
    }
    return cleanedString;
  }
  useEffect(() => {
    setIdentifier(cleanupIdentifier(identifier));
  }, [identifier]);

  function onIdentifierChange(event: ChangeEvent<HTMLInputElement>) {
    event.target.value = event.target.value.toLowerCase();
    setIdentifier(event.target.value);
  }

  function submit(event: FormEvent) {
    event.preventDefault();
    const payload = identifier.includes('@')
      ? identifier
      : identifier.includes('+')
      ? identifier
      : `+${identifier}`;
    onSubmit(payload);
  }

  return (
    <form onSubmit={submit}>
      <SBInput
        label="Enter your e-mail or phone number"
        onChange={onIdentifierChange}
        value={identifier}
        crossOrigin={undefined}
      />
      <Button w="100%" mt="16px" type="submit">
        Continue
      </Button>
    </form>
  );
};

export default StepOneForm;
