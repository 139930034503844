import * as yup from 'yup';

const PackageDetailsSchema = yup.object().shape({
  dimensions: yup.object().shape({
    depth: yup
      .number()
      .positive()
      .typeError('Depth must be a positive number')
      .required('Depth is required'),
    height: yup
      .number()
      .positive()
      .typeError('Height must be a number')
      .required('Height is required'),
    unit: yup.string().required().default('cm'),
    width: yup
      .number()
      .positive()
      .typeError('Width must be a number')
      .required('Width is required'),
  }),
  reference: yup.string().required().default('NA'),
  weight: yup
    .number()
    .positive()
    .typeError('Weight must be a number')
    .required('Weight is required'),
  weightUnit: yup
    .string()
    .typeError('Weight unit must be a string')
    .required('Weight unit is required'),
});

export const InternationalShipmentSchema = yup.object().shape({
  orderId: yup.string().required(),
  carrierId: yup.string().required('You must choose a provider!'),
  totalItemsPrice: yup
    .number()
    .positive()
    .typeError('Total must be a number')
    .required('Total is required'),
  description: yup.string().required('Description is required'),
  postCode: yup.string().optional(),
  packageDetails: yup.array().of(PackageDetailsSchema).required('Package details are required'),
});
