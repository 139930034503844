export const colors : colorScheme = {
  ENABLED: 'green',
  DISABLED: 'red',
  OFFBOARDED: 'yellow',
  PENDING: 'yellow',
  ACTIVE: 'green',
};

export interface colorScheme {
    [key: string]: string;
};

export const actionsColors : colorScheme = {
  ENABLED: 'green.normal',
  DISABLED: 'red.normal',
  OFFBOARDED: 'orange.normal',
  PENDING: 'orange.normal',
  ACTIVE: 'green.normal',
};
