import { type ReactNode, useCallback, useState } from 'react';
import { SearchContext } from '../Contexts';

export function SearchProvider({ children }: { children: ReactNode }) {
  const [searchText, setSearchText] = useState<string>();

  const search = useCallback((text?: string) => {
    setSearchText(text);
  }, []);

  return <SearchContext.Provider value={{ search, searchText }}>{children}</SearchContext.Provider>;
}
