import { useQuery } from '@apollo/client';
import { GET_CONFIGURATIONS_QUERY, type ConfigurationType } from '../Queries';

interface ConfigurationsResponse {
  configurations: ConfigurationType[];
}

export function useConfigurations() {
  const { data, loading } = useQuery<ConfigurationsResponse>(GET_CONFIGURATIONS_QUERY);

  return {
    configurations: data?.configurations || [],
    loading,
  };
}
