export enum ActionsType {
  DELIVERY = 'DELIVERY',
  RECEIVE = 'RECEIVE',
  RTO = 'RTO',
  RTO_COMPLETE = 'RTO_COMPLETE',
  CANCEL = 'CANCEL',
  BACK_TO_REQUESTED = 'BACK_TO_REQUESTED',
}

export const actionsType = [
  {
    value: ActionsType.DELIVERY,
    label: 'Force Delivery',
  },
  {
    value: ActionsType.RTO_COMPLETE,
    label: 'Force RTO Complete',
  },
  {
    value: ActionsType.RECEIVE,
    label: 'Force Receive',
  },
  {
    value: ActionsType.BACK_TO_REQUESTED,
    label: 'Force Back To Requested',
  },
  {
    value: ActionsType.RTO,
    label: 'Force RTO (Not Available Now)',
  },
  {
    value: ActionsType.CANCEL,
    label: 'Force Cancel (Not Available Now)',
  },
];
