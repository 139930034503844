import { Box, Tr } from '@swftbox/style-guide';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Order, useFulfillmentOrderSelection } from 'src/components/Particles';
interface CellSelectedProps {
  children: ReactNode;
  order: Order;
}
const CellSelected = ({ children, order }: CellSelectedProps) => {
  const { selectedOrders } = useFulfillmentOrderSelection();
  return (
    <Tr
      className={clsx({ 'cell-select': selectedOrders.has(order.id) })}
      border="1px solid transparent"
    >
      {children}
    </Tr>
  );
};

export default CellSelected;
