import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useDriversQuery, useSearch } from '../../Particles';
import { DriversFilter, DriversHeader, DriversTable } from './Components';
import style from './style.module.scss';

const driversInitialVariables = {
  page: 1,
};

export function DriversPage() {
  const [page, setPage] = useState(1);
  const [userStatus, setUserStatus] = useState<string[] | undefined>(["ACTIVE", "PENDING"]);
  const [employmentType, setEmploymentType] = useState<string[]>();
  const { searchText } = useSearch();
  const { getDrivers, drivers, loading, totalDrivers } = useDriversQuery(driversInitialVariables);

  useEffect(() => {
    getDrivers({ page, userStatus, employmentType, text: searchText });
    return () => { };
  }, [page, userStatus, employmentType, searchText, getDrivers]);

  function onPageChange(selectedItem: number) {
    setPage(selectedItem);
  }
  return (
    <Box className={style.drivers}>
      <DriversHeader driversCount={totalDrivers} />
      <DriversFilter
        setUserStatus={setUserStatus}
        setEmploymentType={setEmploymentType}
        employmentType={employmentType}
        userStatus={userStatus}
      />
      <WithLoader isLoading={loading && !drivers?.length}>
        <DriversTable
          drivers={drivers}
          page={page}
          total={totalDrivers}
          onPageChange={onPageChange}
        />
        {!loading && !drivers?.length && (
          <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
            <EmptyTable title="No Drivers Found" />
          </Flex>
        )}
      </WithLoader>
    </Box>
  );
}
