import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../SharedTypes';
import { UPDATE_USER } from './users.graphql';
import { type UpdateUserInput, type User } from './users.types';

interface UpdateUserProps {
  payload: UpdateUserInput;
  onCompleted: (data: { updateUser: User }, clientOptions?: clientOptionType) => void;
}

export function useUpdateUser() {
  const [mutate, { loading, error }] = useMutation<
    { updateUser: User },
    { updateUserInput: UpdateUserInput }
  >(UPDATE_USER);

  async function updateUser({ payload, onCompleted }: UpdateUserProps) {
    await mutate({
      variables: {
        updateUserInput: payload,
      },
      onCompleted,
      refetchQueries: [
        'GetUsers',
      ],
    });
  }

  return {
    updateUser,
    loading,
    error,
  };
}
