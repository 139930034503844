import {
  Box,
  GridItem,
  HStack,
  IconButton,
  SBShoppingBag02Icon,
  SBTrashIcon,
  Text,
  Tooltip,
} from '@swftbox/style-guide';
import {
  CreateJobListItemType,
  type SimpleOrder,
  useJobItemsSelectionContext,
  getAddress,
} from 'src/components/Particles';

interface OrderItemProps {
  order: SimpleOrder;
}

export const SelectedOrderItem = (props: OrderItemProps) => {
  const { order } = props;
  const { toggleSelection } = useJobItemsSelectionContext();

  return (
    <GridItem>
      <HStack
        color="gray.800"
        justifyContent="space-between"
        p="12px 20px"
        border="1px solid "
        bg="#fff"
        borderColor={'gray.200'}
        transition="all 0.4s "
        borderRadius="12px"
        h="100%"
      >
        <HStack>
          <Box
            bg="green.100"
            color="green.600"
            borderRadius="50%"
            border="5px solid"
            borderColor="green.50"
            p="4px"
          >
            <SBShoppingBag02Icon
              width="18px"
              height="18px"
              stroke="currentColor"
              fill="transparent"
            />
          </Box>
          <Box>
            <Text fontSize="x-small" color="gray.600">
              <Tooltip hasArrow label="Reference">
                {order.reference}
              </Tooltip>
            </Text>
            <Text fontSize="x-small" color="gray.600">
              <Tooltip hasArrow label="Swftbox Tracking">
                {order.swftboxTracking}
              </Tooltip>
            </Text>
            <Text fontSize="x-small" color="gray.600">
              <Tooltip hasArrow label="Partner company name">
                {order.retailer.name}
              </Tooltip>
            </Text>
            <Text fontSize="x-small" color="gray.600">
              <Tooltip hasArrow label="Dispatch Zone">
                Dubai | Marina/JLT
              </Tooltip>
            </Text>
            <Tooltip
              hasArrow
              label={`Destination Address: ${getAddress(order.to)}`}
              overflow="hidden"
            >
              <Text fontSize="x-small" color="gray.600" noOfLines={2}>
                {getAddress(order.to)}
              </Text>
            </Tooltip>
          </Box>
        </HStack>
        <IconButton
          size="small"
          variant="link"
          _hover={{
            opacity: '0.5',
          }}
          aria-label="delete user"
          color="red.normal"
          onClick={() => {
            toggleSelection(CreateJobListItemType.ORDER, order);
          }}
          icon={<SBTrashIcon width="18px" height="18px" />}
        />
      </HStack>
    </GridItem>
  );
};
