import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@swftbox/style-guide';
import ArrowDownIcon from 'src/assets/icons/Arrows/chervonDown.svg';

import React, { ReactNode, memo, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import { ErrorBoundary } from 'src/components/Particles';
interface Props {
  name: string | ReactNode;
  list: any[];
  onSelect: (item: any) => void;
  selectedFilter?: string | null;
}
export const SingleDropdownFilter = memo(({ name, list, onSelect, selectedFilter = '' }: Props) => {
  const [selectedValue, setSelectedValue] = useState(selectedFilter);
  const handleRemove = () => {
    setSelectedValue('');
    onSelect(null);
  };
  const handleSelect = (item: any) => {
    setSelectedValue(item.label);
    if (item.label === selectedValue) {
      handleRemove();
    } else {
      setSelectedValue(item.label);
      onSelect(item);
    }
  };
  useEffect(() => {
    if (selectedFilter !== null) {
      setSelectedValue(selectedFilter);
    }
  }, [selectedFilter]);
  return (
    <ErrorBoundary>
      <Box position="relative">
        {selectedValue && (
          <Button
            variant="link"
            color="#63C3EC"
            bg="#fff"
            position="absolute"
            minW="15px"
            h="15px"
            fontWeight="semibold"
            fontSize="9px"
            borderRadius="50%"
            top="13px"
            left="10px"
            zIndex={10}
            _hover={{ opacity: '0.8' }}
            onClick={() => {
              handleRemove();
            }}
          >
            <SVG src={XIcon} width="8px" stroke="currentColor" />
          </Button>
        )}
        <Menu>
          <MenuButton
            as={Button}
            whiteSpace="nowrap"
            transition="all 0.3s"
            h="40px"
            borderRadius="8px"
            boxShadow="xs"
            fontSize="x-small"
            px="4"
            border="1px solid"
            fontWeight="bold"
            bg={selectedValue ? '#C9E9F6 !important' : '#fff'}
            color={selectedValue ? '#fff' : 'gray.700'}
            borderColor={selectedValue ? '#63C3EC' : '#D0D5DD'}
            _hover={{ opacity: '0.7' }}
            _focus={{ opacity: '0.7' }}
            w="160px"
          >
            <HStack justifyContent="space-between">
              {!!selectedValue ? (
                <Text bg="#63C3EC" px="4" ms="4" borderRadius="8px" py="0.5">
                  {selectedValue}
                </Text>
              ) : (
                <>
                  {React.isValidElement(name) ? name : <Text>{name}</Text>}{' '}
                  <SVG src={ArrowDownIcon} fill="currentColor" stroke="transparent" />
                </>
              )}
            </HStack>
          </MenuButton>
          <MenuList zIndex="99999">
            {list?.map((item) => (
              <MenuItem onClick={() => handleSelect(item)} textTransform="capitalize">
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </ErrorBoundary>
  );
});
