import { useQuery } from '@apollo/client';
import { GET_CATEGORIES_QUERY, type Category } from '../Queries';

interface CategoriesResponse {
  categories: Category[];
}

export function useCategories() {
  const { data, loading, error } = useQuery<CategoriesResponse>(GET_CATEGORIES_QUERY);
  return {
    categories: data?.categories ?? [],
    loading,
    error,
  };
}
