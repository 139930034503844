import { type FC, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAuthContext, useSidebarExpansionContext } from 'src/components/Particles';
import logo from './../../../assets/swftboxBird.svg';
import primexLogo from 'src/assets/PRIMEX_ICON_GREENWHITE.svg';
import NavItem from './Components/NavItem';
import { SidebarNavigate } from './Helper';
import { Config } from 'src/config';
import style from './SBSidebar.module.scss';

export const SBSidebar: FC = () => {
  const { user } = useAuthContext();
  const [expandedRoute, setExpandedRoute] = useState<string>();
  const location = useLocation();
  const { setSidebarIsExpand } = useSidebarExpansionContext();

  useEffect(() => {
    const matchedNav = SidebarNavigate.find((nav) => !!matchPath(`${nav.to}/*`, location.pathname));
    if (matchedNav && !!matchedNav.children?.length) {
      setExpandedRoute(matchedNav.to);
      setSidebarIsExpand(true);
    } else {
      setExpandedRoute(undefined);
      setSidebarIsExpand(false);
    }
  }, [location, setSidebarIsExpand]);

  return (
    <aside className={`${style.sidebar} ${Config.PRIMEXPRESS_BUILD ? style.sidebar_primex : ''}`}>
      <div className={style.sidebar_logo}>
        {Config.PRIMEXPRESS_BUILD ? (
          <img src={primexLogo} alt="logo" width={'40px'} height={'40px'} />
        ) : (
          <img src={logo} alt="logo" />
        )}
      </div>
      <section className={style.sidebar_list}>
        {SidebarNavigate.map((route) => {
          if (route.roles?.length && !route.roles.some((role) => user?.roles?.includes(role))) {
            return null;
          }

          return (
            <NavItem
              key={route.id}
              route={route}
              user={user}
              sidebarIsExpand={route.to === expandedRoute}
            />
          );
        })}
      </section>
    </aside>
  );
};
