import { Box, SBModal, Button, HStack, Spacer, Text, useDisclosure } from '@swftbox/style-guide';
import { ReactNode } from 'react';
import { ViewAttachments } from './ViewAttachments';

interface OrderCheckboxProps {
  attachments: string[];
  attachmentsURLs: string[];
  button: ReactNode;
}

export function AttachmentsModal(props: OrderCheckboxProps) {
  const { attachments, attachmentsURLs, button } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box onClick={onOpen}>{button}</Box>
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        header={
          <Box pb="3" mt="5">
            <HStack>
              <Box>
                <Text>Attachments</Text>
                <Text fontWeight="regular" fontSize="x-small" color="gray.500">
                  View order attachments.
                </Text>
              </Box>
              <Spacer />
            </HStack>
          </Box>
        }
        size="2xl"
        body={
          <Box>
            <ViewAttachments attachmentsURLs={attachmentsURLs} onRemove={() => {
              console.log("remove not enabled");
            }} />
          </Box>
        }
        bordered={false}
        footer={
          attachments.length ? (
            <HStack w="100%">
              <Button
                w="20%"
                onClick={() => {
                  onClose();
                }}
              >
                Done
              </Button>
            </HStack>
          ) : (
            <></>
          )
        }
      />
    </>
  );
}
