import { type Dispatch, type SetStateAction, useEffect, useState, useMemo } from 'react';
import { PartnerItem } from 'src/components/Pages/Jobs/Components/AddNewJob/Components/ItemList/PartnerList/PartnerItem';
import {
  CreateJobListItemType,
  type SimpleRetailer,
  useSimpleRetailersQuery,
} from 'src/components/Particles';
import { ListItemWrapper } from '../ListItemWrapper';

interface PartnerListProps {
  page: number;
  stopFetching: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  searchText: string | undefined;
  revealAllLocations: boolean;
}

export const PartnerList = (props: PartnerListProps) => {
  const { page, stopFetching, setIsLoading, searchText, revealAllLocations } = props;
  const [retailers, setRetailers] = useState<SimpleRetailer[]>([]);

  const {
    getSimpleRetailers,
    retailers: fetchedRetailers,
    loading,
    pageCount,
    called,
  } = useSimpleRetailersQuery();

  useEffect(() => {
    getSimpleRetailers({ page, perPage: 20, keyword: searchText });
  }, [page, searchText, getSimpleRetailers]);

  useEffect(() => {
    if (!loading && called && page >= pageCount) {
      stopFetching();
    }
  }, [pageCount, page, loading, called, stopFetching]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  // Deep compare function for the fetchedRetailers array
  // to remove the infinite loop warning
  const isThereNewRetailers = (prevRetailers: SimpleRetailer[], newRetailers: SimpleRetailer[]) => {
    const prevRetailersMap = new Map<string, boolean>();

    prevRetailers.forEach((prevRetailer) => {
      prevRetailersMap.set(prevRetailer.id, true);
    });

    return newRetailers.some((newRetailer) => !prevRetailersMap.has(newRetailer.id));
  };

  useEffect(() => {
    if (!isThereNewRetailers(retailers, fetchedRetailers)) {
      return;
    }

    setRetailers((prevRetailers) => [...prevRetailers, ...fetchedRetailers]);
  }, [fetchedRetailers, retailers]);

  useEffect(() => {
    if (searchText) {
      setRetailers([]);
    }
  }, [searchText]);

  const uniqueRetailersLocations = useMemo(() => {
    const retailersLocations = retailers.flatMap((retailer) =>
      retailer.locations.flatMap((location) => {
        const totalPendingPickups =
          retailer.totalPendingPickups?.find((pickup) => pickup.locationId === location.id)
            ?.count ?? 0;

        if (totalPendingPickups > 0 || revealAllLocations) {
          return [
            {
              ...retailer,
              location,
              totalPendingPickups,
            },
          ];
        } else {
          return [];
        }
      })
    );

    const locationsMap = new Map();
    retailersLocations.forEach((retailerLocation) => {
      locationsMap.set(retailerLocation.location.id, retailerLocation);
    });

    return Array.from(locationsMap.values());
  }, [retailers, revealAllLocations]);

  return (
    <>
      {uniqueRetailersLocations.map((retailerLocation) => (
        <ListItemWrapper
          key={retailerLocation.location.id}
          type={CreateJobListItemType.RETAILER}
          item={retailerLocation}
          itemElement={<PartnerItem retailerLocation={retailerLocation} />}
        />
      ))}
    </>
  );
};
