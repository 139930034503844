import { useQuery } from '@apollo/client';
import { GET_TASK_CATEGORIES, type TaskCategory } from '../Queries';

interface TaskCategoriesResponse {
  taskCategories: TaskCategory[];
}

export function useTaskCategories() {
  const { data, loading, error } = useQuery<TaskCategoriesResponse>(GET_TASK_CATEGORIES);
  return {
    taskCategories: data?.taskCategories ?? [],
    loading,
    error,
  };
}
