import {
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  toast,
} from '@swftbox/style-guide';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import { useRegenerateReportMutation, type Report, ReportStatus, diffDates } from 'src/components/Particles';
import SVG from 'react-inlinesvg';
import FileDownloadIcon from 'src/assets/icons/Files/file-download-03.svg';
import { format } from 'date-fns';
import RepeatIcon from 'src/assets/icons/Media/repeat-04.svg';
import { StyledBadge } from 'src/components/Atom/StyledBadge';

interface ReportsTableProps {
  reports: Report[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
}

const StatusBackground: Record<ReportStatus, string> = {
  [ReportStatus.PENDING]: 'orange.500',
  [ReportStatus.FAILED]: 'red.500',
  [ReportStatus.READY]: 'green.500',
};

export function ReportsTable(props: ReportsTableProps) {
  const { reports, page, total, onPageChange } = props;
  const { regenerateReport, loading } = useRegenerateReportMutation();
  if (!reports.length) return null;

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead position="relative">
          <Tr bg="#fbfbfc">
            <Th>Generated by</Th>
            <Th>Retailer</Th>
            <Th>Driver</Th>
            <Th>Assignee</Th>
            <Th>Report type</Th>
            <Th>Status</Th>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Created at</Th>
            <Th right="0">
              <SmallPaginate
                totalOrders={total}
                page={page ?? 1}
                perPage={50}
                onPageChange={onPageChange}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reports.map((report) => {
            return (
              <Tr key={report.id}>
                <Td>
                  <Text maxW="100%">{report.user ? report.user.name : '-'}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{report.retailer ? report.retailer.name : '-'}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{report.driver ? report.driver.user.name : '-'}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{report.assignee ? report.assignee.name : '-'}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{report.type?.replace('_', ' ')}</Text>
                </Td>
                <Td>
                  <StyledBadge color="white" background={StatusBackground[report.status]}>
                    {report.status}
                  </StyledBadge>
                </Td>
                <Td>
                  <Text maxW="100%">
                    {report.fromDate ? format(new Date(report.fromDate), 'yyyy-MM-dd') : '-'}
                  </Text>
                </Td>
                <Td>
                  <Text maxW="100%">
                    {report.toDate ? format(new Date(report.toDate), 'yyyy-MM-dd') : '-'}
                  </Text>
                </Td>
                <Td>
                  <Text maxW="100%">{format(new Date(report.createdAt), 'yyyy-MM-dd HH:mm')}</Text>
                </Td>
                <Td alignItems="end">
                  <Flex gap={4} justifyContent="center" w="100%">
                    {report.status === ReportStatus.READY && (
                      <Tooltip label="Download" hasArrow>
                        <Link href={report.downloadUrl} isExternal>
                          <SVG src={FileDownloadIcon} />
                        </Link>
                      </Tooltip>
                    )}
                    {(report.status !== ReportStatus.PENDING || diffDates(report.updatedAt, new Date(), 'min', true) > 1) && (
                      <Tooltip label="Regenerate" isDisabled={loading} hasArrow>
                        <Text
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            !loading && regenerateReport(report.id).then(({ data }) => {
                              if (data?.regenerateReport.success)
                                toast.success(data.regenerateReport.message);
                            });
                          }}
                        >
                          <SVG src={RepeatIcon} />
                        </Text>
                      </Tooltip>
                    )}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
