import {
  Box,
  Button,
  HStack,
  SBModal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  Image,
  Divider,
} from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import InstructionIcon from 'src/assets/icons/Instructions.svg';
import type { ProcedureType, Procedure } from 'src/components/Particles';
import { MediaContainer } from '../MediaContainer';

interface ManipulateSOPsProps {
  SOPTypes: ProcedureType[];
  procedures: Procedure[];
}

export function RevealSOPs({ SOPTypes, procedures }: ManipulateSOPsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [retailerProcedures, setRetailerProcedures] = useState<Procedure[]>([]);

  useEffect(() => {
    if (!retailerProcedures.length && procedures.length) {
      setRetailerProcedures(procedures);
    }
  }, [procedures, retailerProcedures]);

  return (
    <>
      <Box bg="#EFEFEF" p="1" borderRadius="4" w="max-content" h="25px">
        <Tooltip
          label={`${SOPTypes.map((type) => type.toLowerCase()).join('/')} SOPs`}
          textTransform={'capitalize'}
          hasArrow
        >
          <Button variant="link" onClick={onOpen} color="primary.900" minW="30px">
            <SVG src={InstructionIcon} color=" #FF8EAC" stroke="#000" width="13px" height="13px" />
          </Button>
        </Tooltip>
      </Box>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Box pb="1vh"></Box>}
        size="xl"
        body={SOPTypes.map((sopType, index) => {
          const SOPDetails = retailerProcedures.find((procedure) => procedure.type === sopType);
          return (
            <Stack gap={1} key={index}>
              {index !== 0 && <Divider borderColor="gray.300" mt="4" />}
              <Text
                color="gray.600"
                fontSize="text-sm"
                fontWeight="semibold"
                textTransform={'capitalize'}
              >
                {`${sopType.toLowerCase()} SOPs`}
              </Text>
              {SOPDetails ? (
                <>
                  <Text color="gray.700" fontSize="text-md" fontWeight="semibold">
                    {SOPDetails?.description}
                  </Text>
                  <HStack>
                    {SOPDetails?.images?.map((image, index) => (
                      <MediaContainer
                        key={`${image}+${index}`}
                        mediaHref={image}
                        mediaType="image"
                        mediaDescription={`image #${index + 1}`}
                        openButton={
                          <Image
                            boxSize={'100px'}
                            objectFit={'contain'}
                            src={image}
                            alt={`image #${index + 1}`}
                          />
                        }
                      />
                    ))}
                    {SOPDetails?.videos?.map((video, index) => (
                      <MediaContainer
                        key={`${video}+${index}`}
                        mediaHref={video}
                        mediaType="video"
                        mediaDescription={`video #${index + 1}`}
                        openButton={
                          <Box>
                            <video width="100" height="100">
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                        }
                      />
                    ))}
                  </HStack>
                </>
              ) : (
                <Text color="gray.500" fontSize="text-sm" fontWeight="medium">
                  {`No instructions found for ${sopType.toLowerCase()} SOPs.`}
                </Text>
              )}
            </Stack>
          );
        })}
        footer={<></>}
        bordered={false}
      />
    </>
  );
}
