import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../SharedTypes';
import { CANCEL_TASK, type GeneralMessageResponse, GET_PAGINATED_TASKS } from '../Queries';
import { QueriesStore } from '../../queriesStore';

interface CancelTaskProps {
  payload: {
    cancelTaskId: string;
  };
  onCompleted: (
    data: { cancelTask: GeneralMessageResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useCancelTask() {
  const [mutate, { loading, error }] = useMutation<
    { cancelTask: GeneralMessageResponse },
    { cancelTaskId: string }
  >(CANCEL_TASK);

  async function cancelTask({ payload, onCompleted }: CancelTaskProps) {
    const { cancelTaskId } = payload;
    await mutate({
      variables: { cancelTaskId },
      onCompleted,
      refetchQueries: [
        {
          query: GET_PAGINATED_TASKS,
          variables: QueriesStore.get('GET_TASKS'),
        },
      ],
    });
  }

  return {
    cancelTask,
    loading,
    error,
  };
}
