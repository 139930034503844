import { useMutation } from '@apollo/client';
import { QueriesStore } from "src/components/Particles/resolvers/queriesStore";
import { CREATE_USER, GET_USERS } from './users.graphql';
import { type User } from './users.types';

export interface CreateUserPayload {
	name: string;
	phone: string;
	email?: string;
	roles?: string[];
}


export function useCreateUserMutation() {
	const [mutate, { data, loading, error, reset }] = useMutation<{ createUser: User },
		{ createUserInput: CreateUserPayload }>
	(CREATE_USER);

	async function createUser(payload: CreateUserPayload) {
		return mutate({
			variables: {
				createUserInput: payload,
			},
			refetchQueries: [
				'GetUsers',
			]
		});
	}

	return {
		createUser,
		reset,
		user: data?.createUser,
		loading,
		error,
	};
}
