import { FC, useState } from 'react';
import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
} from '@swftbox/style-guide';
import { paymentColumns } from '../columns';
import PaymentActionButtons from './PaymentActionButtons';
import { PaymentForm } from './PaymentForm';
import { Payment, fixPrecision } from 'src/components/Particles';
import { format } from 'date-fns';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
// import { payments as sampleData } from '../data';

interface Props {
  payments: Payment[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
}

export const PaymentsTable: FC<Props> = ({ payments, page, total, onPageChange }) => {
  const [selectedPayment, setSelectedPayment] = useState<Payment>();

  return (
    <>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              {paymentColumns.map((column) => (
                <Th key={column.field}>
                  <Text>{column.label}</Text>
                  <Text>{column.subLabel}</Text>
                </Th>
              ))}
              <Th right="0" h="100%">
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={50}
                  onPageChange={onPageChange}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {payments.map((payment) => (
              <Tr key={payment.id}>
                <Td>
                  <Text>{payment.orderSwftboxTracking}</Text>
                  <Text>{payment.order?.reference}</Text>
                </Td>
                <Td>
                  <Text>{payment.driver?.user.name ? payment.type : 'Payment Link'}</Text>
                </Td>
                <Td>
                  <Text>{payment.retailer?.name}</Text>
                </Td>
                <Td>
                  <Text>{payment.driver?.user.name ?? payment.provider}</Text>
                </Td>
                <Td>
                  <Text>{`${fixPrecision(payment.amount)} ${payment.currency}`}</Text>
                </Td>
                <Td>
                  <Badge
                    p="2px 8px"
                    fontWeight="medium"
                    borderRadius="8px"
                    minWidth={'60%'}
                    mb="5px"
                    textAlign={'center'}
                    fontSize="x-small"
                    variant="solid"
                    colorScheme={payment.status === 'APPROVED' ? 'green' : 'orange'}
                  >
                    {payment.status}
                  </Badge>
                </Td>
                <Td>
                  <Text>{format(new Date(payment.createdAt), 'HH:mm\xa0\xa0MMM dd, yyyy')}</Text>
                </Td>
                <Td textAlign="end">
                  <PaymentActionButtons
                    onMenuOpen={() => {}}
                    payment={payment}
                    editPayment={setSelectedPayment.bind(null, payment)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {selectedPayment && (
        <PaymentForm
          payment={selectedPayment}
          onModalClose={setSelectedPayment.bind(null, undefined)}
        />
      )}
    </>
  );
};
