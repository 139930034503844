import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import { DELETE_CATEGORY_QUERY, GET_CATEGORIES_QUERY, type DeleteCategoryInput } from '../Queries';

interface DeleteCategoryResponse {
  success: boolean;
  message: string;
}
interface DeleteCategoryProps {
  payload: DeleteCategoryInput;
  onCompleted: (
    data: { removeCategory: DeleteCategoryResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useDeleteCategory() {
  const [mutate, { loading, error }] = useMutation<
    { removeCategory: DeleteCategoryResponse },
    { removeCategoryId: string }
  >(DELETE_CATEGORY_QUERY, {});

  async function callDeleteCategory({ payload, onCompleted }: DeleteCategoryProps) {
    const { id } = payload;
    await mutate({
      variables: {
        removeCategoryId: id,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_CATEGORIES_QUERY,
        },
      ],
    });
  }

  return {
    callDeleteCategory,
    loading,
    error,
  };
}
