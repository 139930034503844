import { Box } from '@swftbox/style-guide';
import { FeaturesList } from './Components';
import { useEffect } from 'react';
import { useFeaturesQuery } from 'src/components/Particles';

export const Features = () => {
  const { features, getFeatures } = useFeaturesQuery();

  useEffect(() => {
    getFeatures();
  }, []);

  return (
    <>
      <Box pb="8">
        <FeaturesList features={features} />
      </Box>
    </>
  );
};
