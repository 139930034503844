import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
} from '@swftbox/style-guide';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigationTabs } from 'src/components/Organisms';
import { Feature, Retailer, useFeaturesQuery, useRetailerQuery } from 'src/components/Particles';
import {
  AccountConfiguration,
  LegalAndBillingDetails,
  OrderFeatureAndFees,
  PickupAndDrop,
} from './Components';
import {
  AccountConfigSubtabsEnum,
  AllSubtabsEnum,
  MainTabsEnum,
  containsMainTab,
  mainTabs,
} from './Helper';
import { ConfirmPageLeave } from './Components/Shared/Modals';
interface Props {
  retailerId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const PartnerSettingDrawer = ({ isOpen, onClose, retailerId }: Props) => {
  const { getRetailer, retailer, loading } = useRetailerQuery();
  const { getFeatures, features: globalFeatures } = useFeaturesQuery();
  const [allowToMove, setAllowToMove] = useState({ page: '', isAllowed: true });
  const [activeTab, setActiveTab] = useState<MainTabsEnum>(MainTabsEnum.AccountConfig);
  const [activeSubTab, setActiveSubTab] = useState<AllSubtabsEnum>(
    AccountConfigSubtabsEnum.AccountSettings
  );
  const categorizedFeatures = useMemo(
    () =>
      globalFeatures?.reduce(
        (acc: Record<string, Feature[]>, feature) => {
          if (feature.category === 'orderEnhancements') {
            acc.orderEnhancements.push(feature);
          } else if (feature.category === 'accountSettings') {
            acc.accountSettings.push(feature);
          }
          return acc;
        },
        { orderEnhancements: [], accountSettings: [] }
      ),
    [globalFeatures]
  );

  useEffect(() => {
    if (retailerId) {
      getRetailer(retailerId).catch(console.log);
    }
  }, [getRetailer, retailerId]);

  useEffect(() => {
    void getFeatures();
  }, []);

  const handleChangeTab = useCallback(
    (tabId: string) => {
      if (!allowToMove?.isAllowed) {
        setAllowToMove({ isAllowed: false, page: tabId });
      } else {
        setActiveTab(tabId as MainTabsEnum);
      }
    },
    [allowToMove]
  );

  const handleClose = () => {
    setAllowToMove({ isAllowed: false, page: '' });
  };
  const handleCloseAndMove = () => {
    if (containsMainTab(allowToMove.page)) {
      setActiveTab(allowToMove.page as MainTabsEnum);
    } else {
      setActiveSubTab(allowToMove.page as AllSubtabsEnum);
    }
    setAllowToMove({ isAllowed: true, page: '' });
  };
  if (!retailer) null;
  return (
    <>
      <Drawer isOpen={isOpen && !!retailer?.id} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader pb="0" pt="1" mb="8">
            <NavigationTabs
              tabs={mainTabs}
              handleChange={handleChangeTab}
              active={activeTab}
              parentStyle={{ marginBottom: '4px' }}
              variant="solid"
              title={retailer?.name}
            />
          </DrawerHeader>

          <DrawerBody py="0" position="relative">
            {activeTab === MainTabsEnum.AccountConfig && (
              <AccountConfiguration
                retailer={retailer as Retailer}
                allowToMove={allowToMove}
                setAllowToMove={setAllowToMove}
                activeTab={activeSubTab}
                setActiveTab={setActiveSubTab}
                categorizedFeatures={categorizedFeatures}
              />
            )}
            {activeTab === MainTabsEnum.PickupAndDrop && (
              <PickupAndDrop
                retailer={retailer as Retailer}
                allowToMove={allowToMove}
                setAllowToMove={setAllowToMove}
              />
            )}
            {activeTab === MainTabsEnum.OrderFeatureAndFees && (
              <OrderFeatureAndFees
                retailer={retailer as Retailer}
                allowToMove={allowToMove}
                setAllowToMove={setAllowToMove}
                activeTab={activeSubTab}
                setActiveTab={setActiveSubTab}
                categorizedFeatures={categorizedFeatures}
              />
            )}
            {activeTab === MainTabsEnum.LegalAndBilling && (
              <LegalAndBillingDetails
                retailer={retailer as Retailer}
                activeTab={activeSubTab}
                setActiveTab={setActiveSubTab}
                allowToMove={allowToMove}
                setAllowToMove={setAllowToMove}
              />
            )}
          </DrawerBody>
        </DrawerContent>
        <ConfirmPageLeave
          isOpen={!!allowToMove?.page}
          onClose={handleClose}
          handleDiscardAndMove={handleCloseAndMove}
        />
      </Drawer>
    </>
  );
};
