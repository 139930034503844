import { Grid, Text } from '@swftbox/style-guide';
import { type FunctionComponent } from 'react';

interface FormHeaderProps {
  title: string;
  description: string;
}

export const FormHeader: FunctionComponent<FormHeaderProps> = ({ title, description }) => {
  return (
    <Grid textAlign="center" mb={3}>
      <Text color="gray.900" fontSize="display-sm" fontWeight="semibold" mt={8}>
        {title}
      </Text>
      <Text color="gray.600">{description}</Text>
    </Grid>
  );
};
