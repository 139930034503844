import { Tooltip } from '@swftbox/style-guide';
import clsx from 'clsx';
import { NavLink, type NavLinkProps } from 'react-router-dom';
import { type AuthUser } from 'src/components/Particles';
import { type SidebarNavigateProps } from '../Helper';
import { Config } from 'src/config';
import style from './../SBSidebar.module.scss';

interface Route extends SidebarNavigateProps, Omit<NavLinkProps, 'children' | 'id'> {
  to: string;
}

interface NavItemProps {
  route: Route;
  sidebarIsExpand?: boolean;
  user: AuthUser | undefined;
}

const NavItem = ({ route, sidebarIsExpand, user }: NavItemProps) => {
  const { children, id, icon, name, to, ...props } = route;

  return (
    <div
      className={`${style['sidebar_list-item']} ${
        Config.PRIMEXPRESS_BUILD ? style['sidebar_primex_list-item'] : ''
      }`}
    >
      <Tooltip hasArrow label={name}>
        <NavLink
          className={({ isActive }) =>
            clsx({
              [style['sidebar_list-link']]: true,
              [style.active]: isActive,
            })
          }
          to={to}
          {...props}
        >
          {icon}
        </NavLink>
      </Tooltip>
      {sidebarIsExpand && children !== null && (
        <section
          className={style['sidebar_list-submenu']}
          style={{ width: sidebarIsExpand ? '260px' : '0', opacity: sidebarIsExpand ? 1 : 0 }}
        >
          <h2>{name}</h2>
          {children?.map(({ id, name: childName, icon: childIcon, roles, ...rest }) => {
            if (roles?.length && !roles.some((role) => user?.roles?.includes(role))) {
              return null;
            }

            return (
              <div key={id} className={style['sidebar_list-submenu--item']}>
                <NavLink
                  className={({ isActive }) =>
                    clsx({
                      [style.active]: isActive,
                      [style['sidebar_list-submenu--link']]: true,
                    })
                  }
                  {...rest}
                >
                  <span>{childName}</span>
                </NavLink>
              </div>
            );
          })}
        </section>
      )}
    </div>
  );
};

export default NavItem;
