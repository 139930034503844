import {
  Box,
  Button,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
  IconWText,
  SBTrashIcon,
  IconButton,
} from '@swftbox/style-guide';
import { useDeleteQuestion } from 'src/components/Particles';
// import { useDeleteTask } from 'src/components/Particles/resolvers';

interface DeleteTaskProps {
  questionId: string;
}

export function DeleteQuestion({ questionId }: DeleteTaskProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, loading } = useDeleteQuestion({
    onCompleted: ({ removeFeedbackQuestion }) => {
      if (removeFeedbackQuestion.message) {
        toast.success(removeFeedbackQuestion.message);
        onClose();
      }
    },
  });

  function onDeleteConfirm() {
    mutate({
      variables: {
        removeFeedbackQuestionId: questionId,
      },
    });
  }

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'red' }}
        aria-label="delete question"
        icon={<SBTrashIcon width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Question</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to delete this question!
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" onClick={onDeleteConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
