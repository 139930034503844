import { Avatar, HStack, IconButton, SBTrashIcon, Text, VStack } from '@swftbox/style-guide';
import {
  CreateJobListItemType,
  type SimpleRetailer,
  useJobItemsSelectionContext,
  type Address,
} from 'src/components/Particles';

interface OrderItemProps {
  retailer: SimpleRetailer & { location: Address };
}

export const SelectedPartnerItem = (props: OrderItemProps) => {
  const { retailer } = props;
  const { toggleSelection } = useJobItemsSelectionContext();

  return (
    <HStack
      spacing={4}
      color="gray.800"
      justifyContent="space-between"
      p="12px 20px"
      border="1px solid "
      bg="#fff"
      borderColor={'gray.200'}
      transition="all 0.4s "
      borderRadius="12px"
    >
      <Avatar src={retailer.logo} name={retailer.name} size="sm" />
      <VStack alignItems="start">
        <Text fontSize="text-md" fontWeight="semibold" color="gray.600" overflowX="clip">
          {retailer.name}
        </Text>

        <Text fontSize="text-sm" fontWeight="semibold" color="gray.400" overflowX="clip">
          {retailer.location.name}
        </Text>
      </VStack>
      <IconButton
        size="small"
        variant="link"
        _hover={{
          opacity: '0.5',
        }}
        aria-label="delete user"
        color="red.normal"
        onClick={() => {
          toggleSelection(CreateJobListItemType.RETAILER, retailer);
        }}
        icon={<SBTrashIcon width="18px" height="18px" />}
      />
    </HStack>
  );
};
