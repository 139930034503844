import { useMutation } from '@apollo/client';
import {
  type RemoveInvoiceSettingResponse,
  LIST_INVOICE_SETTINGS,
  REMOVE_INVOICE_SETTING,
} from '../Queries';
import { clientOptionType } from '../../../SharedTypes';

interface RemoveInvoiceSettingProps {
  onCompleted: (
    data: { removeInvoiceSetting: RemoveInvoiceSettingResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useRemoveInvoiceSetting({ onCompleted }: RemoveInvoiceSettingProps) {
  const [mutate, { error, loading }] = useMutation<
    { removeInvoiceSetting: RemoveInvoiceSettingResponse },
    { removeInvoiceSettingInput: { id: string } }
  >(REMOVE_INVOICE_SETTING, {
    onCompleted,
    refetchQueries: [{ query: LIST_INVOICE_SETTINGS }],
  });

  return {
    mutate,
    loading,
    error,
  };
}
