import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import { AddTaskCategory } from './Modals';

interface TaskCategoriesHeaderProps {
  taskCategoriesCount: number;
}
export default function TaskCategoriesHeader(props: TaskCategoriesHeaderProps) {
  const { taskCategoriesCount } = props;
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Task Categories
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {taskCategoriesCount <= 1
              ? `${taskCategoriesCount} Task Category`
              : `${taskCategoriesCount} Task Categories`}
          </Badge>
        </Text>
      </Box>
      <Spacer />
      <AddTaskCategory />
    </Flex>
  );
}
