import { Helmet } from 'react-helmet';
import {
  ErrorBoundary,
  FulfillmentOrdersFiltersProvider,
  FulfillmentOrdersSelectionProvider,
  OrderModalProvider,
} from 'src/components/Particles';
import { Fulfillment } from './fulfillment.page';

export function FulfillmentPageWrapper() {
  return (
    <>
      <Helmet>
        <title> Fulfillment </title>
      </Helmet>
      <ErrorBoundary>
        <FulfillmentOrdersSelectionProvider>
          <FulfillmentOrdersFiltersProvider>
            <OrderModalProvider>
              <Fulfillment />
            </OrderModalProvider>
          </FulfillmentOrdersFiltersProvider>
        </FulfillmentOrdersSelectionProvider>
      </ErrorBoundary>
    </>
  );
}
