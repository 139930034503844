import { Helmet } from 'react-helmet';
import { ErrorBoundary, JobFiltersProvider } from 'src/components/Particles';
import { JobsPage } from './jobs.page';

export function JobsPageWrapper() {
  return (
    <>
      <Helmet>
        <title> Jobs </title>
      </Helmet>
      <ErrorBoundary>
        <JobFiltersProvider>
          <JobsPage />
        </JobFiltersProvider>
      </ErrorBoundary>
    </>
  );
}
