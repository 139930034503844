import {
  Box,
  Flex,
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBCrossCircleIcon,
  SBMenuDotsVerticalIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  toast,
  Tooltip,
  Tr,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { useState } from 'react';
import SVG from 'react-inlinesvg';
import ResetIcon from 'src/assets/icons/Arrows/refresh.svg';
import FileDownloadIcon from 'src/assets/icons/Files/file-download-03.svg';
import ExternalLinkIcon from 'src/assets/icons/General/link-external1-icon.svg';
import ShareIcon from 'src/assets/icons/General/share-06.svg';
import RepeatIcon from 'src/assets/icons/Media/repeat-04.svg';
import CreditCard from 'src/assets/icons/eCommerce/credit-card-01.svg';
import CreditCardCheck from 'src/assets/icons/eCommerce/credit-card-check.svg';
import WarningIcon from 'src/assets/icons/warning-exclamation.svg';
import { StyledBadge } from 'src/components/Atom/StyledBadge';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import {
  InvoiceStatus,
  InvoiceType,
  useRegenerateInvoiceMutation,
  useUpdateInvoiceStatusMutation,
  type Invoice,
} from 'src/components/Particles';
import { InvoiceModal } from '../Modals';
import { EditInvoiceModal } from '../Modals/EditInvoice.modal';
import { downloadHTMLAsPDF, fetchInvoiceData } from '../helper';

interface InvoicesTableProps {
  invoices: Invoice[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
}

const StatusBackground: Record<InvoiceStatus, string> = {
  [InvoiceStatus.DRAFT]: 'orange.300',
  [InvoiceStatus.ISSUED]: 'orange.500',
  [InvoiceStatus.OVERDUE]: 'red.300',
  [InvoiceStatus.PARTIALLY_PAID]: 'yellow.500',
  [InvoiceStatus.PAID]: 'green.500',
  [InvoiceStatus.CANCELLED]: 'red.500',
};

const closedStatus = [InvoiceStatus.CANCELLED, InvoiceStatus.PAID, InvoiceStatus.PARTIALLY_PAID];

export function InvoicesTable(props: InvoicesTableProps) {
  const { invoices, page, total, onPageChange } = props;
  if (!invoices.length) return null;

  const [downloading, setDownloading] = useState(false);

  const { mutate, loading } = useRegenerateInvoiceMutation({
    onCompleted: (data) => {
      if (data.regenerateInvoice.data.length) {
        toast.success('Invoice Regenerated Successfully');
      } else {
        toast.error('Invoice Regeneration Failed');
      }
    },
  });

  const { mutate: changeStatus, loading: changingStatus } = useUpdateInvoiceStatusMutation({
    onCompleted: (data) => {
      if (data.changeInvoiceStatus.data.length) {
        toast.success('Invoice Updated Successfully');
      } else {
        toast.error('Invoice Update Failed');
      }
    },
  });

  const downloadInvoicePdf = async (
    invoiceId: string,
    invoiceType: InvoiceType,
    filename: string
  ) => {
    setDownloading(true);
    const invoiceData = await fetchInvoiceData(invoiceId, invoiceType);
    if (invoiceData) {
      downloadHTMLAsPDF(invoiceData, filename || 'invoice.pdf');
    }
    setDownloading(false);
  };

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead position="relative">
          <Tr bg="#fbfbfc">
            <Th></Th>
            <Th>Invoice Number</Th>
            <Th>Retailer</Th>
            <Th>Date Created</Th>
            <Th>Issue Date</Th>
            <Th>From - To</Th>
            <Th>Due Date (days)</Th>
            <Th>Total</Th>
            <Th>Type</Th>
            <Th>Status</Th>
            <Th right="0">
              <SmallPaginate
                totalOrders={total}
                page={page ?? 1}
                perPage={50}
                onPageChange={onPageChange}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoices.map((invoice) => {
            return (
              <Tr whiteSpace={'nowrap'} key={invoice.id}>
                <Td>
                  {!invoice.retailer.billingInfo && (
                    <Tooltip
                      label={`It seems that ${invoice.retailer.name} is missing billing information.
                              Please add the necessary details in the partner settings to process this invoice.`}
                    >
                      <Box>
                        <SVG src={WarningIcon} width="15px" height="15px" fill={'#F6CA45'} />
                      </Box>
                    </Tooltip>
                  )}
                </Td>
                <Td>
                  <Text maxW={'100%'}>{invoice.invoiceNumber}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{invoice.retailer.name}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{format(new Date(invoice.createdAt), 'yyyy-MM-dd')}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{format(new Date(invoice.activeAt), 'yyyy-MM-dd')}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{`${format(new Date(invoice.from), 'yyyy-MM-dd')} - ${format(
                    new Date(invoice.to),
                    'yyyy-MM-dd'
                  )}`}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{invoice.dueDateInDays}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{invoice.netTotal.toFixed(2)}</Text>
                </Td>
                <Td>
                  <Text maxW="100%">{invoice.type}</Text>
                </Td>
                <Td>
                  <StyledBadge color="white" background={StatusBackground[invoice.status]}>
                    {invoice.status.split('_').join(' ')}
                  </StyledBadge>
                </Td>
                <Td alignItems="end">
                  <Flex gap={4} justifyContent="center" w="100%">
                    <Menu>
                      <MenuButton as="button" disabled={!invoice.retailer.billingInfo}>
                        <SBMenuDotsVerticalIcon width="15px" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem>
                          <InvoiceModal id={invoice.id} type={invoice.type} />
                        </MenuItem>
                        <MenuItem>
                          <IconWText
                            text="Open In New Tab"
                            spacing="20px"
                            Icon={<SVG src={ExternalLinkIcon} width="15px" />}
                            onClick={() => {
                              const encodedId = btoa(invoice.id);
                              const url = `/invoice/${encodedId}?file_name=${
                                invoice.invoiceNumber.split('-')[1]
                              }_${invoice.retailer.name}_${format(
                                new Date(invoice.from),
                                'MMMM dd'
                              )}-${format(new Date(invoice.to), 'MMMM dd_yyyy')}&type=${
                                invoice.type
                              }`;
                              window.open(url);
                            }}
                            aria-label="Open In New Tab"
                          />
                        </MenuItem>

                        <MenuItem>
                          <IconWText
                            text="Download Invoice"
                            spacing="20px"
                            Icon={<SVG src={FileDownloadIcon} width="17px" />}
                            aria-label="Download Invoice"
                            onClick={async () =>
                              downloadInvoicePdf(
                                invoice.id,
                                invoice.type,
                                `${invoice.invoiceNumber.split('-')[1]}_${
                                  invoice.retailer.name
                                }_${format(new Date(invoice.from), 'MMMM dd')}-${format(
                                  new Date(invoice.to),
                                  'MMMM dd_yyyy'
                                )}`
                              )
                            }
                            _disabled={downloading}
                          />
                        </MenuItem>
                        {invoice.status === InvoiceStatus.DRAFT && (
                          <>
                            <MenuItem>
                              <EditInvoiceModal id={invoice.id} />
                            </MenuItem>
                            <MenuItem>
                              <IconWText
                                text="Regenerate Invoice"
                                spacing="20px"
                                Icon={<SVG src={ResetIcon} width={'15px'} />}
                                onClick={() => {
                                  !loading &&
                                    mutate({ variables: { idInput: { id: invoice.id } } });
                                }}
                                aria-label="View Invoice"
                              />
                            </MenuItem>
                          </>
                        )}
                        {![InvoiceStatus.ISSUED, InvoiceStatus.OVERDUE, ...closedStatus].includes(
                          invoice.status
                        ) && (
                          <MenuItem>
                            <IconWText
                              text="Issue"
                              spacing="20px"
                              Icon={<SVG src={ShareIcon} width={'15px'} />}
                              onClick={() => {
                                !changingStatus &&
                                  changeStatus({
                                    variables: {
                                      changeInvoiceStatusInput: {
                                        id: invoice.id,
                                        status: InvoiceStatus.ISSUED,
                                      },
                                    },
                                  });
                              }}
                              aria-label="Issue Invoice"
                            />
                          </MenuItem>
                        )}
                        {invoice.status === InvoiceStatus.ISSUED && (
                          <MenuItem>
                            <IconWText
                              text="Mark as Paid"
                              spacing="20px"
                              Icon={<SVG src={CreditCardCheck} width={'15px'} />}
                              onClick={() => {
                                !changingStatus &&
                                  changeStatus({
                                    variables: {
                                      changeInvoiceStatusInput: {
                                        id: invoice.id,
                                        status: InvoiceStatus.PAID,
                                      },
                                    },
                                  });
                              }}
                              aria-label="Mark Paid"
                            />
                          </MenuItem>
                        )}
                        {invoice.status === InvoiceStatus.ISSUED && (
                          <MenuItem>
                            <IconWText
                              text="Mark as Partially Paid"
                              spacing="20px"
                              Icon={<SVG src={CreditCard} width={'15px'} />}
                              onClick={() => {
                                !changingStatus &&
                                  changeStatus({
                                    variables: {
                                      changeInvoiceStatusInput: {
                                        id: invoice.id,
                                        status: InvoiceStatus.PARTIALLY_PAID,
                                      },
                                    },
                                  });
                              }}
                              aria-label="Mark Partially Paid"
                            />
                          </MenuItem>
                        )}

                        {[
                          InvoiceStatus.ISSUED,
                          InvoiceStatus.DRAFT,
                          InvoiceStatus.OVERDUE,
                        ].includes(invoice.status) && (
                          <MenuItem>
                            <IconWText
                              text="Cancel"
                              spacing="20px"
                              Icon={<SBCrossCircleIcon width={'15px'} />}
                              onClick={() => {
                                !changingStatus &&
                                  changeStatus({
                                    variables: {
                                      changeInvoiceStatusInput: {
                                        id: invoice.id,
                                        status: InvoiceStatus.CANCELLED,
                                      },
                                    },
                                  });
                              }}
                              aria-label="Cancel invoice"
                            />
                          </MenuItem>
                        )}
                        {!closedStatus.includes(invoice.status) && (
                          <MenuItem>
                            <IconWText
                              text="Recall"
                              spacing="20px"
                              Icon={<SVG src={RepeatIcon} width={'15px'} />}
                              onClick={() => {
                                !changingStatus &&
                                  changeStatus({
                                    variables: {
                                      changeInvoiceStatusInput: {
                                        id: invoice.id,
                                        status: InvoiceStatus.DRAFT,
                                      },
                                    },
                                  });
                              }}
                              aria-label="Recall invoice"
                            />
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
