import {
  Autocomplete,
  Button,
  toast,
  HStack,
  VStack,
  If,
  FormLabel,
  Switch,
  SBInput,
  FormControl,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { RetailerSelectInput, UserSelectInput, DriverSelectInput } from 'src/components/Organisms';
import {
  type GenerateReportInput,
  ReportType,
  useAuthContext,
  useGenerateReportMutation,
} from 'src/components/Particles';

interface Props {
  onClose: () => void;
}

const reportTypeValues = Object.values(ReportType);

const ReportTypeOptions = reportTypeValues.map((value) => {
  const labelComponents = value.split('_');
  return {
    label: labelComponents.map((item) => item.charAt(0) + item.slice(1).toLowerCase()).join(' '),
    value,
  };
});

export function GenerateReportForm({ onClose }: Props) {
  const { user } = useAuthContext();

  const { register, handleSubmit, reset, control, watch } = useForm<GenerateReportInput>({
    mode: 'all',
    defaultValues: {
      type: ReportType.CONSOLIDATED,
      fromDate: null,
      toDate: null,
      options: {
        recepientEmail: user?.email,
      },
    },
  });

  const { generateReport, loading: creating } = useGenerateReportMutation();

  const onSubmit = handleSubmit(async (data) => {
    await generateReport({
      ...data,
      options: {
        isClosed: data.options?.isClosed,
        recepientEmail: data.options?.recepientEmail ?? undefined,
        firstMile: !!data.options?.firstMile,
        lastMile: !!data.options?.lastMile,
        jobless: !!data.options?.jobless,
      },
    });
    toast.success('Report is being generated');
    reset();
    onClose();
  });

  const type = watch('type');

  const showRetailerSelect = ![ReportType.USERS, ReportType.DRIVER_CASH].includes(type);
  const showDriverSelect = [
    ReportType.PAYMENTS,
    ReportType.SETTLEMENTS,
    ReportType.DRIVER_CASH,
    ReportType.MISSING_PAYMENTS,
    ReportType.CONSOLIDATED,
  ].includes(type);
  const showAssigneeSelect = [ReportType.TASKS].includes(type);

  return (
    <form onSubmit={onSubmit}>
      <br />
      <VStack justifyContent="start">
        <Controller
          name="type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              isMulti={false}
              label="Report type *"
              options={ReportTypeOptions}
              value={ReportTypeOptions.find((type) => type.value === value) ?? ''}
              onChange={(val) => {
                // @ts-expect-error, it's just a falsy warning
                onChange(val?.value);
              }}
            />
          )}
        />
        {showRetailerSelect && (
          <Controller
            name="retailerId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RetailerSelectInput value={value ?? null} onChange={onChange} />
            )}
          />
        )}

        {showDriverSelect && (
          <Controller
            name="driverId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DriverSelectInput value={value ?? null} onChange={onChange} />
            )}
          />
        )}

        {showAssigneeSelect && (
          <Controller
            name="assigneeId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <UserSelectInput value={value ?? null} onChange={onChange} />
            )}
          />
        )}
        <SBInput
          label="Recepient Email"
          placeholder="ex: john@example.com"
          {...register('options.recepientEmail')}
        />
        <HStack w="100%" justifyContent="start">
          <Controller
            name={`fromDate`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>From date</FormLabel>
                <SBInput
                  type="date"
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="From date"
                />
              </FormControl>
            )}
          />
          <Controller
            name={`toDate`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>To date</FormLabel>
                <SBInput
                  type="date"
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="To date"
                />
              </FormControl>
            )}
          />
        </HStack>
        <If
          condition={type === ReportType.CONSOLIDATED}
          children={
            <HStack
              spacing={8}
              justifyContent="start"
              alignSelf="start"
              sx={{ marginTop: '2rem !important' }}
            >
              <HStack spacing={2} justifyContent="start" alignItems="center">
                <FormLabel htmlFor="isClosed">Closed Orders</FormLabel>
                <Switch id="isClosed" {...register('options.isClosed')} />
              </HStack>
              <HStack spacing={2} justifyContent="start" alignItems="center">
                <FormLabel htmlFor="jobless">Jobless orders</FormLabel>
                <Switch id="jobless" {...register('options.jobless')} />
              </HStack>
              {/*
              <HStack spacing={2} justifyContent="start" alignItems="center">
                <FormLabel htmlFor="lastMile">Last mile</FormLabel>
                <Switch id="lastMile" {...register('options.lastMile')} />
              </HStack>
              <HStack spacing={2} justifyContent="start" alignItems="center">
                <FormLabel htmlFor="firstMile">First mile</FormLabel>
                <Switch id="firstMile" {...register('options.firstMile')} />
              </HStack>*/}
            </HStack>
          }
        />
        <HStack justifyContent="end" sx={{ marginTop: '20px !important' }}>
          <Button type="submit" disabled={creating}>
            Generate Report
          </Button>
        </HStack>
      </VStack>
    </form>
  );
}
