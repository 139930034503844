import { Box, Grid, GridItem } from '@swftbox/style-guide';
import { type Job, type SequenceStops } from 'src/components/Particles/resolvers/Jobs';

import { JobStops } from './JobStops';

interface JobMapAndStopsProps {
  job?: Job;
  handleOpenOrderDrawer: (item: SequenceStops) => void;
  onSortChange: (sequences?: number[]) => void;
}

export const JobMapAndStops = ({
  job,
  handleOpenOrderDrawer,
  onSortChange,
}: JobMapAndStopsProps) => {
  return (
    <>
      <Box bg="gray.100" borderRadius="8px" p="3" minW="fit-content">
        <Grid
          gridTemplateColumns="180px 200px 180px 280px 10px"
          gridAutoFlow="row"
          justifyContent="space-between"
          color="gray.700"
          fontWeight="semibold"
          borderBottom="1px solid"
          borderColor="gray.500"
          gap="3"
          py="3"
          mb="5"
          fontSize="text-sm"
        >
          <GridItem>Stop and action</GridItem>
          <GridItem>Remaining Stops</GridItem>
          <GridItem>Timing</GridItem>
          <GridItem>Address</GridItem>
          <GridItem></GridItem>
        </Grid>

        <JobStops
          job={job}
          stops={job?.sequenceStops}
          handleOpenOrderDrawer={handleOpenOrderDrawer}
          onSortChange={onSortChange}
        />
      </Box>
    </>
  );
};
