import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  SBTrashIcon,
  Td,
  Tooltip,
  Tr,
} from '@swftbox/style-guide';
import { memo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { type UpdateInvoiceLineItemInput } from 'src/components/Particles';
import styles from '../../style.module.scss';

interface TransactionItemProps {
  transactionLineItem: UpdateInvoiceLineItemInput;
  index: number;
  remove: UseFieldArrayRemove;
  onQuantityOrRateUpdated: () => void;
}
export const TransactionItem = memo(
  ({ transactionLineItem, index, remove, onQuantityOrRateUpdated }: TransactionItemProps) => {
    const { control, watch } = useFormContext();
    const [rate, vat, quantity] = watch([`transactionItems.${index}.rate`, `transactionItems.${index}.vat`, `transactionItems.${index}.quantity`]);
    return (
      <Tr borderTop={'none'}>
        <Td></Td>
        <Td>
          <Flex alignItems="center">
            {transactionLineItem.quantity >= 0 ? 'Add' : 'Less'}:{' '}
            <Controller
              name={`transactionItems.${index}.description`}
              control={control}
              defaultValue={transactionLineItem.description}
              render={({ field }) => (
                <Editable defaultValue={field.value} onSubmit={field.onChange} m={1}>
                  <EditablePreview className={styles.editableFieldPreview} />
                  <EditableInput className={styles.editableFieldInput} />
                </Editable>
              )}
            />
          </Flex>
        </Td>
        <Td>
          <Controller
            name={`transactionItems.${index}.quantity`}
            control={control}
            defaultValue={transactionLineItem.quantity}
            render={({ field }) => (
              <Editable
                defaultValue={String(field.value)}
                onSubmit={(value) => {
                  field.onChange(parseFloat(value));
                  onQuantityOrRateUpdated();
                }}
              >
                <EditablePreview className={styles.editableFieldPreview} />
                <EditableInput className={styles.editableFieldInput} />
              </Editable>
            )}
          />
        </Td>
        <Td>
          <Controller
            name={`transactionItems.${index}.rate`}
            control={control}
            defaultValue={transactionLineItem.rate}
            render={({ field }) => (
              <Editable
                defaultValue={String(field.value)}
                onSubmit={(value) => {
                  field.onChange(parseFloat(value));
                  onQuantityOrRateUpdated();
                }}
              >
                <EditablePreview className={styles.editableFieldPreview} />
                <EditableInput className={styles.editableFieldInput} />
              </Editable>
            )}
          />
        </Td>
        <Td>{(rate * quantity).toFixed(2)}</Td>
        <Td>
          <Controller
            name={`transactionItems.${index}.vat`}
            control={control}
            defaultValue={transactionLineItem.vat}
            render={({ field }) => (
              <Editable
                defaultValue={String(field.value)}
                onSubmit={(value) => {
                  field.onChange(parseFloat(value));
                  onQuantityOrRateUpdated();
                }}
              >
                <EditablePreview className={styles.editableFieldPreview} />
                <EditableInput className={styles.editableFieldInput} />
              </Editable>
            )}
          />
        </Td>
        <Td>{((rate * quantity * vat) / 100).toFixed(2)}</Td>
        <Td>{(rate * quantity * (1 + vat / 100)).toFixed(2)}</Td>
        <Td>
          <Tooltip label="Remove" hasArrow>
            <IconButton
              size="small"
              variant="link"
              _hover={{ opacity: '0.5' }}
              aria-label="remove"
              color="gray.400"
              onClick={() => remove(index)}
              icon={<SBTrashIcon width="15px" />}
            />
          </Tooltip>
        </Td>
      </Tr>
    );
  }
);
