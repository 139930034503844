import { useLazyQuery } from '@apollo/client';

import { GET_JOB_QUERY } from '../Queries';
import { type JobsResponse } from './types';

interface QueryJobsResponse {
  paginatedRoutes: JobsResponse;
}

interface JobsProps {
  id: string;
}

export function useJob({ id }: JobsProps) {
  const [query, { data, loading, error }] = useLazyQuery<QueryJobsResponse>(GET_JOB_QUERY, {
    variables: { paginatedRoutesId: [id], page: 1, perPage: 1 },
    pollInterval: 30000,
  });

  return {
    query,
    job: data?.paginatedRoutes.data.at(0),
    allOrder:
      data?.paginatedRoutes.data.at(0) &&
      Object.values(
        data?.paginatedRoutes?.data.at(0)?.sequenceStops.reduce((prev, current) => {
          const newHash: any = prev;
          current.dropOrders.map((order) => (newHash[order.swftboxTracking] = order));
          current.pickupOrders.map((order) => (newHash[order.swftboxTracking] = order));
          return newHash;
        }, {}) || {}
      ),
    loading,
    error,
  };
}
