import {
  Box,
  Button,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { useRemoveJob } from 'src/components/Particles';

interface RemoveJobProps {
  routeId: string;
  reference: string;
}

export function RemoveJob({ routeId, reference }: RemoveJobProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { remove, loading } = useRemoveJob();

  function onConfirm() {
    void remove({
      payload: { routeId },
      onCompleted: (message) => {
        toast.success(message);
        onClose();
      },
    });
  }

  return (
    <>
      <MenuItem
        onClick={onOpen}
        fontSize="text-sm"
        color="gray.500"
      >
        <Text fontWeight="semibold">Delete</Text>
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Job</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to delete Job with reference{' '}
            <Text as="span" fontWeight="semibold">
              {reference}
            </Text>{' '}
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
