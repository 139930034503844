import { useState } from 'react';
import { Box, Text, Button, SBAddIcon, toast } from '@swftbox/style-guide';
import { ShiftTimesTable, ShiftTimeForm } from './Forms/ShiftTiming';
import { ShiftTime } from 'src/components/Particles';
import { useUpdateShiftTimeMutation, useDeleteShiftTimesMutation } from 'src/components/Particles';

interface Props {
  closeShiftTimes: () => void;
  driverId: string;
  shiftTimes: ShiftTime[];
}

export default function ShiftTimes(props: Props) {
  const { driverId, shiftTimes, closeShiftTimes } = props;
  const [showForm, setShowForm] = useState(false);
  const [selectedShiftTime, setSelectedShiftTime] = useState<ShiftTime | undefined>();
  const { updateShiftTime, updating, isUpdated } = useUpdateShiftTimeMutation();
  const { deleteShiftTimes } = useDeleteShiftTimesMutation();

  const formatPayload = (
    driverId: string,
    shiftTimes: ShiftTime[],
    newShiftTimeData: ShiftTime
  ) => {
    const newShiftTimes = shiftTimes
      .filter((shiftTime) => shiftTime.day !== newShiftTimeData.day)
      .map((shiftTime) => ({
        day: shiftTime.day,
        from: shiftTime.from,
        to: shiftTime.to,
      }));
    newShiftTimes.push(newShiftTimeData);
    return { driverId, shiftTimes: newShiftTimes };
  };

  const onDelete = (shiftTime: ShiftTime) => {
    deleteShiftTimes({
      driverId,
      shiftTimes: [{ day: shiftTime.day, from: shiftTime.from, to: shiftTime.to }],
    });
    closeShiftTimes();
    toast.success('Shift Time deleted successfully');
  };

  const onSave = (data: ShiftTime) => {
    const payload = formatPayload(driverId, shiftTimes, data);
    updateShiftTime(payload);
    closeShiftTimes();
    toast.success('Shift Time saved successfully');
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };
  return (
    <Box>
      {!showForm ? (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
          >
            <Text fontWeight={'bold'}>Shift Times</Text>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                setShowForm(true);
              }}
            >
              <SBAddIcon width="15px" />
              <Text>Add new</Text>
            </Button>
          </Box>
          <ShiftTimesTable
            driverId={driverId}
            onEdit={() => setShowForm(true)}
            onDelete={onDelete}
            shiftTimes={shiftTimes}
            setSelectedShiftTime={setSelectedShiftTime}
          />
        </>
      ) : (
        <ShiftTimeForm
          shiftTime={selectedShiftTime}
          updating={updating}
          onClose={handleCloseForm}
          onSaveForm={onSave}
        />
      )}
    </Box>
  );
}
