import { toast, IconButton, SBTrashIcon } from '@swftbox/style-guide';
import { type Feature, useRemoveFeature } from 'src/components/Particles';

interface RemoveFeatureProps {
  feature: Feature;
}
export function RemoveFeature({ feature }: RemoveFeatureProps) {
  const { mutate } = useRemoveFeature({
    onCompleted: (data) => {
      if (data.removeFeature.success) toast.success(data.removeFeature.message);
    },
  });

  const onRemove = (id: string) => {
    mutate({
      variables: {
        removeFeatureId: id,
      },
    });
  };

  return (
    <IconButton
      onClick={() => onRemove(feature.id)}
      size="small"
      variant="link"
      color="gray.600"
      _hover={{ opacity: '0.5', color: 'green' }}
      aria-label="remove role"
      icon={<SBTrashIcon width="15px" />}
    />
  );
}
