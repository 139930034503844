import {
  Badge,
  Box,
  SBModal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@swftbox/style-guide';
import { useState } from 'react';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import {
  fixPrecision,
  useConfiguration,
  useRemoveDriverMutation,
  useUpdateDriverMutation,
  useUpdateDriverStatusMutation,
  useUpdateUserStatusMutation,
} from 'src/components/Particles';
import { headers } from '../Mocking';
import DriversActionButtons from './DriversActionButtons';
import { DriverForm } from './Forms';
import { colors } from './helper';
import ShiftTimes from './ShiftTimes';

interface driversTableProps {
  drivers: any[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
}

export function DriversTable(props: driversTableProps) {
  const { removeDriver } = useRemoveDriverMutation();
  const { updateDriverStatus } = useUpdateDriverStatusMutation();
  const { updateUserStatus } = useUpdateUserStatusMutation();
  const { updateDriver } = useUpdateDriverMutation();
  const { drivers, page, total, onPageChange } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDriver, setSelectedDriver] = useState<any>();
  const [openShiftTimingForm, setOpenShiftTimingForm] = useState(false);
  const { configuration: currency } = useConfiguration('currency');

  function onConfirm() {}

  return (
    <Box>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              {headers.map((header, index) => (
                <Th key={index}>{header.name}</Th>
              ))}
              <Th right="0">
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={10}
                  onPageChange={onPageChange}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {drivers.map((driver) => (
              <Tr key={driver.id}>
                <Td>
                  <Text>{driver.user.name}</Text>
                </Td>
                <Td>
                  <Text>{driver.user.phone}</Text>
                </Td>
                <Td>
                  <Text> {driver.isOnline ? 'Yes' : 'No'}</Text>
                </Td>
                <Td>
                  <Text>{driver.employmentType}</Text>
                </Td>
                <Td>
                  <Text>{`${fixPrecision(driver.balance)} ${currency?.value}`}</Text>
                </Td>
                <Td>
                  <Text>{`${fixPrecision(driver.codBalance)} ${currency?.value}`}</Text>
                </Td>
                <Td>
                  <VStack gap={2} alignItems="flex-start" justifyContent={'center'}>
                    <Badge
                      p="2px 8px"
                      fontWeight="medium"
                      borderRadius="8px"
                      minWidth={'60%'}
                      textAlign={'center'}
                      fontSize="x-small"
                      variant="solid"
                      colorScheme={colors[driver.status]}
                    >
                      {driver.status}
                    </Badge>
                    <Badge
                      p="2px 8px"
                      fontWeight="medium"
                      borderRadius="8px"
                      minWidth={'60%'}
                      textAlign={'center'}
                      fontSize="x-small"
                      variant="solid"
                      colorScheme={colors[driver.user.status]}
                    >
                      {driver.user.status}
                    </Badge>
                  </VStack>
                </Td>
                <Td textAlign="end">
                  <DriversActionButtons
                    isOnline={driver.isOnline}
                    onMenuOpen={() => {
                      setSelectedDriver(driver);
                    }}
                    status={driver.user.status}
                    driverStatus={driver.status}
                    goOnBreak={async (val: boolean) =>
                      updateDriver({ id: driver.id, isOnline: val })
                    }
                    deleteDriver={() => {
                      removeDriver(driver?.id || '');
                    }}
                    updateDriverStatus={(status) => {
                      updateDriverStatus({ id: driver.id, status });
                    }}
                    updateUserStatus={(status) => {
                      updateUserStatus({ id: driver.user.id, status });
                    }}
                    onOpen={onOpen}
                    openShiftTimingForm={(val) => {
                      setOpenShiftTimingForm(val);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={openShiftTimingForm ? 'Edit Shift Timing' : 'Edit Driver'}
        size="2xl"
        body={
          openShiftTimingForm ? (
            <ShiftTimes
              driverId={selectedDriver?.id}
              shiftTimes={selectedDriver?.shiftTimes}
              closeShiftTimes={onClose}
            />
          ) : (
            <DriverForm mode="edit" data={selectedDriver} onClose={onClose} />
          )
        }
        bordered={false}
        footer={<></>}
        handleConfirm={onConfirm}
        // @ts-expect-error dummy
        scrollBehavior="outside"
      />
    </Box>
  );
}
