import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import {
  fixPrecision,
  PaymentEntityType,
  PurchaseReferenceType,
  type Purchase,
} from 'src/components/Particles';

interface PurchaseTableProps {
  purchases: Purchase[];
  page: number;
  perPage: number;
  total: number;
  onPageChange: (page: number) => void;
}

export function PurchasesTable(props: PurchaseTableProps) {
  const { purchases, page, perPage, total, onPageChange } = props;

  if (!purchases.length) return null;

  return (
    <>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              <Th>Transaction ID</Th>
              <Th width="10%">
                <Text color="neutral.normal"> Type</Text>
                <Text>Reference</Text>
              </Th>
              <Th width="20%">Amount</Th>
              <Th width="30%">
                <Text>Payment Method</Text>
                <Text color="neutral.normal">Provider</Text>
              </Th>
              <Th width="15%">
                <Text color="neutral.normal">Entity Type</Text>
                <Text>Retailer/Customer</Text>
              </Th>
              <Th>Status</Th>
              <Th>Created at</Th>
              <Th>Updated at</Th>

              <Th right="0">
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={perPage ?? 10}
                  onPageChange={onPageChange}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {purchases.map((purchase) => {
              const status =
                !!purchase.transactionId && purchase.transactionId !== ''
                  ? purchase.success
                    ? 'Completed'
                    : 'Failed'
                  : 'Pending';

              const reference =
                purchase.referenceType === PurchaseReferenceType.ORDER
                  ? purchase.order?.swftboxTracking
                  : '-';

              const entity =
                purchase.entityType === PaymentEntityType.CUSTOMER
                  ? purchase?.customer
                    ? String(purchase?.customer?.name)
                    : '--'
                  : purchase?.retailer?.name ?? '--';

              return (
                <Tr key={purchase.id}>
                  <Td alignItems={'center'}>
                    <Text>{purchase.transactionId ? purchase.transactionId : '--'}</Text>
                  </Td>
                  <Td>
                    <Text>{String(purchase.referenceType)}</Text>
                    <Text>{reference}</Text>
                  </Td>
                  <Td>
                    <Text>{` ${purchase?.currency} ${fixPrecision(purchase.amount)}`}</Text>
                  </Td>
                  <Td>
                    <Text>{purchase.paymentMethod}</Text>
                    <Text>{purchase.provider}</Text>
                  </Td>
                  <Td>
                    <Text fontSize={'10px'}>{purchase.entityType}</Text>
                    <Text>{entity}</Text>
                  </Td>
                  <Td>
                    <Text>{status}</Text>
                  </Td>
                  <Td>
                    <Text>{new Date(purchase.createdAt).toLocaleString()}</Text>
                  </Td>
                  <Td>
                    <Text>{new Date(purchase.updatedAt).toLocaleString()}</Text>
                  </Td>
                  <Td>
                    {status !== 'Completed' &&
                      <a href={purchase.redirectUrl} target="_blank">Checkout &raquo;</a>
                    }
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
