import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_FLAG } from 'src/components/Particles/resolvers/orders/orders.graphql';
import { type clientOptionType } from '../SharedTypes';
import type { OrderFlag, UpdateOrderFlagInput } from './orders.types';

interface OrderFlagResponse {
  message: string;
  data: OrderFlag;
}

interface FlagOrderProps {
  payload: {
    updateOrderFlagInput: UpdateOrderFlagInput;
  };
  onCompleted: (
    data: { updateOrderFlag: OrderFlagResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateOrderFlag() {
  const [mutate, { loading, error }] = useMutation<
    { updateOrderFlag: OrderFlagResponse },
    { updateOrderFlagInput: UpdateOrderFlagInput }
  >(UPDATE_ORDER_FLAG);

  async function updateOrderFlag({ payload, onCompleted }: FlagOrderProps) {
    await mutate({
      variables: payload,
      onCompleted,
      refetchQueries: [
        // {
        //   query: FILTER_ORDERS,
        //   variables: QueriesStore.get('FILTER_ORDERS'),
        // },
        'FlaggedOrdersCount',
      ],
    });
  }

  return {
    updateOrderFlag,
    loading,
    error,
  };
}
