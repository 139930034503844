import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  FormLabel,
  HStack,
  SlideFade,
  Switch,
  VStack,
} from '@swftbox/style-guide';
import { type FC, type FocusEvent } from 'react';
import { useForm } from 'react-hook-form';
import {
  type Category,
  type Fulfillment,
  type FulfillmentCenter,
  type SelectOptionType,
} from 'src/components/Particles';
import { StepFiveSchema } from '../../Validation';
import { timezones, type StepFivePayloadProps } from '../Helper';
import styles from '../style.module.scss';
import { FormHeader } from './FormHeader';

interface StepFiveFormProps {
  onDone: (payload: StepFivePayloadProps) => void;
  isLoading: boolean;
  submittedPayload?: StepFivePayloadProps;
  fulfillments: FulfillmentCenter[];
  categories: Category[];
}

export const StepFiveForm: FC<StepFiveFormProps> = (props) => {
  const { fulfillments, categories, submittedPayload, onDone, isLoading } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<StepFivePayloadProps>({
    defaultValues: submittedPayload,
    resolver: yupResolver(StepFiveSchema),
  });

  const fulfillmentOptions = [
    {
      label: 'No Fulfillment',
      value: undefined,
    },
    ...fulfillments?.map((ful: Fulfillment) => ({
      label: ful.name,
      value: ful.id,
    })),
  ];

  const categoriesOptions = categories?.map((cat: Category) => ({
    label: cat.name,
    value: cat.id,
  }));

  const timezoneOptions = timezones.map((timezone) => ({
    label: timezone.label,
    value: timezone.value,
  }));

  function onFulfillmentSelected(
    selectedFulfillment: SelectOptionType | readonly SelectOptionType[] | null
  ) {
    if (selectedFulfillment == null || selectedFulfillment instanceof Array) {
      setValue('fulfillmentId', undefined);
    } else {
      setValue('fulfillmentId', selectedFulfillment.value);
    }
  }

  function onCategorySelected(
    selectedCategory: SelectOptionType | readonly SelectOptionType[] | null
  ) {
    if (selectedCategory == null || selectedCategory instanceof Array) {
      setValue('categoryIds', []);
    } else {
      setValue('categoryIds', [selectedCategory.value]);
    }
  }

  function onTimezoneSelected(
    selectedTimezone: SelectOptionType | readonly SelectOptionType[] | null
  ) {
    if (selectedTimezone == null || selectedTimezone instanceof Array) {
      setValue('timezone', '');
    } else {
      setValue('timezone', selectedTimezone.value);
    }
  }

  function onAutocompleteFocus(event: FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  return (
    <>
      <FormHeader
        title="Additional Settings"
        description="( Provide company additional needed settings )"
      />
      <SlideFade
        in
        exit={{ opacity: 0, translateX: '-1000px' }}
        transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
        unmountOnExit
      >
        <form onSubmit={handleSubmit(onDone)} className={styles.stepFiveForm}>
          <Autocomplete
            label="Timezone *"
            options={timezoneOptions}
            onFocus={onAutocompleteFocus}
            onChange={onTimezoneSelected}
            placeholder="Select The Timezone"
            isClearable
            error={errors.timezone?.message}
          />

          <Autocomplete
            label="Category"
            onFocus={onAutocompleteFocus}
            onChange={onCategorySelected}
            options={categoriesOptions}
            placeholder="Select The category"
            isClearable
          />

          <Autocomplete
            label="Fulfillment"
            onFocus={onAutocompleteFocus}
            onChange={onFulfillmentSelected}
            options={fulfillmentOptions}
            placeholder="Select The fulfillment"
            isClearable
          />
          <HStack spacing={6}>
            <VStack alignItems="start" gap={4}>
              <FormLabel htmlFor="isPaymentOnDelivery">Enable Draft orders *</FormLabel>
              <FormLabel htmlFor="requireDeliveryNote">
                Allow multiple pickup locations? *
              </FormLabel>
            </VStack>
            <VStack alignItems="start" gap={4}>
              <Switch id="isDraftEnabled" {...register('isDraftEnabled')} />
              <Switch id="isMultiLocationAllowed" {...register('isMultiLocationAllowed')} />
            </VStack>
          </HStack>

          <Button w="100%" mt="16px" type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </form>
      </SlideFade>
    </>
  );
};
