import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, GridItem, SBInput, SlideFade } from '@swftbox/style-guide';
import { type ChangeEvent, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { type StepTwoPayloadProps } from '../Helper';
import { StepTwoSchema } from '../../Validation';
import { FormHeader } from './FormHeader';

interface StepTwoFormProps {
  onDone: (payload: StepTwoPayloadProps) => void;
  submittedPayload?: StepTwoPayloadProps;
}

export const StepTwoForm: FC<StepTwoFormProps> = ({ onDone, submittedPayload }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<StepTwoPayloadProps>({
    defaultValues: submittedPayload,
    resolver: yupResolver(StepTwoSchema),
  });

  function onPhoneChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const phone = value.includes('+') ? value : `+${value}`;
    setValue('ownerPhone', phone);
    clearErrors('ownerPhone');
  }

  return (
    <>
      <FormHeader title="Account Details" description="( Owner basic details )" />
      <SlideFade
        in
        exit={{ opacity: 0, translateX: '-1000px' }}
        transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
        unmountOnExit
      >
        <form
          onSubmit={handleSubmit(onDone, (err) => {
            console.log(err);
          })}
        >
          <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4}>
            <GridItem colSpan={6}>
              <SBInput
                 
                 
                label="First Name*"
                placeholder="ex: John"
                {...register('firstName')}
                error={errors?.firstName?.message} />
            </GridItem>
            <GridItem colSpan={6}>
              <SBInput
                 
                 
                label="Last Name*"
                placeholder="ex: John"
                {...register('lastName')}
                error={errors?.lastName?.message} />
            </GridItem>
            <GridItem colSpan={12}>
              <SBInput
                 
                 
                label="Phone*"
                {...register('ownerPhone')}
                onChange={onPhoneChange}
                error={errors.ownerPhone?.message} />
            </GridItem>
          </Grid>
          <Button w="100%" mt="16px" type="submit">
            Continue
          </Button>
        </form>
      </SlideFade>
    </>
  );
};
