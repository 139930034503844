import { Box, Button, SBModal, Text, useDisclosure } from '@swftbox/style-guide';
import {
  useCancelPlanRetailerQuery,
  usePausePlanRetailerQuery,
  useResumePlanRetailerQuery,
} from 'src/components/Particles';

type Props = {
  retailerId: string;
  subscriptionId: string;
  type: 'CANCEL' | 'PAUSE' | 'RESUME';
};
export const ConfirmActionSubscription = ({ retailerId, subscriptionId, type }: Props) => {
  const { handleCancelPlan } = useCancelPlanRetailerQuery({
    retailerId,
    subscriptionId,
  });
  const { handlePausePlan } = usePausePlanRetailerQuery({
    retailerId,
    subscriptionId,
  });
  const { handleResumePlan } = useResumePlanRetailerQuery({
    retailerId,
    subscriptionId,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleConfirm = async () => {
    try {
      if (type === 'CANCEL') await handleCancelPlan();
      if (type === 'PAUSE') await handlePausePlan();
      if (type === 'RESUME') await handleResumePlan();
    } finally {
      onClose();
    }
  };
  return (
    <>
      <Button
        variant="solid"
        w="100%"
        onClick={() => {
          onOpen();
        }}
        bg={type === 'CANCEL' ? 'red' : 'black'}
        textTransform="capitalize"
      >
        {type.toLowerCase()} Plan
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text textTransform="capitalize">{type.toLowerCase()} Plan</Text>}
        size="xl"
        body={
          <Box>
            <Text fontSize="text-md" color="gray.700" textTransform="capitalize">
              Are You Sure About {type.toLowerCase()} Subscription ?
            </Text>
          </Box>
        }
        handleConfirm={handleConfirm}
      />
    </>
  );
};
