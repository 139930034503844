import { useMutation } from '@apollo/client';
import { GET_JOB_QUERY, OPTIMIZE_ROUTE } from '../Queries';

interface OptimizeRoutesResponse {
  message: string;
  success: boolean;
}

interface OptimizeRouteProps {
  payload: {
    routeId: string;
  };
  onCompleted?: (data: { optimizeRoute: OptimizeRoutesResponse }) => void;
}

export function useOptimizeRoute() {
  const [mutate, { loading, error }] = useMutation<
    { optimizeRoute: OptimizeRoutesResponse },
    { optimizeRouteInput: { routeId: string } }
  >(OPTIMIZE_ROUTE);

  async function optimizeRoute({ payload, onCompleted }: OptimizeRouteProps) {
    return mutate({
      variables: {
        optimizeRouteInput: {
          ...payload,
        },
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_JOB_QUERY,
          variables: { paginatedRoutesId: [payload.routeId], page: 1, perPage: 1 },
        },
      ],
    });
  }

  return {
    optimizeRoute,
    loading,
    error,
  };
}
