import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBCalendarIcon,
  SBDateRangePicker,
  Text,
} from '@swftbox/style-guide';
import { addDays, format } from 'date-fns';
import { useState } from 'react';
import { DriverSelectInput, RetailerSelectInput } from 'src/components/Organisms';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
const statusFilter = [
  {
    id: 'all',
    value: null,
    label: 'All',
  },
  {
    id: 'pending',
    value: 'pending',
    label: 'Pending',
  },
  {
    id: 'passed',
    value: 'passed',
    label: 'Passed',
  },
  {
    id: 'rejected',
    value: 'rejected',
    label: 'Rejected',
  },
];
const tagsFilter = [
  {
    id: 'all',
    value: null,
    label: 'All',
  },
  {
    id: 'notAutoChecked',
    value: 'autoPassed',
    label: 'Not Auto-Checked',
  },
  {
    id: 'needsReview',
    value: 'needReview',
    label: 'Needs Review',
  },
  {
    id: 'badUpload',
    value: 'badUpload',
    label: 'Bad Upload',
  },
];
interface Props {
  filters: {
    status?: string[];
    photoLabelTags?: string[];
    driverIds?: string[];
    retailerIds?: string[];
    endDate?: Date;
    startDate?: Date;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      status?: string[];
      photoLabelTags?: string[];
      driverIds?: string[];
      retailerIds?: string[];
      endDate?: Date;
      startDate?: Date;
    }>
  >;
}
const initialValue = [
  {
    startDate: addDays(new Date(), -2),
    endDate: new Date(),
    key: 'selection',
    isSelected: false,
  },
];

export const AttachmentFilters = ({ filters, setFilters }: Props) => {
  const [state, setState] = useState<any>(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  const handleFilter = (value: string | null, id: string) => {
    setFilters({ ...filters, [id]: value === null ? undefined : [value] });
  };
  const handleChangeDate = (isApply: boolean) => {
    if (isApply) {
      setFilters({ ...filters, startDate: state[0].startDate, endDate: state[0].endDate });
    }
    setIsOpen(false);
  };

  const resetStateAfterClose = () => {
    if (filters.startDate || filters.endDate) {
      setState([{ ...initialValue[0], startDate: filters.startDate, endDate: filters.endDate }]);
    } else {
      setIsOpen(false);
    }
  };
  return (
    <Box mb="8">
      <HStack justifyContent="space-between" alignItems="end">
        <Box>
          <HStack alignItems="end">
            <HStack
              border="1px solid #D0D5DD;"
              h="40px"
              lineHeight="40px"
              borderRadius="8px"
              shadow="xs"
              overflow="hidden"
              spacing="0"
              bg="#fff"
            >
              {statusFilter.map((filterItem) => (
                <Button
                  p="8px 12px"
                  fontSize="x-small"
                  key={filterItem.id}
                  variant="grouped"
                  bg={
                    (!filters?.status && filterItem.id === 'all') ||
                    filters?.status?.includes(filterItem?.value as string)
                      ? '#63c3ec'
                      : 'transparent'
                  }
                  color={
                    (!filters?.status && filterItem.id === 'all') ||
                    filters?.status?.includes(filterItem?.value as string)
                      ? '#fff'
                      : 'gray.900'
                  }
                  _focus={{ opacity: 0.85 }}
                  _hover={{ opacity: 0.85 }}
                  onClick={() => {
                    handleFilter(filterItem.value, 'status');
                  }}
                >
                  {filterItem.label}
                </Button>
              ))}
            </HStack>
            <HStack
              border="1px solid #D0D5DD;"
              h="40px"
              lineHeight="40px"
              borderRadius="8px"
              shadow="xs"
              overflow="hidden"
              spacing="0"
              bg="#fff"
            >
              {tagsFilter.map((filterItem) => (
                <Button
                  p="8px 12px"
                  fontSize="x-small"
                  key={filterItem.id}
                  variant="grouped"
                  bg={
                    (!filters?.photoLabelTags && filterItem.id === 'all') ||
                    filters?.photoLabelTags?.includes(filterItem?.value as string)
                      ? '#63c3ec'
                      : 'transparent'
                  }
                  color={
                    (!filters?.photoLabelTags && filterItem.id === 'all') ||
                    filters?.photoLabelTags?.includes(filterItem?.value as string)
                      ? '#fff'
                      : 'gray.900'
                  }
                  _focus={{ opacity: 0.85 }}
                  _hover={{ opacity: 0.85 }}
                  onClick={() => {
                    handleFilter(filterItem.value, 'photoLabelTags');
                  }}
                >
                  {filterItem.label}
                </Button>
              ))}
            </HStack>
            <HStack w="500px">
              <RetailerSelectInput
                value={(filters.retailerIds?.length && filters?.retailerIds[0]) || null}
                onChange={(selectedRetailerId) => {
                  handleFilter(selectedRetailerId, 'retailerIds');
                }}
              />
              <DriverSelectInput
                onChange={(value) => {
                  handleFilter(value, 'driverIds');
                }}
                value={(filters.driverIds?.length && filters?.driverIds[0]) || null}
                byUser={true}
              />
            </HStack>
          </HStack>
        </Box>
        <Box position="relative" mt="18px !important">
          {!!filters?.startDate && (
            <Button
              variant="link"
              color="#63C3EC"
              bg="#fff"
              position="absolute"
              minW="15px"
              h="15px"
              fontWeight="semibold"
              fontSize="9px"
              borderRadius="50%"
              top="13px"
              left="10px"
              zIndex={10}
              _hover={{ opacity: '0.8' }}
              onClick={() => {
                setFilters({
                  ...filters,
                  startDate: initialValue[0].startDate,
                  endDate: initialValue[0].endDate,
                });
              }}
            >
              <SVG src={XIcon} width="8px" stroke="currentColor" />
            </Button>
          )}
          <Menu closeOnSelect={false} onClose={resetStateAfterClose} isOpen={isOpen}>
            <MenuButton
              as={Button}
              whiteSpace="nowrap"
              transition="all 0.3s"
              h="40px"
              borderRadius="8px"
              boxShadow="xs"
              fontSize="x-small"
              border="1px solid"
              fontWeight="bold"
              onClick={() => {
                setIsOpen(true);
              }}
              w="min-content"
              bg="#C9E9F6 !important"
              color="#fff"
              borderColor="#63C3EC"
              _hover={{ opacity: '0.7' }}
              _focus={{ opacity: '0.7' }}
            >
              <HStack justifyContent="space-between">
                {!!filters?.startDate ? (
                  <Text bg="#63C3EC" py="1" px="2" borderRadius="8px" fontSize="x-small" ms="7px">
                    {format(filters?.startDate ?? new Date(), 'MMM dd, yyyy')} - {''}
                    {format(filters?.endDate ?? new Date(), 'MMM dd, yyyy')}
                  </Text>
                ) : (
                  <>
                    <Text>Date Range</Text>
                    <SBCalendarIcon width="15px" />
                  </>
                )}
              </HStack>
            </MenuButton>
            {isOpen && (
              <MenuList>
                <SBDateRangePicker setValue={setState} value={state} />
                <HStack justifyContent="end" p="3" borderTop="1px solid #eee">
                  <Button
                    minW="200px"
                    variant="outline"
                    onClick={() => {
                      handleChangeDate(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    minW="200px"
                    onClick={() => {
                      handleChangeDate(true);
                    }}
                  >
                    Apply
                  </Button>
                </HStack>
              </MenuList>
            )}
          </Menu>
        </Box>
      </HStack>
    </Box>
  );
};
