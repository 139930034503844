import { FulfillmentPageWrapper } from "../../../Pages";

export const FulfillmentRoutes = {
	path: "fulfillment",
	element: <FulfillmentPageWrapper/>,
};

// Atomic (buttons / icons)
// moleculs  (clock / input with label)
// orgsmis (sidebar / navbar / popus)
// pages (Order / Payment / Setting)
// templates (Private / Public)
// particles (language / routes / context / APIS)
