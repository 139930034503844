import { Box, HStack, Text } from '@swftbox/style-guide';
import { calculatePosition } from './helper';
import { format, startOfHour } from 'date-fns';
import Svg from 'react-inlinesvg';
import ColoredRocketIcon from 'src/assets/icons/colored-rocket.svg';

interface DataOnSliderProps {
  sliderDataInfo: Map<
    string,
    { warning: number; danger: number; normal: number; express: number; value: string }
  >;
}

const handleCurrentTime = (value: string) => {
  if (value === '2:00' && format(new Date(), 'HH:mm') < '08:00') {
    return true;
  } else if (format(startOfHour(new Date()), 'H:mm') === value) {
    return true;
  } else {
    return false;
  }
};
const DataOnSlider = ({ sliderDataInfo }: DataOnSliderProps) => {
  return (
    <Box style={{ position: 'relative' }} zIndex={1000} fontSize="text-sm" color="gray.500">
      {Array.from(sliderDataInfo.values()).map((value, index) => (
        <Box key={value.value}>
          <HStack
            sx={{
              transform: 'translateX(-50%)',
              top: '-40px',
              position: 'absolute',
              left: `${calculatePosition(index)}%`,
            }}
            spacing="0.5"
            color="#fff"
            fontWeight="semibold"
          >
            {!!value.warning && (
              <Box bg="#FFB800" py="0.5" px="2" borderRadius="5px">
                {value.warning}
              </Box>
            )}
            {!!value.danger && (
              <Box bg="rgba(255, 101, 101, 1)" py="0.5" px="2" borderRadius="5px">
                {value.danger}
              </Box>
            )}
            {!!value.normal && !value.express && (
              <Box bg="#777" py="0.5" px="2" borderRadius="5px">
                {value.normal}
              </Box>
            )}
            {!!value.normal && value.express && (
              <HStack spacing="0.5">
                <Box bg="#777" py="0.5" px="1.5" borderRadius="5px">
                  <HStack spacing="2px" alignItems="baseline">
                    <Text>{value.express}</Text>
                    <Svg src={ColoredRocketIcon} width="8px" height="8px" stroke="currentColor" />
                  </HStack>
                </Box>
                {value.normal > value.express && (
                  <Box bg="#777" py="0.5" px="2" borderRadius="5px">
                    {value.normal - value.express}
                  </Box>
                )}
              </HStack>
            )}
          </HStack>
          <Box
            sx={{
              transform: 'translateX(-50%)',
              bottom: '-15px',
              position: 'absolute',
              left: `${calculatePosition(index)}%`,
              _after: {
                content: handleCurrentTime(value.value) ? "' '" : 'unset',
                position: 'absolute',
                border: '0.5px solid #63c3ec',
                height: '100px',
                left: `${(+format(new Date(), 'mm') / 60) * 100}%`,
                bottom: '-28px',
                opacity: 0.2,
              },
              color: handleCurrentTime(value.value) ? '#63c3ec' : 'gray.500',
            }}
          >
            {value.value !== '2:00' && value.value !== '23:00' && value.value}
            {value.value === '23:00' && 'After 22:00'}
            {value.value === '2:00' && 'pre-8:00'}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DataOnSlider;
