import { HStack, Badge, Tooltip } from '@swftbox/style-guide';
import BlueUpIcon from 'src/assets/icons/General/blueUp.svg';
import userYellow from 'src/assets/icons/General/userYellow.svg';
import ExchangeIcon from 'src/assets/icons/exchange-arrow.svg';
import SVG from 'react-inlinesvg';

interface Props {
  state: string;
  isReverse: boolean;
}
export const ExchangeBadge = ({ state, isReverse }: Props) => {
  return (
    <Tooltip label={isReverse ? 'Order is Return' : 'Order is Exchange'} hasArrow>
      <HStack>
        <Badge
          background={isReverse ? '#FFF2C9' : '#F6DCFF'}
          border={`1.5px solid ${isReverse ? '#F6CA45' : '#6764EC'}`}
          color={isReverse ? '#F6CA45' : '#6764EC'}
          rounded="16px"
          fontSize="x-small"
          fontWeight="semibold"
          px="4"
          py="2px"
          _notFirst={{ ml: '1' }}
          _notLast={{ mr: '0' }}
          aria-label={isReverse ? 'Order is Return' : 'Order is Exchange'}
        >
          <HStack spacing="2">
            <HStack spacing="0.5">
              {isReverse && (
                <>
                  <SVG src={BlueUpIcon} width="11px" fill={'#F6CA45'} />
                  <SVG src={userYellow} width="11px" height="11px" fill={'#F6CA45'} />
                </>
              )}
              {!isReverse && (
                <>
                  <SVG src={ExchangeIcon} width="11px" height="14px" fill={'#6764EC'} />
                  <SVG src={userYellow} width="11px" height="11px" fill={'#6764EC'} />
                </>
              )}
            </HStack>
          </HStack>
        </Badge>
      </HStack>
    </Tooltip>
  );
};
