import { FC } from 'react';
import {
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Button,
  SBModal,
  Select,
  Text,
  toast,
  useDisclosure
} from '@swftbox/style-guide';
import {
  StopStatus,
  SequenceStops,
  EditStopInput,
  useEditStopMutation,
} from 'src/components/Particles';
import { Controller, useForm } from 'react-hook-form';

type SequenceStopWithId = SequenceStops & { id: string };

interface Props {
  sequenceStop: SequenceStopWithId;
  onModalClose: () => void;
}

const StopStatusOptions = Object.keys(StopStatus).map(status => ({
  label: status.replace('_', ' '),
  value: StopStatus[status as keyof typeof StopStatus],
}))

export const EditStopModal: FC<Props> = ({ sequenceStop, onModalClose }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    handleSubmit,
    control,
    reset,
  } = useForm<EditStopInput>({
    mode: 'all',
    defaultValues: {
      routeId: sequenceStop.routeId,
      sequence: sequenceStop.sequence,
      status: sequenceStop.status as StopStatus,
    },
  });

  const { editStop, loading } = useEditStopMutation();

  const onSubmit = handleSubmit(async (data) => {
    await editStop(data);
    toast.success(`Stop with sequence ${data.sequence} updated successfully.`);

    reset();
    onClose();
    onModalClose();
  });

  return (
    <SBModal
      isOpen={isOpen}
      onClose={() => { onClose(); onModalClose() }}
      closeOnEsc
      header={<Text>{`Edit stop with sequence ${sequenceStop.sequence} having ${sequenceStop.totalCount} orders`}</Text>}
      size="xl"
      body={
        <form onSubmit={onSubmit}>
          <Box marginTop={2}>
            <Stack gap={1}>
              <HStack>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Stop Status</FormLabel>
                      <Select onChange={onChange} value={value} required>
                        {StopStatusOptions.map((option, i) => (
                          <option key={`stop-${i}-status-${value}`} value={option.value}>{option.label}</option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </HStack>
              <Box>
                <Button type="submit" disabled={loading}>Update Stop</Button>
              </Box>
            </Stack>
          </Box>
        </form>
      }
      bordered={false}
      footer={<div />}
    />
  );
}
