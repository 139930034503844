import { Autocomplete, Box, Button, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import { useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import {
  type Order,
  OrderStage,
  OrderState,
  useMarkOrderAsRTO,
} from 'src/components/Particles/resolvers/orders';
import { getRtoReasonsOptions } from '../../helper';
import { useState } from 'react';

export function MarkOrderAsRTO() {
  const [reason, setReason] = useState<string>();
  const { markAsRTOIsOpen, data: order } = useOrderModalContext() as {
    markAsRTOIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    dispatch({ type: 'CLOSE_MARK_AS_RTO' });
  };

  const { markOrder, loading } = useMarkOrderAsRTO();

  const { stage, state } = order;

  if (stage === OrderStage.rto || state !== OrderState.warehouse) {
    return null;
  }

  function onConfirm() {
    if (!reason) {
      toast.error('You must choose a reason!');
      return;
    }
    void markOrder({
      payload: {
        markOrdersAsRtoInput: {
          orderIds: [order.id],
          reason,
        },
      },
      onCompleted: ({ markOrderAsRTO }) => {
        if (markOrderAsRTO.message) {
          handleCloseModal(markOrderAsRTO.message);
        }
      },
    });
  }
  return (
    <>
      <SBModal
        isOpen={markAsRTOIsOpen}
        onClose={handleCloseModal}
        type="general"
        header={
          <Box pb="1vh">
            <Text>Mark As RTO</Text>
          </Box>
        }
        body={
          <Box>
            <Autocomplete
              onChange={(option) => {
                //  @ts-expect-error dummy
                setReason(option.value);
              }}
              options={getRtoReasonsOptions()}
              placeholder="Choose RTO reason..."
            />
          </Box>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button
              variant="outline"
              w="100%"
              onClick={() => {
                dispatch({ type: 'CLOSE_MARK_AS_RTO' });
              }}
            >
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
        // @ts-expect-error dummy
        scrollBehavior="outside"
      />
    </>
  );
}
