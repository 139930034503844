import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { type clientOptionType } from '../SharedTypes';
import { GET_ORDER_TRACKING_NUMBER } from './orders.graphql';
import { type Order } from './orders.types';

interface QueryResponse {
  OrderByTrackingNumber: Order;
}
interface QueryProps {
  onCompleted?: (data: QueryResponse, clientOptions?: clientOptionType) => void;
}
export function useOrderByTrackingNumberQuery({ onCompleted }: QueryProps) {
  const [query, { loading, error }] = useLazyQuery<QueryResponse>(GET_ORDER_TRACKING_NUMBER, {
    onCompleted,
  });

  const getOrderByTrackingNumber = useCallback(
    (trackingNumber: string) => {
      void query({ variables: { trackingNumber } });
    },
    [query]
  );

  return {
    getOrderByTrackingNumber,
    loading,
    error,
  };
}
