import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import style from './style.module.scss';
import TaskCategoriesHeader from './Components/TaskCategoriesHeader';
import TaskCategoriesTable from './Components/TaskCategoriesTable';
import { useTaskCategories } from 'src/components/Particles';

export function TaskCategoriesPage() {
  const { taskCategories, loading } = useTaskCategories();
  return (
    <Box className={style.taskCategories}>
      <TaskCategoriesHeader taskCategoriesCount={taskCategories.length} />
      <WithLoader isLoading={loading}>
        <TaskCategoriesTable taskCategories={taskCategories} />
      </WithLoader>
      {!loading && taskCategories.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Task Categories Found" />
        </Flex>
      )}
    </Box>
  );
}
