import { useMutation } from '@apollo/client';
import { ADD_FEEDBACK_QUESTION, GET_FEEDBACK_QUESTIONS_QUERY, type QuestionType } from '../Queries';
import { type clientOptionType } from '../../../SharedTypes';

interface AddQuestionResponse {
  message: string;
}
interface AddQuestionsProps {
  onCompleted: (
    data: { createFeedbackQuestion: AddQuestionResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useAddQuestion({ onCompleted }: AddQuestionsProps) {
  const [mutate, { error, loading }] = useMutation<
    { createFeedbackQuestion: AddQuestionResponse },
    { input: QuestionType }
  >(ADD_FEEDBACK_QUESTION, {
    onCompleted,
    refetchQueries: [
      {
        query: GET_FEEDBACK_QUESTIONS_QUERY,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
