import { type IProviderProps } from '../../types';
import { JobItemsSelectionProvider } from './JobItemsSelectionProvider';
import { JobTypeProvider } from './JobTypeProvider';

export const CreateJobProvider = ({ children }: IProviderProps) => {
  return (
    <JobTypeProvider>
      <JobItemsSelectionProvider>{children}</JobItemsSelectionProvider>
    </JobTypeProvider>
  );
};
