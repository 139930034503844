import { useMutation } from '@apollo/client';
import { UPDATE_DRIVER, GET_DRIVERS } from './drivers.graphql';
import { UpdateDriverInput } from './drivers.types';
import { QueriesStore } from '../queriesStore';

interface UpdateDriverResponse {
  id: string;
  userId: string;
}

export function useUpdateDriverMutation() {
  const [mutate, { data, loading, error }] = useMutation<{ updateDriver: UpdateDriverResponse },{ updateDriverInput: UpdateDriverInput }>(UPDATE_DRIVER, {
    variables: {
      updateDriverInput: {},
    },
    refetchQueries: [{query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS')}]
  })

  async function updateDriver(payload: UpdateDriverInput) {
    await mutate({
      variables: {
        updateDriverInput: payload,
      },
      refetchQueries: [{query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS')}]
    });
  }

  return {
    updateDriver,
    isUserUpdated: Boolean(data?.updateDriver.id) && !error,
    updating: loading,
    error,
  };
}
