import {
  Box,
  IconWText,
  MenuItem,
  SBModal,
  Text,
  useDisclosure,
  SBEyeIcon,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { StepperHistory } from 'src/components/Organisms';
import { usePaymentHistory } from 'src/components/Particles';

interface PaymentHistoryProps {
  paymentId: string;
}

export function PaymentHistoryModal({ paymentId }: PaymentHistoryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getPaymentTimeline, paymentTimeline, loading } = usePaymentHistory();
  const [steps, setSteps] = useState<any[]>([]);

  useEffect(() => {
    if (!isOpen) return;
    void getPaymentTimeline({
      variables: {
        paymentId,
      },
    });
  }, [paymentId, isOpen]);

  useEffect(() => {
    if (paymentTimeline && !loading) {
      setSteps(
        paymentTimeline
          .map((step) => ({
            id: uuid(),
            status: step.action,
            name: step?.user?.name,
            type: format(new Date(step.date), 'HH:mm MMM dd, yyyy'),
            orderUpdates: step.paymentUpdates,
          }))
          .reverse()
      );
    }
  }, [loading, paymentTimeline]);

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconWText text="View History" spacing="20px" Icon={<SBEyeIcon width="15px" />} />
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Text>Payment History</Text>
          </Box>
        }
        body={
          <>
            {!steps.length && (
              <Box
                fontSize="text-md"
                textAlign="center"
                mt="2"
                fontWeight="bold"
                color="gray.600"
                fontStyle="italic"
              >
                {!loading ? 'No History To Display' : 'Loading...'}
              </Box>
            )}
            {!loading && !!steps.length && <StepperHistory steps={steps} loading={false} />}
          </>
        }
        size="3xl"
        bordered={false}
        footer={<div />}
      />
    </>
  );
}
