import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import { ADD_CONFIGURATIONS_QUERY, ConfigurationType, GET_CONFIGURATIONS_QUERY } from '../Queries';
interface AddConfigurationResponse {
  message: string;
}
interface AddConfigurationProps {
  onCompleted: (
    data: { createConfiguration: AddConfigurationResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useAddConfiguration({ onCompleted }: AddConfigurationProps) {
  const [mutate, { error, loading }] = useMutation<
    { createConfiguration: AddConfigurationResponse },
    { input: ConfigurationType }
  >(ADD_CONFIGURATIONS_QUERY, {
    onCompleted,
    refetchQueries: [
      {
        query: GET_CONFIGURATIONS_QUERY,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
