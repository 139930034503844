import {
  Box,
  SBModal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import { type Order, useOrderModalActions, useOrderModalContext } from 'src/components/Particles';

export function InternationalErrorsModal() {
  const { internationalErrorsIsOpen, data: order } = useOrderModalContext() as {
    internationalErrorsIsOpen: boolean;
    data: Order;
  };

  const dispatch = useOrderModalActions();

  if (!order.internationalDetails?.errors?.length) return null;

  return (
    <>
      <SBModal
        isOpen={internationalErrorsIsOpen}
        onClose={() => {
          dispatch({ type: 'CLOSE_INTERNATIONAL_SHIPMENT_ERRORS' });
        }}
        header={
          <Box pb="1vh" mb="2">
            <Text>International Shipment Errors</Text>
          </Box>
        }
        body={
          <TableContainer>
            <Table variant="striped" colorScheme="red">
              <Thead>
                <Tr>
                  <Th>Source</Th>
                  <Th>Message</Th>
                </Tr>
              </Thead>
              <Tbody>
                {order.internationalDetails?.errors?.map((errorObj, index) => (
                  <Tr key={index}>
                    <Td>
                      <Text>{errorObj.source}</Text>
                    </Td>
                    <Td>
                      <Text>{errorObj.message}</Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        }
        size="xl"
        bordered={false}
        footer={<div />}
      />
    </>
  );
}
