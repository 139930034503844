import { Button, Flex, SBInput } from '@swftbox/style-guide';
import { type ChangeEvent, type FormEvent, useState } from 'react';
import OTPModal from '../Modal/Login/OTP.Modal';

interface StepTwoFormProps {
  onOTP: (otp: string) => void | Promise<void>;
  useOTP: () => void | Promise<void>;
  back: () => void;
  isSendingOTP: boolean;
  identifier: string;
  onLogin: (password: string) => void;
}

const StepTwoForm = (props: StepTwoFormProps) => {
  const { onOTP, useOTP, back, onLogin, isSendingOTP, identifier } = props;
  const [password, setPassword] = useState('');

  function onPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();
    if (password.length < 4) {
      return;
    }
    onLogin(password);
  }

  return (
    <form onSubmit={onSubmit}>
      <SBInput
        label="Password"
        info="Enter Valid Password"
        onChange={onPasswordChange}
        value={password}
        type="password"
      />
      <Button w="100%" mt="16px" type="submit">
        Continue
      </Button>

      <Flex w="100%" direction="column" mt="16px" gap={1} justifyContent="center">
        <OTPModal identifier={identifier} onDone={onOTP} useOTP={useOTP} isLoading={isSendingOTP} />
        <Button variant="link" onClick={back} type="button" boxShadow="none" color="primary.900">
          Incorrect Email / Phone?
        </Button>
      </Flex>
    </form>
  );
};

export default StepTwoForm;
