import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import {
  ADD_FEATURE,
  type AddFeatureInput,
  LIST_FEATURES,
  type FeatureMutationResponse,
} from '../Queries';

interface AddFeatureProps {
  onCompleted: (
    data: { createFeature: FeatureMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useAddFeature({ onCompleted }: AddFeatureProps) {
  const [mutate, { error, loading }] = useMutation<
    { createFeature: FeatureMutationResponse },
    { input: AddFeatureInput }
  >(ADD_FEATURE, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_FEATURES,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
