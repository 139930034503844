import { gql } from '@apollo/client';

export const SEND_OTP = gql`
  mutation SendOTP($sendOtpInput: SendOtpInput!) {
    sendOtp(sendOtpInput: $sendOtpInput) {
      result
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($verifyOtpInput: VerifyOtpInput!) {
    verifyOtp(verifyOtpInput: $verifyOtpInput) {
      result
      token
      user {
        id
        name
        phone
        email
        roles
      }
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      name
      phone
      email
      roles
    }
  }
`;

export const PASSWORD_LOGIN = gql`
  mutation PasswordLogin($loginInput: LoginInput!) {
    passwordLogin(loginInput: $loginInput) {
      token
      result
      user {
        id
        name
        email
        phone
        roles
      }
    }
  }
`;
