export interface User {
  id: string;
  name: string;
  phone: string;
  email?: string;
  status?: string;
  roles?: string[];
  emailVerifiedAt?: string;
}

export interface SimpleUser {
  id: string;
  name: string;
}

export interface UpdateUserStatusInput {
  id: string;
  status: string;
}

export interface UpdateUserInput {
  id: string;
  name?: string;
  phone?: string;
  email?: string;
  password?: string;
  roles?: string[];
  permissions?: string[];
  verifyEmail?: boolean;
  __typename?: never;
}

export interface UpdateUserFormValues extends Omit<UpdateUserInput, 'roles' | 'permissions'> {
  roles: { name: string }[];
  permissions: { name: string }[];
  verifyEmail?: boolean;
}

export interface UpdateCustomerInput {
  id: string;
  retailerId: string;
  name?: string;
  phone?: string;
  email?: string;
}

export enum AccountStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum UserRoles {
  ADMIN = 'Admin',
  DISPATCHER = 'Dispatcher',
  RETAILER = 'Retailer',
  DRIVER = 'Driver',
  OPERATOR = 'Operator',
  SUPERADMIN = 'SuperAdmin',
}

export enum RetailerUserRole {
  ADMIN = 'ADMIN',
  OPERATION = 'OPERATION',
}

export enum UserSortableFields {
  createdAt = 'createdAt',
  name = 'name',
}

export interface UserFilters {
  roles?: UserRoles[];
  status?: AccountStatus;
}
