import { useMutation } from '@apollo/client';
import { QueriesStore } from '../queriesStore';
import { type FilterQueryResponse } from './filterOrders.service';
import { ASSIGN_ORDER, FILTER_ORDERS } from './orders.graphql';

interface AssignOrderResponse {
  message: string;
  data: {
    id: string;
    status: string;
    name: string;
    driverId: string;
    driver: {
      user: {
        name: string;
        phone: string;
      };
    };
  };
}
interface AssignOrderProps {
  payload: {
    orderIds: string[];
    driverId: string;
  };
  onCompleted?: (data: AssignOrderResponse) => void;
}

export function useAssignOrder() {
  const [mutate, { loading, error }] = useMutation<
    { createQuickRoute: AssignOrderResponse },
    { quickRouteInput: { orderIds: string[]; driverId: string } }
  >(ASSIGN_ORDER);

  async function assignOrder({ payload, onCompleted }: AssignOrderProps) {
    return mutate({
      variables: {
        quickRouteInput: {
          ...payload,
        },
      },

      update: (cache, result, context) => {
        if (result.data && onCompleted) {
          onCompleted(result.data.createQuickRoute);
        }
        const existingOrders: FilterQueryResponse | null = cache.readQuery({
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        });
        const newOrders = existingOrders?.filterOrders.data.map((order) =>
          context.variables?.quickRouteInput.orderIds.includes(order.id)
            ? {
                ...order,
                routeId: result.data?.createQuickRoute.data.id,
                driverName: result.data?.createQuickRoute.data.driver.user.name,
                driverPhone: result.data?.createQuickRoute.data.driver.user.phone,
              }
            : order
        );
        cache.writeQuery({
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
          data: { filterOrders: { ...existingOrders?.filterOrders, data: newOrders } },
        });
      },
    });
  }

  return {
    assignOrder,
    loading,
    error,
  };
}
