import {
  Badge,
  Box,
  Button,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@swftbox/style-guide';
import React, { useEffect, useState } from 'react';
import { type FC } from 'react';
import {
  useCreateSuperDropProfileMutation,
  useUpdateSuperDropProfileCities,
  type DropProfile,
  type SuperDropPorfile,
} from 'src/components/Particles';
import SelectCities from '../SelectCities';
import { ActionButtons } from '../DropProfileDetails/ActionButtons';
import { DropProfileDetails, DropProfileForm } from '../DropProfileDetails';

interface Props {
  superDropProfile?: SuperDropPorfile;
  retailerId: string;
  existingCitiesDropProfiles: string[];
  editDropProfile: (dropProfile: DropProfile) => void;
  onClose?: () => void;
}

const tableHeaders = [
  { title: 'Name', subtitle: '', id: 'name' },
  { title: 'Type', subtitle: '', id: 'type' },
  { title: 'Cuttof time', subtitle: '', id: 'cutoffTime' },
  { title: 'Configured Cities', subtitle: '', id: 'billingSlab' },
  { title: 'Status', subtitle: '', id: 'isDefault' },
  { title: '', subtitle: '', id: 'actions' },
];

export const SuperDropProfileForm: FC<Props> = ({
  retailerId,
  onClose,
  editDropProfile,
  existingCitiesDropProfiles,
  superDropProfile,
}) => {
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [dropProfile, setDropProfile] = useState<DropProfile | null>(null);
  const [showCreateProfileForm, setShowCreateProfileForm] = useState<boolean>(false);
  const { mutate: updateSuperProfileCities } = useUpdateSuperDropProfileCities();
  const { createSuperDropProfile } = useCreateSuperDropProfileMutation();

  const onEditDropProfile = (dropProfile: DropProfile) => {
    editDropProfile(dropProfile);
  };

  const onViewDetails = (dropProfile: DropProfile) => {
    setDropProfile(dropProfile);
  };

  useEffect(() => {
    if (superDropProfile) setSelectedCities(superDropProfile.cityNames);
  }, [superDropProfile]);

  const onSave = async () => {
    if (
      superDropProfile &&
      (selectedCities.length !== superDropProfile.cityNames.length ||
        selectedCities.every((city) => !superDropProfile.cityNames.includes(city)))
    ) {
      return await updateSuperProfileCities({
        variables: {
          updateSuperDropProfileInput: {
            groupId: superDropProfile.groupId,
            cities: selectedCities,
          },
        },
      });
    }
  };

  const onSaveSuperProfile = async () => {
    await createSuperDropProfile({ cities: selectedCities, retailerId });
    onClose?.();
  };

  return (
    <React.Fragment>
      {superDropProfile && !showCreateProfileForm && (
        <>
          <Box mb={5}>
            <Text fontWeight={'bold'}>Edit Super Drop Profile</Text>
          </Box>
          {dropProfile ? (
            <DropProfileDetails
              dropProfile={dropProfile}
              back={() => {
                setDropProfile(null);
              }}
            />
          ) : (
            <Stack gap={4}>
              <SelectCities
                selectedCities={selectedCities}
                existingCitiesDropProfile={existingCitiesDropProfiles}
                onChange={(newSelectedCities) => {
                  setSelectedCities(newSelectedCities);
                }}
              />
              {superDropProfile && !showCreateProfileForm && (
                <Table variant="striped" mb="8" mt="2">
                  <Thead>
                    <Tr bg="#fbfbfc" color="neutral.normal">
                      {tableHeaders.map((header) => (
                        <Th key={header.id}>
                          <Text>{header.title}</Text>
                          <Text color="neutral.normal">{header.subtitle}</Text>
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {superDropProfile?.profiles.map((dropProfile: DropProfile, index: number) => (
                      <Tr key={dropProfile.id} title={dropProfile.description}>
                        <Td>
                          <Text>{dropProfile.name}</Text>
                        </Td>
                        <Td>
                          <Text>{dropProfile.profileType}</Text>
                        </Td>
                        <Td>
                          <Text maxW="100%" fontWeight="semibold">
                            {dropProfile.cutoffTime.replace(/:00$/, '')}
                          </Text>
                        </Td>
                        <Td>
                          <Text>{dropProfile.cities.length}</Text>
                        </Td>

                        <Td>
                          <HStack spacing={2}>
                            {dropProfile.isPrimary && (
                              <Badge
                                p="2px 8px"
                                fontWeight="medium"
                                borderRadius="8px"
                                textAlign={'center'}
                                fontSize="text-xs"
                                variant="solid"
                                colorScheme="blue"
                              >
                                Primary
                              </Badge>
                            )}
                            {!dropProfile.isActive && (
                              <Badge
                                p="2px 8px"
                                fontWeight="medium"
                                borderRadius="8px"
                                textAlign={'center'}
                                fontSize="text-xs"
                                variant="solid"
                                colorScheme="red"
                              >
                                Disabled
                              </Badge>
                            )}
                          </HStack>
                        </Td>
                        <Td>
                          <ActionButtons
                            dropProfile={dropProfile}
                            onViewDetails={onViewDetails}
                            onEdit={onEditDropProfile}
                            retailerId={retailerId}
                            groupId={superDropProfile.groupId}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Button
                    backgroundColor={'transparent'}
                    color={'#62C4ED'}
                    mt={4}
                    variant={'link'}
                    onClick={() => {
                      setShowCreateProfileForm(true);
                    }}
                  >
                    + Create Express Profile
                  </Button>
                </Table>
              )}
            </Stack>
          )}
          <Stack spacing="3" w="100%" direction={['column', 'row']} mt={4}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" type="submit" onClick={onSave}>
              Save
            </Button>
          </Stack>
        </>
      )}

      {showCreateProfileForm && superDropProfile && (
        <DropProfileForm
          dropProfiles={[superDropProfile] ?? []}
          retailerId={retailerId}
          cities={selectedCities}
          onClose={() => {
            setShowCreateProfileForm(false);
          }}
          back={() => {
            setShowCreateProfileForm(false);
          }}
        />
      )}

      {!superDropProfile && !showCreateProfileForm && (
        <>
          <Stack>
            <SelectCities
              selectedCities={selectedCities}
              existingCitiesDropProfile={existingCitiesDropProfiles}
              onChange={(newSelectedCities) => {
                setSelectedCities(newSelectedCities);
              }}
            />
          </Stack>
          <Stack spacing="3" w="100%" direction={['column', 'row']} mt={4}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Cancel
            </Button>
            <Button
              w="100%"
              type="submit"
              onClick={onSaveSuperProfile}
              disabled={!selectedCities?.length}
            >
              Save
            </Button>
          </Stack>
        </>
      )}
    </React.Fragment>
  );
};
