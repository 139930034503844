import { useMutation } from '@apollo/client';
import { CANCEL_ORDER_INTERNATIONAL } from 'src/components/Particles/resolvers/orders/orders.graphql';

interface CancelInternationalOrderResponse {
  message: string;
}

interface CancelInternationalOrderProps {
  swftboxTracking: string;
  onCompleted: (message: string) => void;
}

export function useCancelInternationalOrders() {
  const [mutate, { loading, error }] = useMutation<
    { cancelOrderInternational: CancelInternationalOrderResponse },
    { swftboxTracking: string }
  >(CANCEL_ORDER_INTERNATIONAL);

  async function cancelInternationalOrder({
    swftboxTracking,
    onCompleted,
  }: CancelInternationalOrderProps) {
    await mutate({
      variables: {
        swftboxTracking,
      },
      onCompleted: (data) => {
        onCompleted(data.cancelOrderInternational.message);
      },
    });
  }

  return {
    cancelInternationalOrder,
    loading,
    error,
  };
}
