import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../SharedTypes';
import { GET_JOB_QUERY, REORDER_ROUTE_MUTATION, type ReorderStopsInput } from '../Queries';

interface ReorderStopsProps {
  payload: ReorderStopsInput;
  onCompleted?: (message: string) => void;
}

export function useReorderStops() {
  const [mutate, { loading, error }] = useMutation<
    { reorderRouteStops: MessageResponse },
    { reorderRouteStopsInput: ReorderStopsInput }
  >(REORDER_ROUTE_MUTATION);

  async function reorder({ payload, onCompleted }: ReorderStopsProps) {
    const { routeId, newSequence } = payload;
    await mutate({
      variables: {
        reorderRouteStopsInput: {
          routeId,
          newSequence,
        },
      },
      onCompleted: (data) => {
        if (onCompleted) onCompleted(data.reorderRouteStops.message);
      },
      refetchQueries: [
        {
          query: GET_JOB_QUERY,
          variables: { paginatedRoutesId: [routeId], page: 1, perPage: 1 },
        },
      ],
    });
  }

  return {
    reorder,
    loading,
    error,
  };
}
