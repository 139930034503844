import { Badge, Box, Button, Flex, HStack, SBAddIcon, Spacer, Text } from '@swftbox/style-guide';
import { AddPartner } from './Modals/AddPartner';

interface CustomersHeaderProps {
	partnersCount: number;
}

export function PartnersHeader(props: CustomersHeaderProps) {
	const { partnersCount } = props;
	return (
		<Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={ ['column', 'row'] }>
			<Box>
				<Text fontWeight="semibold" fontSize="text-lg">
					Partners
					<Badge
						p="2px 8px"
						mx="1"
						fontWeight="medium"
						borderRadius="8px"
						fontSize="x-small"
						textTransform="capitalize"
					>
						{ partnersCount } Partner
					</Badge>
				</Text>
			</Box>
			<Spacer/>
			<AddPartner />
			{/* <HStack spacing="30px">
				<Button>
					<SBAddIcon width="15px"/> Add Partner
				</Button>
			</HStack> */}
		</Flex>
	);
}
