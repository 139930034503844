import { Helmet } from "react-helmet";
import { ErrorBoundary } from "src/components/Particles";
import { UsersPage } from "./users.page";

export function UsersPageWrapper() {
	return (
		<>
			<Helmet>
				<title> Users </title>
			</Helmet>
			<ErrorBoundary>
				<UsersPage />
			</ErrorBoundary>
		</>
	)
}
