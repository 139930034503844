import {
  Avatar,
  AvatarBadge,
  Box,
  HStack,
  IconWText,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBSearchIcon,
  Text,
} from '@swftbox/style-guide';

import { type ChangeEvent, useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react';
import SVG from 'react-inlinesvg';
import SBLogOut01Icon from 'src/assets/icons/General/SBLogOut01Icon.svg';
import { useAuthContext, useSearch } from '../../Particles';
import style from './SBNav.module.scss';
import CloseIcon from 'src/assets/icons/General/x.svg';

const packageCountRemove = (value: string) => {
  const indexOfPackageSeparator = value.indexOf('<>');
  return indexOfPackageSeparator > -1 ? value.slice(0, indexOfPackageSeparator) : value;
};
export const SBNavbar = () => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [searchText, setSearchText] = useState<string | undefined | null>(null);
  const { search } = useSearch();

  const { user, onUserLogout } = useAuthContext();
  function onSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value);
  }
  const handleSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchText(packageCountRemove(e.currentTarget.value));
    }
    if (e.key === ' ') {
      setSearchText(packageCountRemove(e.currentTarget.value));
    }
  };

  const focusOnSearch = useCallback(() => {
    if (searchInputRef.current) {
      searchInputRef.current?.focus();
    }
  }, [searchInputRef]);

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === '/') {
        focusOnSearch();
        event.preventDefault();
      }
    },
    [focusOnSearch]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText !== null) {
        search(searchText?.trimEnd());
      }
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText, search]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <nav className={style.navbar}>
      <button
        className={style.navbar_search}
        onClick={() => {
          searchText ? setSearchText('') : null;
        }}
      >
        <div className={style['navbar_search-icon']}>
          {searchText ? (
            <SVG src={CloseIcon} stroke="black" width="12px" height="12px" />
          ) : (
            <SBSearchIcon width="20px" height="20px" />
          )}
        </div>
      </button>
      <Input
        placeholder="Search (Shortcut = '/')"
        className={style.navbar_input}
        ref={(ref) => {
          searchInputRef.current = ref;
        }}
        value={searchText ?? ''}
        onChange={onSearchChange}
        onKeyDown={handleSearchKeyDown}
      />
      <HStack spacing="4">
        <Menu>
          <MenuButton as="button">
            <Avatar
              bg="gray.900"
              color="gray.200"
              w="2.5rem"
              h="2.5rem"
              name={user?.name}
              fontSize="10px"
              size="sm"
              src={user?.name}
            >
              <AvatarBadge boxSize="1.25em" bg="green.500" />
            </Avatar>
          </MenuButton>
          <MenuList>
            <Box p={3} borderBottom="1px solid #EAECF0" mx="-2" mb={2} fontSize="text-sm">
              <HStack>
                <Avatar
                  bg="gray.500"
                  color="gray.100"
                  w="2.5rem"
                  h="2.5rem"
                  name={user?.name}
                  fontSize="10px"
                  size="sm"
                ></Avatar>
                <Box>
                  <Text fontWeight="semibold" color="gray.700">
                    {user?.name}
                  </Text>
                  <Text color="gray.700">{user?.email}</Text>
                </Box>
              </HStack>
            </Box>
            <MenuItem onClick={onUserLogout}>
              <IconWText text="Logout" spacing="8px" Icon={<SVG src={SBLogOut01Icon} />} />
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </nav>
  );
};
