import { Box, Button, Grid, GridItem, SBModal, Text, toast } from '@swftbox/style-guide';
import { useEffect } from 'react';
import { Item, useRemoveItem } from 'src/components/Particles';

interface RemoveItemProps {
  item?: Item;
  onClose: () => void;
  isOpen: boolean;
}
const RemoveItem = (props: RemoveItemProps) => {
  const { onClose, item, isOpen } = props;

  const { callRemoveItem, data } = useRemoveItem();

  useEffect(() => {
    if (data) {
      toast.success(data);
    }
  }, [data]);
  const onConfirm = async () => {
    if (item) {
      await callRemoveItem({ id: item.id });
      onClose();
    }
  };

  return (
    <SBModal
      isOpen={isOpen}
      onClose={onClose}
      type="delete"
      header={<Text>Delete Item</Text>}
      size="xl"
      body={
        <Box>
          <Text fontSize="text-md" color="gray.700">
            Are You Sure About Deleting This Item {item?.skuNumber} ?
          </Text>
        </Box>
      }
      handleConfirm={onConfirm}
    />
  );
};

export default RemoveItem;
