import { gql } from '@apollo/client';
export const CORE_PLAN = gql`
  fragment CorePlan on PlanDto {
    price
    name
    id
    features
    type
    isMostPopular
    description
    currency
    status
    termsTemplateId
    stripePriceId
  }
`;

export const GET_SUBSCRIPTIONS_PLANS = gql`
  ${CORE_PLAN}
  query SubscriptionPlans {
    subscriptionPlans {
      ...CorePlan
    }
  }
`;

export const PUT_SUBSCRIPTIONS_PLAN = gql`
  ${CORE_PLAN}
  mutation UpdateSubscriptionPlan($updatePlanInput: UpdatePlanInput!) {
    updateSubscriptionPlan(updatePlanInput: $updatePlanInput) {
      data {
        ...CorePlan
      }
      message
    }
  }
`;

export const DELETE_SUBSCRIPTIONS_PLAN = gql`
  mutation RemoveSubscriptionPlan($removeSubscriptionPlanId: UUID!) {
    removeSubscriptionPlan(id: $removeSubscriptionPlanId) {
      message
    }
  }
`;

export const CREATE_SUBSCRIPTIONS_PLAN = gql`
  ${CORE_PLAN}
  mutation CreateSubscriptionPlan($createPlanInput: CreatePlanInput!) {
    createSubscriptionPlan(createPlanInput: $createPlanInput) {
      data {
        ...CorePlan
      }
      message
    }
  }
`;

export const GET_RETAILER_ACTIVE_AGREEMENT_QUERY = gql`
  query RetailerActiveAgreement($retailerId: UUID!) {
    retailerActiveAgreement(retailerId: $retailerId) {
      userId
      isActive
      termsVersion
      termsContent
      createdAt
      user {
        name
        phone
      }
    }
  }
`;
