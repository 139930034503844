import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  AccountStatus,
  useUsersQuery,
  UserRoles,
  SortOptions,
  UserSortableFields,
  UserFilters,
  useSearch,
  RetailerUserRole,
} from '../../Particles';
import { UsersFilter, UsersHeader, UsersTable, UserFilterKey } from './Components';
import style from './style.module.scss';

const usersQueryInitialVariables = {
  perPage: 10,
  order: SortOptions.DESC,
  orderBy: UserSortableFields.createdAt,
  page: 1,
};

export function UsersPage() {
  const { searchText } = useSearch();
  const [retailerId, setRetailerId] = useState<string | null>();

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<UserFilters>({});

  const { getUsers, users, totalUsers, pageCount, loading } = useUsersQuery(
    usersQueryInitialVariables
  );

  const onPageChange = (selectedItem: number) => {
    setPage(selectedItem);
  };

  const onFilterChange = (selectedFilter: UserFilterKey) => {
    const filterValue = {
      all: {},
      active: { status: AccountStatus.ACTIVE },
      deactivated: { status: AccountStatus.DISABLED },
      beUser: { roles: [UserRoles.ADMIN, UserRoles.SUPERADMIN, UserRoles.DISPATCHER] },
      beAdmin: { roles: [UserRoles.ADMIN, UserRoles.SUPERADMIN] },
      beDispatcher: { roles: [UserRoles.DISPATCHER] },
      driver: { roles: [UserRoles.DRIVER] },
      crUser: { roles: [UserRoles.RETAILER] },
      crAdmin: { roles: [UserRoles.RETAILER], retailerUserRoles: [RetailerUserRole.ADMIN] },
      crOperations: {
        roles: [UserRoles.RETAILER],
        retailerUserRoles: [RetailerUserRole.OPERATION],
      },
    }[selectedFilter];
    setFilters(filterValue);
  };

  useEffect(() => {
    getUsers({ ...filters, page, keyword: searchText, retailerId });
  }, [page, filters, searchText, retailerId]);

  return (
    <Box className={style.customers}>
      <UsersHeader usersCount={totalUsers} />
      <UsersFilter
        onFilterChange={onFilterChange}
        retailerId={retailerId}
        setRetailerId={setRetailerId}
      />
      <WithLoader isLoading={loading}>
        <UsersTable users={users} page={page} total={totalUsers} onPageChange={onPageChange} />
      </WithLoader>
      {!loading && !users.length && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Users Found" />
        </Flex>
      )}
    </Box>
  );
}
