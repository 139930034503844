import {
  Badge,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  SBBriefcaseIcon,
  SBCommentUserIcon,
  SBFileCheckIcon,
  SBInfoIcon,
  SBTrashIcon,
  Text,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  SBMenuDotsVerticalIcon,
  MenuItem,
} from '@swftbox/style-guide';
import { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import SignatureIcon from 'src/assets/SignatureIcon.svg';
import OTPIcon from 'src/assets/icons/OTPonDelivery.svg';
import LeaveAtTheDoorIcon from 'src/assets/icons/leaveAtDoor.svg';
import MoneyIcon from 'src/assets/icons/orders/dollar.svg';
import StarIcon from 'src/assets/icons/star.svg';
import { AddressCard, SchedulingCard } from 'src/components/Organisms';
import { handleTime } from 'src/components/Pages/Jobs/Helper';
import CommentModal from 'src/components/Pages/Orders/Components/Modal/Comment/CommentModal';

import {
  JobOrder,
  OrderFlagStatus,
  OrderFlagType,
  SequenceStops,
  stateBadgeColor,
  useOrderModalActions,
} from 'src/components/Particles';
interface Props {
  order: JobOrder;
  handleRemoveOrderFromJob?: (swftboxReference: string) => void;
  sequenceStops?: SequenceStops;
  isPickup: boolean;
}
export const OrderRow = ({
  order,
  handleRemoveOrderFromJob,
  sequenceStops,
  isPickup = true,
}: Props) => {
  const { consumer, location } = useMemo(
    () =>
      order?.isReverse
        ? { consumer: order.retailer, location: order.to }
        : { consumer: order.customer, location: order.to },
    [order]
  );

  const keyword = order.isRemote
    ? 'Remote'
    : order.internationalDetails?.isAssigned || order.isInternational
    ? 'International'
    : order.isOutOfZone
    ? 'Out of Zone'
    : undefined;
  const zone = useMemo(
    () =>
      keyword
        ? `${keyword} - ${location?.country ?? ''}`
        : `${location?.dispatchCity ?? ''} - ${location?.dispatchZone ?? ''}`,
    [location, order]
  );
  const dispatch = useOrderModalActions();

  const orderStatus = useMemo(
    () => stateBadgeColor(order.status, order.state, order?.isReverse as boolean),
    [order]
  );
  const handleTiming = useMemo(
    () =>
      handleTime({
        isCompleted: sequenceStops
          ? sequenceStops?.completedCount === sequenceStops?.totalCount
          : false,
        isPickup,
        order,
      }),
    [order, isPickup, sequenceStops?.completedCount, sequenceStops?.totalCount]
  );

  const isStared = useMemo(
    () =>
      order?.orderFlags?.filter(
        (flag: any) =>
          flag.flagType === OrderFlagType.star &&
          [OrderFlagStatus.pending, OrderFlagStatus.actionTaken].includes(flag.status)
      )[0],
    [order]
  );
  return (
    <Grid
      key={order.id}
      width="100%"
      gridTemplateColumns="160px 200px 180px 280px 70px"
      gridAutoFlow="row"
      justifyContent="space-between"
      gap="3"
      color="primary.900"
      fontWeight="medium"
      fontSize="x-small"
      mb="5"
      _notLast={{ mb: '2.5', pb: '2.5', borderBottom: '3px solid white' }}
    >
      <GridItem display="flex" alignItems="center">
        <HStack fontSize="text-sm" mx="3">
          <Box fontWeight="semibold" lineHeight="1.2">
            <HStack fontSize="text-sm">
              <Box fontWeight="semibold" lineHeight="1.2">
                {!!sequenceStops?.locationType && (
                  <HStack>
                    <Text>{sequenceStops?.locationType.toUpperCase()} </Text>

                    {!!isStared && (
                      <Box>
                        <SVG
                          src={StarIcon}
                          stroke="black"
                          fill="#ffe500"
                          strokeWidth="25"
                          width="15"
                          style={{ marginLeft: '-2px' }}
                        />
                      </Box>
                    )}
                  </HStack>
                )}{' '}
                <Text> {isPickup ? 'Pickup' : 'Drop'}</Text>
              </Box>
            </HStack>

            <HStack spacing="1">
              {order?.allowCustomerProofSignature && (
                <Tooltip hasArrow label="Signature Required">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SVG src={SignatureIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
              {order?.requireOtpOnDelivery && (
                <Tooltip hasArrow label="OTP on delivery">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SVG src={OTPIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
              {order?.shouldLeaveAtTheDoor && (
                <Tooltip label="Leave at the door" hasArrow>
                  <Box borderRadius="4" h="25px">
                    <SVG src={LeaveAtTheDoorIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
              {order?.requireProofOfId && (
                <Tooltip hasArrow label="Proof of id">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SBCommentUserIcon width="12px" />
                  </Box>
                </Tooltip>
              )}
              {order?.requireDeliveryNote && (
                <Tooltip hasArrow label="Delivery note">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SBFileCheckIcon width="12px" />
                  </Box>
                </Tooltip>
              )}
              {order?.paymentMode === 'paymentOnDelivery' && (
                <Tooltip hasArrow label="Payment on delivery required">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SVG src={MoneyIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
            </HStack>
          </Box>
        </HStack>
      </GridItem>
      <GridItem display="flex" alignItems="center">
        <Box fontSize="text-sm">
          <Text fontWeight="semibold">{order.reference}</Text>

          <HStack>
            <Text fontWeight="semibold" color="gray.500">
              {order?.swftboxTracking ?? '-'}
            </Text>
            {!!order?.comments.length && <CommentModal order={order as any} />}
          </HStack>
          <Badge
            py="1px"
            mt="2"
            fontWeight="medium"
            borderRadius="8px"
            width="110px"
            textAlign="center"
            fontSize="x-small"
            border="1px solid"
            borderColor={orderStatus.border ?? 'transparent'}
            bg={orderStatus.bg}
            color={orderStatus.color}
          >
            {orderStatus.text}
          </Badge>
        </Box>
      </GridItem>
      <GridItem display="flex" alignItems="center">
        <SchedulingCard
          clickable={false}
          start={handleTiming.start}
          end={handleTiming.end}
          slaStart={order.timing.slaStart}
          slaEnd={order.timing.slaEnd}
        />
      </GridItem>
      <GridItem display="flex" alignItems="center">
        <AddressCard
          zone={zone}
          name={(order.isReverse ? location?.name : consumer?.name) || '-'}
          phone={location.phone ?? consumer?.phone}
          address={`${location.city} - ${location.addressLine1} ${location.addressLine2 ?? ''}`}
          location={location}
          editable={!order?.isReverse}
          showHistory
        />
      </GridItem>
      <GridItem display="flex" alignItems="center" justifyContent="end">
        <Menu>
          <MenuButton as="button">
            <SBMenuDotsVerticalIcon width="15px" />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                dispatch({ type: 'OPEN_ORDER_DETAILS', payload: order as any });
              }}
              fontSize="text-sm"
              color="gray.500"
            >
              View details
            </MenuItem>
            {!!order?.retailer.procedures?.length && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_PROCEDURES', payload: order as any });
                }}
                fontSize="text-sm"
                color="gray.500"
              >
                View procedures
              </MenuItem>
            )}
            {!!handleRemoveOrderFromJob && (
              <MenuItem
                onClick={() => {
                  handleRemoveOrderFromJob(order.swftboxTracking);
                }}
                fontSize="text-sm"
                color="gray.500"
              >
                Delete order
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </GridItem>
    </Grid>
  );
};
