import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, HStack, SBInput, SlideFade, Text } from '@swftbox/style-guide';
import { type FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRetailerNameCheckQuery } from 'src/components/Particles';
import { type StepOnePayloadProps } from '../Helper';
import { getStepOneSchema } from '../../Validation';
import { FormHeader } from './FormHeader';

interface StepOneFormProps {
  onDone: (payload: StepOnePayloadProps) => void;
  submittedPayload?: StepOnePayloadProps;
}

export const StepOneForm: FC<StepOneFormProps> = ({ onDone, submittedPayload }) => {
  const { checkIsRetailerNameExist } = useRetailerNameCheckQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StepOnePayloadProps>({
    defaultValues: submittedPayload,
    resolver: yupResolver(getStepOneSchema(checkIsRetailerNameExist)),
  });

  return (
    <>
      <FormHeader title="Add Retailer" description="( Company basic details )" />
      <SlideFade
        in
        exit={{ opacity: 0, translateX: '-1000px' }}
        transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
        unmountOnExit
      >
        <form onSubmit={handleSubmit(onDone)}>
          <Grid rowGap={2}>
            <SBInput
              label="Company Email Address"
              placeholder="ex: swftbox@swftbox.com"
              error={errors?.email?.message}
              {...register('email')}
              onChange={(e) => (e.target.value = e.target.value.toLowerCase())}
            />
            <SBInput
              label="Company Name"
              placeholder="ex: swftbox"
              error={errors?.name?.message}
              {...register('name')}
            />
          </Grid>
          <Button w="100%" mt="16px" type="submit">
            Continue
          </Button>
        </form>
      </SlideFade>
    </>
  );
};
