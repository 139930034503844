import {
  Box,
  Button,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
  SBAddIcon,
  IconWText,
} from '@swftbox/style-guide';
import { useCancelInternationalOrders } from 'src/components/Particles/resolvers/orders';

interface CancelInternationalOrderProps {
  swftboxTracking: string;
}

export function CancelInternationalOrder({ swftboxTracking }: CancelInternationalOrderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cancelInternationalOrder, loading } = useCancelInternationalOrders();

  function onConfirm() {
    void cancelInternationalOrder({
      swftboxTracking,
      onCompleted: (message) => {
        if (message) {
          toast.success(message);
          onClose();
        }
      },
    });
  }

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconWText
          text="Release & Cancel on Carriyo"
          spacing="20px"
          color="red.normal"
          aria-label="Release & Cancel on Carriyo"
          Icon={<SBAddIcon width="15px" />}
        />
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Release & Cancel on Carriyo</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to release international order from carriyo{' '}
            <Text as="span" fontWeight="semibold">
              {swftboxTracking}
            </Text>{' '}
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
