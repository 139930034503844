import { HStack } from '@swftbox/style-guide';
import { DropProfileType } from '../../OrganizingFilters/DropProfileType';
import DateRange from './Components/DateFilter/DateRange';
import { DelayedOrders } from './Components/DelayedOrders';

type Props = {};
export const TimingFilter = ({}: Props) => {
  return (
    <HStack>
      <DateRange />
      <DelayedOrders />
      <DropProfileType />
    </HStack>
  );
};
