import { Autocomplete } from '@swftbox/style-guide';
import { useEffect, useMemo, useState } from 'react';
import {
  type SelectOptionType,
  type Retailer,
  useRetailersQuery,
  CompanyStatus,
} from 'src/components/Particles';

interface Props {
  onFocus?: () => void;
  onChange?: (retailerId: string | null, retailer?: Retailer | null) => void;
  value: string | null;
  noLabel?: boolean;
  onlyActive?: boolean;
}

export const RetailerSelectInput = ({ onFocus, onChange, value, noLabel, onlyActive }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState('');
  const { retailers, getRetailers, loading } = useRetailersQuery();
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer | null>(null);
  useEffect(() => {
    getRetailers({ page, keyword: search });
  }, [page, search]);

  const handleSearch = (inputValue: string) => {
    setSearch(inputValue);
    setPage(1); // Reset to page 1 on new search
  };

  const handleChange = (
    selectedRetailer: SelectOptionType | readonly SelectOptionType[] | null
  ) => {
    if (!onChange) {
      return;
    }
    if (!selectedRetailer) {
      onChange(null);
    }
    setSelectedRetailer(selectedRetailer ? { ...selectedRetailer } : null);
    onChange((selectedRetailer as SelectOptionType).value ?? null);
  };

  useEffect(() => {
    if (!!value && !selectedRetailer?.value && retailers) {
      const retailer = retailers.find((retailer: Retailer) => retailer.id === value);
      setSelectedRetailer(retailer ? { label: retailer.name, value } : null);
    }
  }, [value, retailers, selectedRetailer]);
  const options = useMemo(() => {
    return (
      retailers
        ?.filter((retailer) => !onlyActive || retailer.status === CompanyStatus.active)
        ?.map((retailer) => ({ label: retailer.name, value: retailer.id })) || []
    );
  }, [retailers]);

  return (
    <Autocomplete
      isLoading={loading}
      isClearable
      isMulti={false}
      label={noLabel ? undefined : 'Retailer'}
      onFocus={onFocus}
      onChange={handleChange}
      options={options}
      placeholder="Select retailer"
      value={selectedRetailer || null}
      onInputChange={handleSearch} // Handle search here
    />
  );
};
