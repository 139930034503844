import {
  Autocomplete,
  Box,
  Flex,
  type MenuItemProps,
  Text,
  useDisclosure,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Modal,
  HStack,
} from '@swftbox/style-guide';
import { addHours, addMinutes, format } from 'date-fns';
import React, { type FocusEvent, type ReactNode, useState } from 'react';
import {
  type SelectOptionType,
  useAllDriversQuery,
  type DriverWithoutPagination,
} from 'src/components/Particles';
import {
  AccountStatus,
  DriverStatus,
  type Order,
  OrderStage,
} from 'src/components/Particles/resolvers';
import { DriverJobs } from './DriverJobs';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';
import SVG from 'react-inlinesvg';

interface AssignDriverProps extends MenuItemProps {
  id?: string;
  showExistingRoutes?: boolean;
  onAssign: (driverId: string, id?: string) => Promise<boolean>;
  entityName?: string;
  orders?: Order[];
  children?: React.ReactElement;
  openButton?: ReactNode;
  handleSelectedJob?: (jobId: string) => void;
  handleInputDriver?: (driverId: string) => void;
}

const getOneHourEarlierUtcTime = () => {
  const localNow = new Date();
  const oneHourEarlier = addHours(localNow, 1);
  return format(addMinutes(oneHourEarlier, oneHourEarlier.getTimezoneOffset()), 'HH:mm');
};

export function AssignDriver(props: Readonly<AssignDriverProps>) {
  const {
    id,
    onAssign,
    entityName,
    children,
    openButton,
    handleInputDriver,
    handleSelectedJob,
    showExistingRoutes,
    orders,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [driverId, setDriverId] = useState<string>();
  const [isAssigning, setIsAssigning] = useState(false);

  const { drivers } = useAllDriversQuery({
    userStatus: [AccountStatus.ACTIVE],
    status: DriverStatus.ENABLED,
    shiftTimeStart: getOneHourEarlierUtcTime(),
  });

  const driverOptions = drivers?.map((driver: DriverWithoutPagination) => ({
    label:
      driver.user.name +
      ` (${driver.totalActiveJobs} jobs)` +
      ` (${driver.totalActiveOrders} orders)`,
    value: driver.id,
  }));

  const handleClose = () => {
    setDriverId('');
    setIsAssigning(false);
    onClose();
  };

  const onConfirm = async () => {
    if (!driverId) return;
    setIsAssigning(true);
    const isSuccess = await onAssign(driverId, id).finally(() => {
      setIsAssigning(false);
    });
    if (isSuccess) handleClose();
  };

  function onDriverSelected(selectedDriver: SelectOptionType | readonly SelectOptionType[] | null) {
    if (selectedDriver == null) return;
    if (selectedDriver instanceof Array) {
      setDriverId(selectedDriver[0].value);
      if (handleInputDriver) handleInputDriver(selectedDriver[0].value);
    } else {
      setDriverId(selectedDriver.value);
      if (handleInputDriver) handleInputDriver(selectedDriver.value);
    }
  }

  function onAutocompleteFocus(event: FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  let clickableAssignDriverComponent;
  if (children) {
    clickableAssignDriverComponent = React.cloneElement(children, {
      onClick: onOpen,
    });
  }
  if (handleInputDriver)
    return (
      <Autocomplete
        label="Driver"
        onFocus={onAutocompleteFocus}
        onChange={onDriverSelected}
        options={driverOptions}
        placeholder="Select The Driver"
        value={driverOptions?.filter((option) => option.value === driverId) ?? null}
      />
    );

  const internationalOrders =
    orders?.filter((order) => order.stage === OrderStage.lastMile && order.isInternational) ?? [];

  return (
    <>
      {clickableAssignDriverComponent ?? (
        <Button onClick={onOpen} variant="link" _hover={{ textDecor: 'none' }}>
          {openButton}
        </Button>
      )}
      <Modal
        closeOnOverlayClick
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="outside"
        size="lg"
        motionPreset="slideInBottom"
      >
        <section>
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
          <ModalContent>
            <ModalHeader textAlign="center">
              <Box pb="1vh">
                <Text>{entityName ? `Assign ${entityName}` : 'Create Last Mile Job'} </Text>
                {(!entityName || entityName === 'order') && (
                  <Text color="gray.500" fontSize="text-sm" fontWeight="medium">
                    This will auto create a job and assign it to the Driver.
                  </Text>
                )}
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" gap="2vh">
                <Autocomplete
                  label="Driver"
                  onFocus={onAutocompleteFocus}
                  onChange={onDriverSelected}
                  options={driverOptions}
                  placeholder="Select The Driver"
                  value={driverOptions?.filter((option) => option.value === driverId) ?? null}
                />
              </Flex>
              {showExistingRoutes && (
                <DriverJobs driverId={driverId} handleSelectedJob={handleSelectedJob} />
              )}
              {internationalOrders.length > 0 && (
                <HStack
                  border={`1px solid `}
                  borderColor="brand.500"
                  bg="brand.50"
                  borderRadius="8px"
                  p="8px"
                  w="100%"
                  marginBlock={3}
                  alignItems="center"
                >
                  <SVG src={InfoIcon} color="#fbc70091" stroke="#000" />
                  <Text fontWeight="semibold" fontSize="text-sm">
                    You are about to unmark International flag from {internationalOrders.length}{' '}
                    orders. Please note that these will be shipped locally.
                  </Text>
                </HStack>
              )}
            </ModalBody>
            <ModalFooter>
              <Stack spacing="3" w="100%" direction={['column', 'row']}>
                <Button
                  variant="outline"
                  w="100%"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button w="100%" onClick={onConfirm} isLoading={isAssigning}>
                  Confirm
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </section>
      </Modal>
    </>
  );
}
