import { Button, Grid, GridItem, SBInput, Stack, Text, toast } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdatePassword } from 'src/components/Particles/resolvers/setting/ChangePassword';

interface FormValues {
  newPassword: string;
  currentPassword: string;
}

export const ChangePassword = () => {
  const { mutate } = useUpdatePassword({
    onCompleted: (data) => {
      toast.success(data?.changePassword?.message);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
  });

  const onConfirm = async (data: FormValues) => {
    const { currentPassword, newPassword } = data;
    await mutate({
      variables: {
        changePasswordInput: {
          currentPassword,
          newPassword,
        },
      },
    });
  };

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem
        colSpan={12}
        boxShadow="sm"
        bg="#fff"
        border=" 1px solid #EAECF0"
        borderRadius="12px"
      >
        <Grid templateColumns="repeat(12, 1fr)" gap="6" p="6">
          <GridItem colSpan={12}>
            <Text fontSize="text-md" fontWeight="medium" color="gray.700">
              Change Password
            </Text>
            <Text fontSize="text-sm" fontWeight="regular" color="gray.600">
              Update your Password And secure you account.
            </Text>
          </GridItem>

          <GridItem colSpan={12}>
            <Grid templateColumns="repeat(12,1fr)" rowGap="4">
              <GridItem colSpan={12}>
                <SBInput
                  label="Current Password*"
                  placeholder="****"
                  type="password"
                  {...register('currentPassword')}
                  error={errors.currentPassword?.message}
                />
              </GridItem>
              <GridItem colSpan={12}>
                <SBInput
                  label="New Password*"
                  placeholder="****"
                  type="password"
                  {...register('newPassword')}
                  error={errors.newPassword?.message}
                />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>

        <GridItem colSpan={12} px="6" py="4" borderTop="1px solid #EAECF0">
          <Stack spacing="3" direction={['column', 'row']} justifyContent="end">
            <Button onClick={handleSubmit(onConfirm)}>Save changes</Button>
          </Stack>{' '}
        </GridItem>
      </GridItem>
    </Grid>
  );
};
