import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INTEGRATIONS, CREATE_INTEGRATION, UPDATE_INTEGRATION } from './integrations.graphql';
import { Integration, SaveIntegrationInput } from './integrations.types';

interface QueryResponse {
  integrationConfigs: Integration[];
}

interface MutationResponse {
  createIntegrationConfig?: {
    message: string;
  };
  updateIntegrationConfig?: {
    message: string;
  };
}

export function useIntegrationsQuery() {
  const [query, { data, loading }] = useLazyQuery<QueryResponse, { retailerId: string }>(GET_INTEGRATIONS);

  async function getIntegrations(retailerId: string) {
    return query({ variables: { retailerId } });
  }

  return {
    getIntegrations,
    data: data?.integrationConfigs ?? [],
    loading,
  };
}

export function useSaveIntegrationMutation() {
  const [create, { loading: creating }] = useMutation<MutationResponse>(CREATE_INTEGRATION);
  const [update, { loading: updating }] = useMutation<MutationResponse>(UPDATE_INTEGRATION);

  async function saveIntegration(saveIntegrationInput: SaveIntegrationInput) {
    return saveIntegrationInput.id ? update({
      variables: {
        updateIntegrationConfigInput: saveIntegrationInput,
      },
      refetchQueries: ['RetailerIntegrations'],
    }) : create({
      variables: {
        createIntegrationConfigInput: saveIntegrationInput,
      },
      refetchQueries: ['RetailerIntegrations'],
    });
  }

  return {
    saveIntegration,
    saving: creating || updating,
  };
}
