import { useReactiveVar } from '@apollo/client';
import { Box, HStack, Text, VStack } from '@swftbox/style-guide';
import { useMemo } from 'react';
import { DropProfileType, formatDate, type Order, pendingSLALabel } from 'src/components/Particles';
import { hashMapVar } from 'src/components/Particles/resolvers/orders/getOrderMileStones';
import { RescheduleModal } from './RescheduleModal';
import { UpdateDropProfile } from '../UpdateDropProfile';

interface TimingProps {
  order: Order;
  showReschedule?: boolean;
  align?: string;
}

export const Timing = ({ order, showReschedule = true, align = 'end' }: TimingProps) => {
  const hashMap = useReactiveVar(hashMapVar);
  const milestone = useMemo(() => hashMap[order.id], [hashMap]);
  const isStandard = order.dropProfile?.profileType === DropProfileType.standard;

  return (
    <VStack alignItems="flex-start" w="fit-content" minH="89px">
      {showReschedule && <RescheduleModal order={order} />}
      <HStack justifyContent={align} fontSize="9px" w="100%" color="gray.900">
        <Text w="50px">SLA</Text>
        {!order?.timing?.slaStart && <Text> {pendingSLALabel(order)} </Text>}
        {order?.timing?.slaStart && (
          <>
            <Text fontSize="x-small">
              {isStandard ? (
                <Text as="span">Before {formatDate(order?.timing?.slaEnd, 'HH:mm')}</Text>
              ) : (
                <Text as="span">
                  {formatDate(order?.timing?.slaStart, 'HH:mm')} -{' '}
                  {formatDate(order?.timing?.slaEnd, 'HH:mm')}
                </Text>
              )}
            </Text>
            <Text fontSize="x-small">{formatDate(order?.timing?.slaEnd, 'MMM dd, yyyy')}</Text>
          </>
        )}
      </HStack>
      {!!order.timing.expectedPickupDateStart && (
        <HStack justifyContent={align} fontSize="9px" minW="210px" w="100%" color="gray.900">
          <Text w="50px">Pickup</Text>
          <Text fontSize="x-small">
            {formatDate(order.timing.expectedPickupDateStart, 'HH:mm')} -{' '}
            {formatDate(order.timing.expectedPickupDateEnd, 'HH:mm')}
          </Text>
          <Text fontSize="x-small">
            {formatDate(order.timing.expectedPickupDateEnd, 'MMM dd, yyyy')}
          </Text>
        </HStack>
      )}
      <HStack justifyContent={align} fontSize="9px" w="100%" color="gray.900">
        <Text w="50px">Created</Text>
        <Text fontSize="x-small"> {formatDate(order?.timing.inputDate, 'HH:mm MMM dd, yyyy')}</Text>
      </HStack>
      <HStack justifyContent={align} fontSize="9px" w="100%" color="gray.900">
        <Text w="50px">Type</Text>
        {order.isReverse ? (
          <Text fontSize="x-small">Return</Text>
        ) : (
          <UpdateDropProfile order={order} />
        )}
      </HStack>

      {!!milestone?.isMilestone && (
        <HStack justifyContent={align} fontSize="9px" w="100%" color="gray.900" alignItems="start">
          <Text w="50px" textTransform="capitalize">
            {milestone?.action}
          </Text>
          <Box>
            <Text>{formatDate(milestone?.date, 'HH:mm MMM dd, yyyy')}</Text>
            {!!milestone?.driver && <Text>({milestone?.driver?.name})</Text>}
          </Box>
        </HStack>
      )}
    </VStack>
  );
};
