export function formatStatus(inputString: string): string {
  if (!inputString) return '';

  if (inputString.includes('_')) {
    // Case for words separated by underscore
    const words = inputString.split('_');
    const firstWord = words[0].toUpperCase();

    if (firstWord === 'RTN' || firstWord === 'RTO' || firstWord === 'IN') {
      // Handle special case for RTN, RTO, or IN
      return (
        firstWord +
        ' ' +
        words
          .slice(1)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')
      );
    } else {
      // Standard case for words separated by underscore
      const camelCaseWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return camelCaseWords.join(' ');
    }
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}
