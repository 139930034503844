import { memo } from 'react';
import { EditCustomerLocation } from 'src/components/Pages/Orders/Components/Modal';

const CellModals = () => {
  return (
    <>
      <EditCustomerLocation />
    </>
  );
};

export default memo(CellModals);
