import { Td } from '@swftbox/style-guide';
import type { Order } from 'src/components/Particles';
import { Timing } from '../Modal';
import { DispatchPlan, DropAddress, PickupAddress, Reference } from '../OrderTableColumns';
import { memo } from 'react';
import CellMenu from './CellMenu';

interface CellSelectedProps {
  order: Order;
  performSyncOrders: (order: Order) => void;
}
const OrderTableCell = ({ order, performSyncOrders }: CellSelectedProps) => {
  return (
    <>
      <Td px="3">
        <Reference order={order} />
      </Td>
      <Td px="3">
        <DispatchPlan order={order} />
      </Td>
      <Td px="3">
        <Timing showReschedule={false} order={order} align="start" />
      </Td>
      <Td px="3">
        <PickupAddress order={order} />
      </Td>
      <Td px="3">
        <DropAddress order={order} />
      </Td>

      <Td pr="0" />
      <CellMenu order={order} performSyncOrders={performSyncOrders} />
    </>
  );
};

export default memo(OrderTableCell);
