import { Box } from '@swftbox/style-guide';
import { usePermissionsQuery, useRolesQuery } from 'src/components/Particles';
import { RolesList } from './Components';
import { useEffect } from 'react';

export const RolesAndPermissions = () => {
  const { roles, getRoles } = useRolesQuery();
  const { permissions, getPermissions } = usePermissionsQuery();

  useEffect(() => {
    getRoles({ withPermissions: true });
    getPermissions();
  }, []);

  return (
    <>
      <Box pb="8">
        <RolesList roles={roles} permissions={permissions} />
      </Box>
    </>
  );
};
