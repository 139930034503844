import { Box, Map as GoogleMap, MapMarker, MapPath } from '@swftbox/style-guide';
import { Job, useJobAdherence } from 'src/components/Particles';
import { Config } from 'src/config';

interface DriverAdherenceProps {
  job: Job;
}

export const DriverAdherence = ({ job }: DriverAdherenceProps) => {

  const {
    timelineWithAdherence, actualPath, plannedPath,
  } = useJobAdherence(job);

  return (
    <Box position="relative" height="95%">
      <GoogleMap
        transition="all 0.3s"
        h="100%"
        center={Config.DEFAULT_MAP_CENTER}
        zoom={10}
        zoomControl={false}
      >
        <MapPath
          key="planned"
          inProgress={false}
          path={plannedPath}
        />

        <MapPath
          key="actual"
          inProgress={false}
          path={actualPath}
        />

        {timelineWithAdherence.map((item) => {
          return (
            <MapMarker
              key={item.id}
              // @ts-ignore-next-line
              label={item.stop?.sequence.toString()}
              position={{
                lat: +item?.stop?.coordinates?.latitude,
                lng: +item?.stop?.coordinates?.longitude,
              }}
            />
          );
        })}
      </GoogleMap>
    </Box>
  );
};
