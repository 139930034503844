import { useMutation } from '@apollo/client';
import {
  FAIL_RETURN_ORDERS,
  FILTER_ORDERS,
} from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type clientOptionType } from '../SharedTypes';
import { type Order } from './orders.types';

interface FailReturnResponse {
  message: string;
  data: Order[];
}

interface FailedReturnInput {
  orderIds: string[];
  reason: string;
}

interface FailReturnProps {
  payload: {
    failedReturnInput: FailedReturnInput;
  };
  onCompleted: (
    data: { failReturnOrders: FailReturnResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useFailReturn() {
  const [mutate, { loading, error }] = useMutation<
    { failReturnOrders: FailReturnResponse },
    { failedReturnInput: FailedReturnInput }
  >(FAIL_RETURN_ORDERS);

  async function failReturnOrders({ payload, onCompleted }: FailReturnProps) {
    await mutate({
      variables: payload,
      onCompleted,
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
      ],
    });
  }

  return {
    failReturnOrders,
    loading,
    error,
  };
}
