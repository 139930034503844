import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  SBTextArea,
  SlideFade,
  Stack,
  Text,
  toast,
  Image,
  SBTrashIcon,
} from '@swftbox/style-guide';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import SBEmailICon from 'src/assets/SBFileUploadIcon.svg';
import {
  type CreateProcedureInput,
  type UpdateProcedureInput,
  useCreateRetailerProcedureMutation,
  useGetUploadUrlQuery,
  useUpdateRetailerProcedureMutation,
  uploadFile,
} from 'src/components/Particles';
import {
  type ProcedureSettingsProps,
  ProcedureTypes,
  type DropzoneProps,
  type DropzoneFiles,
} from '../../../../Components/Modals/Validation/types';
import {
  addFile,
  prepareFilesData,
  removeFile,
  resetSection,
} from '../../../../Components/Modals/Validation/helper';
import { MediaContainer } from 'src/components/Organisms';

export const ProcedureSettings = ({ retailer }: ProcedureSettingsProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    watch,
    setValue,
  } = useForm<{ procedures: CreateProcedureInput[] | UpdateProcedureInput[] }>();

  const procedures = watch('procedures');

  useEffect(() => {
    const allProcedures = ProcedureTypes.map((type) => {
      const existProcedure = retailer.procedures.find((procedure) => procedure.type === type);
      if (existProcedure) {
        return existProcedure;
      } else {
        return {
          type,
        };
      }
    }) as any;
    reset({ procedures: allProcedures });
  }, [retailer, reset]);

  const { getUploadUrl } = useGetUploadUrlQuery();
  const { createProcedure, loading: createLoading } = useCreateRetailerProcedureMutation();
  const { updateProcedure, loading: updateLoading } = useUpdateRetailerProcedureMutation();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [dropzoneFiles, setDropzoneFiles] = useState<DropzoneFiles>({});

  const onImageDrop = useCallback((index: number, acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    setDropzoneFiles((prevState) => addFile(prevState, index, acceptedFiles, 'imageFiles'));
  }, []);

  const onVideoDrop = useCallback((index: number, acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return;
    setDropzoneFiles((prevState) => addFile(prevState, index, acceptedFiles, 'videoFiles'));
  }, []);

  const uploadFiles = useCallback(
    async (index: number) => {
      if (!Object.keys(dropzoneFiles).length) return;

      setIsUploadingFiles(true);

      const { imagesUploadArgs, videosUploadArgs } = prepareFilesData(dropzoneFiles, index);

      if (!imagesUploadArgs && !videosUploadArgs) {
        setIsUploadingFiles(false);
        return;
      }

      let uploadImagesPromises: Array<Promise<any>> = [];
      const signedImagesUploadURLs: Array<{ filePath: string; url: string } | undefined> = [];
      if (imagesUploadArgs) {
        for (const imageArgs of imagesUploadArgs) {
          const uploadUrl = await getUploadUrl(imageArgs);
          signedImagesUploadURLs.push(uploadUrl);
        }

        if (!signedImagesUploadURLs.length) {
          setIsUploadingFiles(false);
          return;
        }

        uploadImagesPromises = dropzoneFiles[index].imageFiles.flatMap(async (image, i) => {
          if (!signedImagesUploadURLs[i]) return [];
          return uploadFile(signedImagesUploadURLs[i].url, image);
        });
      }

      let uploadVideosPromises: Array<Promise<any>> = [];
      const signedVideosUploadURLs: Array<{ filePath: string; url: string } | undefined> = [];
      if (videosUploadArgs) {
        for (const videoArgs of videosUploadArgs) {
          const uploadUrl = await getUploadUrl(videoArgs);
          signedVideosUploadURLs.push(uploadUrl);
        }

        if (!signedVideosUploadURLs.length) {
          setIsUploadingFiles(false);
          return;
        }

        uploadVideosPromises = dropzoneFiles[index].videoFiles.flatMap(async (video, i) => {
          if (!signedVideosUploadURLs[i]) return [];
          return uploadFile(signedVideosUploadURLs[i].url, video);
        });
      }

      const areUploaded = await Promise.all([
        ...uploadImagesPromises,
        ...uploadVideosPromises,
      ]).catch(() => false);

      setIsUploadingFiles(false);

      if (!areUploaded) {
        toast.error('Cannot upload your files');
        return;
      }
      return {
        images: signedImagesUploadURLs.flatMap((image) => image?.filePath ?? []),
        videos: signedVideosUploadURLs.flatMap((video) => video?.filePath ?? []),
      };
    },
    [dropzoneFiles, getUploadUrl]
  );

  const validatePayload = useCallback(
    (payload: CreateProcedureInput | UpdateProcedureInput, index: number) => {
      if (!payload.description) {
        setError(`procedures.${index}.description`, { message: "description can't be empty" });
        return false;
      }
      return true;
    },
    [setError]
  );

  const handleFinish = useCallback((index: number, message: string) => {
    setDropzoneFiles((prevState) => resetSection(prevState, index));
    toast.success(message);
  }, []);

  const onCreateProcedure = useCallback(
    (payload: CreateProcedureInput, index: number) => {
      const isValid = validatePayload(payload, index);
      if (isValid) {
        void createProcedure({
          payload: { ...payload, retailerId: retailer.id },
          onCompleted: (message) => {
            message && handleFinish(index, `${payload.type} SOPs created successfully`);
          },
        });
      }
    },
    [createProcedure, handleFinish, retailer.id, validatePayload]
  );

  const onUpdateProcedure = useCallback(
    (payload: UpdateProcedureInput, index: number) => {
      const isValid = validatePayload(payload, index);
      if (isValid) {
        void updateProcedure({
          payload: { ...payload, retailerId: retailer.id },
          onCompleted: (message) => {
            message && handleFinish(index, `${payload.type} SOPs updated successfully`);
          },
        });
      }
    },
    [handleFinish, retailer.id, updateProcedure, validatePayload]
  );

  const onSave = useCallback(
    async (index: number, isUpdate: boolean) => {
      const uploadedFiles = await uploadFiles(index);

      if (uploadedFiles) {
        const updatedProcedure = {
          ...procedures[index],
          images: [...(procedures?.[index].images ?? []), ...uploadedFiles.images],
          videos: [...(procedures?.[index].videos ?? []), ...uploadedFiles.videos],
        };
        setValue(`procedures.${index}`, updatedProcedure);
      }

      if (isUpdate) {
        void handleSubmit((data: any) => {
          onUpdateProcedure(data.procedures[index] as UpdateProcedureInput, index);
        })();
      } else {
        void handleSubmit((data: any) => {
          onCreateProcedure(data.procedures[index] as CreateProcedureInput, index);
        })();
      }
    },
    [handleSubmit, onCreateProcedure, onUpdateProcedure, procedures, setValue, uploadFiles]
  );

  const onRemoveExistFile = useCallback(
    (index: number, fileUrl: string, fileType: 'images' | 'videos') => {
      const currentProcedure = procedures?.[index];

      if (!currentProcedure) return;
      const updatedFiles =
        currentProcedure[fileType]?.filter((url: string) => url !== fileUrl) ?? [];

      setValue(`procedures.${index}.${fileType}`, updatedFiles);
    },
    [procedures, setValue]
  );

  return (
    <Box overflowY="auto" px={3}>
      <SlideFade
        in
        exit={{ opacity: 0, translateX: '-1000px' }}
        transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
        unmountOnExit
      ></SlideFade>
      {procedures?.map((procedure, index) => {
        return (
          <form
            key={procedure.type}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Text
              color="gray.600"
              fontSize="text-sm"
              fontWeight="semibold"
              textTransform={'capitalize'}
              my="1"
            >
              {`${procedure.type.toLowerCase()} SOPs`}
            </Text>
            <Box border="1px solid #D0D5DD" bg="gray.100" p="15" borderRadius="8px" mb="4">
              <HStack w="100%" alignItems="start" justifyContent="center" color="gray.700">
                <Stack w="50%">
                  <SBTextArea
                    placeholder="please write detailed instructions..."
                    label="Description"
                    {...register(`procedures.${index}.description`)}
                    error={errors?.procedures?.[index]?.description?.message}
                  />
                </Stack>

                <Box w="25%">
                  <Text mb="1.5" fontWeight="medium">
                    Images
                  </Text>
                  <Dropzone
                    index={index}
                    onDropFiles={onImageDrop}
                    options={{
                      maxFiles: 1,
                      accept: {
                        'image/*': ['.jpeg', '.png'],
                      },
                    }}
                  />
                  <Grid gap="1" gridTemplateColumns="repeat(auto-fit, minmax(48px, 31.5%))" my="2">
                    {procedure.images
                      ?.filter((url) => url.includes('https'))
                      .map((image, i) => (
                        <GridItem
                          display="flex"
                          key={i}
                          justifyContent="center"
                          alignItems="center"
                          gap="0.75"
                        >
                          {' '}
                          <MediaContainer
                            key={`${image}+${i}`}
                            mediaHref={image}
                            mediaType="image"
                            mediaDescription={`image #${i + 1}`}
                            openButton={
                              <Image
                                boxSize={'30px'}
                                objectFit={'contain'}
                                src={image}
                                alt={`image #${i + 1}`}
                              />
                            }
                          />
                          <SBTrashIcon
                            onClick={() => {
                              onRemoveExistFile(index, image, 'images');
                            }}
                            width="15px"
                            cursor="pointer"
                          />
                        </GridItem>
                      ))}
                    {dropzoneFiles[index]?.imageFiles?.map((file, i) => (
                      <GridItem
                        display="flex"
                        key={i}
                        justifyContent="center"
                        alignItems="center"
                        gap="0.75"
                      >
                        <MediaContainer
                          key={`${file.name}+${i}`}
                          mediaHref={URL.createObjectURL(file)}
                          mediaType="image"
                          mediaDescription={file.name}
                          openButton={
                            <Image
                              boxSize={'30px'}
                              objectFit={'contain'}
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                            />
                          }
                        />
                        <SBTrashIcon
                          onClick={() => {
                            setDropzoneFiles((prevState) =>
                              removeFile(prevState, index, file, 'imageFiles')
                            );
                          }}
                          width="15px"
                          cursor="pointer"
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </Box>
                <Box w="25%">
                  <Text mb="1.5" fontWeight="medium">
                    Videos
                  </Text>
                  <Dropzone
                    index={index}
                    onDropFiles={onVideoDrop}
                    options={{
                      maxFiles: 1,
                      accept: {
                        'video/mp4': ['.mp4'],
                      },
                    }}
                  />
                  <Grid gap="1" gridTemplateColumns="repeat(auto-fit, minmax(48px, 31.5%))" my="2">
                    {procedure.videos
                      ?.filter((url) => url.includes('https'))
                      .map((video, i) => (
                        <GridItem
                          display="flex"
                          key={i}
                          justifyContent="center"
                          alignItems="center"
                          gap="0.75"
                        >
                          {' '}
                          <MediaContainer
                            key={`${video}+${i}`}
                            mediaHref={video}
                            mediaType="video"
                            mediaDescription={`video #${i + 1}`}
                            openButton={
                              <Box>
                                <video width="30" height="30">
                                  <source src={video} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </Box>
                            }
                          />
                          <SBTrashIcon
                            onClick={() => {
                              onRemoveExistFile(index, video, 'videos');
                            }}
                            width="15px"
                            cursor="pointer"
                          />
                        </GridItem>
                      ))}
                    {dropzoneFiles[index]?.videoFiles?.map((file, i) => (
                      <GridItem
                        display="flex"
                        key={i}
                        justifyContent="center"
                        alignItems="center"
                        gap="0.75"
                      >
                        <MediaContainer
                          key={`${file.name}+${i}`}
                          mediaHref={URL.createObjectURL(file)}
                          mediaType="video"
                          mediaDescription={file.name}
                          openButton={
                            <Box>
                              <video width="30" height="30">
                                <source src={URL.createObjectURL(file)} type="video/mp4" />
                                {file.name}
                              </video>
                            </Box>
                          }
                        />
                        <SBTrashIcon
                          onClick={() => {
                            setDropzoneFiles((prevState) =>
                              removeFile(prevState, index, file, 'videoFiles')
                            );
                          }}
                          width="15px"
                          cursor="pointer"
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </Box>
              </HStack>
              <Stack w="100%" alignItems="end">
                <Button
                  w="20%"
                  onClick={() => {
                    void onSave(index, 'id' in procedure);
                  }}
                  isDisabled={createLoading || updateLoading}
                  isLoading={isUploadingFiles}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        );
      })}
    </Box>
  );
};

const Dropzone = ({ index, onDropFiles, options }: DropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDropFiles(index, acceptedFiles);
    },
    [index, onDropFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, ...options });

  return (
    <Box
      {...getRootProps()}
      h="10%"
      w="100%"
      border="1px solid #D0D5DD"
      background="#fff"
      borderRadius="8px"
      p="16px"
      textAlign="center"
      cursor="pointer"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Text>Drop the files here ...</Text>
      ) : (
        <HStack w="100%" h="10px" justifyContent="center">
          <Box fontSize="x-small">Click To Upload</Box>
          <Box>
            <SVG src={SBEmailICon} />
          </Box>
        </HStack>
      )}
    </Box>
  );
};
