import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  SBInput,
  SBTextArea,
  Text,
  VStack,
  toast,
} from '@swftbox/style-guide';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  BillingInfo,
  InvoiceGenerationType,
  useUpdateRetailerMutation,
} from 'src/components/Particles';
import { isEqual } from '../../../Helper';
import { SectionRow, SubmitAndCancel } from '../../Shared';

interface PartnerSettingsProps {
  id: string;
  billingInfo: BillingInfo;
  defaultInvoiceType: InvoiceGenerationType;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
}

interface FormValues {
  id: string;
  billingInfo: BillingInfo;
  retailerConfiguration: {
    defaultInvoiceType: InvoiceGenerationType;
  };
}

export function BillingInfoSettings(props: PartnerSettingsProps) {
  const { id, defaultInvoiceType, billingInfo, setAllowToMove } = props;
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      id,
      ...(!!billingInfo && { billingInfo }),
      ...(!!defaultInvoiceType && { retailerConfiguration: { defaultInvoiceType } }),
    },
  });

  const { updateRetailer, loading } = useUpdateRetailerMutation();

  const onSubmit = handleSubmit(async (data) => {
    await updateRetailer({
      id: data.id,
      billingInfo: {
        address: data.billingInfo.address,
        contactName: data.billingInfo.contactName,
        email: data.billingInfo.email,
        phone: data.billingInfo.phone,
      },
      retailerConfiguration: {
        defaultInvoiceType: data.retailerConfiguration.defaultInvoiceType,
      },
    }).then((data) => {
      reset(getValues());
      toast.success('Retailer settings updated successfully');
    });
  });

  const watchedValues = watch();
  const hasChanges =
    isDirty &&
    (!isEqual(watchedValues.billingInfo?.email, billingInfo?.email) ||
      !isEqual(watchedValues.billingInfo?.address, billingInfo?.address) ||
      !isEqual(watchedValues.billingInfo?.phone, billingInfo?.phone) ||
      !isEqual(watchedValues.billingInfo?.contactName, billingInfo?.contactName) ||
      !isEqual(watchedValues.retailerConfiguration?.defaultInvoiceType, defaultInvoiceType));

  useEffect(() => {
    if (hasChanges) {
      setAllowToMove({ page: '', isAllowed: false });
    } else {
      setAllowToMove({ page: '', isAllowed: true });
    }
  }, [hasChanges]);
  return (
    <Box as="form" onSubmit={onSubmit} h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
        <Box>
          <SectionRow
            title="Billing Details"
            description="These contact details will be used for invoicing and any billing related communication."
            children={
              <>
                <Controller
                  name="billingInfo.contactName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="text"
                      value={value || ''}
                      onChange={onChange}
                      placeholder="ex: swftbox"
                      crossOrigin
                      label="Billing Contact Name"
                    />
                  )}
                />
                <Controller
                  name="billingInfo.email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="text"
                      value={value || ''}
                      onChange={onChange}
                      placeholder="ex: swftbox@swftbox.com"
                      crossOrigin
                      label="Billing Email"
                    />
                  )}
                />
                <Controller
                  name="billingInfo.phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="text"
                      value={value || ''}
                      onChange={onChange}
                      placeholder="ex: +975*****"
                      crossOrigin
                      label="Billing Phone Number"
                    />
                  )}
                />
                <Controller
                  name="billingInfo.address"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBTextArea
                      value={value || ''}
                      onChange={onChange}
                      placeholder="ex: Dubai"
                      rows={3}
                      label="Billing Address"
                    />
                  )}
                />
                <Box w={'100%'} flexShrink="0">
                  <FormLabel color="gray.700" fontWeight="semibold" fontSize="text-sm">
                    Bulk Invoice Generation
                  </FormLabel>
                </Box>
                <Controller
                  name="retailerConfiguration.defaultInvoiceType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <RadioGroup value={value} onChange={onChange}>
                        <VStack w="100%" alignItems="flex-start">
                          <Radio
                            key="general-invoice"
                            value={InvoiceGenerationType.GENERAL}
                            marginRight={10}
                          >
                            <Text fontSize={14}>General Invoice</Text>
                            <Text fontSize={10} color="#98A2B2">
                              Generate a single invoice that includes all service payment lines
                            </Text>
                          </Radio>
                          <Radio
                            key="services-collection-invoices"
                            value={InvoiceGenerationType.SERVICES_COLLECTION}
                            marginRight={10}
                          >
                            <Text fontSize={14}>Service & Collection Invoices</Text>
                            <Text fontSize={10} color="#98A2B2">
                              Generate two invoices, one for service lines, and another for payment
                              collected
                            </Text>
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </>
            }
          />

          {/* <SectionRow
            title="Email Address"
            children={
              <Controller
                name="billingInfo.email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SBInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                    placeholder="ex: swftbox@swftbox.com"
                    crossOrigin
                  />
                )}
              />
            }
          />

          <SectionRow
            title="Phone Number"
            children={
              <Controller
                name="billingInfo.phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SBInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                    placeholder="ex: +975*****"
                    crossOrigin
                  />
                )}
              />
            }
          />
          <SectionRow
            title="Billing Address"
            children={
              <Controller
                name="billingInfo.address"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SBTextArea
                    value={value || ''}
                    onChange={onChange}
                    placeholder="ex: Dubai"
                    rows={3}
                  />
                )}
              />
            }
          /> */}
        </Box>

        <SubmitAndCancel isHidden={hasChanges} reset={reset} loading={loading} />
      </VStack>
    </Box>
  );
}
