import { HStack, Stack, toast, Text } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { type Order, useOrderModalContext } from 'src/components/Particles';
import { useForceBackToRequested } from 'src/components/Particles/resolvers/orders';
import SVG from 'react-inlinesvg';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';

export function ForceBackToRequested({ formRef, handleClose }: { formRef: any; handleClose: any }) {
  const { data: order } = useOrderModalContext() as {
    forceActionsIsOpen: boolean;
    data: Order;
  };
  const { forceBackToRequestedOrders } = useForceBackToRequested();
  const { handleSubmit } = useForm({
    mode: 'all',
  });

  function onConfirm() {
    void forceBackToRequestedOrders({
      payload: {
        swftboxTracking: order.swftboxTracking,
      },
      onCompleted: (message) => {
        if (message) {
          toast.success(message);
          handleClose();
        }
      },
    });
  }
  const existingPayment =
    order?.payment && ['APPROVED', 'PENDING'].includes(order?.payment?.status);

  return (
    <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={4}>
      {!!existingPayment && (
        <HStack
          border={`1px solid #FF8EAC`}
          boxShadow="xs"
          borderRadius="8px"
          p="8px"
          w="100%"
          bg=" #FF8EAC"
          marginBlock={3}
        >
          <SVG src={InfoIcon} color=" #FF8EAC" stroke="#000" width={'40px'} height={'40px'} />
          <Text fontWeight="semibold" fontSize="text-sm">
            There's an existing PENDING or APPROVED payment for this order. To proceed, please
            reject the transaction first on the payments page.
          </Text>
        </HStack>
      )}
      <button ref={formRef} disabled={existingPayment} type="submit" style={{ display: 'none' }}>
        Test
      </button>
    </Stack>
  );
}
