import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SBAddIcon,
  SBUserAddIcon,
  Stack,
  Text,
  useDisclosure,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import {
  type CreateUserPayload,
  useCreateUserMutation,
  useRolesQuery,
} from 'src/components/Particles';
import { UserForm } from './UserForm';
import { CreateUserSchema } from '../../Validation';
import { useEffect } from 'react';

export function AddUser() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createUser, user, reset: resetMutation } = useCreateUserMutation();

  const { roles: allRoles, getRoles } = useRolesQuery();
  useEffect(() => {
    getRoles({ withPermissions: true });
  }, []);

  const rolesOptions = allRoles.map((role) => ({ label: role.name, value: role.name }));
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<CreateUserPayload>({
    resolver: yupResolver(CreateUserSchema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
    },
  });

  async function onConfirm(data: CreateUserPayload) {
    await createUser(data);
  }

  function handleClose() {
    resetMutation();
    reset();
    onClose();
  }

  if (user) {
    handleClose();
  }

  return (
    <>
      <Button onClick={onOpen} fontSize="x-small">
        <SBAddIcon width="15px" /> Add User
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        scrollBehavior="outside"
        size="xl"
        motionPreset="slideInBottom"
        closeOnEsc
      >
        <section>
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
          <ModalContent>
            <ModalHeader textAlign="center">
              <Box pb="1vh">
                <Box
                  mb="4"
                  boxShadow="xs"
                  border="1px solid #eaeCF0"
                  w="48px"
                  h="48px"
                  borderRadius="10px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bg="#fbc70091"
                  color="black"
                >
                  <SBUserAddIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
                </Box>
                <Text>Add User</Text>
                <Text fontWeight="normal" as="sub">
                  Assign proper roles and permissions to align with the scope of responsibilities
                  for the user
                </Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <UserForm
                onDone={onConfirm}
                register={register}
                handleSubmit={handleSubmit}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                roles={rolesOptions}
              />
            </ModalBody>
            <ModalFooter>
              <Stack spacing="3" w="100%" direction={['column', 'row']}>
                <Button variant="outline" w="100%" onClick={handleClose}>
                  Cancel
                </Button>
                <Button w="100%" onClick={handleSubmit(onConfirm)}>
                  Confirm
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </section>
      </Modal>
    </>
  );
}
