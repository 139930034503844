import { Box, Grid, SlideFade } from '@swftbox/style-guide';
import FormSide from './Components/FormSide';
import InfoSide from './Components/InfoSide';

import style from './style.module.scss';

export const Login = () => {
  return (
    <Grid gridTemplate={{ base: '100vh / 100% 50%', lg: '100vh / 50% 50%' }} overflow="hidden">
      <SlideFade
        transition={{ enter: { duration: 0.5 } }}
        offsetY="0px"
        offsetX="-1000px"
        in
        unmountOnExit
      >
        <Box className={style.formside}>
          <FormSide />
        </Box>
      </SlideFade>
      <SlideFade
        transition={{ enter: { duration: 0.5 } }}
        offsetY="0px"
        offsetX="1000px"
        unmountOnExit
        in
      >
        <Box className={style.infoside} display={{ base: 'none', lg: 'flex' }}>
          <InfoSide />
        </Box>
      </SlideFade>
    </Grid>
  );
};
