import { Box, HStack, Text, VStack } from '@swftbox/style-guide';
import { formatDate } from 'src/components/Particles';
import HomeTypeIcon from 'src/assets/icons/orders/home.svg';
import SVG from 'react-inlinesvg';
import { memo } from 'react';
interface SchedulingCardProps {
  start: any;
  end: any;
  slaStart?: any;
  slaEnd?: any;
  clickable?: boolean;
}

export const SchedulingCard = memo(
  ({ start, end, slaStart, slaEnd, clickable = true }: SchedulingCardProps) => {
    return (
      <VStack alignItems="flex-start" w="fit-content" h="45px">
        <Box bg={clickable ? '#EFEFEF' : 'transparent'} p="1" borderRadius="4" w="max-content">
          <HStack>
            <SVG src={HomeTypeIcon} />

            <Text textDecoration="underline" fontWeight="bold">
              {start && `${formatDate(start as string, 'HH:mm')} -`}{' '}
              {formatDate(end as string, 'HH:mm')}
            </Text>
            <Text fontSize="x-small" fontWeight="semibold" pe="8px">
              {formatDate(end as string, 'MMM dd, yyyy')}
            </Text>
          </HStack>
        </Box>
        <HStack justifyContent="end" fontSize="8px" w="100%" color="gray.500">
          <Text>SLA</Text>
          <Text>
            {formatDate((slaStart || start) as string, 'HH:mm')} -{' '}
            {formatDate((slaEnd || end) as string, 'HH:mm')}
          </Text>
          <Text>{formatDate((slaEnd || end) as string, 'MMM dd, yyyy')}</Text>
        </HStack>
        {/* <HStack justifyContent="end" fontSize="8px" w="100%" color="gray.500">
        <Text>
          Created At:
          {formatDate((slaEnd || end) as string, 'HH:mm')}
        </Text>
      </HStack> */}
      </VStack>
    );
  }
);
