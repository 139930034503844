import { Box, Button, SBModal, Stack, Text } from '@swftbox/style-guide';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { StepperHistory } from 'src/components/Organisms';
import { useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import { EditCustomerAddressType } from 'src/components/Particles/Modules/OrderModule/types';
import { useAddressHistory } from 'src/components/Particles/resolvers/orders/AddressHistory';
import { actionAddressHistory, actionAddressTitle } from './Helper';

export function AddressHistoryModal() {
  const { addressHistoryIsOpen, data } = useOrderModalContext() as {
    addressHistoryIsOpen: boolean;
    data: EditCustomerAddressType;
  };
  const dispatch = useOrderModalActions();
  const { getOrderTimeline, loading } = useAddressHistory();
  const [steps, setSteps] = useState<any[]>([]);
  useEffect(() => {
    if (data?.id) {
      getOrderTimeline({
        variables: {
          locationId: data?.id,
        },
        onCompleted: (data) => {
          console.log(data);
          setSteps(
            data?.locationTimelines
              ?.map((step) => ({
                id: uuid(),
                status:
                  step?.locationUpdates?.length >= 2
                    ? 'Multi Update'
                    : actionAddressTitle[step.action],
                name:
                  step.action === 'UPDATED_MANUALLY'
                    ? step?.user?.name || 'Customer'
                    : actionAddressHistory[step.action],
                type: step.updatedAt ? format(new Date(step.updatedAt), 'hh:mm MMM dd, yyyy') : '-',
                orderUpdates: step.locationUpdates,
              }))
              .reverse()
          );
        },
      });
    }
  }, [data]);
  const handleCloseModal = () => {
    dispatch({ type: 'CLOSE_ADDRESS_HISTORY' });
    setSteps([]);
  };
  return (
    <>
      <SBModal
        isOpen={addressHistoryIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Text>Address History</Text>
          </Box>
        }
        body={
          <>
            {!steps.length && (
              <Box
                fontSize="text-md"
                textAlign="center"
                mt="2"
                fontWeight="bold"
                color="gray.600"
                fontStyle="italic"
              >
                {!loading ? 'No History To Display' : 'Loading...'}
              </Box>
            )}
            {!loading && !!steps.length && <StepperHistory steps={steps} loading={false} />}
          </>
        }
        size="3xl"
        bordered={false}
        footer={<div />}
      />
    </>
  );
}
