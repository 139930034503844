import { type ReactNode, useCallback, useState } from 'react';
import { FulfillmentOrdersSelectionContext } from '../../Contexts';
import { type Order } from '../../resolvers';

export function FulfillmentOrdersSelectionProvider({ children }: { children: ReactNode }) {
  const [selectedOrders, setSelectedOrders] = useState<Map<string, Order>>(new Map());
  const [detailedOrder, setDetailedOrder] = useState<Order>();

  const select = useCallback(
    (order: Order) => {
      const newSelections = new Map([...selectedOrders.entries(), [order.id, order]]);
      setSelectedOrders(newSelections);
    },
    [selectedOrders]
  );

  const unselect = useCallback(
    (id: string) => {
      const updatedSelectedOrders: Array<[string, Order]> = [];
      for (const [selectedId, order] of selectedOrders.entries()) {
        if (selectedId !== id) {
          updatedSelectedOrders.push([selectedId, order]);
        }
      }
      setSelectedOrders(new Map(updatedSelectedOrders));
    },
    [selectedOrders]
  );

  const unselectAll = useCallback(() => {
    setSelectedOrders(new Map());
  }, []);

  const toggleDetailedOrder = useCallback(
    (order?: Order) => {
      setDetailedOrder(order);
    },
    [detailedOrder]
  );

  return (
    <FulfillmentOrdersSelectionContext.Provider
      value={{ selectedOrders, select, unselect, unselectAll, toggleDetailedOrder, detailedOrder }}
    >
      {' '}
      {children}{' '}
    </FulfillmentOrdersSelectionContext.Provider>
  );
}
