import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useCategories } from 'src/components/Particles';
import CategoriesHeader from './Components/CategoriesHeader';
import CategoriesTable from './Components/CategoriesTable';
import style from './style.module.scss';

export const Categories = () => {
  const { categories, loading } = useCategories();
  return (
    <Box className={style.categories}>
      <CategoriesHeader categoriesCount={categories.length} />
      <WithLoader isLoading={loading}>
        <CategoriesTable categories={categories} />
      </WithLoader>
      {!loading && categories.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Categories Found" />
        </Flex>
      )}
    </Box>
  );
};
