import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CloseButton,
  Grid,
  GridItem,
  HelperText,
  HStack,
  SBInput,
  SlideFade,
  Text,
  toast,
} from '@swftbox/style-guide';
import { type FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import SBEmailICon from 'src/assets/SBFileUploadIcon.svg';
import {
  type GetUploadUrlVariables,
  uploadFile,
  useGetUploadUrlQuery,
} from 'src/components/Particles';
import { type StepThreePayloadProps } from '../Helper';
import { StepThreeSchema } from '../../Validation';
import { FormHeader } from './FormHeader';

interface StepThreeFormProps {
  onDone: (payload: StepThreePayloadProps) => void;
  submittedPayload: StepThreePayloadProps;
}

function splitFileName(fileName: string) {
  const fileNameParts = fileName.split('.');
  if (!fileNameParts.length) return { name: fileName };
  const extension = fileNameParts.pop();
  const name = fileNameParts.join('.');
  return { name, extension };
}

function getExtensionValue(extension: string) {
  if (extension.toLowerCase() === 'png') return 'IMAGE_PNG';
  if (['jpeg', 'jpg'].includes(extension.toLowerCase())) return 'IMAGE_JPEG';
  return 'APPLICATION_PDF';
}

export const StepThreeForm: FC<StepThreeFormProps> = ({ onDone, submittedPayload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<StepThreePayloadProps>({
    defaultValues: submittedPayload,
    resolver: yupResolver(StepThreeSchema),
  });

  const { getUploadUrl } = useGetUploadUrlQuery();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [trnFile, setTrnFile] = useState<File>();
  const [tradeFile, setTradeFile] = useState<File>();

  function clearTrnFile() {
    setTrnFile(undefined);
  }

  function clearTradeFile() {
    setTradeFile(undefined);
  }

  const onTrnDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
    if (!acceptedFiles.length) return;
    setTrnFile(acceptedFiles[0]);
  }, []);

  const onTradeDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
    if (!acceptedFiles.length) return;
    setTradeFile(acceptedFiles[0]);
  }, []);

  const {
    getRootProps: getTrnRootProps,
    getInputProps: getTrnInputProps,
    isDragActive: isTrnDragActive,
  } = useDropzone({
    onDrop: onTrnDrop,
    maxFiles: 1,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
  });

  const {
    getRootProps: getTradeRootProps,
    getInputProps: getTradeInputProps,
    isDragActive: isTradeDragActive,
  } = useDropzone({
    onDrop: onTradeDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
  });

  function prepareFilesData() {
    if (!tradeFile) {
      setError('trnCertificate', { message: 'Trade License is required!' });
      return { canProceed: false };
    }

    const { extension: tradeFileExtension } = splitFileName(tradeFile.name);
    if (!tradeFileExtension) {
      setError('tradeLicense', { message: 'Cannot obtain file extension' });
      return { canProceed: false };
    }

    const tradeFileUploadArgs: GetUploadUrlVariables = {
      fileType: getExtensionValue(tradeFileExtension),
      fileDirectory: 'CERTIFICATES',
    };

    let trnFileUploadArgs;
    if (trnFile) {
      const { extension: trnFileExtension } = splitFileName(trnFile.name);
      if (!trnFileExtension) {
        setError('trnCertificate', { message: 'Cannot obtain file extension' });
        return { canProceed: false };
      }
      trnFileUploadArgs = {
        fileType: getExtensionValue(trnFileExtension),
        fileDirectory: 'CERTIFICATES',
      };
    }

    return {
      canProceed: true,
      trnFileUploadArgs,
      tradeFileUploadArgs,
    };
  }

  const onSubmit = useCallback(async () => {
    setIsUploadingFiles(true);

    const { canProceed, tradeFileUploadArgs, trnFileUploadArgs } = prepareFilesData();
    if (!canProceed || !tradeFileUploadArgs) {
      setIsUploadingFiles(false);
      return;
    }
    const signedTradeFileUploadURL = await getUploadUrl(tradeFileUploadArgs).catch(() => null);

    let signedTRNFileUploadURL;
    if (trnFileUploadArgs) {
      signedTRNFileUploadURL = await getUploadUrl(trnFileUploadArgs).catch(() => null);
    }

    if ((trnFile && !signedTRNFileUploadURL) || !signedTradeFileUploadURL) {
      setIsUploadingFiles(false);
      return;
    }

    const areUploaded = await Promise.all([
      signedTRNFileUploadURL && uploadFile(signedTRNFileUploadURL.url, trnFile),
      uploadFile(signedTradeFileUploadURL.url, tradeFile),
    ]).catch(() => false);

    setIsUploadingFiles(false);
    if (!areUploaded) {
      toast.error('Cannot upload your files');
      return;
    }

    onDone({
      legalAddress: getValues('legalAddress'),
      trnNumber: getValues('trnNumber'),
      trnCertificate: signedTRNFileUploadURL?.filePath,
      tradeLicense: signedTradeFileUploadURL.filePath,
    });
  }, [trnFile, tradeFile]);

  return (
    <>
      <FormHeader title="Legal Information" description="( Company Legal Information )" />
      <SlideFade
        in
        exit={{ opacity: 0, translateX: '-1000px' }}
        transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
        unmountOnExit
      ></SlideFade>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4}>
          <GridItem colSpan={12}>
            <SBInput
              placeholder="legal Address *"
              label="Legal Address"
              {...register('legalAddress')}
              error={errors?.legalAddress?.message}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <SBInput
              label="TRN number (Optional)"
              placeholder="ex: 995..."
              {...register('trnNumber')}
              error={errors?.trnNumber?.message}
            />
          </GridItem>
          <GridItem colSpan={12}>
            <Box
              {...getTradeRootProps()}
              h="100%"
              border="1px solid #EAECF0"
              background="#fff"
              borderRadius="12px"
              p="16px"
              textAlign="center"
              cursor="pointer"
            >
              <input {...getTradeInputProps()} />
              {isTradeDragActive && <Text>Drop the files here ...</Text>}
              {tradeFile && (
                <HStack>
                  <Text> {tradeFile.name} </Text>
                  <CloseButton onClick={clearTradeFile} />{' '}
                </HStack>
              )}
              {!isTradeDragActive && !tradeFile && (
                <Box>
                  <Box
                    mb="4"
                    mx="auto"
                    border="8px solid #F2F4F7"
                    w="48px"
                    h="48px"
                    borderRadius="28px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="#EDEDED"
                    color="black"
                  >
                    <SVG src={SBEmailICon} />
                  </Box>
                  <Text mb="1" fontSize="x-small">
                    Drag or Click To Upload
                    <Text as="span" fontWeight="600" mx="1">
                      Your trade License
                    </Text>
                  </Text>
                </Box>
              )}
            </Box>
            {errors?.tradeLicense && (
              <HelperText variant="error">{errors?.tradeLicense?.message}</HelperText>
            )}
          </GridItem>
          <GridItem colSpan={12}>
            <Box
              {...getTrnRootProps()}
              h="100%"
              border="1px solid #EAECF0"
              background="#fff"
              borderRadius="12px"
              p="16px"
              textAlign="center"
              cursor="pointer"
            >
              <input {...getTrnInputProps()} />
              {isTrnDragActive && <Text>Drop the files here ...</Text>}
              {trnFile && (
                <HStack>
                  <Text> {trnFile.name} </Text>
                  <CloseButton onClick={clearTrnFile} />{' '}
                </HStack>
              )}
              {!isTrnDragActive && !trnFile && (
                <Box>
                  <Box
                    mb="4"
                    mx="auto"
                    border="8px solid #F2F4F7"
                    w="48px"
                    h="48px"
                    borderRadius="28px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="#EDEDED"
                    color="black"
                  >
                    <SVG src={SBEmailICon} />
                  </Box>
                  <Text mb="1" fontSize="x-small">
                    Drag or Click To Upload
                    <Text as="span" fontWeight="600" mx="1">
                      Your TRN Certificate (Optional)
                    </Text>
                  </Text>
                </Box>
              )}
            </Box>
            {errors?.trnCertificate && (
              <HelperText variant="error">{errors?.trnCertificate?.message}</HelperText>
            )}
          </GridItem>
        </Grid>

        <Button w="100%" mt="16px" type="submit" isLoading={isUploadingFiles}>
          Continue
        </Button>
      </form>
    </>
  );
};
