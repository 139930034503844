import {
  AutocompleteMap,
  type AutocompleteMapLocation,
  FormLabel,
  SBInput,
  Stack,
  Grid,
  VStack,
  FormControl,
} from '@swftbox/style-guide';
import React, { useCallback, useMemo } from 'react';
import {
  type Control,
  type FieldErrors,
  type UseFormClearErrors,
  type UseFormGetValues,
  type UseFormRegister,
  type UseFormSetValue,
} from 'react-hook-form';
import { type location } from 'src/components/Particles';

interface FormValue {
  name: string;
  location: location;
}

interface FulfillmentFormProps {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  errors: FieldErrors<FormValue>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  clearErrors: UseFormClearErrors<any>;
  isOpen: boolean;
}

function FulfillmentForm(props: FulfillmentFormProps) {
  const { register, errors, setValue, getValues, clearErrors, isOpen } = props;

  const onLocationChange = useCallback(
    (mapData: AutocompleteMapLocation) => {
      setValue(
        'location.coordinates.latitude',
        mapData.coordinates.lat || getValues('coordinates.latitude')
      );
      setValue(
        'location.coordinates.longitude',
        mapData.coordinates.lng || getValues('location.coordinates.longitude')
      );
      setValue('location.addressLine1', mapData.address || getValues('location.addressLine1'));
      setValue('location.city', mapData.city || getValues('location.city'));
      setValue('location.country', mapData.country || getValues('location.country'));
      clearErrors();
    },
    [setValue, getValues, clearErrors]
  );

  const mapCenter = useMemo(() => ({ lat: 25.456744, lng: 55.676685 }), []);

  return (
    <Grid
      gridTemplate={{ base: 'auto 100% / 100%', md: '100% / 49% 49%' }}
      justifyContent="space-between"
    >
      <Stack gap={4}>
        <SBInput
          crossOrigin={undefined}
          label="Fulfillment Name*"
          placeholder="Add fulfillment's Name"
          {...register('name')}
          error={errors.name?.message}
        />
        <SBInput
          crossOrigin={undefined}
          label="Address Line 1*"
          placeholder="Add fulfillment's Address Line 1"
          {...register('location.addressLine1')}
          error={errors.location?.addressLine1?.message}
        />

        <SBInput
          crossOrigin={undefined}
          label="Address Line 2:"
          placeholder="Add fulfillment's Address Line 2"
          {...register('location.addressLine2')}
          error={errors.location?.addressLine2?.message}
        />

        <SBInput
          crossOrigin={undefined}
          label="Area"
          error={errors.location?.area?.message}
          placeholder="Add fulfillment's Area"
          {...register('location.area')}
        />

        <SBInput
          crossOrigin={undefined}
          label="Street"
          error={errors.location?.street?.message}
          placeholder="Add fulfillment's Street"
          {...register('location.street')}
        />

        <SBInput
          crossOrigin={undefined}
          label="Building"
          error={errors.location?.building?.message}
          placeholder="Add fulfillment's Building"
          {...register('location.building')}
        />

        <SBInput
          crossOrigin={undefined}
          label="Landmark"
          error={errors.location?.landMark?.message}
          placeholder="Add fulfillment's Landmark"
          {...register('location.landMark')}
        />
      </Stack>
      <VStack>
        <FormControl mb="3" h="100%">
          <FormLabel> Find location on the map *</FormLabel>
          {isOpen && (
            <AutocompleteMap
              onLocationChange={onLocationChange}
              h="calc(100% - 75px)"
              minH="320px"
              borderRadius="10px"
              center={mapCenter}
              inputStyle={{ mb: '2vh' }}
            />
          )}
        </FormControl>
        <Stack flexDir={{ base: 'column', md: 'row' }} w="100%" spacing={0} gap="2">
          <SBInput
            crossOrigin={undefined}
            label="City*"
            placeholder="Add fulfillment's City"
            {...register('location.city')}
            error={errors.location?.city?.message}
          />
          <SBInput
            crossOrigin={undefined}
            label="Country*"
            error={errors.location?.country?.message}
            placeholder="Add fulfillment's Country"
            {...register('location.country')}
          />
        </Stack>
      </VStack>
    </Grid>
  );
}

export default React.memo(FulfillmentForm, areEqual);

// to avoid parents re-renders
function areEqual(prevProps: FulfillmentFormProps, nextProps: FulfillmentFormProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
