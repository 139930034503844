import { GridItem, HStack } from '@swftbox/style-guide';
import { type ReactNode } from 'react';
import {
  CreateJobListItemType,
  type SimpleRetailer,
  useJobItemsSelectionContext,
  type Order,
  type Address,
} from 'src/components/Particles';

type RetailerWithLocation = SimpleRetailer & { location: Address };

interface ListItemWrapperProps {
  itemElement: ReactNode;
  item: Order | RetailerWithLocation;
  type: CreateJobListItemType;
}

export const ListItemWrapper = (props: ListItemWrapperProps) => {
  const { item, itemElement, type } = props;
  const { toggleSelection, orders, retailers } = useJobItemsSelectionContext();

  const isItemSelected =
    type === CreateJobListItemType.RETAILER
      ? retailers.has((item as RetailerWithLocation).location.id)
      : orders.has(item.id);

  return (
    <GridItem>
      <HStack
        justifyContent="space-between"
        p="12px 20px"
        border="1px solid "
        bg={isItemSelected ? '#36c5f12b' : '#fff'}
        borderColor={isItemSelected ? '#36C5F1' : 'gray.200'}
        transition="all 0.4s "
        borderRadius="12px"
        cursor="pointer"
        onClick={() => {
          toggleSelection(type, item);
        }}
        h="100%"
      >
        {itemElement}
      </HStack>
    </GridItem>
  );
};
