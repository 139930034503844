import { useLazyQuery } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import { INVOICE_BY_ID, type InvoiceQueryResponse } from '../Queries';

interface InvoiceQueryProps {
  id: string;
}

export function useInvoiceQuery({ id }: InvoiceQueryProps) {
  QueriesStore.update('INVOICE_BY_ID', { id });
  const [query, { data, loading, error }] = useLazyQuery<InvoiceQueryResponse>(INVOICE_BY_ID, {
    pollInterval: 30000,
    variables: { idInput: { id } },
  });

  return {
    query,
    invoice: data?.invoiceById,
    loading,
    error,
  };
}
