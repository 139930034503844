import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../SharedTypes';
import { ADD_NEW_ROLE, type CreateRoleInput, GET_ROLES, type AddRoleResponse } from '../Queries';

interface AddRoleProps {
  onCompleted: (data: { createRole: AddRoleResponse }, clientOptions?: clientOptionType) => void;
}
export function useAddRole({ onCompleted }: AddRoleProps) {
  const [mutate, { error, loading }] = useMutation<
    { createRole: AddRoleResponse },
    { createRoleInput: CreateRoleInput }
  >(ADD_NEW_ROLE, {
    onCompleted,
    refetchQueries: [
      {
        query: GET_ROLES,
        variables: { withPermissions: true },
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
