import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../../SharedTypes';
import { CANCEL_JOB_MUTATION } from '../../Queries';

export function useCancelJobMutation() {
  const [mutate, { loading, error }] = useMutation<
    { cancelRoute: MessageResponse },
    { id?: string }
  >(CANCEL_JOB_MUTATION);

  function cancelJob(id?: string) {
    return mutate({
      variables: {
        id,
      },
      refetchQueries: ['PaginatedRoutes'],
    });
  }

  return {
    cancelJob,
    loading,
    error,
  };
}
