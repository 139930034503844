import { Box, Button, Flex, HStack, toast } from '@swftbox/style-guide';
import { useCallback } from 'react';
import { AssignDriver } from 'src/components/Organisms';
import { useAssignLastMileJob, useConfiguration } from 'src/components/Particles';
import { OrdersHeader, OrdersTable, StatusFilters, TimelineFilter } from './Components';
import { ScanOrders, SortingMode } from './Components/Modal';
import style from './style.module.scss';
import { TimingFilter } from './Components/Filters/TimingFilter/TimingFilter';

export function OrdersPage() {
  const { assignLastMile } = useAssignLastMileJob();
  const { configuration: FilterTimelineEnabled } = useConfiguration('FilterTimelineEnabled');

  const onDriverAssignment = useCallback(
    async (driverId: string) => {
      try {
        await assignLastMile({
          payload: {
            driverId,
            isFree: true,
          },
          onCompleted: (message) => {
            toast.success(message);
          },
        });
        return true;
      } catch {
        return false;
      }
    },
    [assignLastMile]
  );

  return (
    <>
      <HStack justify="space-between" my="2">
        <TimingFilter />
        <Flex justifyContent="end" gap="2">
          <ScanOrders />
          <SortingMode />
          <AssignDriver onAssign={onDriverAssignment}>
            <Button>Create Last Mile Job</Button>
          </AssignDriver>
        </Flex>
      </HStack>
      {!!+(FilterTimelineEnabled?.value ?? 0) && <TimelineFilter />}
      <Box className={style.order_main}>
        <OrdersHeader />
        <Flex align="center" p="20px 24px">
          <HStack spacing="10">
            <StatusFilters />
          </HStack>
        </Flex>
        <OrdersTable />
      </Box>
    </>
  );
}
