import { FC, useState } from 'react';
import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Box,
} from '@swftbox/style-guide';
import { settlementColumns } from '../columns';
import SettlementActionButtons from './SettlementActionButtons';
import { SettlementForm } from './SettlementForm';
import { Settlement, fixPrecision } from 'src/components/Particles';
import { format } from 'date-fns';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';

interface Props {
  settlements: Settlement[];
  page: number;
  total: number;
  onPageChange: (page: number) => void;
}

export const SettlementsTable: FC<Props> = ({ settlements, total, page, onPageChange }) => {
  const [selectedSettlement, setSelectedSettlement] = useState<Settlement>();

  return (
    <Box>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              {settlementColumns.map((column) => (
                <Th key={column.field}>{column.label}</Th>
              ))}
              <Th right="0">
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={20}
                  onPageChange={onPageChange}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {settlements?.map((settlement) => (
              <Tr key={settlement.id}>
                <Td>
                  {!!settlement.driver && <Text>{settlement.driver.user.name}</Text>}
                  {!!settlement.retailer && <Text> {settlement.retailer.name}</Text>}
                  <Text>({settlement.subjectType})</Text>
                </Td>
                <Td>
                  <Text>{settlement.type} </Text>
                </Td>
                <Td>
                  <Text>{`${fixPrecision(settlement.amount)} ${settlement.currency}`}</Text>
                </Td>
                <Td>
                  <Badge
                    p="2px 8px"
                    fontWeight="medium"
                    borderRadius="8px"
                    minWidth={'60%'}
                    mb="5px"
                    textAlign={'center'}
                    fontSize="x-small"
                    variant="solid"
                    colorScheme={settlement.status === 'APPROVED' ? 'green' : 'orange'}
                  >
                    {settlement.status}
                  </Badge>
                </Td>
                <Td>
                  <Text>
                    {format(new Date(settlement.createdAt), 'HH:MM\xa0\xa0\xa0MMM dd, yyyy')}
                  </Text>
                </Td>
                <Td textAlign="end">
                  <SettlementActionButtons
                    onMenuOpen={() => {}}
                    settlement={settlement}
                    editSettlement={setSelectedSettlement}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {selectedSettlement && (
        <SettlementForm
          settlement={selectedSettlement}
          onModalClose={setSelectedSettlement.bind(null, undefined)}
        />
      )}
    </Box>
  );
};
