import { useQuery } from '@apollo/client';
import { ATTACHMENT_LIST, AttachmentLensArgs, AttachmentListQueryResponse } from '../Queries';
import { endOfDay, startOfDay } from 'date-fns';

export function useAttachmentLensListQuery(
  filter: AttachmentLensArgs,
  onCompleted: (data: AttachmentListQueryResponse) => void
) {
  const { data, loading, error, ...rest } = useQuery<
    AttachmentListQueryResponse,
    AttachmentLensArgs
  >(ATTACHMENT_LIST, {
    variables: {
      ...filter,
      startDate: filter.startDate ? startOfDay(filter.startDate) : undefined,
      endDate: filter.endDate ? endOfDay(filter.endDate) : undefined,
    },
    onCompleted,
    fetchPolicy: 'no-cache',
  });

  return {
    attachmentList: data?.paginatedAttachments.data || null,
    total: data?.paginatedAttachments.total,
    data,
    loading,
    error,
    ...rest,
  };
}
