import { Button, toast } from "@swftbox/style-guide";
import { FC } from "react";
import { Job, useFinishJobMutation } from "src/components/Particles";

interface Props {
  job: Job;
}

export const FinishJob: FC<Props> = ({ job }) => {

  const { finishJob, loading } = useFinishJobMutation();

  const handleClick = async () => {
    const response = await finishJob(job.id);
    toast.success(response.data?.finishRoute.message);
  }

  return (
    <Button type="button" onClick={handleClick} disabled={loading}>
      Finish Job
    </Button>
  );
}

export default FinishJob;