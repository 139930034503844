import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import { AddUser } from './Modal/AddUser';

interface CustomersHeaderProps {
	usersCount: number;
}

export function UsersHeader(props: CustomersHeaderProps) {
	const {usersCount} = props;
	return (
		<Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
			<Box>
				<Text fontWeight="semibold" fontSize="text-lg">
					Users
					<Badge
						p="2px 8px"
						mx="1"
						fontWeight="medium"
						borderRadius="8px"
						fontSize="x-small"
						textTransform="capitalize"
					>
						{usersCount} User
					</Badge>
				</Text>
			</Box>
			<Spacer/>
			<AddUser/>
		</Flex>
	);
}
