import { gql } from '@apollo/client';

export const GET_SYSTEM_NOTIFICATION_TRIGGERS = gql`
  query SystemNotficationsTriggers {
    SystemNotficationsTriggers {
      id
      trigger
      triggerType
      useAsDefault
      description
    }
  }
`;
