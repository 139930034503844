import { Badge, Button, HStack, Image, Text, VStack } from '@swftbox/style-guide';
import { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import checkIcon from 'src/assets/icons/check.svg';
import { statusBadgeVariant, tagsBadgeVariant } from 'src/components/Pages/OperationLens/LensCard';
import { AttachmentLens } from 'src/components/Particles';

type Props = {
  attachment: AttachmentLens;
  handleUpdateStatus?: (status: 'passed' | 'rejected', id: string) => void;
};

export const ImageLens = ({ attachment, handleUpdateStatus }: Props) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'a' && attachment.status !== 'passed') {
        // 'a' key for pass
        handleUpdateStatus && handleUpdateStatus('passed', attachment.id);
      } else if (event.key === 'f' && attachment.status !== 'rejected') {
        // 'f' key for reject
        handleUpdateStatus && handleUpdateStatus('rejected', attachment.id);
      }
    };

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [attachment, handleUpdateStatus]);

  return (
    <HStack alignItems="start" h="100%">
      <Image src={attachment.attachment} alt={attachment.attachment} h="100%" objectFit="cover" />
      <VStack position="sticky" top="0" h="100%" justifyContent="space-between" alignItems="start">
        <VStack justifyContent="start" alignItems="start" display="text-xl" color="gray.600">
          <Text>
            Status:{' '}
            <Text
              textTransform="capitalize"
              as="span"
              fontSize="display-xs"
              fontWeight="semibold"
              color={statusBadgeVariant(attachment?.status).color}
            >
              {attachment?.status}
            </Text>
          </Text>
          {!!attachment?.photoLabelTags?.length && (
            <HStack>
              <Text display="text-xl" color="gray.600">
                Tags:{' '}
              </Text>
              {attachment?.photoLabelTags?.map((name) => (
                <Badge variant={tagsBadgeVariant(name).variant} size="md">
                  {tagsBadgeVariant(name).name}
                </Badge>
              ))}
            </HStack>
          )}
        </VStack>
        <HStack>
          {attachment.status !== 'rejected' && (
            <Button
              borderColor="red.500"
              bg="#fff"
              p="1"
              w="150px"
              color="red.500"
              onClick={() => handleUpdateStatus && handleUpdateStatus('rejected', attachment.id)}
            >
              <SVG src={XIcon} stroke="red" />
              <Text>Reject</Text>
            </Button>
          )}
          {attachment.status !== 'passed' && (
            <Button
              borderColor="green.500"
              bg="#fff"
              p="1"
              w="150px"
              color="green.500"
              onClick={() => handleUpdateStatus && handleUpdateStatus('passed', attachment.id)}
            >
              {' '}
              <SVG src={checkIcon} stroke="green" />
              <Text>Pass</Text>
            </Button>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
