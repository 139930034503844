import { Box, HStack, Spinner } from '@swftbox/style-guide';
import { AreasList } from './Components';
import { useAreasQuery } from 'src/components/Particles';

export const Areas = () => {
  const { areas, loading } = useAreasQuery();

  return (
    <>
      <Box pb="8" justifyContent="center" alignItems="center">
        {loading ? (
          <HStack w="100%" justifyContent="center" minH="200px">
            <Spinner />
          </HStack>
        ) : (
          <AreasList areas={areas} />
        )}
      </Box>
    </>
  );
};
