import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import { GET_CATEGORIES_QUERY, UpdateCategoryInput, UPDATE_CATEGORY_QUERY } from '../Queries';

interface UpdateCategoryResponse {
  message: string;
}

interface UpdateCategoryProps {
  payload: UpdateCategoryInput;
  onCompleted: (
    data: { updateCategory: UpdateCategoryResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateCategory() {
  const [mutate, { data, error, loading }] = useMutation<
    { updateCategory: UpdateCategoryResponse },
    { input: UpdateCategoryInput }
  >(UPDATE_CATEGORY_QUERY);

  const callUpdateCategory = ({ payload, onCompleted }: UpdateCategoryProps) => {
    return mutate({
      variables: {
        input: payload
      },
      refetchQueries: [
        {
          query: GET_CATEGORIES_QUERY,
        },
      ],
      onCompleted,
    });
  };

  return {
    callUpdateCategory,
    data,
    error,
    loading,
  };
}
