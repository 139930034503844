import { useMutation } from '@apollo/client';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { clientOptionType } from '../SharedTypes';
import { type Retailer, type CreateRetailerInput } from './retailer.types';
import { GET_RETAILERS, CREATE_RETAILER } from './retailers.graphql';

interface CreateRetailerResponse {
  message: string;
  data: Retailer;
}

interface CreateRetailerProps {
  payload: CreateRetailerInput,
  onCompleted: (
    data: { createRetailer: CreateRetailerResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useCreateRetailerMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    { createRetailer: CreateRetailerResponse },
    { createRetailerInput: CreateRetailerInput }
  >(CREATE_RETAILER);

  async function createRetailer({ payload, onCompleted }: CreateRetailerProps) {
    return mutate({
      variables: {
        createRetailerInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_RETAILERS,
          variables: QueriesStore.get('GET_RETAILERS'),
        },
      ],
    });
  }

  return {
    createRetailer,
    loading,
    error,
  };
}
