import { Autocomplete, Box, SBModal, Text } from '@swftbox/style-guide';
import { useRef, useState } from 'react';
import {
  useAuthContext,
  useOrderModalActions,
  useOrderModalContext,
} from 'src/components/Particles';
import { ForceDelivery } from './ForceDelivery';
import { ForceRtoComplete } from './ForceRtoComplete';
import { ForceBackToRequested } from './ForceBackToRequested';
import { ForceReceive } from './ForceReceive';
import { ActionsType, actionsType } from './types';

export function ForceActionsOrderModal() {
  const { user } = useAuthContext();
  const [actionType, setActionType] = useState<ActionsType>(ActionsType.DELIVERY);
  const forceDeliveryFormRef = useRef();
  const forceRtoCompleteFormRef = useRef();
  const forceBackToRequestedFormRef = useRef();
  const forceReceiveFormRef = useRef();
  const { forceActionsIsOpen } = useOrderModalContext();
  const dispatch = useOrderModalActions();

  if (!user?.roles?.some((role) => ['Admin', 'SuperAdmin'].includes(role))) {
    return null;
  }

  const handleClose = () => {
    dispatch({ type: 'CLOSE_FORCE_ACTIONS' });
    setActionType(ActionsType.DELIVERY);
  };

  const handleSubmit = () => {
    if (forceDeliveryFormRef.current) {
      (forceDeliveryFormRef?.current as HTMLButtonElement).click();
    }
    if (forceRtoCompleteFormRef.current) {
      (forceRtoCompleteFormRef?.current as HTMLButtonElement).click();
    }
    if (forceBackToRequestedFormRef.current) {
      (forceBackToRequestedFormRef?.current as HTMLButtonElement).click();
    }
    if (forceReceiveFormRef.current) {
      (forceReceiveFormRef?.current as HTMLButtonElement).click();
    }
  };

  return (
    <SBModal
      isOpen={forceActionsIsOpen}
      onClose={handleClose}
      // @ts-expect-error dummy
      scrollBehavior="outside"
      type="general"
      size="xl"
      header={
        <Box pb="1vh">
          <Text>Force Actions</Text>
        </Box>
      }
      body={
        <Box>
          <Autocomplete
            label="Action Type"
            options={actionsType}
            classNames={{ option: (state) => (state.isDisabled ? 'opacity-05' : 'opacity-1') }}
            value={actionsType.find((date) => date?.value === actionType) ?? ''}
            isOptionDisabled={(option) =>
              // @ts-expect-error, it's just a falsy warning
              [ActionsType.RTO, ActionsType.CANCEL].includes(option?.value)
            }
            onChange={(val) => {
              // @ts-expect-error, it's just a falsy warning
              setActionType(val?.value);
            }}
          />
          {actionType === ActionsType.DELIVERY && (
            <ForceDelivery formRef={forceDeliveryFormRef} handleClose={handleClose} />
          )}
          {actionType === ActionsType.RTO_COMPLETE && (
            <ForceRtoComplete formRef={forceRtoCompleteFormRef} handleClose={handleClose} />
          )}
          {actionType === ActionsType.BACK_TO_REQUESTED && (
            <ForceBackToRequested formRef={forceBackToRequestedFormRef} handleClose={handleClose} />
          )}
          {actionType === ActionsType.RECEIVE && (
            <ForceReceive formRef={forceReceiveFormRef} handleClose={handleClose} />
          )}
        </Box>
      }
      handleConfirm={handleSubmit}
    />
  );
}
