import { Box, IconWText, SBEyeIcon, SBModal, useDisclosure } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { InvoiceType } from 'src/components/Particles';
import { fetchInvoiceData } from '../helper';

interface InvoiceProps {
  id: string;
  type: InvoiceType;
}

export const InvoiceModal = ({ id, type }: InvoiceProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [invoiceFile, setInvoiceFile] = useState('');

  const getInvoiceFile = async (invoiceId: string, invoiceType: InvoiceType) => {
    const invoiceData = await fetchInvoiceData(invoiceId, invoiceType);
    if (invoiceData) {
      return setInvoiceFile(invoiceData);
    }
  };

  useEffect(() => {
    if (isOpen) getInvoiceFile(id, type);
  }, [isOpen]);

  return (
    <>
      <IconWText
        text="View Invoice"
        spacing="20px"
        Icon={<SBEyeIcon width="15px" />}
        onClick={onOpen}
        aria-label="View Invoice"
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<></>}
        size="6xl"
        body={
          <Box padding={4} borderWidth={1} borderRadius="md">
            {!!invoiceFile && <Box padding={4} dangerouslySetInnerHTML={{ __html: invoiceFile }} />}
          </Box>
        }
        bordered={false}
        footer={<></>}
      />
    </>
  );
};
