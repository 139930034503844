import { useLazyQuery } from '@apollo/client';
import { GET_RETAILER_ACTIVE_AGREEMENT_QUERY, type Agreement } from '../Queries';

interface RetailerActiveAgreementResponse {
  retailerActiveAgreement: Agreement;
}

export function useRetailerActiveAgreement() {
  const [query, { data, loading }] = useLazyQuery<RetailerActiveAgreementResponse>(
    GET_RETAILER_ACTIVE_AGREEMENT_QUERY
  );

  const getActiveAgreement = (retailerId: string) => {
    void query({
      variables: {
        retailerId,
      },
    });
  };

  return {
    getActiveAgreement,
    activeAgreement: data?.retailerActiveAgreement,
    loading,
  };
}
