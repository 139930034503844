export const paymentColumns = [
  {
    field: 'orderSwftboxTracking',
    label: 'Order tracking',
    subLabel: 'Order Reference',
  },

  {
    field: 'type',
    label: 'Type',
  },
  {
    field: 'retailer',
    label: 'Retailer',
  },
  {
    field: 'collectedBy',
    label: 'Collected By',
  },

  {
    field: 'amount',
    label: 'Amount',
  },
  {
    field: 'status',
    label: 'Status',
  },
  {
    field: 'createdAt',
    label: 'Date created',
  },
];

export const settlementColumns = [
  {
    field: 'type',
    label: 'Type',
  },
  {
    field: 'subject',
    label: 'Subject',
  },
  {
    field: 'amount',
    label: 'Amount',
  },
  {
    field: 'status',
    label: 'Status',
  },
  {
    field: 'createdAt',
    label: 'Date created',
  },
];
