import { ReportsPage } from './Reports.page';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'src/components/Particles';

export function ReportsPageWrapper() {
  return (
    <>
      <Helmet>
        <title> Reports </title>
      </Helmet>
      <ErrorBoundary>
        <ReportsPage />
      </ErrorBoundary>
    </>
  );
}
