import {
  JobOrder,
  JobStatus,
  type JobTypeFilter,
  type SequenceStops,
} from 'src/components/Particles';

export const LIST_VIEW: string = 'list-view';
export const GRID_VIEW: string = 'grid-view';

export function isStopSequencesChanged(stops1: SequenceStops[], stops2: SequenceStops[]) {
  for (let i = 0; i < stops1.length; i++) {
    const newSortedItem = stops1[i];
    const item = stops2[i];
    if (newSortedItem.sequence !== item.sequence) {
      return true;
    }
  }
  return false;
}

export function getJobStatusColor(status?: JobStatus) {
  switch (status) {
    case JobStatus.NOT_ASSIGNED:
      return 'gray';
    case JobStatus.ASSIGNED:
      return 'blue';
    case JobStatus.STARTED:
      return 'orange';
    case JobStatus.COMPLETED:
      return 'green';
    case JobStatus.FAILED:
      return 'red';
    default:
      return 'gray';
  }
}

export function getJobStatusLabel(status?: JobStatus): string {
  if (status === JobStatus.FAILED) {
    return 'CANCELED';
  }

  return status ?? '';
}

export function getJobsSearch(searchText?: string, jobFilter?: JobTypeFilter[]) {
  const search = [];
  if (searchText) {
    search.push(searchText);
  }

  if (jobFilter) {
    search.push(...jobFilter);
  }
  return search.length ? search : undefined;
}

export const handleTime = ({
  isCompleted,
  isPickup,
  order,
}: {
  isCompleted: boolean;
  isPickup: boolean;
  order?: JobOrder;
}) => {
  let start = null;
  let end = null;
  if (isPickup) {
    if (isCompleted) {
      start = null;
      end = order?.timing?.actualPickupDateEnd;
    } else {
      start = order?.timing?.expectedPickupDateStart;
      end = order?.timing?.expectedPickupDateEnd;
    }
  } else {
    if (isCompleted) {
      start = order?.timing?.actualDropDateStart;
      end = order?.timing?.actualDropDateEnd;
    } else {
      start = order?.timing?.expectedDropDateStart;
      end = order?.timing?.expectedDropDateEnd;
    }
  }
  return { start, end };
};
