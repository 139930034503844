import { useMutation } from '@apollo/client';
import {
  type AddItemInput,
  type AddItemResponse,
  ADD_ITEMS_QUERY,
  GET_ITEMS_QUERY,
} from '../Queries';
import { QueriesStore } from '../../../queriesStore';

export function useAddItem() {
  const [mutate, { loading, data, error }] = useMutation<
    { addItems: AddItemResponse },
    { addItemsInput: AddItemInput }
  >(ADD_ITEMS_QUERY, {
    refetchQueries: [
      {
        query: GET_ITEMS_QUERY,
        variables: QueriesStore.get('GET_ITEMS_QUERY'),
      },
    ],
  });

  async function callAddItem(payload: AddItemInput) {
    await mutate({
      variables: {
        addItemsInput: payload,
      },
      refetchQueries: [
        {
          query: GET_ITEMS_QUERY,
          variables: QueriesStore.get('GET_ITEMS_QUERY'),
        },
      ],
    });
  }

  return {
    callAddItem,
    data: data?.addItems.message,
    loading,
    error,
  };
}
