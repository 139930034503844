import {
  Button,
  SBCommentIcon,
  SBModal,
  SBTextArea,
  Stack,
  Text,
  Tooltip,
  Box,
  useDisclosure,
  toast,
} from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import AddCommentIcon from 'src/assets/icons/orders/plus.svg';
import { useAddCommentMutation, type Order } from 'src/components/Particles';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
interface AddCommentModalProps {
  order: Order;
}
export default function AddCommentModal(props: AddCommentModalProps) {
  const { order } = props;

  const { addComment, message } = useAddCommentMutation();
  const {
    handleSubmit,
    register,
    setFocus,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    defaultValues: {},
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onConfirm = (data: any) => {
    addComment({
      orderId: order.id,
      comment: data.comment,
      attachments: [],
      onCompleted: () => {
        onClose();
        toast.success(message);
      },
    });
  };
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setFocus('comment'), 100); // set focus on the input field with the name 'packageCount'
    }
  }, [isOpen, setFocus]);
  return (
    <>
      <Tooltip label="Add Comment" hasArrow>
        <Button variant="link" onClick={onOpen}>
          <SVG src={AddCommentIcon} />
        </Button>
      </Tooltip>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBCommentIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Add Comment</Text>
          </Box>
        }
        size="2xl"
        body={
          <>
            <Stack gap={4}>
              <SBTextArea
                rows={2}
                placeholder="ex: don't forget to call before leave"
                {...register('comment')}
                label="Comment*"
              />
            </Stack>
          </>
        }
        bordered={false}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
