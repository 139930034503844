import { Grid, GridItem, HStack, Box, Text } from '@swftbox/style-guide';
import { Item } from 'src/components/Particles';
import EmptyBox from 'src/assets/emptyBox.svg';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';

interface ItemsProps {
  Items: Item[];
  loading?: boolean;
}

export function ItemsTable({ Items, loading }: ItemsProps) {
  return (
    <>
      <Grid
        gridTemplateColumns="repeat(12,1fr) 15px"
        color="gray.700"
        fontWeight="semibold"
        borderBottom="1px solid"
        borderColor="gray.500"
        gap="3"
        py="3"
        mb="5"
        position="sticky"
        top="0px"
        zIndex="100"
        bg="gray.100"
        _notFirst={{ mt: '2.5', pt: '2.5', borderTop: '3px solid white' }}
      >
        <GridItem colSpan={3}> SKU number</GridItem>
        <GridItem colSpan={3}>Item Name</GridItem>
        <GridItem colSpan={1}>Qty</GridItem>
        <GridItem colSpan={3}>Serial Numbers</GridItem>
      </Grid>
      {Items.map((item: Item) => (
        <Grid
          gridTemplateColumns="repeat(12,1fr)  15px"
          key={item.id}
          gap="3"
          color="primary.900"
          fontWeight="medium"
          fontSize="x-small"
          _notLast={{ mb: '2.5', pb: '2.5', borderBottom: '3px solid white' }}
        >
          <GridItem colSpan={3} display="flex" alignItems="center" wordBreak="break-all">
            {item.skuNumber}
          </GridItem>
          <GridItem colSpan={3} display="flex" alignItems="center" overflow="hidden">
            {item.sku || '-'}
          </GridItem>
          <GridItem colSpan={1} display="flex" alignItems="center">
            {item.quantity}
          </GridItem>
          <GridItem colSpan={3}>
            {item.serialNumbers?.map((serialNumber) => (
              <HStack>
                <Text>{serialNumber}</Text>
              </HStack>
            ))}
          </GridItem>
        </Grid>
      ))}
      {!Items.length && (
        <Box w="100%" h="200px" justifyContent="center" alignItems="center" display="flex">
          {!loading ? <EmptyTable icon={EmptyBox} title="No Items Found" /> : <>Loading...</>}
        </Box>
      )}
    </>
  );
}
