import { useMutation } from '@apollo/client';
import { clientOptionType } from '../SharedTypes';
import { UpsertRetailerFeatureInput, RetailerFeatureMutationResponse } from './retailer.types';
import { GET_RETAILER, UPSERT_RETAILER_FEATURES } from './retailers.graphql';

interface UpsertRetailerFeatureProps {
  payload: UpsertRetailerFeatureInput;
  onCompleted: (
    data: { upsertRetailerFeatures: RetailerFeatureMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpsertRetailerFeatureMutation() {
  const [mutate, { loading, error }] = useMutation<
    { upsertRetailerFeatures: RetailerFeatureMutationResponse },
    { upsertRetailerFeaturesInput: UpsertRetailerFeatureInput }
  >(UPSERT_RETAILER_FEATURES);

  async function upsertRetailerFeature({ payload, onCompleted }: UpsertRetailerFeatureProps) {
    return mutate({
      variables: {
        upsertRetailerFeaturesInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_RETAILER,
          variables: { retailerId: payload.retailerId },
        },
      ],
    });
  }

  return {
    upsertRetailerFeature,
    loading,
    error,
  };
}
