import { createContext, useContext } from 'react';
import { type Order } from 'src/components/Particles/resolvers';

interface FulfillmentOrdersSelectionContextProps {
  select: (order: Order) => void;
  unselect: (id: string) => void;
  unselectAll: () => void;
  selectedOrders: Map<string, Order>;
  detailedOrder?: Order;
  toggleDetailedOrder: (order?: Order) => void;
}

export const FulfillmentOrdersSelectionContext =
  createContext<FulfillmentOrdersSelectionContextProps>({
    select: console.info,
    unselect: console.info,
    unselectAll: () => {},
    selectedOrders: new Map(),
    toggleDetailedOrder: console.log,
  });

export function useFulfillmentOrderSelection() {
  return useContext(FulfillmentOrdersSelectionContext);
}
