import { useLazyQuery } from '@apollo/client';
import { FLAGGED_ORDER_COUNTS } from './orders.graphql';
import { type FlaggedOrdersCount } from './orders.types';

interface QueryResponse {
  flaggedOrdersCount: FlaggedOrdersCount;
}

export function useFlaggedOrderCountsQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(FLAGGED_ORDER_COUNTS);

  function getFlaggedOrderCounts() {
    void query({ pollInterval: 30000 });
  }

  return {
    getFlaggedOrderCounts,
    flaggedCounts: data?.flaggedOrdersCount,
    loading,
    error,
  };
}
