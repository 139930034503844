import { DriverDot } from '@swftbox/style-guide';
import blackDot from './../../../assets/blackMapDot.svg';
import styles from './drivers-tracking.module.scss';
import { type Footprint } from './services';

const DriversFootPrint = ({
  map,
  onlineActiveDriversIds,
  footprints,
}: {
  map: any;
  onlineActiveDriversIds: string[];
  footprints: Footprint[];
}) => {
  if (!onlineActiveDriversIds.length || !footprints.length) return null;

  const filteredFootprints = footprints.filter((footprint) =>
    onlineActiveDriversIds.includes(footprint.driverId)
  );

  return (
    <>
      {filteredFootprints.map((footprint) => (
        <DriverDot
          key={footprint?.driverId}
          // @ts-expect-error it's just to ignore dummy ts warning
          map={map}
          driver={footprint}
          icon={blackDot}
          label={{
            text: footprint.driverName, // The label text
            className: styles.mapDriverLabel,
          }}
        />
      ))}
    </>
  );
};

export default DriversFootPrint;
