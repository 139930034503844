import { useQuery, useMutation } from '@apollo/client';
import { UploadDirectory, useFileUpload } from '../upload';
import { GET_PAYMENTS, UPDATE_PAYMENT, REMOVE_PAYMENT, CREATE_PAYMENT } from './payments.graphql';
import {
  type CreatePaymentInput,
  type Payment,
  type PaymentFilterArgs,
  type UpdatePaymentInput,
} from './payments.type';
import { QueriesStore } from '../queriesStore';

interface QueryResponse {
  paginatedPayments: {
    total: number;
    currentPage: number;
    nextPage: number | null;
    pageCount: number;
    data: Payment[];
  };
}

export function usePaymentsQuery(filter: PaymentFilterArgs) {
  QueriesStore.update('GET_PAYMENTS', { ...filter });
  const { data, loading, error, ...rest } = useQuery<QueryResponse, PaymentFilterArgs>(
    GET_PAYMENTS,
    {
      variables: filter,
    }
  );

  return {
    payments: data?.paginatedPayments || null,
    loading,
    error,
    ...rest,
  };
}

export const useCreatePaymentMutation = () => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { createPayment: Payment },
    { createPaymentInput: CreatePaymentInput }
  >(CREATE_PAYMENT);

  const { uploadFile } = useFileUpload({ directory: UploadDirectory.RECEIPTS });

  async function createPayment(createPaymentInput: CreatePaymentInput) {
    let receipt = null;
    if (createPaymentInput.receipt instanceof File) {
      const { filePath } = await uploadFile(createPaymentInput.receipt);
      receipt = filePath;
    }

    return mutate({
      variables: {
        createPaymentInput: {
          ...createPaymentInput,
          receipt,
        },
      },
      refetchQueries: [{ query: GET_PAYMENTS, variables: QueriesStore.get('GET_PAYMENTS') }],
    });
  }

  return {
    createPayment,
    reset,
    loading,
    error,
    ...rest,
  };
};

export const useUpdatePaymentMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { updatePayment: Payment },
    { updatePaymentInput: UpdatePaymentInput }
  >(UPDATE_PAYMENT);

  const { uploadFile } = useFileUpload({ directory: UploadDirectory.RECEIPTS });

  async function updatePayment(updatePaymentInput: UpdatePaymentInput) {
    let receipt = updatePaymentInput.receipt;

    if (typeof receipt === 'string') {
      // If receipt is string, it was not updated, no need to update it
      receipt = undefined;
    } else if (typeof receipt === 'undefined') {
      // This happens when the user clears the file input
      // Set as null to be removed in backend
      receipt = null;
    } else if (updatePaymentInput.receipt instanceof File) {
      const { filePath } = await uploadFile(updatePaymentInput.receipt);
      receipt = filePath;
    }

    const { orderId, driverId, ...input } = updatePaymentInput;

    return mutate({
      variables: {
        updatePaymentInput: {
          ...input,
          amount: Number(updatePaymentInput.amount),
          receipt,
        },
      },
      refetchQueries: [{ query: GET_PAYMENTS, variables: QueriesStore.get('GET_PAYMENTS') }],
    });
  }

  return {
    updatePayment,
    reset,
    loading,
    error,
  };
};

export const useUpdatePaymentStatusMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { updatePayment: Payment },
    { updatePaymentInput: UpdatePaymentInput }
  >(UPDATE_PAYMENT);

  async function updatePaymentStatus(updatePaymentInput: UpdatePaymentInput) {
    return mutate({
      variables: {
        updatePaymentInput: { ...updatePaymentInput },
      },
      refetchQueries: ['GetPayments'],
    });
  }

  return {
    updatePaymentStatus,
    reset,
    loading,
    error,
  };
};

export const useRemovePaymentMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { removePayment: { success: boolean; message: string } },
    { id: string }
  >(REMOVE_PAYMENT);

  async function removePayment(id: string) {
    return mutate({
      variables: {
        id,
      },
      refetchQueries: [{ query: GET_PAYMENTS, variables: QueriesStore.get('GET_PAYMENTS') }],
    });
  }

  return {
    removePayment,
    reset,
    loading,
    error,
  };
};
