import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { type FulfillmentCenter } from 'src/components/Particles';
import { DeleteFulfillment, EditFulfillment, ViewFulfillment } from './Modal';

interface FulfillmentsTableProps {
  fulfillments: FulfillmentCenter[];
}

export default function FulfillmentsTable(props: FulfillmentsTableProps) {
  const { fulfillments } = props;

  if (!fulfillments.length) return null;

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead>
          <Tr bg="#fbfbfc">
            <Th>name</Th> <Th>City</Th>
            <Th>Country</Th>
            <Th>Location</Th>
            <Th>is Default</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {fulfillments.map((fulfillment) => {
            return (
              <Tr key={fulfillment.id}>
                <Td>
                  <Text>{fulfillment?.name}</Text>
                </Td>
                <Td>
                  <Text>{fulfillment?.location?.city}</Text>
                </Td>
                <Td>
                  <Text>{fulfillment.location.country}</Text>
                </Td>
                <Td>
                  <Tooltip label={fulfillment?.location?.addressLine1 || 'NA'}>
                    <Text overflow="hidden" noOfLines={1}>
                      {fulfillment?.location?.addressLine1 || 'NA'}
                    </Text>
                  </Tooltip>
                </Td>
                <Td>
                  <Text isTruncated>{fulfillment?.location?.isDefault ? 'Default' : ''}</Text>
                </Td>
                <Td>
                  <Flex gap={4} justifyContent="flex-end">
                    <ViewFulfillment fulfillment={fulfillment} />
                    <EditFulfillment fulfillment={fulfillment} />
                    {/* <DeleteFulfillment name={fulfillment.name} id={fulfillment.id} /> */}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
