import { Box, Flex, useDisclosure } from '@swftbox/style-guide';
import { memo, useCallback, useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  type InvoiceStatus,
  type InvoicesFilterArgs,
  SortOptions,
  useInvoicesQuery,
  useSearch,
} from 'src/components/Particles';
import { GenerateInvoiceModal, InvoicesTable, PageHeader } from './Components';
import { Filters } from './Components/Filters';
import styles from './style.module.scss';

export const InvoicesPage = memo(() => {
  const { searchText } = useSearch();
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<InvoicesFilterArgs>({
    perPage: 50,
    page: 1,
    orderBy: 'createdAt',
    order: SortOptions.DESC,
  });
  const [retailersIds, setRetailersIds] = useState<string[] | null>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { invoices, loading, total } = useInvoicesQuery(filters);

  const onFilterChange = useCallback((selectedFilter: InvoiceStatus[]) => {
    setFilters({ ...filters, status: selectedFilter });
  }, []);

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      ...(!!retailersIds?.length
        ? { retailerId: retailersIds as string[] | undefined }
        : { retailerId: null }),
      ...(!!searchText && { keyword: searchText }),
      page: page,
    }));
  }, [retailersIds, page, searchText]);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  return (
    <Box className={styles.invoicesPage}>
      <PageHeader
        title="Invoice"
        count={invoices.length || 0}
        buttonText={'Generate Invoice'}
        onButtonClick={onOpen}
      />
      <Filters
        onFilterChange={onFilterChange}
        retailersIds={retailersIds}
        setRetailersIds={setRetailersIds}
      />
      <InvoicesTable
        invoices={invoices}
        page={page}
        total={total || 0}
        onPageChange={handlePageChange}
      />
      {!loading && !invoices?.length && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Invoices Found" />
        </Flex>
      )}
      <GenerateInvoiceModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </Box>
  );
});
