import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../SharedTypes';
import { QueriesStore } from '../../queriesStore';
import {
  type UpdateTaskStatus,
  UPDATE_TASK_STATUS,
  type GeneralMessageResponse,
  GET_PAGINATED_TASKS,
  GET_TASK,
} from '../Queries';

interface UpdateTaskProps {
  payload: UpdateTaskStatus;
  onCompleted: (
    data: { updateTaskStatus: GeneralMessageResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateTaskStatusMutation() {
  const [mutate, { loading, error }] = useMutation<
    { updateTaskStatus: GeneralMessageResponse },
    { updateTaskStatusInput: UpdateTaskStatus }
  >(UPDATE_TASK_STATUS);

  async function updateTaskStatus({ payload, onCompleted }: UpdateTaskProps) {
    return mutate({
      variables: {
        updateTaskStatusInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_PAGINATED_TASKS,
          variables: QueriesStore.get('GET_TASKS'),
        },
        {
          query: GET_TASK,
          variables: { taskId: payload.id },
        },
      ],
    });
  }

  return {
    updateTaskStatus,
    loading,
    error,
  };
}
