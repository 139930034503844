import { createContext, useContext } from 'react';

export enum JobOption {
  ROUTE = 'ROUTE',
  PARTNER = 'PARTNER',
  RTO = 'RTO',
}

export enum RTOJobOption {
  BY_ORDERS = 'BY_ORDERS',
  BY_PARTNER = 'BY_PARTNER',
}

export interface SubJobOption {
  rtoType?: RTOJobOption;
}

interface JobTypeContextProps {
  jobType: JobOption;
  selectJobType: (jobType: JobOption) => void;
}

export const JobTypeContext = createContext<JobTypeContextProps>({
  jobType: JobOption.PARTNER,
  selectJobType: console.log,
});

export const useJobTypeContext = () => useContext<JobTypeContextProps>(JobTypeContext);
