import { useMutation } from '@apollo/client';
import { SortOptions, clientOptionType } from '../../SharedTypes';
import { GET_ROLES, REMOVE_ROLE, type RemoveRoleResponse } from '../Queries';
import { UserSortableFields } from '../../users';
import { GET_USERS } from '../../users/users.graphql';

interface AddRoleProps {
  onCompleted: (data: { removeRole: RemoveRoleResponse }, clientOptions?: clientOptionType) => void;
}
export function useRemoveRole({ onCompleted }: AddRoleProps) {
  const [mutate, { error, loading }] = useMutation<
    { removeRole: RemoveRoleResponse },
    { removeRoleId: number }
  >(REMOVE_ROLE, {
    onCompleted,
    refetchQueries: [
      { variables: { withPermissions: true }, query: GET_ROLES },
      {
        variables: {
          perPage: 10,
          order: SortOptions.DESC,
          orderBy: UserSortableFields.createdAt,
          page: 1,
        },
        query: GET_USERS,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
