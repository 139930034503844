import {
  Box,
  Button,
  Flex,
  HStack,
  SBMenu,
  SBSettingsSlidersIcon,
  Spacer,
} from '@swftbox/style-guide';
import { useCallback, ChangeEvent } from 'react';
import { Config } from 'src/config';

interface DriversFilterProps {
  setUserStatus: (value?: string[]) => void;
  setEmploymentType: (value?: string[]) => void;
  employmentType?: string[];
  userStatus?: string[];
}

const toggleElement = (array: string[], value: string) => {
  return array.includes(value) ? array.filter((el) => el !== value) : [...array, value];
};

export function DriversFilter(props: DriversFilterProps) {
  const { setUserStatus, setEmploymentType, employmentType, userStatus } = props;

  const employmentTypeChangeAction = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const employmentTypes = toggleElement(employmentType || [], e.target.value);
      setEmploymentType(employmentTypes.length ? employmentTypes : undefined);
    },
    [employmentType]
  );

  const checkboxItems = [
    {
      name: 'Employment Type',
      items: [
        {
          name: Config.PRIMEXPRESS_BUILD ? 'primex' : 'swftbox',
          value: Config.PRIMEXPRESS_BUILD ? 'PRIMEX' : 'SWFTBOX',
          onCheckAction: employmentTypeChangeAction,
        },
        {
          name: 'operator',
          value: 'OPERATOR',
          onCheckAction: employmentTypeChangeAction,
        },
        {
          name: 'Freelance',
          value: 'FREELANCE',
          onCheckAction: employmentTypeChangeAction,
        },
      ],
    },
  ];
  const StatsMocking = [
    {
      header: 'All',
      value: undefined,
    },
    {
      header: 'Pending',
      value: 'PENDING',
    },
    {
      header: 'Active',
      value: 'ACTIVE',
    },
    {
      header: 'Disabled',
      value: 'DISABLED',
    },
  ];

  return (
    <Flex align="center" p="20px 24px">
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
      >
        {StatsMocking.map((stat, index) => (
          <Button
            key={index}
            variant="grouped"
            fontSize="x-small"
            bg={stat.value === userStatus ? 'gray.100' : 'transparent'}
            onClick={() => setUserStatus(stat.value ? [stat.value] : undefined)}
          >
            {stat.header}
          </Button>
        ))}
      </HStack>
      <Spacer />
      <HStack spacing="24px">
        <SBMenu
          list={checkboxItems}
          closeOnSelect={false}
          isCheckboxMenu
          variant="outline"
          toggleButton={
            <HStack>
              <Box fontSize="x-small">Customized filter</Box>
              <SBSettingsSlidersIcon width="15px" />
            </HStack>
          }
        />
      </HStack>
    </Flex>
  );
}
