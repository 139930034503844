type ActionAddressHistory = {
  [key: string]: string; // This is the index signature
  CREATED: string;
  MARKED_DEFAULT: string;
  MATCHED: string;
  UPDATED_FROM_CITY_GETTER: string;
  UPDATED_FROM_GEO_CODER: string;
  UPDATED_MANUALLY: string;
};
export const actionAddressHistory: ActionAddressHistory = {
  CREATED: 'Auto',
  MARKED_DEFAULT: 'Auto',
  MATCHED: 'Auto',
  UPDATED_FROM_CITY_GETTER: 'CityGetter',
  UPDATED_FROM_GEO_CODER: 'Geocoder',
  UPDATED_MANUALLY: 'UPDATED_MANUALLY',
};

export const actionAddressTitle: ActionAddressHistory = {
  CREATED: 'Created',
  MARKED_DEFAULT: 'Marked Default',
  MATCHED: 'Updated',
  UPDATED_FROM_CITY_GETTER: 'Updated',
  UPDATED_FROM_GEO_CODER: 'Updated',
  UPDATED_MANUALLY: 'Manually Update',
};
