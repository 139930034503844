import { OrdersStatusFilter } from '../../../order.types';

export const mapHasAnyOfArrayElements = (map: Map<string, any>, array: string[]): boolean =>
  array.some((element) => map.has(element));

export const flattenMapValues = (map: Map<string, any[]>): string[] => {
  const arrayOfArrays = Array.from(map.values());
  return [...new Set(arrayOfArrays.flat())];
};

export const arrayContainsAny = (firstArray: string[] = [], secondArray: string[] = []) =>
  secondArray.some((element) => firstArray.includes(element));

export const toggleMapAddRemove = (map: any, item: any) => {
  const newMap = new Map(map);
  if (newMap.has(item.id)) {
    newMap.delete(item.id);
  } else {
    newMap.set(item.id, item.payload.status);
  }
  return newMap;
};

export const arraysAreEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
/* -------------------------------------------------------------------------- */
/*                          For Capitalize The State                          */
/* -------------------------------------------------------------------------- */

export const camelCaseToCapitalizedSpace = (str: string): string => {
  // Capitalize the special prefixes 'rto' or 'rtn' if present

  let result = str.replace(/([A-Z])/g, ' $1').toLowerCase();

  result = result.charAt(0).toUpperCase() + result.slice(1);

  result = result.replace(/ (\w)/g, (match) => match.toUpperCase());
  if (result.startsWith('Rto') || result.startsWith('Rtn')) {
    result =
      result.charAt(0).toUpperCase() +
      result.charAt(1).toUpperCase() +
      result.charAt(2).toUpperCase() +
      result.slice(3);
  }

  if (result.startsWith('Cs')) {
    result = 'CS' + result.slice(2);
  }

  return result;
};

/* -------------------------------------------------------------------------- */
/*                             To Get Status Count                            */
/* -------------------------------------------------------------------------- */

export function createStateCountLookup(counts: any = {}) {
  const lookup: Record<string, number> = {};
  const { detailedCounts, ...simpleCounts } = counts;
  Object.keys(simpleCounts).forEach((key) => {
    const normalizedKey = key.replace(/ /g, '_');
    if (['READY_FOR_PICKUP', 'TRANSIT_ASSIGNED'].includes(normalizedKey)) {
      lookup.READY_FOR_PICKUP = (lookup.READY_FOR_PICKUP || 0) + (counts[key] as number);
    } else if (['ASSIGNED', 'DELIVERING', 'FLAGGED'].includes(normalizedKey)) {
      lookup.DELIVERING = (lookup.DELIVERING || 0) + (counts[key] as number);
    } else if (['READY', 'FAILED_ATTEMPT'].includes(normalizedKey)) {
      lookup.READY = (lookup.READY || 0) + (counts[key] as number);
    } else {
      lookup[normalizedKey] = counts[key] as number;
    }
  });

  if (detailedCounts) {
    lookup[OrdersStatusFilter.FLAGGED] = (detailedCounts['validationStatus.FAILED'] as number) ?? 0;
    lookup[OrdersStatusFilter.ASSIGNED] = (detailedCounts['status.ASSIGNED'] as number) ?? 0;
  }

  return lookup;
}

/* -------------------------------------------------------------------------- */
/*                      Status Name And Background Colors                     */
/* -------------------------------------------------------------------------- */

export const statusNameMask = (status: OrdersStatusFilter) => {
  switch (status) {
    case OrdersStatusFilter.DRAFT:
      return { name: 'Draft', bg: '#777', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.PICKUP_REQUESTED:
      return { name: 'Pickup Requested', bg: '#FFB800', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.PICKUP_ASSIGNED:
      return { name: 'Pickup Assigned', bg: '#00B976', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.PICKED_UP:
      return { name: 'Picked Up', bg: '#32C6F1', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTN_REQUESTED:
      return { name: 'RTN Requested', bg: '#FFB800', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTN_ASSIGNED:
      return { name: 'RTN Assigned', bg: '#00B976', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTN_PICKED_UP:
      return { name: 'RTN Picked Up', bg: '#32C6F1', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.UNASSIGNED:
      return { name: 'Unassigned', bg: '#FFB800', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.ASSIGNED:
      return { name: 'Assigned', bg: '#00B976', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.DELIVERING:
      return { name: 'Delivering', bg: '#32C6F1', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.ATTEMPTED:
      return { name: 'Attempted', bg: '#fff', color: '#FF6060', border: '#FF6060' };

    case OrdersStatusFilter.RTN_AT_DEPOT:
      return { name: 'RTN at Depot', bg: '#FFB800', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTO_INITIATED:
      return { name: 'RTO Initiated', bg: '#FFB800', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTO_ASSIGNED:
      return { name: 'RTO Assigned', bg: '#00B976', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTN_TO_PARTNER:
      return { name: 'RTN to Partner', bg: '#32C6F1', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTO_PICKED_UP:
      return { name: 'RTO Picked Up', bg: '#32C6F1', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTN_COMPLETED:
      return { name: 'RTN Complete', bg: '#fff', color: '#000', border: '#000' };

    case OrdersStatusFilter.RTO_COMPLETE:
      return { name: 'RTO Complete', bg: '#fff', color: '#000', border: '#000' };

    case OrdersStatusFilter.DELIVERED:
      return { name: 'Delivered', bg: '#fff', color: '#000', border: '#000' };

    case OrdersStatusFilter.IN_FULFILLMENT:
      return { name: 'in Fulfillment', bg: '#FFB800', color: '#fff', border: 'transparent' };

    case OrdersStatusFilter.RTN_TO_PARTNER_ASSIGNED:
      return {
        name: 'RTN to Partner Assigned',
        bg: '#32C6F1',
        color: '#fff',
        border: 'transparent',
      }; // need action

    case OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE:
      return { name: 'Fulfillment RTO Complete', bg: '#fff', color: '#000', border: '#000' }; // need acction

    case OrdersStatusFilter.CANCELLED:
      return { name: 'Canceled', bg: '#fff', color: '#000', border: '#000' };

    case OrdersStatusFilter.RTN_FAILED:
      return { name: 'RTN Failed', bg: '#fff', color: '#000', border: '#000' };

    // Add a default case if needed
    default:
      return {
        name: `Not Clear (${camelCaseToCapitalizedSpace(status)})`,
        bg: 'red',
        color: '#fff',
        border: 'transparent',
      };
  }
};
