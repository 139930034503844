import { useLazyQuery } from '@apollo/client';
import { GET_ORDER } from './orders.graphql';
import { type Order } from './orders.types';

interface QueryResponse {
  Order: Order;
}

export function useOrderQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_ORDER);

  function getOrder(id: string) {
    void query({ variables: { orderId: id } });
  }

  return {
    getOrder,
    order: data?.Order,
    loading,
    error,
  };
}
