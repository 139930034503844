import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBCalendarIcon,
  SBDateRangePicker,
  Text,
} from '@swftbox/style-guide';
import { addDays } from 'date-fns';
import { format } from 'date-fns/esm';
import { memo, useState } from 'react';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import {
  ErrorBoundary,
  useFulfillmentOrdersFilters,
  useFulfillmentOrdersFiltersActions,
} from 'src/components/Particles';

const initialValue = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
    selected: false,
  },
];

function DateRange() {
  const [state, setState] = useState<any>(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useFulfillmentOrdersFiltersActions();
  const {
    organizedFilters: { dateFilter },
  } = useFulfillmentOrdersFilters();

  const handleChangeDate = (isApply: boolean) => {
    if (isApply) {
      dispatch({
        type: 'ORDER_DATE_CHANGE',
        payload: { dateFilter: { ...state[0], isSelected: true } },
      });
    } else {
      dispatch({ type: 'ORDER_DATE_CHANGE', payload: { dateFilter: initialValue[0] } });
    }
    setIsOpen(false);
  };

  const resetStateAfterClose = () => {
    if (dateFilter) {
      setState([dateFilter]);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <ErrorBoundary>
      <Box position="relative">
        {dateFilter?.isSelected && (
          <Button
            variant="link"
            color="#63C3EC"
            bg="#fff"
            position="absolute"
            minW="15px"
            h="15px"
            fontWeight="semibold"
            fontSize="9px"
            borderRadius="50%"
            top="13px"
            left="10px"
            zIndex={10}
            _hover={{ opacity: '0.8' }}
            onClick={() => {
              handleChangeDate(false);
            }}
          >
            <SVG src={XIcon} width="8px" stroke="currentColor" />
          </Button>
        )}
        <Menu closeOnSelect={false} onClose={resetStateAfterClose} isOpen={isOpen}>
          <MenuButton
            as={Button}
            whiteSpace="nowrap"
            transition="all 0.3s"
            h="40px"
            borderRadius="8px"
            boxShadow="xs"
            fontSize="x-small"
            border="1px solid"
            fontWeight="bold"
            onClick={() => {
              setIsOpen(true);
            }}
            w={dateFilter?.isSelected ? 'min-content' : '185px'}
            bg={dateFilter?.isSelected ? '#C9E9F6 !important' : '#fff'}
            color={dateFilter?.isSelected ? '#fff' : 'gray.700'}
            borderColor={dateFilter?.isSelected ? '#63C3EC' : '#D0D5DD'}
            _hover={{ opacity: '0.7' }}
            _focus={{ opacity: '0.7' }}
          >
            <HStack justifyContent="space-between">
              {dateFilter?.isSelected ? (
                <Text bg="#63C3EC" py="1" px="2" borderRadius="8px" fontSize="x-small" ms="7px">
                  {format(dateFilter?.startDate ?? new Date(), 'MMM dd, yyyy')} - {''}
                  {format(dateFilter?.endDate ?? new Date(), 'MMM dd, yyyy')}
                </Text>
              ) : (
                <>
                  <Text fontSize="x-small">Date Range</Text>
                  <SBCalendarIcon width="15px" />
                </>
              )}
            </HStack>
          </MenuButton>
          <MenuList>
            <SBDateRangePicker setValue={setState} value={state} />
            <HStack justifyContent="end" p="3" borderTop="1px solid #eee">
              <Button
                minW="200px"
                variant="outline"
                onClick={() => {
                  handleChangeDate(false);
                }}
              >
                Cancel
              </Button>
              <Button
                minW="200px"
                onClick={() => {
                  handleChangeDate(true);
                }}
              >
                Apply
              </Button>
            </HStack>
          </MenuList>
        </Menu>
      </Box>
    </ErrorBoundary>
  );
}

export default memo(DateRange);
