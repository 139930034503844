import { type AnyObject, type TestContext } from 'yup';

const TOO_SHORT_MSG = 'Too short!';
const TOO_LONG_MSG = 'Too long!';

export function createMinMaxOptionalValidation(min: number, max: number) {
  return function (val: string | undefined, context: TestContext<AnyObject>) {
    if (!val) return true;
    if (val?.length < min) {
      return context.createError({
        message: TOO_SHORT_MSG,
        path: context.path,
      });
    }
    if (val?.length > max) {
      return context.createError({
        message: TOO_LONG_MSG,
        path: context.path,
      });
    }

    return true;
  };
}
