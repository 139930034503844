import { Box, HStack, Text } from '@swftbox/style-guide';
import { useCallback, useState } from 'react';
import { VerticalTabs } from 'src/components/Organisms';
import { Retailer } from 'src/components/Particles';
import { MainTabsEnum, PickupAndDropSubtabsEnum, subtabMapping } from '../../Helper';
import { DropSettings } from './Components/DropSettings';
import { PickupSettings } from './Components/PickupSettings';

interface Props {
  retailer: Retailer;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
  allowToMove: { page: string; isAllowed: boolean };
}
export const PickupAndDrop = ({ retailer }: Props) => {
  const [activeTab, setActiveTab] = useState<PickupAndDropSubtabsEnum>(
    PickupAndDropSubtabsEnum.PickupSettings
  );

  const handleChangeTab = useCallback(
    (tabId: string) => setActiveTab(tabId as PickupAndDropSubtabsEnum),
    []
  );
  const pickupAndDropTabs = subtabMapping[MainTabsEnum.PickupAndDrop];
  return (
    <Box>
      <HStack align="start" h="100%" flexWrap="wrap">
        <Box position="fixed" top="125" left="5" zIndex={2} bg="#fff" h="100%" w="165px">
          <VerticalTabs
            tabs={pickupAndDropTabs}
            handleChange={handleChangeTab}
            active={activeTab}
          />
        </Box>

        <Box flexGrow="1" h="100%" ps="210px" pt="1">
          {activeTab === PickupAndDropSubtabsEnum.PickupSettings && (
            <PickupSettings back={() => console.log('Hello')} retailerId={retailer.id} />
          )}
          {activeTab === PickupAndDropSubtabsEnum.DropSettings && (
            <DropSettings back={() => console.log('Hello')} retailerId={retailer.id} />
          )}
        </Box>
      </HStack>
    </Box>
  );
};
