import { type FC, useState, useEffect } from 'react';
import { Box, Text, Button, SBAddIcon, HStack } from '@swftbox/style-guide';
import { SuperDropProfilesTable } from './SuperDropProfilesTable';
import { DropProfileForm } from './DropProfileDetails/DropProfileForm';
import { type DropProfile, useDropProfilesQuery } from 'src/components/Particles';
import { SuperDropProfileForm } from './SuperDropProfile';

interface Props {
  back: () => void;
  retailerId: string;
}

export const DropSettings: FC<Props> = ({ back, retailerId }) => {
  const [showForm, setShowForm] = useState(false);
  const [showEditProfileForm, setShowEditProfileForm] = useState(false);
  const [editDropProfile, setEditDropProfile] = useState<DropProfile>();
  const { getDropProfiles, dropProfiles, loading, refetch } = useDropProfilesQuery(retailerId);

  useEffect(() => {
    void getDropProfiles();
  }, [retailerId, loading, refetch, getDropProfiles]);

  const handleEditDropProfile = (dropProfile: DropProfile) => {
    setEditDropProfile(dropProfile);
    setShowEditProfileForm(true);
  };

  const handleCloseForm = () => {
    setShowEditProfileForm(false);
    setEditDropProfile(undefined);
  };

  const existingCitiesDropProfiles = dropProfiles
    ? dropProfiles.reduce<string[]>((acc, dropProfile) => {
        return acc.concat(dropProfile.cityNames);
      }, [])
    : [];

  console.log({ showEditProfileForm, showForm, editDropProfile });

  return (
    <Box>
      {!showForm && !showEditProfileForm && (
        <>
          <HStack justifyContent="space-between" mb="4">
            <Box />

            <Button size="sm" fontSize="text-sm" onClick={setShowForm.bind(null, true)}>
              <SBAddIcon width="13px" />
              <Text>Create Super Drop Profile</Text>
            </Button>
          </HStack>
          <SuperDropProfilesTable
            superDropProfiles={dropProfiles ?? []}
            editDropProfile={handleEditDropProfile}
            loading={loading}
            retailerId={retailerId}
          />
        </>
      )}
      {!!showEditProfileForm && (
        <DropProfileForm
          dropProfiles={dropProfiles ?? []}
          retailerId={retailerId}
          dropProfile={editDropProfile}
          onClose={handleCloseForm}
          back={back}
        />
      )}

      {!!showForm && (
        <SuperDropProfileForm
          retailerId={retailerId}
          existingCitiesDropProfiles={existingCitiesDropProfiles}
          editDropProfile={handleEditDropProfile}
          onClose={() => {
            setShowForm(false);
          }}
        />
      )}
    </Box>
  );
};
