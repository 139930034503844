import { SingleDropdownFilter } from 'src/components/Molecules';
import { delayedOrdersList } from '../../../../OrganizingFilters/constants';
import { useOrdersFilters, useOrdersFiltersActions } from 'src/components/Particles';
import { endOfDay, startOfDay } from 'date-fns';

type Props = {};
export const DelayedOrders = ({}: Props) => {
  const dispatch = useOrdersFiltersActions();

  const {
    organizedFilters: { dateFilter },
  } = useOrdersFilters();
  const handleSelectLateOrder = (item: any) => {
    let dateFilter = {};
    if (item) {
      dateFilter = {
        startDate: startOfDay(item.value.startDate),
        endDate: endOfDay(item.value.endDate),
        isSelected: true,
        key: 'expectedDropEnd',
      };
    } else {
      dateFilter = {};
    }
    dispatch({
      type: 'ORDER_DATE_CHANGE',
      payload: { dateFilter },
    });
  };
  return (
    <SingleDropdownFilter
      name="Delayed Orders"
      list={delayedOrdersList}
      onSelect={handleSelectLateOrder}
      selectedFilter={!!dateFilter?.isSelected ? null : ''}
    />
  );
};
