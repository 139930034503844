import uuid from 'react-uuid';
import { InvoiceStatus } from 'src/components/Particles';

export const statusFilter = [
  { id: uuid(), label: 'Active', value: [InvoiceStatus.DRAFT, InvoiceStatus.ISSUED, InvoiceStatus.PARTIALLY_PAID, InvoiceStatus.OVERDUE], background: '#ebf9fe' },
  ...Object.values(InvoiceStatus).map((value) => ({
    id: uuid(),
    value: [value],
    label: value
      .split('_')
      .map((word, index) => {
        if (index === 0) {
          // Capitalize the first word
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        // Keep the rest lowercase
        return word.toLowerCase();
      })
      .join(' '),
    background: '#ebf9fe',
  })),
];
