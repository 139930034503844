import { Box } from '@swftbox/style-guide';
import 'rc-slider/assets/index.css';
import DataOnSlider from './DataOnSlider';
import SliderFilter from './SliderFilter';
import './TimeFilter.scss';
import { useTimelineFilterQuery } from 'src/components/Particles/resolvers/orders/Filters/TimelineFilter.service';
import { endOfDay, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { useOrdersFilters, useSearch } from 'src/components/Particles';
import { handleORClauseFilter } from '../../helper';

// Define the marks for the slider
export const TimelineFilter = () => {
  const { searchText } = useSearch();

  const {
    filters: {
      stateStageFilter,
      status,
      stopStatus,
      validationStatus,
      statusId,
      failedAttemptCount,
    },
    organizedFilters: { dateFilter, isInternational, isRemote, chilled, ...organizedFilters },
  } = useOrdersFilters();

  const dropProfileOrStatusFilter =
    organizedFilters?.or?.dropProfileKey?.length || statusId?.length;

  const variables = useMemo(
    () => ({
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
      failedAttemptCount,
      ...(searchText ? { search: searchText } : {}),
      stopStatus: stopStatus?.length ? stopStatus : undefined,
      ...stateStageFilter,
      status: status?.length ? status : undefined,
      validationStatus: validationStatus?.length ? validationStatus : undefined,
      ...organizedFilters,
      isInternational: !!isInternational,
      isRemote: !!isRemote,
      chilled: !!chilled,
      ...(dropProfileOrStatusFilter &&
        handleORClauseFilter(
          organizedFilters.or?.dropProfileKey,
          !!isInternational,
          !!isRemote,
          statusId
        )),
    }),
    [
      stopStatus,
      stateStageFilter,
      status,
      isInternational,
      isRemote,
      chilled,
      validationStatus,
      dropProfileOrStatusFilter,
      organizedFilters.or?.dropProfileKey,
      statusId,
    ]
  );

  const { sliderDataInfo } = useTimelineFilterQuery({ variables });

  return (
    <Box bg="#fff" border="1px solid #e9eaed" borderRadius="16px" p="12" my="3">
      <SliderFilter />
      <DataOnSlider sliderDataInfo={sliderDataInfo} />
    </Box>
  );
};
