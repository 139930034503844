import { AnalyticsPage } from './Analytics.page';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'src/components/Particles';

export function AnalyticsPageWrapper() {
  return (
    <>
      <Helmet>
        <title> Analytics </title>
      </Helmet>
      <ErrorBoundary>
        <AnalyticsPage />
      </ErrorBoundary>
    </>
  );
}
