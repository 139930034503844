import { Table, Tbody, Td, Text, Th, Thead, Tr, HStack, Badge } from '@swftbox/style-guide';
import { type FC } from 'react';
import { ActionButtons } from '../DropProfileDetails/ActionButtons';
import { DropProfileKey, type DropProfile } from 'src/components/Particles';
import { sortDropProfiles } from '../utilities';

interface DropProfileProps {
  dropProfiles: DropProfile[];
  retailerId: string;
  groupId: string;
  onEditDropProfile: (dropProfile: DropProfile) => void;
  onViewDetails: (dropProfile: DropProfile) => void;
}

const tableHeaders = [
  { title: 'Name', subtitle: '', id: 'name' },
  { title: 'Type', subtitle: '', id: 'type' },
  { title: 'Cuttof time', subtitle: '', id: 'cutoffTime' },
  { title: 'Configured Cities', subtitle: '', id: 'billingSlab' },
  { title: 'Status', subtitle: '', id: 'isDefault' },
  { title: '', subtitle: '', id: 'actions' },
];

const DropProfileTable: FC<DropProfileProps> = ({
  dropProfiles,
  retailerId,
  onEditDropProfile,
  onViewDetails,
  groupId,
}) => {
  // Sort drop profiles
  const sortedDropProfiles = sortDropProfiles(dropProfiles);

  return (
    <Table variant="striped" mb="8" mt="2" key={groupId}>
      <Thead>
        <Tr bg="#fbfbfc" color="neutral.normal">
          {tableHeaders.map((header) => (
            <Th key={header.id}>
              <Text>{header.title}</Text>
              <Text color="neutral.normal">{header.subtitle}</Text>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {sortedDropProfiles.map((dropProfile, index) => (
          <Tr key={dropProfile.id} title={dropProfile.description}>
            <Td>
              <Text>{dropProfile.name}</Text>
            </Td>
            <Td>
              <Text>{dropProfile.profileType}</Text>
            </Td>
            <Td>
              <Text maxW="100%" fontWeight="semibold">
                {dropProfile.cutoffTime.replace(/:00$/, '')}
              </Text>
            </Td>
            <Td>
              <Text>{dropProfile.cities.length}</Text>
            </Td>

            <Td>
              <HStack spacing={2}>
                {dropProfile.isPrimary && (
                  <Badge
                    p="2px 8px"
                    fontWeight="medium"
                    borderRadius="8px"
                    textAlign={'center'}
                    fontSize="text-xs"
                    variant="solid"
                    colorScheme="blue"
                  >
                    Primary
                  </Badge>
                )}
                {!dropProfile.isActive && (
                  <Badge
                    p="2px 8px"
                    fontWeight="medium"
                    borderRadius="8px"
                    textAlign={'center'}
                    fontSize="text-xs"
                    variant="solid"
                    colorScheme="red"
                  >
                    Disabled
                  </Badge>
                )}
              </HStack>
            </Td>

            <Td>
              <ActionButtons
                dropProfile={dropProfile}
                onViewDetails={onViewDetails}
                onEdit={onEditDropProfile}
                retailerId={retailerId}
                groupId={groupId}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default DropProfileTable;
