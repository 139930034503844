import { useMutation } from '@apollo/client';
import { REMOVE_DRIVER, GET_DRIVERS } from './drivers.graphql';
import { QueriesStore } from '../queriesStore';

interface RemoveDriverResponse {
  success: boolean;
  message: string;
}

export function useRemoveDriverMutation() {
  const [mutate, { data, loading, error }] = useMutation<{ removeDriver: RemoveDriverResponse },{ removeDriverId: string}>(REMOVE_DRIVER, {
    variables: {
      removeDriverId: '',
    },
    refetchQueries: [{query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }]
  })

  async function removeDriver(payload: string) {
    await mutate({
      variables: {
        removeDriverId: payload,
      },
      refetchQueries: [{query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS')}]
    });
  }

  return {
    removeDriver,
    success: data?.removeDriver.success && !error,
    loading,
    error,
  };
}
