import * as yup from 'yup';
import { DefaultRoles } from '../helper';

export const roleCreateSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .test('default role', 'Role name must not be one of the default roles', function (value) {
      return !DefaultRoles.includes(value.replace(' ', '').toLowerCase());
    }),
  permissions: yup
    .array()
    .required('Permissions are required')
    .min(1, 'Permissions must not be empty'),
});

export const roleUpdateSchema = yup.object().shape({
  id: yup.number().required('ID is required'),
  name: yup
    .string()
    .test('default role', 'Role name must not be one of the default roles', function (value) {
      if (value) return !DefaultRoles.includes(value.replace(' ', '').toLowerCase());
      return true;
    })
    .optional(),
  permissions: yup
    .array()
    .required('Permissions are required')
    .min(1, 'Permissions must not be empty'),
});
