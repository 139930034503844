import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_SUBSCRIPTIONS_PLAN,
  DELETE_SUBSCRIPTIONS_PLAN,
  GET_SUBSCRIPTIONS_PLANS,
  PUT_SUBSCRIPTIONS_PLAN,
  type DeletePlanQueryResponse,
  type GetPlanQueryResponse,
  type DeletePlanQueryRequest,
  type CreatePlanQueryResponse,
  type CreatePlanQueryRequest,
  type EditPlanQueryResponse,
  type EditPlanQueryRequest,
} from '../Queries';

export const useSubscriptionsPlans = () => {
  const { data, ...rest } = useQuery<GetPlanQueryResponse>(GET_SUBSCRIPTIONS_PLANS);
  return { plans: data?.subscriptionPlans, rest };
};

export const useCreatePlan = () => {
  const [createMutate, { ...rest }] = useMutation<CreatePlanQueryResponse, CreatePlanQueryRequest>(
    CREATE_SUBSCRIPTIONS_PLAN,
    {
      refetchQueries: [GET_SUBSCRIPTIONS_PLANS],
    }
  );
  return {
    createMutate,
    rest,
  };
};

export const useUpdatePlan = () => {
  const [updateMutate, { ...rest }] = useMutation<EditPlanQueryResponse, EditPlanQueryRequest>(
    PUT_SUBSCRIPTIONS_PLAN
  );
  return {
    updateMutate,
    rest,
  };
};

export const useDeletePlan = () => {
  const [deleteMutate, { ...rest }] = useMutation<DeletePlanQueryResponse, DeletePlanQueryRequest>(
    DELETE_SUBSCRIPTIONS_PLAN,
    {
      refetchQueries: [GET_SUBSCRIPTIONS_PLANS],
    }
  );
  return {
    deleteMutate,
    rest,
  };
};
