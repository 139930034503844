import { Box, BoxProps } from '@swftbox/style-guide';

import style from './ElementLoader.module.scss';
export const ElementLoader = (styling: BoxProps) => {
  return (
    <Box className={style.skeleton} {...styling}>
      {/* You can customize the skeleton's appearance here */}
    </Box>
  );
};
