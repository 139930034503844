import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface SidebarExpandProviderProps {
  children: ReactNode;
}

interface SidebarExpandContextProps {
  sidebarIsExpand: boolean;
  setSidebarIsExpand: Dispatch<SetStateAction<boolean>>;
}

export const SidebarExpandContext = createContext<SidebarExpandContextProps>({
  sidebarIsExpand: false,
  setSidebarIsExpand: (isExpanded: SetStateAction<boolean>): void => {},
});

export const SidebarExpandProvider = ({ children }: SidebarExpandProviderProps) => {
  const [sidebarIsExpand, setSidebarIsExpand] = useState(false);
  const contextValue = useMemo(
    () => ({ sidebarIsExpand, setSidebarIsExpand }),
    [sidebarIsExpand, setSidebarIsExpand]
  );
  return (
    <SidebarExpandContext.Provider value={contextValue}>{children}</SidebarExpandContext.Provider>
  );
};

export const useSidebarExpansionContext = () =>
  useContext<SidebarExpandContextProps>(SidebarExpandContext);
