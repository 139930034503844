import { type DropProfile } from 'src/components/Particles';

export const sortDropProfiles = (profiles: DropProfile[]) => {
  const order = ['sameday', 'nextday', 'timeslots', 'express4h', 'express2h'];
  return [...profiles].sort((a, b) => {
    const aIndex = order.indexOf(a.key);
    const bIndex = order.indexOf(b.key);

    // If a key is not found in the order array, place it at the end
    const aFinalIndex = aIndex === -1 ? order.length : aIndex;
    const bFinalIndex = bIndex === -1 ? order.length : bIndex;

    return aFinalIndex - bFinalIndex;
  });
};
