import { addDays, eachMinuteOfInterval, format, setHours, startOfDay } from 'date-fns';
import { type Dispatch, type MutableRefObject } from 'react';
import { ElasticSearchSortOptions, OrderSortableFields } from 'src/components/Particles';

export interface DropDownItem {
  label: string;
  value: string;
  [x: string]: any;
}

export const sortInitialVariables = {
  order: ElasticSearchSortOptions.DESC,
  orderBy: OrderSortableFields.createdAt,
};

export const ordersQueryInitialVariables = {
  perPage: 50,
  ...sortInitialVariables,
};
export const infiniteScroll = {
  displayedRowsCount: 16,
  increaseCount: 10,
};

const getIsReverse = (selectedDropProfiles: any) => {
  if (selectedDropProfiles.includes('returns')) {
    return true;
  } else if (
    selectedDropProfiles.some((profile: string) =>
      ['express2h', 'express4h', 'timeslots'].includes(profile)
    )
  ) {
    return false;
  } else return undefined;
};

const getGroupType = (selectedDropProfiles: any) => {
  if (selectedDropProfiles.includes('exchanges')) {
    return 'EXCHANGE';
  } else if (
    selectedDropProfiles.some((profile: string) =>
      ['express2h', 'express4h', 'timeslots', 'returns'].includes(profile)
    )
  ) {
    return null;
  } else return undefined;
};

const getStatus = (
  statusId: string,
  selectedDropProfiles: any,
  isInternational: boolean,
  isRemote: boolean
) => {
  let complexFilter;
  let statusObj;

  const isSelectedDropProfile = selectedDropProfiles?.length;
  const isExpressFilter = selectedDropProfiles?.some((type: string) => type === 'express2h');

  switch (statusId) {
    case 'active':
      statusObj = [
        'unassigned',
        'rtnRequested',
        'assigned',
        'rtnAssigned',
        'rtnPickedUp',
        'delivering',
      ];

      complexFilter = {
        or: !isSelectedDropProfile
          ? [
              {
                status: ['pickupRequested'],
                isExpress: true,
              },
              {
                status: ['pickupAssigned'],
                ...(!isInternational && { isInternational: false }),
                ...(!isRemote && { isRemote: false }),
                isExpress: true,
              },
              {
                status: ['assigned', 'rtnAssigned', 'rtnPickedUp', 'delivering'],
                ...(!isInternational && { isInternational: false }),
                ...(!isRemote && { isRemote: false }),
              },
              { status: ['unassigned', 'rtnRequested'] },
            ]
          : [],
        status: isExpressFilter
          ? [...statusObj, 'pickupRequested', 'pickupAssigned']
          : isSelectedDropProfile
          ? statusObj
          : undefined,
      };
      break;

    case 'unassigned':
      statusObj = ['unassigned', 'rtnRequested'];

      complexFilter = {
        or: !isSelectedDropProfile
          ? [
              {
                status: ['pickupRequested'],
                isExpress: true,
              },
              {
                status: statusObj,
              },
            ]
          : [],
        status: isExpressFilter
          ? [...statusObj, 'pickupRequested']
          : isSelectedDropProfile
          ? statusObj
          : undefined,
      };
      break;

    case 'assigned':
      statusObj = ['assigned', 'rtoAssigned', 'rtnAssigned'];

      complexFilter = {
        or: !isSelectedDropProfile
          ? [
              {
                status: ['pickupAssigned'],
                isExpress: true,
                ...(!isInternational && { isInternational: false }),
                ...(!isRemote && { isRemote: false }),
              },
              {
                status: statusObj,
                ...(!isInternational && { isInternational: false }),
                ...(!isRemote && { isRemote: false }),
              },
            ]
          : [],
        status: isExpressFilter
          ? [...statusObj, 'pickupAssigned']
          : isSelectedDropProfile
          ? statusObj
          : undefined,
      };
      break;
  }

  return complexFilter;
};

const getDropProfileKeys = (selectedDropProfiles: any) => {
  if (!selectedDropProfiles?.length) return [];
  const filterAllButReturnAndExchange = selectedDropProfiles.filter((type: string) => type !== 'returns' && type !== 'exchanges');
  const groupType = getGroupType(selectedDropProfiles);

  return [
    {
      dropProfileKey: filterAllButReturnAndExchange.length ? filterAllButReturnAndExchange : undefined,
      isReverse: getIsReverse(selectedDropProfiles),
      groupType,
    },
  ];
};

export const handleORClauseFilter = (
  selectedDropProfiles: any,
  isInternational: boolean | null,
  isRemote: boolean | null,
  statusId?: string[]
) => {
  const isSingleStatus = statusId?.some((status) =>
    ['active', 'unassigned', 'assigned'].includes(status)
  );

  const isFlagStatus = statusId?.some((status) =>
    ['flagged', 'csCheck', 'starred'].includes(status)
  );

  const isExchangeFilter = selectedDropProfiles?.includes('exchanges');

  const statusCombinedFilter = isSingleStatus
    ? // @ts-expect-error-error
      getStatus(statusId[0], selectedDropProfiles, !!isInternational, !!isRemote)
    : undefined;

  const dropProfileKeys = getDropProfileKeys(selectedDropProfiles);

  const notFilters: Record<string, string[]> = {};

  if (!isFlagStatus) {
    notFilters['flagType'] = ['merchant', 'cs'];
  }

  if (!isExchangeFilter && !!selectedDropProfiles?.length) {
    notFilters['groupType'] = ['EXCHANGE'];
    dropProfileKeys[0].groupType = undefined;
  }

  return {
    or: [
      ...(statusCombinedFilter?.or ?? []),
      ...dropProfileKeys,
    ],
    ...(isSingleStatus ? { status: statusCombinedFilter?.status } : {}),
    not: notFilters,
  };
};

export const handleInfiniteScrollInTable = ({
  tableRef,
  setDisplayedRows,
}: {
  tableRef: MutableRefObject<null>;
  setDisplayedRows: Dispatch<React.SetStateAction<number>>;
}) => {
  if (tableRef.current) {
    const { offsetTop, scrollHeight } = tableRef.current;
    const scrolledPastTableTop = window.scrollY > offsetTop;
    const scrolledToTableBottom =
      window.scrollY + window.innerHeight >= offsetTop + scrollHeight - 200;

    if (scrolledPastTableTop && scrolledToTableBottom) {
      setDisplayedRows((prevCount) => prevCount + infiniteScroll.increaseCount);
    }
  }
};

export const getRtoReasonsOptions = () => {
  const markAsRTOReasons = [
    'Customer Cancelled',
    'Duplicate Order',
    'Wrong Order',
    'No Response',
    'Incorrect Phone number',
    'Incorrect Address',
    'Out of Zone',
    'Did not Order',
    'Customer not available',
    'Partner Cancelled',
    'Return',
  ];

  return markAsRTOReasons.map((reason) => ({ label: reason, value: reason }));
};

export const getDateSlots = () => {
  const today = new Date();
  const dates = Array.from({ length: 14 }, (_, i) => addDays(today, i));
  return dates.map((date) => ({
    label: format(date, 'MMM d, yyyy'),
    value: format(date, 'yyyy-MM-dd'),
  }));
};

export const getSingleTimeSlots = (): DropDownItem[] => {
  const startHour = 8;
  const endHour = 23;
  const interval = 30;

  const startDate = startOfDay(new Date());
  const startOfDayWithStartHour = setHours(startDate, startHour);
  const endOfDayWithEndHour = setHours(startDate, endHour);

  return eachMinuteOfInterval(
    { start: startOfDayWithStartHour, end: endOfDayWithEndHour },
    { step: interval }
  ).map((time) => ({
    label: format(time, 'HH:mm'),
    value: format(time, 'HH:mm'),
  }));
};

export const getNearestNext30Min = () => {
  const date = new Date();
  const minutes = date.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 30) * 30;

  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return format(date, 'HH:mm');
};
