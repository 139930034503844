import { FC, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  SBModal,
  Select,
  Text,
  toast,
  useDisclosure,
  SBTextArea,
} from '@swftbox/style-guide';
import {
  CreateSettlementInput,
  useCreateSettlementMutation,
  Settlement,
  useUpdateSettlementMutation,
  SubjectType,
  SettlementType,
  UpdateSettlementInput,
} from 'src/components/Particles';
import { Controller, useForm } from 'react-hook-form';
import { DriverSelectInput } from 'src/components/Organisms/DriverSelectInput';
import { RetailerSelectInput } from 'src/components/Organisms/RetailerSelectInput';
import FileInput from 'src/components/Atom/FileInput/FileInput';

interface Props {
  settlement?: Settlement;
  onModalClose: () => void;
  settlementType?: SettlementType;
}

export const SettlementForm: FC<Props> = ({ settlement, onModalClose, settlementType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [fileInputLabel, setFileInputLabel] = useState<string>(settlement?.receipt || 'receipt');
  const { handleSubmit, control, reset, getValues, register, watch } = useForm<
    CreateSettlementInput | UpdateSettlementInput
  >({
    mode: 'all',
    defaultValues: !settlement
      ? {
          subjectType: SubjectType.DRIVER,
          type: !!settlementType ? settlementType : SettlementType.ORDER,
        }
      : {
          id: settlement.id,
          subjectType: settlement.subjectType,
          subjectId: settlement.subjectId,
          type: settlement.type,
          amount: settlement.amount,
          receipt: settlement.receipt,
          description: settlement.description,
        },
  });

  const { createSettlement, loading: creating } = useCreateSettlementMutation();
  const { updateSettlement, loading: updating } = useUpdateSettlementMutation();

  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      ...data,
      amount:
        data.type === SettlementType.DRIVER_COMPENSATION
          ? -1 * (data.amount || 0)
          : data.amount || 0,
    };
    if ('id' in payload) {
      await updateSettlement(payload);
      toast.success('Settlement updated successfully');
    } else {
      await createSettlement(payload);
      toast.success('Settlement created successfully');
    }

    reset();
    onClose();
    onModalClose && onModalClose();
  });

  watch('subjectType');
  const receiptValue = watch('receipt');

  useEffect(() => {
    if (receiptValue instanceof File) {
      setFileInputLabel(receiptValue.name);
    } else if (typeof receiptValue === 'string' && !receiptValue.includes('https')) {
      setFileInputLabel(receiptValue);
    } else {
      setFileInputLabel('receipt');
    }
  }, [receiptValue]);

  return (
    <SBModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        onModalClose();
      }}
      closeOnEsc
      header={<Text>{settlement ? 'Update settlement' : 'Create new settlement'}</Text>}
      size="xl"
      body={
        <form onSubmit={onSubmit}>
          <Box marginTop={2}>
            <Stack gap={1}>
              <HStack>
                <Controller
                  name="subjectType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Subject type</FormLabel>
                      <Select onChange={onChange} value={value} disabled={!!settlementType}>
                        <option value={SubjectType.DRIVER}>Driver</option>
                        <option value={SubjectType.RETAILER}>Retailer</option>
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name="subjectId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      {getValues('subjectType') === SubjectType.DRIVER ? (
                        <DriverSelectInput onChange={onChange} value={value || null} />
                      ) : (
                        <RetailerSelectInput onChange={onChange} value={value || null} />
                      )}
                    </FormControl>
                  )}
                />
              </HStack>
              <HStack>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Settlement type</FormLabel>
                      <Select
                        onChange={onChange}
                        value={value}
                        required
                        disabled={!!settlementType}
                      >
                        <option value={SettlementType.ORDER}>Order</option>
                        <option value={SettlementType.FUEL}>Fuel</option>
                        <option value={SettlementType.MAINTENANCE}>Maintenance</option>
                        <option value={SettlementType.DRIVER_COMPENSATION}>
                          Driver Compensation
                        </option>
                        <option value={SettlementType.FUEL}>Other</option>
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Amount</FormLabel>
                      <Input
                        type="number"
                        value={value || ''}
                        onChange={onChange}
                        placeholder="Enter settlement amount"
                        required
                      />
                    </FormControl>
                  )}
                />
              </HStack>
              <Controller
                name="receipt"
                control={control}
                render={({ field: { onChange } }) => (
                  <FormControl>
                    <FileInput
                      fileInputLabel={fileInputLabel}
                      setFile={onChange}
                      file={receiptValue || undefined}
                    />
                  </FormControl>
                )}
              />
              <FormControl>
                <SBTextArea
                  label="Description"
                  placeholder="Item's description"
                  {...register('description')}
                  experimental_spaceX={0}
                  experimental_spaceY={0}
                />
              </FormControl>
              <Box>
                <Button type="submit" disabled={creating || updating}>
                  {settlement ? 'Update settlement' : 'Create settlement'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </form>
      }
      bordered={false}
      footer={<div />}
    />
  );
};
