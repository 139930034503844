import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import {
  GET_PAGINATED_TASKS,
  type ITask,
  type TaskSortableFields,
  type TaskStatus,
} from '../Queries';
import { QueriesStore } from '../../queriesStore';
import { type SortOptions } from '../../SharedTypes';

interface QueryResponse {
  paginatedTasks: {
    data: ITask[];
    total: number;
    pageCount: number;
  };
}

interface InitialTasksQueryVariables {
  perPage: number;
  order?: SortOptions;
  orderBy?: TaskSortableFields;
  status?: TaskStatus[];
}

export interface TasksQueryVariables extends InitialTasksQueryVariables {
  page: number;
  perPage: number;
  keyword?: string;
  retailerId?: string;
  assigneeId?: string;
}

export function useTasksQuery(initialVariables: InitialTasksQueryVariables) {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_PAGINATED_TASKS, {
    variables: initialVariables,
  });

  const getTasks = useCallback(
    (variables: Partial<TasksQueryVariables>) => {
      QueriesStore.update('GET_TASKS', { ...initialVariables, ...variables, page: 1 });
      console.log({ ...initialVariables, ...variables, page: 1 });
      void query({
        variables,
      });
    },
    [initialVariables, query]
  );

  return {
    getTasks,
    tasks: data?.paginatedTasks?.data ?? [],
    totalTasks: data?.paginatedTasks?.total ?? 0,
    pageCount: data?.paginatedTasks?.pageCount ?? 0,
    loading,
    error,
  };
}
