import { Autocomplete, Box, Button, HStack, Text, Tooltip, VStack } from '@swftbox/style-guide';
import { format } from 'date-fns/esm';
import { useEffect, useMemo, useState } from 'react';
import { PlanType, useLocations, useSubscriptionsPlans } from 'src/components/Particles';
import { useAssignPlanToRetailerQuery } from 'src/components/Particles/resolvers/retailers/AssignPlanToRetailer.service';
import { useCancelPlanRetailerQuery } from 'src/components/Particles/resolvers/retailers/Subscription/CancelPlanReatiler';
import { useRetailerSubscriptionQuery } from 'src/components/Particles/resolvers/retailers/Subscription.service';
import { ShowTermsOfService } from './ShowTermsOfService';
import { ConfirmActionSubscription } from './ConfirmActionSubscription';
interface Props {
  retailerId: string;
  retailerName: string;
}
export const SubscriptionSetting = ({ retailerId, retailerName }: Props) => {
  const { isEligible } = useLocations('RETAILER', String(retailerId));
  const { query, data } = useRetailerSubscriptionQuery();
  const [planId, setPlanId] = useState('');
  const [planType, setPlanType] = useState('');
  const { plans } = useSubscriptionsPlans();
  const { handleAssignPlan } = useAssignPlanToRetailerQuery({ retailerId, planId });

  const plansOptions = useMemo(
    () => plans?.map((plan) => ({ label: plan.name, value: plan.id })) || [],
    [plans]
  );

  useEffect(() => {
    void query({
      variables: {
        retailerSubscribePlanInput: {
          retailerId,
        },
      },
    });
  }, []);
  console.log(data);
  return (
    <Box h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%">
        <Box>
          {data?.retailerSubscription ? (
            <Box fontSize="text-sm" fontWeight="semibold">
              <Text>
                Plan Name:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {data?.retailerSubscription?.plan?.name}
                </Text>
              </Text>
              <Text>
                Plan Price:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {data?.retailerSubscription?.plan?.price}{' '}
                  {data?.retailerSubscription?.plan?.currency}
                </Text>
              </Text>
              <Text>
                From:{' '}
                <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                  {format(new Date(data?.retailerSubscription?.startDate), 'dd MMMM yyyy')}
                </Text>
              </Text>
              To:{' '}
              <Text as="span" fontSize="text-lg" fontWeight="bold" ml="1">
                {format(new Date(data?.retailerSubscription?.endDate), 'dd MMMM yyyy')}
              </Text>
              <Text>
                Status:{' '}
                <Text
                  as="span"
                  fontSize="text-lg"
                  fontWeight="bold"
                  ml="1"
                  textTransform="capitalize"
                >
                  {data?.retailerSubscription?.status}{' '}
                  {data?.retailerSubscription?.isCanceled &&
                    `(Canceled - Ends ${data?.retailerSubscription?.endDate})`}
                </Text>
              </Text>
              <ShowTermsOfService
                retailerId={retailerId}
                retailerSubscription={data?.retailerSubscription}
              />
            </Box>
          ) : (
            <Autocomplete
              label="Subscription Plan"
              placeholder="Assign plan to retailer"
              options={plansOptions || []}
              value={plansOptions?.find((statusOption) => statusOption?.value === planId)}
              onChange={(val) => {
                // @ts-expect-error, it's just a falsy warning
                setPlanId(val?.value);
              }}
            />
          )}
        </Box>
        <HStack
          spacing="3"
          w="100%"
          position="sticky"
          bottom="0"
          transition="opacity 0.2s, pointer-events 0.2s"
        >
          {data?.retailerSubscription && !data?.retailerSubscription?.isCanceled && (
            <ConfirmActionSubscription
              retailerId={retailerId}
              subscriptionId={data?.retailerSubscription?.id}
              type="CANCEL"
            />
          )}
          {data?.retailerSubscription && !data.retailerSubscription.isCanceled && (
            <>
              {data?.retailerSubscription?.isPaused ? (
                <ConfirmActionSubscription
                  retailerId={retailerId}
                  subscriptionId={data?.retailerSubscription?.id}
                  type="RESUME"
                />
              ) : (
                <ConfirmActionSubscription
                  retailerId={retailerId}
                  subscriptionId={data?.retailerSubscription?.id}
                  type="PAUSE"
                />
              )}
            </>
          )}
          {!data?.retailerSubscription && (
            <Tooltip
              label={
                plans?.find((plan) => plan.id === planId)?.type === PlanType.DELIVERY && !isEligible
                  ? 'No eligible pickup location available for this retailer'
                  : null
              }
              hasArrow
            >
              <Button
                variant="solid"
                w="100%"
                onClick={() => {
                  handleAssignPlan();
                }}
                disabled={
                  !planId ||
                  (plans?.find((plan) => plan.id === planId)?.type === PlanType.DELIVERY &&
                    !isEligible)
                }
              >
                Select Plan
              </Button>
            </Tooltip>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};
