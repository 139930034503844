import { Autocomplete, Stack } from '@swftbox/style-guide';
import { Control, Controller, type FieldErrors } from 'react-hook-form';
import { buildPastWeekDateArray } from 'src/components/Pages/Orders/Utils/datetime.helper';

interface FormValue {
  from: string;
  to: string;
}

interface SyncOrdersFormProps {
  errors: FieldErrors<FormValue>;
  control: Control<FormValue, any>;
}

export default function SyncOrdersForm(props: SyncOrdersFormProps) {
  const { errors, control } = props;

  const datesOptions = buildPastWeekDateArray(10, false);

  return (
    <Stack gap={4}>
      <Controller
        name="from"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            label="Start date"
            options={datesOptions}
            placeholder="Select start date"
            onChange={(val) => {
              // @ts-expect-error, it's just a falsy warning
              onChange(val?.value);
            }}
            error={errors.from?.message}
          />
        )}
      />
      <Controller
        name="to"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            label="End date"
            options={datesOptions}
            placeholder="Select end date"
            onChange={(val) => {
              // @ts-expect-error, it's just a falsy warning
              onChange(val?.value);
            }}
            error={errors.to?.message}
          />
        )}
      />
    </Stack>
  );
}
