import { Box, IconButton, SBModal, useDisclosure, Text, SBEyeIcon } from '@swftbox/style-guide';
import { type location } from 'src/components/Particles';
import FulfillmentForm from './FulfillmentForm';
interface FormValue {
  id: string;
  name: string;
  location: location;
}

interface ViewFulfillmentProps {
  fulfillment: FormValue;
}
export function ViewFulfillment({ fulfillment }: ViewFulfillmentProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onConfirm() {}

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'blue' }}
        aria-label="view Category"
        icon={<SBEyeIcon width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEyeIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>View Fulfillment</Text>
          </Box>
        }
        size="xl"
        body={<FulfillmentForm fulfillment={fulfillment} />}
        bordered={false}
        handleConfirm={onConfirm}
        footer={<></>}
      />
    </>
  );
}
