import * as yup from 'yup';

export const featureCreateSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  billing: yup.number().required('Billing fee is required'),
  description: yup.string().optional(),
  category: yup.string().required('Category is required'),
});

export const featureUpdateSchema = yup.object().shape({
  id: yup.string().required('ID is required'),
  name: yup.string().required('Name is required'),
  billing: yup.number().required('Billing fee is required'),
  description: yup.string().optional(),
  category: yup.string().required('Category is required'),
});
