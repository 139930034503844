import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../../SharedTypes';
import {
  REMOVE_TASK_CATEGORY,
  GET_TASK_CATEGORIES,
  type RemoveTaskCategoryInput,
  type AddTaskCategoryResponse,
} from '../Queries';

interface RemoveTaskCategoryProps {
  payload: RemoveTaskCategoryInput;
  onCompleted: (
    data: { removeTaskCategory: AddTaskCategoryResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useRemoveTaskCategory() {
  const [mutate, { loading, error }] = useMutation<
    { removeTaskCategory: AddTaskCategoryResponse },
    { removeTaskCategoryId: string }
  >(REMOVE_TASK_CATEGORY, {});

  async function callRemoveTaskCategory({ payload, onCompleted }: RemoveTaskCategoryProps) {
    const { id } = payload;
    await mutate({
      variables: {
        removeTaskCategoryId: id,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_TASK_CATEGORIES,
        },
      ],
    });
  }

  return {
    callRemoveTaskCategory,
    loading,
    error,
  };
}
