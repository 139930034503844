import { Box } from '@swftbox/style-guide';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NavigationTabs } from 'src/components/Organisms';
const tabs = () => [
  {
    to: `billing`,
    name: 'Invoices',
    id: `/billing`,
  },
  {
    to: `billing/transactions`,
    name: 'Payment Transactions',
    id: `/billing/transactions`,
  },
];
export const BillingPage = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const tabList = useMemo(() => tabs(), []);
  const handleTabChange = (id: string) => {
    navigate(id);
  };

  return (
    <Box pt="2">
      <Box mb="5" display={{ base: 'none', lg: 'block' }}>
        <NavigationTabs
          handleChange={handleTabChange}
          tabs={tabList}
          active={location.pathname}
          title="Billing"
        />
      </Box>

      <Outlet />
    </Box>
  );
};
