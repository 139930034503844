import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { type clientOptionType } from '../SharedTypes';
import { FETCH_INTERNATIONAL_ORDER_STATUS } from './orders.graphql';

interface MutationResponse {
  fetchInternationalOrderStatus: {
    success: boolean;
    message: string;
  };
}

interface MutationProps {
  onCompleted?: (data: MutationResponse, clientOptions?: clientOptionType) => void;
}

export function useInternationalOrderStatusMutation({ onCompleted }: MutationProps) {
  const [mutate, { loading, error }] = useMutation<MutationResponse>(
    FETCH_INTERNATIONAL_ORDER_STATUS,
    {
      onCompleted,
    }
  );

  const fetchInternationalOrderStatus = useCallback(
    (swftboxTracking: string) => {
      void mutate({ variables: { swftboxTracking } });
    },
    [mutate]
  );

  return {
    fetchInternationalOrderStatus,
    loading,
    error,
  };
}
