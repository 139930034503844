import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../SharedTypes';
import { FILTER_ORDERS, UPDATE_ORDER_DROP_PROFILE } from './orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import type { UpdateOrderDropProfileInput, Order } from './orders.types';
import { type FilterQueryResponse } from './filterOrders.service';

interface UpdateOrderDropProfileResponse {
  message: string;
  data: Order;
}
interface UpdateOrderDropProfileProps {
  payload: UpdateOrderDropProfileInput;
  onCompleted: (
    data: { updateOrderDropProfile: UpdateOrderDropProfileResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useUpdateOrderDropProfile() {
  const [mutate, { loading, error }] = useMutation<
    { updateOrderDropProfile: UpdateOrderDropProfileResponse },
    { updateOrderDropProfileInput: UpdateOrderDropProfileInput }
  >(UPDATE_ORDER_DROP_PROFILE);

  async function updateOrderDropProfile({ payload, onCompleted }: UpdateOrderDropProfileProps) {
    await mutate({
      variables: {
        updateOrderDropProfileInput: payload,
      },
      onCompleted,
      update: (cache, result, context) => {
        const existingOrders: FilterQueryResponse | null = cache.readQuery({
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        });
        console.log({ result });
        const newOrders = existingOrders?.filterOrders.data.map((order) =>
          context.variables?.updateOrderDropProfileInput.id === order.id
            ? {
                ...order,
                dropProfile: result.data?.updateOrderDropProfile.data.dropProfile,
                timing: result.data?.updateOrderDropProfile.data.timing,
              }
            : order
        );
        cache.writeQuery({
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
          data: { filterOrders: { ...existingOrders?.filterOrders, data: newOrders } },
        });
      },
      // this is not working for some reason XXX
      // refetchQueries: [
      //   {
      //     query: FILTER_ORDERS,
      //     variables: QueriesStore.get('FILTER_ORDERS'),
      //   },
      // ],
    });
  }

  return {
    updateOrderDropProfile,
    loading,
    error,
  };
}
