import { toast } from '@swftbox/style-guide';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SortableList } from 'src/components/Molecules/SortableList';
import { isStopSequencesChanged } from 'src/components/Pages/Jobs/Helper';
import {
  EditCustomerLocation,
  OrderDetailsModal,
  ProceduresModal,
} from 'src/components/Pages/Orders/Components/Modal';
import { AddressHistoryModal } from 'src/components/Pages/Orders/Components/Modal/AddressHistory';
import {
  OrderModalProvider,
  useCustomerDropMutation,
  usePickupMutation,
  useRetailerDropMutation,
  useStopFailedMutation,
  useVisitStopMutation,
  useWarehouseDropMutation,
  type Job,
  type SequenceStops,
} from 'src/components/Particles';
import { JobRow } from './JobStops/Components';
import { CustomerDropModal } from './Modals/CustomerDropModal';
import { EditStopModal } from './Modals/EditStopModal';
const handleReference = (stopSequence: SequenceStops) => {
  const { dropOrders, pickupOrders } = stopSequence;
  if (pickupOrders.length === 1) return pickupOrders[0];
  if (dropOrders.length === 1) return dropOrders[0];
  // return [];
};
interface JobStopsProps {
  job?: Job;
  stops?: SequenceStops[];
  handleOpenOrderDrawer: (item: SequenceStops) => void;
  onSortChange: (sequences?: number[]) => void;
}
type SequenceStopWithId = SequenceStops & { id: string };

export const JobStops = ({ job, stops, handleOpenOrderDrawer, onSortChange }: JobStopsProps) => {
  const [customerDropSequenceStop, setCustomerDropSequenceStop] = useState<SequenceStopWithId>();
  const [editSequenceStop, setEditSequenceStop] = useState<SequenceStopWithId>();
  const [items, setItems] = useState<SequenceStopWithId[]>(addIdToStops(stops ?? []));
  const { visitStop } = useVisitStopMutation();
  const { warehouseDrop } = useWarehouseDropMutation();
  const { retailerDrop } = useRetailerDropMutation();
  const { pickup } = usePickupMutation();
  const { stopFailed } = useStopFailedMutation();
  const { customerDrop } = useCustomerDropMutation();
  const previousItemsRef = useRef<SequenceStopWithId[]>([]);
  useEffect(() => {
    previousItemsRef.current = items;
  }, [items]);

  const onItemsSortChange = useCallback(
    (newSortedItems: SequenceStopWithId[]) => {
      const isModified = isStopSequencesChanged(newSortedItems, stops ?? []);
      if (isModified) {
        const sequences = newSortedItems.map((stop) => stop.sequence);
        onSortChange(sequences);
      } else {
        onSortChange(undefined);
      }
      setItems(newSortedItems);
    },
    [stops, onSortChange]
  );

  useEffect(() => {
    setItems(addIdToStops(stops ?? []));
  }, [stops]);

  const handleStopActionModalClose = () => {
    setCustomerDropSequenceStop(undefined);
    setEditSequenceStop(undefined);
  };

  const checkIfNotSortable = (item: SequenceStopWithId): boolean => {
    if (item.sequence === 1) return true; // Lock the first stop
    if (item.status === 'completed') return true; // Lock if the stop is completed
    if (item.label.split(' ').at(1) === 'Drop') {
      if (item.locationType === 'warehouse') return true;
      const correspondingPickup = items.find(
        (pickupItem) =>
          handleReference(pickupItem)?.swftboxTracking === handleReference(item)?.swftboxTracking &&
          pickupItem.label.split(' ').at(1) === 'Pickup'
      );
      if (correspondingPickup && correspondingPickup.status !== 'completed') return true;
    }
    return false;
  };

  const itemsWithSortOverAllowed = items.map((item) => ({
    ...item,
    sortOverAllowed: item.status !== 'completed',
  }));
  const checkIfPositionNotAllowed = (newSortedItems: SequenceStopWithId[]): boolean => {
    const previousItems = previousItemsRef.current;

    const movedItem = previousItems.find(
      (item, index) => item.sequence !== newSortedItems[index].sequence
    );
    const targetIndex = newSortedItems.findIndex((item) => item.sequence === movedItem?.sequence);

    if (!movedItem || targetIndex === -1) return false;

    if (
      targetIndex === 0 ||
      (targetIndex === newSortedItems.length - 1 &&
        newSortedItems[targetIndex].locationType === 'warehouse')
    ) {
      return true;
    }

    return false;
  };
  const markAsVisited = async (item: SequenceStopWithId) => {
    const { data } = await visitStop({
      routeId: item.routeId,
      sequence: item.sequence,
    });

    toast.success(data?.visitStop.message);
  };

  const markAsFailed = async (item: SequenceStopWithId) => {
    const { data } = await stopFailed({
      routeId: item.routeId,
      sequence: item.sequence,
      isMissed: false,
      ordersSwftboxTracking: [
        ...item.pickupOrders
          .map((order) => order.swftboxTracking)
          .filter((swftboxTracking) => !item.pickedUpOrders.includes(swftboxTracking)),
        ...item.dropOrders
          .map((order) => order.swftboxTracking)
          .filter((swftboxTracking) => !item.droppedOrders.includes(swftboxTracking)),
      ],
    });

    toast.success(data?.stopFailed.message);
  };

  const performPickup = async (item: SequenceStopWithId) => {
    const { data } = await pickup({
      routeId: item.routeId,
      sequence: item.sequence,
      ordersSwftboxTracking: item.pickupOrders
        .filter((order) => !item.pickedUpOrders.includes(order.swftboxTracking))
        .map((order) => order.swftboxTracking),
    });

    toast.success(data?.confirmPickup.message);
  };

  const performDrop = async (item: SequenceStopWithId) => {
    if (item.locationType === 'warehouse') {
      const { data } = await warehouseDrop({
        ordersSwftboxTracking: item.dropOrders
          .filter((order) => !item.droppedOrders.includes(order.swftboxTracking))
          .map((order) => order.swftboxTracking),
      });
      toast.success(data?.receiveOrders.message);
    } else if (item.locationType === 'retailer') {
      const { data } = await retailerDrop({
        routeId: item.routeId,
        sequence: item.sequence,
        ordersSwftboxTracking: item.dropOrders
          .filter((order) => !item.droppedOrders.includes(order.swftboxTracking))
          .map((order) => order.swftboxTracking),
      });
      toast.success(data?.dropAtRetailer.message);
    } else if (item.locationType === 'customer') {
      const prepaidOrders = item.dropOrders
        .filter(
          (order) =>
            !item.droppedOrders.includes(order.swftboxTracking) && order?.paymentMode === 'prePaid'
        )
        .map((order) => order.swftboxTracking);

      const podOrders = item.dropOrders
        .filter(
          (order) =>
            !item.droppedOrders.includes(order.swftboxTracking) &&
            order?.paymentMode === 'paymentOnDelivery'
        )
        .map((order) => order.swftboxTracking);

      if (!item.bagCollection && prepaidOrders.length > 0 && podOrders.length === 0) {
        await customerDrop({
          routeId: item.routeId,
          sequence: item.sequence,
          ordersSwftboxTracking: prepaidOrders,
        });
        toast.success(`Dropped ${prepaidOrders.length} prepaid order(s)`);
      }

      if (podOrders.length > 0 || item.bagCollection) {
        setCustomerDropSequenceStop(item);
      }
    }
  };

  const onSortChangeVerification = (sortedItems: SequenceStopWithId[]) => {
    if (checkIfPositionNotAllowed(sortedItems)) {
      toast.warning('Position not allowed');
      return;
    }
    onItemsSortChange(sortedItems);
  };

  return (
    <div>
      <OrderModalProvider>
        <SortableList
          items={itemsWithSortOverAllowed}
          onChange={(sortedItems: SequenceStopWithId[]) => {
            onSortChangeVerification(sortedItems);
          }}
          renderItem={(item) => (
            <SortableList.Item
              id={`${item.routeId}-${item.sequence}`}
              key={`${item.routeId}-${item.sequence}`}
              disableDnD={checkIfNotSortable(item)}
            >
              <JobRow
                nextStop={job?.nextStopSequence}
                stop={item}
                job={job}
                handleOpenOrderDrawer={handleOpenOrderDrawer}
                performPickup={performPickup}
                markAsVisited={markAsVisited}
                performDrop={performDrop}
                markAsFailed={markAsFailed}
                setEditSequenceStop={setEditSequenceStop}
              />
            </SortableList.Item>
          )}
        />
        {customerDropSequenceStop && (
          <CustomerDropModal
            sequenceStop={customerDropSequenceStop}
            onModalClose={handleStopActionModalClose}
          />
        )}
        {editSequenceStop && (
          <EditStopModal
            sequenceStop={editSequenceStop}
            onModalClose={handleStopActionModalClose}
          />
        )}
        <AddressHistoryModal />
        <EditCustomerLocation />
        <OrderDetailsModal />
        <ProceduresModal />
      </OrderModalProvider>
    </div>
  );
};

function addIdToStops(stops: SequenceStops[]) {
  return stops?.map((stop) => ({ ...stop, id: `${stop.routeId}-${stop.sequence}` }));
}
