import { gql } from '@apollo/client';

export const UPDATE_DEMO_SETTING = gql`
  mutation UpdateDemoSetting($updateRetailerInput: UpdateRetailerInput!) {
    updateRetailer(updateRetailerInput: $updateRetailerInput) {
      message
      data {
        id
        revealOverviewPage
        revealInventoryPage
      }
    }
  }
`;
