import { Autocomplete, Box, FormControl, HStack, Stack, toast, Text } from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { DriverSelectInput } from 'src/components/Organisms/DriverSelectInput';
import { type Order, useOrderModalContext } from 'src/components/Particles';
import { type ForceRtoCompleteInput } from 'src/components/Particles/resolvers/orders';
import { buildPastWeekDateArray } from '../../../Utils/datetime.helper';
import { useForceRtoComplete } from 'src/components/Particles/resolvers/orders/ForceActions/forceRtoComplete.service';
import SVG from 'react-inlinesvg';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';

export function ForceRtoComplete({ formRef, handleClose }: { formRef: any; handleClose: any }) {
  const { data: order } = useOrderModalContext() as {
    forceActionsIsOpen: boolean;
    data: Order;
  };

  const { forceRtoCompleteOrders } = useForceRtoComplete();
  const { handleSubmit, control } = useForm<ForceRtoCompleteInput>({
    mode: 'all',
  });

  const deliverDateOptions = buildPastWeekDateArray(5);

  function onConfirm({ driverId, rtoDateTime }: ForceRtoCompleteInput) {
    void forceRtoCompleteOrders({
      payload: {
        swftboxTracking: order?.swftboxTracking,
        driverId,
        rtoDateTime,
      },
      onCompleted: (message) => {
        if (message) {
          toast.success(message);
          handleClose();
        }
      },
    });
  }
  const existingPayment =
    order?.payment && ['APPROVED', 'PENDING'].includes(order?.payment?.status);

  return (
    <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={4}>
      <Box marginTop={2}>
        <Stack gap={1}>
          <HStack>
            <Controller
              name="driverId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <DriverSelectInput onChange={onChange} value={value || null} />
                </FormControl>
              )}
            />
            <Controller
              name="rtoDateTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Deliver date"
                  options={deliverDateOptions}
                  value={deliverDateOptions.find((date) => date.value === value) ?? ''}
                  onChange={(val) => {
                    // @ts-expect-error, it's just a falsy warning
                    onChange(val?.value);
                  }}
                />
              )}
            />
          </HStack>
        </Stack>
      </Box>
      {!!existingPayment && (
        <HStack
          border={`1px solid #FF8EAC`}
          boxShadow="xs"
          borderRadius="8px"
          p="8px"
          w="100%"
          bg=" #FF8EAC"
          marginBlock={3}
        >
          <SVG src={InfoIcon} color=" #FF8EAC" stroke="#000" width={'40px'} height={'40px'} />
          <Text fontWeight="semibold" fontSize="text-sm">
            There's an existing PENDING or APPROVED payment for this order. To proceed, please
            reject the transaction first on the payments page.
          </Text>
        </HStack>
      )}
      <button ref={formRef} type="submit" disabled={existingPayment} style={{ display: 'none' }}>
        Test
      </button>
    </Stack>
  );
}
