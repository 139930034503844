import { useMutation } from '@apollo/client';
import { GET_RETAILER, GET_RETAILERS, UPSERT_RETAILER_CONFIGS } from './retailers.graphql';
import type { RetailerConfigsInput, RetailerConfigsDTO } from './retailer.types';
import { type clientOptionType } from '../SharedTypes';
import { QueriesStore } from '../queriesStore';

interface UpsertConfigurationsResponse {
  message: string;
  data: RetailerConfigsDTO;
}

interface UpsertConfigurationsProps {
  payload: RetailerConfigsInput;
  onCompleted: (
    data: { upsertConfigurations: UpsertConfigurationsResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpsertRetailerConfigsMutation() {
  const [mutate, { loading, error }] = useMutation<
    { upsertConfigurations: UpsertConfigurationsResponse },
    { upsertRetailerConfigurationInput: RetailerConfigsInput }
  >(UPSERT_RETAILER_CONFIGS);

  async function upsertRetailerConfigurations({ payload, onCompleted }: UpsertConfigurationsProps) {
    return mutate({
      variables: { upsertRetailerConfigurationInput: payload },
      onCompleted,
      refetchQueries: [
        {
          query: GET_RETAILERS,
          variables: QueriesStore.get('GET_RETAILERS'),
        },
        {
          query: GET_RETAILER,
          variables: { retailerId: payload.retailerId },
        },
      ],
    });
  }

  return {
    upsertRetailerConfigurations,
    loading,
    error,
  };
}
