import { Permission } from 'src/components/Particles';

//use to make sure we don't allow duplicate default roles
export const DefaultRoles = [
  'superadmin',
  'admin',
  'dispatcher',
  'customer',
  'operator',
  'driver',
  'retailer',
  'accountmanager',
  'reporting',
];

export const groupPermissions = (permissions: Permission[]) => {
  const permissionsMap: Record<string, any[]> = {};
  const permissionGroups: string[] = [];
  permissions.forEach((permission) => {
    const [permissionName, ...permissionGroup] = permission.name.split('-');
    const permissionGroupName = permissionGroup.join(' ');
    if (!permissionGroups.includes(permissionGroupName)) {
      permissionGroups.push(permissionGroupName);
      permissionsMap[permissionGroupName] = [];
    }
    permissionsMap[permissionGroupName].push({ id: permission.id, name: permissionName });
  });
  return { permissionsMap, permissionGroups };
};
