import { gql } from '@apollo/client';

export const GENERATE_REPORT = gql`
  mutation GenerateReport($generateReportInput: GenerateReportInput!) {
    generateReport(generateReportInput: $generateReportInput) {
      success
      message
    }
  }
`;

export const REGENERATE_REPORT = gql`
  mutation RegenerateReport($reportId: UUID!) {
    regenerateReport(reportId: $reportId) {
      success
      message
    }
  }
`;

export const LIST_REPORTS = gql`
  query PaginatedReports(
    $page: Int
    $perPage: Int
    $orderBy: String
    $order: OrderSort
    $keyword: String
    $retailerId: UUID
    $driverId: UUID
    $userId: UUID
    $type: ReportType
    $status: ReportStatus
    $assigneeId: UUID
  ) {
    paginatedReports(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      order: $order
      keyword: $keyword
      retailerId: $retailerId
      driverId: $driverId
      userId: $userId
      type: $type
      status: $status
      assigneeId: $assigneeId
    ) {
      data {
        id
        createdAt
        updatedAt
        deletedAt
        userId
        retailerId
        driverId
        type
        status
        filePath
        fromDate
        toDate
        options {
          isClosed
          recepientEmail
          firstMile
          lastMile
          jobless
        }
        downloadUrl
        createdAt
        retailer {
          id
          name
        }
        driver {
          id
          user {
            name
            id
          }
        }
        user {
          id
          name
        }
        assignee {
          id
          name
        }
      }
      total
      pageCount
      currentPage
    }
  }
`;
