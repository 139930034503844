import {
  HStack,
  IconWText,
  LabelIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PencilIcon,
  SBAddIcon,
  SBBoxIcon,
  SBCheckIcon,
  SBEnvelopeIcon,
  SBEuroIcon,
  SBEyeIcon,
  SBMapMarkerIcon,
  SBMenuDotsVerticalIcon,
  SBUserIcon,
  SBRedoIcon,
  Td,
} from '@swftbox/style-guide';
import { memo, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import RepeatIcon from 'src/assets/icons/Media/repeat-04.svg';
import {
  OrderStage,
  OrderState,
  goToPrintScreen,
  openTrackingApp,
  useAuthContext,
  useInternationalOrderStatusMutation,
  useOrderModalActions,
  type Order,
} from 'src/components/Particles';
import { OrdersStatusFilter } from '../../order.types';
import WorldIcon from 'src/assets/icons/international.svg';
import WorldTrackingIcon from 'src/assets/icons/international-tracking.svg';
import InvoiceIcon from 'src/assets/icons/invoice.svg';
import { Config } from 'src/config';

interface CellMenuProps {
  order: Order;
  performSyncOrders: (order: Order) => void;
}
const CellMenu = ({ order, performSyncOrders }: CellMenuProps) => {
  const { user } = useAuthContext();

  const dispatch = useOrderModalActions();
  const [displayMenu, setDisplayMenu] = useState(false);
  const { fetchInternationalOrderStatus } = useInternationalOrderStatusMutation({
    onCompleted: (data) => {
      console.log(data);
    },
  });

  const internationalDetails = useMemo(() => order.internationalDetails, [order]);
  const isClosedOrder = useMemo(
    () =>
      ['delivered', 'rtnCompleted', 'rtoComplete', 'fulfillmentRtoComplete'].includes(order.status),
    [order]
  );

  return (
    <Td
      textAlign="end"
      padding="5"
      position="sticky"
      right="-1"
      bg="#fbfbfc"
      className="floating-actions"
      zIndex={displayMenu ? 1 : 0}
    >
      <HStack justifyContent="center">
        <Menu
          isLazy
          onOpen={() => {
            setDisplayMenu(true);
          }}
          onClose={() => {
            setDisplayMenu(false);
          }}
        >
          <MenuButton as="button">
            <SBMenuDotsVerticalIcon width="15px" />
          </MenuButton>
          <MenuList display={displayMenu ? 'inline-block' : 'none'} zIndex="1001">
            <MenuItem
              onClick={() => {
                dispatch({ type: 'OPEN_ORDER_DETAILS', payload: order });
              }}
            >
              <IconWText text="View order" spacing="20px" Icon={<SBEyeIcon width="15px" />} />
            </MenuItem>

            {!(
              (order.stage !== OrderStage.transit && order.stage !== OrderStage.failed) ||
              order.state !== OrderState.inJob
            ) && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_RECEIVE_ORDER', payload: order });
                }}
              >
                <IconWText
                  text="Receive"
                  spacing="20px"
                  aria-label="receive order"
                  Icon={<SBEnvelopeIcon width="15px" />}
                />
              </MenuItem>
            )}

            {order.isReverse && order.status === OrdersStatusFilter.RTN_REQUESTED && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_FAIL_RETURN', payload: order });
                }}
              >
                <IconWText
                  text="Mark As Failed Return"
                  spacing="20px"
                  aria-label="mark as failed return"
                  Icon={<SBRedoIcon width="15px" />}
                />
              </MenuItem>
            )}

            {order.stage !== OrderStage.rto && order.state === OrderState.warehouse && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_MARK_AS_RTO', payload: order });
                }}
              >
                <IconWText
                  text="Mark As RTO"
                  spacing="20px"
                  aria-label="mark as rto"
                  Icon={<SBCheckIcon width="15px" />}
                />
              </MenuItem>
            )}

            {order.stage === OrderStage.rto && order.state === OrderState.warehouse && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_UNDO_RTO', payload: order });
                }}
              >
                <IconWText
                  text="Undo RTO"
                  spacing="20px"
                  aria-label="undo rto"
                  Icon={<SBCheckIcon width="15px" />}
                />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                dispatch({ type: 'OPEN_EDIT_ORDER', payload: order });
              }}
            >
              <IconWText
                text="Edit order"
                spacing="20px"
                Icon={<PencilIcon width="15px" viewBox="0 0 16 16" />}
              />
            </MenuItem>
            {[
              OrdersStatusFilter.DELIVERED,
              OrdersStatusFilter.CANCELLED,
              OrdersStatusFilter.RTN_COMPLETED,
              OrdersStatusFilter.FULFILLMENT_RTO_COMPLETE,
              OrdersStatusFilter.RTO_COMPLETE,
            ].includes(order.status as OrdersStatusFilter) ||
              (['SuperAdmin', 'Admin'].some((role) => user?.roles?.includes(role)) && (
                <MenuItem
                  onClick={() => {
                    dispatch({ type: 'OPEN_EDIT_PAYMENT_ORDER', payload: order });
                  }}
                >
                  <IconWText
                    text="Edit payment"
                    spacing="20px"
                    Icon={<SBEuroIcon width="15px" />}
                  />
                </MenuItem>
              ))}
            <MenuItem
              onClick={() => {
                dispatch({ type: 'OPEN_EDIT_CUSTOMER', payload: order });
              }}
            >
              <IconWText text="Edit customer" spacing="20px" Icon={<SBUserIcon width="15px" />} />
            </MenuItem>

            {!Config.PRIMEXPRESS_BUILD && !internationalDetails?.isAssigned && !isClosedOrder && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_INTERNATIONAL_SHIPMENT_REQUEST', payload: order });
                }}
              >
                <IconWText
                  text="International shipment"
                  spacing="20px"
                  Icon={<SVG src={WorldIcon} width="15px" height="15px" fill="currentColor" />}
                />
              </MenuItem>
            )}
            {!Config.PRIMEXPRESS_BUILD && !!order.isInternational && !isClosedOrder && (
              <MenuItem
                onClick={() => {
                  fetchInternationalOrderStatus(order.swftboxTracking);
                }}
              >
                <IconWText
                  text="Refresh International status"
                  spacing="20px"
                  Icon={<LabelIcon width="15px" viewBox="0 0 16 16" />}
                />
              </MenuItem>
            )}
            {!Config.PRIMEXPRESS_BUILD && internationalDetails?.isAssigned && (
              <>
                {!isClosedOrder && (
                  <MenuItem
                    onClick={() => {
                      dispatch({ type: 'OPEN_INTERNATIONAL_SHIPMENT_CANCEL', payload: order });
                    }}
                  >
                    <IconWText
                      text="Release & Cancel on Carriyo"
                      spacing="20px"
                      color="red.normal"
                      aria-label="Release & Cancel on Carriyo"
                      Icon={<SBAddIcon width="15px" />}
                    />
                  </MenuItem>
                )}
                {internationalDetails?.shippingLabelUrl && (
                  <MenuItem
                    onClick={() => {
                      window.open(internationalDetails?.shippingLabelUrl, '_blank');
                    }}
                  >
                    <IconWText
                      text="International shipping label"
                      spacing="20px"
                      Icon={<LabelIcon width="15px" viewBox="0 0 16 16" />}
                    />
                  </MenuItem>
                )}
                {internationalDetails?.trackingUrl && (
                  <MenuItem
                    onClick={() => {
                      window.open(internationalDetails?.trackingUrl, '_blank');
                    }}
                  >
                    <IconWText
                      text="International tracking"
                      spacing="20px"
                      Icon={
                        <SVG
                          src={WorldTrackingIcon}
                          width="16px"
                          height="16px"
                          fill="currentColor "
                        />
                      }
                    />
                  </MenuItem>
                )}
                {internationalDetails?.commercialInvoiceUrl && (
                  <MenuItem
                    onClick={() => {
                      window.open(internationalDetails?.commercialInvoiceUrl, '_blank');
                    }}
                  >
                    <IconWText
                      text="Commercial Invoice"
                      spacing="20px"
                      Icon={
                        <SVG src={InvoiceIcon} width="15px" height="15px" fill="currentColor" />
                      }
                    />
                  </MenuItem>
                )}
              </>
            )}

            <MenuItem
              onClick={() => {
                openTrackingApp(order.swftboxTracking);
              }}
            >
              <IconWText
                text="Tracking app"
                spacing="20px"
                Icon={<SBMapMarkerIcon width="15px" />}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                goToPrintScreen(order);
              }}
            >
              <IconWText
                text="Shipping label"
                spacing="20px"
                Icon={<LabelIcon width="15px" viewBox="0 0 16 16" />}
              />
            </MenuItem>
            {['SuperAdmin', 'Admin'].some((role) => user?.roles?.includes(role)) && (
              <MenuItem
                onClick={() => {
                  performSyncOrders(order);
                }}
              >
                <IconWText
                  text="Sync Order"
                  spacing="20px"
                  Icon={<SVG src={RepeatIcon} width="15px" stroke="currentColor" />}
                />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                dispatch({ type: 'OPEN_FORCE_ACTIONS', payload: order });
              }}
            >
              <IconWText
                text="Force Actions"
                color="orange.normal"
                spacing="20px"
                aria-label="force actions"
                Icon={<SBBoxIcon width="15px" />}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch({ type: 'OPEN_CANCEL_ORDER', payload: [order] });
              }}
            >
              <IconWText
                text="Cancel"
                spacing="20px"
                color="red.normal"
                Icon={<SBAddIcon width="15px" />}
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Td>
  );
};

export default memo(CellMenu);
