import { Box, HStack, Text } from '@swftbox/style-guide';
import React, { useState, useEffect, useRef } from 'react';

interface ChipProps {
  label: string;
}

const Chip: React.FC<ChipProps> = ({ label }) => (
  <Text
    overflow="hidden"
    textOverflow="ellipsis"
    maxW="60px"
    bg="#63C3EC"
    borderRadius="8px"
    px="1"
    color="#fff"
    fontSize="x-small"
    fontWeight="medium"
  >
    {label}
  </Text>
);

interface ChipsListProps {
  labels: string[];
}

const ChipsList: React.FC<ChipsListProps> = ({ labels }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <HStack
      spacing="3px"
      ml="15px"
      ref={containerRef}
      style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
    >
      {labels.slice(0, 1).map((label, i) => (
        <Chip key={i} label={label} />
      ))}
      {1 < labels.length && (
        <Box bg="#63C3EC" color="#fff" borderRadius="8px" px="1" fontSize="x-small">
          +{labels.length - 1}
        </Box>
      )}
    </HStack>
  );
};

export default ChipsList;
