import {
  Box,
  Button,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
  SBCrossCircleIcon,
  IconWText,
} from '@swftbox/style-guide';
import { useCancelTask } from 'src/components/Particles/resolvers';

interface CancelTaskProps {
  taskId: string;
}

export function CancelTask({ taskId }: CancelTaskProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cancelTask, loading } = useCancelTask();

  function onCancelConfirm() {
    void cancelTask({
      payload: { cancelTaskId: taskId },
      onCompleted: ({ cancelTask }) => {
        if (cancelTask.message) {
          toast.success(cancelTask.message);
          onClose();
        }
      },
    });
  }

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconWText
          text="Cancel Task"
          spacing="20px"
          aria-label="cancel task"
          Icon={<SBCrossCircleIcon width="15px" />}
        />
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Cancel Task</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to cancel this task!
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onCancelConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
