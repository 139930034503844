import { useMutation } from '@apollo/client';
import {
  GENERATE_REPORT,
  LIST_REPORTS,
  type GenerateReportInput,
  type GenerateReportResponse,
} from '../Queries';
import { QueriesStore } from '../../queriesStore';

export const useGenerateReportMutation = () => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { generateReport: GenerateReportResponse },
    { generateReportInput: GenerateReportInput }
  >(GENERATE_REPORT);

  async function generateReport(generateReportInput: GenerateReportInput) {
    return mutate({
      variables: {
        generateReportInput,
      },
      refetchQueries: [
        { query: LIST_REPORTS, variables: QueriesStore.get('LIST_REPORTS'), pollInterval: 5000 },
      ],
    });
  }

  return {
    generateReport,
    reset,
    loading,
    error,
    ...rest,
  };
};
