import { HStack, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { useMemo } from 'react';
import uuid from 'react-uuid';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import {
  type ElasticSearchSortOptions,
  type JobSortableFields,
  type Job,
} from 'src/components/Particles';
import CellModals from './JobDetails/Components/Modals/CellModals';
import { sortingOptions } from './JobDetails/Helper';
import { JobTableCell } from './JobsTable/JobCell';

interface JobsTableProps {
  jobs: Job[] | undefined;
  page: number;
  perPage: number;
  total: number;
  onPageChange: (page: number) => void;
  sorting: { by: JobSortableFields; order: ElasticSearchSortOptions };
  onPageSorting: ({
    by,
    order,
  }: {
    by: JobSortableFields;
    order: ElasticSearchSortOptions;
  }) => void;
}

export function JobsTable(props: JobsTableProps) {
  const { jobs, page, perPage, total, onPageChange, onPageSorting } = props;
  const tableHeaders = useMemo(
    () => [
      { title: '', subtitle: '', id: uuid() },

      { title: 'Reference', subtitle: 'Total Orders', id: uuid() },
      { title: 'Job Type', subtitle: '', id: uuid() },
      { title: 'Vehicle', subtitle: '', id: uuid() },
      { title: 'Status', subtitle: 'Stops (P/T)', id: uuid() },
      { title: 'Driver', subtitle: '', id: uuid() },
      { title: 'Last Update', subtitle: 'Created', id: uuid() },
    ],
    []
  );

  return (
    <TableContainer whiteSpace="normal" pb={total < 6 && total !== 0 ? '350px' : '0'}>
      <Table variant="striped">
        <Thead position="relative">
          <Tr bg="#fbfbfc">
            {tableHeaders.map((header) => (
              <Th key={header.id}>
                <Text>{header.title}</Text>
                <Text color="neutral.normal">{header.subtitle}</Text>
              </Th>
            ))}

            <Th
              position={{ base: 'sticky', '2xl': 'absolute' }}
              height="100%"
              p={{ base: '0', '2xl': '0 20px' }}
              bg="#fbfbfc"
              right="2"
              zIndex="1"
            >
              <HStack h="100%" justifyContent="flex-end">
                <SmallPaginate
                  totalOrders={total}
                  page={page ?? 1}
                  perPage={perPage}
                  onPageChange={onPageChange}
                  onPageSorting={onPageSorting}
                  sortingOption={sortingOptions}
                />
              </HStack>
            </Th>
            <Th px="0" position={{ base: 'absolute', '2xl': 'sticky' }} />
          </Tr>
        </Thead>
        <Tbody>
          {jobs?.map((job) => {
            return <JobTableCell job={job} key={job.id} />;
          })}
        </Tbody>
        <CellModals />
      </Table>
    </TableContainer>
  );
}
