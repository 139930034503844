import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import ar from './ar.json';
import en from './en.json';

interface LanguageProviderProps {
  children: any;
}

export const LocaleContext = React.createContext({});
const messages: any = {
  en,
  ar,
};

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') ?? 'en');

  useEffect(() => {
    const body = document.getElementById('body');
    const html = document.getElementById('html');
    localStorage.setItem('locale', locale);
    if (body != null && html != null) {
      body.dir = locale === 'ar' ? 'rtl' : 'ltr';
      html.lang = locale === 'ar' ? 'ar' : 'en';
    }
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {React.Children.only(children)}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
