import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { type clientOptionType } from '../SharedTypes';
import { GET_ORDER_SWFTBOX_TRACKING } from './orders.graphql';
import { type Order } from './orders.types';

interface QueryResponse {
  OrderBySwftboxTracking: Order;
}
interface SwftboxTrackingProps {
  onCompleted?: (data: QueryResponse, clientOptions?: clientOptionType) => void;
}
export function useOrderBySwftboxTrackingQuery({ onCompleted }: SwftboxTrackingProps) {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(
    GET_ORDER_SWFTBOX_TRACKING,
    { onCompleted }
  );

  const getOrderBySwftboxTracking = useCallback(
    (swftboxTracking: string) => {
      void query({ variables: { swftboxTracking } });
    },
    [query]
  );

  return {
    getOrderBySwftboxTracking,
    order: data?.OrderBySwftboxTracking,
    loading,
    error,
  };
}
