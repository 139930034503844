import { useMutation } from '@apollo/client';
import {
  type UpdateFeatureInput,
  LIST_FEATURES,
  type FeatureMutationResponse,
  UPDATE_FEATURE,
} from '../Queries';
import { clientOptionType } from '../../../SharedTypes';

interface UpdateFeatureProps {
  onCompleted: (
    data: { updateFeature: FeatureMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useUpdateFeature({ onCompleted }: UpdateFeatureProps) {
  const [mutate, { error, loading }] = useMutation<
    { updateFeature: FeatureMutationResponse },
    { input: UpdateFeatureInput }
  >(UPDATE_FEATURE, {
    onCompleted,
    refetchQueries: [{ query: LIST_FEATURES }],
  });

  return {
    mutate,
    loading,
    error,
  };
}
