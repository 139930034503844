import { gql } from '@apollo/client';

export const UPDATE_NOTIFICATIONS_TRIGGERS = gql`
  mutation Mutation($notificationTriggerInput: NotificationTriggerInput!) {
    updateNotificationTriggers(notificationTriggerInput: $notificationTriggerInput) {
      message
      success
    }
  }
`;

export const GET_NOTIFICATION_TRIGGERS = gql`
  query GetNotificationTriggers($retailerId: UUID!) {
    notificationTriggers(retailerId: $retailerId) {
      trigger
    }
  }
`;
