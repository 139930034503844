import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import AddCategory from './Modal/AddFulfillment/AddFulfillment';

interface FulfillmentsHeaderProps {
  fulfillmentsCount: number;
}
export default function FulfillmentsHeader(props: FulfillmentsHeaderProps) {
  const { fulfillmentsCount } = props;
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Fulfillments
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {fulfillmentsCount <= 1
              ? `${fulfillmentsCount} Fulfillment`
              : `${fulfillmentsCount} Fulfillments`}
          </Badge>
        </Text>
      </Box>
      <Spacer />
      <AddCategory />
    </Flex>
  );
}
