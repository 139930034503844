import { FC } from 'react';
import {
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PencilIcon,
  SBMenuDotsVerticalIcon,
  SBUserDeleteIcon,
  toast
} from '@swftbox/style-guide';
import { PickupProfile, useRemovePickupProfileMutation } from 'src/components/Particles';

interface Props {
  pickupProfile: PickupProfile;
  onEdit: (pickupProfile: PickupProfile) => void;
}

export const ActionButtons: FC<Props> = ({ pickupProfile, onEdit }) => {

  const { removePickupProfile, loading } = useRemovePickupProfileMutation();

  const onDelete = async () => {
    if (loading) return;
    const confirmed = confirm('Are you sure you want to delete this pickup profile?');
    if (!confirmed) {
      return;
    }

    const { data } = await removePickupProfile(pickupProfile.id);
    toast.success(data?.removePickupProfile?.message);
  }

  return (
    <Menu>
      <MenuButton as="button">
        <SBMenuDotsVerticalIcon width="15px" />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => onEdit(pickupProfile)}>
          <IconWText
            text="Edit"
            spacing="20px"
            Icon={<PencilIcon width="15px" viewBox="0 0 16 16" />}
          />
        </MenuItem>
        <MenuItem onClick={onDelete}>
          <IconWText
            text="Delete"
            spacing="20px"
            color="red.normal"
            Icon={<SBUserDeleteIcon width="15px"/>}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
