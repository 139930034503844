import { useMutation } from '@apollo/client';
import { FILTER_ORDERS, UNDO_RTO } from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';
import { type clientOptionType } from '../SharedTypes';
import { type Order } from './orders.types';

interface MarkOrderAsRTOResponse {
  message: string;
  data: Order[];
}
interface MarkOrderAsRTOProps {
  payload: {
    ids: string[];
  };
  onCompleted: (
    data: { undoRTO: MarkOrderAsRTOResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useUndoRTO() {
  const [mutate, { loading, error }] = useMutation<
    { undoRTO: MarkOrderAsRTOResponse },
    { orderIds: { ids: string[] } }
  >(UNDO_RTO);

  async function markOrder({ payload, onCompleted }: MarkOrderAsRTOProps) {
    await mutate({
      variables: {
        orderIds: {
          ids: payload.ids,
        },
      },
      onCompleted,
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
      ],
    });
  }

  return {
    markOrder,
    loading,
    error,
  };
}
