import { useMutation } from '@apollo/client';
import { clientOptionType, SortOptions } from '../../SharedTypes';
import { BULK_CREATE_INVOICES, BulkCreateInvoiceInput, LIST_INVOICES } from '../Queries';

interface response {
  message: string;
}
interface Props {
  onCompleted: (data: { bulkCreateInvoice: response }, clientOptions?: clientOptionType) => void;
}

export const useBulkGenerateInvoiceMutation = ({ onCompleted }: Props) => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { bulkCreateInvoice: response },
    { bulkCreateInvoiceInput: BulkCreateInvoiceInput }
  >(BULK_CREATE_INVOICES, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_INVOICES,
        variables: { perPage: 50, page: 1, orderBy: 'createdAt', order: SortOptions.DESC },
        pollInterval: 5000,
      },
    ],
  });

  return {
    mutate,
    reset,
    loading,
    error,
    ...rest,
  };
};
