import { useCallback, useState } from 'react';
import { Retailer } from 'src/components/Particles';

export function useRetailerBilling(retailers: Retailer[], retailersIds: string[]) {
  const [addressDetailsMissing, setAddressDetailsMissing] = useState<{
    status: boolean;
    retailersNames: string[];
  }>({
    status: false,
    retailersNames: [],
  });

  const checkRetailerBilling = useCallback(() => {
    if (!retailersIds?.length) return;

    const retailersWithMissingAddressDetails: string[] = [];
    retailersIds.forEach((retailerId) => {
      const retailer = retailers.find((retailer) => retailerId === retailer.id);
      if (retailer && !retailer?.billingInfo?.address) {
        retailersWithMissingAddressDetails.push(retailer?.name);
      }
    });
    setAddressDetailsMissing({
      retailersNames: retailersWithMissingAddressDetails,
      status: !!retailersWithMissingAddressDetails.length,
    });
  }, [retailersIds, retailers]);

  return { addressDetailsMissing, checkRetailerBilling };
}
