import { HStack } from '@swftbox/style-guide';
import { type Order } from 'src/components/Particles';
import { AddressCard } from 'src/components/Organisms';
import { useMemo } from 'react';
interface PickupAddressProps {
  order: Order;
}
export const PickupAddress = ({ order }: PickupAddressProps) => {
  const currentConsumer = useMemo(
    () =>
      order?.isReverse
        ? { consumer: order.customer, location: order.from }
        : { consumer: order.retailer, location: order.from },
    [order]
  );

  const name = useMemo(
    () =>
      (order?.isReverse ? currentConsumer?.consumer?.name : currentConsumer?.location?.name) || '-',
    [order, currentConsumer]
  );

  const phone = useMemo(
    () => currentConsumer?.location.phone ?? currentConsumer?.consumer?.phone ?? '',
    [currentConsumer]
  );

  const address = useMemo(() => {
    if (currentConsumer?.location) {
      const location = currentConsumer.location;
      return `
        ${location.company ?? ''} 
        ${location.addressLine1} 
        ${location.addressLine2 ?? ''} 
        ${location.building ?? ''} 
        ${location.area} - 
        ${location.city} 
        ${location.stateProvinceCode ?? ''} 
        ${location.country ?? ''} 
        ${location.zipPostalCode ?? ''}
      `;
    }
    return '';
  }, [currentConsumer]);

  const zone = useMemo(
    () =>
      `${currentConsumer?.location?.city ?? currentConsumer?.location?.country}
    ${` - ${currentConsumer.location.dispatchZone ?? ''}`}`,
    [currentConsumer]
  );

  return (
    <HStack width={{ lg: '240px', '2xl': '280px' }}>
      <AddressCard
        name={name}
        phone={phone}
        address={address}
        zone={zone}
        location={currentConsumer?.location}
        editable={!!order?.isReverse}
        showHistory
      />
    </HStack>
  );
};
