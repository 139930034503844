import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useCarriyoIntegrationCarriersQuery } from 'src/components/Particles';
import { CarriersHeader, CarriersTable } from './Components';
import style from './style.module.scss';

export const Carriers = () => {
  const { carriyoCarriers, loading } = useCarriyoIntegrationCarriersQuery();
  return (
    <Box className={style.carriers}>
      <CarriersHeader carriersCount={carriyoCarriers.length} />
      <WithLoader isLoading={loading}>
        <CarriersTable carriers={carriyoCarriers} />
      </WithLoader>
      {!loading && carriyoCarriers.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Carriers Found" />
        </Flex>
      )}
    </Box>
  );
};
