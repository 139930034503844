import { Badge, Button, HStack } from '@swftbox/style-guide';
import { useCallback, useEffect } from 'react';
import FilterWithOptions from 'src/components/Molecules/FilterWithOptions/FilterWithOptions';
import { useOrdersFilters, useOrdersFiltersActions, useSearch } from 'src/components/Particles';
import { ClearFilter } from '../../ClearFilters';
import { singleStatus, statusList } from './constants';
import { useFlaggedOrderCountsQuery } from 'src/components/Particles/resolvers/orders/getFlaggedCounts.service';
import { OrdersStatusFilter } from '../../../order.types';

export const StatusFilters = () => {
  const { searchText } = useSearch();
  const dispatch = useOrdersFiltersActions();
  const { getFlaggedOrderCounts, flaggedCounts } = useFlaggedOrderCountsQuery();

  const {
    filters: { statusId },
  } = useOrdersFilters();

  useEffect(() => {
    getFlaggedOrderCounts();

    dispatch({
      type: 'ORDER_STATUS_MAP_CHANGE',
      payload: { ...singleStatus.children[0].payload },
      id: [singleStatus.children[0].id],
    });
  }, [dispatch]);

  useEffect(() => {
    if (searchText) {
      dispatch({
        type: 'ORDER_STATUS_MAP_CHANGE',
        payload: { status: [], validationStatus: [] },
        id: [],
      });
      dispatch({ type: 'CLEAR_ORGANIZING_FILTERS', payload: {} });
    }
  }, [dispatch, searchText]);

  const handleSelectItem = useCallback(
    (ele: any) => {
      dispatch({
        type: 'ORDER_STATUS_MAP_CHANGE',
        payload: { ...ele.payload },
        id: Array.isArray(ele.id) ? ele.id : [ele.id],
      });
    },
    [dispatch]
  );
  return (
    <HStack spacing="2">
      <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2">
        {statusList?.map((state) =>
          !!state?.checkBoxItem ? (
            <FilterWithOptions state={state} onSelect={handleSelectItem} key={state.id} />
          ) : (
            <HStack
              key={state.id}
              border="1px solid #D0D5DD;"
              h="40px"
              lineHeight="40px"
              borderRadius="8px"
              shadow="xs"
              overflow="hidden"
              spacing="0"
            >
              <>
                {state?.children?.map((filterItem) => (
                  <Button
                    p="8px 12px"
                    fontSize="x-small"
                    key={filterItem.id}
                    variant="grouped"
                    bg={statusId?.includes(filterItem.id) ? '#63c3ec' : 'transparent'}
                    color={statusId?.includes(filterItem.id) ? '#fff' : 'gray.900'}
                    _focus={{ opacity: 0.85 }}
                    _hover={{ opacity: 0.85 }}
                    onClick={() => {
                      handleSelectItem(filterItem);
                    }}
                  >
                    {`${filterItem.name} `}
                    {[OrdersStatusFilter.FLAGGED, OrdersStatusFilter.STARRED].includes(
                      state.id as OrdersStatusFilter
                    ) && (
                      <Badge
                        p="2px 8px"
                        mx="1"
                        fontWeight="medium"
                        borderRadius="8px"
                        fontSize="x-small"
                        background={
                          flaggedCounts?.[filterItem.id] && flaggedCounts?.[filterItem.id] > 0
                            ? 'red'
                            : undefined
                        }
                        color={
                          flaggedCounts?.[filterItem.id] && flaggedCounts?.[filterItem.id] > 0
                            ? 'white'
                            : undefined
                        }
                      >
                        {flaggedCounts?.[filterItem.id]}
                      </Badge>
                    )}
                  </Button>
                ))}
              </>
            </HStack>
          )
        )}
      </HStack>
      <ClearFilter />
    </HStack>
  );
};
