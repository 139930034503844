export const DriversData = [
  {
    id: '1',
    employmentType: 'Freelance',
    name: 'Omar Adas',
    phone: '971590000997',
    gender: 'male',
    nationalId: '92382811jmd',
    liscenseNumber: '12345642kd',
    birthday: '1990-09-06',
    active: true,
    enabled: false,
    isOnline: false,
  },
  {
    id: '2',
    employmentType: 'Swftbox',
    name: 'Omar Adas',
    phone: '971590000997',
    gender: 'male',
    nationalId: '92382811jmd',
    liscenseNumber: '12345642kd',
    birthday: '1990-09-06',
    active: false,
    enabled: false,
    isOnline: false,
  },
  {
    id: '3',
    employmentType: 'Operator',
    name: 'Omar Adas',
    phone: '971590000997',
    gender: 'male',
    nationalId: '92382811jmd',
    liscenseNumber: '12345642kd',
    birthday: '1990-09-06',
    active: true,
    enabled: false,
    isOnline: false,
  },
  {
    id: '4',
    employmentType: 'Freelance',
    name: 'Omar Adas',
    phone: '971590000997',
    gender: 'male',
    nationalId: '92382811jmd',
    liscenseNumber: '12345642kd',
    birthday: '1990-09-06',
    active: true,
    enabled: true,
    isOnline: true,
  },
  {
    id: '5',
    employmentType: 'Freelance',
    name: 'Omar Adas',
    phone: '971590000997',
    gender: 'male',
    nationalId: '92382811jmd',
    liscenseNumber: '12345642kd',
    birthday: '1990-09-06',
    active: true,
    enabled: true,
    isOnline: true,
  },
];
export const headers = [
  {
    name: 'Name',
    action: () => null,
  },
  {
    name: 'Phone Number',
    action: () => null,
  },
  {
    name: 'is online',
    action: () => null,
  },
  {
    name: 'Employment Type',
    action: () => null,
  },
  {
    name: 'Total balance',
    action: () => null,
  },
  {
    name: 'COD balance',
    action: () => null,
  },
  {
    name: 'Access',
    action: () => null,
  },
];
