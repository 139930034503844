import { useMutation } from '@apollo/client';
import { GET_ITEMS_QUERY, type RemoveItemInput, REMOVE_ITEM_MUTATION } from '../Queries';
import { QueriesStore } from '../../../queriesStore';

interface UpdateItemResponse {
  message: string;
}

export function useRemoveItem() {
  const [mutate, { loading, data, error }] = useMutation<
    { removeItem: UpdateItemResponse },
    { removeItemInput: RemoveItemInput }
  >(REMOVE_ITEM_MUTATION, {
    refetchQueries: [
      {
        query: GET_ITEMS_QUERY,
        variables: QueriesStore.get('GET_ITEMS_QUERY'),
      },
    ],
  });

  async function callRemoveItem(payload: RemoveItemInput) {
    await mutate({
      variables: {
        removeItemInput: payload,
      },
      refetchQueries: [
        {
          query: GET_ITEMS_QUERY,
          variables: QueriesStore.get('GET_ITEMS_QUERY'),
        },
      ],
    });
  }

  return {
    callRemoveItem,
    data: data?.removeItem.message,
    loading,
    error,
  };
}
