import { Box, Map } from '@swftbox/style-guide';
import { Config } from 'src/config';
import DriversFootPrint from './DriversFootPrint';
import styles from './drivers-tracking.module.scss';
import { AccountStatus, DriverStatus, useAllDriversQuery } from 'src/components/Particles';
import { useFootprintService } from './services';

export function DriversTrackingPage() {
  const footprints = useFootprintService();

  const { drivers } = useAllDriversQuery({
    isOnline: true,
    userStatus: [AccountStatus.ACTIVE],
    status: DriverStatus.ENABLED,
  });

  const driversIds = drivers.map((driver) => driver.id);

  return (
    <Box className={styles.mapContainer}>
      <Map
        h="100vh"
        center={Config.DEFAULT_MAP_CENTER}
        zoom={12}
        Drivers={
          <DriversFootPrint
            onlineActiveDriversIds={driversIds}
            footprints={footprints}
            map={undefined}
          />
        }
      />
    </Box>
  );
}
