import { VStack, Button, Text, Box, SystemStyleObject } from '@swftbox/style-guide';
interface VerticalTabs {
  tabs: { id: string; name: string }[];
  active: string;
  handleChange: (id: string) => void;
  title?: string;
  fontSize?: string;
  parentStyle?: SystemStyleObject;
  buttonStyle?: SystemStyleObject;
}
export const VerticalTabs = ({
  tabs,
  active,
  handleChange,
  title,
  fontSize = 'text-sm',
  parentStyle,
  buttonStyle,
}: VerticalTabs) => {
  return (
    <Box my="2" mb="2" sx={parentStyle}>
      {!!title && (
        <Text fontSize="display-xs" color="gray.900" fontWeight="medium">
          {title}
        </Text>
      )}
      <VStack spacing="1" position="relative" alignItems="start" p="1">
        {tabs.map((tab) => (
          <Button
            flexShrink="0"
            key={tab.id}
            variant="group"
            color={active === tab.id ? 'gray.900' : 'gray.500'}
            ps="4"
            fontSize={fontSize}
            letterSpacing="0.3px"
            fontWeight="semibold"
            transition="all 0.3s"
            onClick={() => handleChange(tab.id)}
            boxShadow="none"
            h="36px"
            // flexGrow="1"
            py="2"
            _before={{
              content: "' '",
              position: 'absolute',
              width: '2px',
              height: '100%',
              left: 1,
              background: active === tab.id ? 'gray.900' : 'transparent',
            }}
            borderRadius="0px"
            textAlign="center"
            sx={buttonStyle}
            _hover={{
              color: 'gray.900',
              // background: 'blue.50',
              _before: {
                background: 'gray.900',
              },
            }}
          >
            {tab.name}
          </Button>
        ))}
      </VStack>
    </Box>
  );
};
