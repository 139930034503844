import { useMutation } from '@apollo/client';
import { toast } from '@swftbox/style-guide';
import { GET_SUBSCRIPTION_QUERY, PAUSE_PLAN_CUSTOMER_QUERY } from '../Subscription.graphql';

interface QueryResponse {
  pauseSubscription: {
    message: string;
  };
}
interface QueryInput {
  updateSubscriptionInput: { id: string };
}

interface Props {
  retailerId: string;
  subscriptionId: string;
}
export function usePausePlanRetailerQuery({ retailerId, subscriptionId }: Props) {
  const [mutate, rest] = useMutation<QueryResponse, QueryInput>(PAUSE_PLAN_CUSTOMER_QUERY);
  const handlePausePlan = () => {
    return mutate({
      variables: {
        updateSubscriptionInput: { id: subscriptionId },
      },
      onCompleted: ({ pauseSubscription }) => toast.success(pauseSubscription.message),
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_QUERY,
          variables: {
            retailerSubscribePlanInput: {
              retailerId,
            },
          },
        },
      ],
    });
  };
  return {
    handlePausePlan,
    ...rest,
  };
}
