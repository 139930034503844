export const removeTypeName: any = (input: any) => {
  if (Array.isArray(input)) {
    return input.map(removeTypeName);
  } else if (typeof input === 'object' && input !== null) {
    const { __typename, ...rest } = input;
    return Object.keys(rest).reduce((result, key) => {
      return { ...result, [key]: removeTypeName(rest[key]) };
    }, {});
  }
  return input;
};
