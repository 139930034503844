import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  IconButton,
  SBModal,
  toast,
  useDisclosure,
  Text,
  SBEditIcon,
  HStack,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdateCategory } from 'src/components/Particles/resolvers/setting/Locations/Services/UpdateCategory.service';
import { CategorySchema } from '../../Validations';
import LocationForm from './LocationForm';
import SVG from 'react-inlinesvg';
import Edit01Icon from 'src/assets/icons/General/Edit01.svg';
interface FormValue {
  id: string;
  name: string;
}

interface EditCategoryProps {
  category: FormValue;
}
export function EditCategory({ category }: EditCategoryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { callUpdateCategory } = useUpdateCategory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValue>({
    mode: 'all',
    resolver: yupResolver(CategorySchema),
    defaultValues: { ...category },
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) toast.success(message);
    if (!message) reset();
    onClose();
  };

  const onConfirm = (data: FormValue) => {
    void callUpdateCategory({
      payload: { name: data.name, id: data.id },
      onCompleted: (data) => {
        handleCloseModal(data.updateCategory.message);
      },
    });
  };

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit Category"
        icon={<SVG src={Edit01Icon} stroke="currentColor" width="15px" />}
        onClick={onOpen}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <HStack justifyContent="space-between">
              <Text>Edit Category</Text>
            </HStack>
          </Box>
        }
        size="xl"
        body={<LocationForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
