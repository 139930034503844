import { useQuery } from '@apollo/client';
import { GET_SIMPLE_TASK_CATEGORIES, type ISimpleTaskCategory } from '../Queries';

interface QueryResponse {
  taskCategories: ISimpleTaskCategory[];
}

export function useTaskCategoriesQuery() {
  const { data, loading, error } = useQuery<QueryResponse>(GET_SIMPLE_TASK_CATEGORIES, {
    pollInterval: 60000,
  });

  return {
    taskCategories: data?.taskCategories,
    loading,
    error,
  };
}
