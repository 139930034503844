import { useMutation } from '@apollo/client';
import { SYNC_ROUTES, FILTERED_JOBS } from '../Queries';

interface SyncRoutesResponse {
  message: string;
  success: boolean;
}
interface SyncRoutesProps {
  payload: {
    reference: string[];
  };
  onCompleted?: (data: { fixRoutesElasticSync: SyncRoutesResponse }) => void;
}

export function useSyncRoutes() {
  const [mutate, { loading, error }] = useMutation<
    { fixRoutesElasticSync: SyncRoutesResponse },
    { routesReferenceInput: { reference: string[] } }
  >(SYNC_ROUTES);

  async function syncRoutes({ payload, onCompleted }: SyncRoutesProps) {
    return mutate({
      variables: {
        routesReferenceInput: {
          ...payload,
        },
      },
      onCompleted,
      refetchQueries: [
        {
          query: FILTERED_JOBS,
          variables: { page: 1, perPage: 10 },
        },
      ],
    });
  }

  return {
    syncRoutes,
    loading,
    error,
  };
}
