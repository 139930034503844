import { useEffect, useState } from 'react';
import {
  Flex,
  Spinner,
  Button,
  Checkbox,
  FormLabel,
  HStack,
  Text,
  toast,
  Tooltip,
  Box,
  VStack,
  Switch,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import {
  NotificationTrigger,
  useGetNotificationTriggersQuery,
  useSystemNotficationsTriggers,
  useUpdateNotificationsTriggersMutation,
} from 'src/components/Particles';
import { SectionRow, SubmitAndCancel } from '../../Shared';

interface Props {
  retailerId: string;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
}

const transformToSentenceCase = (input: string): string => {
  return input
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

interface FormValues extends Record<keyof typeof NotificationTrigger, boolean> {}

interface NotificationInfo {
  label: string;
  description: string;
}

export function NotificationSettings(props: Props) {
  const { retailerId, setAllowToMove } = props;
  const [triggerLabels, setTriggerLabels] = useState<Record<NotificationTrigger, NotificationInfo>>(
    {} as Record<NotificationTrigger, NotificationInfo>
  );

  const { getNotificationTriggers, loading, triggers } =
    useGetNotificationTriggersQuery(retailerId);
  const { systemNotificationsTriggers } = useSystemNotficationsTriggers();
  const { updateNotificationsTriggers, loading: updating } =
    useUpdateNotificationsTriggersMutation();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: triggers,
  });

  useEffect(() => {
    if (!systemNotificationsTriggers?.length) return;

    const labels: Record<NotificationTrigger, NotificationInfo> = {} as Record<
      NotificationTrigger,
      NotificationInfo
    >;

    for (const trigger of systemNotificationsTriggers) {
      const triggerKey = trigger.trigger as NotificationTrigger;
      const label = transformToSentenceCase(triggerKey);

      labels[triggerKey] = { label, description: trigger.description };
    }

    setTriggerLabels(labels);
  }, [systemNotificationsTriggers]);

  useEffect(() => {
    getNotificationTriggers();
  }, []);

  useEffect(() => {
    if (!triggers) {
      return;
    }
    reset(triggers);
  }, [triggers]);
  useEffect(() => {
    if (isDirty) {
      setAllowToMove({ page: '', isAllowed: false });
    } else {
      setAllowToMove({ page: '', isAllowed: true });
    }
  }, [isDirty]);
  const onSubmit = handleSubmit(async (data) => {
    const triggers = Object.keys(NotificationTrigger).filter(
      (trigger: string) => !!data[trigger as keyof typeof NotificationTrigger]
    );
    const triggersInput = triggers.map((trigger) => ({ trigger: trigger, triggersType: 'sms' }));
    const { data: updateResult } = await updateNotificationsTriggers({
      retailerId,
      triggersInput,
    });

    toast.success(updateResult?.updateNotificationTriggers.message);
  });
  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box as="form" onSubmit={onSubmit} h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
        <SectionRow
          title="Customer Notification Triggers"
          description="Select the customer notifications triggers that best match the needs of the partner.

          Notifications are sent by WhatsApp, and as a backup by SMS in case the customer's number doesn't have a WhatsApp account."
          children={
            <>
              {(systemNotificationsTriggers || []).map((systemTrigger) => (
                <Box>
                  <Controller
                    key={`trigger-${systemTrigger.trigger}`}
                    name={systemTrigger.trigger as unknown as keyof typeof NotificationTrigger}
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Switch
                        id={triggerLabels[systemTrigger.trigger]?.label}
                        onChange={onChange}
                        ref={ref}
                        isChecked={value}
                        fontWeight="medium"
                        color="gray.700"
                        fontSize="text-sm"
                        display="flex"
                        alignItems="center"
                        cursor="pointer"
                      >
                        {triggerLabels[systemTrigger.trigger]?.label}
                      </Switch>
                    )}
                  />
                  <FormLabel
                    htmlFor={triggerLabels[systemTrigger.trigger]?.label}
                    color="gray.500"
                    fontSize="text-sm"
                    fontWeight="regular"
                    ps="10"
                    cursor="pointer"
                  >
                    {triggerLabels[systemTrigger.trigger]?.description}
                  </FormLabel>
                </Box>
              ))}
            </>
          }
        />
        <SubmitAndCancel isHidden={isDirty} reset={reset} loading={updating} />
      </VStack>
    </Box>
  );
}
