import {
  Badge,
  Box,
  HStack,
  IconWText,
  SBCommentUserIcon,
  SBFileCheckIcon,
  Text,
  Tooltip,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { type ReactNode, useMemo } from 'react';
import { type Order, OrderGroupType, PaymentMode } from 'src/components/Particles';
import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';
import SignatureIcon from 'src/assets/icons/SignatureIcon.svg';
import userYellow from 'src/assets/icons/General/userYellow.svg';
import SwitchIcon from 'src/assets/icons/exchange-arrow.svg';
import { StyledBadge } from 'src/components/Atom/StyledBadge';
import { type OrdersStatusFilter } from 'src/components/Pages/Orders/order.types';
import { statusNameMask } from '../../../Filters';
import OTPIcon from 'src/assets/icons/OTPonDelivery.svg';
import LeaveAtTheDoorIcon from 'src/assets/icons/leaveAtDoor.svg';

interface OrderDetailsHeaderProps {
  order?: Order;
  tabsButtons: ReactNode;
}
export const OrderDetailsHeader = ({ order, tabsButtons }: OrderDetailsHeaderProps) => {
  const { name, bg, border, color } = useMemo(
    () => statusNameMask(order?.status as OrdersStatusFilter),
    [order]
  );

  return (
    <Box mt="4">
      <Box bg="gray.100" borderRadius="8px" p="3">
        <HStack justifyContent="space-between">
          <Box color="primary.700" fontSize="text-lg">
            <HStack mb="2" gap="3">
              <Text>{order?.retailer.name}</Text>
              {order?.integration && (
                <Box>
                  <StyledBadge color="white" bg="blue.500">
                    {order.integration === 'SWFTBOX' ? 'DIRECT INTEGRATION' : order.integration}
                  </StyledBadge>
                </Box>
              )}
            </HStack>
            <Text mb="2" color="gray.700" fontSize="text-sm">
              {order?.swftboxTracking} - {order?.reference}
            </Text>

            <HStack>
              <Tooltip hasArrow label="Package count">
                <Badge
                  h="26px"
                  px="15px"
                  lineHeight="26px"
                  fontWeight="medium"
                  borderRadius="12px"
                  fontSize="x-small"
                  variant="outline"
                  color="black"
                  letterSpacing="2px"
                >
                  <IconWText
                    color="black"
                    text={`x${order?.packageCount?.toString() ?? ''}`}
                    Icon={<SVG src={PackageIcon} width="15px" stroke="currentColor" />}
                  />
                </Badge>
              </Tooltip>

              {order?.paymentMode === PaymentMode.paymentOnDelivery && (
                <Tooltip hasArrow label="Payment on delivery">
                  <Badge
                    p="4px 15px"
                    fontWeight="medium"
                    borderRadius="12px"
                    fontSize="text-sm"
                    variant="solid"
                    backgroundColor="#FF3A6E"
                    letterSpacing="1px"
                  >
                    {`${order?.paymentAmount ?? '0'} ${order?.currency}`}
                  </Badge>
                </Tooltip>
              )}
              {order?.allowCustomerProofSignature && (
                <Tooltip hasArrow label="Signature Required">
                  <Badge
                    p="6px 20px"
                    borderRadius="12px"
                    mx="5px"
                    fontSize="x-small"
                    variant="solid"
                    backgroundColor="#00B976"
                  >
                    <SVG
                      src={SignatureIcon}
                      width="14px"
                      height="14px"
                      stroke="currentColor"
                      strokeWidth=".1px"
                    />
                  </Badge>
                </Tooltip>
              )}
              {order?.requireOtpOnDelivery && (
                <Tooltip hasArrow label="OTP on delivery">
                  <Badge
                    p="6px 20px"
                    borderRadius="12px"
                    mx="5px"
                    fontSize="x-small"
                    variant="solid"
                    backgroundColor="#00B976"
                  >
                    <SVG src={OTPIcon} width="14px" height="14px" fill="currentColor" />
                  </Badge>
                </Tooltip>
              )}
              {order?.shouldLeaveAtTheDoor && (
                <Tooltip label="Leave at the door" hasArrow>
                  <Badge
                    p="6px 20px"
                    borderRadius="12px"
                    mx="5px"
                    fontSize="x-small"
                    variant="solid"
                    backgroundColor="#00B976"
                  >
                    <SVG src={LeaveAtTheDoorIcon} width="14px" height="14px" fill="currentColor" />
                  </Badge>
                </Tooltip>
              )}
              {order?.requireProofOfId && (
                <Tooltip hasArrow label="Proof of id">
                  <Badge
                    p="6px 20px"
                    borderRadius="12px"
                    mx="5px"
                    fontSize="x-small"
                    variant="solid"
                    backgroundColor="#00B976"
                  >
                    <SBCommentUserIcon width="14px" height="14px" />
                  </Badge>
                </Tooltip>
              )}
              {order?.requireDeliveryNote && (
                <Tooltip hasArrow label="Delivery note">
                  <Badge
                    p="6px 20px"
                    borderRadius="12px"
                    mx="5px"
                    variant="solid"
                    backgroundColor="#9747FF"
                  >
                    <SBFileCheckIcon width="14px" height="14px" />
                  </Badge>
                </Tooltip>
              )}
              {order?.groupType === OrderGroupType.EXCHANGE && (
                <Tooltip label="Order is Exchange" hasArrow>
                  <Badge
                    p="6px 20px"
                    borderRadius="12px"
                    mx="5px"
                    variant="solid"
                    background="#6764EC"
                    aria-label="exchange order"
                  >
                    <HStack spacing="2">
                      <HStack spacing="0.5">
                        <SVG
                          src={SwitchIcon}
                          width="11px"
                          height={'14px'}
                          stroke="#fff"
                          fill="#fff"
                        />
                        <SVG src={userYellow} width="11px" height="11px" fill="#fff" />
                      </HStack>
                    </HStack>
                  </Badge>
                </Tooltip>
              )}
            </HStack>
          </Box>
          <Box>
            <Badge
              py="1px"
              fontWeight="medium"
              borderRadius="8px"
              mb="2"
              width="100%"
              p="4px 8px"
              textAlign="center"
              fontSize="text-sm"
              border="1px solid"
              borderColor={border}
              bg={bg}
              color={color}
            >
              {name}
            </Badge>
            <HStack mb="2" color="gray.700" fontSize="text-sm">
              <Text>Target : </Text>
              {order?.timing.slaStart ? (
                <>
                  <Text>
                    {format(new Date(order?.timing.slaStart ?? Date.now()), 'HH:mm')} -{' '}
                    {format(new Date(order?.timing.slaEnd ?? Date.now()), 'HH:mm')}
                  </Text>
                  <Text>
                    {format(new Date(order?.timing.slaEnd ?? Date.now()), 'MMM dd, yyyy')}
                  </Text>
                </>
              ) : (
                <Text>-</Text>
              )}
            </HStack>
            <Text mb="2" color="gray.700" fontSize="text-sm">
              {`${order?.dropProfile?.name ?? ''} Delivery`}
            </Text>
          </Box>
        </HStack>
      </Box>
      {tabsButtons}
    </Box>
  );
};
