import { Box, Grid } from '@swftbox/style-guide';
import { useConfigurations } from 'src/components/Particles';
import { useMemo } from 'react';
import { ConfigurationsList } from '../../Configurations/Components';
import { subscriptionConfigKeys } from '../../../Helper';
export const SubscriptionConfig = () => {
  const { configurations } = useConfigurations();
  const removedSubscriptionConfig = useMemo(() => {
    const cloneConfig = [...configurations];

    return cloneConfig.filter((config) => subscriptionConfigKeys.includes(config?.key));
  }, [configurations]);
  return (
    <>
      <Box pb="8">
        <Grid templateColumns="repeat(12, 1fr)" gap="6">
          <ConfigurationsList configurations={removedSubscriptionConfig} />
        </Grid>
      </Box>
    </>
  );
};
