import { useLazyQuery } from '@apollo/client';
import { GET_USERS_SIMPLE_LIST } from './users.graphql';
import { type SimpleUser } from './users.types';

interface QueryResponse {
  users: SimpleUser[];
}

export function useUsersListQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_USERS_SIMPLE_LIST);

  function getUsers(roles: string[]) {
    void query({ variables: { roles } });
  }

  return {
    getUsers,
    users: data?.users,
    loading,
    error,
  };
}
