import { Tr, Td, Text } from '@swftbox/style-guide';

interface SubTotalProps {
  taxableAmount: number;
  vatAmount: number;
  totalAmount: number;
}

export function SubTotal({ taxableAmount, vatAmount, totalAmount }: SubTotalProps) {
  return (
    <>
      <Tr borderBottom={'none'}>
        <Td></Td>
        <Td fontWeight="bold">Subtotal</Td>
        <Td colSpan={2}></Td>
        <Td>
          <Text fontWeight={'bold'}>{taxableAmount.toFixed(2)}</Text>
        </Td>
        <Td></Td>
        <Td>
          <Text fontWeight={'bold'}>{vatAmount.toFixed(2)}</Text>
        </Td>
        <Td colSpan={2}>
          <Text fontWeight={'bold'}>{totalAmount.toFixed(2)}</Text>
        </Td>
      </Tr>
    </>
  );
}
