import React, { useCallback, useMemo, useState } from 'react';
import {
  type Address,
  CreateJobListItemType,
  JobItemsSelectionContext,
  type SimpleOrder,
  type SimpleRetailer,
} from 'src/components/Particles';
import { type IProviderProps } from '../../types';

export function JobItemsSelectionProvider({ children }: IProviderProps) {
  const [orders, setOrders] = useState<Map<string, SimpleOrder>>(new Map());
  const [rtoOrders, setRtoOrders] = useState<Map<string, SimpleOrder>>(new Map());
  const [retailers, setRetailers] = useState<Map<string, SimpleRetailer & { location: Address }>>(
    new Map()
  );

  const toggleOrderSelection = useCallback(
    (item: SimpleOrder) => {
      const newOrdersMap = new Map(orders);
      if (newOrdersMap.has(item.id)) {
        newOrdersMap.delete(item.id);
      } else {
        newOrdersMap.set(item.id, item);
      }
      setOrders(newOrdersMap);
    },
    [orders]
  );

  const toggleRtoOrderSelection = useCallback(
    (item: SimpleOrder) => {
      const newOrdersMap = new Map(rtoOrders);
      if (newOrdersMap.has(item.id)) {
        newOrdersMap.delete(item.id);
      } else {
        newOrdersMap.set(item.id, item);
      }
      setRtoOrders(newOrdersMap);
    },
    [rtoOrders]
  );

  const toggleRetailersSelection = useCallback(
    (item: SimpleRetailer & { location: Address }) => {
      const newRetailersMap = new Map(retailers);
      if (newRetailersMap.has(item.location.id)) {
        newRetailersMap.delete(item.location.id);
      } else {
        newRetailersMap.set(item.location.id, item);
      }
      setRetailers(newRetailersMap);
    },
    [retailers]
  );

  const toggleSelection = useCallback(
    (type: CreateJobListItemType, item: SimpleOrder | SimpleRetailer) => {
      const updateFunctions = {
        [CreateJobListItemType.ORDER]: () => {
          toggleOrderSelection(item as SimpleOrder);
        },
        [CreateJobListItemType.RTO_ORDER]: () => {
          toggleRtoOrderSelection(item as SimpleOrder);
        },
        [CreateJobListItemType.RETAILER]: () => {
          toggleRetailersSelection(item as SimpleRetailer & { location: Address });
        },
      };
      updateFunctions[type]();
    },
    [toggleOrderSelection, toggleRetailersSelection]
  );

  const contextValue = useMemo(
    () => ({
      orders,
      retailers,
      rtoOrders,
      toggleSelection,
    }),
    [orders, rtoOrders, retailers]
  );

  return (
    <JobItemsSelectionContext.Provider value={contextValue}>
      {children}
    </JobItemsSelectionContext.Provider>
  );
}
