import { Box, GridItem, HStack, Link, SBModal, Text, Image } from '@swftbox/style-guide';
import { Order, useOrderModalActions, useOrderModalContext } from 'src/components/Particles';

export function ProceduresModal() {
  const { proceduresIsOpen, data } = useOrderModalContext() as {
    proceduresIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();

  const handleCloseModal = () => {
    dispatch({ type: 'CLOSE_PROCEDURES' });
  };
  if (!data?.retailer?.procedures) return <></>;
  return (
    <>
      <SBModal
        isOpen={proceduresIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Text>Address History</Text>
          </Box>
        }
        body={
          <>
            {data?.retailer?.procedures.map((procedure) => {
              return (
                <GridItem colSpan={12} key={procedure.id}>
                  <HStack fontSize="text-md" alignItems="center" fontWeight="bold">
                    <Text textTransform="capitalize">{`${procedure.type.toLowerCase()} SOPs`}</Text>
                  </HStack>
                  <Text fontSize="text-sm" fontWeight="medium" color="gray.500" ml="7">
                    {procedure.description}
                  </Text>
                  <HStack>
                    {procedure.images?.map((image, index) => {
                      return (
                        <Link href={image} key={image} isExternal>
                          <Image
                            boxSize={'100px'}
                            objectFit={'contain'}
                            src={image}
                            alt={`image #${index + 1}`}
                          />
                        </Link>
                      );
                    })}
                    {procedure.videos?.map((video, index) => {
                      return (
                        <Link href={video} key={video} isExternal>
                          <Box>
                            <video width="100" height="100" controls>
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                        </Link>
                      );
                    })}
                  </HStack>
                </GridItem>
              );
            })}
          </>
        }
        size="3xl"
        bordered={false}
        footer={<div />}
      />
    </>
  );
}
