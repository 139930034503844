import { Outlet, RouteObject } from 'react-router-dom';
import { ExpensesSettlementsPage, ExpensesTransactionsPage } from 'src/components/Pages';
import { ErrorBoundary } from '../../ErrorBoundary';

export const ExpensesRoutes: RouteObject = {
  path: 'expenses',
  element: (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <ExpensesTransactionsPage />,
    },
    {
      path: 'settlements',
      element: <ExpensesSettlementsPage />,
    },
  ],
};
