import {
  Box,
  Button,
  IconWText,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { Order, usePushOrderToDispatch } from 'src/components/Particles/resolvers/orders';
import DispatchIcon from 'src/assets/icons/eCommerce/dispatch.svg';
import SVG from 'react-inlinesvg';
interface PushOrderToDispatchProps {
  order: Order;
}

export function PushOrderToDispatch({ order }: PushOrderToDispatchProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pushOrderToDispatch, loading } = usePushOrderToDispatch();

  const onSubmitComplete = (message: string) => {
    toast.success(message);
    onClose();
  };

  function onConfirm() {
    void pushOrderToDispatch({
      payload: { ids: [order.id] },
      onCompleted: onSubmitComplete,
    });
  }

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconWText
          text="Push to dispatch"
          spacing="20px"
          Icon={<SVG src={DispatchIcon} width="15px" stroke="currentColor" />}
        />
      </MenuItem>

      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="warning"
        header={
          <Box pb="1vh">
            <Text>Push to dispatch</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to push order{' '}
            <Text as="span" fontWeight="semibold">
              {order.swftboxTracking}
            </Text>{' '}
            to dispatch !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
