import { Box, Button, Grid, GridItem, SBInput, toast } from '@swftbox/style-guide';
import { useAddItem, type ItemInput } from 'src/components/Particles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AddItemSchema } from './item.schema';
import { useEffect } from 'react';

interface AddItemProps {
  orderId: string;
}

export function AddItem(props: AddItemProps) {
  const { orderId } = props;
  const { callAddItem, data: addMessage } = useAddItem();
  const handleAddItems = (data: ItemInput) => {
    void callAddItem({ orderId, items: [data] });
  };

  useEffect(() => {
    if (addMessage) toast.success(addMessage);
  }, [addMessage]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ItemInput>({
    mode: 'onSubmit',
    resolver: yupResolver(AddItemSchema),
  });

  const onConfirm = async (data: ItemInput) => {
    if (data) {
      handleAddItems(data);
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onConfirm)}>
      <Box mt="4" mb="3">
        <Box bg="gray.100" borderRadius="8px" p="3">
          <Box color="primary.700" fontSize="text-lg">
            <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4}>
              <GridItem colSpan={3}>
                <SBInput
                  crossOrigin={undefined}
                  label="Add New Item"
                  placeholder="SKU Number*"
                  {...register('skuNumber')}
                  error={errors.skuNumber?.message}
                />
              </GridItem>
              <GridItem colSpan={3} mt="22px">
                <SBInput
                  crossOrigin={undefined}
                  placeholder="SKU Name"
                  {...register('sku')}
                  error={errors.sku?.message}
                />
              </GridItem>
              <GridItem colSpan={4} mt="22px">
                <SBInput
                  crossOrigin={undefined}
                  placeholder="Barcode"
                  {...register('barcode')}
                  error={errors.barcode?.message}
                />
              </GridItem>
              <GridItem colSpan={1} mt="22px">
                <SBInput
                  crossOrigin={undefined}
                  placeholder="Price"
                  {...register('price')}
                  error={errors.price?.message}
                />
              </GridItem>
              <GridItem colSpan={1} mt="22px">
                <SBInput
                  crossOrigin={undefined}
                  placeholder="Qty"
                  {...register('quantity')}
                  error={errors.quantity?.message}
                  defaultValue={1}
                />
              </GridItem>
              <GridItem colSpan={2} mt="22px">
                <Button w="100%" type="submit">
                  Add
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
