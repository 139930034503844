import { FC } from 'react';
import { Box, Text, Input, Autocomplete, Button, FormLabel, HStack, Stack, toast, FormControl } from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import {
	useCreatePickupProfileMutation,
	SavePickupProfileInput,
  PickupProfile,
  useUpdatePickupProfileMutation
} from 'src/components/Particles';

interface Props {
	retailerId: string;
	onClose?: () => void;
	pickupProfile?: PickupProfile;
}

const dayOptions = [
	{ label: 'Sunday', value: 'sunday' },
	{ label: 'Monday', value: 'monday' },
	{ label: 'Tuesday', value: 'tuesday' },
	{ label: 'Wednesday', value: 'wednesday' },
	{ label: 'Thursday', value: 'thursday' },
	{ label: 'Friday', value: 'friday' },
	{ label: 'Saturday', value: 'saturday' },
];

export const PickupProfileForm: FC<Props> = ({ retailerId, pickupProfile, onClose }) => {
	const {
		handleSubmit,
		reset,
		control,
	} = useForm<SavePickupProfileInput>({
		mode: 'all',
		defaultValues: {
			retailerId,
			id: pickupProfile?.id,
			day: pickupProfile?.day,
			slot: {
				from: pickupProfile?.slot?.from,
				to: pickupProfile?.slot?.to,
				date: pickupProfile?.slot?.date,
			},
			cutoffTime: pickupProfile?.cutoffTime ? pickupProfile.cutoffTime.replace(new RegExp(':00$'), '') : undefined,
		},
	});

	const { createPickupProfile, loading: creating } = useCreatePickupProfileMutation();
	const { updatePickupProfile, loading: updating } = useUpdatePickupProfileMutation();

	const onSubmit = handleSubmit(async (data) => {
		if (!data.id) {
			const { data: result } = await createPickupProfile(data);
			toast.success(result?.createPickupProfile?.message);
		} else {
			const { data: result } = await updatePickupProfile(data);
			toast.success(result?.updatePickupProfile?.message);
		}

		reset();
		onClose && onClose();
	});

	return (
		<form onSubmit={onSubmit}>
			<Box mb={5}>
        <Text fontWeight={'bold'}>{pickupProfile ? 'Edit pickup profile' : 'Create new pickup profile'}</Text>
			</Box>
			<Stack gap={4}>
				<HStack>
					<Controller
						name="day"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Autocomplete
								label="Day"
								options={dayOptions}
								value={dayOptions.find(day => day.value === value) || ''}
								onChange={(val) => {
									// @ts-expect-error, it's just a falsy warning
									onChange(val?.value);
								}}
							/>
						)}
					/>
					<Controller
						name="cutoffTime"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControl>
	        			<FormLabel>Cutoff time</FormLabel>
								<Input type="time" value={value || ''} onChange={onChange} required />
							</FormControl>
						)}
					/>
				</HStack>
				<HStack>
					<Controller
						name="slot.from"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControl>
	        			<FormLabel>From</FormLabel>
								<Input type="time" value={value || ''} onChange={onChange} required />
							</FormControl>
						)}
					/>
					<Controller
						name="slot.to"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControl>
	        			<FormLabel>To</FormLabel>
								<Input type="time" value={value || ''} onChange={onChange} required />
							</FormControl>
						)}
					/>
				</HStack>
				<Stack spacing="3" w="100%" direction={['column', 'row']}>
					<Button variant="outline" w="100%" onClick={onClose}>
						Cancel
					</Button>
					<Button w="100%" type="submit" disabled={creating || updating}>
						Save
					</Button>
				</Stack>
			</Stack>
		</form>
	);
}
