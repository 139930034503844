import { SBInput, SBTextArea, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { FeatureCategory } from 'src/components/Particles';

interface FormValue {
  name: string;
  description: string;
  billing: number;
  category: FeatureCategory;
}

interface FormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
}

export default function CommonFeatureForm(props: FormProps) {
  const { register, errors } = props;

  return (
    <Stack gap={4} as={'form'}>
      <SBInput
        label="Feature Name*"
        placeholder="Add Feature's Name"
        {...register('name')}
        error={errors.name?.message}
      />
      <SBInput
        label="Billing Fee*"
        placeholder="Add Feature Billing Fee"
        {...register('billing')}
        error={errors.billing?.message}
      />
      <SBTextArea
        label="Description"
        minHeight="60px"
        placeholder="Please add the feature description here..."
        {...register('description')}
      />
    </Stack>
  );
}
