import { useMutation } from '@apollo/client';
import { UploadDirectory, useFileUpload } from 'src/components/Particles';
import { MessageResponse } from '../../../SharedTypes';
import { CustomerDropInput, CUSTOMER_DROP_MUTATION } from '../../Queries';

export function useCustomerDropMutation() {
  const [mutate, { loading, error }] = useMutation<
    { dropAtCustomer: MessageResponse },
    { customerDropInput: CustomerDropInput }
  >(CUSTOMER_DROP_MUTATION);

  const { uploadFile } = useFileUpload({ directory: UploadDirectory.RECEIPTS });

  async function customerDrop(customerDropInput: CustomerDropInput) {
    let receipt = undefined;
    if (customerDropInput.receipt instanceof File) {
      const { filePath } = await uploadFile(customerDropInput.receipt);
      receipt = filePath;
    }
    return mutate({
      variables: {
        customerDropInput: {
          ...customerDropInput,
          receipt,
        },
      },
      refetchQueries: [
        'PaginatedRoutes',
      ],
    });
  }

  return {
    customerDrop,
    loading,
    error,
  };
}
