import { useLazyQuery } from '@apollo/client';
import { GET_DRIVERS } from './drivers.graphql';
import { type Driver } from './drivers.types';
import { QueriesStore } from '../queriesStore';
import { useCallback } from 'react';

interface QueryResponse {
  drivers: Driver;
}

interface DriverQueriesVariables {
  page: number;
  userStatus?: string[];
  employmentType?: string[];
  text?: string;
}

export function useDriversQuery(initialVariables: DriverQueriesVariables) {
  const [query, { data, loading }] = useLazyQuery<QueryResponse>(GET_DRIVERS, {
    variables: initialVariables,
  });

  const getDrivers = useCallback(
    (variables: Partial<DriverQueriesVariables>) => {
      QueriesStore.update('GET_DRIVERS', { ...variables, ...initialVariables, page: 1 });
      void query({
        variables,
      });
    },
    [initialVariables, query]
  );
  return {
    getDrivers,
    drivers: data?.drivers?.data || [],
    totalDrivers: data?.drivers?.total || 0,
    pageCount: data?.drivers?.pageCount || 1,
    loading: loading,
  };
}
