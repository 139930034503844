import uuid from 'react-uuid';
const statusFilter = [
  {
    id: uuid(),
    label: 'All',
    value: 'all',
    background: '#ebf9fe',
  },
  {
    id: uuid(),
    label: 'Active',
    value: 'active',
    background: '#f0f5fe',
  },
  {
    id: uuid(),
    label: 'Deactivated',
    value: 'deactivated',
    background: '#fff5e6',
  },
];

const BEUsersFilters = [
  {
    id: uuid(),
    label: 'Users',
    value: 'beUser',
    background: '#ebf9f1',
  },
  {
    id: uuid(),
    label: 'Admin',
    value: 'beAdmin',
    background: '#ebf9f1',
  },
  {
    id: uuid(),
    label: 'Dispatcher',
    value: 'beDispatcher',
    background: '#ebf9f1',
  },
];
const CRUsersFilters = [
  {
    id: uuid(),
    label: 'Users',
    value: 'crUser',
    background: '#ebf9f1',
  },
  {
    id: uuid(),
    label: 'Admin',
    value: 'crAdmin',
    background: '#ebf9f1',
  },
  {
    id: uuid(),
    label: 'Operations',
    value: 'crOperations',
    background: '#ebf9f1',
  },
];
const driversFilters = [
  {
    id: uuid(),
    label: 'Drivers',
    value: 'driver',
    background: '#ebf9f1',
  },
];

export const filters = [
  {
    id: uuid(),
    children: statusFilter,
    name: '',
  },
  {
    id: uuid(),
    children: BEUsersFilters,
    name: 'BE',
  },
  {
    id: uuid(),
    children: driversFilters,
    name: '',
  },
  {
    id: uuid(),
    children: CRUsersFilters,
    name: 'CR',
  },
];

export type UserFilterKey =
  | 'all'
  | 'active'
  | 'deactivated'
  | 'crAdmin'
  | 'crUser'
  | 'crOperations'
  | 'beUser'
  | 'beAdmin'
  | 'beDispatcher'
  | 'driver';