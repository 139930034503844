import {
  Autocomplete,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  SBInput,
  SBTextArea,
  SBTrashIcon,
  Stack,
  FormLabel,
  Switch,
} from '@swftbox/style-guide';
import {
  type Control,
  Controller,
  useFieldArray,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import { PlanType, type PlanProps } from 'src/components/Particles';

interface PlanFormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<PlanProps>;
  control: Control<PlanProps, any>;
}

export function PlanForm(props: PlanFormProps) {
  const { register, errors, control } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'features', // Name of the field array which must be the same as in defaultValues
  });

  const planTypeOptions = [
    {
      label: PlanType.DELIVERY,
      value: PlanType.DELIVERY,
    },
    {
      label: PlanType.FULFILLMENT,
      value: PlanType.FULFILLMENT,
    },
  ];
  return (
    <Stack gap={4}>
      <Grid templateColumns="repeat(12, 1fr)" gap="2">
        <GridItem colSpan={6}>
          <SBInput
            label="Terms Template ID*"
            placeholder="xxxx-xxxxx-xxxx"
            {...register('termsTemplateId')}
            error={errors.termsTemplateId?.message}
            crossOrigin
          />
        </GridItem>
        <GridItem colSpan={6}>
          <SBInput
            label="Stripe Price ID*"
            placeholder="price_*******"
            {...register('stripePriceId')}
            error={errors.stripePriceId?.message}
            crossOrigin
          />
        </GridItem>
        <GridItem colSpan={6}>
          <SBInput
            label="Name*"
            placeholder="Add Plan's name"
            {...register('name')}
            error={errors.name?.message}
            crossOrigin
          />
        </GridItem>
        <GridItem colSpan={3}>
          <SBInput
            label="Price*"
            placeholder="100"
            {...register('price')}
            error={errors.price?.message}
            crossOrigin
          />
        </GridItem>
        <GridItem colSpan={3}>
          <SBInput
            label="Currency"
            placeholder="AED"
            {...register('currency')}
            error={errors.currency?.message}
            crossOrigin
          />
        </GridItem>
        <GridItem colSpan={6}>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                label="Plan type"
                options={planTypeOptions}
                value={planTypeOptions.find((option) => {
                  return value === option.value;
                })}
                className="auto-complete-multi"
                onChange={(option) => {
                  if (!option) return;
                  onChange((option as { value: string }).value);
                }}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={6}>
          <HStack spacing={2} pt="8" pl="4">
            <Switch id="isMostPopular" {...register('isMostPopular')} />
            <FormLabel htmlFor="isMostPopular" mb="0">
              Most Popular Plan
            </FormLabel>
          </HStack>
        </GridItem>
        <GridItem colSpan={12}>
          <SBTextArea
            label="Description"
            placeholder="Ideal if you're just getting started and have less than 30 deliveries a month."
            {...register('description')}
            error={errors.description?.message}
          />
        </GridItem>
        <GridItem colSpan={12}>
          {fields.map((item, index) => {
            console.log(item);
            return (
              <HStack key={index} mb="2" alignItems="center">
                <Controller
                  render={({ field }) => (
                    <SBInput {...field} crossOrigin label={`Feature ${index + 1}`} />
                  )}
                  name={`features[${index}]` as const}
                  control={control}
                />

                <IconButton
                  onClick={() => {
                    remove(index);
                  }}
                  size="small"
                  variant="link"
                  color="gray.600"
                  pt="5"
                  _hover={{ opacity: '0.5', color: 'red' }}
                  aria-label="delete customer"
                  icon={<SBTrashIcon width="15px" />}
                />
              </HStack>
            );
          })}
          <Button
            type="button"
            onClick={() => {
              append('');
            }}
            mb="0"
            width="100%"
          >
            Add Feature
          </Button>
        </GridItem>
      </Grid>
    </Stack>
  );
}
