export interface GetUploadUrlVariables {
  fileType: string;
  fileDirectory: string;
}

export enum UploadDirectory {
  LICENCES = 'LICENCES',
  NATIONAL_IDS = 'NATIONAL_IDS',
  PROFILE_IMAGES = 'PROFILE_IMAGES',
  CERTIFICATES = 'CERTIFICATES',
  RECEIPTS = 'RECEIPTS',
}
