import { HStack, Spacer, Stack, Text, VStack } from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import swftboxIcon from 'src/assets/swftbox.svg';
import { BillingInfo } from 'src/components/Particles';

interface InvoiceHeaderProps {
  retailerName: string;
  billingInfo: BillingInfo;
}

export const InvoiceHeader = ({ retailerName, billingInfo }: InvoiceHeaderProps) => {
  return (
    <Stack>
      <HStack justify={'space-between'} align={'flex-start'}>
        <VStack align={'start'}>
          <Text fontWeight={'bold'} fontSize={'xx-large'}>
            TAX INVOICE
          </Text>
          <Spacer />
          <Text>{retailerName}</Text>
          <Text>{billingInfo.address}</Text>
          <Text>{billingInfo.phone}</Text>
          <Text>{billingInfo.email}</Text>
        </VStack>
        <VStack align={'flex-end'}>
          <SVG src={swftboxIcon} />
          <Spacer />
          <Text>Swftbox Delivery Service LLC</Text>
          <Text>Dubai Real Estate Corporate Warehouse SOl,</Text>
          <Text>Street 24b, Alquoz Industrial 2, PO Box 211231,</Text>
          <Text>Dubai, United Arab Emirates</Text>
          <Text>+971585822753</Text>
          <Text>TRN: 100559568900003</Text>
        </VStack>
      </HStack>
    </Stack>
  );
};
