import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Spinner,
  IconWText,
  PencilIcon,
  Link,
  Image,
  Stack,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { Comment } from 'src/components/Particles';

interface Props {
  comments: Comment[];
  editComment: (comment: Comment) => void;
  loading: boolean;
}

const tableHeaders = [
  { title: '#', subtitle: '', id: 'id' },
  { title: 'User', subtitle: '', id: 'user' },
  { title: 'Comment', subtitle: '', id: 'comment' },
  { title: 'Attachments', subtitle: '', id: 'attachments' },
  { title: 'Input Time', subtitle: '', id: 'inputTime' },
  { title: '', subtitle: '', id: 'actions' },
];

export default function CommentsTable(props: Props) {
  const { comments, loading, editComment } = props;

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box>
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead>
            <Tr bg="#fbfbfc" color="neutral.normal">
              {tableHeaders.map((header) => (
                <Th key={header.id}>
                  <Text>{header.title}</Text>
                  <Text color="neutral.normal">{header.subtitle}</Text>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {comments?.map((comment: Comment, index: number) => {
              const commentDate = comment?.createdAt ? new Date(comment?.createdAt) : new Date();
              return (
                <Tr key={comment.id}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <Text>{comment?.user?.name}</Text>
                  </Td>
                  <Td>
                    <Text>{comment.comment}</Text>
                  </Td>
                  <Td>
                    <Stack>
                      {comment?.attachments?.map((attachment: string, index: number) => (
                        <Link href={attachment} isExternal>
                          <Image
                            boxSize={'200px'}
                            objectFit={'contain'}
                            src={attachment}
                            alt={`attachment #${index + 1}`}
                          />
                        </Link>
                      ))}
                    </Stack>
                    {!comment?.attachments?.length && 'No Attachment'}
                  </Td>
                  <Td>
                    <Text>{format(commentDate, 'yyyy-MM-dd HH:mm')}</Text>
                  </Td>
                  <Td></Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
