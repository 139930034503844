import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  SBAddIcon,
  SBModal,
  SBMapMarkerIcon,
  useDisclosure,
  toast,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { useAddCategory } from 'src/components/Particles';
import { CategorySchema } from '../../Validations';
import LocationForm from './CategoryForm';

interface FormValue {
  name: string;
}

export default function AddCategory() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { callAddCategory } = useAddCategory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValue>({
    mode: 'all',
    resolver: yupResolver(CategorySchema),
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = (data: FormValue) => {
    void callAddCategory({
      payload: { ...data },
      onCompleted: (data) => {
        handleCloseModal(data.createCategory.message);
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Category
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBMapMarkerIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
          </Box>
        }
        size="xl"
        body={<LocationForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
