import { Box, Flex, Spacer, WithLoader } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  useTasksQuery,
  SortOptions,
  useSearch,
  TaskSortableFields,
  TaskStatus,
  type TaskFilters,
  useAuthContext,
  type DateFilter,
} from '../../Particles';
import {
  TasksFilter,
  TasksHeader,
  TasksTable,
  type TaskFilterKey,
  TasksDateFilter,
} from './Components';

const tasksQueryInitialVariables = {
  page: 1,
  perPage: 20,
  order: SortOptions.DESC,
  orderBy: TaskSortableFields.dueDate,
};

export function TasksPage() {
  const { searchText } = useSearch();
  const { user } = useAuthContext();
  const isAdmin = user?.roles?.includes('Admin') || user?.roles?.includes('SuperAdmin') || false;

  const [page, setPage] = useState(1);
  const [retailerId, setRetailerId] = useState<string | null>();
  const [assigneeId, setAssigneeId] = useState<string | null>();
  const [dateFilter, setDateFilter] = useState<DateFilter>();

  const [filters, setFilters] = useState<TaskFilters>({
    status: [TaskStatus.PENDING, TaskStatus.STARTED],
  });

  const { getTasks, tasks, totalTasks, loading } = useTasksQuery(tasksQueryInitialVariables);

  const onPageChange = (selectedItem: number) => {
    setPage(selectedItem);
  };

  const onFilterChange = (selectedFilter: TaskFilterKey) => {
    const filterValue = {
      all: {},
      pending: { status: [TaskStatus.PENDING] },
      started: { status: [TaskStatus.STARTED] },
      blocked: { status: [TaskStatus.BLOCKED] },
      done: { status: [TaskStatus.COMPLETED] },
      cancelled: { status: [TaskStatus.CANCELLED] },
    }[selectedFilter];
    setFilters(filterValue);
  };

  function onDateSelectionChange(dates?: DateFilter) {
    setDateFilter(dates ?? undefined);
  }

  useEffect(() => {
    getTasks({
      ...filters,
      ...dateFilter,
      page,
      keyword: searchText,
      retailerId: retailerId as string | undefined,
      assigneeId: (!isAdmin ? user?.id : assigneeId) as string | undefined,
    });
  }, [page, filters, searchText, retailerId, assigneeId, dateFilter, getTasks, isAdmin, user]);

  return (
    <Box>
      <TasksHeader tasksCount={totalTasks} isAdmin={isAdmin} />
      <Flex align="center">
        <TasksFilter
          onFilterChange={onFilterChange}
          retailerId={retailerId}
          setRetailerId={setRetailerId}
          assigneeId={assigneeId}
          setAssigneeId={setAssigneeId}
          isAdmin={isAdmin}
        />
        <Spacer />
        <TasksDateFilter onDateRangeChange={onDateSelectionChange} />
      </Flex>

      <WithLoader isLoading={loading}>
        <TasksTable
          tasks={tasks}
          page={page}
          total={totalTasks}
          onPageChange={onPageChange}
          isAdmin={isAdmin}
        />
      </WithLoader>
      {!loading && !tasks.length && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Tasks Found" />
        </Flex>
      )}
    </Box>
  );
}
