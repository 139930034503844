// import { createMinMaxOptionalValidation } from 'src/components/Particles/SharedValidations';
import * as yup from 'yup';

export const planSchema = yup.object().shape({
  name: yup.string().required('name is required'),
  price: yup.string().required('Price is required'),
  currency: yup.string().required('currency is required'),
  description: yup.string().required('description is required'),
  type: yup.string().required('plan type is required'),
  termsTemplateId: yup.string().required('terms ID is required'),
  stripePriceId: yup.string().required('Stripe ID is required'),
});
