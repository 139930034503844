import { Helmet } from "react-helmet";
import { ErrorBoundary } from "src/components/Particles";
import { PartnersPage } from "./partners.page";

export function PartnersPageWrapper() {
	return (
		<>
			<Helmet>
				<title> Partners </title>
			</Helmet>
			<ErrorBoundary>
				<PartnersPage/>
			</ErrorBoundary>
		</>
	)
}
