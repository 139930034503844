import { SBInput, Stack } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';

interface FormValue {
  name: string;
}

interface CategoryFormProps {
  category?: FormValue;
}

export default function CategoryForm(props: CategoryFormProps) {
  const { category } = props;
  const { register } = useForm<FormValue>({
    mode: 'all',
    defaultValues: { ...category },
  });

  return (
    <Stack gap={4}>
      <SBInput
        label="Category Name*"
        placeholder="Category"
        {...register('name')}
        isDisabled
        
        
      />
    </Stack>
  );
}
