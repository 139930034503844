import { gql } from '@apollo/client';

export const GET_PAGINATED_TASKS = gql`
  query PaginatedTasks($page: Int, $perPage: Int, $orderBy: String, $order: OrderSort, $retailerId: UUID, $assigneeId: UUID, $status: [TaskStatus!], $keyword: String, $startDate: DateTime, $endDate: DateTime) {
    paginatedTasks(page: $page, perPage: $perPage, orderBy: $orderBy, order: $order, retailerId: $retailerId, assigneeId: $assigneeId, status: $status, keyword: $keyword, startDate: $startDate, endDate: $endDate) {
      data {
        id
        createdAt
        updatedAt
        deletedAt
        retailerId
        assigneeId
        unitCount
        dueDate
        description
        status
        startDate
        taskCategoryId
        taskCategory {
          name
          description
          unitType
        }
        retailer {
          name
        }
        assignee {
          name
        }
      }
      total
      pageCount
      currentPage
      perPage
      prevPage
      nextPage
    }
  }
`;

export const GET_TASK = gql`
  query Task($taskId: UUID!) {
    task(id: $taskId) {
      id
      createdAt
      updatedAt
      deletedAt
      retailerId
      assigneeId
      unitCount
      dueDate
      description
      status
      startDate
      taskCategoryId
      taskCategory {
        name
        description
        unitType
      }
      retailer {
        name
      }
      assignee {
        name
      }
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTask($createTaskInput: CreateTaskInput!) {
    createTask(createTaskInput: $createTaskInput) {
      message
    }
  }
`;

export const UPDATE_TASK_INFO = gql`
  mutation UpdateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(updateTaskInput: $updateTaskInput) {
      message
    }
  }
`;

export const UPDATE_TASK_STATUS = gql`
  mutation UpdateTaskStatus($updateTaskStatusInput: UpdateTaskStatusInput!) {
    updateTaskStatus(updateTaskStatusInput: $updateTaskStatusInput) {
      message
    }
  }
`;

export const CANCEL_TASK = gql`
  mutation CancelTask($cancelTaskId: UUID!) {
    cancelTask(id: $cancelTaskId) {
      message
    }
  }
`;

export const DELETE_TASK = gql`
  mutation RemoveTask($removeTaskId: UUID!) {
    removeTask(id: $removeTaskId) {
      success
      message
    }
  }
`;

export const GET_SIMPLE_TASK_CATEGORIES = gql`
  query TaskCategories {
    taskCategories {
      id
      name
      unitType
    }
  }
`;