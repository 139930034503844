import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../../SharedTypes';
import { STOP_FAILED_MUTATION, StopFailedInput } from '../../Queries';

export function useStopFailedMutation() {
  const [mutate, { loading, error }] = useMutation<
    { stopFailed: MessageResponse },
    { stopFailedInput: StopFailedInput }
  >(STOP_FAILED_MUTATION);

  async function stopFailed(stopFailedInput: StopFailedInput) {
    return mutate({
      variables: {
        stopFailedInput,
      },
      refetchQueries: [
        'PaginatedRoutes',
      ],
    });
  }

  return {
    stopFailed,
    loading,
    error,
  };
}
