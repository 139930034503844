import { SBInput, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';

interface FormValue {
  key: string;
  value: string;
}

interface ConfigurationFormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
}

export default function ConfigurationForm(props: ConfigurationFormProps) {
  const { register, errors } = props;

  return (
    <Stack gap={4}>
      <SBInput
        label="Configuration key*"
        placeholder="Add Configuration's key"
        {...register('key')}
        error={errors.key?.message}
      />
      <SBInput
        label="Configuration Value*"
        placeholder="Add Configuration's value"
        {...register('value')}
        error={errors.value?.message}
      />
    </Stack>
  );
}
