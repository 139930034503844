import {
  Flex,
  IconButton,
  SBTrashIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  toast,
} from '@swftbox/style-guide';
import { format } from 'date-fns';
import { TaskCategory, useRemoveTaskCategory } from 'src/components/Particles';
import { EditTaskCategory } from './Modals/EditTaskCategory';
import { ViewTaskCategory } from './Modals/ViewTaskCategory';

interface TaskCategoriesTableProps {
  taskCategories: TaskCategory[];
}

export default function TaskCategoriesTable(props: TaskCategoriesTableProps) {
  const { taskCategories } = props;
  const { callRemoveTaskCategory } = useRemoveTaskCategory();
  if (!taskCategories.length) return null;

  const onDelete = (id: string) => {
    callRemoveTaskCategory({
      payload: { id },
      onCompleted: ({ removeTaskCategory }) => {
        if (removeTaskCategory.message) {
          toast.success(removeTaskCategory.message);
        }
      },
    });
  };
  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead>
          <Tr bg="#fbfbfc">
            <Th>Name</Th>
            <Th>Created At</Th>
            <Th>Description</Th>
            <Th>Unit Type</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {taskCategories.map((taskCategory) => {
            const categoryDate = taskCategory?.createdAt
              ? new Date(taskCategory?.createdAt)
              : new Date();
            return (
              <Tr key={taskCategory.id}>
                <Td>
                  <Text>{taskCategory?.name}</Text>
                </Td>
                <Td>
                  <Text>
                    {taskCategory?.createdAt ? format(categoryDate, 'MMM dd, yyyy') : '--- --,----'}
                  </Text>
                </Td>
                <Td>
                  <Text>{taskCategory.description}</Text>
                </Td>
                <Td>
                  <Text>{taskCategory?.unitType}</Text>
                </Td>
                <Td>
                  <Flex gap={4} justifyContent="flex-end">
                    <ViewTaskCategory taskCategory={taskCategory} />
                    <EditTaskCategory taskCategory={taskCategory} />
                    <IconButton
                      onClick={() => {
                        onDelete(taskCategory.id);
                      }}
                      size="small"
                      variant="link"
                      color="gray.600"
                      _hover={{ opacity: '0.5', color: 'red' }}
                      aria-label="delete customer"
                      icon={<SBTrashIcon width="15px" />}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
