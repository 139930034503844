import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { type Retailer } from './retailer.types';
import { GET_RETAILER } from './retailers.graphql';

interface QueryResponse {
  retailer: Retailer;
}

export function useRetailerQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_RETAILER);

  const getRetailer = useCallback(
    async (retailerId: string) => {
      return query({ variables: { retailerId } });
    },
    [query]
  );

  return {
    getRetailer,
    retailer: data?.retailer,
    loading,
    error,
  };
}
