import { FC, useEffect, useMemo } from 'react';
import { Autocomplete } from '@swftbox/style-guide';
import { useUsersListQuery, SelectOptionType, SimpleUser } from 'src/components/Particles';

interface Props {
  onFocus?: () => void;
  onChange?: (userId: string | null) => void;
  value: string | null;
  noLabel?: boolean;
}

export const UserSelectInput: FC<Props> = ({ onFocus, onChange, value, noLabel }) => {
  const { getUsers, users, loading } = useUsersListQuery();

  useEffect(() => {
    getUsers(['Dispatcher']);
  }, []);

  const handleChange = (selectedUser: SelectOptionType | readonly SelectOptionType[] | null) => {
    if (!onChange) {
      return;
    }
    if (!selectedUser) {
      onChange(null);
      return;
    }
    onChange((selectedUser as SelectOptionType).value ?? null);
  };

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const user = users?.find((user: SimpleUser) => user.id === value);
    return user ? { label: user.name, value } : null;
  }, [value, users]);

  return (
    <Autocomplete
      isLoading={loading}
      isClearable
      isMulti={false}
      label={noLabel ? undefined : 'Assignee'}
      onFocus={onFocus}
      onChange={handleChange}
      options={users?.map((user) => ({ label: user.name, value: user.id })) || []}
      placeholder="Select assignee"
      value={selectedOption}
    />
  );
};
