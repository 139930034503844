import { IconWText, SBMarkerIcon, SBInfoIcon, Text, VStack, Box } from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import SharpShopIcon from './../../../assets/SharpShopIcon.svg';
import SharpUserIcon from './../../../assets/SharpUserIcon.svg';
import './StationItem.scss';

interface StationItemProps {
  isUser: boolean;
  name: string;
  address: string;
  phone: string;
}

const StationItem = ({ isUser, name, address, phone }: StationItemProps) => {
  return (
    <VStack alignItems="start" className="stationitem">
      <VStack textAlign="center" w="100%">
        <SVG src={isUser ? SharpUserIcon : SharpShopIcon} width="65px" height="65px" />
        <Text
          fontSize="text-lg"
          fontWeight="bold"
          letterSpacing="0.7px"
          mb="3"
          className="stationitem_title"
        >
          {name}
        </Text>
      </VStack>
      <IconWText Icon={<SBInfoIcon width="14px" height="14px" />} text={phone} fontSize="text-sm" />
      <IconWText
        Icon={<SBMarkerIcon width="14px" height="14px" />}
        alignItems="flex-start"
        align="flex-start"
        textTransform="lowercase"
        text={address}
        fontSize="text-sm"
      />
    </VStack>
  );
};

export default StationItem;
