import { useLazyQuery } from '@apollo/client';
import { GET_COMMENTS } from './comments.graphql';
import { type Comment } from './comments.types';
import { QueriesStore } from '../queriesStore';
import { useCallback } from 'react';

export function useCommentsQuery(orderId: string) {
  const [query, { data, loading, error, ...rest }] = useLazyQuery<{
    comments: Comment[];
  }>(GET_COMMENTS, {
    variables: { orderId },
  });

  const getComments = useCallback(
    (variables: { orderId: string }) => {
      QueriesStore.update('GET_COMMENTS', { ...variables });
      void query({
        variables,
      });
    },
    [query]
  );

  return {
    getComments,
    comments: data?.comments ?? [],
    loading,
    error,
    ...rest,
  };
}
