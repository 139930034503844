import { createContext, useContext } from 'react';

interface SearchContextProps {
  searchText?: string;
  search: (text?: string) => void;
}

export const SearchContext = createContext<SearchContextProps>({
  search: console.info,
});

export function useSearch() {
  return useContext(SearchContext);
}
