import { type DropzoneOptions } from 'react-dropzone';
import { type Retailer, ProcedureType } from 'src/components/Particles';

export interface ProcedureSettingsProps {
  retailer: Retailer;
}

export const ProcedureTypes = [
  ProcedureType.FULFILLMENT,
  ProcedureType.DISPATCH,
  ProcedureType.DRIVER,
];

export interface DropzoneProps {
  index: number;
  onDropFiles: (index: number, acceptedFiles: File[]) => void;
  options?: DropzoneOptions;
}

export const dropzoneStyle: React.CSSProperties = {
  border: '2px dashed #007BFF',
  padding: '20px',
  width: '400px',
  textAlign: 'center',
  marginBottom: '10px',
};

export interface MediaFiles {
  imageFiles: File[];
  videoFiles: File[];
}

export type DropzoneFiles = Record<number, MediaFiles>;
