import { Badge, Box, Button, Flex, HStack, SBAddIcon, Spacer, Text } from '@swftbox/style-guide';
import { AddNewJob } from './AddNewJob';
import { OrganizingFilters } from './OrganizingFilters';

interface JobsHeaderProps {
  jobsCount: number;
}

export function JobsHeader(props: JobsHeaderProps) {
  const { jobsCount } = props;
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Jobs
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {jobsCount} jobs
          </Badge>
        </Text>
      </Box>
      <Spacer />
      <HStack spacing="20px">
        <OrganizingFilters />
        <AddNewJob />
      </HStack>
    </Flex>
  );
}
