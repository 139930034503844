import { Dispatch, FC, SetStateAction } from 'react';
import {
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBMenuDotsVerticalIcon,
  SBUserRemoveIcon,
  SBUserDeleteIcon,
  If,
  SBEditIcon,
  SBFileIcon,
  SBCheckIcon,
  SBCrossCircleIcon,
  HStack,
  Button,
  Tooltip,
  Box,
  toast,
} from '@swftbox/style-guide';
import { actionsColors } from 'src/components/Pages/Drivers/Components/helper';
import {
  Settlement,
  useRemoveSettlementMutation,
  useUpdateSettlementStatusMutation,
} from 'src/components/Particles';

interface Props {
  onMenuOpen: () => void;
  settlement: Settlement;
  editSettlement: Dispatch<SetStateAction<Settlement | undefined>>;
}

export const SettlementActionButtons: FC<Props> = (props) => {
  const { onMenuOpen, settlement, editSettlement } = props;

  const { removeSettlement, loading: removingSettlement } = useRemoveSettlementMutation();
  const { updateSettlementStatus, loading: updating } = useUpdateSettlementStatusMutation();

  const deleteSettlement = async () => {
    await removeSettlement(settlement.id);
  };

  const handleSettlementStatus = async (approved: boolean) => {
    await updateSettlementStatus({
      id: settlement.id,
      ...(approved && { approved: true }),
      ...(!approved && { rejected: true }),
    });
    toast.success(`Payment ${approved ? 'approved' : 'rejected'} successfully`);
  };

  const showReceipt = () => {
    window.open(settlement.receipt!, '_blank', 'noreferrer');
  };

  return (
    <HStack spacing="4" justifyContent="end">
      <If
        children={
          <Button
            variant="link"
            onClick={showReceipt}
            disabled={updating}
            color="gray.700"
            _hover={{ opacity: 0.4 }}
            sx={{ minW: '10px' }}
          >
            <Tooltip label="Receipt">
              <Box>
                <SBFileIcon width="15px" />
              </Box>
            </Tooltip>
          </Button>
        }
        condition={!!settlement.receipt}
      />
      <If
        children={
          <Button
            variant="link"
            onClick={() => {
              handleSettlementStatus(true);
            }}
            disabled={updating}
            color="gray.700"
            _hover={{ opacity: 0.4 }}
            px="0"
            sx={{ minW: '10px' }}
          >
            <Tooltip label="Approve">
              <Box>
                <SBCheckIcon width="15px" />
              </Box>
            </Tooltip>
          </Button>
        }
        condition={settlement.status !== 'APPROVED'}
      />
      <If
        children={
          <Button
            variant="link"
            onClick={() => {
              handleSettlementStatus(false);
            }}
            disabled={updating}
            color="gray.700"
            _hover={{ opacity: 0.4 }}
            sx={{ minW: '10px' }}
          >
            <Tooltip label="Reject">
              <Box>
                <SBCrossCircleIcon width="15px" />
              </Box>
            </Tooltip>
          </Button>
        }
        condition={settlement.status !== 'REJECTED'}
      />
      <Menu>
        <MenuButton as="button" onClick={onMenuOpen}>
          <SBMenuDotsVerticalIcon width="15px" />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={editSettlement.bind(null, settlement)}>
            <IconWText text="Edit" spacing="20px" Icon={<SBEditIcon width="15px" />} />
          </MenuItem>

          <MenuItem onClick={deleteSettlement} disabled={removingSettlement}>
            <IconWText
              text="Delete"
              spacing="20px"
              color="red.normal"
              Icon={<SBUserDeleteIcon width="15px" />}
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default SettlementActionButtons;
