import {
  Box,
  Button,
  IconButton,
  SBModal,
  SBUserDeleteIcon,
  Stack,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import PropTypes from 'prop-types';
import { useDeleteUser } from 'src/components/Particles/resolvers';

interface DeleteUserProps {
  name: string;
  id: string;
}

export function DeleteUser({ name, id }: DeleteUserProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteUser, loading } = useDeleteUser();

  function onDeleteConfirm() {
    void deleteUser({
      payload: { id },
      onCompleted: ({ removeUser }) => {
        if (removeUser.message) {
          toast.success(removeUser.message);
          onClose();
        }
      },
    });
  }

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'red' }}
        aria-label="delete user"
        icon={<SBUserDeleteIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete User</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            Are you sure you want to delete{' '}
            <Text as="span" fontWeight="semibold">
              {name}
            </Text>{' '}
            ?
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onDeleteConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}

DeleteUser.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
