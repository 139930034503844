import {
  getExtensionValue,
  type GetUploadUrlVariables,
  splitFileName,
} from 'src/components/Particles';
import { type DropzoneFiles } from './types';

export const addFile = (
  dropzoneFiles: DropzoneFiles,
  index: number,
  acceptedFiles: File[],
  fileType: 'imageFiles' | 'videoFiles'
): DropzoneFiles => {
  const existingFiles = dropzoneFiles?.[index]?.[fileType] || [];
  return {
    ...dropzoneFiles,
    [index]: {
      ...dropzoneFiles[index],
      [fileType]: [...existingFiles, ...acceptedFiles],
    },
  };
};

export const removeFile = (
  dropzoneFiles: DropzoneFiles,
  index: number,
  fileToRemove: File,
  fileType: 'imageFiles' | 'videoFiles'
): DropzoneFiles => {
  const newFiles = dropzoneFiles[index][fileType].filter((file) => file !== fileToRemove);
  return {
    ...dropzoneFiles,
    [index]: {
      ...dropzoneFiles[index],
      [fileType]: newFiles,
    },
  };
};

export const resetSection = (dropzoneFiles: DropzoneFiles, index: number): DropzoneFiles => {
  return {
    ...dropzoneFiles,
    [index]: {
      imageFiles: [],
      videoFiles: [],
    },
  };
};

export function prepareFilesData(dropzoneFiles: DropzoneFiles, index: number) {
  const imagesExtensions = dropzoneFiles[index]?.imageFiles?.map(
    (imageFile) => splitFileName(imageFile.name).extension
  );

  const imagesUploadArgs = imagesExtensions?.map((extension) => {
    const imageUploadArgs: GetUploadUrlVariables = {
      fileType: getExtensionValue(extension ?? ''),
      fileDirectory: 'PROCEDURE_IMAGES',
    };
    return imageUploadArgs;
  });

  const videosExtensions = dropzoneFiles[index]?.videoFiles?.map(
    (videoFile) => splitFileName(videoFile.name).extension
  );

  const videosUploadArgs = videosExtensions?.map((extension) => {
    const videoUploadArgs: GetUploadUrlVariables = {
      fileType: getExtensionValue(extension ?? ''),
      fileDirectory: 'PROCEDURE_VIDEOS',
    };
    return videoUploadArgs;
  });

  return {
    imagesUploadArgs,
    videosUploadArgs,
  };
}
