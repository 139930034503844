import { yupResolver } from '@hookform/resolvers/yup';
import {
  AutocompleteMap,
  type AutocompleteMapLocation,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  SBInput,
  SlideFade,
} from '@swftbox/style-guide';
import { useMemo, type FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { type StepFourPayloadProps } from '../Helper';
import { StepFourSchema } from '../../Validation';
import { FormHeader } from './FormHeader';

interface StepFourFormProps {
  onDone: (location: StepFourPayloadProps) => void;
  submittedPayload?: StepFourPayloadProps;
}

export const StepFourForm: FC<StepFourFormProps> = ({ submittedPayload, onDone }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<StepFourPayloadProps>({
    defaultValues: submittedPayload,
    resolver: yupResolver(StepFourSchema),
  });

  const onLocationChange = useCallback(
    (updatedLocation: AutocompleteMapLocation) => {
      setValue('location.city', updatedLocation.city || getValues('location.city'));
      setValue('location.country', updatedLocation.country);
      setValue('location.coordinates.latitude', updatedLocation.coordinates.lat);
      setValue('location.coordinates.longitude', updatedLocation.coordinates.lng);
      setValue(
        'location.addressLine1',
        updatedLocation.address || getValues('location.addressLine1')
      );
      clearErrors('location');
    },
    [setValue, getValues, clearErrors]
  );

  const mapCenter = useMemo(
    () => ({
      lat: 25.456744,
      lng: 55.676685,
    }),
    []
  );

  return (
    <>
      <FormHeader
        title="Company Location"
        description="( Provide company location for faster pickups )"
      />
      <SlideFade
        in
        exit={{ opacity: 0, translateX: '-1000px' }}
        transition={{ enter: { duration: 0.3 }, exit: { duration: 0, delay: 0 } }}
        unmountOnExit
      >
        <form onSubmit={handleSubmit(onDone)}>
          <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={4}>
            <GridItem colSpan={12}>
              <FormControl mt={2}>
                <FormLabel> Find your company address *</FormLabel>
                <AutocompleteMap
                  onLocationChange={onLocationChange}
                  h="40vh"
                  borderRadius="10px"
                  center={mapCenter}
                >
                  <>
                    <SBInput
                      crossOrigin={undefined}
                      rootProps={{ mt: 1 }}
                      label="Address Line 1*"
                      {...register('location.addressLine1')}
                      error={errors?.location?.addressLine1?.message}
                    />
                    <Flex gap={2} mb={2} mt={2}>
                      <SBInput
                        crossOrigin={undefined}
                        label="City*"
                        placeholder="ex: Dubai"
                        {...register('location.city')}
                        error={errors?.location?.city?.message}
                      />
                      <SBInput
                        crossOrigin={undefined}
                        label="Country*"
                        placeholder="ex: Unitad Arab Emirates"
                        {...register('location.country')}
                        error={errors?.location?.country?.message}
                      />
                    </Flex>
                  </>
                </AutocompleteMap>
              </FormControl>
            </GridItem>
          </Grid>
          <Button w="100%" mt="16px" type="submit">
            Continue
          </Button>
        </form>
      </SlideFade>
    </>
  );
};
