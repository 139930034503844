import { useQuery } from '@apollo/client';
import { format, startOfHour, addMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { timelineOrdersToMap } from 'src/components/Pages/Orders/Components/Filters/TimelineFilter/helper';
import { GET_TIMELINE_FILTER } from '../orders.graphql';

interface OrdersOverview {
  pastCount?: number;
  futureCount?: number;
  hourlyHistogram: Array<{
    value: string;
    count: number;
    expressCount: number;
  }>;
  dangerCount: number;
  warningCount: number;
}
interface QueryResponse {
  ordersOverview: OrdersOverview;
}

interface QueryProps {
  variables: {
    from: Date;
    to: Date;
    status?: string[];
    stopStatus?: string[];
  };
}

function formatHourlyHistogram({ hourlyHistogram, dangerCount, warningCount }: OrdersOverview) {
  let dangerDate = format(startOfHour(addMinutes(new Date(), 30)), 'H:mm');
  let warningDate = format(startOfHour(addMinutes(new Date(), 120)), 'H:mm');
  let currentDate = format(startOfHour(new Date()), 'HH:mm');
  const formattedHistogram = new Map();
  let beforeEightAsTime = 0;

  hourlyHistogram.forEach((hour) => {
    const hourToDate = new Date(hour.value);
    const startDate = format(startOfHour(hourToDate), 'H:mm');
    if (format(startOfHour(hourToDate), 'HH:mm') < '08:00') {
      beforeEightAsTime += hour.count;
    } else {
      formattedHistogram.set(startDate, {
        express: hour.expressCount,
        normal: startDate >= currentDate ? hour.count : 0,
        danger: startDate >= currentDate ? 0 : hour.count,
        warning:
          //  startDate === warningDate ? warningCount :
          0,
        value: startDate,
      });
    }
  });

  formattedHistogram.set('2:00', {
    normal: format(new Date(), 'HH:mm') < '08:00' ? beforeEightAsTime : 0,
    danger: format(new Date(), 'HH:mm') > '08:00' ? beforeEightAsTime : 0,
    express: 0,
    value: '2:00',
  });

  return formattedHistogram;
}

export function useTimelineFilterQuery({ variables }: QueryProps) {
  const { data, loading, error } = useQuery<QueryResponse>(GET_TIMELINE_FILTER, {
    variables,
    pollInterval: 30000,
  });
  const [sliderDataInfo, setSliderDataInfo] = useState(timelineOrdersToMap);
  useEffect(() => {
    if (data?.ordersOverview) {
      const { pastCount, futureCount, hourlyHistogram, dangerCount, warningCount } =
        data?.ordersOverview;

      const updatedTimelineOrdersToMap = new Map(timelineOrdersToMap);
      updatedTimelineOrdersToMap.set('OLD', { danger: pastCount || 0, value: 'OLD' });
      updatedTimelineOrdersToMap.set('FUTURE', { normal: futureCount || 0, value: 'FUTURE' });

      if (hourlyHistogram?.length) {
        const formattedHistogram = formatHourlyHistogram({
          hourlyHistogram,
          dangerCount,
          warningCount,
        });
        formattedHistogram.forEach((value, key) => updatedTimelineOrdersToMap.set(key, value));
      }
      setSliderDataInfo(updatedTimelineOrdersToMap);
    }
  }, [data]);
  return {
    sliderDataInfo,
    loading,
    error,
  };
}
