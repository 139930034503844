import { useLazyQuery } from '@apollo/client';
import { type Fulfillment } from './fulfillments.type';
import { GET_FULFILLMENTS } from './fulfillments.graphql';

interface QueryResponse {
  fulfillments: Fulfillment[];
}

export function useFulfillmentsQuery() {
  const [getFulfillments, { data, loading, error }] = useLazyQuery<QueryResponse>(
    GET_FULFILLMENTS,
    { pollInterval: 30000 }
  );

  return {
    getFulfillments,
    fulfillments: data?.fulfillments ?? [],
    loading,
    error,
  };
}
