import * as yup from 'yup';
import 'yup-phone-lite';

export const CreateUserSchema = yup.object().shape({
	email: yup.string().email('Please Enter a valid email').nullable().trim(),
	phone: yup
		.string()
		.phone(undefined, 'Please Enter a valid phone number').trim()
		.required('Phone number is required'),
	name: yup
		.string()
		.min(2)
		.max(50)
		.trim()
		.required(),
});
