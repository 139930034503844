import { useMutation } from '@apollo/client';
import { UPDATE_STATUS_DRIVER, GET_DRIVERS } from './drivers.graphql';
import { UpdateDriverStatusInput } from './drivers.types';
import { QueriesStore } from '../queriesStore';

interface UpdateDriverStatusResponse {
  id: string;
  userId: string;
}

export function useUpdateDriverStatusMutation() {
  const [mutate, { data, loading, error }] = useMutation<{ updateDriverStatus: UpdateDriverStatusResponse }, { updateDriverStatusInput: UpdateDriverStatusInput }>(UPDATE_STATUS_DRIVER, {
    variables: {
      updateDriverStatusInput: {},
    },
    refetchQueries: [{ query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }]
  })

  async function updateDriverStatus(payload: UpdateDriverStatusInput) {
    await mutate({
      variables: {
        updateDriverStatusInput: payload,
      },
      refetchQueries: [{ query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }]
    });
  }

  return {
    updateDriverStatus,
    isUserUpdated: Boolean(data?.updateDriverStatus) && !error,
    updating: loading,
    error,
  };
}
