import {
  Box,
  Button,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import React from 'react';
import { TaskStatus, useUpdateTaskStatusMutation } from 'src/components/Particles/resolvers';

interface UpdateTaskStatusProps {
  taskId: string;
  status: TaskStatus;
  children: React.ReactElement<any>;
}

const getStatusTitle = (status: TaskStatus) => {
  return {
    STARTED: 'Start',
    BLOCKED: 'Block',
    COMPLETED: 'Complete',
    CANCELLED: '',
    PENDING: ''
  }[status]
};

export function UpdateTaskStatus({ taskId, status, children }: UpdateTaskStatusProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateTaskStatus, loading } = useUpdateTaskStatusMutation();

  function onUpdateConfirm() {
    void updateTaskStatus({
      payload: { id: taskId, status },
      onCompleted: ({ updateTaskStatus }) => {
        if (updateTaskStatus.message) {
          toast.success(updateTaskStatus.message);
          onClose();
        }
      },
    });
  }

  const clickableComponent = React.cloneElement(children, {
    onClick: onOpen,
  });

  return (
    <>
      {clickableComponent}
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="general"
        header={
          <Box pb="1vh">
            <Text>{getStatusTitle(status)} Task</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to{' '}
            <Text as="span" fontWeight="semibold">
              {getStatusTitle(status)}
            </Text>
            {' '}this task!
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onUpdateConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
