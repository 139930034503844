import * as yup from 'yup';

export const StepThreeSchema = yup.object().shape({
  legalAddress: yup.string()
    .required("Legal Address is Required")
    .test('legalAddress', function (val) {
      if (val && val.length < 10) {
        return this.createError({
          message: 'The current Legal Address should be longer than 10 chars',
          path: 'legalAddress',
        });
      }
      return true;
    }),
  trnNumber: yup
    .string()
    .optional()
    .test('trnNumberType', function (val) {
      if (val !== undefined && val !== null && val !== '' && !/^\d+$/.test(val)) {
        return this.createError({
          message: 'TRN number must be a numeric value',
          path: 'trnNumber',
        });
      }
      return true;
    })
    .test('trnNumberLength', function (val) {
      if (val && val.length < 2) {
        return this.createError({
          message: 'TRN number is shorter than the allowed limit of 2 digits',
          path: 'trnNumber',
        });
      }
      return true;
    }),
  trnCertificate: yup.string().optional(),
  tradeLicense: yup.string(),
});
