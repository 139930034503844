import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import {
  UPSERT_INVOICE_SETTING,
  type UpsertInvoiceSettingInput,
  LIST_INVOICE_SETTINGS,
  type InvoiceSettingMutationResponse,
} from '../Queries';

interface UpsertInvoiceSettingProps {
  onCompleted: (
    data: { upsertInvoiceSetting: InvoiceSettingMutationResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useUpsertInvoiceSetting({ onCompleted }: UpsertInvoiceSettingProps) {
  const [mutate, { error, loading }] = useMutation<
    { upsertInvoiceSetting: InvoiceSettingMutationResponse },
    { upsertInvoiceSettingInput: UpsertInvoiceSettingInput }
  >(UPSERT_INVOICE_SETTING, {
    onCompleted,
    refetchQueries: [
      {
        query: LIST_INVOICE_SETTINGS,
      },
    ],
  });

  return {
    mutate,
    loading,
    error,
  };
}
