import { useMutation } from '@apollo/client';
import { SEND_OTP } from './auth.graphql';
import { type SendOtpInput, type SendOTPResponse } from './auth.types';

interface SendOTPPayload {
  phone?: string;
  email?: string;
  action?: 'LOGIN' | 'RESET_PASSWORD';
}

export function useSendOTPMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    { sendOtp: SendOTPResponse },
    { sendOtpInput: SendOtpInput }
  >(SEND_OTP);

  async function sendOtp({ phone, email, action }: SendOTPPayload) {
    await mutate({
      variables: {
        sendOtpInput: {
          phone,
          email,
          action: action ?? 'LOGIN',
        },
      },
    });
  }

  return {
    sendOtp,
    loading,
    isOTPSent: Boolean(data?.sendOtp.result) && !error,
    error,
  };
}
