import { gql } from '@apollo/client';

export const UPDATE_CONFIGURATIONS_QUERY = gql`
  mutation UpdateConfiguration($input: UpdateConfigurationInput!) {
    updateConfiguration(input: $input) {
      message
    }
  }
`;

export const GET_CONFIGURATIONS_QUERY = gql`
  query configurations {
    configurations {
      value
      id
      key
    }
  }
`;

export const ADD_CONFIGURATIONS_QUERY = gql`
  mutation CreateConfiguration($input: CreateConfigurationInput!) {
    createConfiguration(input: $input) {
      message
    }
  }
`;

export const GET_CONFIGURATION_BY_KEY_QUERY = gql`
  query ConfigurationByKey($key: String!) {
    ConfigurationByKey(key: $key) {
      id
      key
      value
    }
  }
`;
