import { useLazyQuery } from '@apollo/client';
import { ME } from './auth.graphql';
import { type AuthUser } from './auth.types';

export function useMeQuery() {
	const [getCurrentUser, { data, loading, error }] = useLazyQuery<{ me: AuthUser }>(ME);
	
	return {
		getCurrentUser,
		user: data?.me,
		loading,
		error,
	};
}
