import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { StepperHistory } from 'src/components/Organisms';
import { useOrderTimelinesQuery } from 'src/components/Particles';
import { formatStatus } from './helper';

interface StepsProps {
  id: string;
  status: string;
  date: string;
  name?: string;
  type?: string;
  comment?: string;
  notes?: string;
  isDone?: boolean;
  isActive?: boolean;
  orderUpdates?: string[];
}

interface OrderHistoryProps {
  orderId?: string;
}
const handleSort = (timelines?: any) => {
  if (!timelines?.length) return [];
  const timeLineNotSorted = [...timelines];
  const sortedTimelines = timeLineNotSorted.sort(function (a, b) {
    return (new Date(b.date) as any) - (new Date(a.date) as any);
  });
  return sortedTimelines;
};

export const OrderHistory = ({ orderId }: OrderHistoryProps) => {
  const { getOrderTimeline, orderTimeline, loading } = useOrderTimelinesQuery();
  const [timeline, setTimeline] = useState<StepsProps[] | undefined>();

  useEffect(() => {
    if (orderId) {
      void getOrderTimeline({ variables: { orderId } });
    }
  }, [orderId]);

  useEffect(() => {
    if (!!orderTimeline.length && !loading) {
      setTimeline(
        handleSort(orderTimeline)
          ?.flatMap((timeline, index) =>
            timeline.isMilestone ||
            timeline.action === 'update' ||
            timeline.action === 'paymentUpdated'
              ? {
                  id: uuid(),
                  status: timeline.status ? formatStatus(timeline.status) : '',
                  date: timeline.date ?? '',
                  name:
                    timeline.customer?.name ??
                    timeline.retailer?.name ??
                    timeline.driver?.name ??
                    timeline.dispatcher?.name ??
                    timeline.actionByUser?.name ??
                    '',
                  type: timeline.custodianType ?? timeline.actionByUser?.roles[0] ?? '',
                  notes: timeline.failedAttemptReason || '',
                  isActive: index === 0,
                  isDone: index !== 0,
                  isFailed: timeline.action === 'failed',
                  action: timeline.partnerDescription || timeline.description,
                  orderUpdates: timeline.orderUpdates,
                }
              : []
          )
          .filter((item, index, arr) => {
            return index === 0 || item.action !== arr[index - 1].action;
          })
      );
    }
  }, [orderTimeline, loading]);

  return <StepperHistory steps={timeline ?? []} loading={loading} />;
};
