import { type FC, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  SBModal,
  Select,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import {
  type CreatePaymentInput,
  useCreatePaymentMutation,
  type Payment,
  useUpdatePaymentMutation,
  PaymentType,
  type UpdatePaymentInput,
} from 'src/components/Particles';
import { Controller, useForm } from 'react-hook-form';
import { DriverSelectInput } from 'src/components/Organisms/DriverSelectInput';
import FileInput from 'src/components/Atom/FileInput/FileInput';

interface Props {
  payment?: Payment;
  onModalClose: () => void;
}

export const PaymentForm: FC<Props> = ({ payment, onModalClose }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [fileInputLabel, setFileInputLabel] = useState<string>(payment?.receipt || 'receipt');
  const { handleSubmit, control, reset, watch } = useForm<CreatePaymentInput | UpdatePaymentInput>({
    mode: 'all',
    defaultValues: !payment
      ? {
          type: PaymentType.CASH,
        }
      : {
          id: payment.id,
          swftboxTracking: payment.orderSwftboxTracking,
          driverId: payment.driverId,
          type: payment.type,
          amount: payment.amount,
          receipt: payment.receipt,
        },
  });

  const { createPayment, loading: creating } = useCreatePaymentMutation();
  const { updatePayment, loading: updating } = useUpdatePaymentMutation();

  const onSubmit = handleSubmit(async (data) => {
    if ('id' in data) {
      const { swftboxTracking, ...payload } = data;
      await updatePayment(payload);
      toast.success('Payment updated successfully');
    } else {
      await createPayment(data);
      toast.success('Payment created successfully');
    }

    reset();
    onClose();
    onModalClose?.();
  });

  const receiptValue = watch('receipt');
  const paymentType = watch('type');

  useEffect(() => {
    if (receiptValue instanceof File) {
      setFileInputLabel(receiptValue.name);
    } else if (typeof receiptValue === 'string' && !receiptValue.includes('https')) {
      setFileInputLabel(receiptValue);
    } else {
      setFileInputLabel('receipt');
    }
  }, [receiptValue]);

  return (
    <SBModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        onModalClose();
      }}
      closeOnEsc
      header={<Text>{payment ? 'Update payment' : 'Create new payment'}</Text>}
      size="xl"
      body={
        <form onSubmit={onSubmit}>
          <Box marginTop={2}>
            <Stack gap={1}>
              <HStack>
                <Controller
                  name="swftboxTracking"
                  shouldUnregister={!!payment}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Order Tracking</FormLabel>
                      <Input
                        type="text"
                        value={value || ''}
                        disabled={!!payment}
                        onChange={onChange}
                        placeholder="Enter order tracking"
                        required
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="driverId"
                  shouldUnregister={!!payment}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <DriverSelectInput
                        disabled={!!payment}
                        onChange={onChange}
                        value={value || null}
                      />
                    </FormControl>
                  )}
                />
              </HStack>
              <HStack>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Payment type</FormLabel>
                      <Select onChange={onChange} value={value} required>
                        <option value={PaymentType.CASH}>Cash</option>
                        <option value={PaymentType.CARD}>Card</option>
                      </Select>
                    </FormControl>
                  )}
                />
                {payment && (
                  <Controller
                    name="amount"
                    shouldUnregister={!!payment}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <FormLabel>Amount</FormLabel>
                        <Input
                          type="number"
                          disabled={!!payment}
                          value={value || ''}
                          onChange={onChange}
                          placeholder="Enter settlement amount"
                          required
                        />
                      </FormControl>
                    )}
                  />
                )}
              </HStack>
              <Controller
                name="receipt"
                shouldUnregister={paymentType === PaymentType.CASH}
                control={control}
                render={({ field: { onChange } }) =>
                  paymentType === PaymentType.CARD ? (
                    <FormControl>
                      <FileInput
                        fileInputLabel={fileInputLabel}
                        setFile={onChange}
                        file={receiptValue || undefined}
                      />
                    </FormControl>
                  ) : (
                    <div />
                  )
                }
              />
              <Box>
                <Button type="submit" disabled={creating || updating}>
                  {payment ? 'Update payment' : 'Create payment'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </form>
      }
      bordered={false}
      footer={<div />}
    />
  );
};
