import { gql } from '@apollo/client';
import { CORE_ORDER_DATA } from '../../orders/orders.graphql';

export const ATTACHMENT_LIST = gql`
  ${CORE_ORDER_DATA}
  query AttachmentList(
    $retailerIds: [UUID!]
    $photoLabelTags: [AttachmentTags!]
    $status: [AttachmentStatus!]
    $page: Int
    $perPage: Int
    $endDate: DateTime
    $startDate: DateTime
    $driverIds: [UUID!]
    $commentType: [CommentType!]
    $orderBy: String
    $order: OrderSort
  ) {
    paginatedAttachments(
      retailerIds: $retailerIds
      photoLabelTags: $photoLabelTags
      status: $status
      page: $page
      perPage: $perPage
      endDate: $endDate
      startDate: $startDate
      driverIds: $driverIds
      commentType: $commentType
      orderBy: $orderBy
      order: $order
    ) {
      perPage
      prevPage
      total
      pageCount
      currentPage
      data {
        attachment
        id
        photoLabelTags
        status
        commentId
        createdAt
        comment
        order {
          id
          ...CoreOrderData
          dropProfile {
            key
            profileType
            name
          }
          scheduledPickupSlot {
            from
            date
          }
        }
      }
    }
  }
`;

export const UPDATE_ATTACHMENT_STATUS = gql`
  mutation UpdateAttachmentStatus($updateAttachmentInput: UpdateAttachmentInput!) {
    updateAttachmentStatus(updateAttachmentInput: $updateAttachmentInput) {
      data {
        id
        status
      }
      message
    }
  }
`;
