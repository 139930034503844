import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  SBInput,
  Text,
  toast,
  VStack,
} from '@swftbox/style-guide';
import { endOfToday, format, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import { MultiValue, SingleValue } from 'react-select';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';
import {
  BulkCreateInvoiceInput,
  InvoiceGenerationType,
  Retailer,
  SelectOptionType,
  useBulkGenerateInvoiceMutation,
  useGenerateInvoiceMutation,
  useRetailersQuery,
  type CreateInvoiceInput,
} from 'src/components/Particles';
import { useRetailerBilling } from '../Hooks';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export function GenerateInvoiceForm({ onClose, isOpen }: Props) {
  const [retailersIds, setRetailersIds] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isSingleInvoice, setIsSingleInvoice] = useState(true);
  const { retailers, getRetailers, loading: gettingRetailers } = useRetailersQuery();
  const { addressDetailsMissing, checkRetailerBilling } = useRetailerBilling(
    retailers,
    retailersIds
  );

  useEffect(() => {
    checkRetailerBilling();
  }, [checkRetailerBilling]);

  useEffect(() => {
    if (isOpen) void getRetailers({ page: 1, keyword: search });
  }, [isOpen, search]);

  const options = useMemo(() => {
    return retailers?.map((retailer) => ({ label: retailer.name, value: retailer.id })) || [];
  }, [retailers]);

  const [selectedOptions, setSelectedOptions] = useState<
    MultiValue<SelectOptionType> | SingleValue<SelectOptionType>
  >([]);

  const { mutate: generateInvoice, loading: generatingInvoice } = useGenerateInvoiceMutation({
    onCompleted: (data) => {
      if (data.createInvoice.data[0].id) {
        toast.success('Invoice created successfully');
      }
      reset();
      onClose();
    },
  });

  const { mutate: bulkGenerate, loading: bulkGenerating } = useBulkGenerateInvoiceMutation({
    onCompleted: (data) => {
      toast.success(data.bulkCreateInvoice.message);
      reset();
      onClose();
    },
  });

  const today = new Date();
  const defaultValues = {
    from: format(startOfMonth(today), 'yyyy-MM-dd'),
    to: format(endOfToday(), 'yyyy-MM-dd'),
    activeAt: format(today, 'yyyy-MM-dd'),
  };
  const { register, handleSubmit, reset, control, watch } = useForm<
    BulkCreateInvoiceInput | CreateInvoiceInput
  >({
    mode: 'all',
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    if (addressDetailsMissing.status) return;
    if (!isSingleInvoice)
      return bulkGenerate({
        variables: {
          bulkCreateInvoiceInput: {
            retailerIds: retailersIds,
            activeAt: new Date(data.activeAt).toISOString(),
            from: new Date(data.from).toISOString(),
            to: new Date(data.to).toISOString(),
            dueDateInDays: Number(data.dueDateInDays),
          } as BulkCreateInvoiceInput,
        },
      });
    return generateInvoice({
      variables: {
        createInvoiceInput: {
          ...data,
          retailerId: retailersIds.at(0),
          activeAt: new Date(data.activeAt).toISOString(),
          from: new Date(data.from).toISOString(),
          to: new Date(data.to).toISOString(),
          dueDateInDays: Number(data.dueDateInDays),
        } as CreateInvoiceInput,
      },
    });
  });

  const handleChange = (option: MultiValue<SelectOptionType> | SingleValue<SelectOptionType>) => {
    if (!option) return;
    if (Array.isArray(option)) {
      setRetailersIds(option.map((opt) => opt.value));
      setSelectedOptions(option as MultiValue<SelectOptionType>);
    } else {
      setRetailersIds([(option as SelectOptionType).value]);
      setSelectedOptions(option);
    }
  };

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
  };

  return (
    <form onSubmit={onSubmit}>
      <br />
      <VStack justifyContent="start">
        {addressDetailsMissing.status && (
          <HStack
            border={`1px solid #fbc70091`}
            boxShadow="xs"
            borderRadius="8px"
            p="8px"
            w="100%"
            bg="#fbc70091"
            marginBlock={3}
          >
            <SVG src={InfoIcon} color="#fbc70091" stroke="#000" />
            <Text fontWeight="semibold" fontSize="text-sm">
              It seems that the following partner(s):{' '}
              {addressDetailsMissing.retailersNames.join(', ')} is/are missing billing information.
              Please add the necessary details in the partner settings.
            </Text>
          </HStack>
        )}
        <FormControl>
          <RadioGroup
            value={isSingleInvoice ? 'single' : 'multiple'}
            onChange={(value) => setIsSingleInvoice(value === 'single')}
          >
            <HStack w="100%" alignItems="stretch">
              <Radio value="single" w={'50%'}>
                <Text fontSize={14}>Single Invoice</Text>
              </Radio>
              <Radio value="multiple" w={'50%'}>
                <Text fontSize={14}>Multiple Invoices</Text>
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <Autocomplete
          isLoading={gettingRetailers}
          isClearable
          isMulti={!isSingleInvoice}
          label="Retailers"
          className="auto-complete-multi"
          size="small"
          onChange={handleChange}
          options={options}
          placeholder="Select retailer"
          value={selectedOptions}
          onInputChange={handleSearch}
        />
        <HStack w="100%" justifyContent="start">
          <Controller
            name={`activeAt`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>Issue Date</FormLabel>
                <SBInput
                  type="date"
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="Issue Date"
                  crossOrigin={undefined}
                  defaultValue={defaultValues.activeAt}
                />
              </FormControl>
            )}
          />
          <SBInput
            label="Due Date in Days"
            placeholder="Due Date in Days"
            {...register('dueDateInDays')}
            crossOrigin={undefined}
          />
        </HStack>
        {isSingleInvoice && (
          <SBInput
            label="PO number"
            placeholder="PO number"
            {...register('poNumber')}
            crossOrigin={undefined}
          />
        )}

        <HStack w="100%" justifyContent="start">
          <Controller
            name={`from`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>From</FormLabel>
                <SBInput
                  type="date"
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="From"
                  crossOrigin={undefined}
                  defaultValue={defaultValues.from}
                />
              </FormControl>
            )}
          />
          <Controller
            name={`to`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>To</FormLabel>
                <SBInput
                  type="date"
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="To"
                  crossOrigin={undefined}
                  defaultValue={defaultValues.to}
                />
              </FormControl>
            )}
          />
        </HStack>
        {isSingleInvoice && (
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <RadioGroup value={value} onChange={onChange}>
                  <VStack w="100%" alignItems="flex-start">
                    <Radio
                      key="general-invoice"
                      value={InvoiceGenerationType.GENERAL}
                      marginRight={10}
                    >
                      <Text fontSize={14}>General Invoice</Text>
                      <Text fontSize={10} color="#98A2B2">
                        Generate a single invoice that includes all service payment lines
                      </Text>
                    </Radio>
                    <Radio
                      key="services-collection-invoices"
                      value={InvoiceGenerationType.SERVICES_COLLECTION}
                      marginRight={10}
                    >
                      <Text fontSize={14}>Service & Collection Invoices</Text>
                      <Text fontSize={10} color="#98A2B2">
                        Generate two invoices, one for service lines, and another for payment
                        collected
                      </Text>
                    </Radio>
                  </VStack>
                </RadioGroup>
              </FormControl>
            )}
          />
        )}

        <HStack justifyContent="end" sx={{ marginTop: '20px !important' }}>
          <Button
            type="submit"
            disabled={generatingInvoice || bulkGenerating || addressDetailsMissing.status}
          >
            Generate Invoice
          </Button>
        </HStack>
      </VStack>
    </form>
  );
}
