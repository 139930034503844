import { useMutation } from '@apollo/client';
import { toast } from '@swftbox/style-guide';
import { GET_SUBSCRIPTION_QUERY, RESUME_PLAN_CUSTOMER_QUERY } from '../Subscription.graphql';

interface QueryResponse {
  resumeSubscription: {
    message: string;
  };
}
interface QueryInput {
  updateSubscriptionInput: { id: string };
}

interface Props {
  retailerId: string;
  subscriptionId: string;
}
export function useResumePlanRetailerQuery({ retailerId, subscriptionId }: Props) {
  const [mutate, rest] = useMutation<QueryResponse, QueryInput>(RESUME_PLAN_CUSTOMER_QUERY);
  const handleResumePlan = () => {
    return mutate({
      variables: {
        updateSubscriptionInput: { id: subscriptionId },
      },
      onCompleted: ({ resumeSubscription }) => toast.success(resumeSubscription.message),
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_QUERY,
          variables: {
            retailerSubscribePlanInput: {
              retailerId,
            },
          },
        },
      ],
    });
  };
  return {
    handleResumePlan,
    ...rest,
  };
}
