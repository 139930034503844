import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  SBAddIcon,
  SBModal,
  SBMapMarkerIcon,
  useDisclosure,
  toast,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import {
  type CreateCarriyoIntegrationCarrierInput,
  useCreateCarriyoIntegrationCarrier,
} from 'src/components/Particles';
import { CarrierSchema } from './carrier.schema';
import { CarrierForm } from './carrier.form';

export function AddCarrier() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createCarriyoIntegrationCarrier } = useCreateCarriyoIntegrationCarrier();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateCarriyoIntegrationCarrierInput>({
    mode: 'all',
    resolver: yupResolver(CarrierSchema),
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = (data: CreateCarriyoIntegrationCarrierInput) => {
    void createCarriyoIntegrationCarrier({
      createCarriyoIntegrationCarrierInput: data,
      onCompleted: (message) => {
        handleCloseModal(message);
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Carrier
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBMapMarkerIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
          </Box>
        }
        size="xl"
        body={<CarrierForm register={register} errors={errors} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
