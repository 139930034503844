import { SBInput, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { type Question } from 'src/components/Particles';

interface QuestionFromProps {
  question: Question;
  register: UseFormRegister<any>;
  errors: FieldErrors<Question>;
}

export default function EditQuestionFrom(props: QuestionFromProps) {
  const { register, errors, question } = props;

  return (
    <Stack gap={4}>
      <SBInput
        crossOrigin={undefined}
        label="Question*"
        placeholder="Edit Question"
        defaultValue={question.question}
        {...register('question')}
        error={errors?.question?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Sequence*"
        type="number"
        placeholder="Edit Question's sequence"
        defaultValue={question.sequence.toString()}
        {...register('sequence')}
        error={errors?.sequence?.message}
      />
    </Stack>
  );
}
