import { useQuery } from '@apollo/client';
import { GET_CONFIGURATION_BY_KEY_QUERY, type ConfigurationType } from '../Queries';

interface ConfigurationsResponse {
  ConfigurationByKey: ConfigurationType;
}

export function useConfiguration(key: string) {
  const { data, loading } = useQuery<ConfigurationsResponse>(GET_CONFIGURATION_BY_KEY_QUERY, {
    variables: { key },
  });

  return {
    configuration: data?.ConfigurationByKey,
    loading,
  };
}
