import { HStack, Button, Text, Box, SystemStyleObject } from '@swftbox/style-guide';

interface NavigationTabsProps {
  tabs: { id: string; name: string }[];
  active: string;
  handleChange: (id: string) => void;
  title?: string;
  fontSize?: string;
  parentStyle?: SystemStyleObject;
  buttonStyle?: SystemStyleObject;
  variant?: 'solid' | 'outline';
}

const solidVariantStyle = (isActive: boolean): SystemStyleObject => ({
  height: '35px',
  borderRadius: '8',
  bg: isActive ? '#fff' : 'gray.50',
  color: isActive ? 'gray.600' : 'gray.500',
  border: isActive ? '1px solid #D0D5DD' : '1px solid transparent',

  _hover: {
    background: '#fff',
    cursor: 'pointer',
    border: '1px solid #D0D5DD',
    color: 'gray.600',
  },
});

const outlineVariantStyle = (isActive: boolean): SystemStyleObject => ({
  height: '40px',
  borderRadius: '0',
  borderBottom: isActive ? '1px solid primary.700' : '1px solid transparent',
  color: isActive ? 'primary.700' : 'gray.500',
  _hover: {
    borderBottomColor: 'primary.700',
    cursor: 'pointer',
    color: 'primary.700',
  },
});

export const NavigationTabs = ({
  tabs,
  active,
  handleChange,
  title,
  fontSize = 'text-sm',
  parentStyle,
  buttonStyle,
  variant = 'outline',
}: NavigationTabsProps) => {
  return (
    <Box my="2" mb="2" sx={parentStyle}>
      {!!title && (
        <Text fontSize="display-xs" color="gray.900" fontWeight="medium" mb="7">
          {title}
        </Text>
      )}
      <HStack
        overflowX="auto"
        spacing="4"
        position="relative"
        bg={variant === 'solid' ? 'gray.50' : '#fff'}
        borderRadius={variant === 'solid' ? '8' : '0'}
        border={variant === 'solid' ? '1px solid #E4E7EC' : 'none'}
        _before={{
          content: "' '",
          display: variant === 'solid' ? 'none' : 'block',
          position: 'absolute',
          width: '99%',
          height: '1px',
          bottom: 1,
          background: 'gray.200',
        }}
        p={variant === 'solid' ? '0 ' : '1'}
        mt={variant === 'solid' ? '12px ' : '0'}
      >
        {tabs.map((tab) => {
          const isActive = active === tab.id;
          const variantStyle =
            variant === 'solid' ? solidVariantStyle(isActive) : outlineVariantStyle(isActive);

          return (
            <Button
              flexShrink="0"
              key={tab.id}
              variant="group"
              px="4"
              fontSize={fontSize}
              letterSpacing="0.3px"
              fontWeight="semibold"
              transition="all 0.3s"
              onClick={() => handleChange(tab.id)}
              boxShadow="none"
              py="2"
              sx={{ ...variantStyle, ...buttonStyle }}
            >
              {tab.name}
            </Button>
          );
        })}
      </HStack>
    </Box>
  );
};
