import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import {
  ADD_TASK_CATEGORY,
  GET_TASK_CATEGORIES,
  type AddTaskCategoryInput,
  type AddTaskCategoryResponse,
} from '../Queries';

interface CallAddTaskCategoryProps {
  payload: AddTaskCategoryInput;
  onCompleted: (
    data: { createTaskCategory: AddTaskCategoryResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useAddTaskCategory() {
  const [mutate, { loading, error }] = useMutation<
    { createTaskCategory: AddTaskCategoryResponse },
    { createTaskCategoryInput: AddTaskCategoryInput }
  >(ADD_TASK_CATEGORY);

  async function callAddTaskCategory({ payload, onCompleted }: CallAddTaskCategoryProps) {
    await mutate({
      variables: {
        createTaskCategoryInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_TASK_CATEGORIES,
        },
      ],
    });
  }

  return {
    callAddTaskCategory,
    loading,
    error,
  };
}
