import { Avatar, HStack, Text, VStack, color } from '@swftbox/style-guide';
import { type Address, type SimpleRetailer } from 'src/components/Particles';

interface PartnerItemProps {
  retailerLocation: SimpleRetailer & { location: Address } & { totalPendingPickups: number };
}

export const PartnerItem = (props: PartnerItemProps) => {
  const { retailerLocation } = props;

  return (
    <HStack spacing={4}>
      <VStack alignItems="start">
        <Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 58, 110, 1)',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          {retailerLocation.totalPendingPickups}
        </Text>
      </VStack>
      <VStack alignItems="start">
        <Text fontSize="text-md" fontWeight="semibold" color="gray.600" overflowX="clip">
          {retailerLocation.name}
        </Text>

        <Text fontSize="text-sm" fontWeight="semibold" color="gray.400" overflowX="clip">
          {retailerLocation.location.name}
        </Text>
        <Text fontSize="text-sm" fontWeight="semibold" color="gray.400" overflowX="clip">
          {retailerLocation.location.addressLine1}
        </Text>
      </VStack>
    </HStack>
  );
};
