import { gql } from '@apollo/client';

export const ADD_NEW_ROLE = gql`
  mutation Mutation($createRoleInput: CreateRoleInput!) {
    createRole(createRoleInput: $createRoleInput) {
      name
      permissions {
        id
        name
      }
      id
    }
  }
`;

export const GET_ROLES = gql`
  query GetRoles($userId: UUID, $withPermissions: Boolean) {
    roles(userId: $userId, withPermissions: $withPermissions) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query GetPermissions($roleId: Int) {
    permissions(roleId: $roleId) {
      id
      name
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($updateRoleInput: UpdateRoleInput!) {
    updateRole(updateRoleInput: $updateRoleInput) {
      name
      permissions {
        name
        id
      }
      id
    }
  }
`;

export const REMOVE_ROLE = gql`
  mutation RemoveRole($removeRoleId: Int!) {
    removeRole(id: $removeRoleId) {
      success
      message
    }
  }
`;
