export interface StepOnePayloadProps {
  name: string;
  email: string;
}

export interface StepTwoPayloadProps {
  firstName: string;
  lastName: string;
  ownerPhone: string;
}

export interface StepThreePayloadProps {
  legalAddress: string;
  trnNumber?: number;
  trnCertificate?: string;
  tradeLicense: string;
}

export interface StepFourPayloadProps {
  location: {
    city: string;
    country: string;
    addressLine1: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
}

export interface StepFivePayloadProps {
  isDraftEnabled: boolean;
  isMultiLocationAllowed: boolean;
  timezone: string;
  categoryIds?: string[];
  currencyId?: string;
  fulfillmentId?: string;
  logo?: string;
}

export type CreateRetailerFormState = StepOnePayloadProps &
  StepTwoPayloadProps &
  StepThreePayloadProps &
  StepFourPayloadProps &
  StepFivePayloadProps;

export type CreateRetailerFormStateStep =
  | StepOnePayloadProps
  | StepTwoPayloadProps
  | StepThreePayloadProps
  | StepFourPayloadProps
  | StepFivePayloadProps;

export const initialPayload: CreateRetailerFormState = {
  email: '',
  name: '',
  firstName: '',
  lastName: '',
  ownerPhone: '',
  legalAddress: '',
  tradeLicense: '',
  trnCertificate: '',
  trnNumber: undefined,
  location: {
    city: '',
    country: '',
    addressLine1: '',
    coordinates: {
      latitude: 25.456744,
      longitude: 55.676685,
    },
  },
  isDraftEnabled: false,
  isMultiLocationAllowed: false,
  timezone: '',
};
export const timezones = [
  { label: 'UTC', value: 'UTC' },
  { label: 'Asia/Dubai', value: 'Asia/Dubai' },
  { label: 'Asia/Kuwait', value: 'Asia/Kuwait' },
  { label: 'Africa/Cairo', value: 'Africa/Cairo' },
];
