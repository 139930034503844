import { Button } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import ScrollTopIcon from 'src/assets/icons/Arrows/chevron-up.svg';
export const ScrollToTopButton = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, []);
  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Button
      w="40px"
      h="40px"
      p="0"
      borderRadius="50%"
      position="fixed"
      bottom={show ? '30px' : '-500px'}
      right="2px"
      onClick={handleScrollUp}
      transition="bottom 0.5s"
    >
      <SVG src={ScrollTopIcon} stroke="#fff" />
    </Button>
  );
};
