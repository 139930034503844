import { memo } from 'react';
import { InternationalErrorsModal, InternationalShipment } from 'src/components/Organisms';
import { EditCustomerLocation } from 'src/components/Pages/Orders/Components/Modal';

const CellModals = () => {
  return (
    <>
      <EditCustomerLocation />
      <InternationalShipment />
      <InternationalErrorsModal />
    </>
  );
};

export default memo(CellModals);
