import {
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PencilIcon,
  SBMenuDotsVerticalIcon,
  SBUserDeleteIcon,
} from '@swftbox/style-guide';

interface Props {
  onEdit: () => void;
  onRemove: () => void;
}

export const ActionButtons = (props: Props) => {
  const { onEdit, onRemove } = props;

  const onDelete = async () => {
    const confirmed = confirm('Are you sure you want to delete this shift time?');
    if (!confirmed) {
      return;
    }
    onRemove();
  };

  return (
    <Menu>
      <MenuButton as="button">
        <SBMenuDotsVerticalIcon width="15px" />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onEdit}>
          <IconWText
            text="Edit"
            spacing="20px"
            Icon={<PencilIcon width="15px" viewBox="0 0 16 16" />}
          />
        </MenuItem>
        <MenuItem onClick={onDelete}>
          <IconWText
            text="Delete"
            spacing="20px"
            color="red.normal"
            Icon={<SBUserDeleteIcon width="15px" />}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
