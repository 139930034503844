import { Autocomplete, Checkbox, HStack, Stack } from '@swftbox/style-guide';
import { Control, Controller, type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { FeatureCategory, UpdateFeatureInput } from 'src/components/Particles';
import CommonFeatureForm from './CommonFeatureForm';

interface FormValue {
  name: string;
  description: string;
  billing: number;
  category: FeatureCategory;
}

interface FormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<FormValue>;
  control: Control<UpdateFeatureInput, any>;
}

export default function EditFeatureForm(props: FormProps) {
  const { register, errors, control } = props;

  const categoriesOptions = Object.values(FeatureCategory).map((value) => ({
    label: value
      .replace('_', ' ')
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase()),
    value: value.toLowerCase().replace(/_([a-z])/g, (match, p1) => p1.toUpperCase()),
  }));

  return (
    <Stack gap={4} as={'form'}>
      <CommonFeatureForm register={register} errors={errors} />
      <Controller
        name="category"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(val) => {
              // @ts-expect-error, it's just a falsy warning
              onChange(val?.value);
            }}
            value={categoriesOptions.find((option) => option.value === value) || ''}
            options={categoriesOptions}
            label="Category"
            placeholder="Select Category"
            error={errors.category?.message}
          />
        )}
      />
      <Controller
        name="isConfigurable"
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <HStack spacing={5}>
            <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
              Is Configurable
            </Checkbox>
          </HStack>
        )}
      />
    </Stack>
  );
}
