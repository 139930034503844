import {
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  SBTrashIcon,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@swftbox/style-guide';
import { memo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { InvoiceLineItemType, UpdateInvoiceLineItemInput } from 'src/components/Particles';
import styles from '../../style.module.scss';

interface LineItemProps {
  item: UpdateInvoiceLineItemInput;
  index: number;
  remove: UseFieldArrayRemove;
  onQuantityOrRateUpdated: () => void;
}

export const LineItem = memo(({ item, index, remove, onQuantityOrRateUpdated }: LineItemProps) => {
  const { control, watch } = useFormContext();
  const itemsListName = item.type === InvoiceLineItemType.SERVICE ? 'lineItems' : 'adjustments';

  const [quantity, rate, vat] = watch([
    `${itemsListName}.${index}.quantity`,
    `${itemsListName}.${index}.rate`,
    `${itemsListName}.${index}.vat`,
  ]);

  return (
    <Tr>
      <Td>
        <Controller
          name={`${itemsListName}.${index}.code`}
          control={control}
          defaultValue={item.code}
          render={({ field }) => (
            <Editable defaultValue={field.value} onSubmit={field.onChange}>
              <EditablePreview className={styles.editableFieldPreview} />
              <EditableInput className={styles.editableFieldInput} />
            </Editable>
          )}
        />
      </Td>
      <Td>
        <Controller
          name={`${itemsListName}.${index}.description`}
          control={control}
          defaultValue={item.description}
          render={({ field }) => (
            <Editable defaultValue={field.value} onSubmit={field.onChange}>
              <EditablePreview className={styles.editableFieldPreview} />
              <EditableInput className={styles.editableFieldInput} />
            </Editable>
          )}
        />
      </Td>
      <Td>
        <Controller
          name={`${itemsListName}.${index}.quantity`}
          control={control}
          defaultValue={item.quantity}
          render={({ field }) => (
            <Editable
              defaultValue={String(field.value)}
              onSubmit={(value) => {
                field.onChange(parseInt(value));
                onQuantityOrRateUpdated();
              }}
            >
              <EditablePreview className={styles.editableFieldPreview} />
              <EditableInput className={styles.editableFieldInput} />
            </Editable>
          )}
        />
      </Td>
      <Td>
        <Controller
          name={`${itemsListName}.${index}.rate`}
          control={control}
          defaultValue={item.rate}
          render={({ field }) => (
            <Editable
              defaultValue={String(field.value)}
              onSubmit={(value) => {
                field.onChange(parseFloat(value));
                onQuantityOrRateUpdated();
              }}
            >
              <EditablePreview className={styles.editableFieldPreview} />
              <EditableInput className={styles.editableFieldInput} />
            </Editable>
          )}
        />
      </Td>
      <Td>
        <Text>{quantity * rate}</Text>
      </Td>
      <Td>
        <Controller
          name={`${itemsListName}.${index}.vat`}
          control={control}
          defaultValue={item.vat}
          render={({ field }) => (
            <Editable
              defaultValue={String(field.value)}
              onSubmit={(value) => {
                field.onChange(parseFloat(value));
                onQuantityOrRateUpdated();
              }}
            >
              <EditablePreview className={styles.editableFieldPreview} />
              <EditableInput className={styles.editableFieldInput} />
            </Editable>
          )}
        />
      </Td>
      <Td>
        <Text>{((quantity * rate * vat) / 100).toFixed(2)}</Text>
      </Td>
      <Td>{(quantity * rate * (1 + vat / 100)).toFixed(2)}</Td>
      <Td>
        <Tooltip label="Remove Line Item" hasArrow>
          <IconButton
            size="small"
            variant="link"
            _hover={{ opacity: '0.5' }}
            aria-label="remove"
            color="gray.400"
            onClick={() => remove(index)}
            icon={<SBTrashIcon width="15px" />}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
});
