import { useEffect } from 'react';
import { useOrderQuery } from 'src/components/Particles';
import { Box } from '@swftbox/style-guide';
import ReactJson from 'react-json-view';

interface OriginalPayloadProps {
  orderId?: string;
}

export const OriginalPayload = ({ orderId }: OriginalPayloadProps) => {
  const { getOrder, order } = useOrderQuery();

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId]);

  if (!order?.originalPayload) return null;

  return (
    <Box>
      <ReactJson
        src={order.originalPayload}
        theme="summerfruit:inverted"
        collapsed={2}
        displayDataTypes={false}
        enableClipboard={true}
        displayObjectSize={false}
        indentWidth={4}
      />
    </Box>
  );
};
