import { Box, Grid, HStack, Spinner, Text } from '@swftbox/style-guide';
import clsx from 'clsx';
import { format } from 'date-fns';
import './StepperHistory.scss';
import { convertToFormattedLocalTime } from '../../../components/Pages/Orders/Utils/datetime.helper';

interface StepsProps {
  steps: Array<{
    id: string;
    status: string;
    date: string;
    name?: string;
    type?: string;
    comment?: string;
    notes?: string;
    isDone?: boolean;
    isActive?: boolean;
    isFailed?: boolean;
    action?: string;
    orderUpdates?: string[];
  }>;
  loading: boolean;
}

export const StepperHistory = ({ steps, loading }: StepsProps) => {
  return (
    <Box bg="gray.100" p="5" borderRadius="8px">
      {loading ? (
        <HStack w="100%" justifyContent="center" minH="200px">
          <Spinner />
        </HStack>
      ) : (
        <>
          {steps?.map((step, i) => (
            <Grid
              key={`${step.id}`}
              gridTemplateColumns="30% repeat(auto-fit, minmax(30px, 23.333%))"
              _notLast={{ minH: '60px' }}
              className={clsx({
                history_item: true,
                done: i !== 0,
                active: i === 0,
                failed: step.isFailed,
              })}
            >
              <Text>{step.action}</Text>
              <Text>{step.status}</Text>
              {step.date ? (
                <HStack
                  fontWeight="semibold"
                  spacing="2"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Text fontSize="text-sm">{format(new Date(step.date), 'hh:mm a')}</Text>
                  <Text fontSize="x-small" pt="2px">
                    {format(new Date(step.date), 'MMM dd, yyyy')}{' '}
                  </Text>
                </HStack>
              ) : (
                <div />
              )}
              <Box textAlign="end">
                <Text fontWeight="semibold" letterSpacing="0.5px">
                  {step.name}
                </Text>
                <Text letterSpacing="1px">{step.type}</Text>
              </Box>
              <>
                {step?.orderUpdates?.map((update) => {
                  const separator = update.indexOf(':');

                  const key = update.substring(0, separator);
                  const value = update.substring(separator + 1);

                  const updateValue =
                    separator > -1
                      ? `${key} : ${convertToFormattedLocalTime(value.trim()) ?? ''}`
                      : update;

                  return (
                    <Box
                      key={update}
                      gridColumn="1/3"
                      className="history_comment"
                      _notLast={{ marginBottom: '10px' }}
                    >
                      {updateValue}
                    </Box>
                  );
                })}

                {step?.notes && (
                  <Box gridColumn="1/3" className="history_comment">
                    {step.notes}
                  </Box>
                )}
              </>
            </Grid>
          ))}
        </>
      )}
    </Box>
  );
};
