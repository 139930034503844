import { useMutation } from '@apollo/client';
import { ADD_DRIVER, GET_DRIVERS } from './drivers.graphql';
import { CreateDriverInput } from './drivers.types';
import { QueriesStore } from '../queriesStore';

interface AddDriverResponse {
  id: string;
  userId: string;
}

export function useAddDriverMutation() {
  const [mutate, { data, loading, error }] = useMutation<{ createDriver: AddDriverResponse },{ createDriverInput: CreateDriverInput }>(ADD_DRIVER, {
    variables: {
      createDriverInput: {},
    },
    refetchQueries: [{query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }]
  })

  async function createDriver(payload: CreateDriverInput) {
    await mutate({
      variables: {
        createDriverInput: payload,
      },
      refetchQueries: [{query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS')}]
    });
  }

  return {
    createDriver,
    isUserAdded: Boolean(data?.createDriver.id) && !error,
    loading,
    error,
  };
}
