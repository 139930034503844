import { gql } from '@apollo/client';

export const GET_CITES_QUERY = gql`
  query Cities {
    cities {
      id
      city
      standardCityName
    }
  }
`;
