import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import { AddUpdateTask } from './Modal';

interface CustomersHeaderProps {
	tasksCount: number;
	isAdmin?: boolean;
}

export function TasksHeader({ tasksCount, isAdmin }: CustomersHeaderProps) {
	return (
		<Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
			<Box>
				<Text fontWeight="semibold" fontSize="text-lg">
					Tasks
					<Badge
						p="2px 8px"
						mx="1"
						fontWeight="medium"
						borderRadius="8px"
						fontSize="x-small"
						textTransform="capitalize"
					>
						{tasksCount} Task
					</Badge>
				</Text>
			</Box>
			<Spacer />
			{isAdmin && <AddUpdateTask />}
		</Flex>
	);
}
