import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  SBAddIcon,
  SBModal,
  SBMapMarkerIcon,
  Text,
  useDisclosure,
  toast,
  HStack,
  Checkbox,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { type location, useAddFulfillment } from 'src/components/Particles';
import { CategorySchema } from '../../Validations';
import FulfillmentForm from './FulfillmentForm';
import { useCallback } from 'react';

interface FormValue {
  name: string;
  location: location;
}

export default function AddCategory() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { callAddFulfillment } = useAddFulfillment();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<FormValue>({
    mode: 'all',
    resolver: yupResolver(CategorySchema),
  });

  const handleCloseModal = useCallback(
    (message?: string) => {
      if (message) {
        toast.success(message);
      }
      onClose();
      reset();
    },
    [onClose, reset]
  );

  const onConfirm = useCallback(
    (data: FormValue) => {
      void callAddFulfillment({
        payload: { ...data },
        onCompleted: (data) => {
          handleCloseModal(data.createFulfillment.message);
        },
      });
    },
    [callAddFulfillment, handleCloseModal]
  );

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add Fulfillment
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBMapMarkerIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <HStack justifyContent="space-between">
              <Text>Add Fulfillment</Text>
              <Controller
                name="location.isDefault"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, ref } }) => (
                  <HStack spacing={5}>
                    <Checkbox onChange={onChange} ref={ref} isChecked={value}>
                      Set As Default Fulfillment
                    </Checkbox>
                  </HStack>
                )}
              />
            </HStack>
          </Box>
        }
        size="5xl"
        body={
          <FulfillmentForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            isOpen={isOpen}
          />
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
