import { useQuery } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import { LIST_REPORTS, type ReportsFilterArgs, type ReportsQueryResponse } from '../Queries';

export function useReportsQuery(filter: ReportsFilterArgs) {
  QueriesStore.update('LIST_REPORTS', { ...filter });
  const { data, loading, error, ...rest } = useQuery<ReportsQueryResponse, ReportsFilterArgs>(
    LIST_REPORTS,
    {
      variables: filter,
      pollInterval: 30000,
    }
  ); 

  return {
    reports: data?.paginatedReports.data || null,
    total: data?.paginatedReports.total,
    loading,
    error,
    ...rest,
  };
}
