import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../../SharedTypes';
import { VISIT_STOP_MUTATION, VisitStopInput } from '../../Queries';

export function useVisitStopMutation() {
  const [mutate, { loading, error }] = useMutation<
    { visitStop: MessageResponse },
    { visitStopInput: VisitStopInput }
  >(VISIT_STOP_MUTATION);

  async function visitStop(visitStopInput: VisitStopInput) {
    return mutate({
      variables: {
        visitStopInput,
      },
      refetchQueries: [
        'PaginatedRoutes',
      ],
    });
  }

  return {
    visitStop,
    loading,
    error,
  };
}
