import { Box, SBEditIcon, SBModal, useDisclosure, toast, IconButton } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { Permission, Role, useUpdateRole } from 'src/components/Particles';
import RoleForm from './RoleForm';
import { DefaultRoles } from '../helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { roleUpdateSchema } from './Role.Schema';

interface FormValue {
  id: number;
  name: string;
  permissions: { name: string }[];
}
interface EditRoleProps {
  permissions: Permission[];
  role: Role;
}
export function EditRole({ permissions, role }: EditRoleProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useUpdateRole({
    onCompleted: (data) => {
      handleCloseModal('Role added successfully');
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<FormValue>({
    resolver: yupResolver(roleUpdateSchema),
    mode: 'all',
    defaultValues: {
      ...role,
      permissions: role.permissions?.map((permissions) => ({
        name: String(permissions.id),
      })),
    },
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: FormValue) => {
    mutate({
      variables: {
        updateRoleInput: {
          id: data.id,
          name: data.name,
          permissions: data.permissions.map((permission) => Number(permission?.name)),
        },
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit role"
        icon={<SBEditIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={
          <RoleForm
            register={register}
            errors={errors}
            control={control}
            allPermissions={permissions}
            disableNameEdit={DefaultRoles.includes(role.name.replace(' ', '').toLowerCase())}
          />
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
