import { gql } from '@apollo/client';

export const GET_OPERATORS = gql`
  query Operators {
    Operators {
      id
      name
    }
  }
`;
