import { Box, Grid, IconButton, SBEyeIcon, SBModal, useDisclosure, Text } from '@swftbox/style-guide';
import { type User } from 'src/components/Particles';

interface UserDetailsModalProps {
  user?: User;
}

export function UserDetails({ user }: UserDetailsModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'blue' }}
        aria-label="view user"
        icon={<SBEyeIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header="User Details"
        size="xl"
        body={
          <Box
            bg="gray.100"
            overflow="hidden"
            borderRadius="12px"
            border="1px solid #e9eaed"
            my="16px"
          >
            <Grid
              key={user?.id}
              gridTemplateColumns="repeat(auto-fit, minmax(60px, 50%))"
              gridGap="16px"
              p="16px"
            >
              <Text color="gray.600" fontWeight="bold">
                Email:
              </Text>
              <Text color="gray.500">{user?.email}</Text>
              <Text color="gray.600" fontWeight="bold">
                Name:
              </Text>
              <Text color="gray.500">{user?.name}</Text>
              <Text color="gray.600" fontWeight="bold">
                Phone:
              </Text>
              <Text color="gray.500">{user?.phone}</Text>
              <Text color="gray.600" fontWeight="bold">
                Status:
              </Text>
              <Text color="gray.500">{user?.status}</Text>
              <Text color="gray.600" fontWeight="bold">
                Roles:
              </Text>
              <Box>
                {user?.roles?.map((role, index) => (
                  <Text key={index} color="gray.500" mb="8px">
                    {role}
                  </Text>
                ))}
              </Box>
            </Grid>
          </Box>

        }
        bordered={false}
        handleConfirm={(e) => {
          console.log(e);
        }}
        footer={<div />}
      />
    </>
  );
};