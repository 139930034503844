import { SBInput, Stack } from '@swftbox/style-guide';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import type {
  CreateCarriyoIntegrationCarrierInput,
  UpdateCarriyoIntegrationCarrierInput,
} from 'src/components/Particles';

interface CarrierFormProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<CreateCarriyoIntegrationCarrierInput | UpdateCarriyoIntegrationCarrierInput>;
}

export function CarrierForm(props: CarrierFormProps) {
  const { register, errors } = props;

  return (
    <Stack gap={4}>
      <SBInput
        crossOrigin={undefined}
        label="Account Id*"
        placeholder="Carrier account id"
        {...register('accountId')}
        error={errors.accountId?.message}
      />
      <SBInput
        crossOrigin={undefined}
        label="Account Name*"
        placeholder="Carrier account name"
        {...register('accountName')}
        error={errors.accountName?.message}
      />
    </Stack>
  );
}
