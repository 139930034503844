import { Box, Flex, Spacer, WithLoader, Text } from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { AddQuestion } from './Components/AddQuestion';
import QuestionsTable from './Components/QuestionsTable';
import { useFeedbackQuestions } from 'src/components/Particles';
import style from './style.module.scss';

export const FeedbackQuestions = () => {
  const { questions, loading } = useFeedbackQuestions();

  return (
    <Box className={style.questions}>
      <Flex
        align="center"
        p="20px 24px"
        borderBottom="1px solid #EAECF0"
        flexDir={['column', 'row']}
      >
        <Box>
          <Text fontWeight="semibold" fontSize="text-lg">
            Questions
          </Text>
        </Box>
        <Spacer />
        <AddQuestion />
      </Flex>
      <WithLoader isLoading={loading}>
        <QuestionsTable questions={questions} />
      </WithLoader>
      {!loading && questions.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Questions Found" />
        </Flex>
      )}
    </Box>
  );
};
