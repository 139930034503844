export interface Driver {
  pageCount: number;
  data: {
    id: string;
    name: string;
    phone: string;
    operatorId?: string;
    isOnline: boolean;
    employmentType: string;
    status: string;
    user: {
      id: string;
      name: string;
      phone: string;
      status: string;
      email?: string;
    };
    birthday: string;
    gender: string;
    licenseNumber: string;
    nationalId: string;
    vehicle: {
      contractor: string;
      model: string;
      plateNumber: string;
      type: string;
    };
    shiftTimes: ShiftTime[];
  }[];
  total: number;
}

export interface ShiftTime {
  from: string;
  to: string;
  day: string;
}

export interface DriverWithoutPagination {
  id: string;
  user: {
    id: string;
    name: string;
  };
  totalActiveJobs: number;
  totalActiveOrders: number;
}

export enum DriverStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  OFFBOARDED = 'OFFBOARDED',
}

export interface CreateDriverInput {
  user: {
    email?: string;
    name: string;
    phone: string;
  };
  photo?: string;
  gender: string;
  employmentType: string;
  operatorId?: string;
  birthday: string;
  licenseNumber?: string;
  licensePhoto?: string;
  nationalId?: string;
  nationalIdPhoto?: string;
  vehicle: {
    contractor: string;
    model: string;
    plateNumber: string;
    type: string;
  };
}

export interface UpdateDriverInput {
  user?: {
    email?: string;
    name: string;
    phone: string;
  };
  isOnline: boolean;
  photo?: string;
  gender?: string;
  employmentType?: string;
  operatorId?: string;
  birthday?: string;
  licenseNumber?: string;
  licensePhoto?: string;
  nationalId?: string;
  nationalIdPhoto?: string;
  vehicle?: {
    contractor: string;
    model: string;
    plateNumber: string;
    type: string;
  };
  id: string;
}

export interface UpdateDriverStatusInput {
  id: string;
  status: string;
}

export interface UpdateShiftTimeInput {
  driverId: string;
  shiftTimes: ShiftTime[];
}
