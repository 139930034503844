import { Box, HStack, Text } from '@swftbox/style-guide';
import { useCallback, useEffect } from 'react';
import { VerticalTabs } from 'src/components/Organisms';
import { Feature, Retailer } from 'src/components/Particles';
import {
  AllSubtabsEnum,
  MainTabsEnum,
  OrderFeatureAndFeesSubtabsEnum,
  subtabMapping,
} from '../../Helper';
import { OrderFeaturesSettings } from './Components/OrderFeaturesSettings';

interface Props {
  retailer: Retailer;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
  allowToMove: { page: string; isAllowed: boolean };
  categorizedFeatures: Record<string, Feature[]>;
  activeTab: AllSubtabsEnum;
  setActiveTab: React.Dispatch<React.SetStateAction<AllSubtabsEnum>>;
}
export const OrderFeatureAndFees = ({
  retailer,
  categorizedFeatures,
  allowToMove,
  setAllowToMove,
  activeTab,
  setActiveTab,
}: Props) => {
  const handleChangeTab = useCallback(
    (tabId: string) => {
      if (!allowToMove?.isAllowed) {
        setAllowToMove({ isAllowed: false, page: tabId });
      } else {
        setActiveTab(tabId as OrderFeatureAndFeesSubtabsEnum);
      }
    },
    [allowToMove]
  );
  useEffect(() => {
    setActiveTab(OrderFeatureAndFeesSubtabsEnum.OrderFeatures);
  }, []);
  const orderFeaturesTabs = subtabMapping[MainTabsEnum.OrderFeatureAndFees];
  return (
    <Box h="calc(100% - 50px)">
      <HStack align="start" h="100%" flexWrap="wrap">
        <Box position="fixed" top="125" left="5" zIndex={2} bg="#fff" h="100%" w="165px">
          <VerticalTabs
            tabs={orderFeaturesTabs}
            handleChange={handleChangeTab}
            active={activeTab}
          />
        </Box>

        <Box flexGrow="1" h="100%" ps="210px" pt="1">
          {activeTab === OrderFeatureAndFeesSubtabsEnum.OrderFeatures && (
            <OrderFeaturesSettings
              retailer={retailer}
              orderEnhancementsFeatures={categorizedFeatures?.orderEnhancements}
              setAllowToMove={setAllowToMove}
            />
          )}
        </Box>
      </HStack>
    </Box>
  );
};
