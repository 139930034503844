
export const ucFirst = (str: string) => {
  return (str?.charAt(0).toUpperCase() || '') + (str.slice(1) || '');
}

export const diffDates = (date1: string | Date, date2: string | Date, unit = 'min', abs = false) => {
  date1 = typeof date1 === 'string' ? new Date(date1) : date1;
  date2 = typeof date2 === 'string' ? new Date(date2) : date2;

  const diffInMilliSeconds = date1.getTime() - date2.getTime();

  // if (unit === 'milli') return diffInMilliSeconds;
  
  let result = diffInMilliSeconds;

  if (unit === 'sec') result = diffInMilliSeconds / 1000;

  if (unit === 'min') result = diffInMilliSeconds / 60000;

  if (abs) return Math.abs(result);

  return result;
}
