import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  SBModal,
  SBEditIcon,
  Text,
  toast,
  useDisclosure,
  IconButton,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import {
  type UpdateUserInput,
  UpdateUserFormValues,
  useUpdateUser,
} from 'src/components/Particles';
import { UpdateUserSchema } from '../../Validation';
import { UserForm } from './UserForm';

interface EditUserProps {
  user: UpdateUserInput;
}

export function EditUser({ user }: EditUserProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    setValue,
    getValues,
    clearErrors,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UpdateUserFormValues>({
    mode: 'all',
    resolver: yupResolver(UpdateUserSchema),
    defaultValues: {
      ...user,
      roles: user.roles?.map((role) => ({ name: role })) || [],
      permissions: user.permissions?.map((permission) => ({ name: permission })) || [],
    },
  });

  const { updateUser } = useUpdateUser();

  function onConfirm(data: UpdateUserFormValues) {
    const payload: UpdateUserInput = {
      id: data.id,
      name: data.name,
      phone: data.phone,
      email: data.email,
      roles: data.roles.map(({ name }) => name),
      permissions: data.permissions.map(({ name }) => name),
      verifyEmail: data.verifyEmail,
    };

    void updateUser({
      payload,
      onCompleted: ({ updateUser }) => {
        if (updateUser.id) toast.success('User updated successfully');
        if (!updateUser.id) reset();
        onClose();
      },
    });
  }

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit user"
        icon={<SBEditIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          reset();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit User</Text>
          </Box>
        }
        size="5xl"
        body={
          <UserForm
            onDone={onConfirm}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            clearErrors={clearErrors}
            register={register}
            control={control}
            handleSubmit={handleSubmit}
          />
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
