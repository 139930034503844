import { useQuery } from '@apollo/client';
import { GET_DRIVERS_LIST } from './drivers.graphql';
import { type DriverStatus, type DriverWithoutPagination } from './drivers.types';
import { useEffect, useState } from 'react';
import { type AccountStatus } from '../users';

interface QueryResponse {
  driversList: DriverWithoutPagination[];
}

interface QueryVariables {
  isOnline?: boolean;
  userStatus?: AccountStatus[];
  status?: DriverStatus;
  shiftDay?: string;
  shiftTimeStart?: string;
}

export function useAllDriversQuery(variables: QueryVariables) {
  const [sortedDriver, setSortedDriver] = useState<DriverWithoutPagination[]>([]);
  const { data, loading, error } = useQuery<QueryResponse, QueryVariables>(GET_DRIVERS_LIST, {
    fetchPolicy: 'network-only',
    variables,
  });
  useEffect(() => {
    if (data?.driversList) {
      setSortedDriver(
        data?.driversList
          .concat([])
          .sort((driverA, driverB) => (driverA?.user?.name < driverB?.user?.name ? -1 : 1)) ?? []
      );
    }
  }, [data]);

  return {
    drivers: sortedDriver,
    loading,
    error,
  };
}
