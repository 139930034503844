import { handleAddRemoveElement } from '../../helper';

import {
  type JobsOrganizingFilters,
  type JobsOrganizedFilterAction,
} from './types';

const organizedFiltersReducer = (state: JobsOrganizingFilters, action: JobsOrganizedFilterAction) => {
  switch (action.type) {
    case 'JOBS_DRIVER_CHANGE':
      return {
        ...state,
        driverId: handleAddRemoveElement(
          state.driverId ?? [],
          action.payload.driver
        ),
      };
    case 'CLEAR_ORGANIZING_FILTERS':
      return {};
    default:
      return state;
  }
};

export const rootReducer = (state: any, action: any) => ({
  organizedFilters: organizedFiltersReducer(state.organizedFilters, action),
});
