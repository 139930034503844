import * as yup from 'yup';
import 'yup-phone-lite';

export const StepTwoSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(1, 'Too short!')
    .max(15, 'Too long')
    .required('Your first name is required'),
  lastName: yup
    .string()
    .min(1, 'Too short!')
    .max(15, 'Too long!')
    .required('Your second name is required'),
  ownerPhone: yup
    .string()
    .phone(undefined, 'Please Enter a valid phone number')
    .required('Phone number is required'),
  password: yup.string().test('password test', function (val) {
    if (val && val?.length < 6) {
      return this.createError({
        message: 'The current password is shorter than the allowed limit 6 characters',
        path: 'password',
      });
    }
    if (val && val?.length > 20) {
      return this.createError({
        message: 'The current password is more than the allowed limit 20 characters',
        path: 'password',
      });
    }

    return true;
  }),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'password should match confirm password'),
});
