import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { AuthUser } from 'src/components/Particles';
import { Config } from 'src/config';

const FirebaseConfig = Config.Firebase;
const primexBuild = Config.PRIMEXPRESS_BUILD;

const firebaseConfig = {
  apiKey: FirebaseConfig.API_KEY,
  authDomain: FirebaseConfig.AUTH_DOMAIN,
  databaseURL: FirebaseConfig.DATABASE_URL,
  projectId: FirebaseConfig.PROJECT_ID,
  storageBucket: FirebaseConfig.STORAGE_BUCKET,
  messagingSenderId: FirebaseConfig.MESSAGING_SENDER_ID,
  appId: FirebaseConfig.APP_ID,
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);

export async function authenticate(loggedInUser?: AuthUser) {
  if (!loggedInUser) return;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    const extEmail = primexBuild ? '.be@prime-xpress.net' : '.be@swftbox.com';
    const email = loggedInUser.phone + extEmail;
    const password = loggedInUser.id;
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log('Error Signing In to Firebase:', { error });
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (e) {
        console.log('Error Creating Firebase User:', e);
      }
    }
  }
}

export async function logout() {
  const auth = getAuth();
  signOut(auth).catch((error) => {
    console.log('Error logging out on firebase:', { error });
  });
}

export { database as FBDatabase, authenticate as authenticateFirebase, logout as firebaseLogout };
