export const marks = {
  0: 'OLD',
  1: '2:00',
  2: '8:00',
  3: '9:00',
  4: '10:00',
  5: '11:00',
  6: '12:00',
  7: '13:00',
  8: '14:00',
  9: '15:00',
  10: '16:00',
  11: '17:00',
  12: '18:00',
  13: '19:00',
  14: '20:00',
  15: '21:00',
  16: '22:00',
  17: '23:00',
  18: 'FUTURE',
};
export const timelineOrdersToMap = new Map();
Object.values(marks).forEach((time) => {
  if (!timelineOrdersToMap.has(time)) {
    timelineOrdersToMap.set(time, { value: time, warning: 0, normal: 0, danger: 0, express: 0 });
  }
});
export const calculatePosition = (value: number) => {
  // return (value / 17) * sliderWidth; // Assuming your slider max is 23
  return 2.5 + value * 5.28;
};
