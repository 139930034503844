import { createContext, useContext } from 'react';
import { type Order } from 'src/components/Particles/resolvers';

interface OrdersSelectionContextProps {
  select: (order: Order) => void;
  unselect: (id: string) => void;
  unselectAll: () => void;
  selectAll: (order: Order[]) => void;
  selectedOrders: Map<string, Order>;
  detailedOrder?: Order;
  toggleDetailedOrder: (order?: Order) => void;
}

export const OrdersSelectionContext = createContext<OrdersSelectionContextProps>({
  select: console.info,
  unselect: console.info,
  selectAll: console.info,
  unselectAll: () => {},
  selectedOrders: new Map(),
  toggleDetailedOrder: console.log,
});

export function useOrderSelection() {
  return useContext(OrdersSelectionContext);
}
