import { Button, IconWText, SBModal, useDisclosure } from '@swftbox/style-guide';
import Comments from './Comments';
import SVG from 'react-inlinesvg';
import CommentIcon from 'src/assets/icons/orders/comments.svg';
import { type Order } from 'src/components/Particles';
interface CommentsModalProps {
  order: Order;
}
export default function CommentModal(props: CommentsModalProps) {
  const { order } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button variant="link" disabled={!order?.comments?.length} onClick={onOpen} p="1">
        <IconWText
          color="primary.900"
          text={`x${order.comments.length?.toString()}`}
          Icon={<SVG src={CommentIcon} fill="currentColor" />}
        />
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header="Comments"
        size="3xl"
        body={<Comments comments={order.comments} orderId={order.id} />}
        bordered={false}
        footer={<></>}
      />
    </>
  );
}
