import { Radio, RadioGroup, Stack, VStack } from '@swftbox/style-guide';
import { JobOption, useJobTypeContext } from 'src/components/Particles';

export const AddJobOptions = () => {
  const { jobType, selectJobType } = useJobTypeContext();

  function onJobChange(selectedOption: JobOption) {
    selectJobType(selectedOption);
  }

  return (
    <VStack alignItems="start">
      <RadioGroup onChange={onJobChange} value={jobType}>
        <Stack direction="row" gap={5}>
          {/* <Radio value={JobOption.ROUTE}>Route</Radio> */}
          <Radio value={JobOption.PARTNER}>Partner Pickup</Radio>
          <Radio value={JobOption.RTO}>RTO</Radio>
        </Stack>
      </RadioGroup>
    </VStack>
  );
};
