import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBCalendarIcon,
  SBDateRangePicker,
} from '@swftbox/style-guide';
import { formatISO } from 'date-fns';
import { type DateFilter } from 'src/components/Particles';

type DateRangeSelection = Array<{ startDate?: Date; endDate?: Date }>;

interface TasksFilterProps {
  onDateRangeChange: (dates?: DateFilter) => void;
}

export function TasksDateFilter(props: TasksFilterProps) {
  const { onDateRangeChange } = props;

  function updateDateRangeSelection(selections: DateRangeSelection) {
    const [dates] = selections;
    if (!dates) {
      onDateRangeChange();
      return;
    }
    const { startDate, endDate } = dates;
    if (!startDate || !endDate) {
      onDateRangeChange();
    } else {
      onDateRangeChange({
        startDate: formatISO(startDate),
        endDate: formatISO(endDate)
      });
    }
  }

  return (
    <HStack spacing="10px">
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} variant="outline">
          <HStack>
            <Box> Date Range</Box>
            <SBCalendarIcon width="15px" />
          </HStack>
        </MenuButton>
        <MenuList>
          <SBDateRangePicker setValue={updateDateRangeSelection} />
        </MenuList>
      </Menu>
    </HStack>
  );
}
