import { useLazyQuery } from '@apollo/client';
import { GET_CITES_QUERY } from '../Queries';
import { type City } from './types';

interface QueryJobsResponse {
  cities: City[];
}

export function useCities() {
  const [query, { data, loading, error }] = useLazyQuery<QueryJobsResponse>(GET_CITES_QUERY);

  return {
    query,
    data: data?.cities ?? [],
    loading,
    error,
  };
}
