import { gql } from '@apollo/client';

export const GET_CATEGORIES_QUERY = gql`
  query Categories {
    categories {
      name
      deletedAt
      createdAt
      id
      updatedAt
    }
  }
`;

export const GET_CATEGORY_QUERY = gql`
  query GetRetailerLocations($locationId: UUID!) {
    location(id: $locationId) {
      id
      country
      city
      countryCode
      addressLine1
      addressLine2
      addressType
      area
      street
      building
      landMark
      coordinates {
        latitude
        longitude
      }
      entityId
      entityType
      isDefault
    }
  }
`;

export const ADD_CATEGORY_QUERY = gql`
  mutation AddCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      message
    }
  }
`;
export const UPDATE_CATEGORY_QUERY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      message
    }
  }
`;

export const DELETE_CATEGORY_QUERY = gql`
  mutation RemoveCategory($removeCategoryId: UUID!) {
    removeCategory(id: $removeCategoryId) {
      message
      success
    }
  }
`;
