import { gql } from '@apollo/client';

export const ADD_FEEDBACK_QUESTION = gql`
  mutation CreateFeedbackQuestion($input: CreateFeedbackQuestionInput!) {
    createFeedbackQuestion(input: $input) {
      data {
        id
      }
      message
    }
  }
`;

export const GET_FEEDBACK_QUESTIONS_QUERY = gql`
  query FeedbackQuestion {
    FeedbackQuestion {
      id
      createdAt
      updatedAt
      deletedAt
      question
      sequence
    }
  }
`;

export const UPDATE_FEEDBACK_QUESTION = gql`
  mutation UpdateFeedbackQuestion($input: UpdateFeedbackQuestionInput!) {
    updateFeedbackQuestion(input: $input) {
      data {
        id
      }
      message
    }
  }
`;
export const DELETE_FEEDBACK_QUESTION = gql`
  mutation RemoveFeedbackQuestion($removeFeedbackQuestionId: UUID!) {
    removeFeedbackQuestion(id: $removeFeedbackQuestionId) {
      message
    }
  }
`;
