import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  SBCalendarIcon,
  SBDateRangePicker,
  Text,
} from '@swftbox/style-guide';
import { addDays, format } from 'date-fns';
import { type Dispatch, type FC, type ReactNode, type SetStateAction, useState } from 'react';
import { RetailerSelectInput } from 'src/components/Organisms';
import { DriverSelectInput } from 'src/components/Organisms/DriverSelectInput';
import { type DateFilter, type PaymentFilterArgs, PaymentType } from 'src/components/Particles';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
const ButtonGroup: FC<{ children: ReactNode }> = ({ children }) => (
  <HStack
    border="1px solid #D0D5DD;"
    h="40px"
    lineHeight="40px"
    borderRadius="8px"
    shadow="xs"
    overflow="hidden"
    w="fit-content"
    spacing="0"
    marginRight={2}
  >
    {children}
  </HStack>
);

interface Props {
  onChange: (filter: PaymentFilterArgs) => void;
  dateFilter: DateFilter;
  setDateFilter: Dispatch<SetStateAction<DateFilter>>;
  paymentFilter: any;
}

const paymentStatuses = [
  {
    header: 'All',
    value: undefined,
  },
  {
    header: 'Approved',
    value: 'APPROVED',
  },
  {
    header: 'Pending',
    value: 'PENDING',
  },
];

const modifiedPaymentTypes = [...Object.values(PaymentType), 'P Link'];
const isPaymentTypeFilter = (paymentType: any) => {
  return Object.values(PaymentType).includes(paymentType);
};

const initialValue = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
    isSelected: false,
  },
];

export const PaymentsFilter: FC<Props> = (props: Props) => {
  const { onChange, dateFilter, setDateFilter, paymentFilter } = props;
  const [state, setState] = useState<any>(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const handleChangeDate = (isApply: boolean) => {
    if (isApply) {
      setDateFilter({ ...state[0], isSelected: true });
    } else {
      setDateFilter(initialValue[0]);
    }
    setIsOpen(false);
  };

  const resetStateAfterClose = () => {
    if (dateFilter) {
      setState([dateFilter]);
    } else {
      setIsOpen(false);
    }
  };
  return (
    <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2" p="20px 24px">
      <Flex align="center" p="20px 24px 0">
        <ButtonGroup>
          {paymentStatuses.map((stat) => (
            <Button
              key={stat.header}
              variant="grouped"
              p="8px 12px"
              fontSize="x-small"
              bg={stat.value === paymentFilter?.status ? '#63c3ec' : 'transparent'}
              color={stat.value === paymentFilter?.status ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => {
                onChange({ ...paymentFilter, status: stat.value });
              }}
            >
              {stat.header}
            </Button>
          ))}
        </ButtonGroup>
        <ButtonGroup>
          <Button
            variant="grouped"
            p="8px 12px"
            fontSize="x-small"
            bg={
              paymentFilter?.type === undefined && paymentFilter?.provider === false
                ? '#63c3ec'
                : 'transparent'
            }
            color={
              paymentFilter?.type === undefined && paymentFilter?.provider === false
                ? '#fff'
                : 'gray.900'
            }
            _focus={{ opacity: 0.85 }}
            _hover={{ opacity: 0.85 }}
            onClick={() => {
              onChange({ ...paymentFilter, type: undefined });
            }}
          >
            All
          </Button>
          {modifiedPaymentTypes.map((paymentType) => (
            <Button
              key={paymentType}
              p="8px 12px"
              variant="grouped"
              fontSize="x-small"
              bg={
                paymentType === paymentFilter?.type ||
                (paymentType === 'P Link' && paymentFilter?.provider)
                  ? '#63c3ec'
                  : 'transparent'
              }
              color={
                paymentType === paymentFilter?.type ||
                (paymentType === 'P Link' && paymentFilter?.provider)
                  ? '#fff'
                  : 'gray.900'
              }
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => {
                const filter = isPaymentTypeFilter(paymentType)
                  ? { type: paymentType, provider: false }
                  : { provider: true, type: undefined };

                onChange({
                  ...paymentFilter,
                  ...filter,
                });
              }}
            >
              {paymentType}
            </Button>
          ))}
        </ButtonGroup>
      </Flex>
      <HStack p="20px 24px">
        <Box minW="200px">
          <DriverSelectInput
            onChange={(value) => {
              onChange({
                ...paymentFilter,
                driverId: value || undefined,
              });
            }}
            value={paymentFilter?.driverId || null}
          />
        </Box>
        <Box minW="200px">
          <RetailerSelectInput
            onChange={(value) => {
              onChange({ ...paymentFilter, retailerId: value || undefined });
            }}
            value={paymentFilter?.retailerId || null}
          />
        </Box>
      </HStack>
      <Box position="relative" mt="18px !important">
        {dateFilter?.isSelected && (
          <Button
            variant="link"
            color="#63C3EC"
            bg="#fff"
            position="absolute"
            minW="15px"
            h="15px"
            fontWeight="semibold"
            fontSize="9px"
            borderRadius="50%"
            top="13px"
            left="10px"
            zIndex={10}
            _hover={{ opacity: '0.8' }}
            onClick={() => {
              handleChangeDate(false);
            }}
          >
            <SVG src={XIcon} width="8px" stroke="currentColor" />
          </Button>
        )}
        <Menu closeOnSelect={false} onClose={resetStateAfterClose} isOpen={isOpen}>
          <MenuButton
            as={Button}
            whiteSpace="nowrap"
            transition="all 0.3s"
            h="40px"
            borderRadius="8px"
            boxShadow="xs"
            fontSize="x-small"
            border="1px solid"
            fontWeight="bold"
            onClick={() => {
              setIsOpen(true);
            }}
            w={dateFilter?.isSelected ? 'min-content' : '185px'}
            bg={dateFilter?.isSelected ? '#C9E9F6 !important' : '#fff'}
            color={dateFilter?.isSelected ? '#fff' : 'gray.700'}
            borderColor={dateFilter?.isSelected ? '#63C3EC' : '#D0D5DD'}
            _hover={{ opacity: '0.7' }}
            _focus={{ opacity: '0.7' }}
          >
            <HStack justifyContent="space-between">
              {dateFilter?.isSelected ? (
                <Text bg="#63C3EC" py="1" px="2" borderRadius="8px" fontSize="x-small" ms="7px">
                  {format(dateFilter?.startDate ?? new Date(), 'MMM dd, yyyy')} - {''}
                  {format(dateFilter?.endDate ?? new Date(), 'MMM dd, yyyy')}
                </Text>
              ) : (
                <>
                  <Text>Date Range</Text>
                  <SBCalendarIcon width="15px" />
                </>
              )}
            </HStack>
          </MenuButton>
          <MenuList>
            <SBDateRangePicker setValue={setState} value={state} />
            <HStack justifyContent="end" p="3" borderTop="1px solid #eee">
              <Button
                minW="200px"
                variant="outline"
                onClick={() => {
                  handleChangeDate(false);
                }}
              >
                Cancel
              </Button>
              <Button
                minW="200px"
                onClick={() => {
                  handleChangeDate(true);
                }}
              >
                Apply
              </Button>
            </HStack>
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  );
};
