export const StatesAndProvinces = [
  // US States
  { name: 'Alabama, US', code: 'AL' },
  { name: 'Alaska, US', code: 'AK' },
  { name: 'Arizona, US', code: 'AZ' },
  { name: 'Arkansas, US', code: 'AR' },
  { name: 'California, US', code: 'CA' },
  { name: 'Colorado, US', code: 'CO' },
  { name: 'Connecticut, US', code: 'CT' },
  { name: 'Delaware, US', code: 'DE' },
  { name: 'Florida, US', code: 'FL' },
  { name: 'Georgia, US', code: 'GA' },
  { name: 'Hawaii, US', code: 'HI' },
  { name: 'Idaho, US', code: 'ID' },
  { name: 'Illinois, US', code: 'IL' },
  { name: 'Indiana, US', code: 'IN' },
  { name: 'Iowa, US', code: 'IA' },
  { name: 'Kansas, US', code: 'KS' },
  { name: 'Kentucky, US', code: 'KY' },
  { name: 'Louisiana, US', code: 'LA' },
  { name: 'Maine, US', code: 'ME' },
  { name: 'Maryland, US', code: 'MD' },
  { name: 'Massachusetts, US', code: 'MA' },
  { name: 'Michigan, US', code: 'MI' },
  { name: 'Minnesota, US', code: 'MN' },
  { name: 'Mississippi, US', code: 'MS' },
  { name: 'Missouri, US', code: 'MO' },
  { name: 'Montana, US', code: 'MT' },
  { name: 'Nebraska, US', code: 'NE' },
  { name: 'Nevada, US', code: 'NV' },
  { name: 'New Hampshire, US', code: 'NH' },
  { name: 'New Jersey, US', code: 'NJ' },
  { name: 'New Mexico, US', code: 'NM' },
  { name: 'New York, US', code: 'NY' },
  { name: 'North Carolina, US', code: 'NC' },
  { name: 'North Dakota, US', code: 'ND' },
  { name: 'Ohio, US', code: 'OH' },
  { name: 'Oklahoma, US', code: 'OK' },
  { name: 'Oregon, US', code: 'OR' },
  { name: 'Pennsylvania, US', code: 'PA' },
  { name: 'Rhode Island, US', code: 'RI' },
  { name: 'South Carolina, US', code: 'SC' },
  { name: 'South Dakota, US', code: 'SD' },
  { name: 'Tennessee, US', code: 'TN' },
  { name: 'Texas, US', code: 'TX' },
  { name: 'Utah, US', code: 'UT' },
  { name: 'Vermont, US', code: 'VT' },
  { name: 'Virginia, US', code: 'VA' },
  { name: 'Washington, US', code: 'WA' },
  { name: 'West Virginia, US', code: 'WV' },
  { name: 'Wisconsin, US', code: 'WI' },
  { name: 'Wyoming, US', code: 'WY' },

  // Canadian Provinces and Territories
  { name: 'Alberta, CA', code: 'AB' },
  { name: 'British Columbia, CA', code: 'BC' },
  { name: 'Manitoba, CA', code: 'MB' },
  { name: 'New Brunswick, CA', code: 'NB' },
  { name: 'Newfoundland and Labrador, CA', code: 'NL' },
  { name: 'Nova Scotia, CA', code: 'NS' },
  { name: 'Ontario, CA', code: 'ON' },
  { name: 'Prince Edward Island, CA', code: 'PE' },
  { name: 'Quebec, CA', code: 'QC' },
  { name: 'Saskatchewan, CA', code: 'SK' },
  { name: 'Northwest Territories, CA', code: 'NT' },
  { name: 'Nunavut, CA', code: 'NU' },
  { name: 'Yukon, CA', code: 'YT' },
];
