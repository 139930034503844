import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../SharedTypes';
import { QueriesStore } from '../../queriesStore';
import {
  type UpdateTaskInfo,
  UPDATE_TASK_INFO,
  type GeneralMessageResponse,
  GET_PAGINATED_TASKS,
  GET_TASK,
} from '../Queries';

interface UpdateTaskProps {
  payload: UpdateTaskInfo;
  onCompleted: (
    data: { updateTask: GeneralMessageResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateTaskInfoMutation() {
  const [mutate, { loading, error }] = useMutation<
    { updateTask: GeneralMessageResponse },
    { updateTaskInput: UpdateTaskInfo }
  >(UPDATE_TASK_INFO);

  async function updateTaskInfo({ payload, onCompleted }: UpdateTaskProps) {
    return mutate({
      variables: {
        updateTaskInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_PAGINATED_TASKS,
          variables: QueriesStore.get('GET_TASKS'),
        },
        {
          query: GET_TASK,
          variables: { taskId: payload.id },
        },
      ],
    });
  }

  return {
    updateTaskInfo,
    loading,
    error,
  };
}
