export enum TaskStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface ITaskCategory {
  id: string;
  name: string;
  description: string | null;
  unitType: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface ISimpleTaskCategory {
  id: string;
  name: string;
  unitType: string;
}

export interface ITask {
  id: string;
  retailerId: string;
  assigneeId: string;
  taskCategoryId: string;
  unitCount: number;
  dueDate: Date;
  status: TaskStatus;
  description: string | null;
  startDate: Date | null;
  taskCategory: ITaskCategory;
  retailer: {
    name: string;
  },
  assignee: {
    name: string;
  }
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export type CreateTaskInput = {
  retailerId: string;
  assigneeId: string;
  taskCategoryId: string;
  unitCount: number;
  dueDate: Date;
  description: string | null;
  status?: TaskStatus;
}

export type UpdateTaskStatus = {
  id: string;
  status: TaskStatus;
}

export type UpdateTaskInfo = {
  id: string;
  status?: TaskStatus;
  retailerId?: string;
  assigneeId?: string;
  taskCategoryId?: string;
  unitCount?: number;
  dueDate?: Date;
  description?: string | null;
}

export interface GeneralMessageResponse {
  message: boolean;
}


export enum TaskSortableFields {
  createdAt = 'createdAt',
  dueDate = 'dueDate',
}

export interface TaskFilters {
  status?: TaskStatus[];
  startDate?: Date;
  endDate?: Date;
}

export interface DateFilter {
  startDate?: Date;
  endDate?: Date;
}
