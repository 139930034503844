import { Box, Button, SBAddIcon, SBModal, toast, useDisclosure } from '@swftbox/style-guide';
import { useState } from 'react';
import FormSide from './FormSide';
import style from './style.module.scss';

export function AddPartner() {
  const [step, setStep] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function next() {
    if (step === 5) return toStep(1);
    toStep(step + 1);
  }

  function toStep(requiredStep: number) {
    setStep(requiredStep);
  }

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} fontSize="x-small">
        <SBAddIcon width="15px" /> Add Partner
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc
        header={<></>}
        size="xl"
        body={
          <Box className={style.formside}>
            <FormSide step={step} next={next} toStep={toStep} onDone={handleCloseModal} />
          </Box>
        }
        bordered={false}
        footer={<div />}
      />
    </>
  );
}
