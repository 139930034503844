import { gql } from '@apollo/client';

export const LIST_FEATURES = gql`
  query Features {
    features {
      id
      createdAt
      updatedAt
      deletedAt
      name
      key
      description
      billing
      category
      isConfigurable
    }
  }
`;

export const ADD_FEATURE = gql`
  mutation Mutation($input: CreateFeatureInput!) {
    createFeature(input: $input) {
      message
    }
  }
`;

export const UPDATE_FEATURE = gql`
  mutation UpdateFeature($input: UpdateFeatureInput!) {
    updateFeature(input: $input) {
      message
    }
  }
`;

export const REMOVE_FEATURE = gql`
  mutation RemoveFeature($removeFeatureId: UUID!) {
    removeFeature(id: $removeFeatureId) {
      success
      message
    }
  }
`;
