import '@swftbox/style-guide';
import './App.scss';
import { MainHelmet } from './components/Atom';

import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <MainHelmet />
      <Outlet />
    </div>
  );
}

export default App;
