import { Button, HStack, If } from '@swftbox/style-guide';
import { useState } from 'react';
import { filters } from './Filter.helper';
import { RetailerSelectInput, UserSelectInput, DriverSelectInput } from 'src/components/Organisms';
import { ReportStatus, ReportType } from 'src/components/Particles';

interface UsersFilterProps {
  onFilterChange: (filterKey: 'status' | 'type', filterValue: ReportType | ReportStatus) => void;
  setRetailerId: (id: string | null) => void;
  retailerId?: string | null;
  setDriverId: (id: string | null) => void;
  driverId?: string | null;
  setAssigneeId: (id: string | null) => void;
  assigneeId?: string | null;
}

export function Filters(props: UsersFilterProps) {
  const {
    onFilterChange,
    setRetailerId,
    setDriverId,
    retailerId,
    driverId,
    assigneeId,
    setAssigneeId,
  } = props;
  const [active, setActive] = useState<string>('all');

  return (
    <>
      <HStack spacing="2" m="5" className="Numbers" flexWrap="wrap" gap="2">
        {filters.map((filter) => (
          <HStack
            key={filter.id}
            border="1px solid #D0D5DD;"
            h="40px"
            lineHeight="40px"
            borderRadius="8px"
            shadow="xs"
            overflow="hidden"
            spacing="0"
          >
            <>
              {filter.children.map((filterItem) => (
                <Button
                  p="8px 12px"
                  fontSize="x-small"
                  key={filterItem.id}
                  variant="grouped"
                  bg={active === filterItem.value ? '#63c3ec' : 'transparent'}
                  color={active === filterItem.value ? '#fff' : 'gray.900'}
                  _focus={{ opacity: 0.85 }}
                  _hover={{ opacity: 0.85 }}
                  onClick={() => {
                    onFilterChange(
                      filter.name as 'status' | 'type',
                      filterItem.value as ReportStatus | ReportType
                    );
                    setActive(filterItem.value as ReportStatus | ReportType);
                  }}
                >
                  {filterItem.label}
                </Button>
              ))}
            </>
          </HStack>
        ))}
      </HStack>
      <HStack spacing="2" m="5" gap="2" w={'60%'}>
        <RetailerSelectInput
          value={retailerId ?? null}
          onChange={(selectedRetailerId) => {
            setRetailerId(selectedRetailerId);
          }}
        />
        <DriverSelectInput
          value={driverId ?? null}
          onChange={(selectedDriverId) => {
            setDriverId(selectedDriverId);
          }}
        />
        <UserSelectInput
          value={assigneeId ?? null}
          onChange={(selectedAssigneeId) => {
            setAssigneeId(selectedAssigneeId);
          }}
        />
      </HStack>
    </>
  );
}
