import {
  Box,
  SBModal,
  Text,
  useDisclosure,
  IconButton,
  SBTrashIcon,
  toast,
} from '@swftbox/style-guide';
import { useDeletePlan } from 'src/components/Particles';

interface DeletePlanProps {
  name: string;
  id: string;
}

export function DeletePlan({ name, id }: DeletePlanProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteMutate } = useDeletePlan();

  const onConfirm = () => {
    deleteMutate({
      onCompleted: ({ removeSubscriptionPlan }) => {
        if (removeSubscriptionPlan.message) {
          toast.success(removeSubscriptionPlan.message);
          onClose();
        }
      },
      variables: {
        removeSubscriptionPlanId: id,
      },
    });
  };
  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'red' }}
        aria-label="delete customer"
        icon={<SBTrashIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Plan</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            Are You Sure About Deleting This Plan ({name}) ?
          </Text>
        }
        size="lg"
        handleConfirm={onConfirm}
      />
    </>
  );
}
