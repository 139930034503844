import { Avatar, AvatarGroup, Box, HStack, Text } from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import LoginArrow from 'src/assets/icons/Arrows/LoginArrow.svg';
import LoginArrowPrimex from 'src/assets/icons/Arrows/LoginArrowPrimex.svg';
import ThreeStartsIcon from 'src/assets/ThreeStars.svg';
import ThreeStarsPrimexIcon from 'src/assets/ThreeStarsPrimex.svg';
import { Config } from 'src/config';

const InfoSide = () => {
  return (
    <Box maxW="75%" m="0 auto">
      <SVG src={Config.PRIMEXPRESS_BUILD ? ThreeStarsPrimexIcon : ThreeStartsIcon} />
      <Text fontSize="display-xl" lineHeight="60px" mt="24px" mb="32px" fontWeight="medium">
        super-fast deliveries & fulfillment
      </Text>
      <Text fontSize="text-lg" fontWeight="medium" mb="32px" color="gray.200">
        Whether you’re running a shop from home or a retail business with a huge e-commerce
        presence, we’ve got you covered
      </Text>
      <HStack>
        <AvatarGroup
          size="xs"
          max={4}
          position="relative"
          _after={{
            content: `""`,
            display: 'block',
            background: `url(${Config.PRIMEXPRESS_BUILD ? LoginArrowPrimex : LoginArrow})`,
            width: '350px',
            height: '140px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'absolute',
            right: '30px',
            top: '50px',
          }}
        >
          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
          <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
          <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
          <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
          <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
        </AvatarGroup>
        <Text fontSize="text-md" fontWeight="medium" color="gray.200">
          500,000+ shipments
        </Text>
      </HStack>
    </Box>
  );
};

export default InfoSide;
