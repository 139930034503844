import uuid from 'react-uuid';
import { ReportStatus, ReportType } from 'src/components/Particles';

const statusFilter = [
  { id: uuid(), label: 'All', value: undefined, background: '#ebf9fe' },
  ...Object.values(ReportStatus).map((value) => ({
    id: uuid(),
    value,
    label: value.charAt(0) + value.slice(1).toLowerCase(),
    background: '#ebf9fe',
  })),
];

const reportTypeFilter = [
  { id: uuid(), label: 'All', value: undefined, background: '#ebf9fe' },
  ...Object.values(ReportType).map((value) => {
    const labelComponents = value.split('_');
    return {
      id: uuid(),
      value,
      label: labelComponents.map((item) => item.charAt(0) + item.slice(1).toLowerCase()).join(' '),
      background: '#ebf9fe',
    };
  }),
];

export const filters = [
  {
    id: uuid(),
    children: statusFilter,
    name: 'status',
  },
  {
    id: uuid(),
    children: reportTypeFilter,
    name: 'type',
  },
];
