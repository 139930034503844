import { Box, Flex, SBPaginate, WithLoader } from '@swftbox/style-guide';
import { FC, useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  PaymentSortableFields,
  SettlementFilterArgs,
  SettlementType,
  SortOptions,
  useSettlementsQuery,
} from 'src/components/Particles';
import { SettlementsFilter, PageHeader } from './Components';
import { SettlementForm } from './Components/SettlementForm';
import { SettlementsTable } from './Components/SettlementsTable';
import style from './style.module.scss';

export const ExpensesSettlementsPage: FC = () => {
  const [settlementModalOpen, setSettlementModalOpen] = useState(false);
  const [settlementFilter, setSettlementFilter] = useState<SettlementFilterArgs>({
    page: 1,
    perPage: 20,
    type: SettlementType.DRIVER_COMPENSATION,
    order: SortOptions.DESC,
    orderBy: PaymentSortableFields.createdAt,
  });

  const { settlements, loading } = useSettlementsQuery(settlementFilter);

  function onPageChange(selected: number) {
    setSettlementFilter({
      ...settlementFilter,
      page: selected,
    });
  }

  const onFilterChange = (filter: SettlementFilterArgs) => {
    setSettlementFilter(filter);
  };

  const openSettlementModal = () => {
    setSettlementModalOpen(true);
  };

  useEffect(() => {
    setSettlementFilter({
      ...settlementFilter,
      subjectId: undefined,
    });
  }, [settlementFilter.subjectType]);

  return (
    <Box className={style.payment_main}>
      <PageHeader
        title="Settlement"
        count={settlements?.total || 0}
        buttonText="Create Settlement"
        onButtonClick={openSettlementModal}
      />
      <SettlementsFilter onChange={onFilterChange} {...settlementFilter} />
      <WithLoader isLoading={loading}>
        <SettlementsTable
          settlements={settlements?.data || []}
          onPageChange={onPageChange}
          page={settlementFilter.page}
          total={settlements?.total || 0}
        />
        {!loading && !settlements?.total && (
          <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
            <EmptyTable title="No Settlements Found" />
          </Flex>
        )}
      </WithLoader>
      {settlementModalOpen && (
        <SettlementForm
          onModalClose={setSettlementModalOpen.bind(null, false)}
          settlementType={SettlementType.DRIVER_COMPENSATION}
        />
      )}
    </Box>
  );
};
