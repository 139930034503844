import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  SBDocumentSignedIcon,
  SBModal,
  SBTextArea,
  Stack,
  Text,
  toast,
  Tooltip,
  useDisclosure,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdateOrder } from 'src/components/Particles';
import { UpdateOrderSchema } from './order.schema';

interface EditOrderProps {
  orderId: string;
  notes?: string;
  fulfillmentNotes?: string;
}

type UpdateOrderForm = Omit<EditOrderProps, 'orderId'>;

export function EditOrderRemarks({ orderId, notes, fulfillmentNotes }: EditOrderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, reset, handleSubmit } = useForm<UpdateOrderForm>({
    mode: 'all',
    resolver: yupResolver(UpdateOrderSchema),
    defaultValues: { notes },
  });

  const { updateOrder } = useUpdateOrder();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    onClose();
  };

  function onConfirm(data: UpdateOrderForm) {
    void updateOrder({
      payload: { ...data, id: orderId },
      onCompleted: ({ updateOrder }) => {
        handleCloseModal(updateOrder.message);
      },
    });
  }
  return (
    <>
      <Box bg="#EFEFEF" p="1" borderRadius="4" w="max-content" h="25px">
        <Tooltip label="Show Remarks" hasArrow>
          <Button variant="link" onClick={onOpen} color="primary.900" minW="30px">
            <SBDocumentSignedIcon width="13px" height="13px" />
          </Button>
        </Tooltip>
      </Box>
      <SBModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        header={<Box pb="1vh"></Box>}
        size="xl"
        body={
          <Stack as="form" onSubmit={handleSubmit(onConfirm)} gap={4}>
            {!!notes && (
              <>
                <Text color="gray.600" fontSize="text-sm" fontWeight="semibold">
                  Notes
                </Text>
                <SBTextArea {...register('notes')} isDisabled={true} />
              </>
            )}
            {!!fulfillmentNotes && (
              <>
                <Text color="gray.600" fontSize="text-sm" fontWeight="semibold">
                  Fulfillment Notes
                </Text>
                <SBTextArea value={fulfillmentNotes} isDisabled={true} />
              </>
            )}
          </Stack>
        }
        footer={<></>}
        bordered={false}
        // handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
