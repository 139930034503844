import { useMutation } from '@apollo/client';
import { REGENERATE_REPORT, type GenerateReportResponse, LIST_REPORTS } from '../Queries';
import { QueriesStore } from '../../queriesStore';

export const useRegenerateReportMutation = () => {
  const [mutate, { loading, error, reset, ...rest }] = useMutation<
    { regenerateReport: GenerateReportResponse },
    { reportId: string }
  >(REGENERATE_REPORT);

  async function regenerateReport(reportId: string) {
    return mutate({
      variables: {
        reportId,
      },
      refetchQueries: [
        { query: LIST_REPORTS, variables: QueriesStore.get('LIST_REPORTS'), pollInterval: 5000 },
      ],
    });
  }

  return {
    regenerateReport,
    reset,
    loading,
    error,
    ...rest,
  };
};
