import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import { type ChangePasswordType, UPDATE_PASSWORD_QUERY } from '../Queries';

interface UpdatePasswordResponse {
  message: string;
}

interface UpdatePasswordProps {
  onCompleted: (
    data: { changePassword: UpdatePasswordResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdatePassword({ onCompleted }: UpdatePasswordProps) {
  const [mutate, { loading, error }] = useMutation<
    { changePassword: UpdatePasswordResponse },
    { changePasswordInput: ChangePasswordType }
  >(UPDATE_PASSWORD_QUERY, {
    onCompleted,
  });

  return {
    mutate,
    loading,
    error,
  };
}
