import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import { AddCarrier } from './Modal';

interface CarriersHeaderProps {
  carriersCount: number;
}
export function CarriersHeader(props: CarriersHeaderProps) {
  const { carriersCount } = props;
  return (
    <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
      <Box>
        <Text fontWeight="semibold" fontSize="text-lg">
          Carriers
          <Badge
            p="2px 8px"
            mx="1"
            fontWeight="medium"
            borderRadius="8px"
            fontSize="x-small"
            textTransform="capitalize"
          >
            {carriersCount <= 1 ? `${carriersCount} Carrier` : `${carriersCount} Carriers`}
          </Badge>
        </Text>
      </Box>
      <Spacer />
      <AddCarrier />
    </Flex>
  );
}
