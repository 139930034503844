import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { format } from 'date-fns';
import { type Category } from 'src/components/Particles';
import { DeleteCategory, EditCategory, ViewCategory } from './Modal';

interface CategoriesTableProps {
  categories: Category[];
}

export default function CategoriesTable(props: CategoriesTableProps) {
  const { categories } = props;

  if (!categories.length) return null;

  return (
    <TableContainer whiteSpace="normal">
      <Table variant="striped">
        <Thead>
          <Tr bg="#fbfbfc">
            <Th>name</Th>
            <Th>Created At</Th>
            <Th>Updated At</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {categories.map((category) => {
            return (
              <Tr key={category.id}>
                <Td>
                  <Text>{category.name}</Text>
                </Td>
                <Td>
                  <Text> {format(new Date(category.createdAt), 'HH:MM\xa0\xa0\xa0MMM dd, yyyy')}</Text>
                </Td>
                <Td>
                  <Text> {format(new Date(category.updatedAt), 'HH:MM\xa0\xa0\xa0MMM dd, yyyy')}</Text>
                </Td>

                <Td>
                  <Flex gap={4} justifyContent="flex-end">
                    <ViewCategory category={category} />
                    <EditCategory category={category} />
                    <DeleteCategory name={category.name} id={category.id} />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
