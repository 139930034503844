import { Box, Button, toast } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { InvoiceType } from 'src/components/Particles';
import { downloadHTMLAsPDF, fetchInvoiceData } from './Components/helper';

export function InvoicePage() {
  const { key } = useParams();

  if (!key) return;
  const [isGenerating, setIsGenerating] = useState(false);
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get('file_name');
  const [invoice, setInvoice] = useState('');
  const invoiceType = searchParams.get('type');

  useEffect(() => {
    fetchInvoiceData(atob(key), invoiceType as InvoiceType).then((invoice) => {
      setInvoice(invoice);
    });
  }, []);

  const generatePDF = async () => {
    setIsGenerating(true);

    try {
      if (!invoice) return console.error('Invoice not found');
      downloadHTMLAsPDF(invoice, fileName || 'invoice.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error('Error generating PDF');
    } finally {
      setIsGenerating(false);
    }
  };
  if (!invoice) return;

  return (
    <Box padding={4} borderWidth={1} borderRadius="md">
      <Button onClick={generatePDF} isLoading={isGenerating} m={4}>
        Generate PDF
      </Button>
      <Box id="invoiceContent" padding={4} dangerouslySetInnerHTML={{ __html: invoice }} />
    </Box>
  );
}
