import {
  Box,
  Button,
  MenuItem,
  SBModal,
  Stack,
  Text,
  toast,
  useDisclosure,
  IconWText,
  SBTrashIcon,
} from '@swftbox/style-guide';
import { useDeleteTask } from 'src/components/Particles/resolvers';

interface DeleteTaskProps {
  taskId: string;
}

export function DeleteTask({ taskId }: DeleteTaskProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteTask, loading } = useDeleteTask();

  function onDeleteConfirm() {
    void deleteTask({
      payload: { removeTaskId: taskId },
      onCompleted: ({ removeTask }) => {
        if (removeTask.message) {
          toast.success(removeTask.message);
          onClose();
        }
      },
    });
  }

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconWText
          text="Delete Task"
          spacing="20px"
          color="red.normal"
          aria-label="delete task"
          Icon={<SBTrashIcon width="15px" />}
        />
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Task</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to delete this task!
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button variant="outline" w="100%" onClick={onClose}>
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onDeleteConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
