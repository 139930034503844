import { CustomerLocationMatchingStrategy } from 'src/components/Particles';

export const CustomerLocationMatchingStrategyOptions = [
  {
    value: CustomerLocationMatchingStrategy.NONE,
    label: 'Prioritize a single verified address',
    description:
      'Always match a customer’s single verified location if available, else replace the old address with the new one received',
  },
  {
    value: CustomerLocationMatchingStrategy.DICE_ALGORITHM,
    label: 'Looser address matching',
    description: 'Match a previously used address, or create a new location if there’s no match',
  },
];
