import { memo, useCallback, useMemo } from 'react';
import { Autocomplete } from '@swftbox/style-guide';
import { useFormContext, Controller } from 'react-hook-form';
import type { CarriyoIntegrationCarrier, Item } from 'src/components/Particles';

interface CarriersFormProps {
  carriyoCarriers: CarriyoIntegrationCarrier[];
  items: Item[];
}

const CarriersForm = ({ carriyoCarriers, items }: CarriersFormProps) => {
  const { control, watch, setValue, clearErrors } = useFormContext();

  const carrierId = watch('carrierId');

  const carrierOptions = useMemo(
    () =>
      carriyoCarriers.map((carrier) => ({
        label: carrier.accountName,
        value: carrier.id,
      })),
    [carriyoCarriers]
  );

  const selectedCarrier = useMemo(
    () => carrierOptions.find((option) => option.value === carrierId),
    [carrierId, carrierOptions]
  );

  const handleChange = useCallback(
    (option: any) => {
      setValue('carrierId', option?.value || null);
      if (option?.value) {
        clearErrors('carrierId');
      }
    },
    [clearErrors, setValue]
  );

  return (
    <Controller
      name="carrierId"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={handleChange}
          value={selectedCarrier ?? null}
          options={carrierOptions}
          placeholder="Choose provider..."
          isDisabled={!items?.length}
          error={error?.message}
        />
      )}
    />
  );
};

export default memo(CarriersForm);
