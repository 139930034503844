import {
  Box,
  Checkbox,
  HStack,
  IconButton,
  SBEditIcon,
  SBInput,
  SBModal,
  Stack,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import { type Area, type UpdateAreaInput, useUpdateArea } from 'src/components/Particles';

interface EditAreaProps {
  area: Area;
}

export function EditArea({ area }: EditAreaProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateAreaMutation } = useUpdateArea({
    onCompleted: (data) => {
      handleCloseModal(data.updateArea.message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateAreaInput>({
    mode: 'all',
    defaultValues: {
      id: area.id,
      zone: area.zone,
      outOfZone: area.outOfZone,
      remote: area.remote,
      pickupEligible: area.pickupEligible,
    },
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: UpdateAreaInput) => {
    void updateAreaMutation({
      variables: {
        updateAreaInput: data,
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="Edit Area"
        icon={<SBEditIcon width="15px" />}
      />
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={
          <Stack gap={4} as={'form'}>
            <SBInput
              label="Zone*"
              placeholder="Add Zone..."
              {...register('zone')}
              error={errors.zone?.message}
              crossOrigin={undefined}
            />
            <Controller
              name="pickupEligible"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <HStack spacing={5}>
                  <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
                    Is Pickup Eligible
                  </Checkbox>
                </HStack>
              )}
            />
            <Controller
              name="outOfZone"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <HStack spacing={5}>
                  <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
                    Is Out of Zone
                  </Checkbox>
                </HStack>
              )}
            />
            <Controller
              name="remote"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <HStack spacing={5}>
                  <Checkbox onChange={onChange} ref={ref} isChecked={value} size={'sm'}>
                    Is Remote
                  </Checkbox>
                </HStack>
              )}
            />
          </Stack>
        }
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
