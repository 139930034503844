import { Box, SBAddIcon, SBModal, useDisclosure, toast, IconButton } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useEditQuestion, type Question } from 'src/components/Particles';
import EditQuestionFrom from './EditQuestionForm';
import SVG from 'react-inlinesvg';
import Edit01Icon from 'src/assets/icons/General/Edit01.svg';

interface EditQuestionProps {
  question: Question;
}

export function EditQuestion({ question }: EditQuestionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useEditQuestion({
    onCompleted: (data) => {
      handleCloseModal(data.updateFeedbackQuestion.message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Question>({
    mode: 'all',
  });

  const handleCloseModal = (message?: string) => {
    if (message) {
      toast.success(message);
    }
    onClose();
    reset();
  };

  const onConfirm = (data: Question) => {
    void mutate({
      variables: {
        input: { ...data, sequence: Number(data.sequence), id: question.id },
      },
    });
  };

  return (
    <>
      <IconButton
        size="small"
        variant="link"
        color="gray.600"
        _hover={{ opacity: '0.5', color: 'green' }}
        aria-label="edit Fulfillment"
        icon={<SVG src={Edit01Icon} stroke="currentColor" width="15px" />}
        onClick={onOpen}
      />

      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBAddIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={<EditQuestionFrom register={register} errors={errors} question={question} />}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
