import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  WithLoader,
  Image,
  CloseButton,
} from '@swftbox/style-guide';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import EmptyBox from 'src/assets/emptyBox.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
interface ViewItemsProps {
  attachmentsURLs: string[];
  onRemove: (index: number) => void;
  loading?: boolean;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ViewAttachments = (props: ViewItemsProps) => {
  const { attachmentsURLs, loading, onRemove } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Box>
      <WithLoader isLoading={false}>
        <Grid templateColumns="repeat(3, 1fr)" gap={2}>
          {attachmentsURLs.map((attachment: string, index: number) => (
            <GridItem key={attachment}>
              <CloseButton
                onClick={() => {
                  onRemove(index);
                }}
              />
              {attachment.includes('pdf') ? (
                <Document file={attachment} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Link key={el} href={attachment} isExternal>
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={200}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </Link>
                  ))}
                </Document>
              ) : (
                <Link href={attachment} isExternal>
                  <Image
                    // boxSize="150px"
                    objectFit="cover"
                    src={attachment}
                    alt={`attachment #${index + 1}`}
                  />
                </Link>
              )}
            </GridItem>
          ))}
        </Grid>
      </WithLoader>
      {!loading && !attachmentsURLs.length && (
        <Flex w="100%" h="30vh" justifyContent="center" alignItems="center">
          <EmptyTable icon={EmptyBox} title="No Items Found" />
        </Flex>
      )}
    </Box>
  );
};
