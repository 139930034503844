import { useLazyQuery } from '@apollo/client';
import { GET_TOTAL_RETAILERS } from './retailers.graphql';
import { type Retailer } from './retailer.types';

interface QueryResponse {
  paginatedRetailers: { 
    total: number;
  };
}

export function useRetailerNameCheckQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_TOTAL_RETAILERS);

  async function checkIsRetailerNameExist(name: string) {
    const result = await query({ variables: { name, page: 1 } });
    return !!result.data?.paginatedRetailers.total;
  }

  return {
    checkIsRetailerNameExist,
    isRetailerNameExist: !!data?.paginatedRetailers?.total,
    loading,
    error,
  };
}
