import {
  Flex,
  Text,
  SBInput,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  Switch,
  HStack,
} from '@swftbox/style-guide';
import { useEffect, ChangeEvent, useMemo } from 'react';
import { type FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Control,
  UseFormGetValues,
  type UseFormClearErrors,
  type UseFormHandleSubmit,
  type UseFormRegister,
  type UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { useFieldArray } from 'react-hook-form';
import {
  UpdateUserFormValues,
  useRolesQuery,
  usePermissionsQuery,
  ucFirst,
  useAuthContext,
} from 'src/components/Particles';
import styles from '../../../style.module.scss';

interface UpdateUserProps {
  control: Control<UpdateUserFormValues>;
  register: UseFormRegister<UpdateUserFormValues>;
  setValue: UseFormSetValue<UpdateUserFormValues>;
  getValues: UseFormGetValues<UpdateUserFormValues>;
  handleSubmit: UseFormHandleSubmit<UpdateUserFormValues>;
  errors: FieldErrors<UpdateUserFormValues>;
  clearErrors: UseFormClearErrors<UpdateUserFormValues>;
  onDone: (payload: UpdateUserFormValues) => void | Promise<void>;
}

export function UserForm(props: UpdateUserProps) {
  const { user } = useAuthContext();
  const { register, control, handleSubmit, setValue, errors, clearErrors, onDone, getValues } =
    props;

  const { fields: roleFields, replace: setRoles } = useFieldArray({
    control,
    name: 'roles',
  });

  const { fields: permissionFields, replace: setPermissions } = useFieldArray({
    control,
    name: 'permissions',
  });

  const { roles: allRoles, getRoles } = useRolesQuery();
  const { permissions: allPermissions, getPermissions } = usePermissionsQuery();

  useEffect(() => {
    getRoles({ withPermissions: true });
    getPermissions();
  }, []);

  function onPhoneChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const phone = value.includes('+') ? value : `+${value}`;
    setValue('phone', phone);
    clearErrors('phone');
  }

  const handleRoleCheckboxChange = (selectedRoles: string[]) => {
    // First add or remove permissions based on selected roles
    if (selectedRoles.length > roleFields.length) {
      // A role was added
      const addedRole = selectedRoles.find(
        (roleName) => !roleFields.find((field) => field.name === roleName)
      );
      const permissionsToAdd = allRoles.find((role) => role.name === addedRole)?.permissions || [];
      setPermissions([...permissionFields, ...permissionsToAdd]);
    }

    if (selectedRoles.length < roleFields.length) {
      // A role was removed
      const removedRole = roleFields.find(
        (field) => !selectedRoles.find((roleName) => field.name === roleName)
      )?.name;
      // Permissions of removed role
      const removedRolePermissions =
        allRoles.find((role) => role.name === removedRole)?.permissions || [];
      // Keep permissions linked to remaining selected roles
      const permissionsToKeep = allRoles
        .filter((role) => selectedRoles.includes(role.name))
        .flatMap((role) => role.permissions);
      setPermissions(
        permissionFields.filter((field) => {
          return (
            !removedRolePermissions.some((permission) => permission.name === field.name) ||
            permissionsToKeep.some((permission) => permission?.name === field.name)
          );
        })
      );
    }

    setRoles(selectedRoles.map((role) => ({ name: role })));
  };

  const handlePermissionCheckboxChange = (selectedPermissions: string[]) => {
    setPermissions(selectedPermissions.map((permission) => ({ name: permission })));
  };

  const [isSuperAdmin, isAdmin, isDispatcher] = useMemo(() => {
    return [
      user?.roles?.includes('SuperAdmin'),
      user?.roles?.includes('Admin'),
      user?.roles?.includes('Dispatcher'),
    ];
  }, [user]);

  return (
    <form onSubmit={handleSubmit(onDone)} className={styles.addUserForm}>
      <SBInput
        label="Name *"
        placeholder="ex: John"
        {...register('name')}
        error={errors.name?.message}
      />
      <SBInput
        label="Phone *"
        placeholder="ex: +97100000000"
        {...register('phone')}
        onChange={onPhoneChange}
        error={errors.phone?.message}
      />
      <SBInput
        label="Email*"
        placeholder="ex: john@example.com"
        {...register('email')}
        error={errors.email?.message}
      />
      {!getValues('verifyEmail') && (
        <HStack spacing={4} w="100%" justifyContent="start">
          <FormLabel htmlFor="isReverse">Verify Email</FormLabel>
          <Switch id="verifyEmail" {...register('verifyEmail')} />
        </HStack>
      )}
      {(isAdmin || isSuperAdmin || isDispatcher) && (
        <>
          <FormControl>
            <FormLabel fontSize={14} marginBottom={2}>
              Roles
            </FormLabel>
            <CheckboxGroup
              value={roleFields.map((field) => field.name)}
              onChange={handleRoleCheckboxChange}
            >
              <Flex flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                {allRoles?.map((role) => {
                  if (['SuperAdmin', 'Admin'].includes(role.name) && !isSuperAdmin) {
                    return null;
                  }

                  if (
                    isDispatcher &&
                    !isAdmin &&
                    !isSuperAdmin &&
                    !['Driver', 'Retailer', 'Operator', 'Customer'].includes(role.name)
                  ) {
                    return null;
                  }

                  return (
                    <Checkbox key={`role-${role.id}`} value={role.name} marginRight={10}>
                      <Text fontSize={14}>{role.name}</Text>
                    </Checkbox>
                  );
                })}
              </Flex>
            </CheckboxGroup>
          </FormControl>
          <FormControl>
            <FormLabel fontSize={14} marginBottom={2}>
              Permissions
            </FormLabel>
            <CheckboxGroup
              value={permissionFields.map((field) => field.name)}
              onChange={handlePermissionCheckboxChange}
            >
              <Flex
                maxWidth="100%"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {allPermissions?.map((permission) => (
                  <Checkbox
                    key={`permission-${permission.id}`}
                    value={permission.name}
                    marginBottom={2}
                    minWidth="200px"
                  >
                    <Text fontSize={14}>
                      {permission.name
                        .split('-')
                        .map((str) => ucFirst(str))
                        .join(' ')}
                    </Text>
                  </Checkbox>
                ))}
              </Flex>
            </CheckboxGroup>
          </FormControl>
        </>
      )}
    </form>
  );
}
