import { gql } from '@apollo/client';

export const LIST_INVOICE_SETTINGS = gql`
  query InvoiceSettings {
    invoiceSettings {
      id
      createdAt
      updatedAt
      deletedAt
      code
      description
      rate
      vat
      isDefault
      type
    }
  }
`;

export const UPSERT_INVOICE_SETTING = gql`
  mutation UpsertInvoiceSetting($upsertInvoiceSettingInput: UpsertInvoiceSettingInput!) {
    upsertInvoiceSetting(upsertInvoiceSettingInput: $upsertInvoiceSettingInput) {
      message
    }
  }
`;

export const REMOVE_INVOICE_SETTING = gql`
  mutation RemoveInvoiceSetting($removeInvoiceSettingInput: IdInput!) {
    removeInvoiceSetting(RemoveInvoiceSettingInput: $removeInvoiceSettingInput) {
      message
      success
    }
  }
`;
