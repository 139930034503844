import { Badge, Box, Flex, Spacer, Text } from '@swftbox/style-guide';
import AddDriverModal from './Modal/AddDriver.modal';

interface DriversHeaderProps {
	driversCount: number;
}

export function DriversHeader(props: DriversHeaderProps) {
	const { driversCount } = props;
	
	return (
		<>
			<Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
				<Box>
					<Text fontWeight="semibold" fontSize="text-lg">
						Drivers
						<Badge
							p="2px 8px"
							mx="1"
							fontWeight="medium"
							borderRadius="8px"
							fontSize="x-small"
							textTransform="capitalize"
						>
							{driversCount !== 1 ? `${driversCount} Drivers` : `${driversCount} Driver`}
						</Badge>
					</Text>
				</Box>
				<Spacer />
				<AddDriverModal/>
			</Flex>
		</>
	);
}
