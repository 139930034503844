import clsx from 'clsx';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { SBNavbar, SBSidebar } from '../Organisms';
import { ErrorBoundary, PrivateRoute, SidebarExpandContext } from '../Particles';

export function PrivateTemplate() {
  const { sidebarIsExpand } = useContext(SidebarExpandContext);

  return (
    <PrivateRoute>
      <section>
        <SBSidebar />
        <div
          className={clsx({
            container: true,
            collapse: sidebarIsExpand,
          })}
        >
          <SBNavbar />
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </section>
    </PrivateRoute>
  );
}
