import { useMutation } from '@apollo/client';
import { DELETE_SHIFT_TIME, GET_DRIVERS } from './drivers.graphql';
import { QueriesStore } from '../queriesStore';
import { ShiftTime } from './drivers.types';

interface RemoveShiftTimesResponse {
  driverId: string;
}

interface DeleteShiftTimesInput{
  driverId: string; 
  shiftTimes: ShiftTime[]
}
export function useDeleteShiftTimesMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    { deleteShiftTimes: RemoveShiftTimesResponse },
    { deleteShiftTimesInput: DeleteShiftTimesInput }
  >(DELETE_SHIFT_TIME, {
    variables: {
      deleteShiftTimesInput: {}
    },
    refetchQueries: [{ query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }],
  });

  async function deleteShiftTimes(payload: DeleteShiftTimesInput) {
    await mutate({
      variables: {
        deleteShiftTimesInput: payload
      },
      refetchQueries: [{ query: GET_DRIVERS, variables: QueriesStore.get('GET_DRIVERS') }],
    });
  }

  return {
    deleteShiftTimes,
    success: data?.deleteShiftTimes.driverId && !error,
    loading,
    error,
  };
}
