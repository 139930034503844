import { useMutation } from '@apollo/client';
import { GET_SUBSCRIPTION_QUERY, UPDATE_ASSIGN_PLAN_CUSTOMER_QUERY } from './Subscription.graphql';
import { toast } from '@swftbox/style-guide';

interface QueryResponse {
  assignPlanToRetailer: {
    id: string;
    endDate: string;
    startDate: string;
    status: string;
    plan: {
      name: string;
      price: string;
      currency: string;
      id: string;
    };
  };
}
interface QueryInput {
  subscribePlanInput: {
    retailerId: string;
    planId: string;
  };
}
interface Props {
  retailerId: string;
  planId: string;
}
export function useAssignPlanToRetailerQuery({ retailerId, planId }: Props) {
  const [mutate, rest] = useMutation<QueryResponse, QueryInput>(UPDATE_ASSIGN_PLAN_CUSTOMER_QUERY);
  const handleAssignPlan = () => {
    mutate({
      variables: {
        subscribePlanInput: {
          retailerId,
          planId,
        },
      },
      onCompleted: () => toast.success('Plan Assigned!'),
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_QUERY,
          variables: {
            retailerSubscribePlanInput: {
              retailerId,
            },
          },
        },
      ],
    });
  };
  return {
    handleAssignPlan,
    ...rest,
  };
}
