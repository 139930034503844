import { BillingInfo } from '../../retailers';
import { SortOptions } from '../../SharedTypes';

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  ISSUED = 'ISSUED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  OVERDUE = 'OVERDUE',
  CANCELLED = 'CANCELLED',
}

export enum InvoiceLineItemType {
  SERVICE = 'SERVICE',
  ADJUSTMENT = 'ADJUSTMENT',
  TRANSACTION = 'TRANSACTION',
}

export enum InvoiceType {
  SERVICE = 'SERVICE',
  COLLECTION = 'COLLECTION',
  GENERAL = 'GENERAL',
}

export enum InvoiceGenerationType {
  SERVICES_COLLECTION = 'SERVICES_COLLECTION',
  GENERAL = 'GENERAL',
}

export interface InvoicesLineItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  invoiceId: string;
  code?: string;
  description: string;
  quantity: number;
  rate: number;
  taxableAmount: number;
  vat: number;
  vatAmount: number;
  total: number;
  type: InvoiceLineItemType;
}

export interface Invoice {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  retailerId: string;
  invoiceNumber: string;
  poNumber: string;
  from: string;
  to: string;
  type: InvoiceType;
  activeAt: string;
  dueDateInDays: number;
  status: InvoiceStatus;
  lineItemsTotal: number;
  addTotal: number;
  lessTotal: number;
  netTotal: number;
  lineItems?: InvoicesLineItem[];
  retailer: {
    name: string;
    id: string;
    billingInfo: BillingInfo;
  };
}

export interface InvoicesFilterArgs {
  page?: number;
  perPage?: number;
  orderBy: string;
  order: SortOptions;
  retailerId?: string[] | null;
  status?: InvoiceStatus[];
  keyword?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface InvoicesQueryResponse {
  paginatedInvoices: {
    data: Invoice[];
    total: number;
    pageCount: number;
    currentPage: number;
  };
}

export interface InvoiceQueryResponse {
  invoiceById: Invoice;
}

export interface CreateInvoiceInput {
  retailerId: string;
  from: string;
  to: string;
  activeAt: string;
  dueDateInDays?: number;
  poNumber: string;
  type: InvoiceType;
}

export interface SingleInvoiceMutationResponse {
  data: { id: string };
  message: string;
}

export interface InvoiceMutationResponse {
  data: { id: string }[];
  message: string;
}

export interface UpdateInvoiceLineItemInput {
  code?: string;
  description: string;
  id?: string;
  quantity: number;
  type: InvoiceLineItemType;
  rate: number;
  vat: number;
  taxableAmount: number;
}

export interface UpdateInvoiceInput {
  activeAt: string;
  deletedLineItems?: string[];
  dueDateInDays?: number;
  from: string;
  id: string;
  lineItems: UpdateInvoiceLineItemInput[];
  poNumber: string;
  status?: InvoiceStatus;
  to: string;
}

export interface UpdateInvoiceStatusInput {
  id: string;
  status: InvoiceStatus;
}

export interface BulkCreateInvoiceInput {
  retailerIds: string[];
  from: string;
  to: string;
  activeAt: string;
  dueDateInDays?: number;
}
