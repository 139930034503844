import { useLazyQuery } from '@apollo/client';
import { GET_OPERATORS, type Operator } from '../Queries';

interface QueryResponse {
  Operators: Operator[];
}

export function useOperatorsQuery() {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_OPERATORS);

  function getOperators() {
    void query();
  }
  return {
    getOperators,
    operators: data?.Operators,
    loading,
    error,
  };
}
