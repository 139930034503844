import { useQuery } from '@apollo/client';
import { QueriesStore } from '../../queriesStore';
import { type InvoicesFilterArgs, type InvoicesQueryResponse, LIST_INVOICES } from '../Queries';

export function useInvoicesQuery(filter: InvoicesFilterArgs) {
  QueriesStore.update('LIST_INVOICES', { ...filter });
  const { data, loading, error, ...rest } = useQuery<InvoicesQueryResponse, InvoicesFilterArgs>(
    LIST_INVOICES,
    {
      pollInterval: 30000,
      variables: filter,
    }
  );

  return {
    invoices: data?.paginatedInvoices.data || [],
    total: data?.paginatedInvoices.total,
    loading,
    error,
    ...rest,
  };
}
