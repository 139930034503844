import {
  format,
  subWeeks,
  startOfDay,
  endOfDay,
  setHours,
  setMinutes,
  parseISO,
  isValid,
  isWithinInterval,
  addMinutes,
} from 'date-fns';

export function buildPastWeekDateArray(noOfWeeks: number = 1, reverse: boolean = true) {
  const today = new Date();
  const weekAgo = subWeeks(today, noOfWeeks);

  const dateArray = [];

  for (let date = startOfDay(weekAgo); date <= endOfDay(today); date.setDate(date.getDate() + 1)) {
    const dateWithTime = setHours(setMinutes(date, 0), 20); // Set time to 8:00 PM untill handle in UI

    const label = format(dateWithTime, 'MMM d, yyyy');
    const isoValue = dateWithTime.toISOString();

    dateArray.push({ label, value: isoValue });
  }

  if (reverse) return dateArray.reverse();

  return dateArray;
}

function isValidDate(date: Date | string): boolean {
  if (typeof date === 'string') {
    const parsedDate = parseISO(date);
    return isValid(parsedDate);
  } else if (date instanceof Date) {
    return isValid(date);
  }
  return false;
}

export function convertToFormattedLocalTime(input: Date | string): string | null {
  if (!input || !isValidDate(input)) {
    return input.toString();
  }

  let date: Date;
  if (typeof input === 'string') {
    date = parseISO(input);
  } else {
    date = input;
  }
  return format(date, ' E MMM dd HH:mm, yyyy');
}

export function isDateWithinNext30Minutes(date: Date): boolean {
  const interval = {
    start: new Date(),
    end: addMinutes(new Date(), 30),
  };

  return isWithinInterval(date, interval);
}
