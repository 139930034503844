import { FC, useState, useEffect } from 'react';
import { Box, Text, Button, SBAddIcon } from '@swftbox/style-guide';
import { PickupProfilesTable } from './PickupProfilesTable';
import { PickupProfileForm } from './PickupProfileForm';
import { PickupProfile, usePickupProfilesQuery } from 'src/components/Particles';

interface Props {
  back: () => void;
  retailerId: string;
}

export const PickupSettings: FC<Props> = ({ back, retailerId }) => {
  const [showForm, setShowForm] = useState(false);
  const [editPickupProfile, setEditPickupProfile] = useState<PickupProfile>();
  const { getPickupProfiles, pickupProfiles, loading } = usePickupProfilesQuery(retailerId);

  useEffect(() => {
    getPickupProfiles();
  }, [retailerId]);

  const handleEditPickupProfile = (pickupProfile: PickupProfile) => {
    setEditPickupProfile(pickupProfile);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditPickupProfile(undefined);
  };

  return (
    <Box>
      {!showForm ? (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={1}
          >
            <Box />
            <Button size="sm" onClick={setShowForm.bind(null, true)}>
              <SBAddIcon width="13px" />
              <Text fontSize="text-sm">Create Pickup Profile</Text>
            </Button>
          </Box>
          <PickupProfilesTable
            pickupProfiles={pickupProfiles}
            editPickupProfile={handleEditPickupProfile}
            loading={loading}
          />
        </>
      ) : (
        <PickupProfileForm
          retailerId={retailerId}
          pickupProfile={editPickupProfile}
          onClose={handleCloseForm}
        />
      )}
    </Box>
  );
};
