import { useLazyQuery } from '@apollo/client';
import {
  RolesQueryVariables,
  RolesQueryResponse,
  GET_PERMISSIONS,
  GET_ROLES,
  Permission,
  PermissionsQueryResponse,
  PermissionsQueryVariables,
} from '../Queries';

export function useRolesQuery() {
  const [query, { data, loading, error }] = useLazyQuery<RolesQueryResponse, RolesQueryVariables>(
    GET_ROLES
  );

  function getRoles(variables?: Partial<RolesQueryVariables>) {
    return query({ variables });
  }

  return {
    getRoles,
    roles: data?.roles || [],
    loading,
    error,
  };
}

export function usePermissionsQuery() {
  const [query, { data, loading, error }] = useLazyQuery<
    PermissionsQueryResponse,
    PermissionsQueryVariables
  >(GET_PERMISSIONS);

  function getPermissions(variables?: Partial<PermissionsQueryVariables>) {
    return query({ variables });
  }

  return {
    getPermissions,
    permissions: data?.permissions || [],
    loading,
    error,
  };
}
