import {
  Box,
  Checkbox,
  Flex,
  HStack,
  SBInput,
  Spinner,
  Switch,
  Text,
  VStack,
  toast,
} from '@swftbox/style-guide';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  InvoiceGenerationType,
  Retailer,
  RetailerConfigsDTO,
  useUpsertRetailerConfigsMutation,
  useUpsertRetailerFeatureMutation,
  type Feature,
} from 'src/components/Particles';
import { SectionRow, SubmitAndCancel } from '../../Shared';

interface Props {
  retailer: Retailer;
  orderEnhancementsFeatures: Feature[];
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
}
const convertToNumber = (value: string) => {
  return value === '' ? 0 : Number(value);
};
interface FormValues {
  retailerId: string;
  returnBilling: number;
  rtoBilling: number;
  storageRate: number;
  storageCount: number;
  cardTransactionRate: number;
  cashTransactionRate: number;
  defaultInvoiceType: InvoiceGenerationType;
  features: Array<{
    featureKey: string;
    isEnabled: boolean;
    isDefault: boolean;
    billing: number;
  }>;
}
export function OrderFeaturesSettings(props: Props) {
  const { retailer, orderEnhancementsFeatures, setAllowToMove } = props;

  const [formInitialized, setFormInitialized] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      retailerId: retailer.id,
      returnBilling: retailer.retailerConfiguration?.returnBilling,
      rtoBilling: retailer.retailerConfiguration?.rtoBilling,
      storageRate: retailer.retailerConfiguration?.storageRate,
      storageCount: retailer.retailerConfiguration?.storageCount,
      cardTransactionRate: retailer.retailerConfiguration?.cardTransactionRate,
      cashTransactionRate: retailer.retailerConfiguration?.cashTransactionRate,
      defaultInvoiceType: retailer.retailerConfiguration?.defaultInvoiceType,
      features: orderEnhancementsFeatures.map((feature) => ({
        featureKey: feature.key,
        billing:
          retailer?.features?.find((retFeature) => retFeature.featureKey === feature.key)
            ?.billing || feature.billing,
        isEnabled:
          retailer?.features.find((retFeature) => retFeature.featureKey === feature.key)
            ?.isEnabled || false,
        isDefault:
          retailer?.features.find((retFeature) => retFeature.featureKey === feature.key)
            ?.isDefault || false,
      })),
    },
  });
  const { upsertRetailerConfigurations, loading: billingloading } =
    useUpsertRetailerConfigsMutation();
  const { upsertRetailerFeature, loading: featureLoading } = useUpsertRetailerFeatureMutation();
  useEffect(() => {
    if (orderEnhancementsFeatures.length && !formInitialized) {
      reset({
        retailerId: retailer.id,
        returnBilling: retailer.retailerConfiguration?.returnBilling,
        rtoBilling: retailer.retailerConfiguration?.rtoBilling,
        cardTransactionRate: retailer.retailerConfiguration?.cardTransactionRate,
        cashTransactionRate: retailer.retailerConfiguration?.cashTransactionRate,
        storageCount: retailer.retailerConfiguration?.storageCount,
        storageRate: retailer.retailerConfiguration?.storageRate,
        features: orderEnhancementsFeatures.map((feature) => ({
          featureKey: feature.key,
          billing:
            retailer?.features.find((retFeature) => retFeature.featureKey === feature.key)
              ?.billing || feature.billing,
          isEnabled:
            retailer?.features.find((retFeature) => retFeature.featureKey === feature.key)
              ?.isEnabled || false,
          isDefault:
            retailer?.features.find((retFeature) => retFeature.featureKey === feature.key)
              ?.isDefault || false,
        })),
      });
      setFormInitialized(true);
    }
  }, [orderEnhancementsFeatures, retailer?.features, formInitialized, reset]);

  const onSubmit = handleSubmit(async (data: FormValues) => {
    const billingFields: (keyof FormValues)[] = [
      'returnBilling',
      'rtoBilling',
      'storageRate',
      'storageCount',
      'cardTransactionRate',
      'cashTransactionRate',
    ];

    const isBillingDirty = billingFields.some(
      (field) =>
        watch(field) !==
        retailer.retailerConfiguration?.[field as keyof typeof retailer.retailerConfiguration]
    );

    const isFeaturesDirty =
      isDirty &&
      data.features.some((feature, index) => {
        const originalFeature = retailer?.features.find(
          (retFeature) => retFeature.featureKey === feature.featureKey
        );
        return (
          feature.billing !== originalFeature?.billing ||
          feature.isEnabled !== originalFeature?.isEnabled ||
          feature.isDefault !== originalFeature?.isDefault
        );
      });

    if (isBillingDirty) {
      await upsertRetailerConfigurations({
        payload: {
          retailerId: data.retailerId,
          defaultInvoiceType:
            retailer.retailerConfiguration?.defaultInvoiceType || InvoiceGenerationType.GENERAL,
          returnBilling: data.returnBilling ? Number(data.returnBilling) : 0,
          rtoBilling: data.rtoBilling ? Number(data.rtoBilling) : 0,
          storageRate: data.storageRate ? Number(data.storageRate) : 0,
          storageCount: data.storageCount ? Number(data.storageCount) : 0,
          cardTransactionRate: data.cardTransactionRate ? Number(data.cardTransactionRate) : 0,
          cashTransactionRate: data.cashTransactionRate ? Number(data.cashTransactionRate) : 0,
        },
        onCompleted: ({ upsertConfigurations }) => {
          if (upsertConfigurations.message) {
            toast.success(upsertConfigurations.message);
            reset(getValues());
          }
        },
      });
    }

    if (isFeaturesDirty) {
      await upsertRetailerFeature({
        payload: {
          retailerId: data.retailerId,
          features: data.features.map((item) => ({ ...item, billing: Number(item.billing) })),
        },
        onCompleted: ({ upsertRetailerFeatures }) => {
          if (upsertRetailerFeatures.message) {
            toast.success(upsertRetailerFeatures.message);
            reset(getValues());
          }
        },
      });
    }
  });
  useEffect(() => {
    if (isDirty) {
      setAllowToMove({ page: '', isAllowed: false });
    } else {
      setAllowToMove({ page: '', isAllowed: true });
    }
  }, [isDirty]);
  if (!orderEnhancementsFeatures.length) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box as="form" onSubmit={onSubmit} h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
        <VStack alignItems="start" w="100%" spacing={0}>
          <SectionRow
            title="Storage, Transactions, RTO and Return Fees"
            children={
              <>
                <Controller
                  name="storageCount"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => onChange(convertToNumber(e.target.value))}
                      placeholder="storage count (numeric)"
                      crossOrigin={undefined}
                      label="Storage Count"
                    />
                  )}
                />
                <Controller
                  name="storageRate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => onChange(convertToNumber(e.target.value))}
                      placeholder="storage rate (numeric)"
                      crossOrigin={undefined}
                      label="Storage Rate"
                    />
                  )}
                />
                <Controller
                  name="cardTransactionRate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => onChange(convertToNumber(e.target.value))}
                      placeholder="Card Transaction Rate (numeric)"
                      crossOrigin={undefined}
                      label="Card Transaction Rate"
                    />
                  )}
                />
                <Controller
                  name="cashTransactionRate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => onChange(convertToNumber(e.target.value))}
                      placeholder="Cash Transaction Rate (numeric)"
                      crossOrigin={undefined}
                      label="Cash Transaction Rate"
                    />
                  )}
                />
                <Controller
                  name="returnBilling"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => onChange(convertToNumber(e.target.value))}
                      placeholder="return billing slab (numeric)"
                      crossOrigin={undefined}
                      label="Return Billing Slab"
                    />
                  )}
                />
                <Controller
                  name="rtoBilling"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => onChange(convertToNumber(e.target.value))}
                      placeholder="RTO billing slab (numeric)"
                      crossOrigin={undefined}
                      label="RTO Billing Slab"
                    />
                  )}
                />
              </>
            }
          />
          {orderEnhancementsFeatures.map((feature, index) => (
            <SectionRow
              key={feature.key}
              title={feature.name}
              description={feature.description}
              children={
                <VStack alignItems="flex-start" w="100%" spacing="4">
                  <Box>
                    <Controller
                      name={`features.${index}.isEnabled`}
                      control={control}
                      defaultValue={
                        retailer?.features?.find(
                          (retFeature) => retFeature.featureKey === feature.key
                        )?.isEnabled || false
                      }
                      render={({ field: { onChange, value, ref } }) => (
                        <HStack>
                          <Switch
                            onChange={(e) => {
                              onChange(e);
                              if (!e.target.checked) {
                                setValue(`features.${index}.isDefault`, false);
                              }
                            }}
                            ref={ref}
                            isChecked={value}
                            fontWeight="medium"
                            color="gray.700"
                            fontSize="text-sm"
                            display="flex"
                            alignItems="center"
                            cursor="pointer"
                          >
                            Enabled
                          </Switch>
                        </HStack>
                      )}
                    />
                    <Controller
                      name={`features.${index}.featureKey`}
                      control={control}
                      defaultValue={feature.key}
                      render={({ field }) => (
                        <SBInput type="hidden" {...field} crossOrigin={undefined} />
                      )}
                    />
                  </Box>

                  <Controller
                    name={`features.${index}.isDefault`}
                    control={control}
                    defaultValue={
                      retailer?.features?.find(
                        (retFeature) => retFeature.featureKey === feature.key
                      )?.isDefault || false
                    }
                    render={({ field: { onChange, value, ref } }) => (
                      <HStack>
                        <Switch
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          fontWeight="medium"
                          color="gray.700"
                          fontSize="text-sm"
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                          isDisabled={!watch(`features.${index}.isEnabled`)}
                        >
                          On by default
                        </Switch>
                      </HStack>
                    )}
                  />

                  <HStack justify={'space-around'} w="100%">
                    <Controller
                      name={`features.${index}.billing`}
                      control={control}
                      defaultValue={
                        retailer?.features?.find(
                          (retFeature) => retFeature.featureKey === feature.key
                        )?.billing || feature.billing
                      }
                      render={({ field: { value, ref, onChange } }) => (
                        <SBInput
                          type="number"
                          value={String(value)}
                          ref={ref}
                          onChange={onChange}
                          crossOrigin={undefined}
                          label="Billing Fee"
                        />
                      )}
                    />
                  </HStack>
                </VStack>
              }
            />
          ))}
        </VStack>

        <SubmitAndCancel
          isHidden={isDirty}
          reset={reset}
          loading={billingloading || featureLoading}
        />
      </VStack>
    </Box>
  );
}
