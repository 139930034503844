import { Box, Spacer, Text, VStack } from '@swftbox/style-guide';

export const InvoiceFooter = () => {
  return (
    <VStack align="start" textAlign="left">
      <Spacer />
      <Text>Payment Instructions:</Text>
      <Spacer />
      <Text>Payment to be deposited into bank account as follows:</Text>
      <Spacer />
      <Box ml={10} pl={10}>
        <Text>Account Name: SWFTBOX LIMITED</Text>
        <Text>Account Number: 019100649300</Text>
        <Text>IBAN: AE770330000019100649300</Text>
        <Text>SWIFT Code: BOMLAEAD</Text>
        <Text>Currency AED</Text>
        <Text>Bank Mashreq Bank</Text>
      </Box>
      <Spacer />
      <Text>PLEASE SIGN & STAMP ACCORDINGLY</Text>
      <Text>Date Delivered</Text>
      <Text>Recipient Name:</Text>
      <Text>Receiver Signature:</Text>
    </VStack>
  );
};
