import { gql } from '@apollo/client';

export const GET_PAYMENTS = gql`
  query GetPayments(
    $page: Int
    $perPage: Int
    $status: PaymentStatus
    $type: PaymentType
    $orderBy: String
    $order: OrderSort
    $driverId: UUID
    $retailerId: UUID
    $orderSwftboxTracking: String
    $startDate: DateTime
    $endDate: DateTime
    $provider: Boolean
  ) {
    paginatedPayments(
      page: $page
      perPage: $perPage
      status: $status
      type: $type
      orderBy: $orderBy
      order: $order
      driverId: $driverId
      retailerId: $retailerId
      startDate: $startDate
      endDate: $endDate
      orderSwftboxTracking: $orderSwftboxTracking
      provider: $provider
    ) {
      pageCount
      data {
        id
        driverId
        orderId
        orderSwftboxTracking
        type
        amount
        provider
        currency
        receipt
        createdAt
        approvedBy
        approvedAt
        driver {
          id
          user {
            name
          }
        }
        retailer {
          id
          name
        }
        order {
          id
          retailerTracking
          reference
        }
        status
      }
      total
      currentPage
      nextPage
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation createPayment($createPaymentInput: CreatePaymentInput!) {
    createPayment(createPaymentInput: $createPaymentInput) {
      id
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation UpdatePayment($updatePaymentInput: UpdatePaymentInput!) {
    updatePayment(updatePaymentInput: $updatePaymentInput) {
      id
    }
  }
`;

export const REMOVE_PAYMENT = gql`
  mutation RemovePayment($id: UUID!) {
    removePayment(id: $id) {
      message
      success
    }
  }
`;

export const GET_PAYMENT_TIMELINE = gql`
  query PaymentTimelines($paymentId: UUID!) {
    paymentTimelines(paymentId: $paymentId) {
      date
      action
      paymentUpdates
      user {
        name
      }
    }
  }
`;

export const GET_PURCHASES = gql`
  query PaginatedPurchases(
    $page: Int
    $perPage: Int
    $endDate: DateTime
    $startDate: DateTime
    $provider: String
    $status: String
    $keyword: String
    $entityId: UUID
    $referenceId: UUID
    $referenceType: PurchaseReferenceType
    $date: Date
    $limit: Int
    $paginatedPurchasesId: [ID!]
    $success: Boolean
    $orderBy: String
    $order: OrderSort
  ) {
    paginatedPurchases(
      page: $page
      perPage: $perPage
      endDate: $endDate
      startDate: $startDate
      provider: $provider
      status: $status
      keyword: $keyword
      entityId: $entityId
      referenceId: $referenceId
      referenceType: $referenceType
      date: $date
      limit: $limit
      id: $paginatedPurchasesId
      success: $success
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        createdAt
        updatedAt
        deletedAt
        entityId
        entityType
        referenceId
        referenceType
        amount
        currency
        paymentMethod
        paymentGateway
        redirectUrl
        description
        transactionId
        retailer {
          name
        }
        customer {
          phone
          name
        }
        order {
          swftboxTracking
        }
        provider
        success
      }
      total
      pageCount
      currentPage
      perPage
      prevPage
      nextPage
    }
  }
`;
