import * as yup from 'yup';

export const UpdateOrderCustomerSchema = yup.object().shape({
  phone: yup.string().trim().required('Phone number is required'),
  name: yup
    .string()
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must be at most 50 characters')
    .trim()
    .required('Name is required'),
  email: yup.string().email('Invalid email').nullable().optional(),
});

export const UpdateOrderSchema = yup.object().shape({
  requireDeliveryNote: yup.boolean().optional(),
  packageCount: yup
    .number()
    .typeError('Package count must be a number')
    .positive('Package count must be positive')
    .min(1, 'At least one package is required')
    .required('Package count is required'),
  notes: yup
    .string()
    .max(500, 'Notes cannot be longer than 500 characters')
    .nullable()
    .optional(),
});

export const UpdateLocationSchema = yup.object().shape({
  city: yup.string().min(1, 'Too short').required('City is required'),
  country: yup.string().min(1, 'Too short!').required('Country is required'),
  addressLine1: yup
    .string()
    .min(10, 'Must exceed 9 characters')
    .max(100, 'Too long, try shorter one')
    .required('Address line 1 is required'),
  coordinates: yup
    .object()
    .shape({
      latitude: yup.number().required(),
      longitude: yup.number().required(),
    })
    .optional(),
});

