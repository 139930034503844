import { useQuery } from '@apollo/client';
import { GET_FEEDBACK_QUESTIONS_QUERY, type Question } from '../Queries';

interface QuestionsResponse {
  FeedbackQuestion: Question[];
}

export function useFeedbackQuestions() {
  const { data, loading } = useQuery<QuestionsResponse>(GET_FEEDBACK_QUESTIONS_QUERY);

  return {
    questions: data?.FeedbackQuestion ?? [],
    loading,
  };
}
