import { useLazyQuery } from '@apollo/client';
import { QueriesStore } from '../queriesStore';
import { SortOptions } from '../SharedTypes';
import { GET_USERS } from './users.graphql';
import {
  AccountStatus,
  UserRoles,
  UserSortableFields,
  type User,
  type RetailerUserRole,
} from './users.types';
import { useCallback } from 'react';

interface QueryResponse {
  paginatedUsers: {
    data: User[];
    total: number;
    pageCount: number;
  };
}

interface InitialUserQueryVariables {
  perPage: number;
  order?: SortOptions;
  orderBy?: UserSortableFields;
}

export interface UsersQueryVariables extends InitialUserQueryVariables {
  page: number;
  perPage: number;
  roles?: UserRoles[];
  status?: AccountStatus;
  excludeRoles?: UserRoles[];
  keyword?: string;
  retailerUserRoles?: RetailerUserRole[];
  retailerId?: string;
}

export function useUsersQuery(initialVariables?: UsersQueryVariables) {
  const [query, { data, loading, error }] = useLazyQuery<QueryResponse>(GET_USERS, {
    variables: initialVariables,
  });

  const getUsers = useCallback(
    (variables: Partial<UsersQueryVariables>) => {
      QueriesStore.update('GET_USERS', { ...variables, page: 1, ...initialVariables });
      void query({
        variables,
      });
    },
    [initialVariables, query]
  );

  return {
    getUsers,
    users: data?.paginatedUsers?.data ?? [],
    totalUsers: data?.paginatedUsers?.total ?? 0,
    pageCount: data?.paginatedUsers?.pageCount ?? 0,
    loading,
    error,
  };
}
