import EmptyPaper from 'src/assets/emptyPaper.svg';
import SVG from 'react-inlinesvg';
import { Text, HStack, Button, SBAddIcon, Box, Grid } from '@swftbox/style-guide';

interface EmptyTableProps {
  title?: string;
  description?: string;
  handleClear?: () => void;
  handleSubmit?: () => void;
  icon?: string;
}
export const EmptyTable = ({
  title = 'No Records Found',
  description = 'There is No Record Created, or no Result Match You Search',
  handleClear,
  handleSubmit,
  icon = EmptyPaper,
}: EmptyTableProps) => {
  return (
    <Box minW="300px">
      <HStack justifyContent="center">
        <SVG src={icon} />
      </HStack>
      <Box textAlign="center">
        <Text fontSize="text-md" fontWeight="semibold">
          {title}
        </Text>
        <Text mb={6} fontSize="text-sm" color="gray.600" w="75%" mx="auto">
          {description}
        </Text>
        <Grid gap="3" gridTemplateColumns="repeat(auto-fit,minmax(45%,1fr))">
          {!!handleClear && (
            <Button variant="outline" color="neutral.normal">
              Clear Search
            </Button>
          )}
          {!!handleSubmit && (
            <Button>
              <SBAddIcon width="15px" /> New order
            </Button>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
