import { useLazyQuery } from '@apollo/client';
import { GET_PAYMENT_TIMELINE } from './payments.graphql';

interface QueryResponse {
  paymentTimelines: Array<{
    action: string;
    date: string;
    paymentUpdates: string[];
    user: {
      name: string;
    };
  }>;
}

interface QueryArgs {
  paymentId: string;
}

export function usePaymentHistory() {
  const [getPaymentTimeline, { data, loading }] = useLazyQuery<QueryResponse, QueryArgs>(
    GET_PAYMENT_TIMELINE
  );

  return {
    getPaymentTimeline,
    paymentTimeline: data?.paymentTimelines,
    loading,
  };
}
