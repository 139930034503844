import { type ReactNode, useReducer } from 'react';
import {
  JobsFiltersActionsContext,
  JobsFiltersContext,
} from 'src/components/Particles/Contexts/Jobs';
import { rootReducer } from './Filters';

export const initialJobFiltersRoot = {
  organizedFilters: {},
};

export function JobFiltersProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(rootReducer, initialJobFiltersRoot);

  return (
    <JobsFiltersActionsContext.Provider value={dispatch}>
      <JobsFiltersContext.Provider value={state}>{children} </JobsFiltersContext.Provider>
    </JobsFiltersActionsContext.Provider>
  );
}
