import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconWText,
  SBAddIcon,
  Text,
  Tooltip,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import VerifyIcon from 'src/assets/icons/General/verify.svg';
import { type ReactNode, useCallback, useState, useMemo } from 'react';
import SVG from 'react-inlinesvg';
import JobIcon from 'src/assets/icons/orders/job.svg';
import OptimisedIcon from 'src/assets/icons/General/optimized-route.svg';
import NotRoutedIcon from 'src/assets/icons/General/not-routed.svg';
import { AssignDriver, JobStepperHistory, NavigationTabs } from 'src/components/Organisms';
import {
  type SequenceStops,
  useReorderStops,
  useJob,
  type JobOrder as JobOrderType,
  useAssignJob,
  useRouteTimelinesQuery,
  useOptimizeRoute,
  OptimizerStatus,
  JobStatus,
  useJobAdherence,
} from 'src/components/Particles/resolvers/Jobs';
import { getJobStatusLabel, getJobStatusColor } from '../../Helper';
import { JobMapAndStops } from './Components';
import { JobOrder } from './Components/JobOrders';
import { AllStopsOrders } from './Components/ViewAllStopsOrders';
import { JobActions } from './JobActions';
import { JobDetailsMap } from './Components/JobDetailsMap';
import ChilledIcon from 'src/assets/icons/chilled.svg';
import { DriverAdherence } from './Components/DriverAdherence';

interface JobDetailsProps {
  id: string;
  OpenButton: ReactNode;
  disabled?: boolean;
  btnW?: string;
}

enum tabsIds {
  stopsView = 'STOPS_VIEW',
  mapView = 'MAP_VIEW',
  history = 'HISTORY',
  adherence = 'ADHERENCE',
}

export const JobDetails = ({ id, OpenButton, disabled, btnW = 'auto' }: JobDetailsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStop, setSelectedStop] = useState<SequenceStops>();
  const [activeTab, setActiveTab] = useState(tabsIds.stopsView);
  const handleTabChange = (id: string) => {
    setActiveTab(id as tabsIds);
  };
  const { assign } = useAssignJob();

  const { optimizeRoute, loading: isOptimizingRoute } = useOptimizeRoute();

  const viewOrdersModal = useDisclosure();
  const viewAllStopsOrdersModal = useDisclosure();

  const [newStopSequences, setNewStopSequences] = useState<number[]>();
  const { reorder, loading } = useReorderStops();
  const { job, query, allOrder } = useJob({ id });
  const {
    getRouteTimeline,
    routeTimeline,
    loading: routeTimelineLoading,
  } = useRouteTimelinesQuery();

  const { locationAdherencePercentage, sequenceAdherencePercentage } = useJobAdherence(job!);

  const onDriverAssignment = useCallback(
    async (driverId: string, routeId?: string) => {
      try {
        if (!routeId) return false;
        await assign({
          payload: { driverId, routeId },
          onCompleted: (message) => {
            toast.success(message);
          },
        });
        return true;
      } catch {
        return false;
      }
    },
    [assign]
  );

  const viewTabs = useMemo(() => {
    return [
      { name: 'Stops View', id: tabsIds.stopsView },
      { name: 'Map View', id: tabsIds.mapView },
      { name: 'History', id: tabsIds.history },
      ...(job?.status! === JobStatus.COMPLETED
        ? [{ name: 'Driver Adherence', id: tabsIds.adherence }]
        : []),
    ];
  }, [job]);

  const handleOpenOrderModal = (item: SequenceStops) => {
    setSelectedStop(item);
    viewOrdersModal.onOpen();
  };
  const handleCloseOrderModal = () => {
    setSelectedStop(undefined);
    viewOrdersModal.onClose();
    setActiveTab(tabsIds.stopsView);
  };
  const handleOpenAllStopsOrderModal = () => {
    viewAllStopsOrdersModal.onOpen();
  };
  const handleCloseAllStopsOrderModal = () => {
    setSelectedStop(undefined);
    viewAllStopsOrdersModal.onClose();
  };
  const onSortChange = useCallback((sequences?: number[]) => {
    setNewStopSequences(sequences);
  }, []);

  const onSave = () => {
    if (!newStopSequences) return;

    reorder({
      payload: {
        routeId: job?.id,
        newSequence: newStopSequences,
      },
      onCompleted: (message) => {
        toast.success(message);
      },
    }).catch(console.log);
  };

  function performOptimizeRoute(routeId: string) {
    void optimizeRoute({
      onCompleted: (data) => {
        toast.success(data.optimizeRoute.message);
      },
      payload: {
        routeId,
      },
    });
  }

  const handleSort = (timelines?: any) => {
    if (!timelines?.length) return [];
    const timeLineNotSorted = [...timelines];
    const sortedTimelines = timeLineNotSorted.sort(function (a, b) {
      return (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any);
    });
    return sortedTimelines;
  };

  const getJob = () => {
    void query();
    void getRouteTimeline({ variables: { routeId: id } });
    onOpen();
  };

  const optimiserLoading = isOptimizingRoute || job?.optimizerStatus === OptimizerStatus.requested;

  const routeIcon =
    job?.optimizerStatus === OptimizerStatus.completed
      ? OptimisedIcon
      : job?.optimizerStatus === OptimizerStatus.failed
      ? NotRoutedIcon
      : null;

  return (
    <>
      <Button
        onClick={getJob}
        variant="link"
        _hover={{ textDecor: 'none' }}
        isDisabled={disabled}
        width={btnW}
        maxW="100%"
      >
        {OpenButton}
      </Button>
      <Drawer
        isOpen={isOpen && !!job?.id}
        placement="right"
        onClose={onClose}
        size={{ base: 'full', md: 'xl' }}
        id={(isOpen && 'addNewJob') || 'i'}
      >
        <DrawerOverlay />
        <DrawerContent maxWidth={{ base: '100%', md: '75% !important' }}>
          <DrawerCloseButton zIndex={100} />
          <Box justifyContent="space-around" borderRadius="8px" px="6">
            <NavigationTabs handleChange={handleTabChange} tabs={viewTabs} active={activeTab} />
          </Box>
          <DrawerHeader pb="0" pt="0">
            <HStack justifyContent="space-between" bg="gray.100" borderRadius="8px" p="3">
              <HStack gap="4">
                <HStack>
                  <Text fontWeight="semibold" fontSize="text-md" as={'span'}>
                    {job?.reference}
                  </Text>
                  {routeIcon ? <SVG src={routeIcon} width="25px" height="20px" /> : ''}
                </HStack>
                <Button
                  bg="#63C3EC"
                  _hover={{ opacity: '0.8' }}
                  onClick={handleOpenAllStopsOrderModal}
                >
                  View Orders
                </Button>
                {!!job?.hasChilledOrders && (
                  <Tooltip label={'Chilled orders'} hasArrow>
                    <Box
                      bg="blue.500"
                      py="1"
                      px="3"
                      borderRadius="4"
                      display="flex"
                      w="max-content"
                      color="primary.900"
                    >
                      <SVG src={ChilledIcon} width={'15px'} />
                    </Box>
                  </Tooltip>
                )}
                <Text fontSize="text-sm" fontWeight="semibold" color="gray.500">
                  {job?.extraProps?.stopsCount ?? 0} Stops (
                  {(job?.extraProps?.stopsCount ?? 0) - (job?.extraProps?.remainingStopsCount ?? 0)}{' '}
                  Completed)
                </Text>
              </HStack>

              <HStack>
                <AssignDriver
                  fontSize="text-sm"
                  color="gray.500"
                  id={job?.id}
                  onAssign={onDriverAssignment}
                  entityName="job"
                  openButton={
                    <IconWText
                      text="Assign"
                      spacing="20px"
                      aria-label="assign a driver"
                      Icon={<SBAddIcon width="15px" />}
                    />
                  }
                >
                  <Button
                    bg="#EFEFEF"
                    minW="110px"
                    p="1"
                    borderRadius="8px"
                    variant="link"
                    border="1px solid"
                    minH="28px"
                  >
                    <Tooltip label={job?.driver?.user?.phone}>
                      <HStack w="100%" justifyContent="space-between" color="gray.900">
                        <SVG src={JobIcon} fill="currentColor" />
                        <Text>{job?.driver?.user?.name ?? '-'}</Text>
                        <div />
                      </HStack>
                    </Tooltip>
                  </Button>
                </AssignDriver>
                <Badge
                  p="4px 12px"
                  fontWeight="medium"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="8px"
                  fontSize="text-sm"
                  variant="solid"
                  letterSpacing="1px"
                  minH="28px"
                  colorScheme={getJobStatusColor(job?.status)}
                >
                  {getJobStatusLabel(job?.status)}
                </Badge>
              </HStack>
              {!!job?.sequenceStops.length && job?.sequenceStops.length > 2 && (
                <Button
                  variant="outline"
                  minW="120px"
                  disabled={
                    isOptimizingRoute ||
                    ![OptimizerStatus.pending, OptimizerStatus.failed].includes(
                      job.optimizerStatus as OptimizerStatus
                    )
                  }
                  onClick={() => {
                    performOptimizeRoute(id);
                  }}
                  isLoading={optimiserLoading}
                  loadingText="Optimizing..."
                  leftIcon={
                    job.optimizerStatus === OptimizerStatus.completed ? (
                      <SVG src={VerifyIcon} width="14px" height="14px" fill="black" />
                    ) : undefined
                  }
                >
                  Optimize Job
                </Button>
              )}
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            {activeTab === tabsIds.stopsView && (
              <JobMapAndStops
                job={job}
                handleOpenOrderDrawer={handleOpenOrderModal}
                onSortChange={onSortChange}
              />
            )}
            {activeTab === tabsIds.mapView && <JobDetailsMap stops={job?.sequenceStops ?? []} />}
            {activeTab === tabsIds.history && (
              <JobStepperHistory
                steps={
                  handleSort(routeTimeline).filter((item, index, arr) => {
                    return index === 0 || item.action !== arr[index - 1].action;
                  }) ?? []
                }
                loading={routeTimelineLoading}
              />
            )}
            {activeTab === tabsIds.adherence && <DriverAdherence job={job} />}
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <HStack justify="space-between" w="100%">
              <HStack>
                <JobActions job={job} />
                {job?.status === JobStatus.COMPLETED && activeTab === tabsIds.adherence && (
                  <>
                    <Badge colorScheme="blue" p="4px 12px">
                      Sequence Adherence: {sequenceAdherencePercentage}%
                    </Badge>
                    <Badge colorScheme="green" p="4px 12px">
                      Location Adherence: {locationAdherencePercentage}%
                    </Badge>
                  </>
                )}
              </HStack>

              <HStack>
                <Button variant="outline" minW="120px" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  disabled={!newStopSequences}
                  isLoading={loading}
                  onClick={onSave}
                  minW="120px"
                >
                  Save
                </Button>
              </HStack>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <JobOrder
        sequenceStops={selectedStop}
        isOpen={viewOrdersModal.isOpen}
        onClose={handleCloseOrderModal}
      />

      <AllStopsOrders
        allOrders={(allOrder as JobOrderType[]) || []}
        routeId={job?.id ?? ''}
        isOpen={viewAllStopsOrdersModal.isOpen}
        onClose={handleCloseAllStopsOrderModal}
      />
    </>
  );
};
