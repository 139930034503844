export const Config = {
  GRAPHQL_ENDPOINT: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  TRACKING_APP_URL: import.meta.env.VITE_TRACKING_APP_URL,
  PRIMEXPRESS_BUILD: Boolean(import.meta.env.VITE_PRIMEXPRESS_BUILD),
  INTEGRATIONS_ENDPOINT: import.meta.env.VITE_INTEGRATIONS_ENDPOINT,
  DEFAULT_MAP_CENTER: {
    lat: import.meta.env.VITE_PRIMEXPRESS_BUILD ? 29.2303844 : 25.1696803,
    lng: import.meta.env.VITE_PRIMEXPRESS_BUILD ? 47.8482556 : 55.3724993,
  },
  Firebase: {
    API_KEY: import.meta.env.VITE_API_KEY,
    AUTH_DOMAIN: import.meta.env.VITE_AUTH_DOMAIN,
    DATABASE_URL: import.meta.env.VITE_DATABASE_URL,
    FOOTPRINTS_COLLECTION: import.meta.env.VITE_FOOTPRINTS_COLLECTION,
    PROJECT_ID: import.meta.env.VITE_PROJECT_ID,
    STORAGE_BUCKET: import.meta.env.VITE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: import.meta.env.VITE_MESSAGING_SENDER_ID,
    APP_ID: import.meta.env.VITE_APP_ID,
  },
};
