import { gql } from '@apollo/client';

export const GET_PICKUP_PROFILES = gql`
  query GetPickupProfiles($retailerId: UUID!) {
    pickupProfiles(retailerId: $retailerId, orderBy: "createdAt") {
      id
      retailerId
      day
      cutoffTime
      createdAt
      slot {
        from
        to
      }
    }
  }
`;

export const CREATE_PICKUP_PROFILE = gql`
  mutation CreatePickupProfile($createPickupProfileInput: CreatePickupProfileInput!) {
    createPickupProfile(createPickupProfileInput: $createPickupProfileInput) {
      message
    }
  }
`;

export const UPDATE_PICKUP_PROFILE = gql`
  mutation UpdatePickupProfile($updatePickupProfileInput: UpdatePickupProfileInput!) {
    updatePickupProfile(updatePickupProfileInput: $updatePickupProfileInput) {
      message
      data {
        id
        retailerId
        day
        cutoffTime
        createdAt
        slot {
          from
          to
        }
      }
    }
  }
`;

export const REMOVE_PICKUP_PROFILE = gql`
  mutation RemovePickupProfile($id: UUID!) {
    removePickupProfile(id: $id) {
      message
      success
    }
  }
`;
