import {
  Badge,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  SBModal,
  Text,
  VStack,
} from '@swftbox/style-guide';
import { AddressCard, SchedulingCard } from 'src/components/Organisms';
import { stateBadgeColor, useOrderModalActions } from 'src/components/Particles';
import { type SequenceStops } from 'src/components/Particles/resolvers/Jobs';
import { OrderRow } from './ViewAllStopOrders/OrderRow';

interface JobOrderProps {
  sequenceStops?: SequenceStops;
  isOpen: boolean;
  onClose: () => void;
}

export const JobOrder = ({ sequenceStops, isOpen, onClose }: JobOrderProps) => {
  return (
    <>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="general"
        size="6xl"
        isCentered={false}
        header={
          <HStack justifyContent="space-between" my="3" bg="gray.100" borderRadius="8px" p="3">
            <HStack fontSize="text-sm" flexWrap="nowrap">
              <Box
                w="27px"
                lineHeight="27px"
                h="27px"
                color="#fff"
                bg={
                  +(sequenceStops?.completedCount || 0) === +(sequenceStops?.totalCount || 1)
                    ? '#01BA76'
                    : 'gray.900'
                }
                borderRadius="50%"
                textAlign="center"
              >
                {sequenceStops?.sequence}
              </Box>
              <Box fontSize="text-sm" lineHeight="1.2">
                <Text>{sequenceStops?.label.split(' ').at(0)} </Text>
                <Text>{sequenceStops?.label.split(' ').at(1)} </Text>
              </Box>
            </HStack>
            <Badge
              p="12px 30px"
              fontWeight="semibold"
              borderRadius="8px"
              fontSize="text-md"
              variant="solid"
              borderColor={
                stateBadgeColor(sequenceStops?.status as string, '', false).border ?? 'transparent'
              }
              bg={stateBadgeColor(sequenceStops?.status as string, '', false).bg}
              color={stateBadgeColor(sequenceStops?.status as string, '', false).color}
            >
              {stateBadgeColor(sequenceStops?.status as string, '', false).text}
            </Badge>
          </HStack>
        }
        body={
          <Box bg="gray.100" borderRadius="8px" p="3" minW="fit-content">
            <Grid
              gridTemplateColumns="160px 200px 180px 280px"
              gridAutoFlow="row"
              justifyContent="space-between"
              color="gray.700"
              fontWeight="semibold"
              borderBottom="1px solid"
              borderColor="gray.500"
              gap="3"
              py="3"
              mb="5"
              fontSize="text-sm"
              position="sticky"
              top="-8px"
              bg="gray.100"
              zIndex="3"
            >
              <GridItem>Stop and action</GridItem>
              <GridItem>Status</GridItem>
              <GridItem>Timing</GridItem>
              <GridItem>Address</GridItem>
            </Grid>
            <VStack w="100%">
              {sequenceStops?.pickupOrders.map((order) => {
                return (
                  <OrderRow
                    order={order}
                    sequenceStops={sequenceStops}
                    isPickup={!!sequenceStops?.pickupOrders.length}
                  />
                );
              })}
              {sequenceStops?.dropOrders.map((order) => {
                return <OrderRow order={order} sequenceStops={sequenceStops} isPickup={false} />;
              })}
            </VStack>
          </Box>
        }
        footer={
          <Button variant="outline" mr={3} onClick={onClose}>
            Back
          </Button>
        }
      />
    </>
  );
};
