import { useMutation } from '@apollo/client';
import { clientOptionType } from '../../../SharedTypes';
import {
  ADD_FULFILLMENT_QUERY,
  GET_FULFILLMENTS_QUERY,
  type AddFulfillmentInput,
} from '../Queries';

interface AddFulfillmentResponse {
  message: string;
}
interface CallAddFulfillmentProps {
  payload: AddFulfillmentInput;
  onCompleted: (
    data: { createFulfillment: AddFulfillmentResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useAddFulfillment() {
  const [mutate, { loading, error }] = useMutation<
    { createFulfillment: AddFulfillmentResponse },
    { createFulfillmentInput: AddFulfillmentInput }
  >(ADD_FULFILLMENT_QUERY);

  async function callAddFulfillment({ payload, onCompleted }: CallAddFulfillmentProps) {
    await mutate({
      variables: {
        createFulfillmentInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_FULFILLMENTS_QUERY,
        },
      ],
    });
  }

  return {
    callAddFulfillment,
    loading,
    error,
  };
}
