import { Helmet } from "react-helmet";
import { ErrorBoundary } from "src/components/Particles";
import { TasksPage } from "./tasks.page";

export function TasksPageWrapper() {
	return (
		<>
			<Helmet>
				<title> Tasks </title>
			</Helmet>
			<ErrorBoundary>
				<TasksPage />
			</ErrorBoundary>
		</>
	)
}
