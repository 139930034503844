import { HStack, SBModal, useDisclosure, Button, SBAddIcon } from '@swftbox/style-guide';
import { DriverForm } from '../Forms/Driver.form';

const AddDriverModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onConfirm() {}

  return (
    <HStack spacing="30px">
      <Button onClick={onOpen} fontSize="x-small">
        <SBAddIcon width="15px" /> Add Driver
      </Button>

      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header="Add Driver"
        size="2xl"
        body={<DriverForm mode="create" onClose={onClose} />}
        bordered={false}
        footer={<></>}
        handleConfirm={onConfirm}
      />
    </HStack>
  );
};

export default AddDriverModal;
