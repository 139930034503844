import { gql } from '@apollo/client';

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {
    createInvoice(createInvoiceInput: $createInvoiceInput) {
      data {
        id
      }
      message
    }
  }
`;

export const LIST_INVOICES = gql`
  query PaginatedInvoices(
    $status: [InvoiceStatus!]
    $retailerId: [UUID!]
    $keyword: String
    $startDate: DateTime
    $endDate: DateTime
    $page: Int
    $perPage: Int
    $orderBy: String
    $order: OrderSort
  ) {
    paginatedInvoices(
      status: $status
      retailerId: $retailerId
      keyword: $keyword
      startDate: $startDate
      endDate: $endDate
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        createdAt
        updatedAt
        deletedAt
        retailerId
        invoiceNumber
        poNumber
        from
        to
        type
        activeAt
        dueDateInDays
        lineItemsTotal
        addTotal
        lessTotal
        netTotal
        status
        lineItems {
          id
          createdAt
          updatedAt
          deletedAt
          invoiceId
          code
          description
          quantity
          rate
          taxableAmount
          vat
          vatAmount
          total
          type
        }
        retailer {
          id
          name
          billingInfo {
            address
            email
            phone
            contactName
          }
        }
      }
      total
      pageCount
      currentPage
    }
  }
`;

export const INVOICE_BY_ID = gql`
  query InvoiceById($idInput: IdInput!) {
    invoiceById(IdInput: $idInput) {
      id
      createdAt
      updatedAt
      deletedAt
      retailerId
      invoiceNumber
      poNumber
      from
      to
      type
      activeAt
      dueDateInDays
      lineItemsTotal
      addTotal
      lessTotal
      netTotal
      status
      lineItems {
        id
        createdAt
        updatedAt
        deletedAt
        invoiceId
        code
        description
        quantity
        rate
        taxableAmount
        vat
        vatAmount
        total
        type
      }
      retailer {
        id
        name
      }
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($updateInvoiceInput: UpdateInvoiceInput!) {
    updateInvoice(UpdateInvoiceInput: $updateInvoiceInput) {
      data {
        id
      }
      message
    }
  }
`;

export const UPDATE_INVOICE_STATUS = gql`
  mutation ChangeInvoiceStatus($changeInvoiceStatusInput: ChangeInvoiceStatusInput!) {
    changeInvoiceStatus(ChangeInvoiceStatusInput: $changeInvoiceStatusInput) {
      data {
        id
      }
      message
    }
  }
`;

export const REGENERATE_INVOICE = gql`
  mutation RegenerateInvoice($idInput: IdInput!) {
    regenerateInvoice(IdInput: $idInput) {
      data {
        id
      }
      message
    }
  }
`;

export const BULK_CREATE_INVOICES = gql`
  mutation BulkCreateInvoice($bulkCreateInvoiceInput: BulkCreateInvoiceInput!) {
    bulkCreateInvoice(bulkCreateInvoiceInput: $bulkCreateInvoiceInput) {
      message
    }
  }
`;
