import { Box, Button, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import { useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import {
  type Order,
  OrderStage,
  OrderState,
  useUndoRTO,
} from 'src/components/Particles/resolvers/orders';

export function UndoRTO() {
  const { undoRTOIsOpen, data: order } = useOrderModalContext() as {
    undoRTOIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    dispatch({ type: 'CLOSE_UNDO_RTO' });
  };

  const { markOrder, loading } = useUndoRTO();

  const { stage, swftboxTracking, state } = order;

  if (stage !== OrderStage.rto || state !== OrderState.warehouse) {
    return null;
  }

  function onConfirm() {
    void markOrder({
      payload: { ids: [order.id] },
      onCompleted: ({ undoRTO }) => {
        if (undoRTO.message) {
          handleCloseModal(undoRTO.message);
        }
      },
    });
  }

  return (
    <>
      <SBModal
        isOpen={undoRTOIsOpen}
        onClose={handleCloseModal}
        type="general"
        header={
          <Box pb="1vh">
            <Text>Undo RTO</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to undo RTO mark for order
            <Text as="span" fontWeight="semibold">
              {swftboxTracking}
            </Text>{' '}
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button
              variant="outline"
              w="100%"
              onClick={() => {
                dispatch({ type: 'CLOSE_UNDO_RTO' });
              }}
            >
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
