import { useMutation } from '@apollo/client';
import { type clientOptionType } from '../../SharedTypes';
import { QueriesStore } from '../../queriesStore';
import {
  type CreateTaskInput,
  CREATE_TASK,
  type GeneralMessageResponse,
  GET_PAGINATED_TASKS,
} from '../Queries';

interface CreateTaskProps {
  payload: CreateTaskInput;
  onCompleted: (
    data: { createTask: GeneralMessageResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useCreateTaskMutation() {
  const [mutate, { loading, error }] = useMutation<
    { createTask: GeneralMessageResponse },
    { createTaskInput: CreateTaskInput }
  >(CREATE_TASK);

  async function createTask({ payload, onCompleted }: CreateTaskProps) {
    return mutate({
      variables: {
        createTaskInput: payload,
      },
      onCompleted,
      refetchQueries: [
        {
          query: GET_PAGINATED_TASKS,
          variables: QueriesStore.get('GET_TASKS'),
        },
      ],
    });
  }

  return {
    createTask,
    loading,
    error,
  };
}
