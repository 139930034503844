import { Box, Stack } from '@swftbox/style-guide';
import { useReducer, useState, type FC } from 'react';
import {
  useCategories,
  useCreateRetailerMutation,
  useFulfillments,
  type CreateRetailerInput,
} from 'src/components/Particles';
import { StepFiveForm, StepFourForm, StepOneForm, StepThreeForm, StepTwoForm } from './Forms';
import {
  StepFivePayloadProps,
  initialPayload,
  type CreateRetailerFormState,
  type CreateRetailerFormStateStep,
  type StepFourPayloadProps,
  type StepOnePayloadProps,
  type StepThreePayloadProps,
  type StepTwoPayloadProps,
} from './Helper';

interface FormSideProps {
  step: number;
  next: () => void;
  toStep: (step: number) => void;
  onDone: (message?: string) => void;
}

type ReducerFormFunction = (
  prev: CreateRetailerFormState,
  next: CreateRetailerFormStateStep
) => CreateRetailerFormState;

const formReducer: ReducerFormFunction = (prev, next) => ({ ...prev, ...next });

const FormSide: FC<FormSideProps> = ({ step, next, toStep, onDone }) => {
  const [disabledSteps, setDisabledSteps] = useState([2, 3, 4, 5]);

  const { categories, loading: categoriesLoading } = useCategories();
  const { fulfillments, loading: fulfillmentsLoading } = useFulfillments();
  const { createRetailer, loading } = useCreateRetailerMutation();

  const [payload, dispatch] = useReducer<ReducerFormFunction>(formReducer, initialPayload);

  function onStepOneDone(payload: StepOnePayloadProps) {
    dispatch({ ...payload });
    setDisabledSteps((prev) => prev.filter((disabledStep) => disabledStep !== 2));
    next();
  }

  function onStepTwoDone(payload: StepTwoPayloadProps) {
    dispatch({ ...payload });
    setDisabledSteps((prev) => prev.filter((disabledStep) => disabledStep !== 3));
    next();
  }

  function onStepThreeDone(payload: StepThreePayloadProps) {
    dispatch({ ...payload });
    setDisabledSteps((prev) => prev.filter((disabledStep) => disabledStep !== 4));
    next();
  }

  function onStepFourDone(payload: StepFourPayloadProps) {
    dispatch({ ...payload });
    setDisabledSteps((prev) => prev.filter((disabledStep) => disabledStep !== 5));
    next();
  }

  function onStepFiveDone(props: StepFivePayloadProps) {
    const createRetailerPayload: CreateRetailerInput = {
      location: payload.location,
      legalInfo: {
        trnNumber: Number(payload.trnNumber),
        trnCertificate: payload.trnCertificate,
        registrationCertificate: payload.tradeLicense,
        legalAddress: payload.legalAddress,
      },
      name: payload.name,
      email: payload.email,
      phone: payload.ownerPhone,
      ownerPhone: payload.ownerPhone,
      ownerName: `${payload?.firstName} ${payload?.lastName}`,
      ownerEmail: payload.email,
      isDraftEnabled: props.isDraftEnabled,
      isMultiLocationAllowed: props.isMultiLocationAllowed,
      timezone: props.timezone,
      categoryIds: props.categoryIds,
      currencyId: props.currencyId,
      fulfillmentId: props.fulfillmentId,
      logo: props.logo,
    };

    void createRetailer({
      payload: createRetailerPayload,
      onCompleted: ({ createRetailer }) => {
        onDone(createRetailer.message);
        dispatch({ ...initialPayload });
      },
    }).catch((err) => {
      console.log(err);
    });
    setDisabledSteps([2, 3, 4, 5]);
    next();
  }

  return (
    <Stack h="100%" justifyContent="space-between">
      <Box pb={4}>
        {step === 1 && <StepOneForm submittedPayload={payload} onDone={onStepOneDone} />}
        {step === 2 && <StepTwoForm submittedPayload={payload} onDone={onStepTwoDone} />}
        {step === 3 && <StepThreeForm submittedPayload={payload} onDone={onStepThreeDone} />}
        {step === 4 && <StepFourForm submittedPayload={payload} onDone={onStepFourDone} />}
        {step === 5 && (
          <StepFiveForm
            submittedPayload={payload}
            onDone={onStepFiveDone}
            isLoading={loading}
            fulfillments={fulfillments}
            categories={categories}
          />
        )}
      </Box>
    </Stack>
  );
};

export default FormSide;
