import { Box, Button, HStack, SBInput } from '@swftbox/style-guide';
import { type ChangeEvent, type Dispatch, type ReactNode, type SetStateAction } from 'react';
import SVG from 'react-inlinesvg';
import GridIcon from 'src/assets/icons/Layout/grid-01.svg';
import ListIcon from 'src/assets/icons/Layout/list.svg';
import { ItemsGrid } from 'src/components/Pages/Jobs/Components/AddNewJob/Components/ItemsGrid';
import { GRID_VIEW, LIST_VIEW } from 'src/components/Pages/Jobs/Helper';

interface JobItemsListProps {
  view: string;
  setView: Dispatch<SetStateAction<string>>;
  revealAllLocations: boolean;
  setRevealAllLocations: Dispatch<SetStateAction<boolean>>;
  itemList: ReactNode;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ListWrapper = ({
  view,
  setView,
  itemList,
  revealAllLocations,
  setRevealAllLocations,
  onSearchChange,
}: JobItemsListProps) => {
  return (
    <>
      <HStack mb="4">
        <Box flexGrow="1">
          <SBInput placeholder="Search" onChange={onSearchChange} />
        </Box>

        <HStack>
          <Button
            variant="link"
            border="1px solid #eee"
            color={revealAllLocations ? '#fff' : 'gray.500'}
            bg={revealAllLocations ? '#000' : '#fff'}
            w="auto"
            h="40px"
            p="2"
            transition="all 0.5s"
            onClick={() => {
              setRevealAllLocations(!revealAllLocations);
            }}
            _hover={{ color: '#fff', bg: '#000' }}
          >
            Reveal all locations
          </Button>
          <Button
            variant="link"
            border="1px solid #eee"
            color={view === GRID_VIEW ? '#fff' : 'gray.500'}
            bg={view === GRID_VIEW ? '#000' : '#fff'}
            w="40px"
            h="40px"
            transition="all 0.5s"
            onClick={() => {
              setView(GRID_VIEW);
            }}
            _hover={{ color: '#fff', bg: '#000' }}
          >
            <SVG src={GridIcon} stroke="currentColor" />
          </Button>
          <Button
            variant="link"
            border="1px solid #eee"
            color={view === LIST_VIEW ? '#fff' : 'gray.500'}
            bg={view === LIST_VIEW ? '#000' : '#fff'}
            w="40px"
            h="40px"
            transition="all 0.5s"
            onClick={() => {
              setView(LIST_VIEW);
            }}
            _hover={{ color: '#fff', bg: '#000' }}
          >
            <SVG src={ListIcon} stroke="currentColor" />
          </Button>
        </HStack>
      </HStack>
      <ItemsGrid view={view} itemList={itemList} />
    </>
  );
};
