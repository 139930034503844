import {
  Autocomplete,
  Box,
  HStack,
  SBModal,
  Text,
  toast,
  SBEditIcon,
  FormLabel,
  Switch,
} from '@swftbox/style-guide';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useCities,
  useOrderModalActions,
  useOrderModalContext,
  useUpdateLocation,
  useZones,
  type AddressInput,
} from 'src/components/Particles';
import type {
  DispatchZoneCityType,
  EditCustomerAddressType,
} from 'src/components/Particles/Modules/OrderModule/types';

export const EditCityZoneModal = () => {
  const { editDispatchZoneIsOpen, data: props } = useOrderModalContext() as {
    editDispatchZoneIsOpen: boolean;
    data: DispatchZoneCityType;
  };

  const dispatch = useOrderModalActions();

  const {
    register,
    setValue,
    getValues,
    clearErrors,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddressInput>({
    mode: 'all',
    // resolver: yupResolver(UpdateLocationSchema),
    defaultValues: { ...props },
  });

  const { query: getCities, data: cities } = useCities();
  const { query: getZones, data: zones } = useZones();
  const cachedCities = useMemo(
    () =>
      cities.map((cityItem) => ({
        value: cityItem.standardCityName,
        label: cityItem.standardCityName,
      })),
    [cities]
  );
  const cachedZones = useMemo(
    () => zones.map((zoneItem) => ({ value: zoneItem.zone, label: zoneItem.zone })),
    [zones]
  );
  useEffect(() => {
    void getCities();
    void getZones();
  }, []);
  const { updateLocation } = useUpdateLocation();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    dispatch({ type: 'CLOSE_DISPATCH_ZONE_ORDER' });
  };

  useEffect(() => {
    reset({ ...props });
  }, [editDispatchZoneIsOpen]);

  function onConfirm(data: AddressInput) {
    if ('id' in props) {
      const payload: EditCustomerAddressType = {
        ...data,
        id: props?.id as string,
        verificationSource: undefined,
      };

      void updateLocation({
        payload,
        onCompleted: (message) => {
          handleCloseModal(message);
        },
      });
    }
  }

  return (
    <>
      <SBModal
        isOpen={editDispatchZoneIsOpen}
        onClose={handleCloseModal}
        header={
          <HStack pb="1vh" mb="2">
            <Box
              border="1px solid #eaeCF0"
              w="35px"
              h="35px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon
                fill="black"
                stroke="currentColor"
                viewBox="0 -3 20 28"
                width="20px"
                height="20px"
              />
            </Box>
            <Text>Dispatch Location</Text>
          </HStack>
        }
        size="xl"
        body={
          <Box>
            <HStack spacing={4} mb="3" align="end">
              <Switch id="isInternational" {...register('isInternational')} />
              <FormLabel htmlFor="isInternational" mb="0">
                International
              </FormLabel>
            </HStack>
            <HStack spacing={4} mb="3" align="end">
              <Switch id="isRemote" {...register('isRemote')} />
              <FormLabel htmlFor="isRemote" mb="0">
                Remote
              </FormLabel>
            </HStack>
            <HStack spacing={4} mb="3" align="end">
              <Switch id="isOutOfZone" {...register('isOutOfZone')} />
              <FormLabel htmlFor="isOutOfZone" mb="0">
                Out of Zone
              </FormLabel>
            </HStack>
            <HStack>
              <Controller
                name="dispatchCity"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(val) => {
                      // @ts-expect-error, it's just a falsy warning
                      onChange(val?.value);
                    }}
                    value={cachedCities?.find((option) => option.value === value) || ''}
                    options={cachedCities}
                    label="Dispatch City"
                    placeholder="Select The Dispatch City"
                    error={errors.dispatchCity?.message}
                  />
                )}
              />
              <Controller
                name="dispatchZone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(val) => {
                      // @ts-expect-error, it's just a falsy warning
                      onChange(val?.value);
                    }}
                    value={cachedZones?.find((option) => option.value === value) || ''}
                    options={cachedZones}
                    label="Dispatch Zone"
                    placeholder="Select The Dispatch Zone"
                    error={errors.dispatchZone?.message}
                  />
                )}
              />
            </HStack>
          </Box>
        }
        handleConfirm={handleSubmit(onConfirm)}
        //  @ts-expect-error
        scrollBehavior="outside"
      />
    </>
  );
};
