import { SBModal, Text } from '@swftbox/style-guide';
import { FC } from 'react';
import { GenerateInvoiceForm } from '../Forms';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const GenerateInvoiceModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <SBModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc
      header={<Text fontSize="text-xl">Generate Invoice</Text>}
      size="2xl"
      body={<GenerateInvoiceForm onClose={onClose} isOpen={isOpen} />}
      bordered={false}
      footer={<div />}
    />
  );
};
