import { Box, Button, SBModal, Stack, Text, toast } from '@swftbox/style-guide';
import { useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import {
  type Order,
  OrderStage,
  OrderState,
  useReceiveOrders,
} from 'src/components/Particles/resolvers/orders';

export function ReceiveOrder() {
  const { receiveOrderIsOpen, data: order } = useOrderModalContext() as {
    receiveOrderIsOpen: boolean;
    data: Order;
  };
  const dispatch = useOrderModalActions();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    dispatch({ type: 'CLOSE_RECEIVE_ORDER' });
  };
  const { stage, swftboxTracking, state } = order;
  const { receiveOrders, loading } = useReceiveOrders();

  function onReceiveConfirm() {
    void receiveOrders({
      receiveOrdersInput: {
        ordersSwftboxTracking: [swftboxTracking],
      },
      onCompleted: (message) => {
        if (message) {
          handleCloseModal(message);
        }
      },
    });
  }

  if ((stage !== OrderStage.transit && stage !== OrderStage.failed) || state !== OrderState.inJob) {
    return null;
  }

  return (
    <>
      <SBModal
        isOpen={receiveOrderIsOpen}
        onClose={handleCloseModal}
        type="warning"
        header={
          <Box pb="1vh">
            <Text>Receive Order</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            You are about to receive an order with swftbox tracking{' '}
            <Text as="span" fontWeight="semibold">
              {swftboxTracking}
            </Text>{' '}
            !
          </Text>
        }
        size="lg"
        footer={
          <Stack spacing="3" w="100%" direction={['column', 'row']}>
            <Button
              variant="outline"
              w="100%"
              onClick={() => {
                dispatch({ type: 'CLOSE_RECEIVE_ORDER' });
              }}
            >
              Dismiss
            </Button>
            <Button w="100%" isLoading={loading} onClick={onReceiveConfirm}>
              Confirm
            </Button>
          </Stack>
        }
      />
    </>
  );
}
