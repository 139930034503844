import { Badge, Box, Button, Flex, SBAddIcon, Spacer, Text } from '@swftbox/style-guide';
import { FC, memo } from 'react';

interface Props {
  count: number;
  title: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const PageHeader: FC<Props> = memo(({ count, title, buttonText, onButtonClick }) => (
  <Flex align="center" p="20px 24px" borderBottom="1px solid #EAECF0" flexDir={['column', 'row']}>
    <Box>
      <Text fontWeight="semibold" fontSize="text-lg">
        {title}s
        <Badge
          p="2px 8px"
          mx="1"
          fontWeight="medium"
          borderRadius="8px"
          fontSize="x-small"
          textTransform="capitalize"
        >
          {`${count} ${title}(s)`}
        </Badge>
      </Text>
    </Box>
    <Spacer />
    {buttonText && (
      <Button onClick={onButtonClick}>
        <SBAddIcon width="15px" /> {buttonText}
      </Button>
    )}
  </Flex>
));
