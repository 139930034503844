import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, SBAddIcon, SBModal, toast, useDisclosure } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useAddFeature, type AddFeatureInput } from 'src/components/Particles';
import AddFeatureForm from './AddFeatureForm';
import { featureCreateSchema } from './Feature.Schema';

export function AddFeature() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useAddFeature({
    onCompleted: (data) => {
      handleCloseModal(data.createFeature.message);
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AddFeatureInput>({
    resolver: yupResolver(featureCreateSchema),
    mode: 'all',
  });

  const handleCloseModal = (message?: string) => {
    if (!!message) {
      toast.success(message);
    }
    reset();
    onClose();
  };

  const onConfirm = (data: AddFeatureInput) => {
    mutate({
      variables: {
        input: data,
      },
    });
  };

  return (
    <>
      <Button onClick={onOpen}>
        <SBAddIcon width="15px" /> Add New Feature
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBAddIcon fill="black" stroke="currentColor" width="20px" />
            </Box>
          </Box>
        }
        size="xl"
        body={<AddFeatureForm register={register} errors={errors} control={control}/>}
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
