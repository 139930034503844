export enum FeatureCategory {
  accountSettings = 'ACCOUNT_SETTINGS',
  orderEnhancements = 'ORDER_ENHANCEMENTS',
}

export interface Feature {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  key: string;
  description: string;
  billing: number;
  category?: string;
  isConfigurable: boolean;
}

export interface AddFeatureInput {
  name: string;
  description: string;
  billing: number;
  category: FeatureCategory;
  isConfigurable: boolean;
}

export interface UpdateFeatureInput extends AddFeatureInput {
  id: string;
}

export interface FeatureMutationResponse {
  message: string;
}

export interface RemoveFeatureResponse extends FeatureMutationResponse {
  success: boolean;
}
