import {
  Box,
  Text,
  Button,
  Stack,
  HelperText,
  toast,
  FormLabel,
  SBTextArea,
} from '@swftbox/style-guide';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import FileInput from 'src/components/Atom/FileInput/FileInput';
import { SaveCommentInput, Comment } from 'src/components/Particles';
import { getExtensionValue, splitFileName } from 'src/components/Particles/resolvers';
import {
  type GetUploadUrlVariables,
  uploadFile,
  useGetUploadUrlQuery,
  useAddCommentMutation,
} from 'src/components/Particles';

interface Props {
  orderId: string;
  onClose?: () => void;
  comment?: Comment;
}

export default function CommentForm(props: Props) {
  const { orderId, comment, onClose } = props;
  const { addComment, message } = useAddCommentMutation();
  const [file, setFile] = useState<File | undefined>();
  const { getUploadUrl } = useGetUploadUrlQuery();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [attachments, setAttachements] = useState<string[] | []>([]);
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors },
    setError,
  } = useForm<SaveCommentInput>({
    mode: 'all',
    defaultValues: {
      orderId,
      id: comment?.id,
      comment: comment?.comment,
      attachments: comment?.attachments || [],
    },
  });

  const onSubmit = handleSubmit(async (submittedData) => {
    setIsUploadingFiles(true);

    const { canProceed, fileUploadArgs } = prepareFilesData();
    if (!canProceed || !fileUploadArgs) {
      setIsUploadingFiles(false);
      return;
    }
    const fileUploadURL = await getUploadUrl(fileUploadArgs).catch(() => null);

    if (!fileUploadURL) {
      setIsUploadingFiles(false);
      return;
    }

    const isUploaded = await uploadFile(fileUploadURL.url, file).catch(() => false);

    setIsUploadingFiles(false);
    if (!isUploaded) {
      toast.error('Cannot upload your file');
      return;
    }

    addComment({
      ...submittedData,
      attachments: [fileUploadURL?.filePath],
      onCompleted: () => {
        reset();
        onClose && onClose();
        toast.success(message);
      },
    });
  });

  function prepareFilesData() {
    if (!file) {
      return { canProceed: true };
    }
    const { extension: fileExtension } = splitFileName(file.name || '');
    if (!fileExtension) {
      setError('attachments', { message: 'Cannot obtain file extension' });
      return { canProceed: false };
    }

    const fileUploadArgs: GetUploadUrlVariables = {
      fileType: getExtensionValue(fileExtension),
      fileDirectory: 'RECEIPTS',
    };

    return {
      canProceed: true,
      fileUploadArgs,
    };
  }

  return (
    <form onSubmit={onSubmit}>
      <Box mb={5}>
        <Text fontWeight={'bold'}>{comment ? 'Edit comment' : 'Create new comment'}</Text>
      </Box>
      <Stack gap={4}>
        <Stack>
          <FormLabel>Comment:</FormLabel>
          <SBTextArea rows={2} placeholder="Comment" {...register('comment')} />
        </Stack>
        <Stack>
          <FileInput file={file} setFile={setFile} fileInputLabel="Add Attachments" />
          {errors?.attachments && (
            <HelperText variant="error">{errors?.attachments?.message}</HelperText>
          )}
        </Stack>
        <Stack spacing="3" w="100%" direction={['column', 'row']}>
          <Button variant="outline" w="100%" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" type="submit" disabled={false}>
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
