import { useMutation } from '@apollo/client';
import { type MessageResponse } from '../../../SharedTypes';
import { WAREHOUSE_DROP_MUTATION } from '../../Queries';

export interface ReceiveOrdersInput {
  ordersSwftboxTracking: string[];
  isForced?: boolean;
}

export function useWarehouseDropMutation() {
  const [mutate, { loading, error }] = useMutation<
    { receiveOrders: MessageResponse },
    { receiveOrdersInput: ReceiveOrdersInput }
  >(WAREHOUSE_DROP_MUTATION);

  async function warehouseDrop(receiveOrdersInput: ReceiveOrdersInput) {
    return mutate({
      variables: {
        receiveOrdersInput,
      },
      refetchQueries: ['PaginatedRoutes'],
    });
  }

  return {
    warehouseDrop,
    loading,
    error,
  };
}
