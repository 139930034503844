import { useLazyQuery } from '@apollo/client';
import { GET_ORDER_ADDRESS_HISTORY, ORDER_TIMELINE } from './orders.graphql';
import { type OrderTimeline } from './orders.types';

interface QueryResponse {
  locationTimelines: {
    action: string;
    dispatchCity: string;
    dispatchZone: string;
    updatedAt: string;
    locationUpdates: string[];
    user: {
      name: string;
    };
  }[];
}
interface QueryArgs {
  locationId: string;
}

export function useAddressHistory() {
  const [getOrderTimeline, { loading }] = useLazyQuery<QueryResponse, QueryArgs>(
    GET_ORDER_ADDRESS_HISTORY
  );

  return {
    getOrderTimeline,
    loading,
  };
}
