import { Stack, Button } from '@swftbox/style-guide';
interface props {
  reset: () => void;
  isHidden: boolean;
  loading?: boolean;
}
export const SubmitAndCancel = ({ reset, isHidden, loading }: props) => {
  return (
    <Stack
      spacing="3"
      py="20px"
      borderTop="1px solid"
      borderColor="gray.200"
      w="100%"
      direction={['column', 'row']}
      position="sticky"
      bottom="0"
      opacity={isHidden ? 1 : 0}
      pointerEvents={isHidden ? 'auto' : 'none'} // Allow clicks only when visible
      transition="opacity 0.2s, pointer-events 0.2s"
      background="#fff"
      justify="end"
    >
      <Button variant="outline" onClick={() => reset()} disabled={loading}>
        Cancel
      </Button>
      <Button type="submit" disabled={loading}>
        Save
      </Button>
    </Stack>
  );
};
