import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  MenuItem,
  SBModal,
  SBTextArea,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { type Order, useAddCommentMutation } from 'src/components/Particles';
import * as yup from 'yup';

interface ConfirmFlagProps {
  order: Order;
  option: string;
  type: string;
  onChooseFlag: (flagReason?: string) => void;
}

export function ConfirmFlagModal({ order, option, type, onChooseFlag }: ConfirmFlagProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const flagNeedsComment = ['Customer refused', 'Missing documents/items'].includes(option);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ comment: string }>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        comment: flagNeedsComment ? yup.string().required('Comment is required') : yup.string(),
      })
    ),
    defaultValues: {
      comment: '',
    },
  });

  const { addComment, message } = useAddCommentMutation();

  const onConfirm = (data: any) => {
    if (flagNeedsComment) {
      void addComment({
        orderId: order.id,
        comment: data.comment,
        attachments: [],
        onCompleted: () => {
          toast.success(message);
        },
      });
    }
    onChooseFlag(type === 'flag' ? option : undefined);
    onClose();
  };

  const getBody = () => {
    if (type === 'flag') {
      return (
        <>
          <Text fontSize="text-md" color="gray.700">
            Please confirm that you're flagging order with{' '}
            <Text as="span" fontWeight="semibold">
              {option}
            </Text>
            {' Flag'}
          </Text>
          {flagNeedsComment && (
            <Box mt={3}>
              <SBTextArea
                rows={2}
                placeholder="ex: customer don't want the order anymore"
                label="Comment*"
                {...register('comment')}
                error={errors.comment?.message}
              />
            </Box>
          )}
        </>
      );
    } else {
      return (
        <Text fontSize="text-md" color="gray.700">
          Please confirm that you're clearing order flag !
        </Text>
      );
    }
  };

  return (
    <>
      <MenuItem pb="5" _hover={{ opacity: '0.5' }} key={option} onClick={onOpen}>
        {option}
      </MenuItem>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="general"
        header={<Box pb="1vh">Confirmation</Box>}
        body={getBody()}
        size="lg"
        handleConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
}
