import { useMutation } from '@apollo/client';
import { type RemoveFeatureResponse, LIST_FEATURES, REMOVE_FEATURE } from '../Queries';
import { clientOptionType } from '../../../SharedTypes';

interface RemoveFeatureProps {
  onCompleted: (
    data: { removeFeature: RemoveFeatureResponse },
    clientOptions?: clientOptionType
  ) => void;
}
export function useRemoveFeature({ onCompleted }: RemoveFeatureProps) {
  const [mutate, { error, loading }] = useMutation<
    { removeFeature: RemoveFeatureResponse },
    { removeFeatureId: string }
  >(REMOVE_FEATURE, {
    onCompleted,
    refetchQueries: [{ query: LIST_FEATURES }],
  });

  return {
    mutate,
    loading,
    error,
  };
}
