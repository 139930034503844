import { useMutation } from '@apollo/client';
import { removeTypeName } from 'src/components/Particles/Helpers';
import { clientOptionType } from '../../../SharedTypes';
import {
  GET_FULFILLMENTS_QUERY,
  UpdateFulfillmentInput,
  UPDATE_FULFILLMENT_QUERY,
} from '../Queries';

interface UpdateFulfillmentResponse {
  message: string;
}

interface UpdateFulfillmentProps {
  payload: UpdateFulfillmentInput;
  onCompleted: (
    data: { updateFulfillment: UpdateFulfillmentResponse },
    clientOptions?: clientOptionType
  ) => void;
}

export function useUpdateFulfillment() {
  const [mutate, { data, error, loading }] = useMutation<
    { updateFulfillment: UpdateFulfillmentResponse },
    { input: UpdateFulfillmentInput }
  >(UPDATE_FULFILLMENT_QUERY);

  const callUpdateFulfillment = ({ payload, onCompleted }: UpdateFulfillmentProps) => {
    return mutate({
      variables: { input: removeTypeName(payload) },
      refetchQueries: [
        {
          query: GET_FULFILLMENTS_QUERY,
        },
      ],
      onCompleted,
    });
  };

  return {
    callUpdateFulfillment,
    data,
    error,
    loading,
  };
}
