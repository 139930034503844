import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import { useRetailersQuery, useSearch } from 'src/components/Particles';
import { PartnersFilter, PartnersHeader, PartnersTable } from './Components';
import style from './style.module.scss';

export function PartnersPage() {
  const perPage = 50;
  const [page, setPage] = useState(1);
  // it should be set in the callback function of filters component
  const [status, setStatus] = useState<string[] | undefined>(['pending', 'active']); //initial status

  const { searchText } = useSearch();
  const { getRetailers, retailers, totalRetailers, loading } = useRetailersQuery();

  const onPageChange = (selectedItem: number) => {
    setPage(selectedItem);
  };

  useEffect(() => {
    getRetailers({ status, page, perPage, keyword: searchText });
  }, [getRetailers, page, status, searchText]);

  return (
    <Box className={style.partners}>
      <PartnersHeader partnersCount={totalRetailers} />
      <PartnersFilter updateStatusFilter={setStatus} filterStatus={status} />
      <WithLoader isLoading={loading && !retailers?.length}>
        <PartnersTable
          retailers={retailers}
          page={page}
          perPage={perPage}
          total={totalRetailers}
          onPageChange={onPageChange}
        />
      </WithLoader>
      {!loading && retailers.length === 0 && (
        <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
          <EmptyTable title="No Partners Found" />
        </Flex>
      )}
    </Box>
  );
}
